import React, {Component, FunctionComponent, useEffect, useState} from "react";
import {
    Button,
    Col,
    Row,
    Tabs,
    Form,
    Typography,
    Collapse,
    Switch,
    Select,
    Radio,
    message
} from "antd";

import {IAppComponentProps} from "../../../components";
import {StringValueNode} from "graphql";
import {UPDATE_SURVEY_CONNECTIVITY_AS2} from "../mutations";
import {
    getSurveyConnectivityAs2Svc,
    getSurveyContactByType,
    updateSurveyConnectivityAs2,
    updateSurveyContact
} from "./SurveyConnectivityService";
import {values} from "lodash";
import {useQuery} from "@apollo/client";
import {getSurveyConnectivityAs2} from "../__generated__/getSurveyConnectivityAs2";
import {QUERY_SURVEY_CONNECTIVITY_AS2} from "../queries";
import {SurveyContactInput} from "../../../../__generated__/globalTypes";
import {cleanFormValues, Form_Row, getInitialFormValues} from "./_SurveyComponents";
import {useIntl} from "react-intl";
import Paragraph from "antd/lib/typography/Paragraph";
import {useAppDispatch, useAppSelector} from "../../../main/hooks";
import {MODE} from "../IStoredSurvey";

import styles from "../styling/survey.module.css";
import {resetSurveyDataLoading, setSurveyDataLoading} from "../IStoredSurveySlice";
import {useDispatch} from "react-redux";
import { getI18n } from "../../../utils/Utils";

const {Text} = Typography;
const {Panel} = Collapse;
const {Option} = Select;

type ISurveyConnectivityInfoAS2 = {
    groupId?: any;
    onStatusUpdate?: any;
} & IAppComponentProps;

function AS2_PreRequisite(props): JSX.Element {
    const intl = useIntl();
    return (
        <>
            <Row>
                <Typography>
                    <Paragraph>
                        {intl.formatMessage({
                            id: "survey-connectivityInfo-as2-prerequisites-descriptionLine1",
                            defaultMessage: "Your software must be at the latest security version and be TLS v1.2 and SHA-2 compliant. It should also be able to support the following configuration:"
                        })}
                    </Paragraph>
                </Typography>
            </Row>
            <Row>
                <Typography>
                    <Paragraph strong={true}>
                        {intl.formatMessage({
                            id: "survey-connectivityInfo-as2-prerequisites-descriptionLine2",
                            defaultMessage: "Important"
                        })}
                    </Paragraph>
                </Typography>
            </Row>
            <Row>
                <Col span={24}>
                    <Row gutter={[16, 16]}>
                        <Col span={10} className={"cell--bordered"}>
                            {intl.formatMessage({
                                id: "survey-connectivityInfo-as2-prerequisites-mdnSignedEncryptionReceipt",
                                defaultMessage: "MDN Signed Encrypted Receipt"
                            })}
                        </Col>
                        <Col span={10} className={"cell--bordered"}>
                            {intl.formatMessage({
                                id: "survey-connectivityInfo-as2-prerequisites-asyncMdn",
                                defaultMessage: "Asynchronous MDN"
                            })}
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={10} className={"cell--bordered"}>
                            {intl.formatMessage({
                                id: "survey-connectivityInfo-as2-prerequisites-mdnSignedData",
                                defaultMessage: "Signed Data"
                            })}
                        </Col>
                        <Col span={10} className={"cell--bordered"}>
                            {intl.formatMessage({
                                id: "survey-connectivityInfo-as2-prerequisites-sha256SigningCert",
                                defaultMessage: "SHA-256 Signing Certificate (expiring every 3 years)"
                            })}
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={10} className={"cell--bordered"}>
                            {intl.formatMessage({
                                id: "survey-connectivityInfo-as2-prerequisites-encryptionMethod",
                                defaultMessage: "Encryption Method"
                            })}
                        </Col>
                        <Col span={10} className={"cell--bordered"}>
                            {intl.formatMessage({
                                id: "survey-connectivityInfo-as2-prerequisites-tripleDes",
                                defaultMessage: "Triple DES"
                            })}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Typography>
                        <Paragraph strong={true}>
                            {intl.formatMessage({
                                id: "survey-connectivityInfo-as2-prerequisites-note",
                                defaultMessage: "If your software cannot be upgraded to accommodate this Industry Standard Security Information, please move to a different connection variant."
                            })}
                        </Paragraph>
                    </Typography>
                </Col>
            </Row>
        </>
    )
}

interface SurveyBaseParameters {
    isProductionActive: boolean;
    groupId: string;
    onStatusUpdate?: any;
}

function AS2_Detail(props: SurveyBaseParameters): JSX.Element {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const captureType = props.isProductionActive ? "PRODUCTION" : "TEST";
    const captureTypeText = props.isProductionActive ?
        intl.formatMessage({id: "survey-connectivityInfo-production-btn-text", defaultMessage: "PRODUCTION"})
        : intl.formatMessage({id: "survey-connectivityInfo-test-btn-text", defaultMessage: "TEST"});
    const envSelection = props.isProductionActive ?
        intl.formatMessage({id: "survey-connectivityInfo-production-selection", defaultMessage: "Production Detail"}) :
        intl.formatMessage({id: "survey-connectivityInfo-test-selection", defaultMessage: "Test Detail"});
    const [form] = Form.useForm();

    const [dataLoaded, setDataLoaded] = useState(false);
    const [receiptCapabilityAsynchronous, isReceiptCapabilityAsynchronous] = useState(false);
    // const [saving, setSaving] = useState(false);
    const [infoChanged, setInfoChanged] = useState(false);
    const [flag, setFlag] = useState<boolean>(false);

    const commentHeaderCol = intl.formatMessage({
        id: "survey-connectivityInfo-commentHeader",
        defaultMessage: "Comment"
    });
    const detailHeaderCol = intl.formatMessage({id: "survey-connectivityInfo-detailHeader", defaultMessage: "Detail"});
    const instructionHeaderCol = intl.formatMessage({
        id: "survey-connectivityInfo-InstructionHeader",
        defaultMessage: "Instruction"
    });
    const [expandedPanels, setExpandedPanels] = useState([]);

    const surveyState = useAppSelector((state) => state.survey);

    const isViewMode = () => {
        return surveyState.survey.surveyMode === MODE.VIEW ? true : false
    }

    const isOverViewMode = () => {
        return surveyState.survey.surveyMode === MODE.OVERVIEW ? true : false
    }


    const reload = () => setFlag(currVal => !currVal);

    const expandAllPanels = () => {
        setExpandedPanels(['1', '2', '3']);
    }

    const panelsChanged = (e) => {
        setExpandedPanels(e);
    }

    function settingDefaultValues(initialValues: {}) {
        //setting default values....
        if (!initialValues['as2MessageFormatType']) {
            initialValues['as2MessageFormatType'] = 'Encrypted';
        }
        if (!initialValues['___test___as2MessageFormatType']) {
            initialValues['___test___as2MessageFormatType'] = 'Encrypted';
        }
    }

    /**load initial data from api if available */
    // const { data, loading } = useQuery<getSurveyConnectivityAs2>(QUERY_SURVEY_CONNECTIVITY_AS2,
    //   {
    //     fetchPolicy: "network-only",
    //     variables: { groupId: props.groupId },
    //     onCompleted: (response) => {
    //       const initialValues = getInitialFormValues(response.getSurveyConnectivityAs2, 'as2Environment');

    //       settingDefaultValues(initialValues);
    //       // eslint-disable-next-line no-console
    //       console.dir(initialValues);
    //       form.setFieldsValue(initialValues);
    //     }
    //   }
    // );

    useEffect(() => {
        if (!dataLoaded) {
            dispatch(setSurveyDataLoading(true));
            getSurveyConnectivityAs2Svc(props.groupId)
                .then(response => {
                    const initialValues = getInitialFormValues(response.getSurveyConnectivityAs2, 'as2Environment');
                    settingDefaultValues(initialValues);
                    // eslint-disable-next-line no-console
                    // console.dir(initialValues);
                    form.setFieldsValue(initialValues);
                    setDataLoaded(true);
                })
                .catch(() => {
                    dispatch(resetSurveyDataLoading(true));
                })
                .finally(() => {
                    dispatch(setSurveyDataLoading(false));
                });
        }
    }, [dataLoaded])

    const onFinish = (values: any) => {
        values.as2Environment = captureType;
        // eslint-disable-next-line no-console
        // console.dir(values);
        const cleanValues = cleanFormValues(values, captureType);
        cleanValues.as2ServerPort = parseInt(cleanValues.as2ServerPort);
        // eslint-disable-next-line no-console
        // console.dir(cleanValues);
        // setSaving(true);
        dispatch(setSurveyDataLoading(true));
        updateSurveyConnectivityAs2(props.groupId, cleanValues)
            .then((response) => {
                // eslint-disable-next-line no-console
                // console.dir(response);
                setInfoChanged(false);
                //force issues component to refresh
                props.onStatusUpdate();
                message.success(getI18n("survey-connectivity-save-contact-success-message", "Connectivity Information saved successfully" , intl));
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.dir(error);
                dispatch(resetSurveyDataLoading(true));
                message.error(getI18n("survey-connectivity-save-contact-failed-message", "Failed to save connectivity information" , intl));
            })
            .finally(() => {
                // setSaving(false);
                dispatch(setSurveyDataLoading(false));
            });
    }

    const isMDNURLMandatory = () : boolean => {
        return (props.isProductionActive && form.getFieldValue("as2ReceiptCapability") === "Asynchronous") ||
            (!props.isProductionActive && form.getFieldValue("___test___as2ReceiptCapability") === "Asynchronous");
    };



    const onFieldsChange = (changedFields: any, allFields: any) => {
        // eslint-disable-next-line no-console
        console.dir(form.getFieldsValue());
        //TODO: remove console and save to user storage for later.
        setInfoChanged(true);
    };

    function isMDNSigningAlgorithmMandatory() {
        return (props.isProductionActive && form.getFieldValue("as2SigningCapability") === "Signed") ||
            (!props.isProductionActive && form.getFieldValue("___test___as2SigningCapability") === "Signed");
    }

    // @ts-ignore
    return (
        <>
            <Typography>
                <Paragraph>
                    {intl.formatMessage({
                        id: "survey-connectivityInfo-as2-as2details-description",
                        defaultMessage: "If you Require Assistance identifying this information, contact your Network Administrator or your AS2 Software Vendor."
                    })}
                </Paragraph>
            </Typography>
            <Row>
                <Col span={24}>
                    {/* <Spin spinning={loading || saving} size={"large"}> */}
                    <Form
                        form={form}
                        labelCol={{span: 10}}
                        labelWrap={true}
                        wrapperCol={{span: 14}}
                        labelAlign={"left"}
                        onFinish={onFinish}
                        onValuesChange={onFieldsChange}
                    >
                        <Collapse defaultActiveKey={"[1]"} onChange={panelsChanged} activeKey={expandedPanels}>
                            <Panel header=
                                       {`${intl.formatMessage({
                                           id: "survey-connectivityInfo-as2-as2details-as2-section-title",
                                           defaultMessage: "AS2 Details"
                                       })} 
                - ${envSelection}`}
                                   key="1" forceRender={true}>
                                <table className="form--table">
                                    <colgroup>
                                        <col span={1} style={{"width": "55%"}}/>
                                        <col span={1} style={{"width": "20%"}}/>
                                        <col span={1} style={{"width": "25%"}}/>
                                    </colgroup>
                                    <thead>
                                    <tr>
                                        <th>{detailHeaderCol}</th>
                                        <th>{commentHeaderCol}</th>
                                        <th>{instructionHeaderCol}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <Form_Row
                                        isProduction={props.isProductionActive}
                                        label={intl.formatMessage({
                                            id: "survey-connectivityInfo-as2-as2details-serverAddress-prompt",
                                            defaultMessage: "Server Address"
                                        })}
                                        name="as2Server"
                                        required={true}
                                        requiredMessage={intl.formatMessage({
                                            id: "generic-requiredField-message",
                                            defaultMessage: "Please enter required field"
                                        })}
                                        instruction={intl.formatMessage({
                                            id: "survey-connectivityInfo-as2-as2details-serverAddress-instruction",
                                            defaultMessage: "Please provide your server URL or IP address that is exposed to the internet"
                                        })}
                                        rules={[{
                                            required: true,
                                            message: intl.formatMessage({
                                                id: "generic-requiredField-message",
                                                defaultMessage: "Please enter required field"
                                            })
                                        },
                                            {
                                                pattern: new RegExp(/(http|https)?:\/\/([a-zA-Z0-9]+\/?)\S*/),
                                                message: intl.formatMessage({
                                                    id: "survey-connectivityInfo-as2-as2details-serverAddress-validation-message",
                                                    defaultMessage: "Please enter a url with a valid protocol"
                                                })
                                            }]}
                                    />
                                    <Form_Row
                                        isProduction={props.isProductionActive}
                                        label={intl.formatMessage({
                                            id: "survey-connectivityInfo-as2-as2details-serverPort-prompt",
                                            defaultMessage: "Server Port"
                                        })}
                                        name="as2ServerPort"
                                        type="number"
                                        required={true}
                                        requiredMessage={intl.formatMessage({
                                            id: "generic-requiredField-message",
                                            defaultMessage: "Please enter required field"
                                        })}
                                        instruction={intl.formatMessage({
                                            id: "survey-connectivityInfo-as2-as2details-serverPort-instruction",
                                            defaultMessage: "Port server is accessible on"
                                        })}
                                    />
                                    <Form_Row
                                        isProduction={props.isProductionActive}
                                        label={intl.formatMessage({
                                            id: "survey-connectivityInfo-as2-as2details-as2id-prompt",
                                            defaultMessage: "AS2 Id"
                                        })}
                                        name="as2Id"
                                        required={true}
                                        instruction={intl.formatMessage({
                                            id: "survey-connectivityInfo-as2-as2details-as2id-instruction",
                                            defaultMessage: ""
                                        })}
                                        requiredMessage={intl.formatMessage({
                                            id: "generic-requiredField-message",
                                            defaultMessage: "Please enter required field"
                                        })}
                                    />
                                    <Form_Row
                                        isProduction={props.isProductionActive}
                                        label={intl.formatMessage({
                                            id: "survey-connectivityInfo-as2-as2details-accessToCertificate-prompt",
                                            defaultMessage: "Can you provide the AS2 Certificate to the Service Provider"
                                        })}
                                        name="as2CertAvailable"
                                        required={true}
                                        requiredMessage={intl.formatMessage({
                                            id: "generic-requiredField-message",
                                            defaultMessage: "Please enter required field"
                                        })}

                                        instruction={intl.formatMessage({
                                            id: "survey-connectivityInfo-as2-as2details-accessToCertificate-instruction",
                                            defaultMessage: "The AS2 certificate is required to encrypt messages for sending"
                                        })}
                                    >
                                        <Radio.Group className={styles.yesNoStyling}>
                                            <Radio.Button
                                                value={true}>{intl.formatMessage({id: 'ecap-capture-bmw-retail-outlet-choice-yes'})}</Radio.Button>
                                            <Radio.Button
                                                value={false}>{intl.formatMessage({id: 'ecap-capture-bmw-retail-outlet-choice-no'})}</Radio.Button>
                                        </Radio.Group>
                                    </Form_Row>
                                    <Form_Row
                                        isProduction={props.isProductionActive}
                                        label={intl.formatMessage({
                                            id: "survey-connectivityInfo-as2-as2details-ediIdAndQualifier-prompt",
                                            defaultMessage: "EDI ID and Qualifier"
                                        })}
                                        name="as2EDIqualifier"
                                        required={false}
                                        requiredMessage={intl.formatMessage({
                                            id: "generic-requiredField-message",
                                            defaultMessage: "Please enter required field"
                                        })}
                                        instruction={intl.formatMessage({
                                            id: "survey-connectivityInfo-as2-as2details-ediIdAndQualifier-instruction",
                                            defaultMessage: "If you have and EDI address, please provide the details here."
                                        })}
                                    />
                                    <Form_Row
                                        isProduction={props.isProductionActive}
                                        label={intl.formatMessage({
                                            id: "survey-connectivityInfo-as2-as2details-as2Software-prompt",
                                            defaultMessage: "AS2 Software"
                                        })}
                                        name="as2Software"
                                        required={false}
                                        requiredMessage={intl.formatMessage({
                                            id: "generic-requiredField-message",
                                            defaultMessage: "Please enter required field"
                                        })}
                                        instruction={intl.formatMessage({
                                            id: "survey-connectivityInfo-as2-as2details-as2Software-instruction",
                                            defaultMessage: "Please provide the name of the software product used for AS2 connectivity"
                                        })}
                                    />
                                    <Form_Row
                                        isProduction={props.isProductionActive}
                                        label={intl.formatMessage({
                                            id: "survey-connectivityInfo-as2-as2details-as2SoftwareVersion-prompt",
                                            defaultMessage: "AS2 Software Version"
                                        })}
                                        name="as2SoftwareVersion"
                                        required={false}
                                        requiredMessage={intl.formatMessage({
                                            id: "generic-requiredField-message",
                                            defaultMessage: "Please enter required field"
                                        })}
                                        instruction={intl.formatMessage({
                                            id: "survey-connectivityInfo-as2-as2details-as2SoftwareVersion-instruction",
                                            defaultMessage: "Please provide the version of the software product used for AS2 connectivity"
                                        })}
                                    />
                                    </tbody>
                                </table>

                            </Panel>
                            <Panel header={intl.formatMessage({
                                id: "survey-connectivityInfo-as2-as2details-message-section-title",
                                defaultMessage: "Message"
                            })} key="2" forceRender={true}>
                                <table className="form--table">
                                    <colgroup>
                                        <col span={1} style={{"width": "55%"}}/>
                                        <col span={1} style={{"width": "20%"}}/>
                                        <col span={1} style={{"width": "25%"}}/>
                                    </colgroup>
                                    <thead>
                                    <tr>
                                        <th>{detailHeaderCol}</th>
                                        <th>{commentHeaderCol}</th>
                                        <th>{instructionHeaderCol}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <Form_Row
                                        isProduction={props.isProductionActive}
                                        label={intl.formatMessage({
                                            id: "survey-connectivityInfo-as2-as2details-messageFormatType-prompt",
                                            defaultMessage: "Message Format Type"
                                        })}
                                        name="as2MessageFormatType"
                                        required={false}
                                        instruction={intl.formatMessage({
                                            id: "survey-connectivityInfo-as2-as2details-messageFormatType-instruction",
                                            defaultMessage: "Messages sent to you will be Encrypted by default"
                                        })}
                                    >
                                        <Select disabled={isViewMode() || isOverViewMode()}>
                                            <Option
                                                value="Encrypted">{intl.formatMessage({id: 'survey-connectivityInfo-oftp2-dataEncryption-option-encrypted'})}</Option>
                                        </Select>
                                    </Form_Row>
                                    <Form_Row
                                        isProduction={props.isProductionActive}
                                        label={intl.formatMessage({
                                            id: "survey-connectivityInfo-as2-as2details-messageEncryptionAlgorithm-prompt",
                                            defaultMessage: "Encryption Algorithm"
                                        })}
                                        name="as2EncryptionAlgorithm"
                                        required={true}
                                        requiredMessage={intl.formatMessage({
                                            id: "generic-requiredField-message",
                                            defaultMessage: "Please enter required field"
                                        })}
                                        instruction={intl.formatMessage({
                                            id: "survey-connectivityInfo-as2-as2details-messageEncryptionAlgorithm-instruction",
                                            defaultMessage: "Please select the algorithm to be used in the exchange of messages. Recommended is Tripple DES"
                                        })}
                                    >
                                        <Select disabled={isViewMode() || isOverViewMode()}>
                                            <Option value="DES3 168">DES3 168 (Triple DES)</Option>
                                            <Option value="AES 128">AES 128</Option>
                                            <Option value="AES 192">AES 192</Option>
                                            <Option value="AES 256">AES 256</Option>
                                            <Option value="RC2 128">RC2 128</Option>
                                        </Select>
                                    </Form_Row>
                                    <Form_Row
                                        isProduction={props.isProductionActive}
                                        label={intl.formatMessage({
                                            id: "survey-connectivityInfo-as2-as2details-messageSigningAlgorithm-prompt",
                                            defaultMessage: "Signing Algorithm"
                                        })}
                                        name="as2SigningAlgorithm"
                                        required={true}
                                        requiredMessage={intl.formatMessage({
                                            id: "generic-requiredField-message",
                                            defaultMessage: "Please enter required field"
                                        })}
                                        instruction={intl.formatMessage({
                                            id: "survey-connectivityInfo-as2-as2details-messageSigningAlgorithm-instruction",
                                            defaultMessage: "Please select the algorithm that will be used to sign the messages that will be send to you. Recommended is SHA2 256."
                                        })}
                                    >
                                        <Select disabled={isViewMode() || isOverViewMode()}>
                                            <Option
                                                value="SHA2 256">{intl.formatMessage({id: 'survey-connectivityInfo-as2-sha-recommended'})}</Option>
                                            <Option value="SHA2 384">SHA2 384</Option>
                                            <Option value="SHA2 512">SHA2 512</Option>
                                            <Option value="SHA 160">SHA 160</Option>
                                        </Select>
                                    </Form_Row>
                                    <Form_Row
                                        isProduction={props.isProductionActive}
                                        label={intl.formatMessage({
                                            id: "survey-connectivityInfo-as2-as2details-messageToBeExchanged-prompt",
                                            defaultMessage: "Message to be Exchanged"
                                        })}
                                        name="as2MessageToBeExchanged"
                                        required={true}
                                        instruction={intl.formatMessage({
                                            id: "survey-connectivityInfo-as2-as2details-messageToBeExchanged-instruction",
                                            defaultMessage: "Please select the type of messages that will be exchanged with your partners (EDI, Non-EDI or XML)."
                                        })}
                                    >
                                        <Select disabled={isViewMode() || isOverViewMode()}>
                                            <Option value="EDI">EDI</Option>
                                            <Option
                                                value="Non-EDI">{intl.formatMessage({id: 'survey-connectivityInfo-as2-non-edi'})}</Option>
                                            <Option value="XML">XML</Option>
                                        </Select>
                                    </Form_Row>
                                    </tbody>
                                </table>
                            </Panel>
                            <Panel header={intl.formatMessage({
                                id: "survey-connectivityInfo-as2-as2details-mdn-section-title",
                                defaultMessage: "MDN"
                            })} key="3" forceRender={true}>
                                <table className="form--table">
                                    <colgroup>
                                        <col span={1} style={{"width": "55%"}}/>
                                        <col span={1} style={{"width": "20%"}}/>
                                        <col span={1} style={{"width": "25%"}}/>
                                    </colgroup>
                                    <thead>
                                    <tr>
                                        <th>{detailHeaderCol}</th>
                                        <th>{commentHeaderCol}</th>
                                        <th>{instructionHeaderCol}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <Form_Row
                                        isProduction={props.isProductionActive}
                                        label={intl.formatMessage({
                                            id: "survey-connectivityInfo-as2-as2details-mdnSigningCapability-prompt",
                                            defaultMessage: "MDN Signing Capability"
                                        })}
                                        name="as2SigningCapability"
                                        required={true}
                                        requiredMessage={intl.formatMessage({
                                            id: "generic-requiredField-message",
                                            defaultMessage: "Please enter required field"
                                        })}
                                        instruction={intl.formatMessage({
                                            id: "survey-connectivityInfo-as2-as2details-mdnSigningCapability-instruction",
                                            defaultMessage: "Specify your company's MDN signing capability."
                                        })}
                                    >
                                        <Select disabled={isViewMode() || isOverViewMode()} onChange={_ => reload()}>
                                            <Option value="Signed">{intl.formatMessage({
                                                id: "survey-connectivityInfo-as2-as2details-mdnSigningCapability-option-signed",
                                                defaultMessage: "Signed (Recommended)"
                                            })}</Option>
                                            <Option value="Unsigned">{intl.formatMessage({
                                                id: "survey-connectivityInfo-as2-as2details-mdnSigningCapability-option-unsigned",
                                                defaultMessage: "Unsigned"
                                            })}</Option>
                                        </Select>
                                    </Form_Row>
                                    <Form_Row
                                        isProduction={props.isProductionActive}
                                        label={intl.formatMessage({id: "survey-connectivityInfo-as2-as2details-mdnSigningAlgorithm-label"})}
                                        name="as2MDNSigningAlgorithm"
                                        required={isMDNSigningAlgorithmMandatory()}
                                        requiredMessage={intl.formatMessage({
                                            id: "generic-requiredField-message",
                                            defaultMessage: "Please enter required field"
                                        })}
                                        instruction={intl.formatMessage({id: 'survey-connectivityInfo-as2-as2details-mdnSigningAlgorithm-instruction'})}
                                    >
                                        <Select disabled={isViewMode() || isOverViewMode()}>
                                            <Option value="SHA1">SHA1</Option>
                                            <Option value="SHA 256">SHA 256</Option>
                                            <Option value="SHA 384">SHA 384</Option>
                                            <Option value="SHA 512">SHA 512</Option>
                                        </Select>
                                    </Form_Row>
                                    <Form_Row
                                        isProduction={props.isProductionActive}
                                        label={intl.formatMessage({
                                            id: "survey-connectivityInfo-as2-as2details-mdnReceiptCapability-prompt",
                                            defaultMessage: "MDN Receipt Capability"
                                        })}
                                        name="as2ReceiptCapability"
                                        required={true}
                                        requiredMessage={intl.formatMessage({
                                            id: "generic-requiredField-message",
                                            defaultMessage: "Please enter required field"
                                        })}
                                        instruction={intl.formatMessage({
                                            id: "survey-connectivityInfo-as2-as2details-mdnReceiptCapability-instruction",
                                            defaultMessage: "Specify your company's MDN mode preference for MDNs you send in response to AS2 messages you receive."
                                        })}
                                    >
                                        <Select disabled={isViewMode() || isOverViewMode()} onChange={_ => reload()}>
                                            <Option value="Synchronous">{intl.formatMessage({
                                                id: "survey-connectivityInfo-as2-as2details-mdnReceiptCapability-option-sync",
                                                defaultMessage: "Synchronous (Recommended)"
                                            })}</Option>
                                            <Option value="Asynchronous">{intl.formatMessage({
                                                id: "survey-connectivityInfo-as2-as2details-mdnReceiptCapability-option-async",
                                                defaultMessage: "Asynchronous"
                                            })}</Option>
                                        </Select>
                                    </Form_Row>
                                    <Form_Row
                                        isProduction={props.isProductionActive}
                                        label={intl.formatMessage({
                                            id: "survey-connectivityInfo-as2-as2details-mdnUrlSigningCapability-prompt",
                                            defaultMessage: "MDN URL"
                                        })}
                                        name="as2MdnUrl"
                                        required={isMDNURLMandatory()}
                                        instruction={intl.formatMessage({
                                            id: "survey-connectivityInfo-as2-as2details-mdnUrl-instruction",
                                            defaultMessage: "Specify your company's MDN URL."
                                        })}
                                        rules={[{
                                            required:  isMDNURLMandatory() ,
                                            message: intl.formatMessage({
                                                id: "generic-requiredField-message",
                                                defaultMessage: "Please enter required field"
                                            })
                                        },
                                            {
                                                pattern: new RegExp(/(http|https)?:\/\/([a-zA-Z0-9]+\/?)\S*/),
                                                message: intl.formatMessage({
                                                    id: "survey-connectivityInfo-as2-as2details-serverAddress-validation-message",
                                                    defaultMessage: "Please enter a url with a valid protocol"
                                                })
                                            }]}
                                    />
                                    </tbody>
                                </table>
                            </Panel>
                        </Collapse>
                        <Form.Item wrapperCol={{offset: 8, span: 16}}>
                            <Button type="primary" htmlType="submit" onClick={expandAllPanels} disabled={!infoChanged}
                                    hidden={isViewMode() || isOverViewMode()} className={"mt20"}>
                                {`${intl.formatMessage({
                                    id: "survey-connectivityInfo-as2-as2details-save",
                                    defaultMessage: "Save AS2"
                                })} - ${captureTypeText}`}
                            </Button>
                        </Form.Item>
                        {/* <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="button" disabled={!infoChanged}>
                  Cancel Changes.
                </Button>
              </Form.Item> */}
                    </Form>
                    {/* </Spin> */}
                </Col>
            </Row>
        </>
    )
}

function AS2_Certificates(props: SurveyBaseParameters): JSX.Element {
    const dispatch = useAppDispatch();
    const [dataLoaded, setDataLoaded] = useState(false);
    const intl = useIntl();
    // const [saving, setSaving] = useState(false);
    const [form] = Form.useForm();
    const contactEmailCol = intl.formatMessage({
        id: "survey-connectivityInfo-as2-alertsRecipient-email-prompt",
        defaultMessage: "Contact Email"
    });
    const commentHeaderCol = intl.formatMessage({
        id: "survey-connectivityInfo-commentHeader",
        defaultMessage: "Comment"
    });

    const surveyState = useAppSelector((state) => state.survey);

    const isViewMode = () => {
        return surveyState.survey.surveyMode === MODE.VIEW ? true : false
    }

    const isOverViewMode = () => {
        return surveyState.survey.surveyMode === MODE.OVERVIEW ? true : false
    }

    const loadData = () => {
        dispatch(setSurveyDataLoading(true));
        getSurveyContactByType(props.groupId, 2)
            .then((response: any) => {
                // eslint-disable-next-line no-console
                // console.dir(response.getSurveyContactByType);
                if (response.getSurveyContactByType?.length > 0) {
                    const alertsRecipient: any = {};
                    alertsRecipient.as2AlertContactFirstName = response.getSurveyContactByType[0].contactFirstName;
                    alertsRecipient.as2AlertContactLastName = response.getSurveyContactByType[0].contactLastName;
                    alertsRecipient.as2AlertContactPhone = response.getSurveyContactByType[0].contactNumber;
                    alertsRecipient.as2AlertContactEmail = response.getSurveyContactByType[0].contactEmailAddres;
                    // eslint-disable-next-line no-console
                    // console.dir(alertsRecipient);
                    setDataLoaded(true);
                    form.setFieldsValue(alertsRecipient)
                }
            })
            .catch(() => {
                dispatch(resetSurveyDataLoading(true));
            })
            .finally(() => {
                dispatch(setSurveyDataLoading(false));
            });

        dispatch(setSurveyDataLoading(true));
        getSurveyContactByType(props.groupId, 3)
            .then((response: any) => {
                // eslint-disable-next-line no-console
                console.dir(response.getSurveyContactByType);
                if (response.getSurveyContactByType?.length > 0) {
                    const alertsRecipient: any = {};
                    alertsRecipient.as2CertificateContactFirstName = response.getSurveyContactByType[0].contactFirstName;
                    alertsRecipient.as2CertificateContactLastName = response.getSurveyContactByType[0].contactLastName;
                    alertsRecipient.as2CertificateContactPhone = response.getSurveyContactByType[0].contactNumber;
                    alertsRecipient.as2CertificateContactEmail = response.getSurveyContactByType[0].contactEmailAddres;
                    // eslint-disable-next-line no-console
                    console.dir(alertsRecipient);
                    form.setFieldsValue(alertsRecipient)
                }
            })
            .catch(() => {
                dispatch(resetSurveyDataLoading(true));
            })
            .finally(() => {
                dispatch(setSurveyDataLoading(false));
            });

    }

    useEffect(() => {
        if (!dataLoaded) {
            loadData();
        }

    }, [dataLoaded]);

    const onFinish = async (values: any) => {
        try {
            // eslint-disable-next-line no-console
            // console.dir(values);
            // setSaving(true);
            dispatch(setSurveyDataLoading(true));
            const alertContactInfo: SurveyContactInput = {}
            alertContactInfo.contactTypeId = 2;
            alertContactInfo.contactFirstName = values.as2AlertContactFirstName;
            alertContactInfo.contactLastName = values.as2AlertContactLastName;
            alertContactInfo.contactNumber = values.as2AlertContactPhone;
            alertContactInfo.contactEmailAddres = values.as2AlertContactEmail;
            await updateSurveyContact(props.groupId, alertContactInfo);
            const certContactInfo: SurveyContactInput = {}
            certContactInfo.contactTypeId = 3;
            certContactInfo.contactFirstName = values.as2CertificateContactFirstName;
            certContactInfo.contactLastName = values.as2CertificateContactLastName;
            certContactInfo.contactNumber = values.as2CertificateContactPhone;
            certContactInfo.contactEmailAddres = values.as2CertificateContactEmail;
            await updateSurveyContact(props.groupId, certContactInfo);
        } catch {
            dispatch(resetSurveyDataLoading(true));
        } finally {
            // setSaving(false);
            dispatch(setSurveyDataLoading(false));
            //force issues component to refresh
            props.onStatusUpdate();
        }
    }
    return (
        <>
            <Row>
                <Col span={24}>
                    <Typography>
                        <Paragraph strong={true}>
                            {intl.formatMessage({
                                id: "survey-connectivityInfo-as2-alerts-descriptionLine1",
                                defaultMessage: "Indicate the contacts to receive the AS2 alerts and AS2 certificate updates."
                            })}
                        </Paragraph>
                    </Typography>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Typography>
                        <Paragraph>
                            {intl.formatMessage({
                                id: "survey-connectivityInfo-as2-alerts-descriptionLine2",
                                defaultMessage: "AS2 notification receiver is the contact that receives AS2 alerts or errors in case of problems exchanging messages through AS2."
                            })}
                        </Paragraph>
                    </Typography>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    {/* <Spin spinning={saving} size={"large"}> */}
                    <Form noValidate={true}
                        labelCol={{span: 8}}
                        labelWrap={true}
                        wrapperCol={{span: 16}}
                        labelAlign={"left"}
                        onFinish={onFinish}
                        form={form}
                    >
                        <table className="form--table">
                            <colgroup>
                                <col span={1} style={{"width": "55%"}}/>
                                <col span={1} style={{"width": "20%"}}/>
                                <col span={1} style={{"width": "25%"}}/>
                            </colgroup>
                            <thead>
                            <tr>
                                <th>{intl.formatMessage({
                                    id: "survey-connectivityInfo-as2-alertsRecipient-Title",
                                    defaultMessage: "AS2 Alerts Recipient"
                                })}</th>
                                <th>{intl.formatMessage({
                                    id: "survey-connectivityInfo-commentHeader",
                                    defaultMessage: "Comment"
                                })}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <Form_Row
                                isProduction={true}
                                label={intl.formatMessage({
                                    id: "survey-connectivityInfo-as2-alertsRecipient-name-prompt",
                                    defaultMessage: "Contact First Name"
                                })}
                                name="as2AlertContactFirstName"
                                required={false}
                                requiredMessage={intl.formatMessage({
                                    id: "generic-requiredField-message",
                                    defaultMessage: "Please enter required field"
                                })}
                            />
                            <Form_Row
                                isProduction={true}
                                label={intl.formatMessage({
                                    id: "survey-connectivityInfo-as2-alertsRecipient-surname-prompt",
                                    defaultMessage: "Contact Last Name"
                                })}
                                name="as2AlertContactLastName"
                                required={false}
                                requiredMessage={intl.formatMessage({
                                    id: "generic-requiredField-message",
                                    defaultMessage: "Please enter required field"
                                })}
                            />
                            <Form_Row
                                isProduction={true}
                                label={intl.formatMessage({
                                    id: "survey-connectivityInfo-as2-alertsRecipient-phone-prompt",
                                    defaultMessage: "Contact Phone"
                                })}
                                name="as2AlertContactPhone"
                                required={false}
                                requiredMessage={intl.formatMessage({
                                    id: "generic-requiredField-message",
                                    defaultMessage: "Please enter required field"
                                })}
                                type="tel"
                            />
                            <Form_Row
                                isProduction={true}
                                label={intl.formatMessage({
                                    id: "survey-connectivityInfo-as2-alertsRecipient-email-prompt",
                                    defaultMessage: "Contact Email"
                                })}
                                name="as2AlertContactEmail"
                                required={false}
                                rules={[{ type: 'email', message: intl.formatMessage({ id: "survey-connectivityInfo-invalid-email",
                                      defaultMessage: "Email must include one '@' symbol, a domain, no spaces, and only valid characters."
                                    })
                                  }]}
                                requiredMessage={intl.formatMessage({
                                    id: "generic-requiredField-message",
                                    defaultMessage: "Please enter required field"
                                })}
                                type="email"
                            />
                            </tbody>
                        </table>
                        <Row>
                            <Col span={24}>
                                <Typography>
                                    <Paragraph>
                                        {intl.formatMessage({
                                            id: "survey-connectivityInfo-as2-alertsRecipient-note",
                                            defaultMessage: "AS2 certificate update receiver is the contact who is notified if your AS2 certificate expires and needs to be updated."
                                        })}
                                    </Paragraph>
                                </Typography>
                            </Col>
                        </Row>
                        <table className="form--table">
                            <colgroup>
                                <col span={1} style={{"width": "55%"}}/>
                                <col span={1} style={{"width": "20%"}}/>
                                <col span={1} style={{"width": "25%"}}/>
                            </colgroup>
                            <thead>
                            <tr>
                                <th>{intl.formatMessage({
                                    id: "survey-connectivityInfo-as2-alertsCertificate-title",
                                    defaultMessage: "AS2 Certificate Updates"
                                })}</th>
                                <th>{intl.formatMessage({
                                    id: "survey-connectivityInfo-commentHeader",
                                    defaultMessage: "Comment"
                                })}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <Form_Row
                                isProduction={true}
                                label={intl.formatMessage({
                                    id: "survey-connectivityInfo-as2-alertsCertificate-name-prompt",
                                    defaultMessage: "Contact First Name"
                                })}
                                name="as2CertificateContactFirstName"
                                required={false}
                                requiredMessage={intl.formatMessage({
                                    id: "generic-requiredField-message",
                                    defaultMessage: "Please enter required field"
                                })}
                            />
                            <Form_Row
                                isProduction={true}
                                label={intl.formatMessage({
                                    id: "survey-connectivityInfo-as2-alertsCertificate-surname-prompt",
                                    defaultMessage: "Contact Last Name"
                                })}
                                name="as2CertificateContactLastName"
                                required={false}
                                requiredMessage={intl.formatMessage({
                                    id: "generic-requiredField-message",
                                    defaultMessage: "Please enter required field"
                                })}
                            />
                            <Form_Row
                                isProduction={true}
                                label={intl.formatMessage({
                                    id: "survey-connectivityInfo-as2-alertsCertificate-phone-prompt",
                                    defaultMessage: "Contact Phone"
                                })}
                                name="as2CertificateContactPhone"
                                required={false}
                                requiredMessage={intl.formatMessage({
                                    id: "generic-requiredField-message",
                                    defaultMessage: "Please enter required field"
                                })}
                                type="tel"
                            />
                            <Form_Row
                                isProduction={true}
                                label={intl.formatMessage({
                                    id: "survey-connectivityInfo-as2-alertsCertificate-mail-prompt",
                                    defaultMessage: "Contact Email"
                                })}
                                name="as2CertificateContactEmail"
                                required={false}
                                rules={[{ 
                                    type: 'email', message: intl.formatMessage({ id: "survey-connectivityInfo-invalid-email",
                                      defaultMessage: "Email must include one '@' symbol, a domain, no spaces, and only valid characters."
                                    })
                                  }]}
                                requiredMessage={intl.formatMessage({
                                    id: "generic-requiredField-message",
                                    defaultMessage: "Please enter required field"
                                })}
                                type="email"
                            />
                            <tr>
                                <td>
                                    <Form.Item wrapperCol={{offset: 8, span: 16}}
                                               hidden={isViewMode() || isOverViewMode()}>
                                        <Button type="primary" htmlType="submit" className={"mt20"}>
                                            {intl.formatMessage({
                                                id: "survey-connectivityInfo-as2-alertsCertificate-save",
                                                defaultMessage: "Save Details"
                                            })}
                                        </Button>
                                    </Form.Item>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </Form>
                    {/* </Spin> */}
                </Col>
            </Row>
        </>
    )
}


const SurveyConnectivityAS2: FunctionComponent<ISurveyConnectivityInfoAS2> = (props) => {
    const intl = useIntl();
    const {TabPane} = Tabs;
    const [productionActive, setProductionActive] = useState(true);

    const surveyState = useAppSelector((state) => state.survey);

    const isViewMode = () => {
        return surveyState.survey.surveyMode === MODE.VIEW ? true : false
    }

    const isOverViewMode = () => {
        return surveyState.survey.surveyMode === MODE.OVERVIEW ? true : false
    }

    const isReadOnly = () => {
        return isViewMode() || isOverViewMode();
    }

    return (
        <>
            <Row>
                <Col>
                    <Text disabled={true}>GroupId: {props.groupId}</Text>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Typography>
                        <Paragraph>{intl.formatMessage({
                            id: "survey-connectivityInfo-as2-descriptionLine1",
                            defaultMessage: "You have selected AS2 as the communication channel."
                        })}</Paragraph>
                    </Typography>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Typography>
                        <Paragraph>{intl.formatMessage({
                            id: "survey-connectivityInfo-as2-descriptionLine2",
                            defaultMessage: "Please provide the required information for AS2 connectivity between you and our eInvoice service provider:"
                        })}</Paragraph>
                    </Typography>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Tabs defaultActiveKey={"1"} className="users-tabs ml20">
                        <TabPane tab={intl.formatMessage({
                            id: "survey-connectivityInfo-AS2-prereq-tab-title",
                            defaultMessage: "PRE-REQUISITES"
                        })}
                                 key="1" className="pl-5">
                            <AS2_PreRequisite onStatusUpdate={props.onStatusUpdate}/>
                        </TabPane>
                        <TabPane tab={intl.formatMessage({
                            id: "survey-connectivityInfo-as2-as2details-as2-section-title",
                            defaultMessage: "AS2 Details"
                        })}
                                 key="2" className="pl-5">
                            <div>
                                <Typography>
                                    <Paragraph>
                                        {intl.formatMessage({
                                            id: "survey-connectivityInfo-environment-prompt",
                                            defaultMessage: "Do you want to capture the PRODUCTION or TEST environment details?"
                                        })}
                                        <Switch className={"ml10"}
                                                checkedChildren={intl.formatMessage({
                                                    id: "survey-connectivityInfo-production-selection",
                                                    defaultMessage: "Production Detail"
                                                })}
                                                unCheckedChildren={intl.formatMessage({
                                                    id: "survey-as2-test-detail",
                                                    defaultMessage: "Test Detail"
                                                })}
                                                defaultChecked={true}
                                                onChange={setProductionActive}/>
                                    </Paragraph>
                                </Typography>
                            </div>
                            <AS2_Detail isProductionActive={productionActive} groupId={props.groupId}
                                        onStatusUpdate={props.onStatusUpdate}/>
                        </TabPane>
                        <TabPane tab={intl.formatMessage({
                            id: "survey-connectivityInfo-as2-alertsAndCertificate-title",
                            defaultMessage: "Alerts & Certificate Management"
                        })}
                                 key="3" className="pl-5">
                            <AS2_Certificates isProductionActive={productionActive} groupId={props.groupId}
                                              onStatusUpdate={props.onStatusUpdate}/>
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
        </>
    )
}

export default SurveyConnectivityAS2;
