import client from "../../../config/GraphQLApolloClient";
import { GET_ALL_DEFAULT_PROCESS_CHANNEL_FORMATS } from "./queries";
import {
  getDefaultChannelFormat_getDefaultChannelFormat

} from "./__generated__/getDefaultChannelFormat";
import { IntlShape } from "react-intl/src/types";

export enum SUPPLIER_DOC_TYPES {
  OUTGOING,
  INCOMING,
  PAYMENT_ADVICE
}

export const getFormats = (partnerType: string, companyCode: string, processCode: string, direction: string): Promise<getDefaultChannelFormat_getDefaultChannelFormat> => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: GET_ALL_DEFAULT_PROCESS_CHANNEL_FORMATS,
        variables: { partnerType, companyCode, processCode, direction },
        fetchPolicy: "network-only"
      }).then(response => {
      resolve(response.data && response.data.getDefaultChannelFormat);
    }).catch(error => reject(error));
  });
};


export const getFormatsChannels = (partnerType: string, companyCode: string, processCode: string, direction: string, selectedFormat: string): Promise<string[]> => {
  return new Promise((resolve, reject) => {
    const channels: string[] = [];
    client
      .query({
        query: GET_ALL_DEFAULT_PROCESS_CHANNEL_FORMATS,
        variables: { partnerType, companyCode, processCode, direction },
        fetchPolicy: "network-only"
      }).then(response => {
      response.data && response.data.getDefaultChannelFormat &&
      response.data.getDefaultChannelFormat.format.filter((item) => {
        if (item.formatName === selectedFormat) {
          item.channel.forEach(channelData => {
            channels.push(channelData.channelName);
          });
        }
      });

      resolve(channels);
    }).catch(error => reject(error));
  });
};

export const SURVEY_DOCUMENT_CHANNEL_TABLE_HEADERS = (intl: IntlShape) => [
  {
    headerName: intl.formatMessage({
      id: "survey-customer-channel-type-grid-header-processCode",
      defaultMessage: "Process Code"
    }),
    field: "processCode",
    width: 200
  },
  {
    headerName: intl.formatMessage({
      id: "survey-supplier-doc-type-tab-grid-header-process-code-description",
      defaultMessage: "Process Code Description"
    }),
    field: "processCodeName",
    width: 400
  },
  {
    headerName: intl.formatMessage({
      id: "survey-customer-channel-type-grid-header-format",
      defaultMessage: "Current Format"
    }),
    field: "format",
    colId: "format",
    width: 300
  },
  {
    headerName: intl.formatMessage({
      id: "survey-customer-channel-type-grid-header-channel",
      defaultMessage: "Current Channel"
    }),
    field: "channel",
    colId: "channel"
  }
  /*{
    headerName: intl.formatMessage({
      id: "survey-customer-channel-type-grid-header-updatedFormat",
      defaultMessage: "Updated Format"
    }),
    field: "updatedFormat",
    width: 300
  },
  {
    headerName: intl.formatMessage({
      id: "survey-customer-channel-type-grid-header-updatedChannel",
      defaultMessage: "Updated Channel"
    }),
    field: "updatedChannel"
  }*/
];

export const DEFAULT_COL_DEF = { flex: 1, minWidth: 100, sortable: true, filter: true };
export const SURVEY_DOCUMENT_TYPE_TABLE_HEADERS = (intl: IntlShape) => [
  {
    headerName: intl.formatMessage({
      id: "survey-supplier-doc-type-simple-grid-header-select",
      defaultMessage: "Select"
    }),
    field: "",
    colId: "",
    width: 150,
    checkboxSelection: true
  },
  {
    headerName: intl.formatMessage({
      id: "survey-supplier-doc-type-simple-grid-header-partnerno",
      defaultMessage: "Partner Number"
    }),
    field: "partnerNo",
    colId: "partnerNo"
  },

  {
    headerName: intl.formatMessage({
      id: "survey-supplier-doc-type-simple-grid-header-survey-id",
      defaultMessage: "Survey ID"
    }),
    field: "surveyId",
    colId: "surveyId"
  },

    {
      headerName: intl.formatMessage({
        id: "survey-supplier-doc-type-simple-grid-header-companyCode",
        defaultMessage: "Company Code"
      }),
      field: "companyCode",
      colId: "companyCode"
    },
    {
      headerName: intl.formatMessage({
        id: "survey-supplier-doc-type-simple-grid-header-partner-send",
        defaultMessage: "Partner sends documents to BMW"
      }),
      children: [
        {
          headerName: intl.formatMessage({
            id: "survey-supplier-doc-type-simple-grid-header-incoming-document",
            defaultMessage: "Incoming Documents"
          }),
          children: [
            {
              headerName: intl.formatMessage({
                id: "survey-supplier-doc-type-simple-grid-header-format",
                defaultMessage: "Current Format"
              }),
              field: "incomingFormat",
              colId: "incomingFormat"
            },
            {
              headerName: intl.formatMessage({
                id: "survey-supplier-doc-type-simple-grid-header-channel",
                defaultMessage: "Current Channel"
              }),
              field: "incomingChannel",
              colId: "incomingChannel"
            }

          ]
        }]
    },
    {
      headerName: intl.formatMessage({
        id: "survey-supplier-doc-type-simple-grid-header-bmw-send",
        defaultMessage: "BMW sends documents to Partner"
      }),
      children: [
        {
          headerName: intl.formatMessage({
            id: "survey-supplier-doc-type-simple-grid-header-outgoung-documents",
            defaultMessage: "Outgoing Documents"
          }),
          children: [
            {
              headerName: intl.formatMessage({
                id: "survey-supplier-doc-type-simple-grid-header-format",
                defaultMessage: "Current Format"
              }),
              field: "outgoingFormat",
              colId: "outgoingFormat"
            },
            {
              headerName: intl.formatMessage({
                id: "survey-supplier-doc-type-simple-grid-header-channel",
                defaultMessage: "Current Channel"
              }),
              field: "outgoingChannel",
              colId: "outgoingChannel"
            }
          ]
        },
        {
          headerName: intl.formatMessage({
            id: "survey-supplier-doc-type-simple-grid-header-payment-advices",
            defaultMessage: "Payment Advices"
          }),
          children: [

            {
              headerName: intl.formatMessage({
                id: "survey-supplier-doc-type-simple-grid-header-format",
                defaultMessage: "Current Format"
              }),
              field: "paymentAdviceFormat",
              colId: "paymentAdviceFormat"
            },
            {
              headerName: intl.formatMessage({
                id: "survey-supplier-doc-type-simple-grid-header-channel",
                defaultMessage: "Current Channel"
              }),
              field: "paymentAdviceChannel",
              colId: "paymentAdviceChannel"
            }
          ]
        }

      ]
    }
  ]


;


export const SURVEY_CUSTOMER_DOCUMENT_PARTNER_TABLE_HEADERS = (intl: IntlShape) => [
  {
    headerName: intl.formatMessage({
      id: "survey-customer-partner-grid-header-partnerno",
      defaultMessage: "Partner No"
    }),
    field: "partnerNo",
    colId: "partnerNo",
    width: 400
  },
  {
    headerName: intl.formatMessage({
      id: "survey-customer-partner-grid-header-partner-name",
      defaultMessage: "Partner Name"
    }),
    field: "partnerName",
    colId: "partnerName",
    width: 400
  },
  {
    headerName: intl.formatMessage({
      id: "survey-customer-partner-grid-header-company-name",
      defaultMessage: "Company Code"
    }),
    field: "companyCode",
    colId: "companyCode",
    width: 400
  }

];


export const processPaymentAdviceChannelData = [
  { processCodeDescription: "Payment Advice", format: "PDF", channel: "Portal" }];

export const processIncomingChannelData = [
  { processCodeDescription: "Incoming Invoices", format: "ECAP", channel: "PDF" }];

export function getDefinitionFormatChannelHelp() {
  const locale = localStorage.getItem("locale") || "en";
  return `https://www.einvoicing.bmwgroup.com/${locale}/document-transfer.html`
}