import React, { useEffect, useState } from 'react';
import { Form, Select, Spin } from 'antd';
import { useGetObjectClassListQuery } from './queries.generated';
import { message } from 'antd/lib';
import { ObjectClass } from 'src/__generated__/types';
import { useAppSelector } from '../../../main/hooks';
import { useDispatch } from 'react-redux';
import { setObjectClassUpdated } from './redux/IStoredEntitlementsSlice';

const key = 'objectClass';

const ObjectClassComponent = (props: any) => {
    const dispatch = useDispatch();
    const [objectClasses, setObjectClasses] = useState<ObjectClass[]>([]);
    const [refreshLoading, setRefreshLoading] = useState(false);
    const entitlementState = useAppSelector((state) => state.entitlements);

    const onObjectClassSelect = (value) => {
        // console.log('Selected Object Class:');
        // console.log(value);
        props.setObjectClassSelected(key,value);
    };

    const { data: objectClassData, loading: objectClassLoading, error: objectClassError, refetch: refetchData } = useGetObjectClassListQuery({
        variables: {
        },
      });

      if (objectClassError) message.error(objectClassError);

      useEffect(() => {
        if (entitlementState?.entitlementSchema?.ObjectClassUpdate && !objectClassLoading)
        {
            setRefreshLoading(true);
            refetchData().then(({data}) => {
                dispatch(setObjectClassUpdated(false));
                setObjectClasses([...data.getObjectClassList, { id: 99999999,userObjectClass: "ANY", description: "ANY Object Class" }]);
                setRefreshLoading(false);
            });
        }
        else
        if (objectClassData) {
            setObjectClasses([...objectClassData.getObjectClassList, { id: 99999999,userObjectClass: "ANY", description: "ANY Object Class" }]);
        }
    }, [objectClassLoading, objectClassData, entitlementState?.entitlementSchema?.ObjectClassUpdate]);


    //   if (!objectClassLoading && (!objectClasses || objectClasses.length ===0) && objectClassData )
    //   {
    //         setObjectClasses([...objectClassData.getObjectClassList, {userObjectClass:"ANY", description: "ANY Object Class"} ]);
    //   }

    return (
        <>
            <Spin spinning={objectClassLoading || refreshLoading}>
                <Form.Item
                    key="objectClassComponent"
                    name="objectClass"
                    label="Object Class"
                    rules={[{ required: props.required, message: 'Please select an Object Class' }]}
                >
                    <Select key={"objectClassSelect"} placeholder="Select Object Classes" allowClear={true} onSelect={onObjectClassSelect} mode={props?.selectType}
                        showSearch={true}>
                        {objectClasses && objectClasses?.map((objectClass) => (
                            <Select.Option
                                key={objectClass?.id?.toString()}
                                value={objectClass?.userObjectClass}
                            >
                                {objectClass?.description.concat(' (', objectClass?.userObjectClass, ')')}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Spin>
        </>
    );
};

export default ObjectClassComponent;
