import { IAppComponentProps } from "../../../components";
import React from "react";
import { Card, Col, Collapse, Form, Input, Row } from "antd";
import { getI18n } from "../../../utils/Utils";
import { FormattedMessage, useIntl } from "react-intl";
import { CaretRightOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../../main/hooks";
import { updateCollapsedState } from "../IStoredDocumentRecordSlice";

type SuppliersAddressDetailsProps = {} & IAppComponentProps;

const SupplierContactPersonDetails: React.FunctionComponent<SuppliersAddressDetailsProps> = (props) => {
  const intl = useIntl();
  const { document } = useAppSelector((state) => state.document);
  const dispatch = useAppDispatch();

  return (
    <>
      <Collapse
        defaultActiveKey={2}
        className={"ml2"}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        activeKey={document.collapsedState}
         onChange={(newActiveKeys) => {
          // otherwise panels couldn't be open/closed by click
          dispatch(updateCollapsedState(newActiveKeys));
}}
      >
        <Collapse.Panel key={4.2}
                        forceRender={true}
                        header={getI18n("supplierdetails-supplier-contact-person-panel-header"
                                                  ,"Supplier Contact Person", intl)}>
          <Card key={"supplierContactKey"} size={"small"}
                className={"ml-10"}>
            <Row gutter={24} justify={"start"}>
              <Col span={10}>
                <Form.Item name={["supplierDetails", "contactName"]}
                           label={getI18n("supplierdetails-supplier-contact-person-name"
                                                    ,"Name" ,intl)}>
                  <Input placeholder={intl.formatMessage({
                    id: "supplierdetails-supplier-contact-person-name",
                    defaultMessage: "Name"
                  })}
                         allowClear={true} />
                </Form.Item>
                <Form.Item name={["supplierDetails", "contactTelephone"]}
                           label={getI18n("supplierdetails-supplier-contact-person-telephone"
                                                    ,"Telephone", intl)}>
                  <Input placeholder={intl.formatMessage({
                    id: "supplierdetails-supplier-contact-person-telephone",
                    defaultMessage: "Telephone"
                  })}
                         allowClear={true} />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item name={["supplierDetails", "contactDepartment"]}
                           label={getI18n("supplierdetails-supplier-contact-person-department"
                                                    ,"Department", intl)}>
                  <Input placeholder={intl.formatMessage({
                    id: "supplierdetails-supplier-contact-person-department",
                    defaultMessage: "Department"
                  })}
                         allowClear={true} />
                </Form.Item>
                <Form.Item name={["supplierDetails", "contactEmail"]}
                           label={getI18n("supplierdetails-supplier-contact-person-email"
                                                    ,"E-Mail", intl)}>
                  <Input placeholder={intl.formatMessage({
                    id: "supplierdetails-supplier-contact-person-email",
                    defaultMessage: "E-Mail"
                  })}
                         allowClear={true} />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Collapse.Panel>
      </Collapse>
    </>
  );
};

export default SupplierContactPersonDetails;