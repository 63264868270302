import {FormInstance} from "antd";
import client from "../../../config/GraphQLApolloClient";
import {InfoPortalQueryApi, InfoPortalQueryApiVariables} from "../graphql/__generated__/InfoPortalQueryApi";
import {
    RETRIEVE_DOWNLOADED_REJECTION_LETTERS,
    RETRIEVE_INVOICES_REPORT,
    RETRIEVE_KPI_USAGE_AGGREGATE_STATS,
    RETRIEVE_REVALUATION_REPORT,
    SEARCH_FOR_CR1_REPORT,
    SEARCH_FOR_CZ_REPORT,
    SEARCH_FOR_LY5_REPORT,
    SEARCH_FOR_LY6_REPORT,
    SEARCH_FOR_LY7_REPORT,
    SEARCH_FOR_NR5_REPORT,
    SEARCH_FOR_SR1_REPORT,
    SEARCH_FOR_SR2_REPORT,
    SEARCH_FOR_SR4_REPORT,
    SEARCH_FOR_SR5_REPORT
} from "../graphql/queries";
import dayjs, {Dayjs} from 'dayjs';
import {
    CR1Filter,
    CZGenericFilter,
    InvoiceFilter,
    KPIAggregatesQuery,
    LY6Request,
    LY7Request, RevaluationFilter,
    SR1Request,
    SR2Request,
    SR4Request
} from "../../../../__generated__/globalTypes";
import {
    InfoPortalPaymentReportApi,
    InfoPortalPaymentReportApiVariables
} from "../graphql/__generated__/InfoPortalPaymentReportApi";
import {RetrieveInvoiceReport, RetrieveInvoiceReportVariables} from "../graphql/__generated__/RetrieveInvoiceReport";
import {
    InfoPortalBalancesNR5Api,
    InfoPortalBalancesNR5ApiVariables
} from "../graphql/__generated__/InfoPortalBalancesNR5Api";
import {CZQueryApi, CZQueryApiVariables} from "../graphql/__generated__/CZQueryApi";
import {
    DownloadedRejectionLetterReport,
    DownloadedRejectionLetterReportVariables
} from "../graphql/__generated__/DownloadedRejectionLetterReport";
import {
    InfoPortalFreightReport,
    InfoPortalFreightReportVariables
} from "../graphql/__generated__/InfoPortalFreightReport";
import {
    InfoPortalFixedCostReport,
    InfoPortalFixedCostReportVariables
} from "../graphql/__generated__/InfoPortalFixedCostReport";
import {
    GetInfoPortalAggregateUsageStats,
    GetInfoPortalAggregateUsageStatsVariables
} from "../graphql/__generated__/GetInfoPortalAggregateUsageStats";
import {IntlShape} from "react-intl/src/types";
import {groupByKeyMapper} from "../../../utils/Utils";
import {
    InfoPortalSupplierClaimsReport,
    InfoPortalSupplierClaimsReportVariables
} from "../graphql/__generated__/InfoPortalSupplierClaimsReport";
import {
    RetrieveRevaluationReport,
    RetrieveRevaluationReportVariables
} from "../graphql/__generated__/RetrieveRevaluationReport";
import {CORAPI, CORAPIVariables} from "../graphql/__generated__/CORAPI";

export function multipleSelectionMapper(multipleSelections: string[]) {
    if (multipleSelections) {
        const result = multipleSelections.map(selected => {
            const splitStrings = selected.split("\-");
            if (splitStrings.length > 1) {
                return splitStrings[1];
            }
            return selected;
        });

        return result.length > 0 ? result : undefined;
    }

    return undefined;
}

export function singleValueWrappedInArray(singleSelection: string | string[]) {
    if (singleSelection) {
        if (Array.isArray(singleSelection)) {
            return multipleSelectionMapper(singleSelection);
        }
        const splitStrings = singleSelection.split("\-");
        if (splitStrings.length > 1) {
            return [splitStrings[1]];
        }
        return [singleSelection];
    }

    return undefined;
}

export function dayjsToString(date: Dayjs, format: string) {
    if (date && format) {
        return date.format(format);
    }

    return undefined;
}

export function dateRangeToDayjsFromDate(dateRange) {
    if (dateRange && Array.isArray(dateRange)) {
        return dateRange[0];
    }

    return null;
}

export function dateRangeToDayjsToDate(dateRange) {
    if (dateRange && Array.isArray(dateRange)) {
        return dateRange[1];
    }

    return null;
}

export function operatorValue(value, operation: string) {
    if (operation) {
        if (value) {
            if (typeof value === 'string') {
                if (value.indexOf(',') > 0) {
                    return {
                        operation,
                        values: value.split(',').map(val => val.trim()),
                        length: 0
                    }
                }
            }

            return {
                operation,
                values: [`${value}`],
                length: 0
            };
        }
        else if (value === 0) {
            return {
                operation,
                values: [`${value}`],
                length: 0
            };
        }
        else if (operation === 'IN' || operation === 'IE') {
            return {
                operation,
                values: [],
                length: 0
            };
        }
    }

    return undefined;
}

export function retrieveSR3Report(form: FormInstance<any>) {
    const formFields = form.getFieldsValue();
    console.log('Form values', formFields);

    const language = localStorage.getItem("locale") || 'en';

    const invoiceFilter: InvoiceFilter = {
        language,
        compcode: multipleSelectionMapper(formFields["company"]),
        busarea: multipleSelectionMapper(formFields["businessAreaCode"]),
        partnerno: multipleSelectionMapper(formFields["partner"]),
        partnerinvno: operatorValue(formFields["partnerInvoiceNumber"], formFields["partnerInvoiceOperator"]),
        confirmationno: operatorValue(formFields["confirmationNumber"], formFields["confirmationNumberOperator"]),
        bmwdocno: operatorValue(formFields["bmwInvoiceNumber"], formFields["bmwInvoiceNumberOperator"]),
        docdateFrom: dayjsToString(dateRangeToDayjsFromDate(formFields["documentDate"]), "YYYY-MM-DD"),
        docdateUntil: dayjsToString(dateRangeToDayjsToDate(formFields["documentDate"]), "YYYY-MM-DD"),
        gross: operatorValue(formFields["grossAmount"], formFields["grossAmountOperator"]),
        doccurrency: singleValueWrappedInArray(formFields["currency"]),
        invprocess: singleValueWrappedInArray(formFields["invoiceProcess"]),
        invstat: multipleSelectionMapper(formFields['invoiceStatus']),
        invdirection: singleValueWrappedInArray(formFields["invoiceDirection"]),
        spaborderno: operatorValue(formFields["spabOrderNumber"], formFields["spabOrderNumberOperator"]),
        plantcode: multipleSelectionMapper(formFields["plantInfo"]),
        purchaseorderno: operatorValue(formFields["purchaseOrderNumber"], formFields["purchaseOrderNumberOperators"]),
        deliverynoteno: operatorValue(formFields["deliveryNoteNumber"], formFields["deliveryNoteNumberOperators"]),
        deliverynotedateFrom: dayjsToString(dateRangeToDayjsFromDate(formFields["deliveryNoteDate"]), "YYYY-MM-DD"),
        deliverynotedateUntil: dayjsToString(dateRangeToDayjsToDate(formFields["deliveryNoteDate"]), "YYYY-MM-DD")
    };

    const reportName = "SR3";
    return client.query<RetrieveInvoiceReport, RetrieveInvoiceReportVariables>({
        query: RETRIEVE_INVOICES_REPORT,
        context: {headers:{actionName:'SR3',partnerField:'invoiceFilter.partnerno'}},
        variables: {invoiceFilter, reportName},
        fetchPolicy: 'network-only'
    }).then(response => response.data.retrieveInvoicesReport);
}

export function retrieveSR3LightReport(form: FormInstance<any>) {
    const formFields = form.getFieldsValue();
    console.log('Form values', formFields);

    const language = localStorage.getItem("locale") || 'en';

    const invoiceFilter: InvoiceFilter = {
        language,
        compcode: multipleSelectionMapper(formFields["company"]),
        partnerno: multipleSelectionMapper(formFields["partner"]),
        docdateFrom: dayjsToString(dayjs().subtract(36, "months"), "YYYY-MM-DD"),
        docdateUntil: dayjsToString(dayjs(), "YYYY-MM-DD"),
        rejectiondateFrom: dayjsToString(dayjs().subtract(36, "months"), "YYYY-MM-DD"),
        rejectiondateUntil: dayjsToString(dayjs(), "YYYY-MM-DD"),
        invstat: ["60,26,17,16", "4"],
    };

    const reportName = "SR3";
    return client.query<RetrieveInvoiceReport, RetrieveInvoiceReportVariables>({
        query: RETRIEVE_INVOICES_REPORT,
        context: {headers:{actionName:'SR3',partnerField:'invoiceFilter.partnerno'}},
        variables: {invoiceFilter, reportName},
        fetchPolicy: 'network-only'
    }).then(response => response.data.retrieveInvoicesReport);
}

export function retrieveLY1Report(form: FormInstance<any>) {
    const formFields = form.getFieldsValue();
    console.log('Form values', formFields);

    const language = localStorage.getItem("locale") || 'en';

    const invoiceFilter: InvoiceFilter = {
        language,
        compcode: multipleSelectionMapper(formFields["company"]),
        partnerno: multipleSelectionMapper(formFields["partner"]),
        extinvoice: operatorValue(formFields["partnerInvoiceNumber"], formFields["partnerInvoiceOperator"]),
        invstat: singleValueWrappedInArray(formFields['invoiceStatus']),
        bmwdocno: operatorValue(formFields["bmwInvoiceNumber"], formFields["bmwInvoiceNumberOperator"]),
        docdateFrom: dayjsToString(dateRangeToDayjsFromDate(formFields["documentDate"]), "YYYY-MM-DD"),
        docdateUntil: dayjsToString(dateRangeToDayjsToDate(formFields["documentDate"]), "YYYY-MM-DD"),
        paymentdateFrom: dayjsToString(dateRangeToDayjsFromDate(formFields["paymentDate"]), "YYYY-MM-DD"),
        paymentdateUntil: dayjsToString(dateRangeToDayjsToDate(formFields["paymentDate"]), "YYYY-MM-DD"),
        totpaymamnt: operatorValue(formFields["paymentAmount"], formFields["paymentAmountOperator"]),
        paymadviceno: operatorValue(formFields["paymentAdviceNumber"], formFields["paymentAdviceNumberOperator"]),
    };

    const reportName = "LY1";
    return client.query<RetrieveInvoiceReport, RetrieveInvoiceReportVariables>({
        query: RETRIEVE_INVOICES_REPORT,
        context: {headers:{actionName:'LY1',partnerField:'invoiceFilter.partnerno'}},
        variables: {invoiceFilter, reportName},
        fetchPolicy: 'network-only'
    }).then(response => response.data.retrieveInvoicesReport);
}

export function retrieveLY2Report(form: FormInstance<any>) {
    const formFields = form.getFieldsValue();
    console.log('Form values', formFields);

    const language = localStorage.getItem("locale") || "en";

    const sr2Request:SR2Request = {
        compcode: multipleSelectionMapper(formFields["company"]),
        partnerno: multipleSelectionMapper(formFields["partner"]),
        status: singleValueWrappedInArray(formFields["status"]),
        deliverydateFrom: dayjsToString(dateRangeToDayjsFromDate(formFields["deliveryNoteDate"]), "YYYY-MM-DD"),
        deliverydateUntil: dayjsToString(dateRangeToDayjsToDate(formFields["deliveryNoteDate"]), "YYYY-MM-DD"),
        purchaseorderdateFrom: dayjsToString(dateRangeToDayjsFromDate(formFields["purchaseOrderDate"]), "YYYY-MM-DD"),
        purchaseorderdateUntil: dayjsToString(dateRangeToDayjsToDate(formFields["purchaseOrderDate"]), "YYYY-MM-DD"),
        grscdateFrom: dayjsToString(dateRangeToDayjsFromDate(formFields["grScDate"]), "YYYY-MM-DD"),
        grscdateUntil: dayjsToString(dateRangeToDayjsToDate(formFields["grScDate"]), "YYYY-MM-DD"),
        docdateFrom: dayjsToString(dateRangeToDayjsFromDate(formFields["documentDate"]), "YYYY-MM-DD"),
        docdateUntil: dayjsToString(dateRangeToDayjsToDate(formFields["documentDate"]), "YYYY-MM-DD"),
        partnerinvno: operatorValue(formFields["partnerInvoiceNumber"], formFields["partnerInvoiceOperator"]),
        settletype: singleValueWrappedInArray(formFields["settlementType"]),
        deliverynoteno: operatorValue(formFields["deliveryNoteNumber"], formFields["deliveryNoteNumberOperators"]),
        busarea: multipleSelectionMapper(formFields["businessAreaCode"]),
        bmwdocno: operatorValue(formFields["bmwInvoiceNumber"], formFields["bmwInvoiceNumberOperator"]),
        purchaseorderno: operatorValue(formFields["purchaseOrderNumber"], formFields["purchaseOrderNumberOperators"]),
        plantcode: multipleSelectionMapper(formFields["plantInfo"]),
        spaborderno: operatorValue(formFields["spabOrderNumber"], formFields["spabOrderNumberOperator"]),
        postingdateFrom: dayjsToString(dateRangeToDayjsFromDate(formFields["postingDeliveryDate"]), "YYYY-MM-DD"),
        postingdateUntil: dayjsToString(dateRangeToDayjsToDate(formFields["postingDeliveryDate"]), "YYYY-MM-DD"),
        overduestatus: formFields["status"] === "C"? undefined:singleValueWrappedInArray(formFields["overdueStatus"]),
        procurement: singleValueWrappedInArray(formFields["procurement"]),

        language
    };

    console.log(sr2Request);

    return client.query({
        query: SEARCH_FOR_SR2_REPORT,
        context: {headers:{actionName:'LY2',partnerField:'sr2Request.partnerno'}},
        variables: {sr2Request, reportName: "LY2"},
        fetchPolicy: "network-only"
    }).then(response => response.data.findSR2ReportResults);
}

export function retrieveLY4Report(form: FormInstance<any>) {
    const formFields = form.getFieldsValue();
    console.log('Form values', formFields);

    const language = localStorage.getItem("locale") || 'en';

    const revaluationFilter:RevaluationFilter = {
        compcode:multipleSelectionMapper(formFields["company"]),
        partnerno:multipleSelectionMapper(formFields["partner"]),
        purchaseordernumber:operatorValue(formFields["purchaseOrderNumber"], formFields["purchaseOrderNumberOperators"]),
        validitydateFrom:dayjsToString(dateRangeToDayjsFromDate(formFields["changedOnDate"]), "YYYY-MM-DD"),
        validitydateUntil:dayjsToString(dateRangeToDayjsToDate(formFields["changedOnDate"]), "YYYY-MM-DD"),
        status:singleValueWrappedInArray(formFields["status"]),
        overdueStatus:formFields["status"] === "C"? undefined: singleValueWrappedInArray(formFields["overdueStatus"]),
        revaltype:singleValueWrappedInArray(formFields["type"]),
        language
    };


    return client.query<RetrieveRevaluationReport,RetrieveRevaluationReportVariables>({
        query: RETRIEVE_REVALUATION_REPORT,
        context: {headers:{actionName:'LY4',partnerField:'revaluationFilter.partnerno'}},
        variables: {revaluationFilter},
        fetchPolicy: "network-only"
    }).then(response => response.data.findLY4Report);
}

export function retrieveLY5Report(form: FormInstance<any>) {
    const formFields = form.getFieldsValue();
    console.log('Form values', formFields);

    const language = localStorage.getItem("locale") || 'en';

    const supplierClaims:LY6Request = {
        compcode:multipleSelectionMapper(formFields["company"]),
        partnerno:multipleSelectionMapper(formFields["partner"]),
        purchaseOrderNumber:operatorValue(formFields["purchaseOrderNumber"], formFields["purchaseOrderNumberOperators"]),
        validityDateFrom:dayjsToString(dateRangeToDayjsFromDate(formFields["distributionDate"]), "YYYY-MM-DD"),
        validityDateUntil:dayjsToString(dateRangeToDayjsToDate(formFields["distributionDate"]), "YYYY-MM-DD"),
        status:singleValueWrappedInArray(formFields["status"]),
        overdueStatus:formFields["status"] === "C"? undefined: singleValueWrappedInArray(formFields["overdueStatus"]),
        language
    };


    return client.query<InfoPortalSupplierClaimsReport,InfoPortalSupplierClaimsReportVariables>({
        query: SEARCH_FOR_LY5_REPORT,
        context: {headers:{actionName:'LY5',partnerField:'supplierClaims.partnerno'}},
        variables: {supplierClaims},
        fetchPolicy: "network-only"
    }).then(response => response.data.findLY5Report);
}

export function retrieveLY6Report(form: FormInstance<any>) {
    const formFields = form.getFieldsValue();
    console.log('Form values', formFields);

    const language = localStorage.getItem("locale") || 'en';

    const ly6Request:LY6Request = {
        compcode:multipleSelectionMapper(formFields["company"]),
        partnerno:multipleSelectionMapper(formFields["partner"]),
        purchaseOrderNumber:operatorValue(formFields["purchaseOrderNumber"], formFields["purchaseOrderNumberOperators"]),
        validityDateFrom:dayjsToString(dateRangeToDayjsFromDate(formFields["createdDate"]), "YYYY-MM-DD"),
        validityDateUntil:dayjsToString(dateRangeToDayjsToDate(formFields["createdDate"]), "YYYY-MM-DD"),
        status:singleValueWrappedInArray(formFields["status"]),
        overdueStatus:formFields["status"] === "C"? undefined: singleValueWrappedInArray(formFields["overdueStatus"]),
        language
    };


    return client.query<InfoPortalFixedCostReport,InfoPortalFixedCostReportVariables>({
        query: SEARCH_FOR_LY6_REPORT,
        context: {headers:{actionName:'LY6',partnerField:'fixedCost.partnerno'}},
        variables: {fixedCost:ly6Request},
        fetchPolicy: "network-only"
    }).then(response => response.data.findLY6Report);
}

export function retrieveLY7Report(form: FormInstance<any>) {
    const formFields = form.getFieldsValue();
    console.log('Form values', formFields);

    const language = localStorage.getItem("locale") || 'en';

    const ly7Request:LY7Request = {
        compcode:multipleSelectionMapper(formFields["company"]),
        partnerno:multipleSelectionMapper(formFields["partner"]),
        purchaseOrderNumber:operatorValue(formFields["purchaseOrderNumber"], formFields["purchaseOrderNumberOperators"]),
        validityDateFrom:dayjsToString(dateRangeToDayjsFromDate(formFields["postingDeliveryDate"]), "YYYY-MM-DD"),
        validityDateUntil:dayjsToString(dateRangeToDayjsToDate(formFields["postingDeliveryDate"]), "YYYY-MM-DD"),
        status:singleValueWrappedInArray(formFields["status"]),
        language
    };


    return client.query<InfoPortalFreightReport,InfoPortalFreightReportVariables>({
        query: SEARCH_FOR_LY7_REPORT,
        context: {headers:{actionName:'LY7',partnerField:'freightRequest.partnerno'}},
        variables: {freightRequest:ly7Request},
        fetchPolicy: "network-only"
    }).then(response => response.data.findLY7Report);
}

export function retrieveSR1Report(form: FormInstance<any>) {
    const formFields = form.getFieldsValue();
    console.log('Form values', formFields);

    const language = localStorage.getItem("locale") || "en";

    const sr1Request: SR1Request = {
        compCode: multipleSelectionMapper(formFields["company"]),
        partnerNo: multipleSelectionMapper(formFields["partner"]),
        purchaseorderNo: operatorValue(formFields["purchaseOrderNumber"], formFields["purchaseOrderNumberOperators"]),
        busArea: multipleSelectionMapper(formFields["businessAreaCode"]),
        settleType: singleValueWrappedInArray(formFields["settlementType"]),
        plantCode: multipleSelectionMapper(formFields["plantInfo"]),
        invoiceStatus: singleValueWrappedInArray(formFields["invoiceStatus"]),
        deliveryStatus: singleValueWrappedInArray(formFields["deliveredStatus"]),
        invoicee: multipleSelectionMapper(formFields["invoicingParty"]),
        poDateFrom: dayjsToString(dateRangeToDayjsFromDate(formFields["purchaseOrderDate"]), "YYYY-MM-DD"),
        poDateUntil: dayjsToString(dateRangeToDayjsToDate(formFields["purchaseOrderDate"]), "YYYY-MM-DD"),
        language
    };

    return client.query({
        query: SEARCH_FOR_SR1_REPORT,
        context: {headers:{actionName:'SR1',partnerField:'sr1Request.partnerNo'}},
        variables: {sr1Request, reportName: "SR1"},
        fetchPolicy: "network-only"
    }).then(response => response.data.findSR1ReportResults);
}

export function retrieveSR1ReportForPOFlip(form: FormInstance<any>) {
    const formFields = form.getFieldsValue();
    console.log('Form values', formFields);

    const language = localStorage.getItem("locale") || "en";

    const selectedInvoiceStatuses = formFields["invoiceStatus"];
    const sr1Request: SR1Request = {
        compCode: singleValueWrappedInArray(formFields["company"]),
        partnerNo: [],
        invoicee: singleValueWrappedInArray(formFields["invoicee"]),
        purchaseorderNo: operatorValue(formFields["purchaseOrderNumber"], formFields["purchaseOrderNumberOperators"]),
        invoiceStatus: selectedInvoiceStatuses?.length ? multipleSelectionMapper(selectedInvoiceStatuses): ['RE1','RE2'],
        settleType: ['INVOICE'],
        poDateFrom: dayjsToString(dateRangeToDayjsFromDate(formFields["purchaseOrderDate"]), "YYYY-MM-DD"),
        poDateUntil: dayjsToString(dateRangeToDayjsToDate(formFields["purchaseOrderDate"]), "YYYY-MM-DD"),
        language
    };

    return client.query({
        query: SEARCH_FOR_SR1_REPORT,
        context: {headers:{actionName:'SR100',partnerField:'sr1Request.partnerNo'}},
        variables: {sr1Request, reportName: "SR100"},
        fetchPolicy: "network-only"
    }).then(response => response.data.findSR1ReportResults);
}

export function retrieveNR1Report(form: FormInstance<any>) {
    const formFields = form.getFieldsValue();
    console.log('Form values', formFields);

    const language = localStorage.getItem("locale") || "en";

    const sr1Request: SR1Request = {
        compCode: multipleSelectionMapper(formFields["company"]),
        partnerNo: multipleSelectionMapper(formFields["partner"]),
        purchaseorderNo: operatorValue(formFields["purchaseOrderNumber"], formFields["purchaseOrderNumberOperators"]),
        busArea: multipleSelectionMapper(formFields["businessAreaCode"]),
        plantCode: multipleSelectionMapper(formFields["plantInfo"]),
        invoiceStatus: singleValueWrappedInArray(formFields["invoiceStatus"]),
        deliveryStatus: singleValueWrappedInArray(formFields["deliveredStatus"]),
        invoicee: multipleSelectionMapper(formFields["invoicingParty"]),
        poDateFrom: dayjsToString(dateRangeToDayjsFromDate(formFields["purchaseOrderDate"]), "YYYY-MM-DD"),
        poDateUntil: dayjsToString(dateRangeToDayjsToDate(formFields["purchaseOrderDate"]), "YYYY-MM-DD"),
        language
    };

    return client.query({
        query: SEARCH_FOR_SR1_REPORT,
        context: {headers:{actionName:'NR1',partnerField:'sr1Request.partnerNo'}},
        variables: {sr1Request, reportName: "NR1"},
        fetchPolicy: "network-only"
    }).then(response => response.data.findSR1ReportResults);
}

export function retrieveSR4Report(form: FormInstance<any>) {
    const formFields = form.getFieldsValue();
    console.log('Form values', formFields);
    const language = localStorage.getItem("locale") || 'en';
    const reportType = "SR4";

    const sr4Request: SR4Request = {
        bmwdocno: operatorValue(formFields["bmwInvoiceNumber"], formFields["bmwInvoiceNumberOperator"]),
        compcode: multipleSelectionMapper(formFields["company"]),
        doccurrency: singleValueWrappedInArray(formFields["currency"]),
        documentdateFrom: dayjsToString(dateRangeToDayjsFromDate(formFields["documentDate"]), "YYYY-MM-DD"),
        documentdateUntil: dayjsToString(dateRangeToDayjsToDate(formFields["documentDate"]), "YYYY-MM-DD"),
        extinvoice: operatorValue(formFields["partnerInvoiceNumber"], formFields["partnerInvoiceOperator"]),
        language,
        partnerno: multipleSelectionMapper(formFields["partner"]),
        paymadviceno: operatorValue(formFields["paymentAdviceNumber"], formFields["paymentAdviceNumberOperator"]),
        paymdateFrom: dayjsToString(dateRangeToDayjsFromDate(formFields["paymentDate"]), "YYYY-MM-DD"),
        paymdateUntil: dayjsToString(dateRangeToDayjsToDate(formFields["paymentDate"]), "YYYY-MM-DD"),
        settlementtype: singleValueWrappedInArray(formFields["settlementType"]),
        spaborderno: operatorValue(formFields["spabOrderNumber"], formFields["spabOrderNumberOperator"]),
        totpaymamnt: operatorValue(formFields["paymentAmount"], formFields["paymentAmountOperator"])
    };

    return client.query<InfoPortalPaymentReportApi, InfoPortalPaymentReportApiVariables>({
        query: SEARCH_FOR_SR4_REPORT,
        context: {headers:{actionName:'SR4',partnerField:'sr4Request.partnerno'}},
        variables: {sr4Request, reportType},
        fetchPolicy: "network-only"
    }).then(response => response.data.findPaymentReportResults);
}

export function retrieveNR4Report(form: FormInstance<any>) {
    const formFields = form.getFieldsValue();
    console.log('Form values', formFields);
    const language = localStorage.getItem("locale") || 'en';
    const reportType = "NR4";

    const sr4Request: SR4Request = {
        bmwdocno: operatorValue(formFields["bmwInvoiceNumber"], formFields["bmwInvoiceNumberOperator"]),
        compcode: multipleSelectionMapper(formFields["company"]),
        doccurrency: singleValueWrappedInArray(formFields["currency"]),
        documentdateFrom: dayjsToString(dateRangeToDayjsFromDate(formFields["documentDate"]), "YYYY-MM-DD"),
        documentdateUntil: dayjsToString(dateRangeToDayjsToDate(formFields["documentDate"]), "YYYY-MM-DD"),
        extinvoice: operatorValue(formFields["partnerInvoiceNumber"], formFields["partnerInvoiceOperator"]),
        language,
        partnerno: multipleSelectionMapper(formFields["partner"]),
        paymadviceno: operatorValue(formFields["paymentAdviceNumber"], formFields["paymentAdviceNumberOperator"]),
        paymdateFrom: dayjsToString(dateRangeToDayjsFromDate(formFields["paymentDate"]), "YYYY-MM-DD"),
        paymdateUntil: dayjsToString(dateRangeToDayjsToDate(formFields["paymentDate"]), "YYYY-MM-DD"),
        settlementtype: singleValueWrappedInArray(formFields["settlementType"]),
        spaborderno: operatorValue(formFields["spabOrderNumber"], formFields["spabOrderNumberOperator"]),
        totpaymamnt: operatorValue(formFields["paymentAmount"], formFields["paymentAmountOperator"])
    };

    return client.query<InfoPortalPaymentReportApi, InfoPortalPaymentReportApiVariables>({
        query: SEARCH_FOR_SR4_REPORT,
        context: {headers:{actionName:'NR4',partnerField:'sr4Request.partnerno'}},
        variables: {sr4Request, reportType},
        fetchPolicy: "network-only"
    }).then(response => response.data.findPaymentReportResults);
}

export function retrieveSR5Report(form: FormInstance<any>, currentUser: any, distinctEntitlements: string[]) {
    const formFields = form.getFieldsValue();
    console.log('Form values', formFields);

    const language = localStorage.getItem("locale") || 'en';

    const sr5Request = {
        compcode: multipleSelectionMapper(formFields['company']),
        doccurrency: singleValueWrappedInArray(formFields['currency']),
        keydate: dayjsToString(formFields['keyDate'], 'YYYY-MM-DD'),
        partnerno: multipleSelectionMapper(formFields['partner']),
        language
    };

    return client.query<InfoPortalQueryApi, InfoPortalQueryApiVariables>({
        query: SEARCH_FOR_SR5_REPORT,
        variables: {sr5Request},
        context: {headers:{actionName:'SR5',partnerField:'sr5Request.partnerno'}},
        fetchPolicy: "network-only"
    }).then(response => response.data.findSR5ReportResults);
}

export function retrieveNR3Report(form: FormInstance<any>) {
    const formFields = form.getFieldsValue();
    console.log('Form values', formFields);

    const language = localStorage.getItem("locale") || 'en';

    const invoiceFilter: InvoiceFilter = {
        language,
        compcode: multipleSelectionMapper(formFields["company"]),
        busarea: multipleSelectionMapper(formFields["businessAreaCode"]),
        partnerno: multipleSelectionMapper(formFields["partner"]),
        partnerinvno: operatorValue(formFields["partnerInvoiceNumber"], formFields["partnerInvoiceOperator"]),
        confirmationno: operatorValue(formFields["confirmationNumber"], formFields["confirmationNumberOperator"]),
        bmwdocno: operatorValue(formFields["bmwInvoiceNumber"], formFields["bmwInvoiceNumberOperator"]),
        docdateFrom: dayjsToString(dateRangeToDayjsFromDate(formFields["documentDate"]), "YYYY-MM-DD"),
        docdateUntil: dayjsToString(dateRangeToDayjsToDate(formFields["documentDate"]), "YYYY-MM-DD"),
        gross: operatorValue(formFields["grossAmount"], formFields["grossAmountOperator"]),
        doccurrency: singleValueWrappedInArray(formFields["currency"]),
        invprocess: singleValueWrappedInArray(formFields["invoiceProcess"]),
        invstat: singleValueWrappedInArray(formFields['invoiceStatus']),
        invdirection: singleValueWrappedInArray(formFields["invoiceDirection"]),
        spaborderno: operatorValue(formFields["spabOrderNumber"], formFields["spabOrderNumberOperator"]),
        plantcode: multipleSelectionMapper(formFields["plantInfo"]),
        purchaseorderno: operatorValue(formFields["purchaseOrderNumber"], formFields["purchaseOrderNumberOperators"]),
        deliverynoteno: operatorValue(formFields["deliveryNoteNumber"], formFields["deliveryNoteNumberOperators"]),
        deliverynotedateFrom: dayjsToString(dateRangeToDayjsFromDate(formFields["deliveryNoteDate"]), "YYYY-MM-DD"),
        deliverynotedateUntil: dayjsToString(dateRangeToDayjsToDate(formFields["deliveryNoteDate"]), "YYYY-MM-DD")
    };

    const reportName = "NR3";
    return client.query<RetrieveInvoiceReport, RetrieveInvoiceReportVariables>({
        query: RETRIEVE_INVOICES_REPORT,
        context: {headers:{actionName:'NR3',partnerField:'invoiceFilter.partnerno'}},
        variables: {invoiceFilter, reportName},
        fetchPolicy: 'network-only'
    }).then(response => response.data.retrieveInvoicesReport);
}

export function retrieveNR5Report(form: FormInstance<any>, currentUser: any, distinctEntitlements: string[]) {
    const formFields = form.getFieldsValue();
    console.log('Form values', formFields);

    const language = localStorage.getItem("locale") || 'en';

    const nr5Request = {
        compcode: multipleSelectionMapper(formFields['company']),
        doccurrency: singleValueWrappedInArray(formFields['currency']),
        keydate: dayjsToString(formFields['keyDate'], 'YYYY-MM-DD'),
        partnerno: multipleSelectionMapper(formFields['partner']),
        language
    };

    return client.query<InfoPortalBalancesNR5Api, InfoPortalBalancesNR5ApiVariables>({
        query: SEARCH_FOR_NR5_REPORT,
        variables: {nr5Request},
        context: {headers:{actionName:'NR5',partnerField:'nr5Request.partnerno'}},
        fetchPolicy: "network-only"
    }).then(response => response.data.findNR5ReportResults);
}

export function retrieveCZ1Report(form: FormInstance<any>) {
    const formFields = form.getFieldsValue();
    console.log('Form values', formFields);

    const shouldSendFapiao = ()=> {
        // eslint-disable-next-line eqeqeq
        return formFields['invoiceStatus']  != 1
    }

    const language = localStorage.getItem("locale") || "en";
    const cz1Request: CZGenericFilter = {
        logic: null,
        compcode: multipleSelectionMapper(formFields['company']),
        partnerno: multipleSelectionMapper(formFields['partner']),
        ponumber: operatorValue(formFields['purchaseOrderNumber'], formFields['purchaseOrderNumberOperators']),
        czinvoicestatus: singleValueWrappedInArray(formFields['invoiceStatus']),
        bmwdocno: operatorValue(formFields['bmwInvoiceNumber'], formFields['bmwInvoiceNumberOperator']),
        deliverynote: operatorValue(formFields['deliveryNoteNumber'], formFields['deliveryNoteNumberOperators']),
        documentdateFrom: dayjsToString(dateRangeToDayjsFromDate(formFields["documentDate"]), "YYYY-MM-DD"),
        documentdateUntil: dayjsToString(dateRangeToDayjsToDate(formFields["documentDate"]), "YYYY-MM-DD"),
        supplierclaimno: null,
        grdateFrom: dayjsToString(dateRangeToDayjsFromDate(formFields["grScDate"]), "YYYY-MM-DD"),
        grdateUntil: dayjsToString(dateRangeToDayjsToDate(formFields["grScDate"]), "YYYY-MM-DD"),
        fapiaono: !shouldSendFapiao()? undefined: operatorValue(formFields['fapiaoNumber'], formFields['fapiaoNumberOperator']),
        plantcode:singleValueWrappedInArray(formFields['plantInfo']),
        documenttype: null,
        language
    };

    return client.query<CZQueryApi, CZQueryApiVariables>({
        query: SEARCH_FOR_CZ_REPORT,
        context: {headers:{actionName:'CZ1',partnerField:'czRequest.partnerno'}},
        variables: {czRequest:cz1Request,report:"CZ1"},
        fetchPolicy: 'network-only'
    }).then(response => response.data.findCZReport);
}

export function retrieveCZ2Report(form: FormInstance<any>) {
    const formFields = form.getFieldsValue();
    console.log('Form values', formFields);

    const language = localStorage.getItem("locale") || "en";

    const shouldSendFapiao = ()=> {
        // eslint-disable-next-line eqeqeq
        return formFields['invoiceStatus']  != 1
    }

    const cz1Request: CZGenericFilter = {
        logic: null,
        compcode: multipleSelectionMapper(formFields['company']),
        partnerno: multipleSelectionMapper(formFields['partner']),
        ponumber: operatorValue(formFields['purchaseOrderNumber'], formFields['purchaseOrderNumberOperators']),
        czinvoicestatus: singleValueWrappedInArray(formFields['invoiceStatus']),
        bmwdocno: operatorValue(formFields['bmwInvoiceNumber'], formFields['bmwInvoiceNumberOperator']),
        deliverynote: operatorValue(formFields['deliveryNoteNumber'], formFields['deliveryNoteNumberOperators']),
        documentdateFrom: dayjsToString(dateRangeToDayjsFromDate(formFields["documentDate"]), "YYYY-MM-DD"),
        documentdateUntil: dayjsToString(dateRangeToDayjsToDate(formFields["documentDate"]), "YYYY-MM-DD"),
        supplierclaimno: operatorValue(formFields['supplierClaimNumber'], formFields['supplierClaimNumberOperator']),
        grdateFrom: dayjsToString(dateRangeToDayjsFromDate(formFields["grScDate"]), "YYYY-MM-DD"),
        grdateUntil: dayjsToString(dateRangeToDayjsToDate(formFields["grScDate"]), "YYYY-MM-DD"),
        fapiaono: !shouldSendFapiao()? undefined: operatorValue(formFields['fapiaoNumber'], formFields['fapiaoNumberOperator']),
        plantcode:singleValueWrappedInArray(formFields['plantInfo']),
        documenttype: null,
        language
    };

    return client.query<CZQueryApi, CZQueryApiVariables>({
        query: SEARCH_FOR_CZ_REPORT,
        context: {headers:{actionName:'CZ2',partnerField:'czRequest.partnerno'}},
        variables: {czRequest:cz1Request,report:"CZ2"},
        fetchPolicy: 'network-only'
    }).then(response => response.data.findCZReport);
}

export function retrieveCZ3Report(form: FormInstance<any>) {
    const formFields = form.getFieldsValue();
    console.log('Form values', formFields);

    const language = localStorage.getItem("locale") || "en";

    const shouldSendFapiao = ()=> {
        // eslint-disable-next-line eqeqeq
        return formFields['invoiceStatus']  != 1
    }

    const cz1Request: CZGenericFilter = {
        logic: null,
        compcode: multipleSelectionMapper(formFields['company']),
        partnerno: multipleSelectionMapper(formFields['partner']),
        ponumber: operatorValue(formFields['purchaseOrderNumber'], formFields['purchaseOrderNumberOperators']),
        czinvoicestatus: singleValueWrappedInArray(formFields['invoiceStatus']),
        bmwdocno: operatorValue(formFields['bmwInvoiceNumber'], formFields['bmwInvoiceNumberOperator']),
        deliverynote: operatorValue(formFields['deliveryNoteNumber'], formFields['deliveryNoteNumberOperators']),
        documentdateFrom: dayjsToString(dateRangeToDayjsFromDate(formFields["documentDate"]), "YYYY-MM-DD"),
        documentdateUntil: dayjsToString(dateRangeToDayjsToDate(formFields["documentDate"]), "YYYY-MM-DD"),
        supplierclaimno: null,
        grdateFrom: dayjsToString(dateRangeToDayjsFromDate(formFields["grScDate"]), "YYYY-MM-DD"),
        grdateUntil: dayjsToString(dateRangeToDayjsToDate(formFields["grScDate"]), "YYYY-MM-DD"),
        fapiaono: !shouldSendFapiao()? undefined: operatorValue(formFields['fapiaoNumber'], formFields['fapiaoNumberOperator']),
        plantcode:singleValueWrappedInArray(formFields['plantInfo']),
        documenttype: null,
        language
    };

    return client.query<CZQueryApi, CZQueryApiVariables>({
        query: SEARCH_FOR_CZ_REPORT,
        context: {headers:{actionName:'CZ3',partnerField:'czRequest.partnerno'}},
        variables: {czRequest:cz1Request,report:"CZ3"},
        fetchPolicy: 'network-only'
    }).then(response => response.data.findCZReport);
}

export function retrieveDownloadedRejectionLetters(form: FormInstance<any>) {
    const formFields = form.getFieldsValue();
    console.log('Form values', formFields);

    const language = localStorage.getItem("locale") || 'en';

    const invoiceFilter: InvoiceFilter = {
        language,
        compcode: multipleSelectionMapper(formFields["company"]),
        partnerno: multipleSelectionMapper(formFields["partner"]),
        bmwdocno: operatorValue(formFields["bmwInvoiceNumber"], formFields["bmwInvoiceNumberOperator"]),
        rejectiondateFrom: dayjsToString(dateRangeToDayjsFromDate(formFields["rejectionDate"]), "YYYY-MM-DD"),
        rejectiondateUntil: dayjsToString(dateRangeToDayjsToDate(formFields["rejectionDate"]), "YYYY-MM-DD"),
    };
    
    return client.query<DownloadedRejectionLetterReport, DownloadedRejectionLetterReportVariables>({
        query: RETRIEVE_DOWNLOADED_REJECTION_LETTERS,
        variables: {invoiceFilter},
        fetchPolicy: 'network-only'
    }).then(response => response.data.getDownloadedRejectionLetters);
}

export function retrieveKPIUsage(form: FormInstance<any>, intl:IntlShape) {
    const formFields = form.getFieldsValue();
    console.log('Form values', formFields);

    const matcher = /^[a-z]{2}[0-9](Selection)$/
    const reports = [];
    for (const key in formFields) {
        if (matcher.test(key)) {
          if (formFields[key]) {
              reports.push(key.substring(0,3));
          }
        }
    }

    const newQuery:KPIAggregatesQuery = {
        reports,
        fromDate: dayjsToString(dateRangeToDayjsFromDate(formFields["executionDate"]), "YYYY-MM-DD"),
        toDate: dayjsToString(dateRangeToDayjsToDate(formFields["executionDate"]), "YYYY-MM-DD"),
    };

    const nameMapper = {
        'SR3':`SR3 - ${intl.formatMessage({id:'report-type-sr3-label' })}`,
        'SR1':`SR1 - ${intl.formatMessage({id:'report-type-sr1-label' })}`,
        'SR2':`SR2 - ${intl.formatMessage({id:'report-type-sr2-label' })}`,
        'SR4':`SR4 - ${intl.formatMessage({id:'report-type-sr4-label' })}`,
        'SR5':`SR5 - ${intl.formatMessage({id:'report-type-sr5-label' })}`,
        'LY1':`LY1 - ${intl.formatMessage({id:'report-type-ly1-label' })}`,
        'LY2':`LY2 - ${intl.formatMessage({id:'report-type-ly2-label' })}`,
        'LY4':`LY4 - ${intl.formatMessage({id:'report-type-ly4-label' })}`,
        'LY5':`LY5 - ${intl.formatMessage({id:'report-type-ly5-label' })}`,
        'LY6':`LY6 - ${intl.formatMessage({id:'report-type-ly6-label' })}`,
        'LY7':`LY7 - ${intl.formatMessage({id:'report-type-ly7-label' })}`,
        'CZ1':`CZ1 - ${intl.formatMessage({id:'report-type-cz1-label' })}`,
        'CZ2':`CZ2 - ${intl.formatMessage({id:'report-type-cz2-label' })}`,
        'CZ3':`CZ3 - ${intl.formatMessage({id:'report-type-cz3-label' })}`,
        'NR1':`NR1 - ${intl.formatMessage({id:'report-type-sr1-label' })}`,
        'NR3':`NR3 - ${intl.formatMessage({id:'report-type-sr3-label' })}`,
        'NR4':`NR4 - ${intl.formatMessage({id:'report-type-sr4-label' })}`,
        'NR5':`NR5 - ${intl.formatMessage({id:'report-type-sr5-label' })}`
    };

    type PartnerCountGroupedByReportName = {reportName:string,partnerNo:string,count:number,dayOfYear:string};

    return client.query<GetInfoPortalAggregateUsageStats, GetInfoPortalAggregateUsageStatsVariables>({
        query: RETRIEVE_KPI_USAGE_AGGREGATE_STATS,
        variables: {stats:newQuery},
        fetchPolicy: 'network-only'
    }).then(response => response.data.getKPIUsageForInfoPortal).then(serviceResult=> {
        const reportCountsGroupedByName:Record<string,PartnerCountGroupedByReportName[]> =
            groupByKeyMapper(
                serviceResult, item=>item.reportName, item=> {
                    return {reportName:item.reportName,partnerNo:item.partnerName,count:item.count,dayOfYear:item.dayOfYear}
                });

        const result = [];

        for (const key in reportCountsGroupedByName) {
            const reportNameAggregates:PartnerCountGroupedByReportName[] = reportCountsGroupedByName[key];
            const total =
                reportNameAggregates.reduce(
                    (sumTotal:number,currentVal:PartnerCountGroupedByReportName)=>sumTotal+currentVal.count,0);


            const rowRecord = {report: nameMapper[key], positions:reportNameAggregates,count:total};

            result.push(rowRecord);
        }

        return result;
    });
}

export function retrieveCR1Report(form: FormInstance<any>) {
    const formFields = form.getFieldsValue();
    console.log('Form values', formFields);

    const language = localStorage.getItem("locale") || 'en';

    const confirmationOfArrivalFilter: CR1Filter = {
        language,
        compcode: singleValueWrappedInArray(formFields["company"]),
        partnerno: singleValueWrappedInArray(formFields["partner"]),
        grscdate: dayjsToString(formFields["grScDate"], "YYYY-MM-DD"),
    };

    return client.query<CORAPI, CORAPIVariables>({
        query: SEARCH_FOR_CR1_REPORT,
        context: {headers:{actionName:'CR1',partnerField:'cr1Query.partnerno'}},
        variables: {cr1Query:confirmationOfArrivalFilter},
        fetchPolicy: 'network-only'
    }).then(result=> {
        return result.data.findCR1ReportResults
    });
}

export function retrieveTR1Report(form:FormInstance<any>) {
    const formFields = form.getFieldsValue();
    console.log('Form values', formFields);

    return Promise.resolve([
        {
            "bmwCompany": "Germany",
            "documentNumber": "123456",
            "documentDate": "2023-10-01",
            "dealerNumber": "7890",
            "dealerName": "Autohaus Schmidt",
            "partnerSalesPartner": "Vertriebspartner GmbH",
            "vinNumber": "WBA12345678901234",
            "handOverDateTransferOfRisk": "2023-10-05",
            "brand": "BMW",
            "modelCode": "X5",
            "commissionType": "Standard",
            "recipient": "Herr Müller",
            "salesCategory": "Retail",
            "type": "Sale",
            "currency": "EUR",
            "paymentType": "Credit Card",
            "baseValue": 50000,
            "percentage": 10,
            "netValuePaidExclVAT": 45000,
            "grossValuePaidInclVAT": 53550
        }
    ]);
}


