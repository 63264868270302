import React, { FunctionComponent } from 'react'
import { Card, Radio } from 'antd'
import { Icon as LegacyIcon } from '@ant-design/compatible'

export const PortalCard: FunctionComponent<{ value; iconName; description }> = ({ value, iconName, description }) => (
    <Card bordered={true} hoverable={true} className="ant-card-custom">
        <div>
            <Radio value={value} className="radio-card-custom"/>
        </div>
        <div className="request-blocks">
            <LegacyIcon
                component={() => (
                    <svg viewBox="0 0 40 40" className="img-fluid mb2">
                        <image href={`../images/${iconName}`} className="scaling-svg"/>
                    </svg>
                )}
            />
        </div>
        <div className="request-description">{description}</div>
    </Card>
)
