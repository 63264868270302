import {IAppComponentProps} from "../../components";
import React, {useEffect, useState} from "react";
import {AgGridReact} from "ag-grid-react";
import {INCOMING_DOCS_DETAILS_COLS} from "./utils";
import {GridApi} from "ag-grid-community";
import client from "../../config/GraphQLApolloClient";
import {INCOMING_DOC_DETAILS_DATA} from "./queries";
import { showErrorNotification } from "../Notification";
import { Button, Col, Row, Spin } from "antd";
import { useIntl } from "react-intl";

export interface IProps extends IAppComponentProps {
  selectedProcessError: string;
  loading: boolean;
  isLoading: any;
  onGridReady: any;
}

const DocumentDetailComponent: React.FunctionComponent<IProps> = ({
                                                                    selectedProcessError,
                                                                    isLoading,
                                                                    onGridReady
                                                                  }: IProps) => {
    const  [incomingDocsDetailsData, setIncomingDocsDetailsData] = useState(undefined);
    const [detailGridApi, setDetailGridApi] = useState<GridApi>(undefined);
    const intl = useIntl();
  //  const [loading, isLoading] = useState(false);

    useEffect(() => {
        if (selectedProcessError !== undefined) {
            isLoading(true);
            client.query({
                query: INCOMING_DOC_DETAILS_DATA,
                fetchPolicy: "cache-first",
                variables: {processError: selectedProcessError}
            })
                .then(response => {
                    setIncomingDocsDetailsData(response.data.getIncomingDocuments);
                    isLoading(false);
                })
                .catch((error) => {
                    isLoading(false);
                    showErrorNotification(`${error}`);
                });
        }
    }, [selectedProcessError]);


    return(
            <AgGridReact
              defaultColDef={{
                    enableValue: true,
                    sortable: true,
                    resizable: true
                }}
              columnDefs={INCOMING_DOCS_DETAILS_COLS(intl)}
              rowData={incomingDocsDetailsData === undefined ? [] : incomingDocsDetailsData}

              sideBar={false}
              rowClass="agGridRows"
              suppressMenuHide={true}
              debug={false}
              pagination={true}
              paginationPageSize={15}
              suppressRowClickSelection={true}
              onGridReady={onGridReady}
            />
    );
};
export default DocumentDetailComponent;