import React from "react";
import "./index.css";
import { Alert, Button, Col, Row, Space } from "antd";
import { generateNonce, getWeEAMUrl } from "../main/OauthUtils";


type LogOutLayoutState = {
  isReady: boolean;
  oAuthUrl: string;
  error: string;
  authenticated: boolean;
};

class LogOutLayout extends React.Component<LogOutLayoutState> {
  state: LogOutLayoutState = {
    isReady: false,
    oAuthUrl: undefined,
    error: undefined,
    authenticated: false
  };

  componentDidMount() {
    Promise.resolve(getWeEAMUrl()).then((url) => {
        this.setState({
          isReady: true,
          oAuthUrl: url
        });
      }
    )
      .catch((err) => {
        this.setState({
          isReady: true,
          error: err
        });
      });

  }

  oauthLogin() {
    window.location.assign(`${this.state.oAuthUrl}&nonce=${generateNonce("xxx")}`);
  }

  render() {
    return (
      <div className="content">
        <Row gutter={16}>
          <Col className="gutter-row" style={{ marginLeft: "5px" }}>
            <div
              style={{ textAlign: "center", width: "1000px", height: "300px", marginLeft: "5px" }}>
              <div className="title-header-card-text">
                eDocument Portal
              </div>
              <div className="title-header-card-text">
                {sessionStorage.getItem("sessionExpired") === "true"
                ?
                  <Alert showIcon={true} message={"Session expired"}
                    description="Your session expired, click the button to login again"
                    type="success"
                  />
                :
                  <Alert showIcon={true} message={"Successfully Logout"}
                    description="You have successfully logout, click the button to login again"
                    type="success"
                  />
                }


              </div>
              <Row gutter={8}>
                <Col xs={24} sm={6} md={4}>
                  <Button type="primary" size="large" className="w-100 mt10" onClick={() => this.oauthLogin()}>
                    LOGIN
                  </Button>
                </Col>
              </Row>

            </div>
          </Col>
        </Row>
      </div>

    );
  }
}

export default LogOutLayout;
