import React from 'react'
import { WrappedComponentProps } from 'react-intl'
import { getUserEntitlementResultByUserId_getUserEntitlementResultByUserId_partners } from 'src/main/__generated__/getUserEntitlementResultByUserId';

export const ApiContext = React.createContext({ currentUser: null })

export type IAppComponentProps = { currentUser?: any; distinctEntitlements?: string[],entitlementPartner?:getUserEntitlementResultByUserId_getUserEntitlementResultByUserId_partners[] , children?: any } & WrappedComponentProps;

export type TokenInfoSummary = {
    access_token: string;
    id_token: string;
    token_type: string;
    expires_in: number;
};

export type UserModelState = {
    currentUser?: PortalUser;
};

export type PortalUser = {
  _id?: number;
  user_id?: number;
  username?: string;
  firstname?: string;
  lastname?: string;
  givenName?: string;
  department?: string;
  email?: string;
  title?: string;
  role?: string;
  roleName?: string;
  role_id?: number;
  active?: boolean;
  displayName?: string;
  uid?: string;
  userSettingsId?: number;
  dateFormat?: string;
  thousandSeparator?: string;
  decimalSeparator?: string;
  precision?: number;
  language?:string;
};

export type UserProcess = {
    _id?: number;
    processName?: string;
    processDescription?: string;
};
export type UserPartner = {
    _id?: number;
    partnerName?: string;
    partnerDescription?: string;
};

export type UserCompany = {
    _id?: number;
    companyCode?: string;
    companyName?: string;
};

export type SelectOption = {
    id?: number;
    name?: string;
    value?: string;
};
export type OAuth_WebEAMConfig = {
    url: string;
    enabled: boolean;
};

export type OAuth_WebEamUserInfo = {
    suppliernumber?: string;
    suppliername?: string;
    given_name?: string;
    family_name?: string;
    name?: string;
    departmentnumber?: string;
    inumber?: string;
    email?: string;
    sub?: string;
    auth_level?: string;
};

export type PortalUserInput = {
  user_id?: string;
  title?: string;
  username?: string;
  loginName?: string;
  roleName?: string;
  password?: string;
  firstname?: string;
  lastname?: string;
  email?: string;
  contact?: string;
  insert_user?: string;
  update_user?: string;
};

export type DownloadStatusOption = {
    downloaded: boolean;
};

export type FailureDownloadDocsResp = {
    documentId: number;
    documentName: string;
    documentLink: string;
    documentPath: string;
    errorMessage: string;
}

export type FailureDownloadDocsRespEcap = {
    id: number;
    blobId: number;
    errorMessage: string;
}
export const randomInt = (): number => Math.floor(Math.random() * 100)
