import React, { useState, useEffect } from "react";
import { Col,  Input, Row,  Card, Select, Form } from "antd";
import { useIntl } from "react-intl";
import { Form_Item } from "./FormItem-component";
import { getCountryListData, getRegionListData } from "../services/SelectServices";
import { getControlRuleset, getDefaultDisabledRuleset,  getDefaultNotRequiredRuleset,  getDefaultRequiredRuleset } from "../RulesEngine";
import { getI18n } from "../../../utils/Utils";
import { FormInstance } from "antd/es/form/Form";
import { useAppDispatch, useAppSelector } from "../../../main/hooks";
import { updateFiscalCountryCode, updateFiscalRegionCode, updateShippingFromCountryCode, updateShippingFromRegionCode, updateShippingToCountryCode, updateShippingToRegionCode } from "../../e-cap/IStoredDocumentRecordSlice";
import { isArray } from "lodash";
import { SupportOptionRange } from "prettier";
import { CompassOutlined } from "@ant-design/icons";


const { Option } = Select;

type addressDetailsProps = {
  form: FormInstance<any>;
  regionDisplayFldName: any;
  regionCodeFldLabel?: string;
  regionCodeFldName: any;
  regionCodeFldRule?: string;
  regionCodePlaceholder?: string;
  streetFldName: any;
  streetFldLabel?: string;
  streetFldRule?: any;
  streetFldPlaceholder?: string;
  countryDisplayFldName: any;
  countryFldLabel?: string
  countryCodeFldName: any;
  countryFldRule?: any;
  countryFldPlaceholder?: string;
  postalCodeFldName: any;
  postalCodeFldLabel?: string;
  postalCodeFldRule?: any;
  postalCodeFldPlaceholder?: string;
  cityFldName: any;
  cityFldLabel?: string;
  cityFldRule?: any;
  cityFldPlaceholder?: string;
  editable?: boolean;
  noTitle?: boolean;
  panelTitle?: string;
  noRegionField?: boolean;

};

export function ECAPAddressInfo(props: addressDetailsProps): JSX.Element {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const form: FormInstance = props.form;
  const [regionCodes, setRegionCodes] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const { document } = useAppSelector((state) => state.document);
  const rules  = useAppSelector((state) => state.rules);

  const getRegionDropDown = () => {
    if (regionCodes.length === 0) {
      getRegionListData()
        .then((data) => {
          const regionList = data?.getRegionList;
          setRegionCodes(regionList);
          // eslint-disable-next-line no-console
          //console.log("Region Code list");
          //console.dir(regionList);
        });
    }

  };

  const getCountryDropDown = () => {
    if (countryCodes.length === 0) {
      getCountryListData()
        .then((data) => {
          const countryList = data?.getCountryList;
          setCountryCodes(countryList);
          // eslint-disable-next-line no-console
          //console.log("Country Code list");
          //console.dir(countryList);
          getRegionDropDown();
        });
    }
  };

  useEffect(() => {
    getCountryDropDown();
    if (isArray(props.countryCodeFldName))
    {
      if (props.countryCodeFldName[0] === "shippingInformation" && props.countryCodeFldName[1] === "shipFromCountryCode")
      {
        //console.log(`Get region codes for ship from country : ${document.shipFromCountryCode}`);
        const regionList = regionCodes.filter((item) => {return item.id.countryCode === document.shipFromCountryCode});
        setRegionList(regionList);
      } else if (props.countryCodeFldName[0] === "shippingInformation" && props.countryCodeFldName[1] === "shipToCountryCode")
      {
        //console.log(`Get region codes for ship to country : ${document.shipToCountryCode}`);
        const regionList = regionCodes.filter((item) => {return item.id.countryCode === document.shipToCountryCode});
        setRegionList(regionList);
      }
      else if (props.countryCodeFldName[0] === "supplierDetails" && props.countryCodeFldName[1] === "fiscalRepresentativeAddressCountryCode")
      {
        //console.log(`Get region codes for fiscal country : ${document.fiscalCountryCode}`);
        const regionList = regionCodes.filter((item) => {return item.id.countryCode === document.fiscalCountryCode});
        setRegionList(regionList);
      }
    }

  }, [regionCodes, countryCodes]);

  const OnSelectCountry = (key,value) => 
  {
    //console.log("Country selected");
    //console.dir(value);
    const countryCode: string = value.key;
    const countryCodeSplit = countryCode.split("_");
    const selectedCountryCode = countryCodeSplit[0];
    //console.log(`Country Code field : ${props.countryCodeFldName} to selected ${selectedCountryCode}`);
    if (isArray(props.countryCodeFldName))
    {
      if (props.countryCodeFldName[0] === "shippingInformation" && props.countryCodeFldName[1] === "shipFromCountryCode")
      {
        dispatch(updateShippingFromCountryCode(selectedCountryCode));
      } else if (props.countryCodeFldName[0] === "shippingInformation" && props.countryCodeFldName[1] === "shipToCountryCode")
      {
        dispatch(updateShippingToCountryCode(selectedCountryCode));
      }
      else if (props.countryCodeFldName[0] === "supplierDetails" && props.countryCodeFldName[1] === "fiscalRepresentativeAddressCountryCode")
      {
        dispatch(updateFiscalCountryCode(selectedCountryCode));
      }
    }
    //
    //clear the region field as new country was selected
    form.setFieldsValue({[props.regionDisplayFldName]:  undefined});
    //fill region list using selected country code
    const regionList = regionCodes.filter((item) => {return item.id.countryCode === selectedCountryCode});
    setRegionList(regionList);
  }

  const OnSelectRegion = (key,value) =>
  {
    //console.log("Region selected");
    //console.dir(value);
    const regionCode: string = value.key;
    const regionCodeSplit = regionCode.split("_");
    const selectedRegionCode = regionCodeSplit[0];
    //console.log(`Region Code field : ${props.regionCodeFldName} to selected ${selectedRegionCode}`);
    if (isArray(props.regionCodeFldName))
    {
      //console.log("Update region state");
      if (props.regionCodeFldName[0] === "shippingInformation" && props.regionCodeFldName[1] === "shipFromRegionCode")
      {
        //console.log(`Updating state for ${props.regionCodeFldName}`);
        dispatch(updateShippingFromRegionCode(selectedRegionCode));
      } else if (props.regionCodeFldName[0] === "shippingInformation" && props.regionCodeFldName[1] === "shipToRegionCode")
      {
        //console.log(`Updating state for ${props.regionCodeFldName}`);
        dispatch(updateShippingToRegionCode(selectedRegionCode));
      }
      else if (props.regionCodeFldName[0] === "supplierDetails" && props.regionCodeFldName[1] === "fiscalRepresentativeAddressRegionCode")
      {
        //console.log(`Updating state for ${props.regionCodeFldName}`);
        dispatch(updateFiscalRegionCode(selectedRegionCode));
      }
    }
  }

  return (

    <Card bordered={true} size={"small"}
          title={props.noTitle ? "" : getI18n("general-address-title", props.panelTitle?props.panelTitle: "Address", intl )}>
      <Row gutter={24}>
        <Col span={10}>
          <Form_Item label={props.streetFldLabel !== undefined ? props.streetFldLabel :  getI18n("general-address-street-label", "Street",intl) }
                     placeholder={props.streetFldPlaceholder !== undefined ? props.streetFldPlaceholder :  getI18n("general-address-street-label", "Street",intl) }
                     name={props.streetFldName} itemRules={props.streetFldRule !== undefined ?  getControlRuleset(props.streetFldRule,form,rules,document) : props.editable ? getDefaultNotRequiredRuleset() : getDefaultDisabledRuleset()} />
          <Form_Item
            label={props.postalCodeFldLabel !== undefined ? props.postalCodeFldLabel : getI18n("general-address-postalCode-label", "Postal Code", intl )}
            placeholder={props.postalCodeFldPlaceholder !== undefined ? props.postalCodeFldPlaceholder : getI18n("general-address-postalCode-label", "Postal Code", intl )}
            name={props.postalCodeFldName} itemRules={props.postalCodeFldRule !== undefined ?  getControlRuleset(props.postalCodeFldRule,form,rules,document) : props.editable ? getDefaultNotRequiredRuleset() : getDefaultDisabledRuleset()} />
          <Form_Item label={props.countryFldLabel !== undefined ? props.countryFldLabel :  getI18n("general-address-country-label", "Country", intl )}
                     name={props.countryDisplayFldName} itemRules={props.countryFldRule !== undefined ?  getControlRuleset(props.countryFldRule,form,rules,document) : props.editable ? getDefaultNotRequiredRuleset() : getDefaultDisabledRuleset()}>
            {props.editable ? 
                <Select className=""
                        onSelect={OnSelectCountry}
                        style={{ width: 180 }}
                        showSearch={true}
                        disabled={props.countryFldRule !== undefined ?  getControlRuleset(props.countryFldRule,form,rules,document).readOnly : !props.editable }
                        placeholder={getI18n("general-country-codes-placeholder", "Country Codes", intl )} allowClear={true}>
                  {countryCodes &&
                  countryCodes.map((item) => (
                    <Option key={`${item.id}_${item.countryCode}`} value={item.country}
                            data-testid={item.country}>
                      {getI18n(item.phraseId, item.country, intl)}
                    </Option>
                  ))}
                </Select>
            : <Input readOnly={true}/>}
          </Form_Item>
        </Col>

        <Col span={10}>
          <Form_Item label={props.cityFldLabel !== undefined ? props.cityFldLabel : getI18n("general-address-city-label", "City", intl )}
                     placeholder={props.cityFldPlaceholder !== undefined ? props.cityFldPlaceholder : getI18n("general-address-city-label", "City", intl )}
                     name={props.cityFldName} itemRules={props.cityFldRule !== undefined ?  getControlRuleset(props.cityFldRule,form,rules,document) : props.editable ? getDefaultNotRequiredRuleset() : getDefaultDisabledRuleset()} />
          {props.noRegionField === undefined || props.noRegionField === false ?
            <Form_Item label={props.regionCodeFldLabel !== undefined ? props.regionCodeFldLabel : getI18n("general-address-region-label", "Region" , intl )}
                      name={props.regionDisplayFldName} itemRules={props.regionCodeFldRule !== undefined ?  getControlRuleset(props.regionCodeFldRule,form,rules,document) : props.editable ? getDefaultNotRequiredRuleset() : getDefaultDisabledRuleset()}>
                {props.editable ?
                <Select className=""
                  style={{ width: 180 }}
                  showSearch={true}
                  onSelect={OnSelectRegion}
                  disabled={props.regionCodeFldRule !== undefined ?  getControlRuleset(props.regionCodeFldRule,form,rules,document).readOnly : !props.editable }
                  placeholder={getI18n("ecap-general-region-codes-placeholder", "Region Codes", intl)} allowClear={true}>
                      {regionList &&
                      regionList.map((item) => (
                          <Option key={`${item.id.regionCode}_${item.region}`} value={item.id.region}
                                  data-testid={item.region}>
                          {item.region}
                          </Option>
                      ))}
                </Select>
                : <Input readOnly={true}/>}
            </Form_Item>
           : null}
        </Col>
      </Row>

    </Card>

  );
}