import React, { useState } from "react";
import SuppliersBankingDetails from "./supplier/SupplierComplianceData";
import { Checkbox, Modal, notification, Space, Spin } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import Link from "antd/lib/typography/Link";
import { useIntl } from "react-intl";
import { getI18n } from "../../utils/Utils";
import { DOWNLOAD_SURVEY_DOCUMENT_QUERY_BY_BLOB_ID } from "../survey/surveyAgreements/queries";
import { useLazyQuery, useQuery } from "@apollo/client";
import { saveAs } from "save-as";
import { downloadSurveyDocument } from "../survey/surveyAgreements/__generated__/downloadSurveyDocument";
import { downloadSurveyDocumentVariables } from "../survey/__generated__/downloadSurveyDocument";
import { LocalState } from "@apollo/client/core/LocalState";

import {Buffer} from "buffer";
import { INVOICE_AGREEMENT_INFO } from "./queries";

type SubmitDocumentModalProps = {
  companyCode: string;
  category: string;
  isModalVisible: any;
  handleOk: any;
  handleCancel: any;
}
const SubmitDocumentModal: React.FunctionComponent<SubmitDocumentModalProps> = (props) => {
  const intl = useIntl();
  const [invoicingAgreementChecked, isInvoicingAgreementChecked] = useState<boolean>(false);
  const [loading, isLoading] = useState<boolean>(false);
  const agreementNotChecked = "Please select the checkbox to confirm that you that you have read, understood and agree to the eInvoicing Agreement or select Return to the document to continue processing";

  const [getInvoiceAgreementInfoData, {}] = useLazyQuery(INVOICE_AGREEMENT_INFO, {
    fetchPolicy: "network-only",
    variables: {companyCode: props.companyCode,languageCode: localStorage.getItem("locale").toLocaleUpperCase(), moduleCode: "ECAP",category: props.category}
  });


  const [downloadSurveyAgreement, {}] = useLazyQuery<downloadSurveyDocument, downloadSurveyDocumentVariables>(DOWNLOAD_SURVEY_DOCUMENT_QUERY_BY_BLOB_ID, {
    fetchPolicy: "network-only", onError: (error) => console.log(error)
  });

   const handleOK = () => {
    if (invoicingAgreementChecked) {
      getInvoiceAgreementInfoData().then( responseData => {
        const agreementInfo = responseData.data?.getAgreementInfo;
        if(agreementInfo.length > 0) {
          props.handleOk(agreementInfo[0].agreementId);
        }
      })
    } else {
      notification.error({
        message: getI18n("ecap-document-error", "Error", intl),
        description: getI18n("ecap-document-submit-document-agreement-not-checked", agreementNotChecked, intl)
      });
    }
  };


  const downloadDocument = () => {
    isLoading(true);

    getInvoiceAgreementInfoData().then( responseData => {
      const agreementInfo = responseData.data?.getAgreementInfo;
      if(agreementInfo.length > 0) {
        downloadSurveyAgreement({ variables: { blobId: agreementInfo[0].blobId} }).then(response => {
          const mimeType = response.data.downloadSurveyDocument.value.mimeType;
          const decodedData = Buffer.from(response.data.downloadSurveyDocument.value.encodedFileData, "base64");
          const blob = new Blob([decodedData], { type: mimeType });
          saveAs(blob, response.data.downloadSurveyDocument.value.fileName);
          isLoading(false);
        });
      }
    })
  };
  return (
    <>
      <Spin spinning={loading} size={"large"}>
        <Modal open={props.isModalVisible} onOk={handleOK} onCancel={props.handleCancel} closable={false}
               width={"900px"}
               okText={intl.formatMessage({
                 id: "ecap-document-submit-document-confirm-button",
                 defaultMessage: "Submit Document"
               })}
               cancelText={intl.formatMessage({
                 id: "ecap-document-submit-document-cancel-button",
                 defaultMessage: "Return to the Document"
               })}>
          <Checkbox onChange={(e: CheckboxChangeEvent) => isInvoicingAgreementChecked(e.target.checked)}>
          {intl.formatMessage({
            id: "ecap-document-submit-document-confirm-message",
            defaultMessage: "By selecting this checkbox you are confirming that you have read, understood and agree to the "
          })}
            <Space>
              <Link onClick={downloadDocument}>
                {intl.formatMessage({
                  id: "ecap-document-submit-document-confirm-agreementLink",
                  defaultMessage: "eInvoicing Agreement"
                })}
              </Link>
            </Space>
          </Checkbox>
        </Modal>
      </Spin>
    </>
  );
};

export default SubmitDocumentModal;