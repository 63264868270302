function timeDifference(current, previous) {
    const milliSecondsPerMinute = 60 * 1000;
    const milliSecondsPerHour = milliSecondsPerMinute * 60;
    const milliSecondsPerDay = milliSecondsPerHour * 24;
    const milliSecondsPerMonth = milliSecondsPerDay * 30;
    const milliSecondsPerYear = milliSecondsPerDay * 365;

    const elapsed = current - previous;

    if (elapsed < milliSecondsPerMinute / 3) {
        return 'just now';
    }

    if (elapsed < milliSecondsPerMinute) {
        return 'less than 1 min ago';
    } else if (elapsed < milliSecondsPerHour) {
        return Math.round(elapsed / milliSecondsPerMinute).toString.concat(' min ago');
    } else if (elapsed < milliSecondsPerDay) {
        return Math.round(elapsed / milliSecondsPerHour).toString.concat(' h ago');
    } else if (elapsed < milliSecondsPerMonth) {
        return Math.round(elapsed / milliSecondsPerDay).toString.concat(' days ago');
    } else if (elapsed < milliSecondsPerYear) {
        return Math.round(elapsed / milliSecondsPerMonth).toString.concat(' mo ago');
    } 
    return Math.round(elapsed / milliSecondsPerYear).toString.concat(' years ago');
}

export function timeDifferenceForDate(date) {
    const now = new Date().getTime();
    const updated = new Date(date).getTime();
    return timeDifference(now, updated);
}

export function getCurrentLocale() {
    const locale = localStorage.getItem("locale") ? localStorage.getItem("locale") : "en";
    return locale;
}

export function saveLocale(locale){
    if (locale) {
        console.log(`Setting the locale to ${locale}`)
        localStorage.setItem("locale", locale);
    }
}


export function isDocumentTypeViewMode() {
    const mode = window.localStorage.getItem("docTypeViewMode");
    if (mode !== undefined && mode === "1")
    {
        return true;
    }
    return false;
}

export function setDocumentTypeViewMode(mode) {
    if (mode)
    {
        window.localStorage.setItem("docTypeViewMode", "1");
    }
    else{
        window.localStorage.setItem("docTypeViewMode", "0");
    }
}

export function setViewMode(mode) {
    if (mode)
    {
        window.localStorage.setItem("viewMode", "1");
    }
    else{
        window.localStorage.setItem("viewMode", "0");
    }
}

export function setGroupId(groupId) {
    window.localStorage.setItem('selected-group', groupId);
}

export function getGroupId(groupId) {
    return window.localStorage.getItem('selected-group');
}



