import {
    ATTACH_BLOB,
    ATTACH_SUPPORTING_DOCUMENT,
    DELETE_DOCUMENT_BY_BLOB_ID,
    DELETE_SUPPORTING_DOCUMENT
} from "../mutations";
import {DOWNLOAD_ECAP_DOCUMENT_BY_BLOB_ID, RETRIEVE_SUPPORTING_DOCUMENTS} from "../queries";
import {SupportingDocumentResponse} from "./model/SupportingDocumentsModel";
import {
    AllSupporingDocumentsResponse,
    AllSupporingDocumentsResponseVariables
} from "../__generated__/AllSupporingDocumentsResponse";
import {ApolloClient} from "@apollo/client";
import {BlobFileInput, BlobInfo} from "../../../../__generated__/globalTypes";
import {
    DeleteSupportingDocument as DeleteResponseWrapper,
    DeleteSupportingDocument_deleteSupportingDocument as DeleteResult,
    DeleteSupportingDocumentVariables as DeleteRequest
} from "../__generated__/DeleteSupportingDocument";
import client from "../../../config/GraphQLApolloClient";
import {
    uploadBlobFile_uploadBlobFile as TFBlobResponse,
    uploadBlobFileVariables as TFBlobInput
} from "../../survey/__generated__/uploadBlobFile";
import {AssociateWithBlob, AssociateWithBlobVariables} from "../__generated__/AssociateWithBlob";
import {DeleteBlob, DeleteBlobVariables} from "../__generated__/DeleteBlob";
import {AttachSupportingDocumentResponse} from "../__generated__/AttachSupportingDocumentResponse";
import {
    DownloadEcapDocumentAPI,
    DownloadEcapDocumentAPI_downloadECapDocument
} from "../__generated__/DownloadEcapDocumentAPI";

export class SupportingDocumentService {
    apolloClient: ApolloClient<any>;


    constructor(apolloClient: ApolloClient<any>) {
        this.apolloClient = apolloClient;
    }

    downloadDocumentByBlobId(blobId:number) {
        return new Promise<DownloadEcapDocumentAPI_downloadECapDocument>((resolve, reject) => {
            client.query<DownloadEcapDocumentAPI>({
                query: DOWNLOAD_ECAP_DOCUMENT_BY_BLOB_ID,
                variables: {blobId},
                fetchPolicy: "network-only"
            })
                .then((documents) => {
                    const response = documents.data.downloadECapDocument
                    resolve(
                        response
                    );
                })
                .catch((err) => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    uploadFile(blobFile:BlobFileInput) {
        return new Promise<TFBlobResponse>((resolve,reject)=> {
            client.mutate<AttachSupportingDocumentResponse,TFBlobInput>({
                fetchPolicy: "no-cache",
                mutation: ATTACH_SUPPORTING_DOCUMENT,
                variables: { blobFile },
            }).then(result=> {
                resolve(result.data?.uploadEcapDocument);
            }).catch(error=> {
                console.log(error);
                reject(error);
            });
        });
    }

    deleteBlob(blob:TFBlobResponse) {
        return new Promise<boolean>((resolve, reject) => {
            this.apolloClient.mutate<DeleteBlob, DeleteBlobVariables>({
                mutation: DELETE_DOCUMENT_BY_BLOB_ID,
                variables: {
                    blobId: blob.blobId,
                    appName:'ecap'
                }
                ,
                fetchPolicy: "network-only"
            }).then(result=> {
                 console.log('Blob delete result',result.data.deleteBlobFile);
                resolve(true);
            }).catch(error=> {
                console.log(error);
                resolve(true);
            });
        });
    }

    associateWithBlob(blobInfo: BlobInfo): Promise<SupportingDocumentResponse> {

        return new Promise<SupportingDocumentResponse>((resolve, reject) => {
            this.apolloClient.mutate<AssociateWithBlob, AssociateWithBlobVariables>({
                mutation: ATTACH_BLOB,
                variables: {
                    blobInfo
                }
                ,
                fetchPolicy: "network-only"
            })
                .then((attachmentResult) => {
                    const response: SupportingDocumentResponse = {...attachmentResult.data.associateWithBlob}
                    resolve(
                        response
                    );
                })
        });
    }

    deleteSupportingDocument(attachmentId: number) {
        return new Promise<DeleteResult>((resolve,reject)=> {
            this.apolloClient.mutate<DeleteResponseWrapper,DeleteRequest>({
                mutation: DELETE_SUPPORTING_DOCUMENT,
                variables: {
                    attachmentId: `${attachmentId}`
                },
                fetchPolicy: "network-only"
            }).then(result=> {
                const response = result.data.deleteSupportingDocument;
                resolve(response);
            }).catch(error=> {
                console.log(error);
                reject(error)
            })
        });
    }

    retrieveAllAttachedSupportingDocuments(documentId: number): Promise<SupportingDocumentResponse[]> {
        return new Promise<SupportingDocumentResponse[]>((resolve, reject) => {
            this.apolloClient.query<AllSupporingDocumentsResponse, AllSupporingDocumentsResponseVariables>({
                    query: RETRIEVE_SUPPORTING_DOCUMENTS,
                    variables: {
                        docId: `${documentId}`
                    }
                    ,
                    fetchPolicy: "network-only"
                })
                    .then((attachmentResult) => {
                        const response: SupportingDocumentResponse[] = attachmentResult.data.retrieveSupportingDocuments;
                        resolve(
                            response
                        );
                    })
                    .catch((err) => {
                        console.error(err);
                        reject(err);
                    });
            }
        );
    }
}