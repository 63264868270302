import { Form, message, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useGetAllEntitlementsQuery } from './queries.generated';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../main/hooks';
import { setEntitlementTypeUpdated } from './redux/IStoredEntitlementsSlice';
import { Entitlement } from '../../../__generated__/types';

interface EntitlementTypeDropDownProps {
    options: string[];
    form: any;
    entitlementGroupSelected: string[];
    onChange: (selectedOption: string) => void;
}

const EntitlementTypeDropDown = (props: EntitlementTypeDropDownProps) => {
    const dispatch = useDispatch();
    const entitlementState = useAppSelector((state) => state.entitlements);
    const [entitlementTypes, setEntitlementTypes] = useState<Entitlement[]>([]);
    const [refreshLoading, setRefreshLoading] = useState(false);

    const {
        data: entitlementTypeData,
        loading: entitlementTypesLoading,
        error: entitlementTypesError,
        refetch: refetchData
    } = useGetAllEntitlementsQuery({
        variables: {},
    });

    if (entitlementTypesError) {
        console.error('Error loading entitlement types: ', entitlementTypesError);
        message.error(entitlementTypesError);
    }

    useEffect(() => {
        console.log("entitlementTypesUpdated: ",entitlementState?.entitlementSchema?.entitlementTypesUpdated)
        if (entitlementState?.entitlementSchema?.entitlementTypesUpdated && !entitlementTypesLoading)
        {
            console.log("refresh entitlement data");
            setRefreshLoading(true);
            refetchData().then(({data}) => {
                dispatch(setEntitlementTypeUpdated(false));
                setEntitlementTypes(data.getAllEntitlements);
                setRefreshLoading(false);
            });
        }
        else 
        if (!entitlementTypesLoading && (!entitlementTypes || entitlementTypes.length ===0) && entitlementTypeData)
        {
            setEntitlementTypes(entitlementTypeData.getAllEntitlements);
        }
    }, [entitlementTypesLoading, entitlementTypes, entitlementState?.entitlementSchema?.entitlementTypesUpdated]);

    return (
        <Spin spinning={entitlementTypesLoading || refreshLoading} >
            <Form.Item name="entitlementName" label="Entitlement">
                {props.form.getFieldValue('entitlementGroup') && props.form.getFieldValue('entitlementGroup').length > 0 ? (
                    <Select placeholder="Entitlement" allowClear={true} onChange={props.onChange} showSearch={true}>
                        {entitlementTypes
                                ?.filter((entitlement) => {
                                    return (
                                        props.entitlementGroupSelected.filter((groupId) => {
                                            return entitlement.groupId.includes(groupId);
                                        }).length > 0
                                    );
                                })
                                .map((entitlement) => (
                                    <Select.Option key={entitlement?.entitlement} value={entitlement?.entitlement}>
                                        {entitlement?.description?.concat(' (', entitlement?.entitlement, ')')}
                                    </Select.Option>
                                ))}
                    </Select>
                ) : (
                    <Select placeholder="Entitlement" allowClear={true} onChange={props.onChange} showSearch={true}>
                        {entitlementTypes?.map((entitlement) => (
                            <Select.Option key={entitlement?.entitlement} value={entitlement?.entitlement}>
                                {entitlement?.description?.concat(' (', entitlement?.entitlement, ')')}
                            </Select.Option>
                        ))}
                    </Select>
                )}
            </Form.Item>
        </Spin>
    );
};

export default EntitlementTypeDropDown;
