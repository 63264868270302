import { FormInstance } from 'antd';
import { isArray } from 'lodash';
import { readNestedFormValue } from '../../utils/Utils';
import { IStoredDocumentRecord } from './IStoredDocumentRecord';
import { getRuleDataByRuleType } from './services/RulesServices';
import { getFilteredRulesWithRuleType, getFilteredRulesWithRuleType_getFilteredRulesWithRuleType } from './__generated__/getFilteredRulesWithRuleType';

interface RulesState {
    rules: getFilteredRulesWithRuleType;
    rulesIndex: [];
    ruleMap: any;
}

export const getDefaultRequiredRuleset = () => {
    return {
        required: true,
        picture: '',
        readOnly: false,
        hidden: false,
    };
};

export const getDefaultNotRequiredRuleset = () => {
    return {
        required: false,
        picture: '',
        readOnly: false,
        hidden: false,
    };
};

export const getDefaultDisabledRuleset = () => {
    return {
        required: false,
        picture: '',
        readOnly: true,
        hidden: false,
    };
};

export const getDefaultHiddenRuleset = () => {
    return {
        required: false,
        picture: '',
        readOnly: true,
        hidden: true,
    };
};

const getRules = async (ruleType) => {
    return await getRuleDataByRuleType(ruleType);
};

type RuleSet = {
    required?: boolean;
    hidden?: boolean;
    readOnly?: boolean;
    picture?: string;
    defaultValue?: any;
};

function checkRules(definition, form: FormInstance<any>) {
    //ruleset contains behavior rules
    if (definition.ruleSet !== undefined) {
        //console.debug(`Processing BMW behavior RuleSet`);
        //console.debug(definition.ruleSet);
        return definition.ruleSet;
    } else if (definition.defaultValue !== undefined) {
        //console.debug(`Processing BMW Default Value : ${definition.defaultValue}`);
        // form.setFieldsValue({ definition.defaultValue.fieldName : `${definition.defaultValue.fieldValue}`});
        form.setFieldsValue({ bmwBusinessArea: `${definition.defaultValue.fieldValue}` });
        return;
    }
}

function equalOperation(fieldValue, definition,  form: FormInstance<any>) {
    //console.debug('BMW Rule Match - (equality check)');
    if (
        fieldValue !== undefined &&
        definition.fieldValue !== undefined &&
        fieldValue === definition.fieldValue
    ) {
        //console.debug('BMW Rule Match success');
        return checkRules(definition, form);
    }
}

function notEqualOperation(fieldValue, definition,  form: FormInstance<any>) {
    //console.debug('BMW Rule Match - (not equal check)');
    if (
        fieldValue !== undefined &&
        definition.fieldValue !== undefined &&
        fieldValue !== definition.fieldValue
    ) {
        //console.debug('BMW Rule Match success');
        return checkRules(definition, form);
    }
}

function lessThanOperation(fieldValue, definition,  form: FormInstance<any>) {
    //console.debug('BMW Rule Match - (less than check)');
    if (
        fieldValue !== undefined &&
        definition.fieldValue !== undefined &&
        fieldValue < definition.fieldValue
    ) {
        //console.debug('BMW Rule Match success');
        return checkRules(definition, form);
    }
}

function greaterThanOperation(fieldValue, definition,  form: FormInstance<any>) {
    //console.debug('BMW Rule Match - (greater than check)');
    if (
        fieldValue !== undefined &&
        definition.fieldValue !== undefined &&
        fieldValue > definition.fieldValue
    ) {
        //console.debug('BMW Rule Match success');
        return checkRules(definition, form);
    }
}

function containsOperation(fieldValue, definition,  form: FormInstance<any>) {
    //console.debug('BMW Rule Match found- (contains check)');
    if (
        fieldValue !== undefined &&
        definition.fieldValue !== undefined &&
        fieldValue.toString().contains(definition.fieldValue)
    ) {
        //console.debug('BMW Rule Match success');
        return checkRules(definition, form);
    }
}

function inListOperation(fieldValue, definition,  form: FormInstance<any>) {
    //console.debug('BMW Rule Match - (in list check)');
    if (fieldValue !== undefined && definition.fieldValue !== undefined ) {
        const item = definition.fieldValue
            .toString()
            .split(',')
            .find((item) => {
                if (item === fieldValue) return item;
            });
        //if item found then return rules
        if (item !== undefined) {
            //console.debug('BMW Rule Match success');
            return checkRules(definition, form);
        }
    }
}
function notInListOperation(fieldValue, definition,  form: FormInstance<any>) {
    //console.debug('BMW Rule Match - (NOT in list check)');
    if (fieldValue !== undefined && definition.fieldValue !== undefined ) {
        const item = definition.fieldValue
            .toString()
            .split(',')
            .find((item) => {
                if (item === fieldValue) return item;
            });
        //if item not found then return rules
        if (item === undefined) {
            //console.debug('BMW Rule Match success');
            return checkRules(definition, form);
        }
    }
}

function nullOperation(fieldValue, definition, form: FormInstance<any>) {
    //console.debug('BMW Rule Match - (NULL check)');
    if (fieldValue === undefined || fieldValue === null) {
        //console.debug('BMW Rule Match success');
        return checkRules(definition, form);
    }
}

function notNullOperation(fieldValue, definition, form: FormInstance<any>) {
    //console.debug('BMW Rule Match - (NOT NULL check)');
    if (fieldValue !== undefined && fieldValue !== null) {
        //console.debug('BMW Rule Match success');
        return checkRules(definition, form);
    }
}


function startWithOperation(fieldValue, definition, xpathVariable, form: FormInstance<any>) {
    //console.debug('BMW Rule Match - (start with check)');
    if (
        fieldValue !== undefined &&
        definition.fieldValue !== undefined &&
        xpathVariable === definition.xpathVariable &&
        definition.fieldValue.toString().startsWith(definition.fieldValue)
    ) {
        //console.debug('BMW Rule Match success');
        return checkRules(definition, form);
    }
}

function findFieldValue(form: FormInstance<any>, field: string)
{
    //console.debug(`Get property value : ${field}`);
    const parts = field.split('.');
    //console.debug(`Field parts : ${parts.length}`);
    if (parts.length === 1)
    {
        return form.getFieldValue(field);
    }
    //console.debug(`Return nested property ${parts.at(0)} and ${parts.at(1)}`);
    const qualifier = form.getFieldValue(parts.at(0));
    if (qualifier !== undefined)
    {
        //console.debug(`top level`);
        //console.debug(qualifier);
        return qualifier[parts.at(1)];
    }
    return undefined;
}

function findFieldValueFromState(document: IStoredDocumentRecord, field: string, lineIndex: number)
{
    //console.debug(`Get state property value : ${field}`);
    const parts = field.split('.');
    //console.debug(`Field state parts : ${parts.length}`);
    if (parts.at(0) === 'state' && parts.at(1) === 'document')
    {
        //console.debug(`Return value from state : ${field} = ${document[parts.at(2)]}`)
        return document[parts.at(2)];
    } else
    if (parts.length === 1)
    {
        return document[field];
    }
    //console.debug(`Return nested state property ${parts.at(1)}`);
    const qualifier = document[parts.at(0)];
    //console.debug(qualifier);
    if (lineIndex !== undefined && isArray(qualifier))
    {
        //console.debug(`top level of state for an array`);
        //console.debug(qualifier);
        const item = qualifier.at(lineIndex);
        if (item !== undefined)
        {
            return item[parts.at(1)];
        }
    } else if (qualifier !== undefined)
    {
        //console.debug(`top level of state`);
        //console.debug(qualifier);
        return qualifier[parts.at(1)];
    }
    return undefined;
}

function getBMWRule(xpathVariable: string, definition, form: FormInstance<any>, document: IStoredDocumentRecord, lineIndex?: number): RuleSet {
    //if the xpath do not match, there is no need to check th rest 
    if (xpathVariable === definition.xpathVariable) {
        let fieldValue = undefined;
        
        fieldValue = findFieldValue(form, definition.fieldName);

        if (fieldValue === null || fieldValue === undefined) {
            //console.debug(`Check state, if field : ${definition.fieldName} have nested values`);
            fieldValue = findFieldValueFromState(document, definition.fieldName, lineIndex);
            //console.debug(
            //    `XPATH : ${xpathVariable}, Check state value of field : ${definition.fieldName} and value : ${fieldValue}`
            //);
        }
        //console.debug(
        //    `Rule: ${definition.fieldName} ${definition.operation} : ${definition.fieldValue} : form field value to check: ${fieldValue}`
        //);
        //Match field using equality, default operation is equality
        if (definition.operation === 'EQ' || definition.operation === undefined) {
            return equalOperation(fieldValue, definition,  form);
        } else if (definition.operation === 'NE') {
            return notEqualOperation(fieldValue, definition,  form);
        } else if (definition.operation === 'GT') {
            return greaterThanOperation(fieldValue, definition,  form);
        } else if (definition.operation === 'LT') {
            return lessThanOperation(fieldValue, definition,  form);
        } else if (definition.operation === 'CONTAINS') {
            return containsOperation(fieldValue, definition,  form);
        } else if (definition.operation === 'IN') {
            return inListOperation(fieldValue, definition,  form);
        } else if (definition.operation === '!IN') {
            return notInListOperation(fieldValue, definition,  form);
        }
        else if (definition.operation === 'NULL') {
            return nullOperation(fieldValue, definition,  form);
        }
        else if (definition.operation === '!NULL') {
            return notNullOperation(fieldValue, definition,  form);
        }
        else if (definition.operation === 'START_WITH') {
            return startWithOperation(fieldValue, definition, xpathVariable, form);
        }
    }
    return {};
}

function getEffectiveRuleSet(ruleSet: RuleSet, effectiveRuleSet) {
    //iterate through properties of item and set them in effective rule set
    const newSet = { ...effectiveRuleSet };
    for (const [key, value] of Object.entries(ruleSet)) {
        //console.debug(`Add entry : ${key} = ${value}`);
        //do not override a required field set to true
        if (newSet[key] !== undefined && key === "required" && newSet[key] !== false)
        {
        } else 
        {
            newSet[key] = value;
        }
        
    }
    //console.debug(newSet);
    return newSet;
}

function findInIndex(rulesIndex: any, xpathVariable: string) {
    if (isArray(rulesIndex))
    {
        return rulesIndex.find((xpath) => {if (xpath === xpathVariable) {return true}} );
    }
    return false;
    
}

function processRule(applicableRule, form, effectiveRuleSet,xpathVariable,document,lineIndex) {
    //console.debug("Parse JSON definition");
    const definition = JSON.parse(applicableRule.definition);
    //console.debug("Parse definition success ");
    // eslint-disable-next-line no-console
    //console.debug('Validate Rule definition');
    // eslint-disable-next-line no-console
    //console.debug(definition);
    //
    let newRulesSet = undefined;
    if (applicableRule.ruleCategory === 'BMW') {
        const bmwFoundRuleSet = getBMWRule(xpathVariable, definition, form, document,lineIndex);
        // eslint-disable-next-line no-console
        //console.debug('BMW Rule to apply');
        // eslint-disable-next-line no-console
        //console.debug(bmwFoundRuleSet);
        if (bmwFoundRuleSet !== undefined) {
            newRulesSet = getEffectiveRuleSet(bmwFoundRuleSet, effectiveRuleSet);
            // eslint-disable-next-line no-console
            //console.debug('Merged with BMW rule set');
            // eslint-disable-next-line no-console
            //console.debug(newRulesSet);
        }
        
    } else if (
        applicableRule.ruleCategory === 'Country' &&
        definition.countryCode === form?.getFieldValue(definition.fieldName) &&
        definition.xpathVariable === xpathVariable
    ) {
        //Merge with effective rule set
        newRulesSet = getEffectiveRuleSet(definition.ruleSet, effectiveRuleSet);
        // eslint-disable-next-line no-console
        //console.debug('Merged country rule with effective rule set');
        // eslint-disable-next-line no-console
        //console.debug(newRulesSet);
    } else if (applicableRule.ruleCategory === 'Format') {
        //console.debug('Format rule set');
        // eslint-disable-next-line no-console
        //console.debug(definition.ruleSet);
        //7merge to effective rule set
        newRulesSet = getEffectiveRuleSet(definition.ruleSet, effectiveRuleSet);
        // eslint-disable-next-line no-console
        //console.debug('Merged format rule with effective rule set');
        // eslint-disable-next-line no-console
        //console.debug(newRulesSet);
    }
    return newRulesSet;
}

export const getControlRuleset = (
    xpathVariable: string,
    form: FormInstance<any>,
    rules: RulesState,
    document: IStoredDocumentRecord,
    lineIndex?: number
) : RuleSet => {
    //console.debug(`getControlRuleset for XPATH=${xpathVariable} with LineIndex : ${lineIndex}`);
     /* set all rules to return field as not required as default*/
    const effectiveRuleSet = getDefaultNotRequiredRuleset();
    const rulesIndex = rules?.rulesIndex;
    //check if xpath is in index first
    const ruleIndex = findInIndex(rulesIndex, xpathVariable);
    //if exist , get rule from state map
    if (ruleIndex)
    {
        //console.debug(`XPATH: ${xpathVariable} found in index`);
        let applicableRules: getFilteredRulesWithRuleType_getFilteredRulesWithRuleType[] = [];
        applicableRules = rules.ruleMap.get(xpathVariable);
        //console.debug("Applicable Rules");
        //console.debug(applicableRules);
        let rulesSet = {};
        applicableRules?.map((applicableRule) => {
            const response: RuleSet = processRule(applicableRule, form, effectiveRuleSet,xpathVariable,document,lineIndex);
            if (response !== undefined)
            {
                //console.debug("response from Rules process");
                rulesSet = response;
                //console.debug(rulesSet);
            }
            if (applicableRule.stopRule)
            {
                return;
            }
        });
        return rulesSet;
    }

    //console.debug(`No rule found for XPATH=${xpathVariable}`)
    return {};
};