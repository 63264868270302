import React, { FunctionComponent, useEffect } from "react"
import { WrappedComponentProps } from "react-intl";
import { useAppDispatch, useAppSelector } from "../../../../main/hooks";
import CaptureDocument from "../../capture-document";
import { setTemplateMode } from "../../IStoredDocumentRecordSlice";

type IAppComponentProps = { 
    currentUser?: any; 
    distinctEntitlements?: string[] 
} & WrappedComponentProps;

const ManageTemplates: FunctionComponent<IAppComponentProps> = (props) =>  {
    return (
        <>
        <CaptureDocument templateMode={true} intl={props.intl} currentUser={props.currentUser} distinctEntitlements={props.distinctEntitlements}/>
        </>
    )
}

export default ManageTemplates;
