import { gql } from '@apollo/client';

export const MY_DOCS_TEMPLATE_DATA = gql`
    query getTemplateDocumentList($templateListInput:TemplateListInput!){
        getTemplateDocumentList(templateListInput:$templateListInput) {
            id
            documentNumber
            documentDate
            documentType
            bmwCompany
            partnerNumber
            partnerName
            currency
            grossAmount
            taxAmount
            lastUpdate
            phraseId
            tpUser
            templateName
            templateId
            templateInsertTime
            templateUpdateTime
        }
    }
`;

export const MY_DOCS_TEMPLATE_DROPDOWN = gql`
    query getDocumentTemplateDropdown{
        getDocumentTemplateDropdown {
            companyCode
            documentId
            documentTemplateID
            partnerNo
            systemCode
            templateName
        }
    }
`;

export const MY_DOCS_TEMPLATE_PARTNER_COUNT = gql`
    query getTemplatePartnerCount($partnerNo:String!){
        getTemplatePartnerCount(partnerNo: $partnerNo)
    }
`;

