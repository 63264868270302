import { gql } from "@apollo/client";

export const UPDATE_SURVEY_SUBMITTED = gql`
    mutation updateSurveySubmitData($groupId: String!) {
        updateSurveySubmitData(groupId: $groupId)
        {
            response
            success
            failureCode
            failureDescription
        }
    }
`
