import {ExternalStateUpdateCallback, LabelLocation, OnChangeListener, Validator} from "../InfoPortalInterfaces";
import {DatePicker, FormInstance} from "antd";
import {IAppComponentProps} from "../../../components";
import {Form_Item} from "../../e-cap/componets/FormItem-component";
import React, {useEffect, useState} from "react";
import dayjs, {Dayjs} from "dayjs";
import {getRangePickerLocalization} from "./grid/CalendarUtils";

type DateSelectionProperties = {
    form: FormInstance<any>,
    externalStateUpdater?: ExternalStateUpdateCallback<any>,
    onDateChange?: OnChangeListener<Dayjs>,
    validator?:Validator,
    initialValue?:Dayjs,
    dateFormatter?:string,
    labelLocation?: LabelLocation,
    dateDisabler?: (current: Dayjs) => boolean,
    displayColon?:boolean
} & IAppComponentProps;

type BaseDateSelectionProperties = {
    fieldName:string,
    heading:string,
    picker?: "date" | "week" | "month" | "quarter" | "year",
    props: DateSelectionProperties

} & IAppComponentProps;

function BaseDateSelection({fieldName, heading,picker,props: {
    intl,
    form,
    externalStateUpdater,
    validator,
    labelLocation,
    dateFormatter,
    displayColon,
    onDateChange,
    dateDisabler,
    initialValue,
}}:BaseDateSelectionProperties) {
    const [_, rerender] = useState<boolean>(false);
    const [selectedDate,setSelectedDate] = useState<Dayjs>(null);
    const forceRerender = () => rerender(current => !current);

    const handleSelectedDate = (dayjs: Dayjs) => {
        setSelectedDate(dayjs);
        onDateChange?.performAction(dayjs);
    };

    const setInitialValues = ()=> {
        if (initialValue) {
            if (!form.getFieldValue(fieldName)) {
                const initialValues = {};
                initialValues[fieldName] = initialValue;
                form.setFieldsValue(initialValues);
            }
        }
    };

    useEffect(() => {
        externalStateUpdater?.register({
            performAction() {
                forceRerender();
            }
        });
        setInitialValues();
    }, [])

    const getDateFormat = () => dateFormatter || "YYYY-MM-DD";

    const defaultDisableDate = (current: Dayjs) => current.isAfter(dayjs());

    return   <div>
      <Form_Item label={heading} name={fieldName} itemRules={validator.fetchRule(fieldName)}>
          <DatePicker
            data-testid={`${fieldName}-info-portal-date-picker`}
            format={getDateFormat()}
            style={{ width: "100%" }}
            placeholder=""
            picker={picker}
            onChange={handleSelectedDate}
            disabledDate={dateDisabler || defaultDisableDate}
            locale={getRangePickerLocalization(intl, localStorage.getItem("locale") || "en")}
            allowClear={true}
            inputReadOnly={true}
          />
      </Form_Item>
    </div>
}

export function GRSCDate (props:DateSelectionProperties) {
    return <BaseDateSelection fieldName={'grScDate'}
                              heading={props.intl.formatMessage({
                                  'id': 'gr-sc-date-per-month',
                                  defaultMessage: 'GR/SC Date (per month)'
                              })}
                              picker={"month"}
                              props={props}
                              intl={props.intl} />
}

export function OpenItemsSelectionDate (props:DateSelectionProperties) {
    return <BaseDateSelection fieldName={'keyDate'}
                              heading={props.intl.formatMessage({
                                  'id': 'open-items-key-date-heading',
                                  defaultMessage: 'Open items at key date'
                              })}
                              props={props}
                              intl={props.intl} />
}