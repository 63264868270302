import { gql } from "@apollo/client";

export const UPDATE_AGREEMENT_DOWNLOADED = gql`
    mutation updateAgreementDownloaded($groupId: String!) {
        updateAgreementDownloaded(groupId: $groupId)
        {
            response
        }

    }
`

export const UPDATE_AGREEMENT_UPDATE = gql`
    mutation updateAgreementInfo($surveyAgreementInput: SurveyAgreementInput!) {
        updateAgreementInfo(surveyAgreementInput: $surveyAgreementInput)
        {
            response
            success
            failureCode
            failureDescription
        }

    }
`