import { Input, Spin } from "antd";
import React, { FunctionComponent, useState } from "react";

export type IPortalBlobFileInput = {
    onChange?: any;
    value?: IFileInformation
}

export type IFileInformation = {
    fileName?: string;
    fileData?: string;
    mimeType?: string;
    error?: string;
    size?: number;
}

export const PortalBlobFileInput: FunctionComponent<IPortalBlobFileInput> = ({ onChange, value }) => {

    const [fileValue, setFileValue] = useState<IFileInformation>(value);
    const [loading, setLoading] = useState(false);

    const fileChangeEvent = (e) => {
        const fileData: IFileInformation = {};
        if (e.target.files.length > 0) {
            setLoading(true);
            const uploadedFile = e.target.files[0];
            // // eslint-disable-next-line no-console
            // console.dir(uploadedFile);
            fileData.fileName = uploadedFile.name;
            fileData.mimeType = uploadedFile.type;
            fileData.size = uploadedFile.size
            const reader = new FileReader();
            reader.readAsDataURL(uploadedFile);
            reader.onload = () => {
                fileData.fileData = reader.result.toString().split('base64,')[1];
                setFileValue(fileData);
                onChange?.(fileData);
                setLoading(false);

            }
            reader.onerror = (err) => {
                console.error(err);
                fileData.error = "Error Reading File.";
                setFileValue(fileData);
                onChange?.(fileData);
                setLoading(false);
            }

        }
    }

    return (
        <Spin spinning={loading} size={"large"}>
            <Input type={"file"} onChange={fileChangeEvent} />
        </Spin>
    )
}