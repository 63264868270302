import {IntlShape} from "react-intl/src/types";
import {PortalUser} from "../../../components";
import { convertFromSpecifiedDateFormatToUserDefined } from "../../users/UserFormatPreferenceServices";
import MissingInvoicesRowOption from "./MissingInvoicesGridIconRender";
import MissingInvoicesRowOptionFileName from "./MissingInvoicesGridIconRenderFilename";


export const TECH_MISSING_INVOICES_TABLE_HEADERS = (intl: IntlShape,user:PortalUser) => [
   {
    headerName: intl.formatMessage({
      id: "missing-invoices-grid-header-documentNumber",
      defaultMessage: "Document Number"
    }),
    field: "documentNumber",
    colId: "documentNumber",
    tooltipField:'documentNumber',
    headerTooltip: intl.formatMessage({
      id: "missing-invoices-grid-tooltip-documentNumber",
      defaultMessage: "Document Number from EC Document Partner Profile"
    }),
    cellStyle: params => {
      if (params?.data?.partnerNoIssue || params?.data?.partnerNameIssue || params?.data?.companyNameIssue || params?.data?.partnerAddressIssue
        || params?.data?.countryCodeAddressIssue || params?.data?.partnerVatCodeIssue || params?.data?.partnerVatCountryCodeIssue
        || params?.data?.companyVatCodeIssue || params?.data?.companyVatCountryCodeIssue || params?.data?.attachmentPending || params?.data?.companyPartnerIdIssue) {
        return { backgroundColor: 'yellow' };
      }
      return null; // Return null to use the default style
    },
    filter: true,
    width: 210,
    minWidth : 210,
    checkboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    headerCheckboxSelection: true,
    autoHeight: true,
    cellRenderer: MissingInvoicesRowOption
  },
  {
    headerName: intl.formatMessage({
      id: "missing-invoices-grid-header-processingStatusFlag",
      defaultMessage: "Processing Flag"
    }),
    field: 'processingStatusFlag',
    colId: 'processingStatusFlag',
    tooltipField:'processingStatusFlag',
    headerTooltip: intl.formatMessage({
      id: "missing-invoices-grid-tooltip-processingStatusFlag",
      defaultMessage: "Processing flag set from EAI process"
    }),
    filter: true,
    width: 158,
    minWidth : 158,
  },
  {
    headerName: intl.formatMessage({
      id: "missing-invoices-grid-header-companyPartnerId",
      defaultMessage: "Company Partner Id"
    }),
    field: 'companyPartnerId',
    colId: 'companyPartnerId',
    tooltipField:'companyPartnerId',
    headerTooltip: intl.formatMessage({
      id: "missing-invoices-grid-tooltip-companyPartnerId",
      defaultMessage: "Company Partner Id from EC Document"
    }),
    filter: true,
    width: 187,
    minWidth : 187,
  },
  {
    headerName: intl.formatMessage({
      id: "missing-invoices-grid-header-correctCompanyPartnerId",
      defaultMessage: "Correct Company Partner Id"
    }),
    field: 'correctCompanyPartnerId',
    colId: 'correctCompanyPartnerId',
    tooltipField:'correctCompanyPartnerId',

    headerTooltip: intl.formatMessage({
      id: "missing-invoices-grid-tooltip-correctCompanyPartnerId",
      defaultMessage: "Correct Company Partner Id from Partner Company System"
    }),
    cellStyle: params => {
      if (params?.data?.correctCompanyPartnerId !== params?.data?.companyPartnerId) {
        return { backgroundColor: 'yellow' };
      }
      return null; // Return null to use the default style
    },
    filter: true,
    width: 237,
    minWidth : 237,
  },
  {
    headerName: intl.formatMessage({
      id: "missing-invoices-grid-header-insertTime",
      defaultMessage: "Insert Time"
    }),
    colId: 'insertTime',
    field: 'insertTime',
    tooltipField:'insertTime',
    headerTooltip: intl.formatMessage({
      id: "missing-invoices-grid-tooltip-insertTime",
      defaultMessage: "Time the document was created in ec document"
    }),
    valueGetter: (params) => {
      if (params.data.insertTime) {
        return convertFromSpecifiedDateFormatToUserDefined(user, params.data.insertTime.substring(0,10), "YYYY-MM-DD");
      }
      return "";
    },
    filter: true,
    width: 131,
    minWidth : 131,
  },
  {
    headerName: intl.formatMessage({
      id: "missing-invoices-grid-header-updateTime",
      defaultMessage: "Update Time"
    }),
    colId: 'updateTime',
    field: 'updateTime',
    tooltipField:'updateTime',
    headerTooltip: intl.formatMessage({
      id: "missing-invoices-grid-tooltip-updateTime",
      defaultMessage: "Time the document was updated in ec document, most likely the submission time, hover over field to see actual insert time"
    }),
    valueGetter: (params) => {
      if (params.data.updateTime) {
        return convertFromSpecifiedDateFormatToUserDefined(user, params.data.updateTime.substring(0,10), "YYYY-MM-DD");
      }
      return "";
    },
    filter: true,
    width: 142,
    minWidth : 142,
  },
  {
    headerName: intl.formatMessage({
      id: "missing-invoices-grid-header-partnerNumber",
      defaultMessage: "Partner No"
    }),
    field: 'partnerNo',
    colId: 'partnerNo',
    tooltipField:'partnerNo',
    headerTooltip: intl.formatMessage({
      id: "missing-invoices-grid-tooltip-partnerNumber",
      defaultMessage: "Partner No from EC Document Partner Profile"
    }),
    filter: true,
    width: 129,
    minWidth : 129,
  },
  {
    headerName: intl.formatMessage({
      id: "missing-invoices-grid-header-partnerNoFromPartnerCompanySystem",
      defaultMessage: "Correct Partner No"
    }),
    field: 'partnerNoFromPartnerCompanySystem',
    colId: 'partnerNoFromPartnerCompanySystem',
    tooltipField:'partnerNoFromPartnerCompanySystem',
    headerTooltip: intl.formatMessage({
      id: "missing-invoices-grid-tooltip-partnerNoFromPartnerCompanySystem",
      defaultMessage: "Correct Partner No from Partner Company System"
    }),
    cellStyle: params => {
      if (params?.data?.partnerNoFromPartnerCompanySystem.trim() !== params?.data?.partnerNo?.trim()) {
        return { backgroundColor: 'yellow' };
      }
      return null; // Return null to use the default style
    },
    filter: true,
    width: 180,
    minWidth : 180,
  },
  {
    headerName: intl.formatMessage({
      id: "missing-invoices-grid-header-partnerName",
      defaultMessage: "Partner Name"
    }),
    field: 'partnerName' ,
    colId: 'partnerName',
    tooltipField:'partnerName',
    headerTooltip: intl.formatMessage({
      id: "missing-invoices-grid-tooltip-partnerName",
      defaultMessage: "Partner Name from EC Document Partner Profile"
    }),
    filter: true,
    width: 261,
    minWidth : 261,
  },
  {
    headerName: intl.formatMessage({
      id: "missing-invoices-grid-header-partnerNameFromPartnerCompanySystem",
      defaultMessage: "Suggested Partner Name"
    }),
    field: 'partnerNameFromPartnerCompanySystem' ,
    colId: 'partnerNameFromPartnerCompanySystem',
    tooltipField:'partnerNameFromPartnerCompanySystem',
    headerTooltip: intl.formatMessage({
      id: "missing-invoices-grid-tooltip-partnerNameFromPartnerCompanySystem",
      defaultMessage: "Partner Name from Partner Company system, The partner name might differ from what is stored based on the the lookup using the shortest partner name on front-end"
    }),
    cellStyle: params => {
      if (params?.data?.partnerNameFromPartnerCompanySystem?.trim() !== params?.data?.partnerName?.trim()) {
        return { backgroundColor: 'yellow' };
      }
      return null; // Return null to use the default style
    },
    filter: true,
    width: 382,
    minWidth : 382,
  },
  {
    headerName: intl.formatMessage({
      id: "missing-invoices-grid-header-partner-address",
      defaultMessage: "Partner Address"
    }),
    field: 'street1' ,
    colId: 'street1',
    tooltipField:'street1',
    headerTooltip: intl.formatMessage({
      id: "missing-invoices-grid-tooltip-partnerName",
      defaultMessage: "Partner address from EC Document Partner Profile"
    }),
    valueGetter: (params) => {
      if (params.data.street1) {
        return 'Street1='.concat(params.data?.street1).concat(', street2=').concat(params.data?.street2).concat(', city=')
        .concat(params.data?.city).concat(', country code=').concat(params.data?.countryCode).concat(', postal code=').concat(params.data?.postalCode)
        .concat(', region=').concat(params.data?.region).concat(', region code=').concat(params.data?.regionCode)
      }
      return "";
    },
    filter: true,
    width: 831,
    minWidth : 831,
  },
  {
    headerName: intl.formatMessage({
      id: "missing-invoices-grid-header-partnerAddressFromPartnerAddress",
      defaultMessage: "Correct Partner Address"
    }),
    field: 'partnerStreet1FromPartnerAddress' ,
    colId: 'partnerStreet1FromPartnerAddress',
    tooltipField:'partnerStreet1FromPartnerAddress',
    headerTooltip: intl.formatMessage({
      id: "missing-invoices-grid-tooltip-partnerAddressFromPartnerAddress",
      defaultMessage: "Partner address from Partner Address table"
    }),
    valueGetter: (params) => {
      if (params.data.street1) {
        return 'Street1='.concat(params.data?.partnerStreet1FromPartnerAddress).concat(', street2=').concat(params.data?.partnerStreet2FromPartnerAddress).concat(', city=')
        .concat(params.data?.partnerCityFromPartnerAddress).concat(', country code=').concat(params.data?.partnerCountryCodeFromPartnerAddress).concat(', postal code=').concat(params.data?.partnerPostalCodeFromPartnerAddress)
        .concat(', region=').concat(params.data?.partnerRegionFromPartnerAddress).concat(', region code=').concat(params.data?.partnerRegionCodeFromPartnerAddress)
      }
      return "";
    },
    cellStyle: params => {
      if (params?.data?.partnerStreet1FromPartnerAddress?.trim() !== params?.data?.street1?.trim()) {
        return { backgroundColor: 'yellow' };
      }
      return null; // Return null to use the default style
    },
    filter: true,
    width: 852,
    minWidth : 852,
  },
  {
    headerName: intl.formatMessage({
      id: "missing-invoices-grid-header-partnerVatCode",
      defaultMessage: "Partner VAT Code"
    }),
    field: 'partnerVatCode' ,
    colId: 'partnerVatCode',
    tooltipField:'partnerVatCode',
    headerTooltip: intl.formatMessage({
      id: "missing-invoices-grid-tooltip-partnerVatCode",
      defaultMessage: "Partner VAT Code from EC Document Partner Profile"
    }),
    filter: true,
    width: 172,
    minWidth : 172,
  },
  {
    headerName: intl.formatMessage({
      id: "missing-invoices-grid-header-partnerVatCodeFromPartnerVatCode",
      defaultMessage: "Suggested Partner VAT Code"
    }),
    field: 'partnerVatCodeFromPartnerVatCode' ,
    colId: 'partnerVatCodeFromPartnerVatCode',
    tooltipField:'partnerVatCodeFromPartnerVatCode',
    headerTooltip: intl.formatMessage({
      id: "missing-invoices-grid-tooltip-partnerVatCodeFromPartnerVatCode",
      defaultMessage: "Partner VAT Code from Partner VAT code table, This is just a suggested VAT Code and the partner could have selected a different one during capture process"
    }),
    cellStyle: params => {
      if (params?.data?.partnerVatCodeFromPartnerVatCode?.trim() !== params?.data?.partnerVatCode?.trim()) {
        return { backgroundColor: 'yellow' };
      }
      return null; // Return null to use the default style
    },
    filter: true,
    width: 242,
    minWidth : 242,
  },
  {
    headerName: intl.formatMessage({
      id: "missing-invoices-grid-header-partnerVatCountryCode",
      defaultMessage: "Partner VAT Code"
    }),
    field: 'partnerVatCountryCode' ,
    colId: 'partnerVatCountryCode',
    tooltipField:'partnerVatCountryCode',
    headerTooltip: intl.formatMessage({
      id: "missing-invoices-grid-tooltip-partnerVatCountryCode",
      defaultMessage: "Partner VAT Code from EC Document Partner Profile"
    }),
    filter: true,
    width: 172,
    minWidth : 172,
  },
  {
    headerName: intl.formatMessage({
      id: "missing-invoices-grid-header-partnerVatCountryCodeFromPartnerVatCode",
      defaultMessage: "Suggested Partner VAT Country Code"
    }),
    field: 'partnerVatCountryCodeFromPartnerVatCode' ,
    colId: 'partnerVatCountryCodeFromPartnerVatCode',
    tooltipField:'partnerVatCountryCodeFromPartnerVatCode',
    headerTooltip: intl.formatMessage({
      id: "missing-invoices-grid-tooltip-partnerVatCountryCodeFromPartnerVatCode",
      defaultMessage: "Partner VAT Country Code from Partner VAT code table, this country code is based on the partner address country"
    }),
    cellStyle: params => {
      if (params?.data?.partnerVatCodeFromPartnerVatCode?.trim() !== params?.data?.partnerVatCode?.trim()) {
        return { backgroundColor: 'yellow' };
      }
      return null; // Return null to use the default style
    },
    filter: true,
    width: 298,
    minWidth : 298,
  },
  {
    headerName: intl.formatMessage({
      id: "missing-invoices-grid-header-companyCode",
      defaultMessage: "Company Code"
    }),
    field: 'companyCode',
    colId: 'companyCode',
    tooltipField:'companyCode',
    headerTooltip: intl.formatMessage({
      id: "missing-invoices-grid-tooltip-companyCode",
      defaultMessage: "Company Code from EC Document Partner Profile"
    }),
    filter: true,
    width: 157,
    minWidth : 157,
  },
  {
    headerName: intl.formatMessage({
      id: "missing-invoices-grid-header-correct-companyCode",
      defaultMessage: "Correct Company Code"
    }),
    field: 'companyCodeFromPartnerCompanySystem',
    colId: 'companyCodeFromPartnerCompanySystem',
    tooltipField:'companyCodeFromPartnerCompanySystem',
    headerTooltip:intl.formatMessage({
      id: "missing-invoices-grid-tooltip-Format",
      defaultMessage: "Correct Company Code from Partner Company System"
    }),
    cellStyle: params => {
      if (params?.data?.companyCodeFromPartnerCompanySystem?.trim() !== params?.data?.companyCode?.trim()) {
        return { backgroundColor: 'yellow' };
      }
      return null; // Return null to use the default style
    },
    filter: true,
    width: 207,
    minWidth : 207,
  },
  {
    headerName: intl.formatMessage({
      id: "missing-invoices-grid-header-companyNameFromPartnerCompanySystem",
      defaultMessage: "Correct Company Name"
    }),
    colId: 'companyNameFromPartnerCompanySystem',
    field: 'companyNameFromPartnerCompanySystem',
    tooltipField:'companyNameFromPartnerCompanySystem',
    headerTooltip: intl.formatMessage({
      id: "missing-invoices-grid-tooltip-companyNameFromPartnerCompanySystem",
      defaultMessage: "Correct Company Name from Partner Company System"
    }),
    filter: true,
    width: 212,
    minWidth : 212,
  },
  {
    headerName: intl.formatMessage({
      id: "missing-invoices-grid-header-companyVatCode",
      defaultMessage: "Company VAT Code"
    }),
    colId: 'companyVatCode',
    field: 'companyVatCode',
    tooltipField:'companyVatCode',
    headerTooltip: intl.formatMessage({
      id: "missing-invoices-grid-tooltip-companyVatCode",
      defaultMessage: "Company VAT Code from EC Document Partner Profile"
    }),
    filter: true,
    width: 185,
    minWidth : 185,
  },
  {
    headerName: intl.formatMessage({
      id: "missing-invoices-grid-header-companyVatCodeFromCompanyVatCode",
      defaultMessage: "Suggested Company VAT Code"
    }),
    colId: 'companyVatCodeFromCompanyVatCode',
    field: 'companyVatCodeFromCompanyVatCode',
    tooltipField:'companyVatCodeFromCompanyVatCode',
    headerTooltip: intl.formatMessage({
      id: "missing-invoices-grid-tooltip-companyVatCodeFromCompanyVatCode",
      defaultMessage: "Possible correct Company VAT Code, we cannot be sure what the suer selected on the UI"
    }),
    cellStyle: params => {
      if (params?.data?.companyVatCodeFromCompanyVatCode?.trim() !== params?.data?.companyVatCode?.trim()) {
        return { backgroundColor: 'yellow' };
      }
      return null; // Return null to use the default style
    },
    filter: true,
    width: 256,
    minWidth : 256,
  },
  {
    headerName: intl.formatMessage({
      id: "missing-invoices-grid-header-companyVatCountryCode",
      defaultMessage: "Company VAT Country Code"
    }),
    colId: 'companyVatCountryCode',
    field: 'companyVatCountryCode',
    tooltipField:'companyVatCountryCode',
    headerTooltip: intl.formatMessage({
      id: "missing-invoices-grid-tooltip-companyVatCountryCode",
      defaultMessage: "Company VAT Country Code from EC Document Partner Profile"
    }),
    filter: true,
    width: 240,
    minWidth : 240,
  },
  {
    headerName: intl.formatMessage({
      id: "missing-invoices-grid-header-companyVatCountryCodeFromCompanyVatCode",
      defaultMessage: "Suggested Company VAT Country Code"
    }),
    colId: 'companyVatCountryCodeFromCompanyVatCode',
    field: 'companyVatCountryCodeFromCompanyVatCode',
    tooltipField:'companyVatCountryCodeFromCompanyVatCode',
    headerTooltip: intl.formatMessage({
      id: "missing-invoices-grid-tooltip-companyVatCountryCodeFromCompanyVatCode",
      defaultMessage: "Company VAT Country Code from Company VAT Code table"
    }),
    cellStyle: params => {
      if (params?.data?.companyVatCountryCodeFromCompanyVatCode?.trim() !== params?.data?.companyVatCountryCode?.trim()) {
        return { backgroundColor: 'yellow' };
      }
      return null; // Return null to use the default style
    },
    filter: true,
    width: 256,
    minWidth : 256,
  },
  {
    headerName: intl.formatMessage({
      id: "missing-invoices-grid-header-countryCode",
      defaultMessage: "Country Code"
    }),
    colId: 'countryCode',
    field: 'countryCode',
    tooltipField:'countryCode',
    headerTooltip: intl.formatMessage({
      id: "missing-invoices-grid-tooltip-countryCode",
      defaultMessage: "Country Code from EC Document Partner Profile"
    }),
    filter: true,
    width: 148,
    minWidth : 148,
  },
  {
    headerName: intl.formatMessage({
      id: "missing-invoices-grid-header-messageId",
      defaultMessage: "Message Id"
    }),
    colId: 'messageId',
    field: 'messageId',
    tooltipField:'messageId',
    headerTooltip: intl.formatMessage({
      id: "missing-invoices-grid-header-messageId",
      defaultMessage: "Message ID from EC Document"
    }),
    filter: true,
    width: 308,
    minWidth : 308,
  },
  {
    headerName: intl.formatMessage({
      id: "missing-invoices-grid-header-logInfoId",
      defaultMessage: "Log Info Id"
    }),
    colId: 'logInfoId',
    field: 'logInfoId',
    tooltipField:'logInfoId',
    headerTooltip: intl.formatMessage({
      id: "missing-invoices-grid-tooltip-logInfoId",
      defaultMessage: "The Log Info If , only if t_log_info record exists"
    }),
    filter: true,
    width: 129,
    minWidth : 129,
  },
  {
    headerName: intl.formatMessage({
      id: "missing-invoices-grid-header-fileName",
      defaultMessage: "File Name"
    }),
    colId: 'fileName',
    field: 'fileName',
    tooltipField:'fileName',
    headerTooltip: intl.formatMessage({
      id: "missing-invoices-grid-tooltip-fileName",
      defaultMessage: "The File Name, only if t_log_info record exists"
    }),
    cellRenderer: MissingInvoicesRowOptionFileName,
    cellStyle: params => {
      if (!params?.data?.blobId && params?.data?.logInfoId) {
        return { backgroundColor: 'yellow' };
      }
      return null; // Return null to use the default style
    },      
    filter: true,
    width: 329,
    minWidth : 329,
  },
  {
    headerName: intl.formatMessage({
      id: "missing-invoices-grid-header-blobId",
      defaultMessage: "File Blob Id"
    }),
    colId: 'blobId',
    field: 'blobId',
    tooltipField:'blobId',
    headerTooltip: intl.formatMessage({
      id: "missing-invoices-grid-tooltip-blobId",
      defaultMessage: "The File Blob Id, only if t_log_info and t_f_blob record exists"
    }),
  filter: true,
  width: 131,
  minWidth : 131,
  },
  {
    headerName: intl.formatMessage({
      id: "missing-invoices-grid-header-discrepancies",
      defaultMessage: "Discrepancies"
    }),
    colId: 'discrepancies',
    field: 'discrepancies',
    tooltipField:'discrepancies',
    headerTooltip: intl.formatMessage({
      id: "missing-invoices-grid-tooltip-discrepancies",
      defaultMessage: "Will be true if any discrepancies are found in the data"
    }),
  filter: true,
  width: 146,
  minWidth : 146,
  },
];

