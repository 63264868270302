import React, {useEffect, useState} from "react";
import {Card, Col, Collapse, DatePicker, Form, FormInstance, Input, InputNumber, Row, Select, Tooltip} from "antd";
import styles from "../styling/ecap.module.css";
import {getCurrencyList_getCurrencyList} from "../__generated__/getCurrencyList";
import {getCurrencyListData} from "../services/SelectServices";
import {CaretRightOutlined} from "@ant-design/icons";
import {IAppComponentProps, PortalUser} from "../../../components";
import {LineItemRecord, LineItemsState} from "./LineItems";
import {isNotNull, readNestedFormValue, roundNumber} from "../../../utils/Utils";
import {useIntl} from "react-intl";
import {useAppDispatch, useAppSelector} from "../../../main/hooks";
import {Form_Item} from "../componets/FormItem-component";
import {RuleResultCache} from "../util/RuleResultCache";
import {
    antDInputNumberFormatter,
    antDInputParser,
    convertToUserSpecifiedNumericFormat,
    getDateFormatOrDefault
} from "../../users/UserFormatPreferenceServices";
import dayjs from "dayjs";
import {updateCollapsedState} from "../IStoredDocumentRecordSlice";

interface Totals {
    netAmount: number;
    taxableAmount: number;
    taxAmount: number;
    grossAmount: number;
}

function getSelectedCurrency(currencies: getCurrencyList_getCurrencyList[], form: FormInstance<any>) {
    if (currencies) {
        const result = currencies.filter(currency => currency.id === form.getFieldValue('headerCurrency'));
        return result && result.length > 0 ? result[0] : null;
    }

    return null;
}

function getCurrencyMaxDecimals(currencies: getCurrencyList_getCurrencyList[], form: FormInstance<any>) {
    const selectedCurrency = getSelectedCurrency(currencies, form);
    let currMaxDecimals = 2;
    if (selectedCurrency) {
        if (selectedCurrency.decimalPlaces !== undefined) {
            currMaxDecimals = selectedCurrency.decimalPlaces;
        }
    }
    return currMaxDecimals;
}

function getSelectedLocalCurrency(currencies: getCurrencyList_getCurrencyList[], form: FormInstance<any>) {
    if (currencies) {
        const localCurrency = readNestedFormValue(form, "document.localCurrencyCodeId");
        const result = currencies.filter(currency => currency.id === localCurrency);
        return result && result.length > 0 ? result[0] : null;
    }

    return null;
}

function getLocalCurrencyMaxDecimals(currencies: getCurrencyList_getCurrencyList[], form: FormInstance<any>) {
    const selectedCurrency = getSelectedLocalCurrency(currencies, form);
    let currMaxDecimals = 2;
    if (selectedCurrency) {
        if (selectedCurrency.decimalPlaces !== undefined) {
            currMaxDecimals = selectedCurrency.decimalPlaces;
        }
    }
    return currMaxDecimals;
}

type LineItemTotalProperties =
    { form: FormInstance<any>, lineItemState: LineItemsState, refreshCallback?: (arg:()=>void)=>void, }
    & IAppComponentProps;

type LocalCurrencyProperties = {
    form: FormInstance<any>,
    currencies: getCurrencyList_getCurrencyList[],
    totals: Totals,
    currentUser:PortalUser
};

function LocalCurrency(props: LocalCurrencyProperties) {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const [exchangeRate,setExchangeRate] =
        useState<number>(props.form.getFieldValue(["document", "exchangeRate"]) || 1);
    const [flag,setFlag] = useState<boolean>(false);

    const {document} = useAppSelector((state) => state.document);
    const rules  = useAppSelector((state) => state.rules);

    const {Panel} = Collapse;
    const {Option} = Select;

    useEffect(()=> {
        const valueNullCheck = isNotNull(props.form.getFieldValue(["document", "exchangeRate"]));
        // eslint-disable-next-line eqeqeq
        if (valueNullCheck && props.form.getFieldValue(["document", "exchangeRate"]) != exchangeRate) {
            setExchangeRate(props.form.getFieldValue(["document", "exchangeRate"]));
        }
        else {
            const localCurrencyMaxDecimals = getLocalCurrencyMaxDecimals(props.currencies, props.form);

            const newValues = {
                document:
                    {
                        localCurrencyGrossAmount:  parseFloat(roundNumber((exchangeRate && exchangeRate*props.totals.grossAmount) || 0, localCurrencyMaxDecimals)),
                        localCurrencyNetAmount:  parseFloat(roundNumber((exchangeRate && exchangeRate*props.totals.netAmount) || 0, localCurrencyMaxDecimals)),
                        localCurrencyTaxAmount:  parseFloat(roundNumber((exchangeRate && exchangeRate*props.totals.taxAmount) || 0, localCurrencyMaxDecimals)),
                        localCurrencyTaxableAmount:  parseFloat(roundNumber((exchangeRate && exchangeRate*props.totals.taxableAmount) || 0, localCurrencyMaxDecimals))
                    }
            };

            if (!valueNullCheck) {
                newValues.document["exchangeRate"] = 1;
            }

            props.form.setFieldsValue(newValues);
        }
    });

    return new RuleResultCache(rules, props.form, document, ['Local_Currency','Exchange_Rate','Exchange_Rate_Date'], intl).render(propertyCache =>(<Card className={styles.lineTotalCollapsableCard}>
        <Row key="localCurrencyKey" gutter={24} className="mt10">
            <Col>
                <Collapse
                    activeKey={document.collapsedState}
                    onChange={(newActiveKeys) => {
                        dispatch(updateCollapsedState(newActiveKeys));
                    }}
                    defaultActiveKey={1}
                    bordered={false}
                    expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}
                    expandIconPosition={"left"}>
                    <Panel
                        header={intl.formatMessage({'id':'show-local-currency-amounts','defaultMessage':'Show Local Currency Amounts'})}
                        forceRender={true}
                        key={'localCurrencyKeyPanel'}>
                        <div className={`${styles.lineItemTotalsLocalCurrency} ${styles.lineItemTotalsData}`}>
                            <div>
                                <span hidden={!propertyCache.isMandatory('Local_Currency')}
                                      className={styles.mandatoryField}>*</span>
                                {intl.formatMessage({
                                    'id': 'line-item-totals-local-currency-title',
                                    'defaultMessage': 'Local Currency'
                                })}
                            </div>
                            <div>
                                <span hidden={!propertyCache.isMandatory('Exchange_Rate')} className={styles.mandatoryField}>*</span>
                                { intl.formatMessage({'id':'line-item-totals-exchange-rate-title','defaultMessage': 'Exchange Rate'})}
                            </div>
                            <div>
                                <span hidden={!propertyCache.isMandatory('Exchange_Rate_Date')} className={styles.mandatoryField}>*</span>
                                { intl.formatMessage({'id':'line-item-totals-exchange-rate-date-title','defaultMessage': 'Exchange Rate Date'})}
                            </div>
                            <div>
                                { intl.formatMessage({'id':'line-item-totals-net-amount-title','defaultMessage': 'Net Amount'})}
                            </div>
                            <div>
                                { intl.formatMessage({'id':'line-item-totals-taxable-amount-title','defaultMessage': 'Taxable Amount'})}
                            </div>
                            <div>
                                { intl.formatMessage({'id':'line-item-totals-tax-amount-title','defaultMessage': 'Tax Amount'})}
                            </div>
                            <div>
                                { intl.formatMessage({'id':'line-item-totals-gross-amount-title','defaultMessage': 'Gross Amount'})}
                            </div>

                            <div>
                                <Form_Item name={["document", "localCurrencyCodeId"]}
                                           label={''}
                                           itemRules={propertyCache.fetchRule('Local_Currency')}>
                                    <Select
                                        loading={!(props.currencies && props.currencies.length > 0)}
                                        showSearch={true} onChange={()=> setFlag(current=>!current)}
                                        placeholder={ intl.formatMessage({'id':'select-option','defaultMessage': 'Select'})}>
                                        {props.currencies &&
                                            props.currencies.map((currency, index) => (
                                                <Option className={styles.currencySelect}
                                                        key={`line_item_total_currency_${index}_${currency.id}`}
                                                        value={currency.id}
                                                        data-testid={currency.id}>
                                                    {currency.id}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Form_Item>
                            </div>

                            <div>
                                <Form_Item name={["document", "exchangeRate"]} label={''}
                                    itemRules={propertyCache.fetchRule('Exchange_Rate')}>
                                    <InputNumber<number> controls={false}
                                                 formatter={(value,info)=>antDInputNumberFormatter(props.currentUser, value,  info, 10,true)}
                                                 parser={(value)=> antDInputParser(props.currentUser,value)}
                                                 onChange={(val:number)=> setExchangeRate(val)} />
                                </Form_Item>
                            </div>

                            <div>
                                <Form_Item name={["document", "exchangeRateDate"]} label=""
                                           itemRules={propertyCache.fetchRule('Exchange_Rate_Date')}>
                                    <DatePicker
                                        format={getDateFormatOrDefault(props.currentUser)}
                                        placeholder=""
                                        disabledDate={(current) => current.isAfter(dayjs())}
                                        allowClear={true}
                                        style={{width: "100%"}}
                                        inputReadOnly={true}
                                    />
                                </Form_Item>
                            </div>

                            <div>
                                <Form.Item name={["document", "localCurrencyNetAmount"]}>
                                    <InputNumber disabled={true}
                                                 formatter={(value,info)=>antDInputNumberFormatter(props.currentUser, value,  info,getLocalCurrencyMaxDecimals(props.currencies, props.form))}
                                                 parser={(value)=> antDInputParser(props.currentUser,value)}
                                                 precision={getLocalCurrencyMaxDecimals(props.currencies, props.form)}/>
                                </Form.Item>
                            </div>

                            <div>
                                <Form.Item name={["document", "localCurrencyTaxableAmount"]}>
                                    <InputNumber disabled={true}
                                                 formatter={(value,info)=>antDInputNumberFormatter(props.currentUser, value,  info,getLocalCurrencyMaxDecimals(props.currencies, props.form))}
                                                 parser={(value)=> antDInputParser(props.currentUser,value)}
                                                 precision={getLocalCurrencyMaxDecimals(props.currencies, props.form)}/>
                                </Form.Item>
                            </div>

                            <div>
                                <Form.Item name={["document", "localCurrencyTaxAmount"]}>
                                    <InputNumber disabled={true}
                                                 formatter={(value,info)=>antDInputNumberFormatter(props.currentUser, value,  info,getLocalCurrencyMaxDecimals(props.currencies, props.form))}
                                                 parser={(value)=> antDInputParser(props.currentUser,value)}
                                                 precision={getLocalCurrencyMaxDecimals(props.currencies, props.form)}/>
                                </Form.Item>
                            </div>
                            <div>
                                <Form.Item name={["document", "localCurrencyGrossAmount"]}>
                                    <InputNumber disabled={true}
                                                 formatter={(value,info)=>antDInputNumberFormatter(props.currentUser, value,  info,getLocalCurrencyMaxDecimals(props.currencies, props.form))}
                                                 parser={(value)=> antDInputParser(props.currentUser,value)}
                                                 precision={getLocalCurrencyMaxDecimals(props.currencies, props.form)}/>
                                </Form.Item>
                            </div>

                        </div>
                    </Panel>
                </Collapse>
            </Col>
        </Row>
    </Card>));
}

type TaxSummaryProperties = {
    form: FormInstance<any>,
    allCurrencies: getCurrencyList_getCurrencyList[] ,
    totals: Totals,
    lineItems:LineItemRecord[],
    currentUser:PortalUser
};

function TaxSummary(props: TaxSummaryProperties) {
    const {Panel} = Collapse;

    const intl = useIntl();

    const summary = {};
    const summaryElements = [];

    props?.lineItems.forEach(lineItem=> {
        const taxRate = lineItem.taxRate;
        const taxLawReference = lineItem.taxLawReference || '';

        // eslint-disable-next-line prefer-const
        let [taxTypeId, taxType] = taxLawReference.split('_');

        if (taxRate === 0 && !taxTypeId) {
            taxType = intl.formatMessage({'id':'zero-rated','defaultMessage':'Zero-Rated'});
        } else if (taxRate > 0 && !taxTypeId) {
            taxType =  intl.formatMessage({'id':'standard-rated','defaultMessage':'Standard Rate'});
        }

        let summaryElement = summary[`${taxType}|${taxRate}`];
        if (!summaryElement) {
            summaryElement = [];
            summary[`${taxType}|${taxRate}`] = summaryElement;
        }

        const taxableAmount = lineItem.taxableAmount();
        const totalTax = lineItem.taxAmount();
        summaryElement.push({taxableAmount, totalTax});
    });

    for (const key in summary) {
        const typeRate = key.split('|');
        const arrItems = summary[key];
        let taxableAmount = 0;
        let totalTax = 0;
        for (let i = 0; i < arrItems.length; i++) {
            taxableAmount += arrItems[i].taxableAmount;
            totalTax += arrItems[i].totalTax;
        }

        summaryElements.push(
            <div key={`tax_totals_${key}`} className={styles.lineItemTotalTaxSummary}>
                <div>
                    <Input value={typeRate[0]} disabled={true}/>
                </div>
                <div>
                    <InputNumber precision={2}
                                 value={convertToUserSpecifiedNumericFormat(props.currentUser, typeRate[1])}
                                 disabled={true}
                                 controls={false}/>
                </div>
                <div>
                    <InputNumber value={taxableAmount}
                                 formatter={(value,info)=>antDInputNumberFormatter(props.currentUser, value,  info,getCurrencyMaxDecimals(props.allCurrencies, props.form))}
                                 parser={(value)=> antDInputParser(props.currentUser,value)}
                                 precision={getCurrencyMaxDecimals(props.allCurrencies, props.form)} disabled={true}
                                 controls={false}/>
                </div>
                <div>
                    <InputNumber value={totalTax}
                                 formatter={(value,info)=>antDInputNumberFormatter(props.currentUser, value,  info,getCurrencyMaxDecimals(props.allCurrencies, props.form))}
                                 parser={(value)=> antDInputParser(props.currentUser,value)}
                                 precision={getCurrencyMaxDecimals(props.allCurrencies, props.form)}
                                 disabled={true} controls={false}/>
                </div>
            </div>)
    }


    return (<Card className={styles.lineTotalCollapsableCard}>
        <Row key="showTaxSummaryKey" gutter={24} className="mt10">
            <Col>
                <Collapse
                    bordered={false}
                    expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}
                    expandIconPosition={"left"}>
                    <Panel header={intl.formatMessage({'id':'show-tax-summary','defaultMessage':'Show Tax Summary'})} key={'showTaxSummaryPanel'}>
                        <div className={styles.lineItemTotalTaxSummary}>
                            <div> { intl.formatMessage({'id':'line-item-totals-tax-type-title','defaultMessage': 'Tax Type'})}</div>
                            <div>{ intl.formatMessage({'id':'line-item-totals-tax-rate-title','defaultMessage': 'Tax Rate'})}</div>
                            <div>{ intl.formatMessage({'id':'line-item-totals-tax-taxable-amount-title','defaultMessage': 'Taxable Amount'})}</div>
                            <div>{ intl.formatMessage({'id':'line-item-totals-tax-amount-title','defaultMessage': 'Tax Amount'})}</div>
                        </div>
                        <div id={'taxSummaryOutlet'} className={`${styles.lineItemTotalsData}`}>
                            {summaryElements}
                        </div>
                    </Panel>
                </Collapse>
            </Col>
        </Row>
    </Card>);
}

function calculateTotalsAndRender(lineItems: LineItemRecord[]): Totals {
    let netAmount: number = 0;
    let taxableAmount: number = 0;
    let taxAmount: number = 0;
    let grossAmount: number = 0;

    lineItems?.forEach(lineItem => {
        netAmount += lineItem.netAmount();
        taxAmount += lineItem.taxAmount();
        grossAmount += lineItem.grossAmount();
        taxableAmount += lineItem.taxableAmount();
    });

    return {netAmount, taxableAmount, taxAmount, grossAmount};
}

export default function LineItemTotals(props: LineItemTotalProperties) {
    const [loading, setLoading] = useState<boolean>(true);
    const [flag,setFlag] = useState<boolean>(false);
    const forceRefresh = ()=> setFlag(current=>!current);
    const [allCurrencies, setCurrencies] = useState<getCurrencyList_getCurrencyList[]>(null);

    const {document} = useAppSelector((state) => state.document);
    const rules  = useAppSelector((state) => state.rules);

    useEffect(() => {
        if (loading) {
            getCurrencyListData().then(result => {
                setCurrencies(result.getCurrencyList);
            })
            setLoading(false);
            if (props.refreshCallback) {
                props.refreshCallback(forceRefresh);
            }
        }

        const totals = calculateTotalsAndRender(props.lineItemState?.lineItems);
        props.form.setFieldsValue(
            {
                document: {
                    netAmount: totals.netAmount,
                    taxableAmount: totals.taxableAmount,
                    taxAmount: totals.taxAmount,
                    grossAmount: totals.grossAmount,
                }
            })
    });

    return new RuleResultCache(rules, props.form, document, [], props.intl).render(propertyCache => {
        return (<>
            <div className={`${styles.lineItemTotalsGrid} ${styles.lineItemTotalsData}`}>
                <div>
                    { props.intl.formatMessage({'id':'line-item-totals-invoice-currency-title','defaultMessage': 'Invoice Currency'})}
                </div>
                <div>
                    { props.intl.formatMessage({'id':'line-item-totals-net-amount-title','defaultMessage': 'Net Amount'})}
                </div>
                <div>
                    { props.intl.formatMessage({'id':'line-item-totals-taxable-amount-title','defaultMessage': 'Taxable Amount'})}
                </div>
                <div>
                    { props.intl.formatMessage({'id':'line-item-totals-tax-amount-title','defaultMessage': 'Tax Amount'})}
                </div>

                <div>
                    { props.intl.formatMessage({'id':'line-item-totals-gross-amount-title','defaultMessage': 'Gross Amount'})}
                </div>

                <div>
                    <Tooltip title={ props.intl.formatMessage({'id':'select-in-header','defaultMessage': 'Select in Header Details'})}>
                        <Form.Item name={'headerCurrency'}>
                            <Input disabled={true} placeholder={ props.intl.formatMessage({'id':'select-from-option','defaultMessage': 'Select in...'})}/>
                        </Form.Item>
                    </Tooltip>
                </div>

                <div>
                    <Form.Item name={['document','netAmount']}>
                        <InputNumber disabled={true}
                                     formatter={(value,info)=>antDInputNumberFormatter(props.currentUser, value,info,  getCurrencyMaxDecimals(allCurrencies, props.form))}
                                     parser={(value)=> antDInputParser(props.currentUser,value)}
                                     precision={getCurrencyMaxDecimals(allCurrencies, props.form)}/>
                    </Form.Item>
                </div>

                <div>
                    <Form.Item name={['document','taxableAmount']}>
                        <InputNumber disabled={true}
                                     formatter={(value,info)=>antDInputNumberFormatter(props.currentUser, value, info, getCurrencyMaxDecimals(allCurrencies, props.form))}
                                     parser={(value)=> antDInputParser(props.currentUser,value)}
                                     precision={getCurrencyMaxDecimals(allCurrencies, props.form)}/>
                    </Form.Item>
                </div>

                <div>
                    <Form.Item name={['document','taxAmount']}>
                        <InputNumber disabled={true}
                                     formatter={(value,info)=>antDInputNumberFormatter(props.currentUser, value,info,  getCurrencyMaxDecimals(allCurrencies, props.form))}
                                     parser={(value)=> antDInputParser(props.currentUser,value)}
                                     precision={getCurrencyMaxDecimals(allCurrencies, props.form)}/>
                    </Form.Item>
                </div>

                <div>
                    <Form.Item name={['document','grossAmount']}>
                        <InputNumber disabled={true}
                                     formatter={(value,info)=>antDInputNumberFormatter(props.currentUser, value,info,  getCurrencyMaxDecimals(allCurrencies, props.form))}
                                     parser={(value)=> antDInputParser(props.currentUser,value)}
                                     precision={getCurrencyMaxDecimals(allCurrencies, props.form)}/>
                    </Form.Item>
                </div>

                <div hidden={true}>
                    {/*An Almighty hack....*/}
                    {/*When you do a form.setFieldsValue and the fields you want to set*/}
                    {/*Are in double nested in a Collapsable, if you do a getFieldsValue you need*/}
                    {/*to first expand the component before the value becomes readable. If the field*/}
                    {/*is not in view getFieldValue will not retrieve that value.*/}
                    {/*Forcing me to copy and paste and have duplicates of the fields in order to get*/}
                    {/*The getFieldValue to work.*/}
                    {/*The long term fix should be to fix state management for ecap*/}
                    <Form.Item name={["document", "localCurrencyNetAmount"]}>
                        <InputNumber disabled={true}
                                     formatter={(value,info)=>antDInputNumberFormatter(props.currentUser, value,info,getLocalCurrencyMaxDecimals(allCurrencies, props.form))}
                                     parser={(value)=> antDInputParser(props.currentUser,value)}
                                     precision={getLocalCurrencyMaxDecimals(allCurrencies, props.form)}/>
                    </Form.Item>
                    <Form.Item name={["document", "localCurrencyGrossAmount"]}>
                        <InputNumber disabled={true}
                                     formatter={(value,info)=>antDInputNumberFormatter(props.currentUser, value,info,  getLocalCurrencyMaxDecimals(allCurrencies, props.form))}
                                     parser={(value)=> antDInputParser(props.currentUser,value)}
                                     precision={getLocalCurrencyMaxDecimals(allCurrencies, props.form)}/>
                    </Form.Item>
                    <Form.Item name={["document", "localCurrencyTaxAmount"]}>
                        <InputNumber disabled={true}
                                     formatter={(value,info)=>antDInputNumberFormatter(props.currentUser, value,info,  getLocalCurrencyMaxDecimals(allCurrencies, props.form))}
                                     parser={(value)=> antDInputParser(props.currentUser,value)}
                                     precision={getLocalCurrencyMaxDecimals(allCurrencies, props.form)}/>
                    </Form.Item>
                    <Form.Item name={["document", "localCurrencyTaxableAmount"]}>
                        <InputNumber disabled={true}
                                     formatter={(value,info)=>antDInputNumberFormatter(props.currentUser, value, info, getLocalCurrencyMaxDecimals(allCurrencies, props.form))}
                                     parser={(value)=> antDInputParser(props.currentUser,value)}
                                     precision={getLocalCurrencyMaxDecimals(allCurrencies, props.form)}/>
                    </Form.Item>
                    <Form.Item name={["document", "localCurrencyCodeId"]}>
                        <Input disabled={true}/>
                    </Form.Item>
                    <Form.Item name={["document", "exchangeRate"]}>
                        <InputNumber disabled={true}
                                     precision={getLocalCurrencyMaxDecimals(allCurrencies, props.form)}/>
                    </Form.Item>
                    <Form.Item name={['document', 'exchangeRateDate']}>
                        <DatePicker
                            format={getDateFormatOrDefault(props.currentUser)}
                            disabled={true}
                            allowClear={true}
                            inputReadOnly={true}
                        />
                    </Form.Item>
                </div>
            </div>

            <LocalCurrency
                form={props.form}
                currencies={allCurrencies}
                totals={calculateTotalsAndRender(props.lineItemState?.lineItems)}
                currentUser={props.currentUser}/>
            <TaxSummary
                form={props.form}
                allCurrencies={allCurrencies}
                totals={calculateTotalsAndRender(props.lineItemState?.lineItems)}
                lineItems={props.lineItemState?.lineItems}
                currentUser={props.currentUser}/>
        </>)
    });
}