import React from 'react';
import { EntitlementFormProps } from './EntitlementInterfaces';
import RenderIfEntitled from '../../../components/EntitlementComponents';
import { Tabs } from 'antd';
import { getI18n } from '../../../utils/Utils';
import AppliedDistinctEntitlementsComponent from './AppliedDistinctEntitlementsComponent';
import AppliedPartnerEntitlementsComponent from './AppliedPartnerEntitlementsComponent';


const AppliedEntitlementsTabComponent: React.FunctionComponent<EntitlementFormProps> = (props) => {

    const tabItems = [
        {
            defaultActiveKey: '1',
            className: 'users-tabs ml20',
            key: '1',
            label: getI18n(
                'entitlements-dashboard-applied-distinct-entitlements-tab',
                'Distinct Entitlements',
                props.intl
            ),
            children: <AppliedDistinctEntitlementsComponent intl={props.intl} onLoading={props.onLoading} currentUser={props.currentUser}  />,
        },
        {
            defaultActiveKey: '1',
            className: 'users-tabs ml20',
            key: '2',
            label: getI18n(
                'entitlements-dashboard-applied-partner-entitlements-tab',
                'Partner Entitlements',
                props.intl
            ),
            children: <AppliedPartnerEntitlementsComponent intl={props.intl} onLoading={props.onLoading} currentUser={props.currentUser}  />,
        },

    ];

    return (
        <>
            <RenderIfEntitled
                entitlementToCheck="view@entitlements"
                distinctEntitlements={props.distinctEntitlements}
                intl={props.intl}
            >
                <h3>Master Data Maintenance</h3>
                <Tabs defaultActiveKey="1" items={tabItems} />
            </RenderIfEntitled>
        </>
    );
};


export default AppliedEntitlementsTabComponent;