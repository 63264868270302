// ______________________________________________________________________________
//
//       Project: e-Invoicing Documents Portal
//       File: https://atc.bmwgroup.net/bitbucket/scm/einvmrp/einvoicing-portal.git
//       Version: 0.0.1
// ______________________________________________________________________________
//
//    Created by: Lesley Netshiavhela <Lesley.Netshiavhela@partner.bmw.co.za>
//    Creation date:  2021/09/14
// ______________________________________________________________________________
//
//     Copyright: (C) BMW Group 2020, all rights reserved
// ______________________________________________________________________________
//
import { notification } from "antd";
import { FormattedMessage } from "react-intl";

export const showErrorNotification = (message) => {
  notification.error({
    message: "Error",
    description: message
  });
};

export const showErrorWithHeaderNotification = (errorHeader: string, message: string) => {
  notification.error({
    message: errorHeader,
    description: message
  });
};

export const showSuccessNotification = (message) => {
  notification.success({
    message: "Incoming documents reprocessed successfully",
    description: message
  });
};
export const showSuccessWithHeaderNotification = (messageHeader: string, message: string) => {
  notification.success({
    message: messageHeader,
    description: message
  });
};
