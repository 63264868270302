import React, { FunctionComponent, useEffect, useState } from 'react';
import { Alert, Col, ConfigProvider, Form, Modal, Row, Spin, Tabs, Typography } from 'antd';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { PageContainer } from '@ant-design/pro-layout';
import styles from '../../../../styles/scss/portal.scss';
import { useIntl } from 'react-intl';
import { useLazyQuery } from '@apollo/client';
import enUS from 'antd/lib/locale/en_US';
import { getI18n, isExternalUser } from '../../../../utils/Utils';
import { FailureDownloadDocsResp, IAppComponentProps } from '../../../../components';
import { getTemplateDocument } from './TemplateDocumentService';
import { AgGridReact } from 'ag-grid-react';
import { GridApi } from 'ag-grid-community';
import { isTemplateDocumentLoading, setTotalTemplateDocument } from '../../IStoredTemplateDocumentLoadingSlice';
import { useAppDispatch, useAppSelector } from '../../../../main/hooks';
import deDe from 'antd/lib/locale/de_DE';
import { ECAP_USER_ENTITLEMENT_BY_USERID } from '../../../../main/queries';
import { getEcapUserEntitlementResultByUserId } from '../../../../main/__generated__/getEcapUserEntitlementResultByUserId';
import { CustomRender } from '../../../../components/CustomRender';
import TemplateDocumentSearchForm from './TemplateListSearchForm';
import { MYDOCS_TABLE_HEADERS_TEMPLATES } from './templateDocumentUtils';
import { getDocumentTemplateDropdown } from '../__generated__/getDocumentTemplateDropdown';
import { MY_DOCS_TEMPLATE_DROPDOWN } from '../queries';

export const renderAsDate = (record) => <CustomRender record={record} renderAs="date" />;
export const renderAsButton = (record) => <CustomRender record={record} renderAs="button" />;

const TemplateDocumentList: FunctionComponent<IAppComponentProps> = (props) => {
    const dispatch = useAppDispatch();
    const currentUser = props.currentUser;
    const [form] = Form.useForm();
    const [docGridApi, setDocGridApi] = useState<GridApi>(undefined);
    const [docData, setDocData] = useState(undefined);
    const [docCount, setDocCount] = useState(0);
    const [formDataValues, setFormDataValues] = useState(undefined);
    const [failureDocuments, setFailureDocuments] = useState<FailureDownloadDocsResp[]>(undefined);
    const [partnerNos, setPartnerNos] = useState<string[]>(undefined);
    

    const { TabPane } = Tabs;
    const sizes: React.CSSProperties = {
        height: '100%',
        width: '98%',
    };

    const intl = useIntl();

    const { loading, total } = useAppSelector((state) => state.templateDocumentLoading);
    const [getUserEntitlement, {}] = useLazyQuery<getEcapUserEntitlementResultByUserId>(
        ECAP_USER_ENTITLEMENT_BY_USERID,
        {
            variables: { userId: props.currentUser.uid, authentication: localStorage.getItem('id_token') },
            fetchPolicy: 'cache-first',
        }
    );

    const onDocGridReady = (params) => {
        setDocGridApi(params.api);
    };

    const onExport = () => {
        const selectedRows = docGridApi.getSelectedRows();

        if (selectedRows.length === 0) {
            //console.log("Error")
            Modal.warning({
                title: getI18n('Warning-message', 'Export Document(s)', intl),
                content: getI18n(
                    'Warning-export-message',
                    'You have not selected any items to be exported. Please select an item first.',
                    intl
                ),
            });
        } else {
            docGridApi.exportDataAsExcel({
                onlySelectedAllPages: false,
                onlySelected: true,
                fileName: 'Template_Document_Export',
            });
        }
    };

    const onSearch = (values) => {
        dispatch(isTemplateDocumentLoading(true));
        setFormDataValues(values);

        const templateListInput = {
            templateNameId: values.templateNameId ? parseInt(values.templateNameId) : undefined,
            bmwCompanyId: values.bmwCompanyId ? parseInt(values.bmwCompanyId) : undefined,
            documentTypeId: values.documentTypeId ? parseInt(values.documentTypeId) : undefined,
            partnerNo: values.partnerNo ? values.partnerNo : undefined,
            myTemplates: values.myTemplates,
        };
        localStorage.setItem('templateListInput', JSON.stringify(templateListInput));
        Promise.resolve(getTemplateDocument(currentUser, templateListInput, intl))
            .then((data) => {
                setDocCount(data.getTemplateDocumentList.length);
                dispatch(setTotalTemplateDocument(data?.getTemplateDocumentList.length));
                setDocData(data.getTemplateDocumentList);
                dispatch(isTemplateDocumentLoading(false));
            })
            .catch((err) => {
                dispatch(isTemplateDocumentLoading(false));
                console.error(err);
            });
    };


    useEffect(() => {
        if (isExternalUser(props.distinctEntitlements)) {
            dispatch(isTemplateDocumentLoading(true));
            const partnerNos: string[] = [];
            getUserEntitlement()
                .then((response) => {
                    return new Promise<string[]>((resolve) => {
                        const userEntitlement = response.data?.getEcapUserEntitlementResultByUserId;
                        userEntitlement.partners.map((item) => {
                            partnerNos.push(item.partnerNo);
                        });
                        setPartnerNos(partnerNos);
                        resolve(partnerNos);
                        dispatch(isTemplateDocumentLoading(false));
                    });
                })
                .catch(() => {
                    dispatch(isTemplateDocumentLoading(false));
                });
        }
    }, [props.currentUser]);

    return (
        <>
            <ConfigProvider locale={localStorage.getItem('locale') === 'en' ? enUS : deDe}>
                <PageContainer
                    content={''}
                    extraContent={''}
                    className={styles.users}
                    style={{ background: 'white' }}
                    title={false}
                >
                    <Spin spinning={loading} size={'large'}>
                        <div style={{ ...sizes, minHeight: '200px' }} className="pl15 ag-theme-balham">
                            <Col md={24} sm={12} xs={24}>
                                <Tabs defaultActiveKey={'1'} className="users-tabs">
                                    <TabPane
                                        tab={getI18n('ecap-template-document-title', 'Template Documents', intl)}
                                        key="1"
                                    >
                                        <Typography.Title level={5} className={'mb10'}>
                                            {getI18n('e-cap-capture-template-title-massage', '', intl)}
                                        </Typography.Title>
                                        <TemplateDocumentSearchForm
                                            onExport={onExport}
                                            onSearch={onSearch}
                                            currentUser={currentUser}
                                            intl={props.intl}
                                            distinctEntitlements={props.distinctEntitlements}
                                            count={total}
                                        />
                                        {failureDocuments && (
                                            <Row gutter={24}>
                                                <Col span={24} className="mb5 mt5">
                                                    {failureDocuments.map((x, i) => (
                                                        <Alert
                                                            key={i}
                                                            message={`${x.errorMessage}`}
                                                            type="error"
                                                            showIcon={true}
                                                            closable={true}
                                                        />
                                                    ))}
                                                </Col>
                                            </Row>
                                        )}

                                        <Col md={24} sm={12} xs={24}>
                                            <Tabs defaultActiveKey={'1'}>
                                                <TabPane
                                                    tab={getI18n(
                                                        'ecap-template-document-title',
                                                        'Template Documents',
                                                        intl
                                                    )}
                                                    key="1"
                                                >
                                                    <div style={{ flex: 'auto', height: '500px' }}>
                                                        <AgGridReact
                                                            defaultColDef={{
                                                                enableValue: true,
                                                                sortable: true,
                                                                resizable: true,
                                                            }}
                                                            api={docGridApi}
                                                            columnDefs={MYDOCS_TABLE_HEADERS_TEMPLATES(
                                                                intl,
                                                                props.distinctEntitlements,
                                                                props.currentUser
                                                            )}
                                                            rowData={docData}
                                                            sideBar={false}
                                                            rowClass="agGridRows"
                                                            suppressMenuHide={true}
                                                            debug={false}
                                                            onGridSizeChanged={() => docGridApi.sizeColumnsToFit()}
                                                            onGridReady={onDocGridReady}
                                                            rowSelection={'multiple'}
                                                            pagination={true}
                                                            paginationPageSize={15}
                                                            suppressRowClickSelection={true}
                                                            enableBrowserTooltips={true}
                                                            data-testid="documents-test-id"
                                                        />
                                                    </div>
                                                </TabPane>
                                            </Tabs>
                                        </Col>
                                    </TabPane>
                                </Tabs>
                            </Col>
                        </div>
                    </Spin>
                </PageContainer>
            </ConfigProvider>
        </>
    );
};
export default TemplateDocumentList;
