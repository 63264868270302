import client from "../config/GraphQLApolloClient";
import { TRANSLATIONS_QUERY } from "./queries";

/**
 * Accepts language Code and object with transalations
 * fetches DB translations from t_md_translation and merges the key:value pairs from db in to new object with passed in messages
 * returns promise with merged messages
 * if error encoutered just return original passed in messages.
 * @param languageCode - language to fetch from db
 * @param messages - UI messages to merge with db messages
 * @returns 
 */
export const getTranslationsByLanguageCode = (languageCode: string, messages: any):Promise<any> => {
  try {
    const newMessages = JSON.parse(JSON.stringify(messages));
    return new Promise((resolve, reject) => {
      client.query({
        fetchPolicy: "network-only",
        query: TRANSLATIONS_QUERY,
        variables: { languageCode }
      })
        .then((response: any) => {
          response.data.getTranslations.forEach(element => {
            newMessages[element.phraseId] = element.translation;
          });
          resolve(newMessages);
        })
        .catch((err) => reject(err))
        ;
    });
  }
  catch (e: unknown) {
    console.error("Error reading DB translations");
    console.error(e);
    return new Promise((resolve, reject) => {
      resolve(messages);
    })
  }

};