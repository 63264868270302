import {IAppComponentProps} from "../../../components";
import React, { useState} from "react";
import {AgGridReact} from "ag-grid-react";
import {INCOMING_DOCS_DETAILS_COLS} from "./utils";


export interface IProps extends IAppComponentProps {
  loading: boolean;
  isLoading: any;
  onGridReady: any;
  rowData: any;
}

const DocumentDetailGrid: React.FunctionComponent<IProps> = ({
                                                                    isLoading,
                                                                    onGridReady,
                                                                    rowData
                                                                  }: IProps) => {
    
    const onRowDataChanged = (params) =>
    {
      console.log("Resize columns");
        params.columnApi.autoSizeAllColumns();
    }

    return(
            <AgGridReact
              defaultColDef={{
                    flex: 1,
                    enableValue: true,
                    sortable: true,
                    resizable: true
                }}
              columnDefs={INCOMING_DOCS_DETAILS_COLS}
              //rowData={rowData}
              sideBar={false}
              domLayout='autoHeight'
              rowClass="agGridRows"
              suppressMenuHide={true}
              pagination={true}
              paginationPageSize={15}
              suppressRowClickSelection={true}
              onGridReady={onGridReady}
              onFirstDataRendered={onRowDataChanged}
              rowSelection={"multiple"}
              rowModelType={"infinite"} //Required to use Server side data data model
              cacheBlockSize={15} //Required to use Server side data data model
              maxBlocksInCache={10} //Required to use Server side data data model
            />
    );
};
export default DocumentDetailGrid;