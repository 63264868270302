import React, {FunctionComponent, useState, useEffect } from "react";
import { IAppComponentProps } from "../components";
import { Alert } from "antd";
import {ExclamationCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";
import { useLazyQuery} from "@apollo/client";
import { QUERY_GET_BANNER_DETAILS } from "../pages/survey/queries";
import { getCurrentLocale } from  "../utils";
import dayjs from 'dayjs';
import {convertFromSpecifiedDateFormatToUserDefined} from "../pages/users/UserFormatPreferenceServices"

const Banner: FunctionComponent<IAppComponentProps> = (props) => {
  const user = props.currentUser;
  const [bannerExistsForCurrentTime, setBannerExistsForCurrentTime] = useState(false);
  const [bannerHeading, setBannerHeading] = useState("");
  const [bannerMessage, setBannerMessage] = useState("");
  const [messageType, setMessageType] = useState<"warning" | "success" | "info" | "error">();
  const [messageAlertVisible, setMessageAlertVisible] = useState(true);
  const dateTimeFormat = "YYYY-MM-DD HH:mm:ss";

  const [getBannerDetails] = useLazyQuery(QUERY_GET_BANNER_DETAILS,
    { fetchPolicy: "network-only" }
  );

  useEffect( () => {
   getBannerDetails().then
    (response => {

      if (response.data === undefined) {
        setBannerExistsForCurrentTime(false);
      } else {

const localeLanguage = getCurrentLocale();
      const startTimeFromDB = dayjs.utc(response.data?.getBannerDetailsData.startTime)?.local();
      const startTimeDateInYYYY_MM_DD = dayjs(startTimeFromDB).format(dateTimeFormat).substring(0,10);
      const startTimeHourMinutes = dayjs(startTimeFromDB).format(dateTimeFormat).substring(11,16);
      const endTimeFromDB = dayjs.utc(response.data?.getBannerDetailsData.endTime)?.local();
      const endTimeDateInYYYY_MM_DD = dayjs(endTimeFromDB).format(dateTimeFormat).substring(0,10);
      const endTimeHourMinutes = dayjs(endTimeFromDB).format(dateTimeFormat).substring(11,16);

      const startTimeVariable = "{start_time}"
      const endTimeVariable = "{end_time}"
      const convertStartTimeToUserPreferenceFormat = convertFromSpecifiedDateFormatToUserDefined(user, startTimeDateInYYYY_MM_DD, "YYYY-MM-DD")
      const startTimeAddIntlTime = `${convertStartTimeToUserPreferenceFormat} ${startTimeHourMinutes}`;
      const convertEndTimeToUserPreferenceFormat = convertFromSpecifiedDateFormatToUserDefined(user, endTimeDateInYYYY_MM_DD, "YYYY-MM-DD")
      const endTimeAddIntlTime = `${convertEndTimeToUserPreferenceFormat} ${endTimeHourMinutes}`;
      const bannerMessageFromDbEn =response.data.getBannerDetailsData.bannerMessageEn;
      const bannerMessageFromDbDe =response.data.getBannerDetailsData.bannerMessageDe;

      if (localeLanguage === "en" && bannerMessageFromDbEn !== null) {
      let bannerMessageAddIntlTime = bannerMessageFromDbEn.replace(startTimeVariable, startTimeAddIntlTime)
      bannerMessageAddIntlTime = bannerMessageAddIntlTime.replace(endTimeVariable, endTimeAddIntlTime)
      setBannerMessage(bannerMessageAddIntlTime);
      setBannerHeading(response.data.getBannerDetailsData.bannerHeadingEn);
      setBannerExistsForCurrentTime(true);
      }
      else if (localeLanguage === "de" && bannerMessageFromDbDe !== null
      ) {
           let bannerMessageAddIntlTime = bannerMessageFromDbDe.replace(startTimeVariable, startTimeAddIntlTime)
          bannerMessageAddIntlTime = bannerMessageAddIntlTime.replace(endTimeVariable, endTimeAddIntlTime)
          setBannerMessage(bannerMessageAddIntlTime);
          setBannerHeading(response.data.getBannerDetailsData.bannerHeadingDe);
          setBannerExistsForCurrentTime(true);
      }
      setMessageType(response.data.getBannerDetailsData.messageType);
    }
  }
    );
  },[user] )

    const messageAlertToggle = () => {
        setMessageAlertVisible(!messageAlertVisible)
    }
    return (
        <div style={{marginTop: "53px"}} data-testid={'banner-outter-div'}>
            {bannerExistsForCurrentTime && messageAlertVisible === true ?
                (<Alert
                    description={
                        <div dangerouslySetInnerHTML={{__html: bannerMessage}}/>
                    }
                    type={messageType}
                    style={{
                        zIndex: 999,
                        marginBottom: "10",
                        textAlign: "center",
                        alignItems: "center",
                        height: "50px",
                        backgroundColor: `${messageType} === error ? "#FFEFEF" :${messageType} === warning ? "#FFF7DE" :${messageType} === info ? "#ECF3FF" : "#E5FCEB`
                    }}
                    action={<CloseCircleOutlined style={{
                        fontSize: '16px',
                        color: `${messageType} === error ? "#E31F35" :${messageType} === warning ? "#353A41" :${messageType} === info ? "#0071C5" : "#E5FCEB`
                    }} onClick={messageAlertToggle}/>}
                />)
                : messageAlertVisible === false &&
                (<Alert
                    message={<div dangerouslySetInnerHTML={{__html: bannerHeading}}/>}
                    type={messageType}
                    style={{
                        fontFamily: "BMW Group Condensed",
                        zIndex: 999,
                        textAlign: "right",
                        marginRight: "0",
                        marginLeft: "auto",
                        height: "26px",
                        backgroundColor: `${messageType} === error ? "#FFEFEF" :${messageType} === warning ? "#FFF7DE" :${messageType} === info ? "#ECF3FF" : "#E5FCEB`
                    }}
                    action={
                        <ExclamationCircleOutlined style={{
                            fontSize: '16px',
                            marginLeft: "10px",
                            color: `${messageType} === error ? "#E31F35" :${messageType} === warning ? "#353A41" :${messageType} === info ? "#0071C5" : "#E5FCEB`
                        }} onClick={messageAlertToggle}/>
                    }
                />)}
        </div>
    )
}
export default Banner;