import { gql } from "@apollo/client";

export const TRANSLATIONS_QUERY = gql`
    query getTranslations($languageCode: String!) {
        getTranslations(languageCode: $languageCode) {
            languageCode
            phraseId
            translation
        }
    }
`;