import React, { Component } from "react";
import { Spin } from "antd";
import { IAppComponentProps, PortalUser } from "../index";
import { getUserNotificationCount } from "../../pages/notifications/NotificationService";
import { BellOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export type GlobalHeaderRightProps = {
  currentUser?: PortalUser;
  fetchingNotices?: boolean;
  onNoticeVisibleChange?: (visible: boolean) => void;
  onNoticeClear?: (tabName?: string) => void;
} & IAppComponentProps;

const noNotificationStyle = { marginTop: 10 };
const notificationStyle = { marginTop: 10, color: "red" };

interface myState {
  notificationCount: number,
  loading: boolean
}

class GlobalHeaderRight extends Component<GlobalHeaderRightProps, myState> {

  constructor(props) {
    super(props);
    this.state = { notificationCount: 0, loading: false };
  }

  componentWillUnmount(): void {
    Promise.reject("").then().catch();
  }

  componentDidMount() {
    this.setState({ loading: true });
    Promise.resolve(getUserNotificationCount())
      .then((response) => {
        if (response.getNotificationCount.notificationCount !== null) {
          this.setState({ notificationCount: response.getNotificationCount.notificationCount });
        } else {
          this.setState({ notificationCount: 0 });
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.error(err);
        this.setState({ loading: false });
      });

  }


    render () {
      const { currentUser, fetchingNotices, onNoticeVisibleChange } = this.props;
      const location = window.location.pathname;
      // eslint-disable-next-line no-console
      //console.log(`Location : ${location}`);

      return (
        <Spin spinning={this.state.loading} size={"large"}>
          <Link id="Notifications" to={"/notifications/notifications"}
                style={{ color: "#4a576d" }}>
            <BellOutlined disabled={true}
                          style={this.state.notificationCount === 0 ? noNotificationStyle : notificationStyle} />
            {this.state.notificationCount}
          </Link>
        </Spin>
      );
    }
}

export default GlobalHeaderRight
