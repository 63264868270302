import { renderAsDateTime } from "../users/Users";
import client from "../../config/GraphQLApolloClient";
import { ALL_SURVEY_OVERVIEW_FOR_LOGGED_IN_USER_QUERY, QUERY_SURVEY_MANAGEMENT_DATA } from "./queries";
import { renderAsDate } from "../documents/myDocuments";
import { IntlShape } from "react-intl/src/types";
import {  SurveyCreateRequest, SurveyOverviewFilter } from "__generated__/globalTypes";
import { CREATE_SURVEY } from "./mutations";
import { createSurvey, createSurvey_createSurvey } from "./__generated__/createSurvey";
import {PortalUser} from "../../components";
import {convertFromSpecifiedDateFormatToUserDefined} from "../users/UserFormatPreferenceServices";


export function agGridGetTranslationFromPhraseID(phraseID:string, untranslatedValue:string, intl: IntlShape) {
  return (params) => {
    if (params.data[phraseID]) {
      return intl.formatMessage({id: params.data[phraseID], defaultMessage: params.data[untranslatedValue]});
    }
    return params.data[untranslatedValue];
  };
}

export const SURVEY_OVERVIEW_TABLE_HEADERS = (intl: IntlShape, user:PortalUser) => [
  {
    headerName: intl.formatMessage({ id: "survey-overview-grid-header-partnerNo", defaultMessage: "PartnerNo" }),
    field: "partnerNo",
    colId: "partnerNo",
    checkboxSelection: true,
    filter: "agTextColumnFilter",
    headerCheckboxSelection: false
  },
  {
    headerName: intl.formatMessage({ id: "survey-overview-grid-header-partner Name", defaultMessage: "Partner Name" }),
    field: "partnerName",
    colId: "partnerName",
    filter: "agTextColumnFilter"
  },
  {
    headerName: intl.formatMessage({ id: "survey-overview-grid-header-companyCode", defaultMessage: "Company Code" }),
    field: "companyCode",
    colId: "companyCode",
    filter: true
  },
  {
    headerName: intl.formatMessage({ id: "survey-overview-grid-header-agreement-type", defaultMessage: "eInvoice Agreement" }),
    field: "agreementType",
    colId: "agreementType",
    filter: true
  },
  {
    headerName: intl.formatMessage({ id: "survey-overview-grid-header-partnerType", defaultMessage: "Partner Type" }),
    field: "categoryDescription",
    colId: "categoryDescription",
    filter: "agTextColumnFilter",
    valueFormatter: agGridGetTranslationFromPhraseID('categoryPhraseId', 'categoryDescription',intl),
    filterParams: {
      buttons: ["Customer",
        "Supplier"]
    }
  },
  {
    headerName: intl.formatMessage({ id: "survey-overview-grid-header-status", defaultMessage: "Status" }),
    field: "surveyStatus",
    colId: "status",
    filter: true,
    valueFormatter: params=> {
      if (params.data.surveyStatusPhraseId) {
        return agGridGetTranslationFromPhraseID('surveyStatusPhraseId', 'surveyStatus', intl)(params);
      }
      if (params.data.surveyStatus === 'NOT STARTED') {//TODO:Should probably be fixed in the backend....
        return intl.formatMessage({ id: "survey-status-not-started"});
      }

      return params.data.surveyStatus;
    },
    filterParams: {
      debounceMs: 200
    }
  },
  {
    headerName: intl.formatMessage({ id: "survey-overview-grid-header-surveyDate", defaultMessage: "Survey Date" }),
    field: "insertTime",
    colId: "insertTime",
    cellRenderer: (params) => {
      if (params.value) {
        const dateInYYYY_MM_DD = params.value.substring(0, 10);
        return convertFromSpecifiedDateFormatToUserDefined(user, dateInYYYY_MM_DD, "YYYY-MM-DD");
      }
      return "";
    },
    filterParams: {

      comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = cellValue;

        if (dateAsString == null) {
          return 0;
        }
        const dateParts = dateAsString.split("/");
        const day = Number(dateParts[2]);
        const month = Number(dateParts[1]) - 1;
        const year = Number(dateParts[0]);
        const cellDate = new Date(year, month, day);

        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
        return 0;
      }
    }
  },

  {
    headerName: intl.formatMessage({ id: "survey-overview-grid-header-surveyStart", defaultMessage: "Survey Start" }),
    field: "surveyStartDate",
    colId: "surveyStart",
    cellRenderer: (params) => {
      if (params.value) {
        const dateInYYYY_MM_DD = params.value.substring(0, 10);
        return convertFromSpecifiedDateFormatToUserDefined(user, dateInYYYY_MM_DD, "YYYY-MM-DD");
      }
      return "";
    },
    filterParams: {

      comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = cellValue;

        if (dateAsString == null) {
          return 0;
        }
        const dateParts = dateAsString.split("/");
        const day = Number(dateParts[2]);
        const month = Number(dateParts[1]) - 1;
        const year = Number(dateParts[0]);
        const cellDate = new Date(year, month, day);

        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
        return 0;
      }
    }
  },
  {
    headerName: intl.formatMessage({ id: "survey-overview-grid-header-currentUser", defaultMessage: "Current User" }),
    field: "assignedPartnerUser",
    colId: "responsibleUser",
    filter: "agTextColumnFilter"
  },
  {
    headerName: intl.formatMessage({ id: "survey-overview-grid-header-groupId", defaultMessage: "Survey Group ID" }),
    field: "groupId",
    colId: "groupId",
    filter: "agTextColumnFilter"
  }
];

export const getSurveyOverviewData = (surveyOverviewFilter: SurveyOverviewFilter): Promise<any> => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: ALL_SURVEY_OVERVIEW_FOR_LOGGED_IN_USER_QUERY,
        variables: { surveyOverviewFilter, pageNumber:  1, pageSize: 1000},
        fetchPolicy: "network-only"
      })
      .then((response) => {
        resolve(response.data?.getSurveyList);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createSurveyMutation = (surveyCreateRequests: SurveyCreateRequest[]) => {
  return new Promise<createSurvey>((resolve, reject) => {
    client.mutate({
        fetchPolicy: "no-cache",
        mutation: CREATE_SURVEY,
        variables: { surveyCreateRequests },
    }).then((response) => {
        resolve(response.data?.createSurvey);
    }).catch((error) => {
        console.error(error);
        reject(error);
    })
  });
}


export const SURVEY_MANAGEMENT_TABLE_HEADERS = (intl: IntlShape, user:PortalUser) => [
  {
    headerName: intl.formatMessage({ id: "survey-management-grid-header-select", defaultMessage: "Select" }),
    checkboxSelection: true,
    colId: "select",
    width: 80,
    filter: false
  },
  {
    headerName: intl.formatMessage({ id: "survey-management-grid-header-surveyId", defaultMessage: "Survey IDs" }),
    field: "includedSurveyIds",
    colId: "includedSurveyIds",
    headerCheckboxSelection: false,
    width: 100
  },
  {
    headerName: intl.formatMessage({ id: "survey-management-grid-header-groupId", defaultMessage: "Survey Group ID" }),
    field: "groupId",
    colId: "groupId",
    width: 250
  },
  {
    headerName: intl.formatMessage({ id: "survey-management-grid-header-partnerNo", defaultMessage: "PartnerNo" }),
    field: "partnerNo",
    colId: "partnerNo"
  },
  {
    headerName: intl.formatMessage({ id: "survey-management-grid-header-partnerName", defaultMessage: "Partner Name" }),
    field: "partnerName",
    colId: "partnerName"
  },
  {
    headerName: intl.formatMessage({ id: "survey-management-grid-header-partnerType", defaultMessage: "Partner Type" }),
    field: "categoryDescription",
    valueFormatter: agGridGetTranslationFromPhraseID('categoryPhraseId', 'categoryDescription',intl),
    colId: "partnerType"
  },
  {
    headerName: intl.formatMessage({ id: "survey-management-grid-header-companyCode", defaultMessage: "Company Code" }),
    field: "companyCode",
    colId: "companyCode"
  },
  {
    headerName: intl.formatMessage({
      id: "survey-management-grid-header-surveyStatus",
      defaultMessage: "Survey Status"
    }),
    valueFormatter: agGridGetTranslationFromPhraseID('surveyStatusPhraseId', 'surveyStatus',intl),
    field: "surveyStatus",
    colId: "surveyStatus"
  },
  {
    headerName: intl.formatMessage({ id: "survey-management-grid-header-surveyDate", defaultMessage: "Survey Date" }),
    colId: "insertTime",
    valueGetter: (params) => {
      if (params.data.insertTime) {
        const dateInYYYY_MM_DD = params.data.insertTime.substring(0, 10);
        return convertFromSpecifiedDateFormatToUserDefined(user, dateInYYYY_MM_DD, "YYYY-MM-DD");
      }
      return "";
    },
  },
  {
    headerName: intl.formatMessage({ id: "survey-management-grid-header-userName", defaultMessage: "User Name" }),
    field: "assignedPartnerUser",
    colId: "username"
  },

  {
    headerName: intl.formatMessage({
      id: "survey-management-grid-header-userEmailAddress",
      defaultMessage: "User email address"
    }),
    field: "email",
    colId: "email",
    width: 250
  }
];

export const getSurveyManagementOverview = (surveyManagementInputs: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    client
        .query({
          query: QUERY_SURVEY_MANAGEMENT_DATA,
          variables: { surveyManagementInputs },
          fetchPolicy: "network-only"
        })
        .then((response) => {
          resolve(response.data?.getSurveyManagementList);
        })
        .catch((err) => {
          reject(err);
        });
  });
};
