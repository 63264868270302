import { gql } from "@apollo/client";

export const ALL_USER_GUIDES = gql`
    query getUserGuides{
        getUserGuidesDocList{        
            groupName
            sortOrder
            userGuideGroupId
            userGuideId
            userGuideName
            details {
            language
            userGuideBlob
            }          
        }
    }
`;

export const DOWNLOAD_USER_GUIDE_DOCUMENT_QUERY_BY_BLOB_ID = gql`
    query downloadUserGuideDocument($blobId: Float!){
        downloadUserGuideDocument(blobId : $blobId)
        {
            success
            value {
                encodedFileData
                fileName
                mimeType
                }
            }
    }
`;

