import client from "../../../config/GraphQLApolloClient";
import { GET_ALL_DEFAULT_PROCESS, GET_PROCESS_DETAILS, INSERT_SURVEY_CHANNELS } from "./queries";
import { getI18n, omitDeep } from "../../../utils/Utils";
import { ALL_SURVEY_CHANNEL_SURVEY_ID } from "../queries";
import {
  getProcessCodeName,
  getProcessCodeName_getProcessCodeName,
  getProcessCodeNameVariables
} from "./__generated__/getProcessCodeName";
import { IntlShape } from "react-intl/src/types";
import { PARTNER_TYPE } from "../../../constants";

export type  SurveyPartnerProcessChannelData = {
  partnerNo?: string;
  partnerType?: string;
  companyCode?: string;
  partnerName?: string;
  surveyId?: number;
  channelFormatsData?: SurveyProcessChannelData[];

}

export type  SurveyProcessChannelData = {
  partnerNo?: string
  partnerName?: string
  companyCode?: string
  processCodeId?: number;
  processCode?: string;
  processCodeName?: string;
  format?: string;
  channel?: string;
  updatedFormat?: string;
  updatedChannel?: string;
  defaultAlternative?: string
  direction?: string
  surveyId?: number;
  surveyChannelId?: number;
  channelTypeId?: number;
  formatId?: number;
  documentTypeId?: number;
  acceptedDate?: number;
}


export const getProcessName = (surveyProcessChannels, intl: IntlShape) => {
  const requestValue = omitDeep(surveyProcessChannels, (_, prop) => ["__typename"].includes(prop));

  return new Promise((resolve, reject) => {
    client.query<getProcessCodeName, getProcessCodeNameVariables>({
      query: GET_PROCESS_DETAILS,
      variables: { processCodes: requestValue },
      fetchPolicy: "cache-first"
    }).then(response => {
      const refinedProcesses: getProcessCodeName_getProcessCodeName[] = [];
      response.data?.getProcessCodeName.map(item => {
        const process = { processCodeId: item.processCodeId, ...item };
        process.processCodeName = getI18n(item.phraseId, item.processCodeName, intl);
        refinedProcesses.push(process);
      });
      return refinedProcesses;
    }).catch((err) => {
      console.error(err);
      reject(err);
    });

  });
};

export const insertSurveyChannels = (includedSurveyIds: string, surveyChannels) => {

  return new Promise((resolve, reject) => {
    client.mutate({
      mutation: INSERT_SURVEY_CHANNELS,
      variables: { includedSurveyIds, partnerType: localStorage.getItem(PARTNER_TYPE), surveyChannels },
      fetchPolicy: "network-only"
    }).then((response) => {
      resolve(response.data?.insertSurveyChannels);
    }).catch((err) => {
      console.error(err);
      reject(err);
    });
  });
};

export const addPartnerDetails = async (surveySummaryRecord) => {
  const channelsData: SurveyPartnerProcessChannelData = {
    partnerNo: surveySummaryRecord.partnerNo, partnerName: surveySummaryRecord.partnerName,
    companyCode: surveySummaryRecord.companyCode, partnerType: surveySummaryRecord.categoryDescription,
    surveyId: surveySummaryRecord.surveyId
  };
  return channelsData;
};

export const getDefaultChannelData = (partnerType: string, companyCode: string) => {
  // get all the process name
  return new Promise((resolve, reject) => {
    client.query({
      query: GET_ALL_DEFAULT_PROCESS,
      variables: { partnerType, companyCode },
      fetchPolicy: "cache-first"
    }).then((response) => {
      resolve(response.data?.getDefaultProcessCodes.filter(item => item.format !== null && item.format !== undefined));
    }).catch((err) => {
      console.error(err);
      reject(err);
    });

  });
};


export const getSurveyChannelBySurveyId = async (surveyId: number, partnerType: string, companyCode: string, intl: IntlShape) => {
  // get all the process name
  return new Promise((resolve, reject) => {
    client.query({
      query: ALL_SURVEY_CHANNEL_SURVEY_ID,
      variables: { surveyId, partnerType, companyCode },
      fetchPolicy: "network-only"
    }).then((response) => {
      const channels = [];
      if (Array.isArray(response.data?.Portal_getSurveyChannels)) {
        response.data?.Portal_getSurveyChannels.forEach(item => {
          const channel = { surveyChannelId: item.surveyChannelId, ...item };
          channel.updatedChannel = item.channel;
          channel.updatedFormat = item.format;
          channel.format = item.format;
          channel.channel = item.channel;
          channel.processCodeName = getI18n(item.processCodePhraseId, item.processCodeName, intl);
          channels.push(channel);
        });
      }
      resolve(channels);
    }).catch((err) => {
      console.error(err);
      reject(err);
    });

  });
};