import {gql} from "@apollo/client";

export const INCOMING_DOCS_SUMMARY_DATA = gql`
    query getIncomingDocumentsSummary{
      getIncomingDocumentsSummary{
        processError
        volume
        firstOccurance
        lastOccurance
        }
    }
`
export const INCOMING_DOC_DETAILS_DATA = gql`
query getIncomingDocuments($processError:String!){
    getIncomingDocuments(processError:$processError){
        documentIncomingId
        logObjectId
        companyCode
        partnerNo
        processCode
        area
        fileName
        insertUser
        insertTime
        reference
        dateReceived
        processError
    }
}
`
export const PENDING_DOCS_DATA = gql`
    query getPendingDocuments{
      getPendingDocuments {
                        amount
                        azContainerName
                        azDownloadFileName
                        azItemPathName
                        azStorageType
                        blobId
                        companyCode
                        companyName
                        currency
                        dateReceived
                        documentId
                        downloadLink
                        downloaded
                        insertTs
                        logObjId
                        partnerName
                        partnerno
                        processCode
                        quantity
                        reference
                        systemCode
                        updatetime
          area
          filePrefix
          status
          fileName
          updateUser
          insertUser

      }
    }
`
export const PENDING_DOCS_VOLUME = gql`
    query getUnavailableDocsVolume{
        getUnavailableDocsVolume{
            volume
        }
    }
`;

export const REPROCESS_INCOMING_DOCUMENTS = gql`
    query reprocessDocumentsIncoming{
        reprocessDocumentsIncoming{
            success
            value
        }
    }
`


