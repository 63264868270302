import { EntitlementRule } from "src/__generated__/types";
import { IAppComponentProps } from "src/components";

export interface Entitlement {
    id: number;
    name: string;
    description: string;
}

export type EntitlementFormProps = {
    onLoading?: any;
    showModal?: boolean;
    onCancel?: any;
    entitlementRuleToEdit?: EntitlementRule;
  } & IAppComponentProps;


export type EntitlementEditFormProps = {
    handleUpdateEntitlement: any;
} & EntitlementFormProps

export enum DropDownSelectType {
    Single = 'single',
    Multiple = 'multiple',
}


export const ADMIN_ENTITLEMENT = 'admin@entitlements';
export const EDIT_ENTITLEMENT = 'edit@entitlements';
export const VIEW_ENTITLEMENT = 'view@entitlements';

export const GetEntitlementRowStyle = (params) => {
    if (params.data.updateAction) {
        return { backgroundColor: '#ffeb3b' }; // Yellow background color
    }
    return null;
};
