import React, { FunctionComponent } from "react";
import { PageContainer } from "@ant-design/pro-layout";
import { Col, Collapse, Row } from "antd";
import "./../Welcome.css";
import "./SurveyLandingPage.css";
import { CaretRightOutlined } from "@ant-design/icons";
import { IAppComponentProps } from "../../components";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

const SurveyLanding: FunctionComponent<IAppComponentProps> = (props) => {
  const currentUser = props.currentUser;
  const { Panel } = Collapse;
  const intl = useIntl();
  const redirect = (route: string) => {
    // @ts-ignore
    props.history?.replace({ pathname: route, state: { currentUser } });
  };

  // @ts-ignore
  return (
    <>
      <PageContainer title={"Survey"}>
        <Row gutter={16}>
          <Col>

            <Row gutter={16}>
              <Col className="gutter-row" style={{ marginLeft: "5px" }}>
                <div className="title-header-card"
                     style={{ textAlign: "center", width: "250px", height: "300px", marginLeft: "5px" }}>
                  <div className="title-header-card-text">
                    {intl.formatMessage({
                      id: "survey.landing.title",
                      defaultMessage: "Survey"
                    })}
                  </div>
                </div>
              </Col>
              <Col className="gutter-row " span={6}>
                <div id="title-header-card-image" style={{
                  textAlign: "center",
                  width: "800px",
                  height: "300px",
                  marginLeft: "-10px"
                }} />
              </Col>
            </Row>
          </Col>
        </Row>
      {/*  <Row gutter={16}>
          <Col span={24}>
            <Collapse
              bordered={false}
              defaultActiveKey={["1"]}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            >
              <Panel header="Survey" key="1">

                <Row justify="start" gutter={[24, 24]}>
                  <Col span={4} style={{
                    boxShadow: "0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)", marginRight: "10px",
                    backgroundColor: "#b3b3b1"
                  }}>
                    <Row justify="space-around" align="middle" style={{ height: "40px" }}>
                      <Col>
                        <Link className={"survey-buttons-text"} to={"/survey/SurveyOverview"}
                              style={{ color: "#4a576d" }}>{"SURVEY eINVOICING"}</Link>

                      </Col>
                    </Row>
                  </Col>
                  <Col span={4} style={{
                    boxShadow: "0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)",
                    backgroundColor: "#b3b3b1"
                  }}>
                    <Row justify="space-around" align="middle" style={{ height: "40px" }}>
                      <Col>
                        <Link className={"survey-buttons-text"} to={"/survey/management"}
                              style={{ color: "#4a576d" }}>{"MANAGEMENT OVERVIEW"}</Link>

                      </Col>
                    </Row>

                  </Col>

                </Row>
              </Panel>

            </Collapse>
          </Col>
        </Row>*/}
      </PageContainer>
    </>
  );

};

export default SurveyLanding;
