import React, { useState } from "react";
import { saveAs } from "save-as";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { ICellRendererParams } from "ag-grid-community";
import { useLazyQuery, useMutation } from "@apollo/client";
import { DOWNLOAD_DOCUMENT } from "./queries";
import { downloadDocument } from "./__generated__/downloadDocument";
import { showErrorNotification } from "../Notification";
import { UPDATE_SINGLE_DOWNLOAD_STATUS } from "./mutations";
import { base64toZipFile } from "./DocumentUtils";

type DownloadDocumentProps = {} & ICellRendererParams

const BtnDownloadCellRenderer = (props: DownloadDocumentProps) => {

  const [downloadFile, {
    error,
    loading
  }] = useLazyQuery<downloadDocument>(DOWNLOAD_DOCUMENT, {
    fetchPolicy: "no-cache"
  });

  const [updateDownLoadStatus] = useMutation(UPDATE_SINGLE_DOWNLOAD_STATUS);

  if (error) {
    showErrorNotification(`${error}`);
  }

  return (

    <span style={{ marginRight: "10px" }}>
      <Button type="primary" htmlType="button" size={"small"} loading={loading} onClick={
        () => {
          const input = {
            blobId: props.data.documentId,
            documentName: props.data.azDownloadFileName,
            documentPath: props.data.azItemPathName,
            documentLink: props.data.downloadLink
          };
          downloadFile({
            variables: { documentDownloadInput: input }
          }).then(response => {
            base64toZipFile(response.data?.downloadDocument.encodedZipFileData, props.data.azDownloadFileName).then(_ => {
              updateDownLoadStatus({
                variables: { documentDownloadInput: input }
              }).then(_ => {
                const rowData = props.data;
                const newData = { reference: rowData.reference, ...rowData };
                newData.downloaded = "YES";
                if (props.api) {
                  const rowNode = props.api.getRowNode(props.node.id);
                  rowNode.setData(newData);
                }
              }).catch(err => {
                console.error(err);
                showErrorNotification(err);
              });
            });
          }).catch(err => {
            console.error(err);
            showErrorNotification(err);
          });


        }}>
        <DownloadOutlined />
      </Button>
    </span>

  );


};

export default BtnDownloadCellRenderer;
