import React, { FunctionComponent, useEffect, useState, useRef } from "react";
import {
  Input,
  Select,
  Button,
  Alert,
  Card,
  Col,
  Row,
  Spin,
  Tabs,
  message,
  DatePicker,
  Checkbox,
  Form,
  ConfigProvider,
  Typography
} from "antd"; //Re check
import { TextAreaRef } from "antd/lib/input/TextArea";
import "./../../Welcome.css";
import { useLazyQuery, useQuery } from "@apollo/client";
import { IAppComponentProps } from "../../../components";
import { useIntl } from "react-intl";
import { getI18n } from "../../../utils/Utils";
import { PortalFormItem } from "../../../components/FormUtils";
import { addNotificationData } from "../NotificationService";
import { AddNotificationInput } from "__generated__/globalTypes";
import { SELECT_OPTION_TEMPLATE_NAME_QUERY, SELECT_OPTION_TEMPLATE_TEXT_QUERY, SELECT_OPTION_ENTITLEMENT_QUERY } from "../../survey/queries";
import { add } from "lodash";
import enUS from 'antd/lib/locale/en_US';
import deDe from "antd/lib/locale/de_DE";

const { TextArea } = Input


const AddNotification: FunctionComponent<IAppComponentProps> = (props) => {
  const intl = useIntl();
  const [alertMessage] = useState<string>("Please enter all required fields");
  const [showAlert, setShowAlert] = useState<boolean>(false);;
  const [activeFromDate, setActiveFromDate] = useState(undefined);
  const [activeToDate, setActiveToDate] = useState(undefined);
  const [inputTemplateId, setInputTemplateId] = React.useState('');
  const [inputNotificationTypeId, setInputNotificationTypeId] = React.useState('');
  const { Paragraph } = Typography;
  const [hiddenUserName, ishiddenUserName] = useState(true);
  const [hiddenInvoiceName, isHiddenInvoiceName] = useState(true);
  const [hiddenInvoiceLink, isHiddenInvoiceLink] = useState(true);
  const [hiddenStartTimeEN, isHiddenStartTimeEN] = useState(true);
  const [hiddenStartTimeDE, isHiddenStartTimeDE] = useState(true);
  const [hiddenEndTimeEN, isHiddenEndTimeEN] = useState(true);
  const [hiddenEndTimeDE, isHiddenEndTimeDE] = useState(true);
  const [customVariableArray, setCustomVariableArray] = useState([]);
  const [templateNamesDropDown, setTemplateNamesDropDown] = useState([]);
  const [templateTextEn, setTemplateTextEn] = useState(undefined);
  const [templateTextDe, setTemplateTextDe] = useState(undefined);
  const [selectTemplate, setSelectTemplate] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState();
  const [selectedEntitlementsDropDown, setSelectedEntitlementsDropDown] = useState([]);
  const [selectedEntitlements, setSelectedEntitlements] = useState([]);
  const [selectNotificationType, setNotificationType] = useState([
    {
      value: '1',
      label: 'TECHNICAL',
    },
    {
      value: '2',
      label: 'INFO',
    },
    {
      value: '3',
      label: 'Partner Specific',
    },
    {
      value: '4',
      label: 'General',
    },
    {
      value: '5',
      label: 'MAINTENANCE',
    },
    {
      value: '6',
      label: 'RELEASE',
    }
  ]);

  const headerParagraphLine1 = getI18n(
      'add-notification-message-headerText-line1',
      `{user_name} variable value is greyed out as it is a keyword, when {user_name} is used this will return the logged in user's name`, intl);
  const builtInVariablesContainer = useRef(null);
  const customVariablesContainer = useRef(null);
  const [form] = Form.useForm();
  const [getTemplateNamesDropDown] = useLazyQuery(SELECT_OPTION_TEMPLATE_NAME_QUERY,
    { fetchPolicy: "network-only" }
  );

  const [getNotificationTemplateText] = useLazyQuery(SELECT_OPTION_TEMPLATE_TEXT_QUERY,
    { fetchPolicy: "network-only", variables: { messageTemplateId: inputTemplateId } }
  );

  const [getNotificationEntitlementsDropdown] = useLazyQuery(SELECT_OPTION_ENTITLEMENT_QUERY,
    { fetchPolicy: "network-only" }
  );

  useEffect(() => {

    if (!templateNamesDropDown || templateNamesDropDown.length === 0) {
      getTemplateNamesDropDown().then
        (response => {
          const templateNamesArray = []
          response.data.getNotificationTemplateNamesDropDown.map(template => {
            templateNamesArray.push({ "value": template.messageTemplateId, "label": template.templateName })
          });
          setSelectTemplate(templateNamesArray)
        }
        );
    }
    if (!selectedEntitlementsDropDown || selectedEntitlementsDropDown.length === 0) {
      getNotificationEntitlementsDropdown().then
        (response => {
          const entitlementsArray = []
          response.data.getNotificationEntitlementsDropdown.map(entitlement => {
            entitlementsArray.push({ "value": entitlement.entitlement, "label": entitlement.description })
          });
          setSelectedEntitlementsDropDown(entitlementsArray)
        }
        );
    }
  }, []);

  const handleTemplateIdChange = (value) => {
    setInputTemplateId(value);

  }

  const onCheckboxChange = () => {
    setSelectedEntitlements([]);
    setSelectedEntitlements(["*@survey"]);
  }

  // regex for finding values between brackets
  const extractNotifcationVariableArray = (englishNotification: String, germanNotification: String) => {
    const regexPaternToExtractVariables = (/{([^}]*)}/g);

    const notificationTextVariablesSet = new Set<string>();

    englishNotification.match(regexPaternToExtractVariables).map(e => notificationTextVariablesSet.add(e.substring(1, e.length - 1)));
    germanNotification.match(regexPaternToExtractVariables).map(e => notificationTextVariablesSet.add(e.substring(1, e.length - 1)));
    setCustomVariableArray([]);


    notificationTextVariablesSet.forEach(value => {
      const addItem = () => {
        setCustomVariableArray(prevCustomVariableAppendedArray => {
          return [...prevCustomVariableAppendedArray, value]
        });
      }

      switch (value.toLocaleLowerCase()) {
        // case 'user_name': {
        //   ishiddenUserName(false);
        //   break;
        // }
        case 'invoice_name': {
          isHiddenInvoiceName(false)
          break;
        }
        case 'invoice_link': {
          isHiddenInvoiceLink(false)
          break;
        }
        case 'start_time_en': {
          isHiddenStartTimeEN(false)
          break;
        }
        case 'start_time_de': {
          isHiddenStartTimeDE(false)
          break;
        }

        default: {
          addItem()
        }
      }
    });
  }

  const currentUser = props.currentUser;
  const distinctEntitlements = props.distinctEntitlements;
  const { TabPane } = Tabs;
  const sizes: React.CSSProperties = {
    height: "150%",
    width: "98%"
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [selectOptions, setSelectOption] = useState("true");



  //const CreateNotification: FunctionComponent<HeaderProperties> = (props) => {   
  const [englishNotificationInput, setEnglishNotificationInput] = React.useState('');
  const [germanNotificationInput, setGermanNotificationInput] = React.useState('');
  const [typeAlert, setTypeAlert] = React.useState<"error" | "success" | "info" | "warning" | undefined>(undefined);
  const [notificationData, setClearNotificationData] = React.useState<any>('')
  const [select1, setSelect1] = React.useState('Custom');
  const [select2, setSelect2] = React.useState('Custom');
  const textarea1Ref = React.useRef<TextAreaRef | null>(null);
  const textarea2Ref = React.useRef<TextAreaRef | null>(null);
  let undefinedInputVariable = false;

  const onSubmit = () => {
    setLoading(true);
    undefinedInputVariable = false;
    let templateVariablesData = "{";
    customVariableArray.map((variable) => {
      const variableInput = form.getFieldValue(variable)
      if (variable !== 'user_name' && !variableInput) {
      undefinedInputVariable = true;
      }
      templateVariablesData += `"${variable}":"${variableInput}",`;
    })
    templateVariablesData = templateVariablesData.slice(0, -2);
    templateVariablesData = templateVariablesData.concat(`"}`);

    const notificationData: AddNotificationInput = {
      activeFromDate,
      activeToDate,
      notificationTypeId: inputNotificationTypeId,
      notificationTemplateId: inputTemplateId,
      templateData: templateVariablesData,
      entitlements: selectedEntitlements,

    };
    console.log(notificationData);

    if (undefinedInputVariable || !notificationData.notificationTemplateId || !notificationData.activeFromDate || !notificationData.activeToDate || !notificationData.notificationTypeId || !notificationData.entitlements.length) {
      setShowAlert(true)
      setLoading(false);
      return
    }
    setShowAlert(false)
    setLoading(true);

    Promise.resolve(addNotificationData(notificationData))
      .then(() => {
        // setClearNotificationData()

        message.success(getI18n(
          "add-notifications-add-notifcation-success",
          "Notification added successfully", intl));
        setLoading(false);
        form.resetFields();
      })
      .catch((err) => {

        console.error(err);
        message.error(getI18n(
          "add-notifications-add-notifcation-failure",
          "Add Notifications failed", intl
        ));
      });

  };

  const handleActiveFromDate = (date) => {
    setActiveFromDate(date!.format('YYYY-MM-DD HH:mm:ss'));
  }

  //const handleTemplateIdChange = (value) => { setInputTemplateId(value) }

  // const handleNotification = (value) => 
  //
  // { setInputTemplateId(value)}

  useEffect(() => {
    setCustomVariableArray([]);
    if (inputTemplateId) {
      getNotificationTemplateText().then
        (response => {
          console.log(`InputTempalteId ${inputTemplateId}`);
          if (response.data.getNotificationTemplateText !== null) {
            const englishText = response.data.getNotificationTemplateText[0].templateTextEn;
            setTemplateTextEn(englishText);

            const germanText = response.data.getNotificationTemplateText[0].templateTextDe;
            setTemplateTextDe(germanText);
            extractNotifcationVariableArray(englishText, germanText);
          }
        }).catch((err) => {
          console.error(err);
        });
    }
  }, [inputTemplateId])


  return (<>
    <ConfigProvider locale={localStorage.getItem("locale") === "en" ? enUS : deDe}>
      <Spin spinning={loading} size="large" />
      {showAlert && <Alert message={alertMessage} type={"error"} showIcon={true} closable={true} />}
      <Form size={"middle"} layout={"vertical"} form={form}>
        <Row gutter={24} style={{ alignContent: "flex-end", marginTop: "50" }} />
        <Card
          title={getI18n("add-notifications-add-notifcation-title",
            "Add Notification", intl
          )}>
          <Row gutter={24}>
            <Col span={5}>
              <Form.Item name={"templateName"} key={"templateNameKey"}
              required={true}
                label={getI18n("add-notifications_template_name-label-placeholder", "Template Name", intl)}>
                <Select
                  placeholder={getI18n("add-notifications_template_name-label-placeholder", "Template Name", intl)}
                  allowClear={true}
                  data-testid={"templateName"}
                  options={selectTemplate}
                  //onChange={handleTemplateIdChange}
                  onSelect={(value, option) => {

                    handleTemplateIdChange(value);
                    console.log(inputTemplateId);
                    getNotificationTemplateText(value).then
                      //set variables with use state string
                      (response => {
                        const englishText = response?.data?.getNotificationTemplateText[0]?.templateTextEn;
                        setTemplateTextEn(englishText);
                        //builtInVariablesContainer.current.style.display='block';

                        const germanText = response?.data?.getNotificationTemplateText[0]?.templateTextDe;
                        setTemplateTextDe(germanText);
                        extractNotifcationVariableArray(englishText, germanText);
                      }).catch(error => {
                        console.log(error);
                      });
                  }}
                />

              </Form.Item>
            </Col>

            <Col span={5}>
              <Form.Item required={true} name={"notificationType"} key={"notificationTypeKey"} label={getI18n(
                "add-notification-notification-type-label-placeholder",
                "Notification Type", intl)}>
                <Select placeholder={getI18n(
                "add-notification-notification-type-label-placeholder",
                "Notification Type", intl)} allowClear={true}
                  showSearch={true}
                  data-testid={"notificationType"}
                  options={selectNotificationType}
                  onSelect={(value) => {
                    setInputNotificationTypeId(value);
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={5}>
              <Form.Item name={"messageStartDate"} key={"messageStartDate"}
              required={true}
                label={getI18n("add-notification-start-date-label-placeholder", "Message Start", intl)}>
                <DatePicker
                  showTime={{ format: 'HH:mm' }}
                  format={"YYYY-MM-DD HH:mm"}
                  placeholder={getI18n("add-notification-start-date-label-placeholder", "Message Start", intl)}
                  allowClear={true}
                  data-testid={"messageStartDate"}
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    setActiveFromDate(value?.format('YYYY-MM-DD HH:mm'));
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={5}>
              <Form.Item required={true} name={"messageEndDate"} key={"messageEndDate"} label={getI18n(
              "add-notification-end-date-label-placeholder", "Message Expiry", intl)}>
                <DatePicker
                  showTime={{ format: 'HH:mm' }}
                  format={"YYYY-MM-DD HH:mm"}
                  placeholder={getI18n("add-notification-end-date-label-placeholder", "Message Expiry", intl)}
                  allowClear={true}
                  data-testid={"messageEndDate"}
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    setActiveToDate(value?.format('YYYY-MM-DD HH:mm'));
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={5}>
              <Form.Item name={"entitlements"} key={"entitlementKey"} 
                label={getI18n("add-notification-entitlement-label-placeholder", "Entitlements", intl)}>
                <Select mode="multiple"
                  placeholder={getI18n("add-notification-entitlement-label-placeholder", "Entitlements", intl)}
                  allowClear={true}
                  showSearch={true}
                  options={selectedEntitlementsDropDown}
                  onSelect={(value) => {
                    setSelectedEntitlements([...selectedEntitlements, value]);
                  }} />
              </Form.Item>
            </Col>

            <Col span={5}>
              <Checkbox onChange={onCheckboxChange}>All Users</Checkbox>
            </Col>
          </Row>

        </Card>

        <Card title={getI18n("add-notification-english-german-template-text-title","English and German Template Text", intl)}>

          <Col>
            {/* <pre>English Text </pre> */}
            <TextArea
              placeholder={getI18n("add-notification-english-template-text-placeholder","English Template Text", intl)}              
              ref={textarea1Ref}
              onChange={({ target }) => setEnglishNotificationInput(target.value)}
              // value={englishNotificationInput}
              value={templateTextEn}
              style={{ width: 500, height: 100 }} allowClear={true} />

            <TextArea
              placeholder={getI18n("add-notification-german-template-text-placeholder","German Template Text", intl)}
              ref={textarea2Ref}
              onChange={({ target }) => setGermanNotificationInput(target.value)}
              // value={germanNotificationInput}
              value={templateTextDe}
              style={{ width: 500, height: 100, marginLeft: 50 }} allowClear={true} />

          </Col>
        </Card>
        <Card>
        <div ref={builtInVariablesContainer}>
          <PortalFormItem
            hidden={hiddenEndTimeEN}
            label={'DownTimeEndTime'}
            // onChange={({ target }) => setInputTemplateName(target.value)}

            name={'downTimeEndTimeVariable'}
            placeHolder={'DownTimeEndTime'}
          />

          <PortalFormItem
            hidden={hiddenStartTimeDE}
            label={'DownTimeStartTimeDE'}
            name={'downTimeEndTimeVariable'}
            placeHolder={'DownTimeEndTime'}
          />

            <Col>
              <PortalFormItem
                hidden={hiddenEndTimeDE}
                label={'DownTimeEndTime'}
                onChange={({ target }) => setActiveToDate(target.value)}
                name={'downTimeEndTimeVariable'}
                placeHolder={'DownTimeEndTime'}
              />
            </Col>
          </div>

          <div ref={customVariablesContainer}>
            {customVariableArray.map(customVariable => (
              <div key={customVariable} style={{ width: 500 }}>
                {customVariable === 'user_name' ? (
                    <PortalFormItem
                        label={customVariable}
                        name={customVariable}
                        placeHolder={customVariable}
                        key={customVariable}
                        disabled={true}
                    />
                ) : (
                    <PortalFormItem
                        label={customVariable}
                        name={customVariable}
                        placeHolder={customVariable}
                        key={customVariable}
                    />
                )}
          </div>
            ))}
          </div>
        </Card>
        <Card>
          <Row  >
            <Typography>
              <Paragraph>
                <Row>
                  {headerParagraphLine1}
                </Row>
              </Paragraph>
            </Typography>
            </Row>
          <Row>
            <Col span={5} >
              <Button onClick={onSubmit}
                type="primary"
                size="middle"
              >

                {getI18n("add-notification-add-notification-button", "Add Notification", intl)}
              </Button>
            </Col>
          </Row>
        </Card>
        {typeAlert && <Alert type={typeAlert} showIcon={true} />}
        {/* {typeAlert && <Alert message={alert} type={typeAlert} showIcon={true} />} */}
      </Form >
    </ConfigProvider>
  </>
  );
}

export default AddNotification;
