import React, {FunctionComponent, useEffect, useState} from "react";
import { PageContainer } from "@ant-design/pro-layout";
import { Col, message, Spin } from "antd";
import "./../Welcome.css";
import { IAppComponentProps } from "../../components";
import { AgGridReact } from "ag-grid-react";
import { GridApi } from "ag-grid-community";
import { NOTIFICATION_TABLE_HEADERS } from "./NotificationsUtils";
import { IntlMessageFormat } from "intl-messageformat";
import { getCurrentLocale } from "../../utils";
import { getUserNotifications, updateNotifications } from "./NotificationService";
import { useIntl } from "react-intl";
import { NotificationInput } from "../../../__generated__/globalTypes";
import { getI18n } from "../../utils/Utils";


const Notifications: FunctionComponent<IAppComponentProps> = (props) => {
  const [notificationGridApi, setNotificationGridApi] = useState<GridApi>(undefined);
  const [isLoading, setLoading] = useState(false);
  //   const [formDataValues, setFormDataValues] = useState(undefined);
  const [surveyNotificationData, setSurveyNotificationData] = useState(undefined);
  const intl = useIntl();

  useEffect(() => {
             setLoading(true);
             const currentUser = props.currentUser.displayName;
             if(notificationGridApi !==undefined){
                Promise.resolve(getUserNotifications())
                .then((response) => {
                    const notifications = response.getNotifications;
                    const notifications2 =[];
                    if (notifications) {
                        notifications.forEach(element => {
                            // eslint-disable-next-line no-console
                            console.log(`Template data : ${element.templateData}`);
                            // eslint-disable-next-line no-console
                            console.log(`Template text : ${element.templateText}`);
                            // These variables become reserved words and can be used anywhere in notifications without passing the 
                            // values in the t_s_notifications table
                            let data = {"user_name": "", "language": ""};
                            if (element.templateData !== undefined || element.templateData !== "")
                            {
                              try {
                                data =  JSON.parse(element.templateData);
                              } catch (e)
                              {
                                console.error(e);
                              }
                            }
                            //make sure user_name is set dynamically, we should add all variables here that is not in templateData or is user specific
                            data.user_name = currentUser;
                            data.language = getCurrentLocale();
                            try {
                              const msg = new IntlMessageFormat(element.templateText, data.language).format(data);
                              notifications2.push({
                                "insertTime": element.insertTime,
                                "notificationId": element.notificationId,
                                "menu": element.menu,
                                "audience": element.audience,
                                "notificationType": getI18n(element.phraseId, element.notificationType, intl),
                                "templateText": msg,
                                "linkURL": (element.linkText != null ? element.linkText.concat(",").concat(element.linkAddress) : "")
                              });
                              // eslint-disable-next-line no-console
                              console.log(`Message Text : ${msg}`);
                            } catch (e) {
                                console.error(e);
                            }
                        });
                    }
                     setSurveyNotificationData(notifications2);
                     setLoading(false);
                })
                .catch((err) => {
                    message.error(`Unable to load Notifications : ${err}`);
                  console.error(`Unable to load Notifications : ${err}`);
                  setLoading(false);
                });
             }
  }, [notificationGridApi]);

  const onGridReady = (params) => {
    setNotificationGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const updateNotificationData = (notificationData) => {
    setLoading(true);
    Promise.resolve(updateNotifications(notificationData))
      .then(() => {
        message.success(intl.formatMessage({
          id: "notifications-updateDataSuccess",
          defaultMessage: "Notifications updated successfully"
        }));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        message.success(intl.formatMessage({
          id: "notifications-updateDataFailure",
          defaultMessage: "Notifications Data update failed"
        }));
      });
  };

  const rowsSelected = () => {
    const notificationIds: NotificationInput[] = [];
    const selectedRows = notificationGridApi!.getSelectedRows();
    const selectedNodes = notificationGridApi!.getSelectedNodes();

    selectedNodes.map((row) => {
      const input: NotificationInput = {};
      input.notificationId = row.data.notificationId;
      input.readFlag = row.isSelected();
      notificationIds.push(input);
     });

    //call service to update read flag and read date
    updateNotificationData(notificationIds);
  };

  const rangeSelected = () => {
    // eslint-disable-next-line no-console
    console.log("Range selected");
  };

  return (
    <>
      <PageContainer title={intl.formatMessage({
                                    id: "notifications-title",
                                    defaultMessage: "Notifications"
                                    })}
                     content={""}
      >

        <Spin spinning={isLoading} size={"large"}>
          <div className="pl15 ag-theme-balham" id={"main"}>
            <Col md={24} sm={12} xs={24}>
              <Col>
                <div style={{ height: "400px" }}>
                  <AgGridReact
                    defaultColDef={{
                      enableValue: true,
                      sortable: true,
                      resizable: true
                    }}
                    api={notificationGridApi}
                                      columnDefs={NOTIFICATION_TABLE_HEADERS(intl)}
                                      rowData={surveyNotificationData === undefined ? [] : surveyNotificationData}

                                      sideBar={false}
                                      rowClass="agGridRows"
                                      //          domLayout="autoHeight"
                                      suppressMenuHide={true}
                                      debug={false}
                                      onGridSizeChanged={() => notificationGridApi.sizeColumnsToFit()}
                                      onGridReady={onGridReady}
                                      onRowSelected={rowsSelected}
                                      onRangeSelectionChanged={rangeSelected}
                                      rowSelection={"multiple"}
                                      pagination={true}
                                      paginationPageSize={15}
                                      suppressRowClickSelection={true}
                                      enableBrowserTooltips={true}
                                      enableCellTextSelection={true}
                                    />
                                </div>
                            </Col>
                        </Col>
                    </div>
                </Spin>
            </PageContainer>
        </>
    );
};
export default Notifications;