// ______________________________________________________________________________
//
//       Project: e-Invoicing Documents Portal
//       File: https://atc.bmwgroup.net/bitbucket/scm/einvmrp/einvoicing-portal.git
//       Version: 0.0.1
// ______________________________________________________________________________
//
//    Created by: Lesley Netshiavhela <Lesley.Netshiavhela@partner.bmw.co.za>
//    Creation date:  2021/10/27
// ______________________________________________________________________________
//
//     Copyright: (C) BMW Group 2020, all rights reserved
// ______________________________________________________________________________
//
import React, { useEffect, useState } from "react";
import { IAppComponentProps, PortalUser } from "../../components";
import { Button, Col, Form, Row, Select } from "antd";
import { AgGridReact } from "ag-grid-react";
import { PARTNER_TABLE_HEADERS, removeDuplicates } from "./UserUtils";
import {useNavigate} from "react-router-dom"; // useNavigate replaces useHistory in react-router-dom v6, history changed to navigate
import { ALL_PARTNER_BY_PARTNER_NO, ALL_PARTNER_FOR_USER_QUERY, AssignRights } from "./queries";
import client from "../../config/GraphQLApolloClient";
import BtnUnassignedCellRenderer from "./BtnUnassignedCellRenderer";
import { GridApi } from "ag-grid-community";

export type Action = "next" | "prev" | "submit";

type SubmitValuesFn = (
  values: AssignRights[],
  action: Action
) => any;

type AssignPartnerProps = {
  selectedUser?: PortalUser;
  assignedPartners?: AssignRights[];
  submitValues: SubmitValuesFn;
  next?: any;
} & IAppComponentProps;


const AssignPartners: React.FunctionComponent<AssignPartnerProps> = (props) => {

  const [form] = Form.useForm();
  const { Option } = Select;
  const navigate = useNavigate();

  const [gridApi, setGridApi] = useState<GridApi>(undefined);
  const [loading, isLoading] = useState(false);
  const [assignLoading, isAssignLoading] = useState(false);
  const [partnerNumbersOptions, setPartnerNumbersOptions] = useState<AssignRights[]>([]);
  const [selectedPartner, setSelectedPartner] = useState<number>(undefined);
  const [assignedPartners, setAssignedPartner] = useState<AssignRights[]>([]);
  const [partnerList, setPartnerList] = useState(undefined);


  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  useEffect(() => {
    if (!props.assignedPartners) {
      client.query(
        {
          query: ALL_PARTNER_FOR_USER_QUERY,
          variables: { userId: Number(props.selectedUser.user_id) },
          fetchPolicy: "network-only"
        }).then(response => {

        const partnerList = Array.from(
          response.data?.allPartnersForUser
            .reduce(
              (acc, item) => (
                item && item["partnerId"] && acc.set(item["partnerId"], item),
                  acc
              ),
              new Map()
            )
            .values()
        );
        if (assignedPartners.length > 0) {
          partnerList.push(assignedPartners);
        }
        setPartnerList(partnerList);
      });
    } else {
      setPartnerList(props.assignedPartners);
    }

  }, []);

  const partnerAssign = () => {
    const selected: AssignRights = partnerNumbersOptions.filter(selected => selected.partnerId === selectedPartner)[0];

    partnerList.push(selected);
    assignedPartners.push(selected);

    // @ts-ignore
    setAssignedPartner(assignedPartners.length > 1 ? removeDuplicates("partnerId")(assignedPartners) : assignedPartners);
    gridApi.setRowData(partnerList.length > 1 ? removeDuplicates("partnerId")(partnerList) : partnerList);
  };

  const handleSearchByNumber = value => {
    if (value !== undefined && value.length > 2) {
      isLoading(true);
      client.query(
        {
          query: ALL_PARTNER_BY_PARTNER_NO,
          variables: { partnerNo: value },
          fetchPolicy: "network-only"
        }).then(response => {

        const searchOption = Array.from(
          response.data.allPartnersByPartnerNo
            .reduce(
              (acc, item) => (
                item && item["partnerId"] && acc.set(item["partnerId"], item),
                  acc
              ),
              new Map()
            )
            .values()
        );
        // @ts-ignore
        setPartnerNumbersOptions(searchOption);
        isLoading(false);
      });
    }
  };


  const handleChange = value => {
    setSelectedPartner(value);
  };

  const next = () => {
    props.submitValues(partnerList, "next");
  };

  return (

    <>
      <Form size={"middle"} layout={"vertical"} form={form}>
        <h5 className="mb5 mt5">Select Partner Details you want to assign to the User</h5>

        <Row gutter={20}>
          <Col span={18}>
            <Form.Item label={"Please select a Partner"}
                       rules={[{ required: true, message: "Please select partner details" }]}
                       name={"partnerNumber"}>
              <Select
                value={selectedPartner}
                showSearch={true}
                placeholder={"Enter partner number or partner name"}
                onSearch={handleSearchByNumber}
                onChange={handleChange}
                defaultActiveFirstOption={false}
                // showArrow={false}
                filterOption={false}
                notFoundContent={null}
                loading={loading}
              >
                {partnerNumbersOptions &&
                partnerNumbersOptions.map((item) => (
                  <Option key={`${item.partnerId}_${item.partnerNo}`} value={item.partnerId}>
                    {item.partnerNo} - {item.partnerName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={6} md={4}>
            <Form.Item className="mt5">
              <Button className="w-100 mt20" size={"large"} type={"primary"} onClick={partnerAssign}
                      disabled={!selectedPartner} loading={assignLoading}>Assign</Button>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={22}>
            <div style={{ flex: "auto", height: "250px", paddingTop: "10px" }} className="ag-theme-balham">
              <AgGridReact
                defaultColDef={{
                  enableValue: true,
                  sortable: true,
                  resizable: true
                }}
                datasource={partnerList}
                columnDefs={PARTNER_TABLE_HEADERS}
                rowData={partnerList}

                sideBar={false}
                rowClass="agGridRows"
                suppressMenuHide={true}
                debug={false}
                pagination={true}
                paginationPageSize={6}
                suppressRowClickSelection={true}
                onGridReady={onGridReady}
                frameworkComponents={{
                  btnUnassignedCellRenderer: BtnUnassignedCellRenderer
                }}
              />
            </div>
          </Col>
        </Row>
        <Form.Item>
          <Row gutter={8}>
            <Col xs={24} sm={6} md={4}>
              <Button type="default" size="large" className="w-100 mt10" onClick={() => navigate("/users")}>
                {props.intl.formatMessage({
                  id: "survey-main-general-cancel-button",
                  defaultMessage: "CANCEL"
                  })}
              </Button>
            </Col>

            <Col xs={24} sm={6} md={4}>
              <Button
                type="primary"
                size="large"
                className="w-100 mt10"
                onClick={next}
              >
                {props.intl.formatMessage({
                  id: "survey-main-general-next-button",
                  defaultMessage: "NEXT"
                  })}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>


    </>
  );
};

export default AssignPartners;
