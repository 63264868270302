// ______________________________________________________________________________
//
//       Project: e-Invoicing Documents Portal
//       File: https://atc.bmwgroup.net/bitbucket/scm/einvmrp/einvoicing-portal.git
//       Version: 0.0.1
// ______________________________________________________________________________
//
//    Created by: Lesley Netshiavhela <Lesley.Netshiavhela@partner.bmw.co.za>
//    Creation date:  2021/10/27
// ______________________________________________________________________________
//
//     Copyright: (C) BMW Group 2020, all rights reserved
// ______________________________________________________________________________
//
import React, { useEffect, useState } from "react";
import { IAppComponentProps, PortalUser } from "../../components";
import { Button, Col, Form, Row, Select } from "antd";
import { AgGridReact } from "ag-grid-react";
import { COMPANY_TABLE_HEADERS, removeDuplicates } from "./UserUtils";
import {
  ALL_ASSIGN_USER_RIGHTS,
  ALL_PROCESS_CODE_QUERY,
  ASSIGN_USER_RIGHTS,
  AssignRights,
  COMPANY_CODE_BY_PARTNER_QUERY
} from "./queries";
import client from "../../config/GraphQLApolloClient";
import BtnUnassignedCellRenderer from "./BtnUnassignedCellRenderer";
import { GridApi } from "ag-grid-community";
import _ from "lodash";
import { showErrorNotification } from "../Notification";
import { omitDeep } from "../../utils/Utils";
import {useNavigate} from "react-router-dom";

type AssignCompanyCodeProps = {
  selectedUser: PortalUser;
  assignedPartners: AssignRights[];
  next: any;
  prev: any;
} & IAppComponentProps;


const AssignCompanyCode: React.FunctionComponent<AssignCompanyCodeProps> = (props) => {

  const [form] = Form.useForm();
  const { Option } = Select;
  const navigate = useNavigate(); // useNavigate replaces use History in react-router-dom v6, history changed to navigate
  const [assignLoading, isAssignLoading] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi>(undefined);
  const [loading, isLoading] = useState(false);
  const [submitLoading, isSubmitLoading] = useState(false);
  const [companyCodeOptions, setCompanyCodeOptions] = useState([]);
  const [partnerNumberOptions, setPartnerNumberOptions] = useState<AssignRights[]>([]);
  const [selectedCompanyCode, setSelectedCompanyCode] = useState(undefined);
  const [selectedPartnerNumber, setSelectedPartnerNumber] = useState(undefined);
  const [companyCodeList, setCompanyCodeList] = useState<AssignRights[]>([]);
  const [assignedPartnersCompanyCode, setAssignedPartnersCompanyCode] = useState<AssignRights[]>([]);
  const [processCodeList, setProcessCode] = useState(undefined);
  const [selectedProcessCodeId, setSelectedProcessCodeId] = useState(undefined);
  const [processCodeOptions, setProcessCodeOptions] = useState([]);


  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  useEffect(() => {
    setPartnerNumberOptions(props.assignedPartners);
    Promise.all([
      client.query(
        {
          query: ALL_ASSIGN_USER_RIGHTS,
          variables: { userId: Number(props.selectedUser.user_id) },
          fetchPolicy: "network-only"
        }),
      client.query(
        {
          query: ALL_PROCESS_CODE_QUERY,
          fetchPolicy: "network-only"
        })

    ]).then(([allCompanyCodesForUser, allProcesses]) => {

      const assignedRights: AssignRights[] = omitDeep(
        allCompanyCodesForUser.data?.getAllUserRights,
        (_, prop) => ["__typename"].includes(prop)
      );

      setCompanyCodeList(assignedRights);
      setAssignedPartnersCompanyCode(assignedRights);
      setProcessCodeOptions(allProcesses.data.allProcessCode);
    });

  }, [partnerNumberOptions]);

  const companyCodeAssign = () => {

    const selected: AssignRights = companyCodeOptions.filter(s => s.companyId === Number(selectedCompanyCode))[0];
    const assignedPartner = props.assignedPartners.filter(p => p.partnerNo === selectedPartnerNumber)[0];
    const assignedProcessCode = processCodeOptions.filter(p => p.id === selectedProcessCodeId)[0];

    const assignRights: AssignRights = {
      ...selected, ...assignedPartner,
      processCodeId: Number(assignedProcessCode.id),
      processCode: assignedProcessCode.value,
      processCodeName: assignedProcessCode.name,
      userId: Number(props.selectedUser.user_id),
      assigned: Number(1),
      companyId: Number(selected.companyId),
      roleId: Number(props.selectedUser.role_id),
      roleName: props.selectedUser.role,
      systemId: Number(selected.systemId)
    };
    delete assignRights["__typename"];
    delete assignRights["category"];

    let assigned: AssignRights[] = _.cloneDeep(assignedPartnersCompanyCode);
    assigned.push(assignRights);
    assigned = assigned.length > 1 ? _.uniqWith(
      assigned,
      (assignedA, assignedB) =>
        assignedA.partnerNo === assignedB.partnerNo &&
        assignedA.companyCode === assignedB.companyCode &&
        assignedA.processCode === assignedB.processCode
    ) : assigned;


    // @ts-ignore
    setAssignedPartnersCompanyCode(assigned);
    gridApi.setRowData(assigned);


  };

  const handleProcessCodeChange = value => {
    setSelectedProcessCodeId(value);
  };

  const handleCompanyChange = value => {
    setSelectedCompanyCode(value);
  };
  const handleChange = value => {
    isLoading(true);
    setSelectedPartnerNumber(value);
    client.query(
      {
        query: COMPANY_CODE_BY_PARTNER_QUERY,
        variables: {
          partnerno: value,
          rolename: props.currentUser.role
        },
        fetchPolicy: "network-only"
      }).then(response => {
      setCompanyCodeOptions(response.data.allCompaniesByPartnerNumber.length > 1 ? removeDuplicates("companyId")(response.data.allCompaniesByPartnerNumber) : response.data.allCompaniesByPartnerNumber);
      isLoading(false);
    }).catch(_ => isLoading(false));

  };


  const submitUserRights = () => {

    if (assignedPartnersCompanyCode) {
      isSubmitLoading(true);
      client.mutate(
        {
          mutation: ASSIGN_USER_RIGHTS,
          variables: {
            userRights: assignedPartnersCompanyCode,
            userId: Number(props.selectedUser.user_id)
          }
        }).then(response => {
        setAssignedPartnersCompanyCode(response.data.mutateAssignedUserRights);
        isSubmitLoading(false);
      }).catch(e => {
        showErrorNotification(e.errors);
        isSubmitLoading(false);
      });
    }

  };

  return (

    <>
      <Form size={"middle"} layout={"vertical"} form={form}>
        <h5 className="mb5 mt5">Select Partner Number and Company code you want to assign to the User</h5>


        <Row gutter={20}>
          <Col span={10}>
            <Form.Item label={"Select Partner Number"}
                       rules={[{ required: true, message: "Select Partner Number" }]}
                       name={"partnerNo"}>
              <Select
                value={selectedPartnerNumber}
                placeholder={"Select Partner Number"}
                defaultActiveFirstOption={false}
                onChange={handleChange}
              >
                {partnerNumberOptions &&
                partnerNumberOptions.map((item) => (
                  <Option key={`${item.partnerId}_${item.partnerNo}`} value={item.partnerNo}>
                    {item.partnerNo} - {item.partnerName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label={"Please select Company code"}
                       rules={[{ required: true, message: "Please select Company code" }]}
                       name={"companyCode"}>
              <Select
                value={selectedCompanyCode}
                placeholder={"Select company Code"}
                defaultActiveFirstOption={false}
                loading={loading}
                onChange={handleCompanyChange}
              >
                {companyCodeOptions &&
                companyCodeOptions.map((item) => (
                  <Option key={`${item.companyId}_${item.companyCode}`} value={item.companyId}>
                    {item.companyCode} - {item.companyName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={10}>
            <Form.Item label={"Please select process code"}
                       rules={[{ required: true, message: "Please select process code" }]}
                       name={"processCode"}>
              <Select
                value={selectedProcessCodeId}
                placeholder={"Select process Code"}
                defaultActiveFirstOption={false}
                loading={loading}
                onChange={handleProcessCodeChange}
              >
                {processCodeOptions &&
                processCodeOptions.map((item) => (
                  <Option key={`${item.id}_${item.name}`} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={6} md={4}>
            <Form.Item className="mt5">
              <Button className="w-100 mt20" size={"large"} type={"primary"} onClick={companyCodeAssign}
                      disabled={!selectedProcessCodeId}>Assign</Button>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={22}>
            <div style={{ flex: "auto", height: "250px", paddingTop: "10px" }} className="ag-theme-balham">
              <AgGridReact
                defaultColDef={{
                  enableValue: true,
                  sortable: true,
                  resizable: true
                }}
                rowData={assignedPartnersCompanyCode}
                columnDefs={COMPANY_TABLE_HEADERS(props.intl)}

                sideBar={false}
                rowClass="agGridRows"
                suppressMenuHide={true}
                debug={false}
                pagination={true}
                paginationPageSize={6}
                suppressRowClickSelection={true}
                onGridReady={onGridReady}
                frameworkComponents={{
                  btnUnassignedCellRenderer: BtnUnassignedCellRenderer
                }}
              />
            </div>
          </Col>
        </Row>
        <Form.Item>

          <Row gutter={8}>
            <Col xs={24} sm={6} md={4}>
              <Button type="default" size="large" className="w-100 mt10" onClick={() => {navigate("/users")}}>
                {props.intl.formatMessage({
                  id: "survey-main-general-cancel-button",
                  defaultMessage: "CANCEL"
                  })}
              </Button>
            </Col>

            <Col xs={24} sm={6} md={4}>
              <Button
                type="default"
                size="large"
                className="w-100 mt10"
                onClick={props.prev}
              >
                {props.intl.formatMessage({
                  id: "survey-main-general-previous-button",
                  defaultMessage: "PREVIOUS"
                  })}
              </Button>
            </Col>
            <Col xs={24} sm={6} md={4}>
              <Button
                type="primary"
                size="large"
                className="w-100 mt10"
                onClick={submitUserRights}
                loading={submitLoading}
                disabled={!selectedProcessCodeId}>
                {props.intl.formatMessage({
                  id: "survey-main-general-submit-button",
                  defaultMessage: "SUBMIT"
                  })}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>


    </>
  );
};

export default AssignCompanyCode;
