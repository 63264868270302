import client from "../../../config/GraphQLApolloClient";
import {
  ADD_SELECTED_PARTNER_TO_SURVEY_PROCESS,
  GET_SELECTED_PARTNER_TO_SURVEY_PROCESS,
  GET_SURVEY_PARTNER_CONTACT_MASTER_DATA_BY_GROUP_ID,
  GET_SURVEY_PARTNER_MASTER_DATA_BY_GROUP_ID
} from "./queries";
import { Portal_AddSurveyData, Portal_AddSurveyDataVariables } from "./__generated__/Portal_AddSurveyData";
import { Portal_GetSurveyData, Portal_GetSurveyDataVariables } from "./__generated__/Portal_GetSurveyData";
import { SurveyMasterDataType } from "../../../../__generated__/globalTypes";
import {
  Portal_GetSurveyPartnerMasterData, Portal_GetSurveyPartnerMasterData_Portal_GetSurveyPartnerMasterData,
  Portal_GetSurveyPartnerMasterDataVariables
} from "./__generated__/Portal_GetSurveyPartnerMasterData";
import {
  Portal_GetSurveyContactMasterData, Portal_GetSurveyContactMasterData_Portal_GetSurveyContactMasterData,
  Portal_GetSurveyContactMasterDataVariables
} from "./__generated__/Portal_GetSurveyContactMasterData";
import React from "react";
import { renderAsDate } from "../../documents/myDocuments";
import { IntlShape } from "react-intl/src/types";
import {agGridGetTranslationFromPhraseID} from "../SurveyUtils";
import {PortalUser} from "../../../components";
import {
  convertFromSpecifiedDateFormatToUserDefined,
  convertFromUserSpecifiedDateFormatToExpected
} from "../../users/UserFormatPreferenceServices";


export const getSurveyPartnerDataByGroupId = (groupID: string): Promise<Portal_GetSurveyPartnerMasterData_Portal_GetSurveyPartnerMasterData[]> => {
  return new Promise((resolve, reject) => {
    client
      .query<Portal_GetSurveyPartnerMasterData, Portal_GetSurveyPartnerMasterDataVariables>({
        query: GET_SURVEY_PARTNER_MASTER_DATA_BY_GROUP_ID,
        variables: { groupId: groupID },
        fetchPolicy: "network-only"
      }).then(response => resolve(response.data.Portal_GetSurveyPartnerMasterData)).catch(error => reject(error));
    }
  );
};
export const getSurveyContactDataByGroupId = (groupID: string): Promise<Portal_GetSurveyContactMasterData_Portal_GetSurveyContactMasterData[]> => {
  return new Promise((resolve, reject) => {
      client
        .query<Portal_GetSurveyContactMasterData, Portal_GetSurveyContactMasterDataVariables>({
          query: GET_SURVEY_PARTNER_CONTACT_MASTER_DATA_BY_GROUP_ID,
          variables: { groupId: groupID },
          fetchPolicy: "network-only"
        }).then(response => resolve(response.data.Portal_GetSurveyContactMasterData)).catch(error => reject(error));
    }
  );
};

export const addSelectedSurveyMasterData = (groupID: string, surveyIDs: SurveyMasterDataType[]): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    client
      .query<Portal_AddSurveyData, Portal_AddSurveyDataVariables>({
        query: ADD_SELECTED_PARTNER_TO_SURVEY_PROCESS,
        variables: { surveyIds: surveyIDs, groupId: groupID ? groupID : "" },
        fetchPolicy: "network-only"
      })
      .then((response) => {
        resolve(response.data?.Portal_AddSurveyData[0].groupId);
      })
      .catch((err) => {
        reject(err);
      });
  });

};

export const getSelectedSurveyMasterData = (groupID: string, surveyIDs: SurveyMasterDataType[]): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    client
      .query<Portal_GetSurveyData, Portal_GetSurveyDataVariables>({
        query: GET_SELECTED_PARTNER_TO_SURVEY_PROCESS,
        variables: { surveyIds: surveyIDs, groupId: groupID ? groupID : "" },
        fetchPolicy: "network-only"
      })
      .then((response) => {
        resolve(response.data?.Portal_GetSurveyData[0].groupId);
      })
      .catch((err) => {
        reject(err);
      });
  });

};

export const SURVEY_MASTER_DATA_TABLE_HEADERS = (intl: IntlShape) => [
  {
    headerName: intl.formatMessage({ id: "survey-master-data-grid-header-partnerno", defaultMessage: "Partner No" }),
    field: "partnerNo",
    colId: "partnerNo"
  },
  {
    headerName: intl.formatMessage({
      id: "survey-master-data-grid-header-partnerName",
      defaultMessage: "Partner Name"
    }),
    field: "partnerName",
    colId: "partnerName"
  },
  {
    headerName: intl.formatMessage({
      id: "survey-master-data-grid-header-companyCode",
      defaultMessage: "Company Code"
    }),
    field: "companyCode",
    colId: "companyCode"
  },
  {
    headerName: intl.formatMessage({
      id: "survey-master-data-grid-header-partnerType",
      defaultMessage: "Partner Type"
    }),
    field: "categoryDescription",
    colId: "categoryDescription"
  },
  {
    headerName: intl.formatMessage({ id: "survey-master-data-grid-header-surveyDate", defaultMessage: "Survey Date" }),
    field: "insertTime",
    colId: "insertTime",
    cellRendererFramework: (props) => renderAsDate(props.value)
  }
];

export const SURVEY_MASTER_DATA_CONTACT_TABLE_HEADERS = (intl: IntlShape) => [
  {
    headerName: intl.formatMessage({ id: "survey-master-data-grid-header-partnerno", defaultMessage: "Partner No" }),
    field: "partnerNo",
    colId: "partnerNo"
  },
  {
    headerName: intl.formatMessage({
      id: "survey-master-data-grid-header-companyCode",
      defaultMessage: "Company Code"
    }),
    field: "companyCode",
    colId: "companyCode"
  },
  {
    headerName: intl.formatMessage({ id: "survey-master-data-grid-header-role", defaultMessage: "Role" }),
    field: "user_Role",
    colId: "user_Role"
  },
  {
    headerName: intl.formatMessage({ id: "survey-master-data-grid-header-email", defaultMessage: "E-Mail" }),
    field: "email",
    colId: "email"
  },
  {
    headerName: intl.formatMessage({ id: "survey-master-data-grid-header-firstName", defaultMessage: "First name" }),
    field: "firstName",
    colId: "firstName"
  },
  {
    headerName: intl.formatMessage({ id: "survey-master-data-grid-header-lastName", defaultMessage: "Last name" }),
    field: "lastName",
    colId: "lastName"
  },
  {
    headerName: intl.formatMessage({
      id: "survey-master-data-grid-header-userLinkedToPartner",
      defaultMessage: "Is User Linked To Partner"
    }),
    field: "hasB2BUser",
    colId: "hasB2BUser",
    cellRendererFramework: (props) => {
      return props.value ? "YES" : "NO";
    }
  }
];

export const SURVEY_MASTER_DATA_PARTNER_TABLE_HEADERS = (intl: IntlShape) => [
  {
    headerName: intl.formatMessage({ id: "survey-master-data-grid-header-partnerno", defaultMessage: "Partner No" }),
    field: "partnerNo",
    colId: "partnerNo",
    checkboxSelection: true
  },
  {
    headerName: intl.formatMessage({
      id: "survey-master-data-grid-header-partnerName",
      defaultMessage: "Partner Name"
    }),
    field: "partnerName",
    colId: "partnerName"
  },
  {
    headerName: intl.formatMessage({
      id: "survey-master-data-grid-header-companyCode",
      defaultMessage: "Company Code"
    }),
    field: "companyCode",
    colId: "companyCode"
  },
  {
    headerName: intl.formatMessage({
      id: "survey-master-data-grid-header-partnerType",
      defaultMessage: "Partner Type"
    }),
    field: "categoryDescription",
    colId: "categoryDescription",
    // valueFormatter: agGridGetTranslationFromPhraseID('categoryPhraseId', 'categoryDescription',intl),
  }, {
    headerName: intl.formatMessage({ id: "survey-master-data-grid-header-accept", defaultMessage: "Accept" }),
    field: "masterDataAcceptedDate",
    colId: "masterDataAcceptedDate",
    cellRendererFramework: (props) => {
      return props.value ? (
          <span>{intl.formatMessage({ id: "survey-master-data-grid-header-accept-yes", defaultMessage: "YES" })}</span>) :
        (<span>{intl.formatMessage({ id: "survey-master-data-grid-header-accept-no", defaultMessage: "NO" })}</span>);
    }
  },
  /*{
    headerName: intl.formatMessage({ id: "survey-master-data-grid-header-action", defaultMessage: "Action" }),
    filter: false,
    cellRenderer: "actionIconMasterDataCellRenderer",
    width: 200
  }*/
];

export const SURVEY_MASTER_DATA_VAT_TABLE_HEADERS = (intl: IntlShape, user:PortalUser) => [
  {
    headerName: intl.formatMessage({ id: "survey-master-data-grid-header-partnerno", defaultMessage: "Partner No" }),
    field: "partnerNo",
    colId: "partnerNo",
  },
  {
    headerName: intl.formatMessage({
      id: "survey-master-data-grid-header-partnerName",
      defaultMessage: "Partner Name"
    }),
    field: "partnerName",
    colId: "partnerName"
  },
  {
    headerName: intl.formatMessage({
      id: "survey-master-data-grid-header-partnerCountryCode",
      defaultMessage: "Partner Country Code"
    }),
    field: "country",
    colId: "country"
  },
  {
    headerName: intl.formatMessage({ id: "survey-master-data-grid-header-taxId", defaultMessage: "Tax Id" }),
    field: "taxId",
    colId: "taxId"
  },
  {
    headerName: intl.formatMessage({ id: "survey-master-data-grid-header-vatCode", defaultMessage: "Vat Code" }),
    field: "vatCode",
    colId: "vatCode"
  },
  {
    headerName: intl.formatMessage({ id: "survey-master-data-grid-header-updateDate", defaultMessage: "Update Date" }),
    colId: "updateTime",
    comparator:(a:string,b:string)=> {
      if (a && b) {
        const aInYYYYMMDD = convertFromUserSpecifiedDateFormatToExpected(user, a, "YYYY-MM-DD");
        const bInYYYYMMDD = convertFromUserSpecifiedDateFormatToExpected(user, b, "YYYY-MM-DD");
        return aInYYYYMMDD.localeCompare(bInYYYYMMDD);
      }
      return 0;
    },
    valueGetter: (params) => {
      if (params.data.updateTime) {
        const dateInYYYY_MM_DD = params.data.updateTime.substring(0, 10);
        return convertFromSpecifiedDateFormatToUserDefined(user, dateInYYYY_MM_DD, "YYYY-MM-DD");
      }
      return "";
    },
  }
];

export const SURVEY_MASTER_DATA_ADDRESS_TABLE_HEADERS = (intl: IntlShape, user:PortalUser) => [
  {
    headerName: intl.formatMessage({ id: "survey-master-data-grid-header-partnerno", defaultMessage: "Partner No" }),
    field: "partnerNo",
    colId: "partnerNo",
  },
  {
    headerName: intl.formatMessage({
      id: "survey-master-data-grid-header-partnerName",
      defaultMessage: "Partner Name"
    }),
    field: "partnerName",
    colId: "partnerName"
  },
  {
    headerName: intl.formatMessage({ id: "survey-master-data-grid-header-street", defaultMessage: "Street" }),
    field: "street1",
    colId: "street1"
  },
  {
    headerName: intl.formatMessage({ id: "survey-master-data-grid-header-city", defaultMessage: "City" }),
    field: "city",
    colId: "city"
  },
  {
    headerName: intl.formatMessage({ id: "survey-master-data-grid-header-poBox", defaultMessage: "PO Box" }),
    field: "poBox",
    colId: "poBox"
  },
  {
    headerName: intl.formatMessage({ id: "survey-master-data-grid-header-postalCode", defaultMessage: "Postal Code" }),
    field: "postalCode",
    colId: "postalCode"
  },
  {
    headerName: intl.formatMessage({
      id: "survey-master-data-grid-header-countryCode",
      defaultMessage: "Partner Country"
    }),
    field: "countryCode",
    colId: "countryCode"
  },
  {
    headerName: intl.formatMessage({ id: "survey-master-data-grid-header-updateDate", defaultMessage: "Update Date" }),
    colId: "updateTime",
    comparator:(a:string,b:string)=> {
      if (a && b) {
        const aInYYYYMMDD = convertFromUserSpecifiedDateFormatToExpected(user, a, "YYYY-MM-DD");
        const bInYYYYMMDD = convertFromUserSpecifiedDateFormatToExpected(user, b, "YYYY-MM-DD");
        return aInYYYYMMDD.localeCompare(bInYYYYMMDD);
      }
      return 0;
    },
    valueGetter: (params) => {
      if (params.data.updateTime) {
        const dateInYYYY_MM_DD = params.data.updateTime.substring(0, 10);
        return convertFromSpecifiedDateFormatToUserDefined(user, dateInYYYY_MM_DD, "YYYY-MM-DD");
      }
      return "";
    },
  }
];


