import React, { FunctionComponent, useEffect, useState } from "react";
import {Button, Col, Row, Form, Typography, Collapse, Switch, Radio, message} from "antd";

import { IAppComponentProps } from "../../../components";
import { SurveyConnectivitySMTPInput } from "../../../../__generated__/globalTypes";
import { cleanFormValues, Form_Row, FormItem_VDA_Row, getInitialFormValues } from "./_SurveyComponents";
import { updateSurveyConnectivitySmtp, getSurveyConnectivitySmtp } from "./SurveyConnectivityService";
import { useIntl } from "react-intl";
import { PARTNER_TYPE } from "../../../constants";
import { useAppDispatch, useAppSelector } from "../../../main/hooks";
import { MODE } from "../IStoredSurvey";

import styles from "../styling/survey.module.css";
import { resetSurveyDataLoading, setSurveyDataLoading } from "../IStoredSurveySlice";
import { getI18n } from "../../../utils/Utils";

const { Text } = Typography;
const { Panel } = Collapse;

type ISurveyConnectivityInfoContactProps = {
  groupId?: any;
  onStatusUpdate?: any;
} & IAppComponentProps;

const SurveyConnectivitySMTP: FunctionComponent<ISurveyConnectivityInfoContactProps> = (props) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [productionActive, setProductionActive] = useState(true);
  const intl = useIntl(); 
  const envSelected = productionActive ? intl.formatMessage({ id: "survey-connectivityInfo-production-selection", defaultMessage: "Production Detail" }) : intl.formatMessage({ id: "survey-as2-test-detail", defaultMessage: "Test Detail" })
  const [expandedPanels, setExpandedPanels] = useState([]);

 const surveyState = useAppSelector((state) => state.survey);

 const isViewMode = () => {
   return surveyState.survey.surveyMode === MODE.VIEW ? true : false
 }

 const isOverViewMode = () => {
   return surveyState.survey.surveyMode === MODE.OVERVIEW ? true : false
 }

  const expandAllPanels = () => {
    setExpandedPanels(["1", "2", "4"]);
  }

  const panelsChanged = (e) =>{
    setExpandedPanels(e);
  }

  const loadData = () => {
    dispatch(setSurveyDataLoading(true));
    getSurveyConnectivitySmtp(props.groupId)
      .then((response: any) => {
        if (response.getSurveyConnectivitySmtpBySurvey?.length > 0) {
          const initialValues = getInitialFormValues(response.getSurveyConnectivitySmtpBySurvey, 'environment');
          form.setFieldsValue(initialValues);
          setDataLoaded(true);
        }
      })
      .catch(() => {
        dispatch(resetSurveyDataLoading(true));
      })
      .finally(() => {
        dispatch(setSurveyDataLoading(false));
      });
  }

  const onFinish = (values: any) => {
    // eslint-disable-next-line no-console
    // console.dir(values);
    expandAllPanels();
    // setLoading(true);
    dispatch(setSurveyDataLoading(true));
    values.environment = productionActive ? "PRODUCTION" : "TEST";
    const cleanValues = cleanFormValues(values, values.environment);
    const smtpInfo: SurveyConnectivitySMTPInput = cleanValues;
    smtpInfo.partnerInboundMaximumEmailSizeMegaBytes = Number.parseInt(cleanValues.partnerInboundMaximumEmailSizeMegaBytes);
    // eslint-disable-next-line no-console
    //console.dir(smtpInfo);
    updateSurveyConnectivitySmtp(props.groupId, smtpInfo)
    .then(() => {
      message.success(getI18n("survey-connectivity-save-contact-success-message", "Connectivity Information saved successfully" , intl));
    })
      .catch(() => {
        dispatch(resetSurveyDataLoading(true));
        message.error(getI18n("survey-connectivity-save-contact-failed-message", "Failed to save connectivity information" , intl));
      })
      .finally(() => {
        dispatch(setSurveyDataLoading(false));
        //force issues component to refresh
        props.onStatusUpdate();
      });
  }

  useEffect(()=>{
    if (!dataLoaded)
    {
      loadData();
    }
    
  },[dataLoaded]);

  return (
    <>
      <Row>
        <Col>
          <Text disabled={true}>GroupId: {props.groupId}</Text>
        </Col>
      </Row>
      <Row gutter={[24, 10]}>
        <Col>
          <Text>{intl.formatMessage({ id: "survey-connectivityInfo-smtp-descriptionLine1", defaultMessage: "You have selected SMTP as the communication channel." })}</Text>
        </Col>
      </Row>
      <Row gutter={[24, 10]}>
        <Col>
          <Text>{intl.formatMessage({ id: "survey-connectivityInfo-smtp-descriptionLine2", defaultMessage: "Please provide the required information for SMTP connectivity between you and our eInvoice service provider:" })}</Text>
        </Col>
      </Row>
      <Row>
        <div>
          <label>
            {intl.formatMessage({
              id: "survey-connectivityInfo-environment-prompt",
              defaultMessage: "Do you want to capture the PRODUCTION or TEST environment details?"
            })}
          </label><Switch className={"ml10"}
                          checkedChildren={intl.formatMessage({
                            id: "survey-connectivityInfo-production-selection",
                            defaultMessage: "Production Detail"
                          })}
                          unCheckedChildren={intl.formatMessage({
                            id: "survey-as2-test-detail",
                            defaultMessage: "Test Detail"
                          })}
                          defaultChecked={true}
                          onChange={setProductionActive} />
        </div>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          {/* <Spin spinning={loading} size={"large"}> */}
            <Form noValidate={true}
              labelAlign={"left"}
              labelWrap={true}
              onFinish={onFinish}
              form={form}
            >
              <Collapse defaultActiveKey={["1"]} activeKey={expandedPanels} onChange={panelsChanged} className={"mt10"}>
                <Panel header={`${intl.formatMessage({
                  id: "survey-connectivityInfo-smtp-serviceProviderToTradingPartner-title",
                  defaultMessage: "Service Provider TO Trading Partner"
                })} - ${envSelected}`} key="1" forceRender={true}>
                  <table className="form--table" style={{ tableLayout: "fixed", width: "100%" }}>
                    {/*   <colgroup>
                      <col span={1} style={{ "width": "60%" }} />
                      <col span={1} style={{ "width": "20%" }} />
                      <col span={1} style={{ "width": "20%" }} />
                    </colgroup>*/}
                    <thead>
                    <tr>
                      <th>{intl.formatMessage({
                        id: "survey-connectivityInfo-smtp-emailDetails-title",
                        defaultMessage: "eMail details"
                      })}</th>
                      <th />
                      <th>{intl.formatMessage({
                        id: "survey-connectivityInfo-commentHeader",
                        defaultMessage: "Comment"
                      })}</th>
                        <th>{intl.formatMessage({ id: "survey-connectivityInfo-InstructionHeader", defaultMessage: "Instruction" })}</th>
                      </tr>
                    </thead>
                    <tbody>
                    <FormItem_VDA_Row
                      isProduction={productionActive}
                      label={intl.formatMessage({
                        id: "survey-connectivityInfo-smtp-subject-prompt",
                        defaultMessage: "Subject"
                      })}
                      instruction={intl.formatMessage({
                        id: "survey-connectivityInfo-smtp-subject-instruction",
                        defaultMessage: " "
                      })}
                      name="partnerInboundMessageSubject"
                      required={false}
                    />
                    <FormItem_VDA_Row
                      isProduction={productionActive}
                      label={intl.formatMessage({ id: "survey-connectivityInfo-smtp-to-prompt", defaultMessage: "To" })}
                      instruction={intl.formatMessage({
                        id: "survey-connectivityInfo-smtp-to-instruction",
                        defaultMessage: " "
                      })}
                      name="partnerInboundMessageTo"
                      required={false}
                      validationMessage={intl.formatMessage({
                        id: "survey-connectivityInfo-invalid-email",
                        defaultMessage: "Email must include one '@' symbol, a domain, no spaces, and only valid characters."
                      })}
                      type="email"
                    />
                    <FormItem_VDA_Row
                      isProduction={productionActive}
                      label={intl.formatMessage({ id: "survey-connectivityInfo-smtp-cc-prompt", defaultMessage: "CC" })}
                      instruction={intl.formatMessage({
                        id: "survey-connectivityInfo-smtp-cc-instruction",
                        defaultMessage: " "
                      })}
                      name="partnerInboundMessageCC"
                      required={false}
                      validationMessage={intl.formatMessage({
                          id: "survey-connectivityInfo-invalid-email",
                          defaultMessage: "Email must include one '@' symbol, a domain, no spaces, and only valid characters."
                      })}
                      type="email"
                    />
                    <tr>
                      <th>{intl.formatMessage({
                        id: "survey-connectivityInfo-smtp-emailBody-title",
                        defaultMessage: "eMail Attachment And Body Details"
                      })}</th>
                      <th>{intl.formatMessage({
                        id: "survey-connectivityInfo-commentHeader",
                        defaultMessage: "Comment"
                      })}</th>
                      <th>{intl.formatMessage({
                        id: "survey-connectivityInfo-InstructionHeader",
                        defaultMessage: "Instruction"
                      })}</th>
                    </tr>
                    <FormItem_VDA_Row
                      isProduction={productionActive}
                      label={intl.formatMessage({
                        id: "survey-connectivityInfo-smtp-sendDataFileInBody-prompt",
                        defaultMessage: "Send Data File In Email Body?"
                      })}
                      instruction={intl.formatMessage({
                        id: "survey-connectivityInfo-smtp-sendDataFileInBody-instruction",
                        defaultMessage: " "
                      })}
                      name="partnerInboundMessageDataFileInEmailBody"
                      required={false}
                    >
                      <Radio.Group className={styles.yesNoStyling}>
                        <Radio.Button value={true}>{intl.formatMessage({id:'ecap-capture-bmw-retail-outlet-choice-yes'})}</Radio.Button>
                        <Radio.Button value={false}>{intl.formatMessage({id:'ecap-capture-bmw-retail-outlet-choice-no'})}</Radio.Button>
                      </Radio.Group>
                    </FormItem_VDA_Row>
                    <FormItem_VDA_Row
                      isProduction={productionActive}
                      label={intl.formatMessage({
                        id: "survey-connectivityInfo-smtp-attachmentFilename-prompt",
                        defaultMessage: "Attachement Filename Requirements"
                      })}
                      instruction={intl.formatMessage({
                        id: "survey-connectivityInfo-smtp-attachmentFilename-instruction",
                        defaultMessage: " "
                      })}
                      name="partnerInboundAttachmentFilenameRequirements"
                      required={false}
                    />
                    <FormItem_VDA_Row
                      isProduction={productionActive}
                      label={intl.formatMessage({
                        id: "survey-connectivityInfo-smtp-mailBody-prompt",
                        defaultMessage: "Email Body Text"
                      })}
                      instruction={intl.formatMessage({
                        id: "survey-connectivityInfo-smtp-mailBody-instruction",
                        defaultMessage: " "
                      })}
                      name="partnerInboundEmailBodyText"
                      required={false}
                    />
                    <FormItem_VDA_Row
                      isProduction={productionActive}
                      label={intl.formatMessage({
                        id: "survey-connectivityInfo-smtp-maxSize-prompt",
                        defaultMessage: "Maximum eMail Size In Megabytes"
                      })}
                      instruction={intl.formatMessage({
                        id: "survey-connectivityInfo-smtp-maxSize-instruction",
                        defaultMessage: " "
                      })}
                      name="partnerInboundMaximumEmailSizeMegaBytes"
                      required={false}
                      type="number"
                    />
                    </tbody>
                  </table>
                </Panel>
                <Panel header={`${intl.formatMessage({ id: "survey-connectivityInfo-smtp-tradingPartnerToServiceProvider-title", defaultMessage: "Trading Partner TO Service Provider" })} - ${envSelected}`} key="2" forceRender={true}>
                  <table className="form--table" style={{ tableLayout: "fixed", width: "100%" }}>
                    {/*      <colgroup>
                      <col span={1} style={{ "width": "55%" }} />
                      <col span={1} style={{ "width": "20%" }} />
                      <col span={1} style={{ "width": "25%" }} />
                    </colgroup>*/}
                    <thead>
                    <tr>
                      <th>{intl.formatMessage({
                        id: "survey-connectivityInfo-smtp-emailDetails-title",
                        defaultMessage: "eMail details"
                      })}</th>
                      <th />
                      <th>{intl.formatMessage({
                        id: "survey-connectivityInfo-commentHeader",
                        defaultMessage: "Comment"
                      })}</th>
                      <th>{intl.formatMessage({
                        id: "survey-connectivityInfo-InstructionHeader",
                        defaultMessage: "Instruction"
                      })}</th>
                      </tr>
                    </thead>
                    <tbody>
                    <FormItem_VDA_Row
                      isProduction={productionActive}
                      label={intl.formatMessage({
                        id: "survey-connectivityInfo-smtp-partnerEmail-prompt",
                        defaultMessage: "Trading Partner eMail Address"
                      })}
                      instruction={intl.formatMessage({
                        id: "survey-connectivityInfo-smtp-partnerEmail-instruction",
                        defaultMessage: " "
                      })}
                      name="partnerOutboundMessageFrom"
                      required={false}
                      validationMessage={intl.formatMessage({
                          id: "survey-connectivityInfo-invalid-email",
                          defaultMessage: "Email must include one '@' symbol, a domain, no spaces, and only valid characters."
                      })}
                      type="email"
                    />
                    <FormItem_VDA_Row
                      isProduction={productionActive}
                      label={intl.formatMessage({
                        id: "survey-connectivityInfo-smtp-partnerOutboundSubject-prompt",
                        defaultMessage: "Subject"
                      })}
                      instruction={intl.formatMessage({
                        id: "survey-connectivityInfo-smtp-partnerOutboundSubject-instruction",
                        defaultMessage: " "
                      })}
                      name="partnerOutboundMessageSubject"
                      required={false}
                    />
                    <FormItem_VDA_Row
                      isProduction={productionActive}
                      label={intl.formatMessage({
                        id: "survey-connectivityInfo-smtp-partnerFilesInBody-prompt",
                        defaultMessage: "Input File In Email Body"
                      })}
                      instruction={intl.formatMessage({
                        id: "survey-connectivityInfo-smtp-partnerFilesInBody-instruction",
                        defaultMessage: " "
                      })}
                      name="partnerOutboundMessageDataFileInEmailBody"
                        required={false}
                      >
                      <Radio.Group className={styles.yesNoStyling}>
                        <Radio.Button value={true}>{intl.formatMessage({id:'ecap-capture-bmw-retail-outlet-choice-yes'})}</Radio.Button>
                        <Radio.Button value={false}>{intl.formatMessage({id:'ecap-capture-bmw-retail-outlet-choice-no'})}</Radio.Button>
                      </Radio.Group>
                    </FormItem_VDA_Row>
                    </tbody>
                  </table>
                </Panel>
                <Panel header={`${intl.formatMessage({
                  id: "survey-connectivityInfo-smtp-direc-connection-email-details-title",
                  defaultMessage: "Direct Connection EMAIL Details"
                })} - ${envSelected}`} key="4" forceRender={true}>
                  <table className="form--table" style={{ tableLayout: "fixed", width: "100%" }}>
                    {/*              <colgroup>
                      <col span={1} style={{ "width": "50%" }} />
                      <col span={1} style={{ "width": "20%" }} />
                      <col span={1} style={{ "width": "20%" }} />
                      <col span={1} style={{ "width": "10%" }} />
                    </colgroup>*/}

                    <thead>
                    <tr>
                      <th>{intl.formatMessage({
                        id: "survey-connectivityInfo-smtp-emailDetails-title",
                        defaultMessage: "eMail details"
                      })}</th>
                      <th key={"item"}>{""} </th>
                      <th>{intl.formatMessage({
                        id: "survey-connectivityInfo-commentHeader",
                        defaultMessage: "Comment"
                      })}</th>
                      <th>{intl.formatMessage({
                        id: "survey-connectivityInfo-InstructionHeader",
                        defaultMessage: "Instruction"
                      })}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <FormItem_VDA_Row
                      isProduction={productionActive}
                      label={intl.formatMessage({
                        id: "survey-connectivityInfo-smtp-dc-invoice-company-e-mail-address-prompt",
                        defaultMessage: "DC Invoice Company e-mail address"
                      })}
                      instruction={getI18n(
                        "survey-connectivityInfo-smtp-dc-invoice-company-e-mail-address-instruction",
                        "Please enter 1 valid email address.", intl
                      )}
                      name="dcInvoiceCompanyEmailAddress"
                      required={true}
                      requiredMessage={intl.formatMessage({
                        id: "generic-requiredField-message",
                        defaultMessage: "Please enter required field"
                      })}
                      validationMessage={intl.formatMessage({
                          id: "survey-connectivityInfo-invalid-email",
                          defaultMessage: "Email must include one '@' symbol, a domain, no spaces, and only valid characters."
                      })}
                      type="email"
                      maxLength={100}
                    />

                    {localStorage.getItem(PARTNER_TYPE) === "V" &&
                    <FormItem_VDA_Row
                      isProduction={productionActive}
                      label={intl.formatMessage({
                        id: "survey-connectivityInfo-smtp-dc-payment-advice-company-e-mail-address-prompt",
                        defaultMessage: "DC Payment Advice Company e-mail address"
                      })}
                      instruction={intl.formatMessage({
                        id: "survey-connectivityInfo-smtp-dc-payment-advice-company-e-mail-address-instruction",
                        defaultMessage: " "
                      })}
                      name="dcPaymentAdviceCompanyEmailAddress"
                      required={true}
                      requiredMessage={intl.formatMessage({
                        id: "generic-requiredField-message",
                        defaultMessage: "Please enter required field"
                      })}                    
                      validationMessage={intl.formatMessage({
                          id: "survey-connectivityInfo-invalid-email",
                          defaultMessage: "Email must include one '@' symbol, a domain, no spaces, and only valid characters."
                      })}
                      type="email"
                      maxLength={100}
                    />
                    }

                    </tbody>
                  </table>
                </Panel>
              </Collapse>
              <Row />
              <Row gutter={[24, 10]}>
                <Col span={24} className={"mt10"}>
                  <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit" onClick={expandAllPanels} disabled={isViewMode() || isOverViewMode()}>
                      {intl.formatMessage({
                        id: "survey-connectivityInfo-smtp-save",
                        defaultMessage: "Save SMTP Detail"
                      })}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          {/* </Spin> */}
        </Col>
      </Row >
    </>
  )
}

export default SurveyConnectivitySMTP;