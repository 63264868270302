import {
    SurveyAgreementConnectivityAs2,
    SurveyConnectivityOFTP2Input,
    SurveyConnectivityPeppolInput,
    SurveyConnectivitySMTPInput,
    SurveyContactInput
} from "../../../../__generated__/globalTypes";
import client from "../../../config/GraphQLApolloClient";
import {
    UPDATE_PEPPOL_DETAILS,
    UPDATE_SURVEY_CONNECTIVITY_AS2,
    UPDATE_SURVEY_CONNECTIVITY_OFTP2,
    UPDATE_SURVEY_CONNECTIVITY_SMTP,
    UPDATE_SURVEY_CONTACT
} from "../mutations";
import {
    QUERY_PEPPOL_DETAILS,
    QUERY_SURVEY_CONNECTIVITY_AS2,
    QUERY_SURVEY_CONNECTIVITY_OFTP2_BY_SURVEY,
    QUERY_SURVEY_CONNECTIVITY_SMTP_BY_SURVEY,
    QUERY_SURVEY_CONTACT_BY_TYPE
} from "../queries";
import {
    getConnectivityPeppolDetailsForSurvey, getConnectivityPeppolDetailsForSurvey_getConnectivityPeppolDetailsForSurvey,
    getConnectivityPeppolDetailsForSurveyVariables
} from "../__generated__/getConnectivityPeppolDetailsForSurvey";
import {
    saveConnectivityPeppolDetailsForSurvey,
    saveConnectivityPeppolDetailsForSurvey_saveConnectivityPeppolDetailsForSurvey,
    saveConnectivityPeppolDetailsForSurveyVariables
} from "../__generated__/saveConnectivityPeppolDetailsForSurvey";
import { getSurveyConnectivityOftp2BySurvey } from "../__generated__/getSurveyConnectivityOftp2BySurvey";
import { getSurveyConnectivityAs2 } from "../__generated__/getSurveyConnectivityAs2";
import { getSurveyConnectivityVda } from "./__generated__/getSurveyConnectivityVda";
import { gql } from "@apollo/client";
import {updateSurveyContact as updateSurveyContactResponse,updateSurveyContactVariables} from "../__generated__/updateSurveyContact";


const FETCH_VDA_DETAILS = gql`
    query getSurveyConnectivityVda($groupId:String!) {
        getSurveyConnectivityVda(groupId: $groupId) {
            surveyConnectivityId
            surveyId
            environment
            dcInvoiceEdiIdentifier
            dcInvoiceEdiIdentifierComment
            dcInvoiceEdiQualifier
            dcInvoiceEdiQualifierComment
            dcPadEdiIdentifier
            dcPadEdiIdentifierComment
            dcPadEdiQualifier
            dcPadEdiQualifierComment
        }
    }
`;

export const updateSurveyConnectivityAs2 = (groupId: string, surveyAgreementConnectivityAs2: SurveyAgreementConnectivityAs2) => {
    return new Promise((resolve, reject) => {
        client.mutate({
            mutation: UPDATE_SURVEY_CONNECTIVITY_AS2,
            variables: { groupId, surveyAgreementConnectivityAs2 }
        })
            .then((response) => resolve(response))
            .catch((err) => reject(err))
            ;
    });
}

export const updateSurveyContact = (groupId: string, surveyContact: SurveyContactInput) => {
    return new Promise<updateSurveyContactResponse>((resolve, reject) => {
        client.mutate<updateSurveyContactResponse,updateSurveyContactVariables>({
            mutation: UPDATE_SURVEY_CONTACT,
            variables: { groupId, surveyContact }
        })
            .then((response) => resolve(response.data))
            .catch((err) => reject(err))
            ;
    });
}

export const getSurveyContactByType = (groupId: string, contactTypeId: number) => {
    return new Promise((resolve, reject) => {
        client.query({
            fetchPolicy: "network-only",
            query: QUERY_SURVEY_CONTACT_BY_TYPE,
            variables: { groupId, contactTypeId }
        })
            .then((response) => resolve(response.data))
            .catch((err) => reject(err))
            ;
    });    
}

export const updateSurveyConnectivitySmtp = (groupId: String, surveyConnectivitySMTP: SurveyConnectivitySMTPInput) => {
    return new Promise((resolve, reject) => {
        client.mutate({
            mutation: UPDATE_SURVEY_CONNECTIVITY_SMTP,
            variables: { groupId, surveyConnectivitySMTP }
        })
            .then((response) => resolve(response))
            .catch((err) => reject(err))
            ;
    });    
}

export const getSurveyConnectivitySmtp = (groupId: String) => {
    return new Promise((resolve, reject) => {
        client.query({
            fetchPolicy: "network-only",
            query: QUERY_SURVEY_CONNECTIVITY_SMTP_BY_SURVEY,
            variables: { groupId }
        })
            .then((response) => resolve(response.data))
            .catch((err) => reject(err))
            ;
    });    
}

export const getSurveyConnectivityOftp2BySurveySvc = (groupId: String) => {
    return new Promise<getSurveyConnectivityOftp2BySurvey>((resolve, reject) => {
        client.query<getSurveyConnectivityOftp2BySurvey>({
            fetchPolicy: "network-only",
            query: QUERY_SURVEY_CONNECTIVITY_OFTP2_BY_SURVEY,
            variables: { groupId }
        })
            .then((response) => resolve(response.data))
            .catch((err) => reject(err))
            ;
    });    
}

export const getSurveyConnectivityAs2Svc = (groupId: String) => {
    return new Promise<getSurveyConnectivityAs2>((resolve, reject) => {
        client.query<getSurveyConnectivityAs2>({
            fetchPolicy: "network-only",
            query: QUERY_SURVEY_CONNECTIVITY_AS2,
            variables: { groupId }
        })
            .then((response) => resolve(response.data))
            .catch((err) => reject(err))
            ;
    });    
}


export const getSurveyConnectivityVDASvc = (groupId: String) => {
    return new Promise<getSurveyConnectivityVda>((resolve, reject) => {
        client.query<getSurveyConnectivityVda>({
            fetchPolicy: "network-only",
            query: FETCH_VDA_DETAILS,
            variables: { groupId }
        })
            .then((response) => resolve(response.data))
            .catch((err) => reject(err))
            ;
    });    
}


export const updateSurveyConnectivityOftp2 = (groupId: string, surveyConnectivityOFTP2: SurveyConnectivityOFTP2Input) => {
    return new Promise((resolve, reject) => {
        client.mutate({
            mutation: UPDATE_SURVEY_CONNECTIVITY_OFTP2,
            variables: { groupId, surveyConnectivityOFTP2 }
        })
            .then((response) => resolve(response))
            .catch((err) => reject(err))
            ;
    });    
}


export const getSurveyConnectivityOftp2 = (groupId: string) => {
    return new Promise((resolve, reject) => {
        client.query({
            fetchPolicy: "network-only",
            query: QUERY_SURVEY_CONNECTIVITY_OFTP2_BY_SURVEY,
            variables: { groupId }
        })
            .then((response) => resolve(response.data))
            .catch((err) => reject(err))
            ;
    });    
}

export const getPeppolDetailsForSurvey = (groupId: string) => {
    return new Promise<getConnectivityPeppolDetailsForSurvey_getConnectivityPeppolDetailsForSurvey[]>((resolve, _) => {
        client.query<getConnectivityPeppolDetailsForSurvey,getConnectivityPeppolDetailsForSurveyVariables>({
            fetchPolicy: "network-only",
            query: QUERY_PEPPOL_DETAILS,
            variables: { groupId }
        })
            .then((response) => resolve(response.data.getConnectivityPeppolDetailsForSurvey));
    });
}

export const updateSurveyConnectivityPeppolDetails = (groupId: string, peppolDetails: SurveyConnectivityPeppolInput) => {
    return new Promise<saveConnectivityPeppolDetailsForSurvey_saveConnectivityPeppolDetailsForSurvey>((resolve, reject) => {
        client.mutate<saveConnectivityPeppolDetailsForSurvey,saveConnectivityPeppolDetailsForSurveyVariables>({
            mutation: UPDATE_PEPPOL_DETAILS,
            variables: { groupId, peppolDetails }
        })
            .then((response) => resolve(response.data.saveConnectivityPeppolDetailsForSurvey))
        ;
    });
}