import { IAppComponentProps } from "../../../components";
import React from "react";
import { useIntl } from "react-intl";
import { ECAPAddressInfo } from "../componets/ECAP-Address-component";
import { Collapse, FormInstance } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { getI18n } from "../../../utils/Utils";
import { useQuery } from "@apollo/client";
import { getDocumentType } from "../../documents/__generated__/getDocumentType";
import { getControlRuleset } from "../RulesEngine";

type SuppliersAddressDetailsProps = {
  form: FormInstance<any>;
} & IAppComponentProps;

const SuppliersAddressDetails: React.FunctionComponent<SuppliersAddressDetailsProps> = (props) => {
  const intl = useIntl();



  return (
    <>
      <Collapse
        defaultActiveKey={2}
        className={"ml2"}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      >
        <Collapse.Panel key={2.2} 
                        forceRender={true}
                        header={getI18n("general-address-title", "Supplier Address", intl)}>
          <ECAPAddressInfo streetFldName="supplierStreet1"
                           countryDisplayFldName="supplierCountry"
                           countryCodeFldName="supplierCountryCode"
                           postalCodeFldName="supplierPostalCode"
                           regionDisplayFldName="supplierRegion"
                           regionCodeFldName="supplierRegionCode"
                           regionCodeFldRule="Supplier_Address_US_OR_Canada"
                           cityFldName="supplierCity"
                           editable={false}
                           noTitle={true}
                           form={props.form} />
        </Collapse.Panel>
      </Collapse>
    </>
  );
};

export default SuppliersAddressDetails;