// @ts-ignore
import React from "react";
// @ts-ignore
import { useIntl } from "react-intl";
import { CheckOutlined, EditOutlined, EyeOutlined, FormOutlined, SaveOutlined } from "@ant-design/icons";
import { Popconfirm, Tooltip } from "antd";
import { useAppSelector } from "../../../main/hooks";
import { MODE } from "../IStoredSurvey";


type IActionIconDocumentTypeCellRendererProps = {
  view?: any;
  edit?: any;
  onBulkEdit?: any;
  accept?: any;
}
const ActionIconDocumentTypeCellRenderer: React.FunctionComponent<IActionIconDocumentTypeCellRendererProps> = (props) => {
  const intl = useIntl();

  const surveyState = useAppSelector((state) => state.survey);

  const isViewMode = () => {
    return surveyState.survey.surveyMode === MODE.VIEW ? true : false
  }

  const isOverViewMode = () => {
    return surveyState.survey.surveyMode === MODE.OVERVIEW ? true : false
  }

const isReadOnly = () => {
  return isViewMode() || isOverViewMode();
 }


  const viewChannel = () => {
    // @ts-ignore
    props.view(props.data);
  };

  const editChannel = () => {
    // @ts-ignore
    props.edit();
  };

  const bulkEdit = () => {
    // @ts-ignore
    props.onBulkEdit();
  };
  const accept = () => {
    // @ts-ignore
    props.accept();
  };

  const editDocumentTypes = intl.formatMessage({
    id: "survey-eDocument-supplier-edit-document-tooltip",
    defaultMessage: "Expert View"
  });

  const bulkEditDocumentTypes = intl.formatMessage({
    id: "survey-eDocument-supplier-bulk-edit-document-tooltip",
    defaultMessage: "Edit All"
  });

  const viewDocumentTypes = intl.formatMessage({
    id: "survey-eDocument-supplier-view-document-tooltip",
    defaultMessage: "View eDocument types"
  });
  const acceptDefaultTooltipTitle = intl.formatMessage({
    id: "survey-eDocument-supplier-grid-acceptDefault-tooltip-title",
    defaultMessage: "Accept"
  });
  const warningMessage = intl.formatMessage({
    id: "survey-eDocument-supplier-defaultAccept-message",
    defaultMessage: "You have decided to accept the BMW default settings for Format and Channels of the Process Groups on the eDocument Types for the selected partner/s."
  });

  return (
    <>
      <span>
         <span style={{ marginRight: "20px" }} hidden={isReadOnly()}>
          <Tooltip title={bulkEditDocumentTypes}>
          <FormOutlined onClick={bulkEdit} style={{ fontSize: "15px", cursor: "pointer" }} />
          </Tooltip>
        </span>
        <span style={{ marginRight: "20px" }} hidden={isReadOnly()}>
          <Tooltip title={editDocumentTypes}>
          <EditOutlined onClick={editChannel} style={{ fontSize: "15px", cursor: "pointer" }} />
          </Tooltip>
        </span>

        <span style={{ marginRight: "10px" }}>
          <Tooltip title={viewDocumentTypes}>
          <EyeOutlined onClick={viewChannel} style={{ fontSize: "15px" }} />
            </Tooltip>
        </span>
      </span>
    </>
  );
};
export default ActionIconDocumentTypeCellRenderer;