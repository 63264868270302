import {Button, Popover} from "antd";
import React from "react";

export function renderInvoiceCellValue(cellValue) {
    if (cellValue !== null && cellValue !== undefined && cellValue.trim().length > 0) {
        return (
            <Popover content={cellValue} >
                <Button type={'primary'} size={'small'}>
                    {cellValue.slice(0, 24)}
                </Button>
            </Popover>
        );
    }}

    export function renderLinkCellValue(linkText, LinkValue) {
        if (LinkValue !== null && LinkValue !== undefined && LinkValue.trim().length > 0) {
            return (
              <a className="external_link" target="_blank" href={LinkValue} rel="noreferrer">
                {linkText}
              </a>
            );
        }
        return <> </>
    }