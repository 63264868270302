import React from "react";
import styles from "../styles/scss/portal.scss";
import "./index.css";
import { Button, Col, Layout, Row } from "antd";
import { generateNonce, getWeEAMUrl } from "../main/OauthUtils";
import PortalLoading from "./PortalLoading";


type LoginLayoutProps = {
  intl?: boolean;
  oAuthUrl?: string;
};

type LoginLayoutState = {
  isReady: boolean;
  oAuthUrl: string;
  error: string;
  authenticated: boolean;
};

class LoginLayout extends React.Component<LoginLayoutProps, LoginLayoutState> {
  state: LoginLayoutState = {
    isReady: false,
    oAuthUrl: undefined,
    error: undefined,
    authenticated: false
  };

  componentDidMount() {
    Promise.resolve(getWeEAMUrl()).then((url) => {
        this.setState({
          isReady: true,
          oAuthUrl: url
        });
      window.location.assign(`${url}&nonce=${generateNonce("xxx")}`);
      }
    )
      .catch((err) => {
        this.setState({
          isReady: true,
          error: err
        });
      });

  }

  oauthLogin() {
    window.location.assign(`${this.state.oAuthUrl}&nonce=${generateNonce("xxx")}`);
  }

  render() {
    const { isReady } = this.state;

    if (!isReady) {
      return <PortalLoading key={"lgnKey"} userLoading={isReady} errorMessage={undefined} />;
    }
    return (
      <div className="content" />

    );
  }
}

export default LoginLayout;
