export function getAuthority() {
    let authority = localStorage.getItem("portal-Authorized");
    if (authority) {
        if (authority.includes("[")) {
            authority = JSON.parse(authority);
        }
    } else {
        authority = "user";
    }
    return authority;
}

export function setAuthority(authority) {
    return localStorage.setItem("portal-Authorized", JSON.stringify(authority));
}
