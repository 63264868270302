import React, { FunctionComponent, useState } from "react";
import { Card, Col, notification, Row, Space, Spin, Tooltip, Typography } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { AgGridReact } from "ag-grid-react";
import { GridApi } from "ag-grid-community";
import {
  SURVEY_MASTER_DATA_ADDRESS_TABLE_HEADERS,
  SURVEY_MASTER_DATA_PARTNER_TABLE_HEADERS,
  SURVEY_MASTER_DATA_VAT_TABLE_HEADERS
} from "./SurveyMasterDataUtils";
import "./../../Welcome.css";
import "./../../survey/SurveyOverview.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { FormattedMessage, useIntl } from "react-intl";
import { Portal_GetSurveyPartnerMasterData_Portal_GetSurveyPartnerMasterData } from "./__generated__/Portal_GetSurveyPartnerMasterData";
import { MASTER_DATA_STATUS } from "./components/ActionIconMasterDataCellRenderer";
import client from "../../../config/GraphQLApolloClient";
import { GET_SURVEY_PARTNER_ACCEPT_MASTER_DATA } from "./queries";
import { ArrowLeftOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { getSurveyVerifyData, getVerifyReportPortion } from "../SurveyVerifySubmit/SurveyVerifyAndSubmitService";
import { SELECTED_GROUP_ID } from "../../../constants";
import { getSurveyVerifyReport } from "../SurveyVerifySubmit/__generated__/getSurveyVerifyReport";
import reactDom from "react-dom";
import { getI18n } from "../../../utils/Utils";
import { getGroupId } from "../../../utils";
import { getAgGridLocalization } from "../../info-portal/components/grid/GridUtil";
import { useAppDispatch, useAppSelector } from "../../../main/hooks";
import { MODE } from "../IStoredSurvey";
import { resetSurveyDataLoading, setSurveyDataLoading } from "../IStoredSurveySlice";
import {IAppComponentProps} from "../../../components";


type IMasterDataPartnerTabDetailsProps = {
  surveyMasterData?: any;
  onStatusUpdate?: () => void;
} & IAppComponentProps;

const MasterDataPartnerTabDetails: FunctionComponent<IMasterDataPartnerTabDetailsProps> = (props) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { Title, Paragraph, Text } = Typography;
  const [gridApi, setGridApi] = useState<GridApi>(undefined);
  const [surveyAddressMasterData, setSurveyAddressMasterData] = useState([]);
  const [surveyVatMasterData, setSurveyVatMasterData] = useState([]);

  const surveyState = useAppSelector((state) => state.survey);

  const isViewMode = () => {
    return surveyState.survey.surveyMode === MODE.VIEW ? true : false
  }

  const isOverViewMode = () => {
    return surveyState.survey.surveyMode === MODE.OVERVIEW ? true : false
  }

  const isReadOnly = () => {
    return isViewMode() || isOverViewMode();
   }

  const acceptButton = intl.formatMessage({
    id: "survey-masterData-grid-accept-tooltip-title",
    defaultMessage: "Accept Master Data"
  });

  const queryButton = intl.formatMessage({
    id: "survey-masterData-grid-query-tooltip-title",
    defaultMessage: "Query Master Data"
  });
  const headerParagraphLine1 = intl.formatMessage({
    id: "survey-masterdata-partners-headerText-line1",
    defaultMessage: ""
  });
  const headerParagraphTitle = intl.formatMessage({
    id: "survey-masterdata-partners-headerText-title",
    defaultMessage: "The following partner data sets of the BMW Group have been selected for this survey. "
  });
  const headerParagraphLine2 = intl.formatMessage({
    id: "survey-masterdata-partners-headerText-line2",
    defaultMessage: " Group Partner Portal (https://b2b.bmw.com) with the application 'SUPPLIER DATA MAINTENANCE'. As a customer, please contact your BMW Group contact person in sales or business department, who will arrange for the updating of the customer master data for you."
  });
  const headerParagraphLine3 = intl.formatMessage({
    id: "survey-masterdata-partners-headerText-line3",
    defaultMessage: "contact person in sales or business department, who will arrange the updating of the customer master data for you."
  });

  const tabTaxVatTitle = intl.formatMessage({
    id: "survey-masterdata-partners-tax-vat-title",
    defaultMessage: "Partner Tax/VAT"
  });

  const tabAddressTitle = intl.formatMessage({
    id: "survey-masterdata-partners-address-title",
    defaultMessage: "Partner Address"
  });

  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const getSelectedSurvey = async (status: number) => {
    const surveyIds = [];
    gridApi?.getSelectedRows().map(selectedItem => {
      surveyIds.push({ surveyId: Number(selectedItem.surveyId), masterDataStatusId: status });
      selectedItem.includedSurveyIds
        ?.split(",")
        .map((surveyId) => surveyIds.push({ surveyId: Number(surveyId), masterDataStatusId: status }));
    });
    return surveyIds;
  };

  const updateMasterData = async () => {
    dispatch(setSurveyDataLoading(true));
    const surveyIds = await getSelectedSurvey(MASTER_DATA_STATUS.YES);
    await client.mutate({
      mutation: GET_SURVEY_PARTNER_ACCEPT_MASTER_DATA,
      variables: { surveyIds },
      fetchPolicy: "network-only"
    }).then((response) => {
      if (gridApi && gridApi.getSelectedRows().length > 0) {
        const rowData = gridApi.getSelectedRows()[0];
        const rowNode = gridApi.getSelectedNodes()[0];
        rowNode.setData({ ...rowData, partnerNo: rowData.partnerNo, masterDataAcceptedDate: "YES" });
      }
      notification.success({
        message: intl.formatMessage({
          id: "masterdata-accept-successfully-title",
          defaultMessage: "Successfully"
        }),
        description: response.data.Portal_AcceptMasterData
      })

      dispatch(setSurveyDataLoading(true));
      Promise.resolve(getSurveyVerifyData(localStorage.getItem(SELECTED_GROUP_ID)))
        .then((response: getSurveyVerifyReport) => {
          const masterData = getVerifyReportPortion(response, 1);

          const stepDescription = Array.prototype.slice.call(document.querySelectorAll(".ant-steps-item-description"))[0];
          reactDom.render(
            <div className="ant-steps-item-description">
              {getI18n(masterData.status, "Status not decoded", intl)}
            </div>,
            stepDescription
          );
          const masterDataIssuesTranslated = [];
          masterData.issues.forEach((item) => {
            masterDataIssuesTranslated.push(getI18n(item, "Issue not decoded", intl));
          });
          reactDom.render(
            <label>{masterDataIssuesTranslated?.join(", ")}</label>,
            document.querySelector("#portionSequence-1")
          );
          //isLoading(false);
        })
        .finally(() => {
          if (props.onStatusUpdate) {
            //console.log('Refresh screen...')
            props.onStatusUpdate();
            dispatch(setSurveyDataLoading(false));
          }
        })
    }).catch((err) => {
      console.error(err);
      notification.error({
        message: intl.formatMessage({
          id: "masterdata-accept-error-title",
          defaultMessage: "Error"
        }),
        description:
          intl.formatMessage({
            id: "masterdata-accept-error-message",
            defaultMessage: "Fail to accept master data"
          })
      });
      dispatch(resetSurveyDataLoading(false));
      //isLoading(false);
    })
    .finally(() => {
      dispatch(setSurveyDataLoading(false));
    });
  };

  const onAccept = () => {
    //isLoading(true);
    dispatch(setSurveyDataLoading(true));

    //  updateMasterData();
    Promise.resolve(getSurveyVerifyData(getGroupId())
      .then((response: getSurveyVerifyReport) => {
        //Get Survey master data portion
        const masterData = getVerifyReportPortion(response, 1);
        if (masterData !== undefined && masterData.issues !== undefined && masterData.canUpdate) {
          updateMasterData()
        } else if (masterData !== undefined && masterData.issues !== undefined && masterData.issues.length > 0) {
          const masterDataIssuesTranslated = [];
          masterData.issues.forEach((item) => {
            masterDataIssuesTranslated.push(getI18n(item, "Issue not decoded", intl));
          });
          //isLoading(false);
          notification.error({
            message: intl.formatMessage({
              id: "masterdata-accept-error-title",
              defaultMessage: "Error"
            }),
            description: masterDataIssuesTranslated.join(",")

          });
        } else {
          //isLoading(false);
          notification.error({
            message: intl.formatMessage({
              id: "masterdata-accept-error-title",
              defaultMessage: "Error"
            }),
            description:
              intl.formatMessage({
                id: "masterdata-accept-error-unresolved-error",
                defaultMessage: `Fail to accept master data, cannot find master data`
              })
          });
        }
      }))
      .catch(e => {
        console.error(e);
        notification.error({
          message: intl.formatMessage({
            id: "masterdata-accept-error-title",
            defaultMessage: "Error"
          }),
          description:
            intl.formatMessage({
              id: "masterdata-accept-error-message",
              defaultMessage: "Fail to accept master data"
            })
        });
        dispatch(resetSurveyDataLoading(false));
        //isLoading(false);
      }
      )
      .finally(() => {
        dispatch(setSurveyDataLoading(false));
      });
  };


  const onReject = async () => {
    dispatch(setSurveyDataLoading(true));
    //isLoading(true);
    const surveyIds = await getSelectedSurvey(MASTER_DATA_STATUS.QUERIED);
    client.mutate({
      mutation: GET_SURVEY_PARTNER_ACCEPT_MASTER_DATA,
      variables: { surveyIds },
      fetchPolicy: "network-only"
    }).then((response) => {
      notification.success({
        message: intl.formatMessage({
          id: "masterdata-accept-successfully-title",
          defaultMessage: "Successfully"
        }),
        description: response.data.Portal_AcceptMasterData
      });
      //isLoading(false);
    }).catch((err) => {
      console.error(err);
      notification.error({
        message: intl.formatMessage({
          id: "masterdata-query-error-title",
          defaultMessage: "Error"
        }),
        description:
          intl.formatMessage({
            id: "masterdata-query-error-message",
            defaultMessage: "Fail to query master data"
          })
      });
      dispatch(resetSurveyDataLoading(false));
      //isLoading(false);
    })
    .finally(() => {
      dispatch(setSurveyDataLoading(false));
    });
  };


  const surveySummaryMasterData = [];
  props.surveyMasterData.forEach(item => {
    surveySummaryMasterData.push({
      partnerNo: item.surveySummaryRecord.partnerNo,
      partnerName: item.surveySummaryRecord.partnerName,
      companyCode: item.surveySummaryRecord.companyCode,
      insertTime: item.surveySummaryRecord.insertTime,
      categoryDescription: intl.formatMessage({ id: item.surveySummaryRecord.categoryPhraseId }),
      surveyId: item.surveySummaryRecord.surveyId,
      includedSurveyIds: item.surveySummaryRecord.includedSurveyIds,
      masterDataAcceptedDate: item.surveySummaryRecord.masterDataAcceptedDate
    });
  });


  const showVatAddressDetails = (params) => {
    const vatRowData = [];
    const surveyId = params.data.surveyId;
    const masterData: Portal_GetSurveyPartnerMasterData_Portal_GetSurveyPartnerMasterData = props.surveyMasterData.filter(item => item.surveySummaryRecord.surveyId === surveyId)[0];
    setSurveyAddressMasterData(masterData.partnerRecord.partnerAddressesList);
    masterData.partnerRecord.partnerVatCodes.forEach(item => {
      vatRowData.push({ ...item, taxId: masterData.partnerRecord.taxId });
    });
    if (vatRowData.length === 0 && masterData.partnerRecord.taxId) {
      vatRowData.push({
        partnerNo: masterData.surveySummaryRecord?.partnerNo,
        partnerName: masterData.surveySummaryRecord?.partnerName, taxId: masterData.partnerRecord.taxId
      });
    }
    setSurveyVatMasterData(vatRowData);

  };

  return (
    <>
      <Typography><Title level={5}>{headerParagraphTitle}</Title>
        <Paragraph> {headerParagraphLine1} {headerParagraphLine2} {headerParagraphLine3}</Paragraph>
      </Typography>
      {/* <Spin spinning={surveyState.survey.masterDataLoading} size={"large"}> */}

        <Row gutter={24} justify="start">
          <Col span={24}>
            <span style={{ marginRight: "10px" }} hidden={isReadOnly()}>
              <Tooltip title={queryButton}>
                <CloseOutlined onClick={onReject} style={{ fontSize: "25px", cursor: "pointer" }} />
              </Tooltip>
            </span>
            <span style={{ marginRight: "10px" }} hidden={isReadOnly()}>
              <Tooltip title={acceptButton}>
                <CheckOutlined onClick={onAccept} style={{ fontSize: "25px", cursor: "pointer" }} />
              </Tooltip>
            </span>
            <Space>
              <Link to={"/survey/survey-overview"}>
                <Tooltip
                  title={
                    getI18n(
                      "survey-master-data-partner-tab-back-icon-tool-tip",
                      "Return to Survey Overview Page", intl)
                    
                  }
                  key={"keyCancelInvoiceIcon"}
                >
                  <ArrowLeftOutlined
                    style={{ fontSize: "25px", color: "#444444", cursor: "pointer" }}
                  />
                </Tooltip>
              </Link>
            </Space>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} className="mb5">
            <div style={{ flex: "auto", height: "250px", paddingTop: "0px" }}>
              <AgGridReact
                defaultColDef={{
                  enableValue: true,
                  sortable: true,
                  resizable: true
                }}
                rowData={surveySummaryMasterData}
                columnDefs={SURVEY_MASTER_DATA_PARTNER_TABLE_HEADERS(intl)}
                localeText={getAgGridLocalization(intl)}
                sideBar={false}
                rowClass="agGridRows"
                suppressMenuHide={true}
                debug={false}
                pagination={true}
                rowSelection={"single"}
                onGridReady={onGridReady}
                paginationPageSize={10}
                suppressRowClickSelection={false}
                onRowClicked={showVatAddressDetails}

              />
            </div>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} className="mb5">
            <div style={{ flex: "auto", height: "150px", paddingTop: "0px" }}>
              <Typography.Title level={5}>
                {tabTaxVatTitle}
              </Typography.Title>

              <AgGridReact
                defaultColDef={{
                  enableValue: true,
                  sortable: true,
                  resizable: true
                }}
                rowData={surveyVatMasterData}
                columnDefs={SURVEY_MASTER_DATA_VAT_TABLE_HEADERS(intl, props.currentUser)}
                localeText={getAgGridLocalization(intl)}
                sideBar={false}
                rowClass="agGridRows"
                suppressMenuHide={true}
                debug={false}
                paginationPageSize={5}
                onGridReady={params => params.api.sizeColumnsToFit()}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} className="mb5 mt30 mb25">
            <div style={{ flex: "auto", height: "100px", paddingTop: "0px" }} className="mb25">
              <Typography.Title level={5}>
                {tabAddressTitle}
              </Typography.Title>
              <AgGridReact
                defaultColDef={{
                  enableValue: true,
                  sortable: true,
                  resizable: true
                }}
                rowData={surveyAddressMasterData}
                columnDefs={SURVEY_MASTER_DATA_ADDRESS_TABLE_HEADERS(intl,props.currentUser)}
                localeText={getAgGridLocalization(intl)}
                sideBar={false}
                rowClass="agGridRows"
                suppressMenuHide={true}
                debug={false}
                suppressRowClickSelection={true}
                onGridReady={params => params.api.sizeColumnsToFit()}
              />
            </div>
          </Col>
        </Row>
      {/* </Spin> */}
    </>
  );

};

export default MasterDataPartnerTabDetails;
