import {dropDownOverloadedConstructor, DropDownSelection} from "../info-portal/InfoPortalInterfaces";
import client from "../../config/GraphQLApolloClient";
import {USER_PREFERENCES} from "./queries";
import {PreferencesGetAPI, PreferencesGetAPIVariables} from "./__generated__/PreferencesGetAPI";
import {SetUserPreferences} from "../../../__generated__/globalTypes";
import {SetUserPreferencesAPI, SetUserPreferencesAPIVariables} from "./__generated__/SetUserPreferencesAPI";
import {UPDATE_USER_SETTINGS} from "./mutations";
import {PortalUser} from "../../components";
import dayjs from 'dayjs';
import {roundNumber} from "../../utils/Utils";
import numberFormat from "ag-grid-enterprise/dist/lib/excelExport/files/xml/styles/numberFormat";
import {last} from "lodash";

export function saveUserFormatPreferences(preferences:SetUserPreferences):Promise<any> {
    return client.mutate<SetUserPreferencesAPI, SetUserPreferencesAPIVariables>({
        mutation: UPDATE_USER_SETTINGS,
        variables: {preferences},
        fetchPolicy: 'network-only'
    });
}

export function retrieveDateFormatOptions():Promise<DropDownSelection[]> {
    return client.query<PreferencesGetAPI, PreferencesGetAPIVariables>({
        query: USER_PREFERENCES,
        variables: {preference: 'date-format'},
        fetchPolicy: 'network-only'
    }).then(response=>response.data.userPreferences.map(item=>dropDownOverloadedConstructor(item.code, item.description)))
}


export function retrieveLanguageOptions():Promise<DropDownSelection[]> {
    return Promise.resolve([
            new DropDownSelection('en',null,'english-option'),
            new DropDownSelection('de',null,'german-option'),
        ]
    );
}

export function retrieveNumericFormats():Promise<DropDownSelection[]> {
    return client.query<PreferencesGetAPI, PreferencesGetAPIVariables>({
        query: USER_PREFERENCES,
        variables: {preference: 'number-format'},
        fetchPolicy: 'network-only'
    }).then(response=>response.data.userPreferences.map(item=>dropDownOverloadedConstructor(item.code, item.description)))
}

export function getDateFormatOrDefault(user:PortalUser, defaultFormat:string = "YYYY-MM-dd") {
    return (user?.dateFormat || defaultFormat).toUpperCase();
}


export function getDateFormatWithNoDate(user:PortalUser) {
    const formatOrDefault = getDateFormatOrDefault(user);

    if (formatOrDefault.toLowerCase() === 'mm-dd-yyyy') {
        return 'MM-YYYY';
    }
    else if (formatOrDefault.toLowerCase() === 'dd.mm.yyyy') {
        return 'MM.YYYY';
    }

    return 'YYYY-MM';
}


export function dateComparatorUsingUserPreferences(user:PortalUser) {
    const dateFormat = getDateFormatOrDefault(user);
    return function(a:string, b:string) {
        if (!a) {
            return -1;
        }
        if (!b) {
            return 1;
        }

        if (dateFormat === 'YYYY-MM-dd') {
            return a.localeCompare(b);
        }

        const aDate = dayjs(a, dateFormat);
        const bDate = dayjs(b, dateFormat);

        if (aDate.isSame(bDate)) {
            return 0;
        }

        if (aDate.isAfter(bDate)) {
            return 1;
        }

        return -1;
    }
}
export function convertFromUserSpecifiedDateFormatToExpected(user:PortalUser, dateValue:string, dateFormat:string):string {
    if (dateValue) {
        return dayjs(dateValue, getDateFormatOrDefault(user)).format(dateFormat);
    }

    return null;
}

export function convertFromSpecifiedDateFormatToUserDefined(user:PortalUser, dateValue:string, dateFormat:string):string {
    try {
        if (dateValue && dateFormat && dateValue.length === dateFormat.length) {
            return dayjs(dateValue, dateFormat).format( getDateFormatOrDefault(user));
        }
    }
    catch(e) {
        console.error('Error occurred while performing conversion',e)
    }

    return null;
}

export function numericComparatorUsingUserPreferences(user:PortalUser): (a: string, b: string) => number {
    const decimalSeparator = user?.decimalSeparator || ".";
    let thousandSeparator = ",";
    if (user?.thousandSeparator) {
        if (user.thousandSeparator === "s") {
            thousandSeparator = " ";
        } else {
            thousandSeparator = user.thousandSeparator;
        }
    }

    function convertToNumber(value:string) {
        let result = value.replaceAll(/\s+/g,'');
        result = result.replaceAll(thousandSeparator,'');

        if (decimalSeparator !== '.') {
            result = result.replaceAll(decimalSeparator, '.');
        }

        const resultAsNumber = Number(result);
        return resultAsNumber;
    }

    return function(a: string, b: string) {
        if (!a) {
            return -1;
        }
        if (!b) {
            return 1;
        }

        if (typeof a !== "string" || typeof b !== "string") {
            return 0;
        }

        const aNumber = convertToNumber(a);
        const bNumber = convertToNumber(b);

        if (aNumber === bNumber) {
            return 0;
        }

        if (aNumber > bNumber) {
            return 1;
        }

        return -1;
    }
}

export function convertToUserSpecifiedNumericFormat(user:PortalUser, numbericValue:string|number, precision:number = 2, trimTrailingZeros:boolean = false):string {
    if (numbericValue ==null || numbericValue ===undefined || numbericValue==='') {
        return '';
    }

    const decimalSeparator = user?.decimalSeparator || ".";
    let thousandSeparator =  ",";
    if (user?.thousandSeparator) {
        if (user.thousandSeparator === "s") {
            thousandSeparator = " ";
        }
        else {
            thousandSeparator = user.thousandSeparator;
        }
    }

    function extractDecimals(numberAsString:string) {
        const decimalIndex = numberAsString.indexOf('.');
        if (decimalIndex>-1) {
            return Number(`0.${numberAsString.substring(decimalIndex+1)}`);
        }

        return 0;
    }

    const originalNumber:number = typeof numbericValue === "string" ? Number(numbericValue): numbericValue;
    const positiveOriginalNumber = precision===0? Math.round(Math.abs(originalNumber)):Math.abs(originalNumber);
    let wholeNumberPortion = Math.floor(positiveOriginalNumber);
    const extractedDecimal = roundNumber(extractDecimals(`${positiveOriginalNumber}`),precision);

    const thousandGroup:string[] = [];
    while(wholeNumberPortion>0) {
        const extractLowest3Digits = wholeNumberPortion%1000;
        wholeNumberPortion = Math.floor(wholeNumberPortion/1000);
        if (wholeNumberPortion>0 && extractLowest3Digits<100) {
            thousandGroup.push(`${extractLowest3Digits}`.padStart(3,'0'));
        }
        else {
            thousandGroup.push(`${extractLowest3Digits}`);
        }

    }

    if (thousandGroup.length===0) {
        thousandGroup.push('0');
    }


    const result = `${originalNumber<0?'-':''}${thousandGroup.reverse().join(thousandSeparator)}${precision>0?decimalSeparator:''}${precision>0?extractedDecimal.substring(2):''}`;

    if (trimTrailingZeros) {
        if (precision>0) {
            const index = result.lastIndexOf(decimalSeparator);
            if (index>-1) {
                const decimal = result.substring(index+1);
                const decimalAsNumber = Number(`0.${decimal}`);
                if (decimalAsNumber===0) {
                    return result.substring(0,index);
                }

                const decimalPortion = `${decimalAsNumber}`.substring(2);
                return `${result.substring(0, index+1)}${decimalPortion}`
            }
        }
    }

    return result;
}

export function antDInputNumberFormatter(user:PortalUser, value:any,input:{
    userTyping: boolean;
    input: string;
}, precision:number = 2,trimTrailingZeros:boolean = false) {
    let newPrecision = precision;

    if (input.userTyping) {
        const lastIndex = `${value}`.lastIndexOf('.');
        if (lastIndex>-1) {
            newPrecision =    Math.max(0, (`${value}`.length - lastIndex -1));
        }
        else {
            newPrecision = 0;
        }
    }

    return convertToUserSpecifiedNumericFormat(user, value, newPrecision,trimTrailingZeros)
}

export function antDInputParser(user:PortalUser, numericValue:string): number {
    if (!numericValue) {
        return 0;
    }

    const decimalSeparator = user?.decimalSeparator || ".";
    let thousandSeparator = ",";
    if (user?.thousandSeparator) {
        if (user.thousandSeparator === "s") {
            thousandSeparator = " ";
        } else {
            thousandSeparator = user.thousandSeparator;
        }
    }

    let result = numericValue.replaceAll(/\s+/g,'');
    result = result.replaceAll(thousandSeparator,'');

    if (decimalSeparator !== '.') {
        result = result.replaceAll(decimalSeparator, '.');
    }

    const resultAsNumber = Number(result);
    return resultAsNumber;
}

export class FormatterWrapper {
    previousValue = "";
    isNegativeNumber = false;

    public antDInputNumberFormatter(user:PortalUser, value:any,input:{
        userTyping: boolean;
        input: string;
    }, precision:number = 2,trimTrailingZeros:boolean = false) {
        if (input.userTyping) {
            if (this.isNegativeNumber && this.previousValue === '-') {
                return '-';
            }
        }
        const response = antDInputNumberFormatter(user, value, input, precision, trimTrailingZeros);
        return response;
    }

    public antDInputParser(user:PortalUser, numericValue:string): number {
        if (!numericValue) {
            return undefined;
        }
        this.previousValue = numericValue;
        this.isNegativeNumber = numericValue.startsWith('-');
        const response = antDInputParser(user, numericValue);
        return response;
    }
}


