import client from "../../../config/GraphQLApolloClient";
import { ALL_AGREEMENTS_FOR_SURVEY_TYPE
  , ALL_AGREEMENT_STATUS_CODES, GET_AGREEMENT_APPROVAL_DATA
  , DOWNLOAD_SURVEY_DOCUMENT_QUERY_BY_BLOB_ID } from "./queries";
import { UPDATE_AGREEMENT_DOWNLOADED, UPDATE_AGREEMENT_UPDATE } from "./mutations";
import {getSurveyAgreements} from "./__generated__/getSurveyAgreements";
import { getSurveyAgreementStatusCodes } from "./__generated__/getSurveyAgreementStatusCodes";
import { getSurveyAgreementApprovalData } from "./__generated__/getSurveyAgreementApprovalData";
import { downloadSurveyDocument } from "./__generated__/downloadSurveyDocument";


export const getSurveyAgreementsDocs = (agreementTypeId: number) => {
    return new Promise<getSurveyAgreements>((resolve, reject) => {
        client.query<getSurveyAgreements>({
          query: ALL_AGREEMENTS_FOR_SURVEY_TYPE,
          variables: {
            agreementTypeId
          }
          ,
          fetchPolicy: "cache-first"
        })
          .then((documents) => {
            const response: getSurveyAgreements = { getSurveyAgreementDocList: documents.data.getSurveyAgreementDocList };
            resolve(
              response
            );
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      });
}

export const getSurveyAgreementStatusCodeList = () => {
    return new Promise<getSurveyAgreementStatusCodes>((resolve, reject) => {
        client.query<getSurveyAgreementStatusCodes>({
          query: ALL_AGREEMENT_STATUS_CODES,
          fetchPolicy: "cache-first"
        })
          .then((documents) => {
            const response: getSurveyAgreementStatusCodes = { getSurveyAgreementStatusList: documents.data.getSurveyAgreementStatusList };
            resolve(
              response
            );
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      });
}

export const getSurveyAgreementApprovalInfo = (groupId) => {
  return new Promise<getSurveyAgreementApprovalData>((resolve, reject) => {
      client.query<getSurveyAgreementApprovalData>({
        query: GET_AGREEMENT_APPROVAL_DATA,
        variables: { groupId },
        fetchPolicy: "network-only"
      })
        .then((documents) => {
          const response: getSurveyAgreementApprovalData = { getSurveyAgreementApprovalData: documents.data.getSurveyAgreementApprovalData };
          resolve(
            response
          );
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
}


export const updateAgreementDownloadedOperation = (groupId) => {
  return new Promise((resolve, reject) => {
      client.mutate({
        mutation: UPDATE_AGREEMENT_DOWNLOADED,
        variables: { groupId }
      }).then((_) => {
        // console.info("Agreement download flag , update successful");
        resolve("Agreement download flag , update successful");
      }).catch((err) => {
        console.error(err);
        reject(err);
      });

    });
}


export const updateAgreementInfoOperation = (groupId,agreementApproverName,agreementApproverEmail,agreementStatusId, agreementStatusDate) => {
  return new Promise((resolve, reject) => {
      const info: any = {groupId,
        agreementApproverName,
        agreementApproverEmail,
        agreementStatusDate,
        agreementStatusId: agreementStatusId !== undefined ? parseFloat(agreementStatusId): undefined};
      client.mutate({
        mutation: UPDATE_AGREEMENT_UPDATE,
        variables: { surveyAgreementInput: info }
      }).then((response) => {
        console.error(`Response in service : ${response.data.updateAgreementInfo.success}`);
          if (response.data.updateAgreementInfo.success)
          {
             resolve(response.data.updateAgreementInfo.response);
          }
          else
          {
            console.error("error");
            reject(response.data.updateAgreementInfo.failureDescription);
          }
      }).catch((err) => {
        console.error(err);
        reject(err);
      });

    });
}

export const downloadSurveyDocumentByBlobId = (blobId) => {
  return new Promise<downloadSurveyDocument>((resolve, reject) => {
      client.query<downloadSurveyDocument>({
        query: DOWNLOAD_SURVEY_DOCUMENT_QUERY_BY_BLOB_ID,
        variables: {blobId: blobId !== undefined ? parseFloat(blobId): undefined},
        fetchPolicy: "network-only"
      })
        .then((documents) => {
          const response: downloadSurveyDocument = { downloadSurveyDocument: documents.data.downloadSurveyDocument };
          resolve(
            response
          );
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
}
