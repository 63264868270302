import React, { useState } from "react";

import { Button } from "antd";
import client from "../../config/GraphQLApolloClient";
import { ASSIGN_USER_RIGHTS } from "./queries";
import { showErrorNotification } from "../Notification";

const BtnUnassignedCellRenderer: React.FunctionComponent = (props) => {
  const [loading, isLoading] = useState(false);


  const remove = () => {
    // @ts-ignore
    const assignedPartnersCompanyCode = { ...props.data, assigned: 0 };
    delete assignedPartnersCompanyCode["__typename"];
    // eslint-disable-next-line no-console
    console.log(assignedPartnersCompanyCode);
    // @ts-ignore
    const userId = props.data.userId;
    isLoading(true);
    client.mutate(
      {
        mutation: ASSIGN_USER_RIGHTS,
        variables: {
          userRights: assignedPartnersCompanyCode,
          userId: Number(userId)
        }
      }).then(response => {
      // @ts-ignore
      props.api.setRowData(response.data.mutateAssignedUserRights);
      isLoading(false);
    }).catch(e => {
      showErrorNotification(e.errors);
      isLoading(false);
    });

  };

  return (
    <>
            <span>
                <span style={{ marginRight: "10px" }}>
                    <Button type="default" htmlType="button" size={"small"} loading={loading}
                            onClick={remove}>
                        Remove
                    </Button>
                </span>

            </span>

    </>
  );
};

export default BtnUnassignedCellRenderer;
