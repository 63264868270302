export class StateContainer {
    private state: {[key:string]: any}

    constructor() {
        this.state = {};
    }

    public updateKey = (key:string, value: any) => {
        this.state[key] = value;
    };

    public getValue = (key:string) => {
        return this.state[key];
    };
}