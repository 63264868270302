import React, { useEffect, useState } from 'react';
import {
    DropDownSelectionMode,
    ExternalStateUpdateCallback,
    InfoPortalAgGridColumnDef,
    InfoPortalColumnDef,
    InfoPortalGridService,
    InfoPortalReport,
    InfoPortalSearchProperties,
    IValidator,
    LabelLocation,
    OnChangeListener,
    ReportViewConfig,
    stringToDayjs_yyyy_MM_dd,
    utcDateToFormatTo_yyyy_MM_dd_string,
    Validator,
} from '../../../InfoPortalInterfaces';
import { Card, FormInstance, Typography } from 'antd';
import { RuleSet } from '../../../../e-cap/util/RuleResultCache';
import { IntlShape } from 'react-intl/src/types';
import { retrieveLY4Report } from '../../../services/ReportSearch';
import styles from '../../styling/infoportal.module.css';
import {
    CompanySelection,
    descriptionFilter,
    OverdueStatusSelection,
    PartnerSelection,
    StatusSelection,
    TypeSelection,
} from '../../InfoPortalDropdowns';
import { PurchaseOrderNumber } from '../../InputWithOperator';
import { ChangedOnFromToSelection } from '../../FromToDateSelection';
import { PortalUser } from '../../../../../components';
import {
    convertToUserSpecifiedNumericFormat,
    dateComparatorUsingUserPreferences,
    getDateFormatOrDefault, numericComparatorUsingUserPreferences
} from "../../../../users/UserFormatPreferenceServices";
import {
    filterParamAmountFormattedAsStringUsingPreferences,
    filterParamWithUserDateFormatComparator
} from "../../grid/GridUtil";

function validatator(form: FormInstance<any>): Validator {
    const validator: IValidator = {
        fetchRule(fieldName: string): RuleSet {
            const result = {
                required: false,
                picture: '',
                readOnly: false,
                hidden: false,
                formatMessage: null,
                infoMessage: null,
            };
            if (fieldName === 'company') {
                result.required = true;
            }
            if (fieldName === 'partner') {
                result.required = true;
            }
            if (fieldName === 'status') {
                result.required = true;
            }
            return result;
        },
    };
    return new Validator(validator);
}

function RevaluationsSimpleSearch({currentUser, intl, distinctEntitlements, reportEntitlement,initialization, form, initialValuesCache,rerenderDependentFields}: InfoPortalSearchProperties) {

    const [vertical, setVertical] = useState<boolean>(false);
    const [showOverdueStatus,setShowOverdueStatus] = useState<boolean>(true);
    const validator = validatator(form);
    const partnerSelectionUpdate = new ExternalStateUpdateCallback<{ companyIds: string[], newVal: string[] }>();

    const { Text } = Typography;

    const companySelectionChange: OnChangeListener<string[]> = {
        performAction(companyIds: string[]) {
            const newVal = form.getFieldValue("partner") || [];
            partnerSelectionUpdate.invokeCallBack({companyIds, newVal});
        }
    };

    const statusSelectionChange: OnChangeListener<string[]> = {
        performAction(val: string[]) {
            const result = val[0] !== "C";
            setShowOverdueStatus(result);
        }
    };

    useEffect(()=> {
        const result = (form.getFieldValue("status") || initialValuesCache?.getValue("status"))!== "C";
        setShowOverdueStatus(result);

    },[]);

    useEffect(() => {
        if (rerenderDependentFields) {
            const companyIds = form.getFieldValue("company");
            const newVal = form.getFieldValue("partner");
            partnerSelectionUpdate.invokeCallBack({companyIds, newVal});
        }
    });

    return <Card size={"small"} style={{backgroundColor: "#f1f3f5"}}>
        <div className={styles.selfBilledSimpleSearchContainer} style={{marginLeft: "10px"}}>
            <div className={styles.singleColumnGrid}>
                <CompanySelection
                    selectionMode={DropDownSelectionMode.MULTIPLE}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    currentUser={currentUser}
                    initialValue={initialValuesCache?.getValue("company")}
                    intl={intl}
                    validator={validator}
                    filterOptions={descriptionFilter}
                    distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form}
                    onSelectionChange={companySelectionChange}
                    companyEntitlement={reportEntitlement}/>
                <PartnerSelection
                    selectionMode={DropDownSelectionMode.MULTIPLE}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    currentUser={currentUser} intl={intl}
                    validator={validator}
                    initialValue={initialValuesCache?.getValue("partner")}
                    distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form}
                    filterOptions={descriptionFilter}
                    companyIds={initialValuesCache?.getValue("company")}
                    externalStateUpdater={partnerSelectionUpdate}
                    partnerEntitlement={reportEntitlement}/>
                <PurchaseOrderNumber
                    currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
                    initialInputValue={initialValuesCache?.getValue("purchaseOrderNumber")}
                    initialSelectedOperator={initialValuesCache?.getValueOrDefault("purchaseOrderNumberOperator", "CP")}
                    initialization={initialization} form={form} />

                <ChangedOnFromToSelection
                    validator={validator}
                    dateFormatter={getDateFormatOrDefault(currentUser)}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    initialValue={initialValuesCache?.getValue("changedOnDate", stringToDayjs_yyyy_MM_dd)}
                    currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form} />
            </div>

            <div className={styles.singleColumnGrid}>

                <StatusSelection
                    initialValue={initialValuesCache?.getValue("status")}
                    displayColon={true}
                    selectionMode={DropDownSelectionMode.SINGLE}
                    currentUser={currentUser}
                    intl={intl}
                    onSelectionChange={statusSelectionChange}
                    validator={validator}
                    reportName={"ly4"}
                    distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form} />

                <OverdueStatusSelection
                    initialValue={initialValuesCache?.getValue("overdueStatus")}
                    displayColon={true}
                    disabled={!showOverdueStatus}
                    customDescription={(item)=>`${item.id} - ${item.description}`}
                    selectionMode={DropDownSelectionMode.SINGLE}
                    currentUser={currentUser}
                    intl={intl}
                    validator={validator}
                    reportName={"ly4"}
                    distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form} />

                <TypeSelection
                    initialValue={initialValuesCache?.getValue("type")}
                    displayColon={true}
                    selectionMode={DropDownSelectionMode.SINGLE}
                    currentUser={currentUser}
                    intl={intl}
                    validator={validator}
                    reportName={"ly4"}
                    distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form} />

            </div>
        </div>

        <div style={{marginLeft: "10px"}}>

            <div style={{marginTop:"30px"}}>
                <ul>
                    <li><Text>{intl.formatMessage({id:'ly4-extra-info-1'})}</Text></li>
                    <li><Text>{intl.formatMessage({id:'ly4-extra-info-2'})}</Text></li>
                    <li><Text>{intl.formatMessage({id:'ly4-extra-info-3'})}</Text></li>
                </ul>
            </div>
            <div>&nbsp;</div>
        </div>

    </Card>;
}

export class LY4AgGridColumnDef implements InfoPortalAgGridColumnDef {
    getLineItemColumnDefinitions(intl: IntlShape,user:PortalUser,distinctEntitlements:string[]): InfoPortalColumnDef[] {
        return [
            {field: "deliverynote", headerName: intl.formatMessage({"id": "grid-heading-deliveryNoteNumber"})},
            {field: "material", headerName: intl.formatMessage({"id": "grid-heading-materialNumber"})},
            {field: "materialtext", headerName: intl.formatMessage({"id": "grid-heading-materialtext"})},
            {
                field: "oldnet",
                headerName: intl.formatMessage({"id": "grid-heading-netAmount-old"}),
                type: "rightAligned",
                valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.oldnet),
                filter: "agTextColumnFilter",
                filterParams:filterParamAmountFormattedAsStringUsingPreferences(user, intl),
                comparator:numericComparatorUsingUserPreferences(user)
            },
            {
                field: "newnet",
                headerName: intl.formatMessage({"id": "grid-heading-netAmount-new"}),
                type: "rightAligned",
                valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.newnet),
                filter: "agTextColumnFilter",
                filterParams:filterParamAmountFormattedAsStringUsingPreferences(user, intl),
                comparator:numericComparatorUsingUserPreferences(user)
            },
            {
                field: "diffnet",
                headerName: `${intl.formatMessage({"id": "grid-heading-difference-in-net"})} *`,
                type: "rightAligned",
                valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.diffnet),
                filter: "agTextColumnFilter",
                filterParams:filterParamAmountFormattedAsStringUsingPreferences(user, intl),
                comparator:numericComparatorUsingUserPreferences(user)
            },
        ];
    }

    getDefaultColumnDefinitions(intl: IntlShape,user:PortalUser,distinctEntitlements:string[]): InfoPortalColumnDef[] {
        return [
            {field: "compcode", headerName: intl.formatMessage({"id": "grid-heading-company"})},
            {field: "partnerno", headerName: intl.formatMessage({"id": "grid-heading-partnerNumber"})},
            { field: "status", headerName: intl.formatMessage({ "id": "grid-heading-status" }) },
            { field: "revaltype", headerName: intl.formatMessage({ "id": "edoc-notification-header-type" }) },
            {field: "purchaseordernumber", headerName: `${intl.formatMessage({"id": "grid-heading-purchaseOrderNumber"})} *`},
            {field: "overduestatus", headerName: intl.formatMessage({"id": "overdue-status"})},
            {field: "poposition", headerName: intl.formatMessage({"id": "grid-heading-purchaseOrderItem"})},
            {field: "revalnumber", headerName: `${intl.formatMessage({ "id": "grid-heading-revaluation-number"})} *`},
            {
                field: "total",
                headerName: `${intl.formatMessage({"id": "grid-heading-total-revaluation-amount"})} *`,
                type: "rightAligned",
                valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.total),
                filter: "agTextColumnFilter",
                filterParams:filterParamAmountFormattedAsStringUsingPreferences(user, intl),
                comparator:numericComparatorUsingUserPreferences(user)
            },
            {field: "currency", headerName: `${intl.formatMessage({"id": "grid-heading-currency"})} *`},
            {field: "extinvoice", headerName: `${intl.formatMessage({"id": "grid-heading-partnerInvoiceNumber"})}`},
            {field: "bmwdocnumber", headerName: intl.formatMessage({"id": "grid-heading-bmwInvoiceNumber"})},
            {
                field: "documentdate",
                headerName: intl.formatMessage({"id": "grid-heading-documentDate"}),
                valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.documentdate,getDateFormatOrDefault(user)),
                filterParams:filterParamWithUserDateFormatComparator(user),
                filter:'agDateColumnFilter',
                comparator: dateComparatorUsingUserPreferences(user)
            },
            {
                field: "changedon",
                headerName: intl.formatMessage({"id": "grid-heading-changed-on"}),
                valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.changedon,getDateFormatOrDefault(user)),
                filterParams:filterParamWithUserDateFormatComparator(user),
                filter:'agDateColumnFilter',
                comparator: dateComparatorUsingUserPreferences(user)
            },
        ]
    }

    getShortViewColumns(): string[] {
        return [
            'compcode',
            'partnerno',
            'status',
            'revaltype',
            'purchaseordernumber',
            'overduestatus',
            "poposition",
            'revalnumber',
            'total',
            'currency',
        ]
    }
}

export class LY4Report implements InfoPortalReport {
    private _config = new LY4AgGridColumnDef();

    getColumnsDefinition(): InfoPortalAgGridColumnDef {
        return this._config;
    }

    performSearch(form:FormInstance,currentUser:any, distinctEntitlements:string[]):Promise<InfoPortalGridService> {
        return retrieveLY4Report(form).then(result=>
            new InfoPortalGridService(
                new ReportViewConfig('LY4',this._config), result, distinctEntitlements));
    }

    hasExpertSearch(): boolean {
        return false;
    }

    renderExpertSearch({
                           currentUser,
                           intl,
                           distinctEntitlements,
                           initialization,
                           form,
                           initialValuesCache
                       }: InfoPortalSearchProperties): JSX.Element {
        return null;
    }

    renderSimpleSearch({
                           currentUser,
                           intl,
                           distinctEntitlements,
                           initialization,
                           form,
                           initialValuesCache,
                           rerenderDependentFields
                       }: InfoPortalSearchProperties): JSX.Element {
        return <RevaluationsSimpleSearch initialization={initialization}
                                     currentUser={currentUser}
                                     intl={intl}
                                     form={form}
                                     distinctEntitlements={distinctEntitlements}
                                     rerenderDependentFields={rerenderDependentFields}
                                     initialValuesCache={initialValuesCache} reportEntitlement={this.getEntitlement()}/>;
    }

    getEntitlement(): string {
        return "display_ly@reports";
    }

    getReportName(): string {
        return "LY4";
    }
}