export const AUTH_TOKEN = "access_token";
export const USER_NAME = "username";
export const LINKS_PER_PAGE = 5;

export const SELECTED_SURVEYS = "selected-surveys";
export const SELECTED_GROUP_ID = "selected-group";
export const PARTNER_TYPE = "partner-type";
export const SELECTED_PARTNER_NO = "selected_partner_no";
export const SELECTED_SURVEY_ID = "selected_survey_id";
export const SELECTED_COMPANY_CODE = "selected_company_code";
export const DOCUMENT_TYPE = "DOCUMENT_TYPE";
