import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep, isArray } from 'lodash';
import { getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners } from '../../main/__generated__/getSelfEntitlementResultByFilter';
import {
    getFilteredRulesWithRuleType,
    getFilteredRulesWithRuleType_getFilteredRulesWithRuleType,
} from './__generated__/getFilteredRulesWithRuleType';
import { enableMapSet } from 'immer';

interface RulesState {
    rules: getFilteredRulesWithRuleType;
    rulesIndex: [];
    ruleMap: any;
}

const initialState: RulesState = {
    rules: {
        getFilteredRulesWithRuleType: [],
    },
    rulesIndex: [],
    ruleMap: new Map(),
};

function indexXpath(state, xpathVariable: String) {
    let found = false;
    state.rulesIndex.find((item) => {
        if (item === xpathVariable) {
            found = true;
        }
    });
    if (!found) {
        state.rulesIndex.push(xpathVariable);
    }
}

const ruleSlice = createSlice({
    name: 'rules',
    initialState,
    reducers: {
        setRules(state, action: PayloadAction<getFilteredRulesWithRuleType>) {
            const rules = action.payload;
            //I understand that this will throw an error but the data is not persisted so its not an issue
            enableMapSet();
            console.log(`Set Rules state, number of rules loaded : ${rules.getFilteredRulesWithRuleType.length}`);
            //set state for all rules so process still works until RuleEngine can be changed
            state.rules = rules;
            let xpath = undefined;
            let ruleGroup: getFilteredRulesWithRuleType_getFilteredRulesWithRuleType[] = [];
            console.dir(rules.getFilteredRulesWithRuleType);
            const rulesCopy: getFilteredRulesWithRuleType_getFilteredRulesWithRuleType[] = cloneDeep(rules.getFilteredRulesWithRuleType);
            // Sort list in ascending order
            rulesCopy.sort((x, y) => {
                return JSON.parse(x.definition).xpathVariable === JSON.parse(y.definition).xpathVariable 
                    ? 0 || x.priority - y.priority
                    : JSON.parse(x.definition).xpathVariable > JSON.parse(y.definition).xpathVariable
                    ? 1 || x.priority - y.priority
                    : -1;
            });
            rulesCopy.find((item) => {
                const definition = JSON.parse(item.definition);
                indexXpath(state, definition.xpathVariable);
                //const rules: getFilteredRulesWithRuleType_getFilteredRulesWithRuleType[] = map.get(definition.xpathVariable);
                if (xpath === undefined) {
                    ruleGroup.push(item);
                    xpath = definition.xpathVariable;
                } else if (xpath === definition.xpathVariable) {
                    ruleGroup.push(item);
                } else {
                    //save rules group for previous xpath
                    state.ruleMap.set(xpath, ruleGroup);
                    ruleGroup = [];
                    ruleGroup.push(item);
                    xpath = definition.xpathVariable;
                }
            });
            console.dir(state.ruleMap);
            console.dir('Test retrieve of VAT rate');
            console.dir(state.ruleMap.get('VAT_RATE'));
            console.dir('Test retrieve of Address_US_OR_Canada');
            console.dir(state.ruleMap.get('Address_US_OR_Canada'));
            console.log(`Rules Index size : ${state.rulesIndex.length}`);
        },
    },
});

export const { setRules } = ruleSlice.actions;
export default ruleSlice.reducer;
