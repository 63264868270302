import React, { FunctionComponent, useEffect, useState } from "react";
// eslint-disable-next-line import/named
import {useLocation, useNavigate} from "react-router-dom";
import { Button, Card, Col, Form, message, Row, Spin, Typography } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { FormInstance } from "antd/lib/form/hooks/useForm";
import { useIntl } from "react-intl";
import { getSurveyVerifyData, updateSurveySubmitData } from "./SurveyVerifyAndSubmitService";
import queryString from "query-string";
import VerifySubmitRow from "./VerifySubmitRow-component";
import { getI18n } from "../../../utils/Utils";
import { useAppDispatch, useAppSelector } from "../../../main/hooks";
import { MODE } from "../IStoredSurvey";
import { resetSurveyDataLoading, setSurveyDataLoading } from "../IStoredSurveySlice";


type ISurveyVerifySubmitData = {
  form: FormInstance;
  groupId: string;
  onStatusUpdate: any;
};


const SurveyVerifySubmit: FunctionComponent<ISurveyVerifySubmitData> = ({
                                                                          form,
                                                                          groupId,
                                                                          onStatusUpdate                                                                    
                                                                        }) => {

  const intl = useIntl();
  const navigate = useNavigate();
  const search = useLocation().hash;
  const urlSearchParams = queryString.parse(search);
  const action = urlSearchParams.action;
  const dispatch = useAppDispatch();

  const [viewMode, setViewMode] = useState<boolean>(false);
  // const [loading, isLoading] = useState(false);
  const [success, setSuccess] = useState<boolean>(false);

  const [masterDataStatus, setMasterDataStatus] = useState<string>("In Progress");
  const [masterDataErrors, setMasterDataErrors] = useState<string>(undefined);
  const [masterDataDone, setMasterDataDone] = useState<boolean>(false);

  const [eDocumentTypesStatus, setEDocumentTypesStatus] = useState<string>(undefined);
  const [eDocumentTypesErrors, setEDocumentTypesErrors] = useState<string>(undefined);
  const [eDocumentTypesDone, setEDocumentTypesDone] = useState<boolean>(false);

  const [connectivityStatus, setConnectivityStatus] = useState<string>(undefined);
  const [connectivityErrors, setConnectivityErrors] = useState<string>(undefined);
  const [connectivityDone, setConnectivityDone] = useState<boolean>(false);

  const [eInvAgreementStatus, setEInvAgreementStatus] = useState<string>(undefined);
  const [eInvAgreementErrors, setEInvAgreementErrors] = useState<string>(undefined);
  const [eInvAgreementDone, setEInvAgreementDone] = useState<boolean>(false);

  const [submitHiddenFlag, setSubmitHiddenFlag] = useState<boolean>(true);

  const surveyState = useAppSelector((state) => state.survey);

  const isViewMode = () => {
    return surveyState.survey.surveyMode === MODE.VIEW ? true : false
  }

  const isOverViewMode = () => {
    return surveyState.survey.surveyMode === MODE.OVERVIEW ? true : false
  }

const isReadOnly = () => {
  return isViewMode() || isOverViewMode();
 }


  const successMessage = intl.formatMessage({
    id: "verifySubmit-successMessage",
    defaultMessage: "Based on the above, your Survey is ready for SUBMISSION. Click on the SUBMIT button to finalise Survey Completion"
  });

  const successMessageSubmitted = intl.formatMessage({
    id: "verifySubmit-successMessageSubmitted",
    defaultMessage: "Completed Survey Information has been successfully submitted"
  });

  const incompleteMessage = intl.formatMessage({
    id: "verifySubmit-incompleteMessage",
    defaultMessage: "Based on the verification executed, your Survey is not completed and requires your attention to address and complete the areas with the noted issues"
  });

  const [successLabelMassage, setSuccessLabelMassage] = useState<string>(successMessage);

  useEffect(() => {
    if (action === undefined || action === "edit") {
      setViewMode(false);
    } else {
      setViewMode(true);
    }
  });


  const onFinish = (values: any) => {
    // isLoading(true);
    dispatch(setSurveyDataLoading(true));
    Promise.resolve(updateSurveySubmitData(groupId))
      .then(() => {
        message.success(intl.formatMessage({
          id: "verifySubmit-surveySubmitSuccess",
          defaultMessage: "Survey submitted successfully"
        }));
        setViewMode(true);
        setSubmitHiddenFlag(true);
        setSuccessLabelMassage(successMessageSubmitted);
        navigate("/survey/survey-overview");
      })
      .catch((err) => {
        // isLoading(false);
        dispatch(resetSurveyDataLoading(true));
        console.error(err);
        message.success(intl.formatMessage({
          id: "verifySubmit-surveySubmitFailure",
          defaultMessage: "Survey submit failed"
        }));
      })
      .finally(() => {
        dispatch(setSurveyDataLoading(false));
      });

  };

  const handleVerifyCompletion = () => {
    //call backend to check status of all stages
    // isLoading(true);
    dispatch(setSurveyDataLoading(true));
    Promise.resolve(getSurveyVerifyData(groupId))
      .then((data) => {
        message.success(intl.formatMessage({
          id: "verifySubmit-Success",
          defaultMessage: "Verification done successfully"
        }));
        setSubmitHiddenFlag(!data.getSurveyVerifyReport.canSubmit || isReadOnly());
        setSuccess(data.getSurveyVerifyReport.canSubmit);

        data.getSurveyVerifyReport.portions.forEach((portion) => {
          const errors = portion.issues.reduce((result, item) => {
            if (result === "") {
              return getI18n(item, "Issue not decoded", intl);
            }
            return `${result}\n${getI18n(item, "Issue not decoded", intl)}`;
          }, "");
          const status = getI18n(portion.status, "Status not decoded", intl);
          if (portion.portionSequence === 1) {
            setMasterDataStatus(portion.status);
            setMasterDataErrors(errors);

            form.setFieldsValue({ masterDataStatus: status });
            form.setFieldsValue({ masterDataErrors: errors });
            // statusId = 3 = COMPLETED
            if (portion.statusId === 3) {
              setMasterDataDone(true);
            } else {
              setMasterDataDone(false);
            }
          } else if (portion.portionSequence === 2) {
            setEDocumentTypesStatus(portion.status);
            setEDocumentTypesErrors(errors);
            form.setFieldsValue({ eDocumentTypesStatus: status });
            form.setFieldsValue({ eDocumentTypesErrors: errors });
            // statusId = 3 = COMPLETED
            if (portion.statusId === 3) {
              setEDocumentTypesDone(true);
            } else {
              setEDocumentTypesDone(false);
            }
          } else if (portion.portionSequence === 3) {
            setConnectivityStatus(portion.status);
            setConnectivityErrors(errors);
            form.setFieldsValue({ connectivityStatus: status });
            form.setFieldsValue({ connectivityErrors: errors });
            // statusId = 3 = COMPLETED
            if (portion.statusId === 3) {
              setConnectivityDone(true);
            } else {
              setConnectivityDone(false);
            }
          } else if (portion.portionSequence === 4) {
            setEInvAgreementStatus(portion.status);
            setEInvAgreementErrors(errors);
            form.setFieldsValue({ eInvAgreementStatus: status });
            form.setFieldsValue({ eInvAgreementErrors: errors });
            // statusId = 3 = COMPLETED
            if (portion.statusId === 3) {
              setEInvAgreementDone(true);
            } else {
              setEInvAgreementDone(false);
            }
          }
        });
        // isLoading(false);
        //Do not update status bar as nothing could have changed it
        // onStatusUpdate();
      })
      .catch((err) => {
        // isLoading(false);
        dispatch(resetSurveyDataLoading(true));
        console.error(`Failure on component: ${err}`);
        message.error(intl.formatMessage({
          id: "verifySubmit-Failure",
          defaultMessage: "Verification failed"
        }));
      })
      .finally(() => {
        dispatch(setSurveyDataLoading(false));
      });
  };

  return (

    <Card>
      {/* <Spin spinning={loading} size={"large"}> */}
        <div>
          <Row gutter={8}>
            <Col xs={24} sm={6} md={16}>
              <Typography>
                <Paragraph>
                  <Row>
                    {intl.formatMessage({
                      id: "verifySubmit-headertext-line1",
                      defaultMessage: "Here you can find the processing status for the individual categories. If the categories are in 'completed' status, please click 'Confirm' at the end of the survey"
                    })}
                  </Row>
                  <Row>
                    {intl.formatMessage({
                      id: "verifySubmit-headertext-line2",
                      defaultMessage: "Afterwards, the eInvoicing will be set up for you."
                    })}
                  </Row>
                </Paragraph>
              </Typography>
            </Col>
          </Row>
        </div>
        <div>
          <Form
            labelAlign={"left"}
            labelWrap={true}
            form={form}
            size={"middle"}
            layout={"horizontal"}
            onFinish={onFinish}
            labelCol={{ span: 10 }} wrapperCol={{ span: 24 }}
          >
            <VerifySubmitRow inputColLabel={intl.formatMessage({
              id: "survey-main-wizard-masterdata-title",
            })}
                             inputColName="masterDataStatus"
                             inputColValue={masterDataStatus}
                             textAreaName="masterDataErrors"
                             textAreaValue={masterDataErrors}
                             completeFlag={masterDataDone}
            />
            <VerifySubmitRow inputColLabel={intl.formatMessage({
              id: "survey-main-wizard-edoc-types-title",
            })}
                             inputColName="eDocumentTypesStatus"
                             inputColValue={eDocumentTypesStatus}
                             textAreaName="eDocumentTypesErrors"
                             textAreaValue={eDocumentTypesErrors}
                             completeFlag={eDocumentTypesDone}
            />
            <VerifySubmitRow inputColLabel={intl.formatMessage({
              id: "survey-main-wizard-conn-info-title",
              defaultMessage: "Connectivity Info"
            })}
                             inputColName="connectivityStatus"
                             inputColValue={connectivityStatus}
                             textAreaName="connectivityErrors"
                             textAreaValue={connectivityErrors}
                             completeFlag={connectivityDone}

            />
            <VerifySubmitRow inputColLabel={intl.formatMessage({
              id: "verifySubmit-eInvAgreement-col",
              defaultMessage: "eInvoice Agreement"
            })}
                             inputColName="eInvAgreementStatus"
                             inputColValue={eInvAgreementStatus}
                             textAreaName="eInvAgreementErrors"
                             textAreaValue={eInvAgreementErrors}
                             completeFlag={eInvAgreementDone}

            />

            <div>
              <Form.Item
              >
                <Row gutter={12}>
                  <Col span={10} offset={1}>
                    <Button
                      type="primary"
                      size="middle"
                      className="w-100 mt10"
                      hidden={viewMode || success}
                      onClick={handleVerifyCompletion}
                    >
                      {intl.formatMessage({
                        id: "verifySubmit-completion-button",
                        defaultMessage: "Verify Survey Completion"
                      })}
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item>
                <Row gutter={12}>
                  <Col span={6} offset={1}>
                    <Button
                      type="primary"
                      size="middle"
                      className="w-100 mt10"
                      htmlType={"submit"}
                      hidden={submitHiddenFlag || viewMode}
                    >
                      {intl.formatMessage({
                        id: "verifySubmit-submit-button",
                        defaultMessage: "Submit"
                      })}
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </div>
          </Form>
          <div>
            <Row gutter={8}>
              <Col xs={24} sm={6} md={16}>
                <Typography>
                  <Paragraph>
                    {success ? successLabelMassage : incompleteMessage}
                  </Paragraph>
                </Typography>
              </Col>
            </Row>
          </div>
        </div>
      {/* </Spin> */}
    </Card>
  );
};

export default SurveyVerifySubmit;
