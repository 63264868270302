//import React, { useState } from 'react';
import React, { FunctionComponent, useEffect, useState, } from "react";
import { PageContainer, PageContainerProps } from "@ant-design/pro-layout";
import { Alert, Col, ConfigProvider, Form, Row, Spin, Tabs, Collapse,  } from "antd";
import "./Welcome.css";
import { BellOutlined, CaretRightOutlined, OrderedListOutlined } from "@ant-design/icons";
import { IAppComponentProps } from "../components";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import Title from "antd/lib/skeleton/Title";
import { Color } from "ag-grid-community";
import RenderIfEntitled from "../components/EntitlementComponents";

const EcapWelcome: FunctionComponent<IAppComponentProps> = (props) => {
  const currentUser = props.currentUser;
  const [loading, isLoading] = useState(false);
  const { Panel } = Collapse;
  const intl = useIntl();
  const redirect = (route: string) => {
    // @ts-ignore
    props.history?.replace({ pathname: route, state: { currentUser } });
  };

  return (
    <>
      <PageContainer
        title={intl.formatMessage({
          id: "Welcome-title-ecap",
          defaultMessage: "Welcome"
        })} content={""} extraContent={""}>
        <Spin spinning={loading} size={"large"}>
          <div style={{ flex: "auto", height: "850px", paddingTop: "0px" }} className="ag-theme-balham">
            <Row gutter={16}>
              <Col>
                <Row gutter={16}>
                  <Col className="gutter-row" style={{ marginLeft: "1px" }}>
                    <div className="title-header-card"
                      style={{ textAlign: "center", width: "310px", height: "200px", marginLeft: "1px" }}>
                      <div className="title-header-card-text">
                        {intl.formatMessage({
                          id: "welcome-eCap-title",
                          defaultMessage: "eCap"
                        })}

                      </div>
                      <div className="title-header-card-text">
                        {intl.formatMessage({
                          id: "welcome-eCap-portal-title",
                          defaultMessage: "Portal"
                        })}
                      </div>
                    </div>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <div id="title-header-card-image" style={{
                      textAlign: "center",
                      width: "750px",
                      height: "200px",
                      marginLeft: "-5px"
                    }} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <br /><br />

            <Row gutter={18}>
              <Col span={24}>
                <Row justify="start" gutter={[2, 2]}>
                <RenderIfEntitled entitlementToCheck="create_dp@ecap" distinctEntitlements={props.distinctEntitlements} intl={props.intl}>
                  <Col span={5} style={{
                    boxShadow: "0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)", marginRight: "10px"
                  }}>
                    <br />
                    <Row justify="space-around" align="middle" style={{ height: "70px" }}>
                      <Col>
                        <Link className={"title-header-card-text"} to={"/ecap/document-processing"}
                          style={{ color: "#4a576d" }}>
                          {intl.formatMessage({
                            id: "welcome-Capture-Document-title",
                            defaultMessage: "Capture Document"
                          })}</Link>

                      </Col>
                    </Row>
                  </Col>
                  </RenderIfEntitled>
                    <RenderIfEntitled entitlementToCheck="display_tp@ecap" distinctEntitlements={props.distinctEntitlements} intl={props.intl}>
                      <Col span={5} style={{
                        boxShadow: "0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)", marginRight: "10px"
                      }}>
                        <br />
                        <Row justify="space-around" align="middle" style={{ height: "70px" }}>
                          <Col>
                            <Link className={"title-header-card-text"} to={"/ecap/manage-templates"}
                              style={{ color: "#4a576d" }}>{intl.formatMessage({
                                id: "welcome-Manage-Template-title",
                                defaultMessage: "Manage Templates"
                              })}</Link>

                          </Col>
                        </Row>
                      </Col>
                    </RenderIfEntitled>
                  <RenderIfEntitled entitlementToCheck="upload_dp@ecap" distinctEntitlements={props.distinctEntitlements} intl={props.intl}>
                    <Col span={5} style={{
                      boxShadow: "0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)", marginRight: "10px"
                    }}>
                      <br />
                      <Row justify="space-around" align="middle" style={{ height: "70px" }}>
                        <Col>
                          <Link className={"title-header-card-text"} to={"#"}
                            style={{ color: "#4a576d" }}>
                            {intl.formatMessage({
                              id: "welcome-upload-Document-title",
                              defaultMessage: "Upload Document"
                            })}</Link>

                        </Col>
                      </Row>
                    </Col>
                  </RenderIfEntitled>

                </Row>
                <br />
                <Row justify="start" gutter={2}>
                <RenderIfEntitled entitlementToCheck="grf_dp@ecap" distinctEntitlements={props.distinctEntitlements} intl={props.intl}>
                  <Col span={5} style={{
                    boxShadow: "0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)", marginRight: "10px"
                  }}>
                    <br />
                    <Row justify="space-around" align="middle" style={{ height: "70px" }}>
                      <Col>
                        <Link className={"title-header-card-text"} to={"../e-cap/po-flip"}
                          style={{ color: "#4a576d" }}>
                          {intl.formatMessage({
                            id: "purchase-order-flip",
                            defaultMessage: "Purchase Order Flip"
                          })}</Link>

                      </Col>
                    </Row>
                  </Col>
                  </RenderIfEntitled>

                  <Col span={5} style={{
                    boxShadow: "0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)", marginRight: "10px"
                  }}>
                    <br />
                    <Row justify="space-around" align="middle" style={{ height: "70px" }}>
                      <Col>
                        <Link className={"title-header-card-text"} to={"../e-cap/draft-documents"}
                          style={{ color: "#4a576d" }}>
                          {intl.formatMessage({
                            id: "welcome-List-Draft-Documents-title",
                            defaultMessage: "List Draft Documents"
                          })}</Link>

                      </Col>
                    </Row>
                  </Col>

                </Row>

              </Col>
            </Row>
          </div>
        </Spin>
      </PageContainer>
    </>
  );
};

export default EcapWelcome;