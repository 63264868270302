import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import { Button, Modal, Tooltip } from "antd";
import { useIntl } from "react-intl";

const MissingInvoicesRowOption: React.FunctionComponent<ICellRendererParams> = (props) => {
  const intl = useIntl();

  const getIssues = (params) => {
    const partnerNoIssue = params.data.partnerNoIssue;
    const companyCodeIssue = params.data.companyCodeIssue;
    const companyNameIssue = params.data.companyNameIssue;
    const partnerAddressIssue = params.data.partnerAddressIssue;
    const countryCodeAddressIssue = params.data.countryCodeAddressIssue;
    const partnerNameIssue = params.data.partnerNameIssue;
    const partnerVatCodeIssue = params.data.partnerVatCodeIssue;
    const partnerVatCountryCodeIssue = params.data.partnerVatCountryCodeIssue;
    const companyVatCodeIssue = params.data.companyVatCodeIssue;
    const companyVatCountryCodeIssue = params.data.companyVatCountryCodeIssue;
    const companyPartnerIdIssue = params.data.companyPartnerIdIssue;
    const attachmentPending = params.data.attachmentPending;
    const issues = []
    if (partnerNoIssue)   issues.push(`Partner No issue: ${partnerNoIssue}\n`)
    if (partnerNameIssue)   issues.push(`Partner Name issue: ${partnerNameIssue}\n`)
    if (partnerAddressIssue)        issues.push(`Partner Address Issue: ${partnerAddressIssue}\n`);
    if (partnerVatCodeIssue)        issues.push(`Partner VAT Code Issue: ${partnerVatCodeIssue}\n`);
    if (companyCodeIssue)        issues.push(`Company Code issue: ${companyCodeIssue}\n`);
    if (companyNameIssue)        issues.push(`Company Name Issue: ${companyNameIssue}\n`);
    if (companyVatCodeIssue)        issues.push(`Company VAT code issue: ${companyVatCodeIssue}\n`);
    if (partnerVatCountryCodeIssue)        issues.push(`Partner VAT country code issue: ${partnerVatCountryCodeIssue}\n`);
    if (countryCodeAddressIssue)        issues.push(`Country Code Issue: ${countryCodeAddressIssue}\n`);
    if (companyVatCountryCodeIssue)        issues.push(`Company VAT Country Code Issue: ${companyVatCountryCodeIssue}\n`);
    if (companyPartnerIdIssue)        issues.push(`Company Partner Issue: ${companyPartnerIdIssue}\n`);
    if (attachmentPending)        issues.push(`ECAP Document have t_log_info record with possible issues: ${attachmentPending}\n`);
    if (issues && issues.length > 0)  return `Issues found: ${issues.toString()}`
    return 'No issues found'

  }

  
  return (<div>
    
    <Tooltip title={getIssues(props)}>
      {
         <div> {props.data.documentNumber}</div>
      }
    </Tooltip>

    </div>);
};

export default MissingInvoicesRowOption;

