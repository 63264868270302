import React, { FunctionComponent, useEffect, useState } from "react";
import { Button, Col, Row, Form, Input, Typography, message } from "antd";

import { IAppComponentProps } from "../../../components";
import { SurveyContactInput } from "../../../../__generated__/globalTypes";
import { getSurveyContactByType, updateSurveyContact } from "./SurveyConnectivityService";
import { values } from "lodash";
import { useIntl } from "react-intl";
import Paragraph from "antd/es/typography/Paragraph";
import {StateContainer} from "../utilities/StateContainer";
import { MODE } from "../IStoredSurvey";
import { useAppDispatch, useAppSelector } from "../../../main/hooks";
import { resetSurveyDataLoading, setSurveyDataLoading } from "../IStoredSurveySlice";
import {convertFromSpecifiedDateFormatToUserDefined} from "../../users/UserFormatPreferenceServices";
import { getI18n } from "../../../utils/Utils";


type ISurveyConnectivityInfoContactProps = {
  groupId?: any;
  onStatusUpdate?: any;
  stateContainer?: StateContainer;
} & IAppComponentProps;

const SurveyConnectivityContact: FunctionComponent<ISurveyConnectivityInfoContactProps> = (props) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [form] = Form.useForm();
  // const [loading, setLoading] = useState(false);
  const [contactInfo, setContactInfo] = useState(undefined);
  const [changed, setChanged] = useState(false);
  const { Text } = Typography;

 const surveyState = useAppSelector((state) => state.survey);

  const isViewMode = () => {
    return surveyState.survey.surveyMode === MODE.VIEW ? true : false
  }

  const isOverViewMode = () => {
    return surveyState.survey.surveyMode === MODE.OVERVIEW ? true : false
  }

  const formatContactModificationTimestamp = (timeStamp:string)=> {
      if (timeStamp) {
          return `${convertFromSpecifiedDateFormatToUserDefined(props.currentUser, timeStamp.substring(0,10),'YYYY-MM-DD')} ${timeStamp.substring(11)}`;
      }

      return timeStamp;
  }

  const loadData = () => {
    // if (surveyState.survey.surveyDataLoading > 0) {
    //   return;
    // }
    dispatch(setSurveyDataLoading(true));
    getSurveyContactByType(props.groupId, 1)
      .then((response: any) => {
        if (response.getSurveyContactByType?.length > 0) {
          const contactInfo: any = {};
          contactInfo.contactFirstName = response.getSurveyContactByType[0].contactFirstName;
          contactInfo.contactLastName = response.getSurveyContactByType[0].contactLastName;
          contactInfo.contactNumber = response.getSurveyContactByType[0].contactNumber;
          contactInfo.contactEmailAddres = response.getSurveyContactByType[0].contactEmailAddres;
          contactInfo.updateTime = formatContactModificationTimestamp(response.getSurveyContactByType[0].updateTime);
          setContactInfo(contactInfo);
          form.setFieldsValue(contactInfo);
        }
      })
      .catch(() => {
        dispatch(resetSurveyDataLoading(true));
      })
      .finally(() => {
        dispatch(setSurveyDataLoading(false));
      });
  }

  const onFinish = (values: any) => {
    // // eslint-disable-next-line no-console
    // console.dir(values);
    // // eslint-disable-next-line no-console
    // console.dir(values);
    // setLoading(true);
    dispatch(setSurveyDataLoading(true));
    const contactInfo: SurveyContactInput = values;
    contactInfo.contactTypeId = 1;
    contactInfo.updateTime = null;
    updateSurveyContact(props.groupId, contactInfo).then(response=> {
        const contactInfo: any = {};
        contactInfo.contactFirstName = response.updateSurveyContact.contactFirstName;
        contactInfo.contactLastName = response.updateSurveyContact.contactLastName;
        contactInfo.contactNumber = response.updateSurveyContact.contactNumber;
        contactInfo.contactEmailAddres = response.updateSurveyContact.contactEmailAddres;
        contactInfo.updateTime = formatContactModificationTimestamp(response.updateSurveyContact.updateTime);
        setContactInfo(contactInfo);
        form.setFieldsValue(contactInfo);
        message.success(getI18n("survey-connectivity-save-contact-success-message", "Connectivity Information saved successfully" , intl));
    })
      .catch(() => {
        dispatch(resetSurveyDataLoading(true));
        message.error(getI18n("survey-connectivity-save-contact-failed-message", "Failed to save connectivity information" , intl));
      })
      .finally(() => {
        // loadData();
        //force issues component to refresh
        props.onStatusUpdate();
        dispatch(setSurveyDataLoading(false));
        // // eslint-disable-next-line no-console    
        // console.dir('save complete')
      });
  }

  useEffect(()=> {
    if (contactInfo === undefined)
    {
      loadData();
    }
  },[contactInfo])

  return (
    <>
      <Row>
        <Col>
          <Text type="secondary">GroupId: {props.groupId}</Text>
        </Col>
      </Row>
      <Row gutter={[24, 10]}>
        <Col>
          <Typography>
            <Paragraph>{intl.formatMessage({
              id: "survey-connectivityInfo-technicalContactDetails-description",
              defaultMessage: "Provide Technical Contact Person Details. This is the person who is contacted to support and complete the eInvoice connection connectivity setup."
            })}
            </Paragraph>
          </Typography>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col>
          <Form noValidate={true}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            labelAlign={"left"}
            onFinish={onFinish}
            form={form}
          >
            <Form.Item
              label={intl.formatMessage({ id: "survey-connectivityInfo-technicalContactDetails-contactName", defaultMessage: "Technical Contact Name" })}
              name="contactFirstName"
              rules={[{ required: true, message: intl.formatMessage({ id: "generic-requiredField-message", defaultMessage: "Please Enter Required Data" }) }]}
            >
              <Input className="mr10" type={"text"} readOnly={isViewMode() || isOverViewMode()} />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({ id: "survey-connectivityInfo-technicalContactDetails-contactSurname", defaultMessage: "Technical Contact Surname" })}
              name="contactLastName"
              rules={[{ required: true, message: intl.formatMessage({ id: "generic-requiredField-message", defaultMessage: "Please Enter Required Data" }) }]}
            >
              <Input className="mr10" type={"text"} readOnly={isViewMode() || isOverViewMode()} />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({ id: "survey-connectivityInfo-technicalContactDetails-contactNumber", defaultMessage: "Technical Contact Number" })}
              name="contactNumber"
              rules={[{ required: true, message: intl.formatMessage({ id: "generic-requiredField-message", defaultMessage: "Please Enter Required Data" }) }]}
            >
              <Input className="mr10" type={"tel"} readOnly={isViewMode() || isOverViewMode()} />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({ id: "survey-connectivityInfo-technicalContactDetails-contactEmail", defaultMessage: "Technical Contact Email Address" })}
              name="contactEmailAddres"
              rules={[{ required: true, message: intl.formatMessage({ id: "generic-requiredField-message", defaultMessage: "Please Enter Required Data" }) },
                      {type: 'email',  message: intl.formatMessage({ id: "survey-connectivityInfo-invalid-email", defaultMessage: "Email must include one '@' symbol, a domain, no spaces, and only valid characters." }) }]}
            >
              <Input className="mr10" type={"email"} readOnly={isViewMode() || isOverViewMode()} />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({
                id: "survey-connectivityInfo-technicalContactDetails-date",
                defaultMessage: "Date"
              })}
              name="updateTime"
            >
              <Input className="mr10" type={"text"} readOnly={true} />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }} hidden={isViewMode() || isOverViewMode()}>
              <Row gutter={12}>
                <Col span={12} offset={6}>
                  <Button
                    type="primary"
                    size="middle"
                    className="w-100 mt10" htmlType="submit">
                    {intl.formatMessage({
                      id: "survey-connectivityInfo-technicalContact-save"
                      , defaultMessage: "Save Contact details"
                    })}
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
          <Typography>
            <Paragraph strong={true}>
              {intl.formatMessage({
                id: "survey-connectivityInfo-technicalContactDetails-note"
                , defaultMessage: "NB: To complete the connectivity setup for your respective eInvoice connection, our eInvoice service provider will contact you."
              })}
            </Paragraph>
          </Typography>
        </Col>
      </Row>
    </>
  )
}

export default SurveyConnectivityContact;