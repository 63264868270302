import {ApolloClient, ApolloLink, from, HttpLink, InMemoryCache} from '@apollo/client'
import ApolloLinkTimeout from 'apollo-link-timeout'
import { onError } from "@apollo/client/link/error";
import { clearStorage, generateNonce, getLastDocumentReferrer, getParameterByName, getWeEAMUrl, isValidReferrer } from "../main/OauthUtils";
import GraphQLQueryLogger from "../interceptors/RequestLogger";

const timeoutLink = new ApolloLinkTimeout(300_000);

const URL = `${process.env.REACT_APP_GRAPHQL_PROTOCOL}://${process.env.REACT_APP_GRAPHQL_SERVER}`;
const httpLink = new HttpLink({ uri: URL })
const timeoutHttpLink = timeoutLink.concat(httpLink)

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: localStorage.getItem("access_token") || null,
      username: localStorage.getItem("username") || null,
      userId: localStorage.getItem("userId") || null,
      token: localStorage.getItem("access_token") || null,
      idToken: localStorage.getItem("id_token") || null,
      authentication: `bearer ${localStorage.getItem("id_token")}`
    }
  }))
  new GraphQLQueryLogger(localStorage.getItem("username")).logGraphQL(operation);

  return forward(operation);
})

// Log any GraphQL errors or network error that occurred
export const authorizationErrorLink = onError(({ graphQLErrors, networkError }) => {
  let had400AuthError = false;/*400 - BAD REQUEST - user token not sent to backend api*/
  let had401AuthError = false;/*401 - UNAUTHORIZED - user token broken / not valid - reauth*/
  let had403AuthError = false;/*403 - FORBIDDEN - user token received and validated by web eam, but user not allowed action - do not reauth */
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
        if(message.includes("status code 400") && !had400AuthError){
          had400AuthError = true;
        }
        if(message.includes("status code 401") && !had401AuthError){
          had401AuthError = true;
        }
        if(message.includes("status code 403") && !had403AuthError){
          had403AuthError = true;
        }
      }
    );
  }
  if(had400AuthError){
    console.error("BAD REQUEST - NO TOKEN SUPPLIED");
  }

  //if 401 and have valid referrer and have realm in url new login  send to WEBEAM auth
  console.log(`is valid referrer : ${isValidReferrer(getLastDocumentReferrer())}`)
  console.log(`valid realm : ${getParameterByName("realm")}`)
  if(had401AuthError && isValidReferrer(getLastDocumentReferrer()) ){
    console.log("New Login - send to auth url")
    Promise.resolve(getWeEAMUrl()).then((url) => {
              console.log(`auth url: ${url}`)
              window.location.assign(`${url}&nonce=${generateNonce("xxx")}`)
            });
   }
   else if (had401AuthError && getParameterByName("realm") )  { //sGate is not sending a referrer
    console.log("New Login - send to auth url")
    Promise.resolve(getWeEAMUrl()).then((url) => {
              console.log(`auth url: ${url}`)
              window.location.assign(`${url}&nonce=${generateNonce("xxx")}`)
            });
   }
  else if (had401AuthError)
   {
      console.error("Token expired - Logout");
      if(window.location.pathname !== "/logout"){
        clearStorage();
        sessionStorage.setItem("sessionExpired","true");
        window.location.href = "/logout";
      }
  }
  if(had403AuthError){
    console.error("FORBIDDEN - USER AUTH OK BUT ENDPOINT NOT ALLOWED - NOOP");
  }
});


const client = new ApolloClient({
  cache: new InMemoryCache(),
  link  : from([authMiddleware,authorizationErrorLink,timeoutHttpLink])
});

export default client;