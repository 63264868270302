import { gql } from '@apollo/client';


export const REPROCESS_DOCUMENT_RECORDS = gql`
    mutation reprocessECDocuments($documentIdList: [EcDocumentIds!]!) {
        reprocessECDocuments(documentIdList: $documentIdList)
    }
`;



// export const CREATE_MISSING_COMPANY_PROCESS_CODES = gql`
//     mutation createMissingCompanyProcessCodes($companyProcessingCodeRecordsInput: [CompanyProcessingCodeRecordsInput!]!) {
//         createMissingCompanyProcessCodes(companyProcessingCodeRecordsInput: $companyProcessingCodeRecordsInput)
//     }
// `;
