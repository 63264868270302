import React, { FunctionComponent, useState } from "react";
import { Card, Spin, Tabs } from "antd";
import { IAppComponentProps } from "../../components";
import "./../Welcome.css";
import "./../survey/SurveyOverview.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { useIntl } from "react-intl";
import MasterDataPartnerTabDetails from "./surveyMasterData/MasterDataPartnerTabDetails";
import {StateContainer} from "./utilities/StateContainer";
import { useAppSelector } from "../../main/hooks";


type SurveyMasterDataStepProps = {
  next?: any;
  surveyMasterData?: any;
  surveyContactMasterData?: any;
  onStatusUpdate?: ()=>void;
  stateContainer?: StateContainer;
} & IAppComponentProps;

const SurveyMasterDataStep: FunctionComponent<SurveyMasterDataStepProps> = (props) => {

  const intl = useIntl();
  const { TabPane } = Tabs;
  const surveyState = useAppSelector((state) => state.survey);

  const masterdataTab = intl.formatMessage({
    id: "survey-masterdata-main-tab-title",
    defaultMessage: "Master Data"
  });

  return (
    <>
      {/* <Spin spinning={surveyState.survey.surveyDataLoading > 0} size={"large"}> */}
        <div style={{ minHeight: "200px" }} className="pl15 ag-theme-balham">
          <Tabs>
            <TabPane tab={masterdataTab} key={1}>
              <Card size={"small"} className={"ml-10"}>
                <MasterDataPartnerTabDetails surveyMasterData={props.surveyMasterData}
                                             onStatusUpdate={props.onStatusUpdate} intl={intl} currentUser={props.currentUser} />
              </Card>
            </TabPane>
          </Tabs>
        </div>
      {/* </Spin> */}
    </>
  );

};

export default SurveyMasterDataStep;
