import { FormInstance } from "antd";
import client from "../../../config/GraphQLApolloClient";
import { SEARCH_FOR_SR2_REPORT } from "../graphql/queries";
import {
  dateRangeToDayjsFromDate,
  dateRangeToDayjsToDate,
  dayjsToString,
  multipleSelectionMapper, operatorValue, singleValueWrappedInArray
} from "./ReportSearch";
import {SR2Request} from "../../../../__generated__/globalTypes";

export const retrieveSR2Report = (form: FormInstance<any>, currentUser: any, distinctEntitlements: string[]) => {
  const formFields = form.getFieldsValue();
  console.log('Form values', formFields);

  const language = localStorage.getItem("locale") || "en";

  const sr2Request:SR2Request = {
    compcode: multipleSelectionMapper(formFields["company"]),
    partnerno: multipleSelectionMapper(formFields["partner"]),
    status: singleValueWrappedInArray(formFields["status"]),
    deliverydateFrom: dayjsToString(dateRangeToDayjsFromDate(formFields["deliveryNoteDate"]), "YYYY-MM-DD"),
    deliverydateUntil: dayjsToString(dateRangeToDayjsToDate(formFields["deliveryNoteDate"]), "YYYY-MM-DD"),
    purchaseorderdateFrom: dayjsToString(dateRangeToDayjsFromDate(formFields["purchaseOrderDate"]), "YYYY-MM-DD"),
    purchaseorderdateUntil: dayjsToString(dateRangeToDayjsToDate(formFields["purchaseOrderDate"]), "YYYY-MM-DD"),
    grscdateFrom: dayjsToString(dateRangeToDayjsFromDate(formFields["grScDate"]), "YYYY-MM-DD"),
    grscdateUntil: dayjsToString(dateRangeToDayjsToDate(formFields["grScDate"]), "YYYY-MM-DD"),
    docdateFrom: dayjsToString(dateRangeToDayjsFromDate(formFields["documentDate"]), "YYYY-MM-DD"),
    docdateUntil: dayjsToString(dateRangeToDayjsToDate(formFields["documentDate"]), "YYYY-MM-DD"),
    partnerinvno: operatorValue(formFields["partnerInvoiceNumber"], formFields["partnerInvoiceOperator"]),
    settletype: singleValueWrappedInArray(formFields["settlementType"]),
    deliverynoteno: operatorValue(formFields["deliveryNoteNumber"], formFields["deliveryNoteNumberOperators"]),
    busarea: multipleSelectionMapper(formFields["businessAreaCode"]),
    bmwdocno: operatorValue(formFields["bmwInvoiceNumber"], formFields["bmwInvoiceNumberOperator"]),
    purchaseorderno: operatorValue(formFields["purchaseOrderNumber"], formFields["purchaseOrderNumberOperators"]),
    plantcode: multipleSelectionMapper(formFields["plantInfo"]),
    spaborderno: operatorValue(formFields["spabOrderNumber"], formFields["spabOrderNumberOperator"]),
    materialdocumentno: operatorValue(formFields["grScDocumentNumber"], formFields["grScDocumentNumberOperator"]),

    language
  };

  console.log(sr2Request);

  return client.query({
    query: SEARCH_FOR_SR2_REPORT,
    context: {headers:{actionName:'SR2',partnerField:'sr2Request.partnerno'}},
    variables: {sr2Request, reportName: "SR2"},
    fetchPolicy: "network-only"
  }).then(response => response.data.findSR2ReportResults);
};

