import React, { useEffect, useState } from 'react';
import {
    DropDownSelectionMode,
    ExternalStateUpdateCallback,
    InfoPortalAgGridColumnDef,
    InfoPortalColumnDef,
    InfoPortalGridService,
    InfoPortalReport,
    InfoPortalSearchProperties,
    IValidator,
    LabelLocation,
    OnChangeListener,
    ReportViewConfig,
    stringToDayjs_yyyy_MM_dd,
    utcDateToFormatTo_yyyy_MM_dd_string,
    Validator,
} from '../../../InfoPortalInterfaces';
import { BMWInvoiceNumber, FapiaoNumber, PurchaseOrderNumber, SupplierClaimNumber } from '../../InputWithOperator';
import { Card, FormInstance } from 'antd';
import {
    CompanySelection,
    descriptionFilter,
    InvoiceStatusSelection,
    PartnerSelection,
    PlantInfoSelection,
} from '../../InfoPortalDropdowns';
import styles from '../../styling/infoportal.module.css';
import { DocumentDateFromToSelection, GRSCDateFromToSelection } from '../../FromToDateSelection';
import { RuleSet } from '../../../../e-cap/util/RuleResultCache';
import { IntlShape } from 'react-intl/src/types';
import { retrieveCZ2Report } from '../../../services/ReportSearch';
import { PortalUser } from '../../../../../components';
import {
    convertToUserSpecifiedNumericFormat,
    dateComparatorUsingUserPreferences,
    getDateFormatOrDefault,
    numericComparatorUsingUserPreferences,
} from '../../../../users/UserFormatPreferenceServices';
import {
    filterParamAmountFormattedAsStringUsingPreferences,
    filterParamWithUserDateFormatComparator
} from "../../grid/GridUtil";

function validatator(form: FormInstance<any>): Validator {
    const validator: IValidator = {
        fetchRule(fieldName: string): RuleSet {
            const result = {
                required: false,
                picture: '',
                readOnly: false,
                hidden: false,
                formatMessage: null,
                infoMessage: null,
            };
            if (fieldName === 'company') {
                result.required = true;
            }
            if (fieldName === 'partner') {
                result.required = true;
            }
            if (fieldName === 'invoiceStatus') {
                result.required = true;
            }
            return result;
        },
    };
    return new Validator(validator);
}

function SupplierClaimsSimpleSearch({
                                     currentUser,
                                     intl,
                                     reportEntitlement,
                                     distinctEntitlements,
                                     initialization,
                                     form,
                                     initialValuesCache,
                                     rerenderDependentFields
                                   }: InfoPortalSearchProperties) {
  const [vertical, _] = useState<boolean>(false);
  const [showFapiao,setShowFapia] = useState<boolean>(false);
  const validator = validatator(form);
  const partnerSelectionUpdate = new ExternalStateUpdateCallback<{ companyIds: string[], newVal: string[] }>();

  const companySelectionChange: OnChangeListener<string[]> = {
    performAction(val: string[]) {
      const newVal = form.getFieldValue("partner") || [];
      partnerSelectionUpdate.invokeCallBack({ companyIds: val, newVal });
    }
  };

  const statusSelectionChange: OnChangeListener<string[]> = {
    performAction(val: string[]) {
      // @ts-ignore
      // eslint-disable-next-line eqeqeq
      const result = val[0] != 1;
      setShowFapia(result);
    }
  };

  useEffect(()=> {
    // @ts-ignore
    // eslint-disable-next-line eqeqeq
    const result = initialValuesCache?.getValue("invoiceStatus") != 1;
    setShowFapia(result);
  },[]);

  useEffect(() => {
    if (rerenderDependentFields) {
      const companyIds = form.getFieldValue("company");
      const newVal = form.getFieldValue("partner");
      partnerSelectionUpdate.invokeCallBack({ companyIds, newVal });
    }
  });

  return <Card size={"small"} style={{ backgroundColor: "#f1f3f5" }}>
    <div className={styles.selfBilledSimpleSearchContainer}>
      <div className={styles.singleColumnGrid}>
        <CompanySelection
          selectionMode={DropDownSelectionMode.MULTIPLE}
          currentUser={currentUser}
          initialValue={initialValuesCache?.getValue("company")}
          intl={intl}
          validator={validator}
          distinctEntitlements={distinctEntitlements}
          filterOptions={descriptionFilter}
          initialization={initialization} form={form}
          onSelectionChange={companySelectionChange}  companyEntitlement={reportEntitlement}/>
        <PartnerSelection
          selectionMode={DropDownSelectionMode.MULTIPLE}
          currentUser={currentUser} intl={intl}
          partnerEntitlement={reportEntitlement}
          validator={validator}
          initialValue={initialValuesCache?.getValue("partner")}
          distinctEntitlements={distinctEntitlements}
          initialization={initialization} form={form}
          filterOptions={descriptionFilter}
          companyIds={initialValuesCache?.getValue("company")}
          externalStateUpdater={partnerSelectionUpdate} />
        <PurchaseOrderNumber
            currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
            initialInputValue={initialValuesCache?.getValue("purchaseOrderNumber")}
            initialSelectedOperator={initialValuesCache?.getValueOrDefault("purchaseOrderNumberOperators", "CP")}
            initialization={initialization} form={form} />
        <InvoiceStatusSelection
          currentUser={currentUser}
          intl={intl}
          validator={validator}
          initialValue={initialValuesCache?.getValue("invoiceStatus")}
          distinctEntitlements={distinctEntitlements}
          initialization={initialization}
          customDescription={(item)=>`${item.id} - ${item.description}`}
          customLabel={intl.formatMessage({id:'grid-heading-status'})}
          sortByDescription={true}
          onSelectionChange={statusSelectionChange}
          form={form} reportName={"cz2"} />
        <BMWInvoiceNumber
            validator={validator}
            labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
            displayColon={!vertical}
            initialInputValue={initialValuesCache?.getValue("bmwInvoiceNumber")}
            initialSelectedOperator={initialValuesCache?.getValueOrDefault("bmwInvoiceNumberOperator", "CP")}
            currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
            initialization={initialization} form={form}/>
        <DocumentDateFromToSelection
            validator={validator}
            labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
            dateFormatter={getDateFormatOrDefault(currentUser)}
            displayColon={!vertical}
            initialValue={initialValuesCache?.getValue("documentDate", stringToDayjs_yyyy_MM_dd)}
            currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
            initialization={initialization} form={form}/>
      </div>

      <div className={styles.singleColumnGrid}>
        <SupplierClaimNumber
            validator={validator}
            labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
            displayColon={!vertical}
            initialInputValue={initialValuesCache?.getValue("supplierClaimNumber")}
            initialSelectedOperator={initialValuesCache?.getValueOrDefault("supplierClaimNumberOperator", "CP")}
            currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
            initialization={initialization} form={form}/>

        <GRSCDateFromToSelection
            validator={validator}
            dateFormatter={getDateFormatOrDefault(currentUser)}
            initialValue={initialValuesCache?.getValue("grScDate", stringToDayjs_yyyy_MM_dd)}
            customHeading={ intl.formatMessage({ "id": "grid-heading-grDate" })}
            currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
            initialization={initialization} form={form} />
        <PlantInfoSelection
          selectionMode={DropDownSelectionMode.MULTIPLE}
          initialValue={initialValuesCache?.getValue("plantInfo")}
          filterOptions={descriptionFilter}
          currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
          initialization={initialization} form={form} reportName={"cz2"} />

        <div data-testid="fapio-number-hid-show-div" hidden={!showFapiao}>
          <FapiaoNumber
              initialInputValue={initialValuesCache?.getValue("fapiaoNumber")}
              initialSelectedOperator={initialValuesCache?.getValueOrDefault("fapiaoNumberOperator", "CP")}
              currentUser={currentUser}
              validator={validator}
              labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
              displayColon={!vertical}
              intl={intl} distinctEntitlements={distinctEntitlements}
              initialization={initialization} form={form}/>
        </div>
      </div>
    </div>
  </Card>;
}

export class SupplierClaimsAgGridColumnDef implements InfoPortalAgGridColumnDef {
  getLineItemColumnDefinitions(intl: IntlShape,user:PortalUser,distinctEntitlements:string[]): InfoPortalColumnDef[] {
    return [
      {
        field: "ponumber",
        headerName: intl.formatMessage({ "id": "purchase-order-number"})
      },
      {
        field: "poitem",
        headerName: intl.formatMessage({ "id": "grid-heading-purchaseOrderItem"})
      },
      {
        field: "material",
        headerName: intl.formatMessage({ "id": "grid-heading-materialNumber"})
      },
      {
        field: "materialtext",
        headerName: intl.formatMessage({ "id": "grid-heading-materialtext"})
      },
      {
        field: "materialtextzh",
        headerName: intl.formatMessage({ "id": "grid-heading-materialNumberCN"})
      },
      {
        field: "pounit",
        headerName: intl.formatMessage({ "id": "grid-heading-grunit"})
      },
      {
        field: "pounitprice",
        headerName: intl.formatMessage({ "id": "grid-heading-unit-price"}),
        type: "rightAligned",
        valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.pounitprice),
        filter: "agTextColumnFilter",
        filterParams:filterParamAmountFormattedAsStringUsingPreferences(user, intl),
        comparator:numericComparatorUsingUserPreferences(user)
      },
      {
        field: "quantity",
        headerName: intl.formatMessage({ "id": "grid-heading-grqty"}),
        type: "rightAligned",
        valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.quantity),
        filter: "agTextColumnFilter",
        filterParams:filterParamAmountFormattedAsStringUsingPreferences(user, intl),
        comparator:numericComparatorUsingUserPreferences(user)
      },
      {
        field: "supplierclaim",
        headerName: intl.formatMessage({ "id": "grid-heading-supplierClaimNumber"})
      },
      {
        field: "grdate",
        headerName: intl.formatMessage({ "id": "grid-heading-grDate" }),
        valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.grdate,getDateFormatOrDefault(user)),
        filterParams:filterParamWithUserDateFormatComparator(user),
        filter:'agDateColumnFilter',
        comparator: dateComparatorUsingUserPreferences(user)
      },
      {
        field: "amount",
        headerName: intl.formatMessage({ "id": "grid-heading-amount"}),
        type: "rightAligned",
        valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.amount),
        filter: "agTextColumnFilter",
        filterParams:filterParamAmountFormattedAsStringUsingPreferences(user, intl),
        comparator:numericComparatorUsingUserPreferences(user)
      },
      {
        field: "currency",
        headerName: intl.formatMessage({ "id": "grid-heading-currency"})
      },
      {
        field: "plantname",
        headerName: intl.formatMessage({ "id": "grid-heading-plant"})
      },
    ];
  }

  getDefaultColumnDefinitions(intl: IntlShape,user:PortalUser, distinctEntitlements:string[]): InfoPortalColumnDef[] {
    return [
      { field: "czcompcode", headerName: intl.formatMessage({ "id": "grid-heading-company" }) },
      { field: "partnerno", headerName: intl.formatMessage({ "id": "grid-heading-partnerNumber" }) },
      { field: "invoicestatus", headerName: intl.formatMessage({ "id": "grid-heading-status" }) },
      { field: "bmwdocno", headerName: intl.formatMessage({ "id": "grid-heading-bmwInvoiceNumber" }) },
      {
        field: "documentdate",
        headerName: intl.formatMessage({ "id": "grid-heading-documentDate" }),
        valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.documentdate,getDateFormatOrDefault(user)),
        filterParams:filterParamWithUserDateFormatComparator(user),
        filter:'agDateColumnFilter',
        comparator: dateComparatorUsingUserPreferences(user)
      },
      { field: "revstattxt", headerName: intl.formatMessage({ "id": "grid-heading-reversalStatus" }) },
      { field: "revdocno", headerName: intl.formatMessage({ "id": "grid-heading-reversalDocumentNumber" }) },
      {
        field: "grossamount",
        headerName: intl.formatMessage({ "id": "grid-heading-grossAmount" }),
        type:"rightAligned",
        valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.grossamount),
        filter: "agTextColumnFilter",
        filterParams:filterParamAmountFormattedAsStringUsingPreferences(user, intl),
        comparator:numericComparatorUsingUserPreferences(user)
      },
      { field: "currency", headerName: intl.formatMessage({ "id": "grid-heading-currency" }) },
      { field: "fapiaono", headerName: intl.formatMessage({ "id": "info-portal-fapiao-number" }) },
      {
        field: "invpostdate",
        headerName: intl.formatMessage({ "id": "grid-heading-postingDate-invoice" }),
        valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.invpostdate,getDateFormatOrDefault(user)),
        filterParams:filterParamWithUserDateFormatComparator(user),
        filter:'agDateColumnFilter',
        comparator: dateComparatorUsingUserPreferences(user)
      },
      { field: "originvoiceno", headerName: intl.formatMessage({ "id": "grid-heading-originalBmwInvoiceNumber" }) },
      { field: "origfapiaono", headerName: intl.formatMessage({ "id": "info-portal-original-fapiao-number" }) },
    ];
  }

  getShortViewColumns(): string[] {
    return [];
  }
}

export class CZ2Report implements InfoPortalReport {
  private _config = new SupplierClaimsAgGridColumnDef();

  getColumnsDefinition(): InfoPortalAgGridColumnDef {
    return this._config;
  }

  performSearch(form: FormInstance, currentUser: any, distinctEntitlements: string[]): Promise<InfoPortalGridService> {
    return retrieveCZ2Report(form).then(result =>
        new InfoPortalGridService(
            new ReportViewConfig("CZ2", this._config), result, distinctEntitlements));
  }

  hasExpertSearch(): boolean {
    return false;
  }

  renderExpertSearch({
                       currentUser,
                       intl,
                       distinctEntitlements,
                       initialization,
                       form,
                       initialValuesCache
                     }: InfoPortalSearchProperties): JSX.Element {
    return undefined;
  }

  renderSimpleSearch({
                       currentUser,
                       intl,
                       distinctEntitlements,
                       initialization,
                       form,
                       initialValuesCache,
                       rerenderDependentFields
                     }: InfoPortalSearchProperties): JSX.Element {
    return <SupplierClaimsSimpleSearch initialization={initialization}
                                      currentUser={currentUser}
                                      intl={intl}
                                      form={form}
                                      distinctEntitlements={distinctEntitlements}
                                      rerenderDependentFields={rerenderDependentFields}
                                      initialValuesCache={initialValuesCache}
                                      reportEntitlement={this.getEntitlement()}/>;
  }


  getEntitlement(): string {
    return "display_cz@reports";
  }

  getReportName(): string {
    return "CZ2";
  }
}