import client from "../../../config/GraphQLApolloClient";
import {  GET_BUSINESS_AREA_LIST, GET_BUSINESS_UNIT_LIST, GET_COUNTRY_INFO, GET_REGION_INFO } from "../queries";
import { getCountryInfo } from "../__generated__/getCountryInfo";
import { getRegionInfo } from "../__generated__/getRegionInfo";
import { getBusinessAreaList } from "../__generated__/getBusinessAreaList";
import { getBusinessUnitList } from "../__generated__/getBusinessUnitList"

export const getRegionInfoData = (regionId: string) => {
  // get specific Region Code
  return new Promise<getRegionInfo>((resolve, reject) => {
    client.query<getRegionInfo>({
      query: GET_REGION_INFO,
      variables: { regionId },
      fetchPolicy: "cache-first"
    }).then((response) => {
      const finalResponse: getRegionInfo = { getRegionInfo: response?.data.getRegionInfo };
      resolve(finalResponse);
    }).catch((err) => {
      console.error(err);
      reject(err);
    });

  });
};

export const getCountryInfoData = (countryId: string) => {
  // get specific Country Code
  return new Promise<getCountryInfo>((resolve, reject) => {
    client.query<getCountryInfo>({
      query: GET_COUNTRY_INFO,
      variables: { countryId },
      fetchPolicy: "cache-first"
    }).then((response) => {
      const finalResponse: getCountryInfo = { getCountryInfo: response?.data.getCountryInfo };
      resolve(finalResponse);
    }).catch((err) => {
      console.error(err);
      reject(err);
    });
  })
}

  export const getBusinessAreaCodeData = () => {
    // get list of Business Area Codes
    return new Promise<getBusinessAreaList>((resolve, reject) => {
      client.query<getBusinessAreaList>({
        query: GET_BUSINESS_AREA_LIST,
        variables: { },
        fetchPolicy: "cache-first"
      }).then((response) => {
        const finalResponse: getBusinessAreaList = { getBusinessAreaList: response?.data.getBusinessAreaList };
        resolve(finalResponse);
      }).catch((err) => {
        console.error(err);
        reject(err);
      });
  
    });
  };

  export const getBusinessUnitCodeData = () => {
    // get list of Business Area Codes
    return new Promise<getBusinessUnitList>((resolve, reject) => {
      client.query<getBusinessUnitList>({
        query: GET_BUSINESS_UNIT_LIST,
        variables: { },
        fetchPolicy: "cache-first"
      }).then((response) => {
        const finalResponse: getBusinessUnitList = { getBusinessUnitList: response?.data.getBusinessUnitList };
        resolve(finalResponse);
      }).catch((err) => {
        console.error(err);
        reject(err);
      });
  
    });
  };
