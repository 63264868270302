import React, { FunctionComponent } from 'react';
import { PageContainer } from '@ant-design/pro-layout';
import { Col, Collapse, Row } from 'antd';
import './Welcome.css';
import { CaretRightOutlined } from '@ant-design/icons';
import { IAppComponentProps } from '../components';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import RenderIfEntitled from '../components/EntitlementComponents';
import { INFO_PORTAL_ALL } from './info-portal/InfoPortalConstants';
import { entitlementExists } from '../main/entitlementService';

const Welcome: FunctionComponent<IAppComponentProps> = (props) => {
    const currentUser = props.currentUser;
    // const history = useHistory();  //it is not being used need to remove it for React upgrade
    const { Panel } = Collapse;
    const intl = useIntl();

    const redirect = (route: string) => {
        // @ts-ignore
        props.history?.replace({ pathname: route, state: { currentUser } });
    };

    return (

        <>
            <PageContainer title={intl.formatMessage({
                id: 'Welcome-title',
                defaultMessage: 'Welcome',
            })} content={''} extraContent={''}>
                <Row gutter={16}>
                    <Col>
                        <Row gutter={16}>
                            <Col className="gutter-row" style={{ marginLeft: '2px' }}>
                                <div className="title-header-card"
                                     style={{
                                         textAlign: 'center',
                                         width: '260px',
                                         height: '250px',
                                         marginLeft: '5px',
                                     }}>
                                    <div className="title-header-card-text">
                                        {intl.formatMessage({
                                            id: 'welcome-document-title',
                                            defaultMessage: 'eDocument',
                                        })}
                                    </div>
                                    <div className="title-header-card-text">
                                        {intl.formatMessage({
                                            id: 'welcome-document-portal-title',
                                            defaultMessage: 'Portal',
                                        })}
                                    </div>

                                </div>
                            </Col>
                            <Col className="gutter-row " span={6}>
                                <div id="title-header-card-image" style={{
                                    textAlign: 'center',
                                    width: '550px',
                                    height: '250px',
                                }} />
                            </Col>
                            <Col className="gutter-row" style={{ marginLeft: '2px' }}>
                                <div className="title-header-card"
                                     style={{
                                         textAlign: 'center',
                                         width: '260px',
                                         height: '250px',
                                         marginLeft: '5px'
                                     }}>
                                        <Col>
                                        <Row>
                                    <div className="title-header-card-text"
                                    style={{paddingBottom:'10px'}}>
                                        {intl.formatMessage({
                                            id: 'welcome-top-links-title',
                                            defaultMessage: 'Top Links',
                                        })}
                                    </div>
                                    </Row>
                                    <Row>
                                    {/*<span className="title-header-card-text" style={{ textDecoration: 'none',*/}
                                    {/*     paddingRight:'5px', fontSize:'20px', paddingTop:'8px' }}>{'> '}</span>*/}
                                    <div className="title-header-card-text"
                                    style={{textDecoration: 'underline',
                                        paddingBottom:'10px'
                                   }}>

                                         <a href="https://b2b.bmw.com/group/b2b/ap-academy1" target="_blank" rel="noopener noreferrer"
                                         style={{ textDecoration: 'none', color: 'inherit', fontSize:'20px' }}>
                                          {intl.formatMessage({
                                            id: 'welcome-ap-academy-tile',
                                            defaultMessage: '> AP Academy',
                                          })}
                                        </a>
                                    </div>
                                    </Row>
                                    <Row>
                                    {/*<span className="title-header-card-text" style={{ textDecoration: 'none',*/}
                                    {/*     paddingRight:'5px', fontSize:'20px', paddingTop:'8px' }}>{'> '}</span>    */}
                                    <div className="title-header-card-text"
                                    style={{textDecoration: 'underline',
                                        paddingBottom:'10px'
                                   }}>
                                    <a href="https://www.einvoicing.bmwgroup.com/" target="_blank" rel="noopener noreferrer"
                                    style={{ textDecoration: 'none', color: 'inherit', fontSize:'20px' }} >
                                        {intl.formatMessage({
                                            id: 'welcome-eInvoice-homepage-title',
                                            defaultMessage: ' > eInvoice Homepage',
                                        })}
                                        </a>
                                    </div>
                                    </Row>
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        {(props.distinctEntitlements && (props.distinctEntitlements.includes('menu_onboarding_einv@survey') ||
                                props.distinctEntitlements.includes('menu_management@survey')))
                            &&
                            <Collapse
                                bordered={false}
                                defaultActiveKey={['3']}
                                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                            >
                                <Panel header={intl.formatMessage({
                                    id: 'welcome-onboarding-panel-title',
                                    defaultMessage: 'My OnBoarding',
                                })} key="2"
                                >

                                    <Row justify="start" gutter={[12, 12]}>
                                        {props.distinctEntitlements && props.distinctEntitlements.includes('menu_onboarding_einv@survey') &&
                                            <Col span={5} style={{
                                                boxShadow: '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
                                                marginRight: '10px',
                                                marginLeft: '10px',
                                            }}>
                                                <Link className={'title-header-card-text mb10'}
                                                      to={'/survey/survey-overview'}
                                                      style={{ color: '#4a576d' }}>
                                                    <Row justify="space-around" align="middle"
                                                         style={{ height: '70px' }}>
                                                        <Col>
                                                            {intl.formatMessage({
                                                                id: 'welcome-onboarding-survey-title',
                                                                defaultMessage: 'EDI SURVEY',
                                                            })}
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </Col>

                                        }
                                        {props.distinctEntitlements && props.distinctEntitlements.includes('internal_viewer@portal') &&
                                            <Col span={5} style={{
                                                boxShadow: '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
                                            }}>
                                                <Link className={'title-header-card-text'}
                                                      to={'/survey/survey-management'}
                                                      style={{ color: '#4a576d' }}>
                                                    <Row justify="space-around" align="middle"
                                                         style={{ height: '70px' }}>
                                                        <Col>
                                                            {intl.formatMessage({
                                                                id: 'welcome-onboarding-survey-management-title',
                                                                defaultMessage: 'MANAGEMENT OVERVIEW',
                                                            })}
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </Col>
                                        }
                                    </Row>
                                </Panel>
                            </Collapse>
                        }
                    </Col>
                </Row>
                <Row justify="start" gutter={[12, 12]}>
                    <Col span={24}>
                        {(props.distinctEntitlements && (props.distinctEntitlements.includes('menu_documents@freight') || props.distinctEntitlements.includes('overview@documents'))) &&
                            <Collapse
                                bordered={false}
                                defaultActiveKey={['3']}
                                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                            >
                                <Panel header={
                                    intl.formatMessage({
                                        id: 'welcome-document-panel-title',
                                        defaultMessage: 'My Documents',
                                    })}
                                       key="9" showArrow={true}>

                                    <Row justify="start" gutter={[12, 12]}>
                                        {props.distinctEntitlements && props.distinctEntitlements.includes('menu_documents@freight') &&
                                            <Col span={5} style={{
                                                boxShadow: '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
                                                marginRight: '10px',
                                            }}>
                                                <Link className={'title-header-card-text'}
                                                      to={'/documents/transportation'}
                                                      style={{ color: '#4a576d' }}>
                                                    <Row justify="space-around" align="middle"
                                                         style={{ height: '70px' }}>
                                                        <Col>

                                                            {intl.formatMessage({
                                                                id: 'welcome-document-freight-title',
                                                                defaultMessage: 'FREIGHT',
                                                            })}

                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </Col>
                                        }
                                        {props.distinctEntitlements && props.distinctEntitlements.includes('display_dd@ecap') &&
                                            <Col span={5} style={{
                                                boxShadow: '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
                                                marginRight: '10px',
                                            }}>
                                                <Link className={'title-header-card-text'} to={'/e-cap-welcome'}
                                                      style={{ color: '#4a576d' }}>
                                                    <Row justify="space-around" align="middle"
                                                         style={{ height: '70px' }}>
                                                        <Col>
                                                            {intl.formatMessage({
                                                                id: 'welcome-eCap-title',
                                                                defaultMessage: 'eCAP',
                                                            })}
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </Col>
                                        }
                                        {props.distinctEntitlements && props.distinctEntitlements.includes('overview@documents') &&
                                            <Col span={5} style={{
                                                boxShadow: '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
                                            }}>
                                                <Link className={'title-header-card-text'}
                                                      to={'/documents/documents-overview'}
                                                      style={{ color: '#4a576d' }}>
                                                    <Row justify="space-around" align="middle"
                                                         style={{ height: '70px' }}>
                                                        <Col>

                                                            {intl.formatMessage({
                                                                id: 'welcome-List-Documents-title',
                                                                defaultMessage: 'DOCUMENTS OVERVIEW',
                                                            })}
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </Col>
                                        }
                                    </Row>
                                </Panel>
                            </Collapse>
                        }
                    </Col>
                </Row>
                <RenderIfEntitled entitlementToCheck={INFO_PORTAL_ALL} distinctEntitlements={props.distinctEntitlements}
                                  intl={props.intl}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Collapse
                                bordered={false}
                                defaultActiveKey={['3']}
                                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
                                <Panel data-testid={'my-reports'} header={intl.formatMessage({
                                    id: 'welcome-reports-panel-title',
                                    defaultMessage: 'My Reports',
                                })} key="7" showArrow={true}>
                                    <Row justify="start" gutter={[12, 12]}>

                                        <Col span={5} style={{
                                            boxShadow: '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
                                            marginRight: '10px',
                                            marginLeft: '10px',
                                        }}>
                                            <Link className={'title-header-card-text menu-item-to-uppercase'}
                                                  to={'/reports/account-payable-reports'}
                                                  style={{ color: '#4a576d' }}>
                                                <Row justify="space-around" align="middle" style={{ height: '70px' }}>
                                                    <Col>
                                                        {intl.formatMessage({
                                                            id: 'accounts-payable', defaultMessage: 'accounts payable',
                                                        })}

                                                    </Col>
                                                </Row>
                                            </Link>
                                        </Col>


                                        {entitlementExists('display_kp*@reports', props.distinctEntitlements) &&
                                            <Col span={5} style={{
                                                boxShadow: '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
                                                marginRight: '10px',
                                            }}>
                                                <Link
                                                    className={'title-header-card-text menu-item-to-uppercase'}
                                                    to={'/reports/internal-reports'}
                                                    style={{ color: '#4a576d' }}>
                                                    <Row justify="space-around" align="middle"
                                                         style={{ height: '70px' }}>
                                                        <Col>
                                                            {intl.formatMessage({
                                                                id: 'internal-reports',
                                                                defaultMessage: 'internal reports',
                                                            })}
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </Col>
                                        }

                                        {entitlementExists('display_tnr@reports', props.distinctEntitlements) &&
                                            <Col span={5} style={{
                                                boxShadow: '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
                                                marginRight: '10px',
                                            }}>
                                                <Link
                                                    className={'title-header-card-text menu-item-to-uppercase'}
                                                    to={'/reports/tnr-reports'}
                                                    style={{ color: '#4a576d' }}>
                                                    <Row justify="space-around" align="middle"
                                                         style={{ height: '70px' }}>
                                                        <Col>
                                                            {intl.formatMessage({
                                                                id: 'accounts-receivable',
                                                                defaultMessage: 'accounts-receivable',
                                                            })}
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </Col>
                                        }

                                    </Row>
                                </Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </RenderIfEntitled>
            </PageContainer>
        </>
    );
};

export default Welcome;
