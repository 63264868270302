import React, { useEffect, useState } from 'react';
import { useIntl, WrappedComponentProps } from 'react-intl';
import { Col, Form, Select, Spin } from 'antd';
import { getI18n } from '../utils/Utils';
import { getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners } from '../main/__generated__/getSelfEntitlementResultByFilter';
import { companiesLookupByEntitlement, filteredEntitlementLookupForSelf } from '../main/entitlementService';
import _ from 'lodash';

type IAppComponentProps = {
    currentUser?: any;
    distinctEntitlements?: string[];
    entitlement: string;
    colSpan?: number;
} & WrappedComponentProps;

type CompanyCodes = {
    companyCode: string,
    companyName: string
  }

export const CompanyLookup: React.FunctionComponent<IAppComponentProps> = (props) => {
    const intl = useIntl();
    const { Option } = Select;
    const [loading, setLoading] = useState(false);
    const [companyCodes, setCompanyCodes] = useState<CompanyCodes[]>(undefined);
    const [selectOptions, setSelectOption] = useState<
        getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners[]
    >([]);

    useEffect(() => {
        if (!companyCodes) {
          companiesLookupByEntitlement(props.entitlement).then(response => setCompanyCodes(response));
        }
    
      }, []);

    return (
        <>
            <Col span={props.colSpan || 5}>
                <Form.Item
                    name={'companyCode'}
                    key={'companyCodeKey'}
                    label={intl.formatMessage({
                        id: 'freight_panel_form_companyCode-label',
                        defaultMessage: 'Company Code',
                    })}
                >
                    <Select
                        placeholder={intl.formatMessage({
                            id: 'freight_panel_form_companyCode-label',
                            defaultMessage: 'Company Code',
                        })}
                        allowClear={true}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                            option.props.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
                        }
                        optionFilterProp="children"
                        showSearch={true}
                        data-testid={'companyCode'}
                    >
                        {companyCodes &&
                            companyCodes.map((companyCodeItem) => (
                                <Option
                                    key={`${companyCodeItem.companyCode}_${companyCodeItem.companyName}companyCode`}
                                    value={companyCodeItem.companyCode}
                                    data-testid={companyCodeItem.companyCode}
                                >
                                    {companyCodeItem.companyCode.concat(' - ').concat(companyCodeItem.companyName)}
                                </Option>
                            ))}
                    </Select>
                </Form.Item>
            </Col>
        </>
    );
};
