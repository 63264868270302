import { gql } from "@apollo/client";


export const LDAP_PARTNER_BY_PARTNERNO = gql`
    query getLdapPartnerResultByPartnerNo($partnerNo: String!, $authentication: String!){
        getLdapPartnerResultByPartnerNo(partnerNo: $partnerNo, authentication: $authentication) {
            ou
            supplierNameExt1
            partnerName
            localAdminUsers { mail givenName sn uid}
            masterAdminDeputyUsers {  mail givenName sn uid }
            masterAdminUsers {  mail givenName sn uid }
            countryCodeEU
            countryCodePlate
            currency
            localAdmin
            masterAdminDeputy
            masterAdmin
            objectClass
            supplierExtNumber
            supplierBaseNumber
            telephoneNumber
            supplierName
        }
    }
`;

export const LDAP_USER_BY_USERID = gql`
    query getLdapUserResultByUserId($userId: String!, $authentication: String!){
        getLdapUserResultByUserId(userId: $userId, authentication: $authentication){
            mail
            loginName
            uid
            givenName
            sn
            departmentNumber
            internalUser
            allEnterpriseRoles
            supplierExtNumber
            supplierBaseNumber
            permissionrole
            objectClass
            enterpriserole2
            enterpriserole1
            allSupplierNumbers
        }
    }
`


