import { IAppComponentProps } from "../../../components";
import React, { useEffect, useState } from "react";
import { Badge, Button, Col, Collapse, DatePicker, Form, Modal, Row, Select, Space, Tooltip } from "antd";
import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import styles from '../../info-portal/components/styling/infoportal.module.css';
import { useIntl } from "react-intl";
import {getI18n, getMinDate} from "../../../utils/Utils";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector  } from "../../../main/hooks";
import _ from "lodash";
import dayjs, { Dayjs } from "dayjs";
import {getDateFormatOrDefault} from "../../users/UserFormatPreferenceServices";
import { updateMissingInvoicesFilters } from "../../e-cap/IStoredMissingInvoicesSlice";


type TechDocumentsSearchFormProps = {
  onSearch: any;
  onDisplay: any;
  gridRef: any;
} & IAppComponentProps;


const TechDocumentsSearchForm: React.FunctionComponent<TechDocumentsSearchFormProps> = (props) => {

  const [form] = Form.useForm();
  const location = useLocation();
  const isFromCaptureDocument = location.state && location.state.isFromCaptureDocument;
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const missingInvoicesFiltersState = useAppSelector((state) => state.missingInvoices);

  const onSearch = () => {
      props.onSearch(form.getFieldsValue());
      dispatch(updateMissingInvoicesFilters((form.getFieldsValue())))
   
  };


useEffect(() => {
  if (missingInvoicesFiltersState.missingInvoicesFilters.receptionDateFrom && missingInvoicesFiltersState.missingInvoicesFilters.receptionDateTo)
    {
      form.setFieldsValue({
        receptionDateFrom:  missingInvoicesFiltersState.missingInvoicesFilters.receptionDateFrom ,
        receptionDateTo: missingInvoicesFiltersState.missingInvoicesFilters.receptionDateTo ,
      });
    }
}, [form])


  const onClear = () => {
    Modal.confirm({
      title: intl.formatMessage({
        id: "e-cap-draft",
        defaultMessage: "Reset Input Data"
      }),
      content: intl.formatMessage({
        id: "e-cap-message",
        defaultMessage: "Input Data will be lost. Do you want to continue?"
      }),
      okText: intl.formatMessage({ id: "e-cap-capture-document-cancel-confirm-modal-yes", defaultMessage: "YES" }),
      cancelText: intl.formatMessage({ id: "e-cap-capture-document-cancel-confirm-modal-no", defaultMessage: "NO" }),
      onOk: () => form.resetFields()
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const fromDateMinimumDate = ()=> {
    return dayjs().subtract(5, "years");
  };

  const toDateMinimumDate = ()=> {
    const receptionFromDate = form.getFieldValue('receptionDateFrom');
    if (receptionFromDate) {
      return receptionFromDate;
    }

    return dayjs().subtract(5, "years");
  };

  const toDateMaximumDate = ()=> {
    const receptionFromDate = form.getFieldValue('receptionDateFrom');
    if (receptionFromDate) {
      return getMinDate(dayjs(), receptionFromDate.add(3, "months"));
    }

    return dayjs().subtract(5, "years").add(3, "months");
  };

  const handleReceptionDateFrom = (newReceptionDateFrom:dayjs.Dayjs) => {
    if (newReceptionDateFrom) {
      const receptionToDate = form.getFieldValue('receptionDateTo');
      if (receptionToDate) {
        if (newReceptionDateFrom.isBefore(receptionToDate.subtract(3, "months")) ||
            newReceptionDateFrom.isAfter(receptionToDate)) {
          form.setFieldsValue({receptionDateTo: newReceptionDateFrom.clone()});
        }
      }
      else {
        form.setFieldsValue({receptionDateTo: newReceptionDateFrom.clone()});
      }
    }
    else {
        form.setFieldsValue({receptionDateTo: undefined});
    }
  }

  return (
    <>
      <Form size={"middle"} layout={"vertical"} form={form} onFinish={onSearch} onFinishFailed={onFinishFailed}
            autoComplete="off"
            initialValues={{ receptionDateFrom: dayjs(missingInvoicesFiltersState.missingInvoicesFilters.receptionDateFrom), 
              receptionDateTo:dayjs(missingInvoicesFiltersState.missingInvoicesFilters.receptionDateTo)}
              }
            className={"mt100"}>
        <Row gutter={24}>

          <Col span={5}>
            <label>{getI18n("missing-invoices-to-update-date", "From Update Date", intl)}</label>
            <Form.Item name={"receptionDateFrom"} key={"receptionDateFrom"}
                       rules={[{ required: true, message: intl.formatMessage({ id: "missing-invoices-select-update-date-to", defaultMessage: "Please Select Update Date From" }) }]}>
              <DatePicker
                // defaultValue={dayjs(missingInvoicesFiltersState.missingInvoicesFilters.receptionDateFrom)}
                className="mb20 mt8"
                format={getDateFormatOrDefault(props.currentUser)}
                placeholder={getI18n("missing-invoices-to-update-date", "From Update Date", intl)}
                style={{ width: 420 }}
                allowClear={true}
                data-testid={"receptionDateFrom"}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                onChange={handleReceptionDateFrom}
                disabledDate={(current) => current.isBefore(fromDateMinimumDate()) || current.isAfter(dayjs())}
              />
            </Form.Item>
          </Col>

          <Col span={5}>
            <label>{getI18n("missing-invoices-to-update-date", "To Update Date", intl)}</label>
            <Form.Item name={"receptionDateTo"} key={"receptionDateTo"}
                       rules={[{ required: true, message: getI18n("missing-invoices-select-update-date-to",
                       "Please Select Update Date To", intl)
                       }]}>
              <DatePicker
                // defaultValue={dayjs()}
                //defaultValue={isFromCaptureDocument ? dayjs(missingInvoicesFiltersState.missingInvoicesFilters.receptionDateTo): dayjs()}
                className="mb20 mt8"
                format={getDateFormatOrDefault(props.currentUser)}
                placeholder={getI18n("missing-invoices-to-update-date", "To Update Date", intl)}
                style={{ width: 420 }}
                allowClear={true}
                data-testid={"receptionDateTo"}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                disabledDate={(current) => current.isAfter(toDateMaximumDate()) || current.isBefore(toDateMinimumDate())}
              />
            </Form.Item>
          </Col>
        </Row><br />
        <div style={{margin:"20px 0px 10px 0px"}}>
          <Tooltip title={intl.formatMessage({'id': 'info-portal-search', 'defaultMessage': "Search"})}>
            <Button
                style={{marginRight:"10px"}}
                data-testid="info-portal-search-button"
                type="primary" htmlType="submit" icon={<SearchOutlined/>} size={'large'}/>
          </Tooltip>

          <Tooltip title={intl.formatMessage({'id': 'do-clear', 'defaultMessage': "Clear"})}>
            <Button
                data-testid="info-portal-clear"
                className={styles.simpleFlipRefresh}
                type="primary"
                icon={<ReloadOutlined/>}
                onClick={onClear}
                size={'large'}/>
          </Tooltip>

        </div>
      </Form>
    </>
  );
};

export default TechDocumentsSearchForm;


