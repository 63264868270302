import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TemplateDocumentLoadingState {
  loading: boolean;
  total: number;
}

const initialState: TemplateDocumentLoadingState = { loading: false, total: undefined };

const templateDocumentLoadingSlice = createSlice({
  name: "TemplateDocumentLoading",
  initialState,
  reducers: {
    isTemplateDocumentLoading(state, action: PayloadAction<boolean>) {
      const loading = action.payload;
      state.loading = loading;
    },
    setTotalTemplateDocument(state, action: PayloadAction<number>) {
      state.total = action.payload;
    }
  }
});

export const { isTemplateDocumentLoading, setTotalTemplateDocument } = templateDocumentLoadingSlice.actions;
export default templateDocumentLoadingSlice.reducer;