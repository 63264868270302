import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import { Button, Popover } from "antd";

export default (props: ICellRendererParams) => {
  if (props.value !== null && props.value !== undefined && props.value.trim().length > 0) {
    return (

      <Popover content={props.value}>
        <Button type={"primary"} size={"small"}>
          {props.value.slice(0, 24)}
        </Button>
      </Popover>
    );
  }
  return null
    ;
}