import React, { useEffect, useState } from 'react';
import { EntitlementFormProps, GetEntitlementRowStyle } from './EntitlementInterfaces';
import { Button, Card, Col, Form, Input, message, Row, Spin } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import { getAgGridLocalization } from '../../info-portal/components/grid/GridUtil';
import { IntlShape } from 'react-intl';
import { GridApi } from 'ag-grid-community';
import { useGetObjectClassListQuery } from './queries.generated';
import { ObjectClassInput } from 'src/__generated__/types';
import {
    UpdateEntitlementObjectClassMutationVariables,
    useUpdateEntitlementObjectClassMutation,
} from './mutations.generated';
import { getI18n } from '../../../utils/Utils';
import { ReloadOutlined, SaveOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { setObjectClassUpdated, updateMasterDataUpdated } from './redux/IStoredEntitlementsSlice';

function OBJECT_CLASS_TABLE_HEADERS(intl: IntlShape, onDelete: any, onEdit: any) {
    return [
        {
            headerName: intl.formatMessage({ id: 'entitlements-grid-header-action' }),
            filter: false,
            cellRenderer: 'buttonRenderer',
            cellRendererParams: {
                onClick: onEdit,
                label: 'Edit',
                type: 'primary',
            },
            width: 100,
        },
        {
            headerName: intl.formatMessage({ id: 'entitlements-grid-header-action' }),
            filter: false,
            cellRenderer: 'buttonRenderer',
            cellRendererParams: {
                onClick: onDelete,
                label: 'Delete',
                type: 'delete',
            },
            width: 110,
        },
        {
            headerName: intl.formatMessage({ id: 'entitlements-grid-header-object-class' }),
            field: 'userObjectClass',
            colId: 'User_Object_Class',
            width: 200,
            filter: 'agMultiColumnFilter',
        },
        {
            headerName: intl.formatMessage({ id: 'entitlements-grid-header-description' }),
            field: 'description',
            colId: 'description',
            width: 300,
            filter: 'agMultiColumnFilter',
        },
        {
            headerName: getI18n('entitlements-master-data-group-phrase-id-grid-header-name','Phrase Id',intl ),
            field: 'phraseId',
            colId: 'phraseId',
            width: 300,
            filter: 'agMultiColumnFilter',
        },
        {
            headerName: intl.formatMessage({ id: 'entitlements-grid-header-insert-user' }),
            field: 'insertUser',
            colId: 'insertUser',
            width: 130,
            filter: 'agMultiColumnFilter',
            cellRenderer: (params) => (params.value ? params.value.toUpperCase() : '')
        },
        {
            headerName: intl.formatMessage({ id: 'entitlements-grid-header-insert-time' }),
            field: 'insertTime',
            colId: 'insertTime',
            width: 200,
            filter: 'agDateColumnFilter',
        },
        {
            headerName: intl.formatMessage({ id: 'entitlements-grid-header-update-user' }),
            field: 'updateUser',
            colId: 'updateUser',
            width: 130,
            filter: 'agMultiColumnFilter',
            cellRenderer: (params) => (params.value ? params.value.toUpperCase() : '')
        },
        {
            headerName: intl.formatMessage({ id: 'entitlements-grid-header-update-time' }),
            field: 'updateTime',
            colId: 'updateTime',
            width: 200,
            filter: 'agDateColumnFilter',
        },
        {
            headerName: getI18n('entitlements-grid-header-volume' ,'#Times used',intl ),
            field: 'volume',
            colId: 'volume',
            width: 150,
            filter: 'agNumberColumnFilter',
        },
    ];
}

const EntitlementMasterDataEditObjectClassComponent: React.FunctionComponent<EntitlementFormProps> = (props) => {
    const [form] = Form.useForm();
    const [gridApi, setGridApi] = useState<GridApi>(undefined);
    const [objectClassList, setObjectClassList] = useState<ObjectClassInput[]>([]);
    const [updatedObjectClasses, setUpdatedObjectClasses] = useState<ObjectClassInput[]>([]);
    const [deletedObjectClasses, setDeletedObjectClasses] = useState<ObjectClassInput[]>([]);
    const [newObjectClasses, setNewObjectClasses] = useState<ObjectClassInput[]>([]);
    const [updateMode, setUpdateMode] = useState<boolean>(false);
    const [refreshLoading, setRefreshLoading] = useState(false);
    const dispatch = useDispatch();

    const [UpdateEntitlementObjectClassMutation, { data: updateResponse, loading: updateLoading, error: updateError }] =
        useUpdateEntitlementObjectClassMutation({
            variables: {
                objectClasses: [] as ObjectClassInput[],
            },
        });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const exists = objectClassList.find((objectClass) => objectClass.userObjectClass === value);
        if (exists && !updateMode) {
            message.error('Object Class already exists');
        }
        form.setFieldsValue({ phraseId: 'entitlement_object_class-'.concat(form.getFieldValue('userObjectClass')) });
    };

    const addObjectClass = () => {
        const exists = objectClassList.find(
            (objectClass) => objectClass.userObjectClass === form.getFieldValue('userObjectClass')
        );
        console.log('Exists: ', exists);
        
        if (!exists) {
            //insert new entitlement
            const userObjectClass = form.getFieldValue('userObjectClass');
            const description = form.getFieldValue('description');
            const phraseId = form.getFieldValue('phraseId');
            const insertUser = props.currentUser.username;
            const updateUser = props.currentUser.username;
            const updateAction = 'insert';
            setNewObjectClasses([
                ...newObjectClasses,
                { userObjectClass, description, phraseId,insertUser, updateUser, updateAction,volume:0 },
            ]);
            setObjectClassList([
                ...objectClassList,
                { userObjectClass, description, phraseId, insertUser, updateUser, updateAction, volume:0 },
            ]);
            form.setFieldValue('userObjectClass', '');
            form.setFieldValue('description', '');
            form.setFieldValue("phraseId", '');
        } else if (updateMode) {
            const userObjectClass = form.getFieldValue('userObjectClass');
            const description = form.getFieldValue('description');
            const id = exists.id;
            const phraseId = form.getFieldValue('phraseId');
            const updateUser = props.currentUser.username;
            const updateAction = 'update';
            dispatch(updateMasterDataUpdated(true));
            setUpdatedObjectClasses([
                ...updatedObjectClasses,
                {
                    id,
                    userObjectClass,
                    description,
                    phraseId,
                    insertUser: exists.insertUser,
                    insertTime: exists.insertTime,
                    updateUser,
                    updateAction,
                },
            ]);
            setObjectClassList(
                objectClassList.map((item) =>
                    item.userObjectClass === userObjectClass
                        ? {
                              ...item,
                              id,
                              description,
                              phraseId,
                              insertUser: exists.insertUser,
                              insertTime: exists.insertTime,
                              updateUser,
                              updateAction,
                          }
                        : item
                )
            );
            form.setFieldValue('userObjectClass', '');
            form.setFieldValue('description', '');
            form.setFieldValue("phraseId", '');
            setUpdateMode(false);
            dispatch(updateMasterDataUpdated(true));
        } else {
            message.error('Object Class already exists');
        }
    };

    const cancelUpdate = () => {
        form.setFieldValue('userObjectClass', '');
        form.setFieldValue('description', '');
        setUpdateMode(false);
    }   

    const onGridReady = (params) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
    };

    const ButtonRenderer = (props) => {
        const handleClick = () => {
            props.onClick(props);
        };

        return (
            <Button onClick={handleClick} className={`btn btn-${props.type}`}>
                {props.label}
            </Button>
        );
    };

    const components = {
        buttonRenderer: ButtonRenderer,
    };

    const onDeleteLine = (params) => {
        if (params.data.volume > 0) {
            message.error(
                `Object Class was used ${params.data.volume} times in Entitlement Rules and cannot be delete`
            );
            return;
        }
        params.api.applyTransaction({ remove: [params.data] });
        const newList = objectClassList.filter(
            (objectClass) => objectClass.id !== params.data.id
        );
        setObjectClassList(newList);
        //If not insert then also add to deleted list
        if (params.data.updateAction !== 'insert') {
            const userObjectClass = params.data.userObjectClass;
            const id = params.data.id;
            const description = params.data.description;
            const updateUser = props.currentUser.username;
            const updateAction = 'delete';
            setDeletedObjectClasses([
                ...deletedObjectClasses,
                { id, userObjectClass, description, updateUser, updateAction },
            ]);
            dispatch(updateMasterDataUpdated(true));
        }
    };

    const onEditLine = (params) => {
        form.setFieldValue('userObjectClass', params.data.userObjectClass);
        form.setFieldValue('description', params.data.description);
        form.setFieldValue('phraseId', params.data.phraseId);
        if (params.data.updateAction !== 'insert') {
            setUpdateMode(true);
        }
    };

    const {
        data: objectClassData,
        loading: objectClassLoading,
        error: objectClassError,
        refetch: objectClassRefresh,
    } = useGetObjectClassListQuery({
        variables: {},
    });

    if (objectClassError || updateError) {
        console.error(objectClassError || updateError);
        message.error('Error loading Object Classes');
    }



    const saveObjectClasses = () => {
        console.log('Save all data');
        //save all entitlements
        //call mutation for save
        const toBeUpdatedObjectClasses = [...newObjectClasses, ...updatedObjectClasses, ...deletedObjectClasses];
        if (toBeUpdatedObjectClasses && toBeUpdatedObjectClasses.length > 0) {
            UpdateEntitlementObjectClassMutation({
                variables: { objectClasses: toBeUpdatedObjectClasses } as UpdateEntitlementObjectClassMutationVariables,
            })
                .then((response) => {
                    console.dir(response);
                    //find the entitlements in responds in the entitlementTypes and update the list
                    const updatedObjectClasses = response.data.updateEntitlementObjectClass;
                    const updatedList = objectClassList.map((objectClass) => {
                        const updatedObjectClass = updatedObjectClasses.find(
                            (updated) => updated.userObjectClass === objectClass.userObjectClass
                        );
                        return updatedObjectClass ? { ...updatedObjectClass, updateAction: null } : objectClass;
                    });
                    console.log('updated list');
                    console.dir(updatedList);
                    setObjectClassList(updatedList);
                    setNewObjectClasses([]);
                    setUpdatedObjectClasses([]);
                    setDeletedObjectClasses([]);
                    dispatch(updateMasterDataUpdated(false));
                    dispatch(setObjectClassUpdated(true));

                    message.success(
                        getI18n(
                            'entitlement-master-data-edit-success-update-message',
                            'Updated successfully',
                            props.intl
                        )
                    );
                })
                .catch((error) => {
                    console.dir(error);
                });
        } else {
            message.error('Nothing to update');
        }
    };

    const refreshData = () => {
        setRefreshLoading(true);
        objectClassRefresh().then(({ data }) => {
            setObjectClassList(data.getObjectClassList);
            if (gridApi) {
                gridApi.setRowData(data.getObjectClassList);
            }
            setRefreshLoading(false);
        }).catch(() => {
            setRefreshLoading(false);
        });
    };

    React.useEffect(() => {
        props.onLoading(objectClassLoading);
        if (!objectClassLoading && objectClassData) {
            setObjectClassList(objectClassData.getObjectClassList);
        }
    }, [objectClassLoading, objectClassData]);

    return (
        <>
            <Spin spinning={updateLoading || objectClassLoading || refreshLoading }>
                <Card>
                    <Form form={form} layout="vertical" onFinish={addObjectClass}>
                        <Row gutter={24} className={'pl20'}>
                            <Col span={2}>
                                <Button type={'primary'} onClick={saveObjectClasses} icon={<SaveOutlined />}>
                                    Save all Object Classes
                                </Button>
                            </Col>
                            <Col span={3}>
                                <Button type="default" icon={<ReloadOutlined />} onClick={refreshData}>
                                    Refresh
                                </Button>
                            </Col>
                        </Row>
                        <Row gutter={24} className={'pl20'}>
                            <Col span={3}>
                                <Form.Item name={"userObjectClass"} key={"userObjectClass"} label={"User Object Class"} required={!updateMode}
                                    rules={[{ required: !updateMode, message: 'Please input the Object Class!' }]}>
                                    <Input
                                        type="text"
                                        onChange={handleInputChange}
                                        placeholder="User Object Class"
                                        readOnly={updateMode}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <Form.Item name={"description"} key={"description"} label={"Description"} rules={[{ required: true, message: 'Please input the description!' }]}>
                                    <Input
                                        type="text"
                                        name="description"
                                        placeholder="Object CLass Description"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item name={"phraseId"} key={"phraseId"} label={"Phrase Id"} rules={[{ required: true, message: 'Please input the Phrase Id!' }]}>
                                    <Input
                                        type="text"
                                        placeholder="Phrase Id"
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Button type='primary' onClick={() => form.submit()}>{updateMode ? "Update Entitlement": "Add Object Class"}</Button>
                            </Col>
                            <Col span={2}>
                                <Button type='primary' onClick={cancelUpdate}>{updateMode ? "Cancel Update": "Clear"}</Button>
                            </Col>
                        </Row>
                    </Form>
                    <Row gutter={24} className={'pl20'}>
                        <Col span={22}>
                            <div style={{ flex: 'auto', height: '500px', paddingTop: '10px' }} className="ag-theme-balham">
                                <AgGridReact
                                    defaultColDef={{
                                        enableValue: true,
                                        sortable: true,
                                        resizable: true,
                                    }}
                                    rowData={objectClassList}
                                    columnDefs={OBJECT_CLASS_TABLE_HEADERS(props.intl, onDeleteLine, onEditLine)}
                                    localeText={getAgGridLocalization(props.intl)}
                                    sideBar={false}
                                    rowClass="agGridRows"
                                    suppressMenuHide={true}
                                    debug={false}
                                    pagination={true}
                                    paginationPageSize={15}
                                    suppressRowClickSelection={true}
                                    onGridReady={onGridReady}
                                    rowSelection={'multiple'}
                                    components={components}
                                    getRowStyle={GetEntitlementRowStyle}
                                />
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Spin>
        </>
    );
};

export default EntitlementMasterDataEditObjectClassComponent;
