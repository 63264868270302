import {IntlShape} from 'react-intl/src/types';
import styles from '../styling/infoportal.module.css';
import React from 'react';
import {createRoot} from 'react-dom/client';
import {GridReadyEvent} from 'ag-grid-community/dist/lib/events';
import {ProcessCellForExportParams} from 'ag-grid-community/dist/lib/interfaces/exportParams';
import {ColDef, ColGroupDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {GridApi, IDateComp, IDateParams} from 'ag-grid-community';
import {PortalUser} from "../../../../components";
import {getDateFormatOrDefault} from "../../../users/UserFormatPreferenceServices";
import dayjs, {Dayjs} from "dayjs";
import {DatePicker} from "antd";
import {getRangePickerLocalization} from "./CalendarUtils";

export function getAgGridLocalization(intl:IntlShape) {
    return {
        selectAll:intl.formatMessage({id:'ag-grid-selectAll'}),
        selectAllSearchResults:intl.formatMessage({id:'ag-grid-selectAllSearchResults'}),
        searchOoo:intl.formatMessage({id:'ag-grid-searchOoo'}),
        blanks:intl.formatMessage({id:'ag-grid-blanks'}),
        noMatches:intl.formatMessage({id:'ag-grid-noMatches'}),
        filterOoo:intl.formatMessage({id:'ag-grid-filterOoo'}),
        equals:intl.formatMessage({id:'ag-grid-equals'}),
        notEqual:intl.formatMessage({id:'ag-grid-notEqual'}),
        blank:intl.formatMessage({id:'ag-grid-blank'}),
        notBlank:intl.formatMessage({id:'ag-grid-notBlank'}),
        empty:intl.formatMessage({id:'ag-grid-empty'}),
        lessThan:intl.formatMessage({id:'ag-grid-lessThan'}),
        greaterThan:intl.formatMessage({id:'ag-grid-greaterThan'}),
        lessThanOrEqual:intl.formatMessage({id:'ag-grid-lessThanOrEqual'}),
        greaterThanOrEqual:intl.formatMessage({id:'ag-grid-greaterThanOrEqual'}),
        inRange:intl.formatMessage({id:'ag-grid-inRange'}),
        inRangeStart:intl.formatMessage({id:'ag-grid-inRangeStart'}),
        inRangeEnd:intl.formatMessage({id:'ag-grid-inRangeEnd'}),
        contains:intl.formatMessage({id:'ag-grid-contains'}),
        notContains:intl.formatMessage({id:'ag-grid-notContains'}),
        startsWith:intl.formatMessage({id:'ag-grid-startsWith'}),
        endsWith:intl.formatMessage({id:'ag-grid-endsWith'}),
        dateFormatOoo:intl.formatMessage({id:'ag-grid-dateFormatOoo'}),
        andCondition:intl.formatMessage({id:'ag-grid-andCondition'}),
        orCondition:intl.formatMessage({id:'ag-grid-orCondition'}),
        applyFilter:intl.formatMessage({id:'ag-grid-applyFilter'}),
        resetFilter:intl.formatMessage({id:'ag-grid-resetFilter'}),
        clearFilter:intl.formatMessage({id:'ag-grid-clearFilter'}),
        cancelFilter:intl.formatMessage({id:'ag-grid-cancelFilter'}),
        textFilter:intl.formatMessage({id:'ag-grid-textFilter'}),
        numberFilter:intl.formatMessage({id:'ag-grid-numberFilter'}),
        dateFilter:intl.formatMessage({id:'ag-grid-dateFilter'}),
        setFilter:intl.formatMessage({id:'ag-grid-setFilter'}),
        columns:intl.formatMessage({id:'ag-grid-columns'}),
        filters:intl.formatMessage({id:'ag-grid-filters'}),
        pivotMode:intl.formatMessage({id:'ag-grid-pivotMode'}),
        groups:intl.formatMessage({id:'ag-grid-groups'}),
        rowGroupColumnsEmptyMessage:intl.formatMessage({id:'ag-grid-rowGroupColumnsEmptyMessage'}),
        values:intl.formatMessage({id:'ag-grid-values'}),
        valueColumnsEmptyMessage:intl.formatMessage({id:'ag-grid-valueColumnsEmptyMessage'}),
        pivots:intl.formatMessage({id:'ag-grid-pivots'}),
        pivotColumnsEmptyMessage:intl.formatMessage({id:'ag-grid-pivotColumnsEmptyMessage'}),
        group:intl.formatMessage({id:'ag-grid-group'}),
        rowDragRow:intl.formatMessage({id:'ag-grid-rowDragRow'}),
        rowDragRows:intl.formatMessage({id:'ag-grid-rowDragRows'}),
        loadingOoo:intl.formatMessage({id:'ag-grid-loadingOoo'}),
        loadingError:intl.formatMessage({id:'ag-grid-loadingError'}),
        noRowsToShow:intl.formatMessage({id:'ag-grid-noRowsToShow'}),
        enabled:intl.formatMessage({id:'ag-grid-enabled'}),
        pinColumn:intl.formatMessage({id:'ag-grid-pinColumn'}),
        pinLeft:intl.formatMessage({id:'ag-grid-pinLeft'}),
        pinRight:intl.formatMessage({id:'ag-grid-pinRight'}),
        noPin:intl.formatMessage({id:'ag-grid-noPin'}),
        valueAggregation:intl.formatMessage({id:'ag-grid-valueAggregation'}),
        autosizeThiscolumn:intl.formatMessage({id:'ag-grid-autosizeThiscolumn'}),
        autosizeAllColumns:intl.formatMessage({id:'ag-grid-autosizeAllColumns'}),
        groupBy:intl.formatMessage({id:'ag-grid-groupBy'}),
        ungroupBy:intl.formatMessage({id:'ag-grid-ungroupBy'}),
        addToValues:intl.formatMessage({id:'ag-grid-addToValues'}),
        removeFromValues:intl.formatMessage({id:'ag-grid-removeFromValues'}),
        addToLabels:intl.formatMessage({id:'ag-grid-addToLabels'}),
        removeFromLabels:intl.formatMessage({id:'ag-grid-removeFromLabels'}),
        resetColumns:intl.formatMessage({id:'ag-grid-resetColumns'}),
        expandAll:intl.formatMessage({id:'ag-grid-expandAll'}),
        collapseAll:intl.formatMessage({id:'ag-grid-collapseAll'}),
        copy:intl.formatMessage({id:'ag-grid-copy'}),
        ctrlC:intl.formatMessage({id:'ag-grid-ctrlC'}),
        copyWithHeaders:intl.formatMessage({id:'ag-grid-copyWithHeaders'}),
        copyWithGroupHeaders:intl.formatMessage({id:'ag-grid-copyWithGroupHeaders'}),
        paste:intl.formatMessage({id:'ag-grid-paste'}),
        ctrlV:intl.formatMessage({id:'ag-grid-ctrlV'}),
        export:intl.formatMessage({id:'ag-grid-export'}),
        csvExport:intl.formatMessage({id:'ag-grid-csvExport'}),
        excelExport:intl.formatMessage({id:'ag-grid-excelExport'}),
        sum:intl.formatMessage({id:'ag-grid-sum'}),
        min:intl.formatMessage({id:'ag-grid-min'}),
        max:intl.formatMessage({id:'ag-grid-max'}),
        none:intl.formatMessage({id:'ag-grid-none'}),
        count:intl.formatMessage({id:'ag-grid-count'}),
        avg:intl.formatMessage({id:'ag-grid-avg'}),
        filteredRows:intl.formatMessage({id:'ag-grid-filteredRows'}),
        selectedRows:intl.formatMessage({id:'ag-grid-selectedRows'}),
        totalRows:intl.formatMessage({id:'ag-grid-totalRows'}),
        totalAndFilteredRows:intl.formatMessage({id:'ag-grid-totalAndFilteredRows'}),
        more:intl.formatMessage({id:'ag-grid-more'}),
        to:intl.formatMessage({id:'ag-grid-to'}),
        of:intl.formatMessage({id:'ag-grid-of'}),
        page:intl.formatMessage({id:'ag-grid-page'}),
        nextPage:intl.formatMessage({id:'ag-grid-nextPage'}),
        lastPage:intl.formatMessage({id:'ag-grid-lastPage'}),
        firstPage:intl.formatMessage({id:'ag-grid-firstPage'}),
        previousPage:intl.formatMessage({id:'ag-grid-previousPage'}),
        pivotColumnGroupTotals:intl.formatMessage({id:'ag-grid-pivotColumnGroupTotals'}),
        pivotChartAndPivotMode:intl.formatMessage({id:'ag-grid-pivotChartAndPivotMode'}),
        pivotChart:intl.formatMessage({id:'ag-grid-pivotChart'}),
        chartRange:intl.formatMessage({id:'ag-grid-chartRange'}),
        columnChart:intl.formatMessage({id:'ag-grid-columnChart'}),
        groupedColumn:intl.formatMessage({id:'ag-grid-groupedColumn'}),
        stackedColumn:intl.formatMessage({id:'ag-grid-stackedColumn'}),
        normalizedColumn:intl.formatMessage({id:'ag-grid-normalizedColumn'}),
        barChart:intl.formatMessage({id:'ag-grid-barChart'}),
        groupedBar:intl.formatMessage({id:'ag-grid-groupedBar'}),
        stackedBar:intl.formatMessage({id:'ag-grid-stackedBar'}),
        normalizedBar:intl.formatMessage({id:'ag-grid-normalizedBar'}),
        pieChart:intl.formatMessage({id:'ag-grid-pieChart'}),
        pie:intl.formatMessage({id:'ag-grid-pie'}),
        doughnut:intl.formatMessage({id:'ag-grid-doughnut'}),
        line:intl.formatMessage({id:'ag-grid-line'}),
        xyChart:intl.formatMessage({id:'ag-grid-xyChart'}),
        scatter:intl.formatMessage({id:'ag-grid-scatter'}),
        bubble:intl.formatMessage({id:'ag-grid-bubble'}),
        areaChart:intl.formatMessage({id:'ag-grid-areaChart'}),
        area:intl.formatMessage({id:'ag-grid-area'}),
        stackedArea:intl.formatMessage({id:'ag-grid-stackedArea'}),
        normalizedArea:intl.formatMessage({id:'ag-grid-normalizedArea'}),
        histogramChart:intl.formatMessage({id:'ag-grid-histogramChart'}),
        histogramFrequency:intl.formatMessage({id:'ag-grid-histogramFrequency'}),
        combinationChart:intl.formatMessage({id:'ag-grid-combinationChart'}),
        columnLineCombo:intl.formatMessage({id:'ag-grid-columnLineCombo'}),
        AreaColumnCombo:intl.formatMessage({id:'ag-grid-AreaColumnCombo'}),
        pivotChartTitle:intl.formatMessage({id:'ag-grid-pivotChartTitle'}),
        rangeChartTitle:intl.formatMessage({id:'ag-grid-rangeChartTitle'}),
        settings:intl.formatMessage({id:'ag-grid-settings'}),
        data:intl.formatMessage({id:'ag-grid-data'}),
        format:intl.formatMessage({id:'ag-grid-format'}),
        categories:intl.formatMessage({id:'ag-grid-categories'}),
        defaultCategory:intl.formatMessage({id:'ag-grid-defaultCategory'}),
        series:intl.formatMessage({id:'ag-grid-series'}),
        xyValues:intl.formatMessage({id:'ag-grid-xyValues'}),
        paired:intl.formatMessage({id:'ag-grid-paired'}),
        axis:intl.formatMessage({id:'ag-grid-axis'}),
        navigator:intl.formatMessage({id:'ag-grid-navigator'}),
        color:intl.formatMessage({id:'ag-grid-color'}),
        thickness:intl.formatMessage({id:'ag-grid-thickness'}),
        xType:intl.formatMessage({id:'ag-grid-xType'}),
        automatic:intl.formatMessage({id:'ag-grid-automatic'}),
        category:intl.formatMessage({id:'ag-grid-category'}),
        number:intl.formatMessage({id:'ag-grid-number'}),
        time:intl.formatMessage({id:'ag-grid-time'}),
        autoRotate:intl.formatMessage({id:'ag-grid-autoRotate'}),
        xRotation:intl.formatMessage({id:'ag-grid-xRotation'}),
        yRotation:intl.formatMessage({id:'ag-grid-yRotation'}),
        ticks:intl.formatMessage({id:'ag-grid-ticks'}),
        width:intl.formatMessage({id:'ag-grid-width'}),
        height:intl.formatMessage({id:'ag-grid-height'}),
        length:intl.formatMessage({id:'ag-grid-length'}),
        padding:intl.formatMessage({id:'ag-grid-padding'}),
        spacing:intl.formatMessage({id:'ag-grid-spacing'}),
        chart:intl.formatMessage({id:'ag-grid-chart'}),
        title:intl.formatMessage({id:'ag-grid-title'}),
        titlePlaceholder:intl.formatMessage({id:'ag-grid-titlePlaceholder'}),
        background:intl.formatMessage({id:'ag-grid-background'}),
        font:intl.formatMessage({id:'ag-grid-font'}),
        top:intl.formatMessage({id:'ag-grid-top'}),
        right:intl.formatMessage({id:'ag-grid-right'}),
        bottom:intl.formatMessage({id:'ag-grid-bottom'}),
        left:intl.formatMessage({id:'ag-grid-left'}),
        labels:intl.formatMessage({id:'ag-grid-labels'}),
        size:intl.formatMessage({id:'ag-grid-size'}),
        minSize:intl.formatMessage({id:'ag-grid-minSize'}),
        maxSize:intl.formatMessage({id:'ag-grid-maxSize'}),
        legend:intl.formatMessage({id:'ag-grid-legend'}),
        position:intl.formatMessage({id:'ag-grid-position'}),
        markerSize:intl.formatMessage({id:'ag-grid-markerSize'}),
        markerStroke:intl.formatMessage({id:'ag-grid-markerStroke'}),
        markerPadding:intl.formatMessage({id:'ag-grid-markerPadding'}),
        itemSpacing:intl.formatMessage({id:'ag-grid-itemSpacing'}),
        itemPaddingX:intl.formatMessage({id:'ag-grid-itemPaddingX'}),
        itemPaddingY:intl.formatMessage({id:'ag-grid-itemPaddingY'}),
        layoutHorizontalSpacing:intl.formatMessage({id:'ag-grid-layoutHorizontalSpacing'}),
        layoutVerticalSpacing:intl.formatMessage({id:'ag-grid-layoutVerticalSpacing'}),
        strokeWidth:intl.formatMessage({id:'ag-grid-strokeWidth'}),
        lineDash:intl.formatMessage({id:'ag-grid-lineDash'}),
        offset:intl.formatMessage({id:'ag-grid-offset'}),
        offsets:intl.formatMessage({id:'ag-grid-offsets'}),
        tooltips:intl.formatMessage({id:'ag-grid-tooltips'}),
        callout:intl.formatMessage({id:'ag-grid-callout'}),
        markers:intl.formatMessage({id:'ag-grid-markers'}),
        shadow:intl.formatMessage({id:'ag-grid-shadow'}),
        blur:intl.formatMessage({id:'ag-grid-blur'}),
        xOffset:intl.formatMessage({id:'ag-grid-xOffset'}),
        yOffset:intl.formatMessage({id:'ag-grid-yOffset'}),
        lineWidth:intl.formatMessage({id:'ag-grid-lineWidth'}),
        normal:intl.formatMessage({id:'ag-grid-normal'}),
        bold:intl.formatMessage({id:'ag-grid-bold'}),
        italic:intl.formatMessage({id:'ag-grid-italic'}),
        boldItalic:intl.formatMessage({id:'ag-grid-boldItalic'}),
        predefined:intl.formatMessage({id:'ag-grid-predefined'}),
        fillOpacity:intl.formatMessage({id:'ag-grid-fillOpacity'}),
        strokeOpacity:intl.formatMessage({id:'ag-grid-strokeOpacity'}),
        histogramBinCount:intl.formatMessage({id:'ag-grid-histogramBinCount'}),
        columnGroup:intl.formatMessage({id:'ag-grid-columnGroup'}),
        barGroup:intl.formatMessage({id:'ag-grid-barGroup'}),
        pieGroup:intl.formatMessage({id:'ag-grid-pieGroup'}),
        lineGroup:intl.formatMessage({id:'ag-grid-lineGroup'}),
        scatterGroup:intl.formatMessage({id:'ag-grid-scatterGroup'}),
        areaGroup:intl.formatMessage({id:'ag-grid-areaGroup'}),
        histogramGroup:intl.formatMessage({id:'ag-grid-histogramGroup'}),
        combinationGroup:intl.formatMessage({id:'ag-grid-combinationGroup'}),
        groupedColumnTooltip:intl.formatMessage({id:'ag-grid-groupedColumnTooltip'}),
        stackedColumnTooltip:intl.formatMessage({id:'ag-grid-stackedColumnTooltip'}),
        normalizedColumnTooltip:intl.formatMessage({id:'ag-grid-normalizedColumnTooltip'}),
        groupedBarTooltip:intl.formatMessage({id:'ag-grid-groupedBarTooltip'}),
        stackedBarTooltip:intl.formatMessage({id:'ag-grid-stackedBarTooltip'}),
        normalizedBarTooltip:intl.formatMessage({id:'ag-grid-normalizedBarTooltip'}),
        pieTooltip:intl.formatMessage({id:'ag-grid-pieTooltip'}),
        doughnutTooltip:intl.formatMessage({id:'ag-grid-doughnutTooltip'}),
        lineTooltip:intl.formatMessage({id:'ag-grid-lineTooltip'}),
        groupedAreaTooltip:intl.formatMessage({id:'ag-grid-groupedAreaTooltip'}),
        stackedAreaTooltip:intl.formatMessage({id:'ag-grid-stackedAreaTooltip'}),
        normalizedAreaTooltip:intl.formatMessage({id:'ag-grid-normalizedAreaTooltip'}),
        scatterTooltip:intl.formatMessage({id:'ag-grid-scatterTooltip'}),
        bubbleTooltip:intl.formatMessage({id:'ag-grid-bubbleTooltip'}),
        histogramTooltip:intl.formatMessage({id:'ag-grid-histogramTooltip'}),
        columnLineComboTooltip:intl.formatMessage({id:'ag-grid-columnLineComboTooltip'}),
        areaColumnComboTooltip:intl.formatMessage({id:'ag-grid-areaColumnComboTooltip'}),
        customComboTooltip:intl.formatMessage({id:'ag-grid-customComboTooltip'}),
        noDataToChart:intl.formatMessage({id:'ag-grid-noDataToChart'}),
        pivotChartRequiresPivotMode:intl.formatMessage({id:'ag-grid-pivotChartRequiresPivotMode'}),
        chartSettingsToolbarTooltip:intl.formatMessage({id:'ag-grid-chartSettingsToolbarTooltip'}),
        chartLinkToolbarTooltip:intl.formatMessage({id:'ag-grid-chartLinkToolbarTooltip'}),
        chartUnlinkToolbarTooltip:intl.formatMessage({id:'ag-grid-chartUnlinkToolbarTooltip'}),
        chartDownloadToolbarTooltip:intl.formatMessage({id:'ag-grid-chartDownloadToolbarTooltip'}),
        seriesChartType:intl.formatMessage({id:'ag-grid-seriesChartType'}),
        seriesType:intl.formatMessage({id:'ag-grid-seriesType'}),
        secondaryAxis:intl.formatMessage({id:'ag-grid-secondaryAxis'}),
        ariaChecked:intl.formatMessage({id:'ag-grid-ariaChecked'}),
        ariaColumn:intl.formatMessage({id:'ag-grid-ariaColumn'}),
        ariaColumnGroup:intl.formatMessage({id:'ag-grid-ariaColumnGroup'}),
        ariaColumnList:intl.formatMessage({id:'ag-grid-ariaColumnList'}),
        ariaColumnSelectAll:intl.formatMessage({id:'ag-grid-ariaColumnSelectAll'}),
        ariaDateFilterInput:intl.formatMessage({id:'ag-grid-ariaDateFilterInput'}),
        ariaDefaultListName:intl.formatMessage({id:'ag-grid-ariaDefaultListName'}),
        ariaFilterColumnsInput:intl.formatMessage({id:'ag-grid-ariaFilterColumnsInput'}),
        ariaFilterFromValue:intl.formatMessage({id:'ag-grid-ariaFilterFromValue'}),
        ariaFilterInput:intl.formatMessage({id:'ag-grid-ariaFilterInput'}),
        ariaFilterList:intl.formatMessage({id:'ag-grid-ariaFilterList'}),
        ariaFilterToValue:intl.formatMessage({id:'ag-grid-ariaFilterToValue'}),
        ariaFilterValue:intl.formatMessage({id:'ag-grid-ariaFilterValue'}),
        ariaFilteringOperator:intl.formatMessage({id:'ag-grid-ariaFilteringOperator'}),
        ariaHidden:intl.formatMessage({id:'ag-grid-ariaHidden'}),
        ariaIndeterminate:intl.formatMessage({id:'ag-grid-ariaIndeterminate'}),
        ariaInputEditor:intl.formatMessage({id:'ag-grid-ariaInputEditor'}),
        ariaMenuColumn:intl.formatMessage({id:'ag-grid-ariaMenuColumn'}),
        ariaRowDeselect:intl.formatMessage({id:'ag-grid-ariaRowDeselect'}),
        ariaRowSelectAll:intl.formatMessage({id:'ag-grid-ariaRowSelectAll'}),
        ariaRowToggleSelection:intl.formatMessage({id:'ag-grid-ariaRowToggleSelection'}),
        ariaRowSelect:intl.formatMessage({id:'ag-grid-ariaRowSelect'}),
        ariaSearch:intl.formatMessage({id:'ag-grid-ariaSearch'}),
        ariaSortableColumn:intl.formatMessage({id:'ag-grid-ariaSortableColumn'}),
        ariaToggleVisibility:intl.formatMessage({id:'ag-grid-ariaToggleVisibility'}),
        ariaUnchecked:intl.formatMessage({id:'ag-grid-ariaUnchecked'}),
        ariaVisible:intl.formatMessage({id:'ag-grid-ariaVisible'}),
        ariaSearchFilterValues:intl.formatMessage({id:'ag-grid-ariaSearchFilterValues'}),
        ariaRowGroupDropZonePanelLabel:intl.formatMessage({id:'ag-grid-ariaRowGroupDropZonePanelLabel'}),
        ariaValuesDropZonePanelLabel:intl.formatMessage({id:'ag-grid-ariaValuesDropZonePanelLabel'}),
        ariaPivotDropZonePanelLabel:intl.formatMessage({id:'ag-grid-ariaPivotDropZonePanelLabel'}),
        ariaDropZoneColumnComponentDescription:intl.formatMessage({id:'ag-grid-ariaDropZoneColumnComponentDescription'}),
        ariaDropZoneColumnValueItemDescription:'',
        ariaDropZoneColumnGroupItemDescription:intl.formatMessage({id:'ag-grid-ariaDropZoneColumnGroupItemDescription'}),
        ariaDropZoneColumnComponentAggFuncSeperator:intl.formatMessage({id:'ag-grid-ariaDropZoneColumnComponentAggFuncSeperator'}),
        ariaDropZoneColumnComponentSortAscending:intl.formatMessage({id:'ag-grid-ariaDropZoneColumnComponentSortAscending'}),
        ariaDropZoneColumnComponentSortDescending:intl.formatMessage({id:'ag-grid-ariaDropZoneColumnComponentSortDescending'}),
        ariaLabelColumnMenu:intl.formatMessage({id:'ag-grid-ariaLabelColumnMenu'}),
        ariaLabelCellEditor:intl.formatMessage({id:'ag-grid-ariaLabelCellEditor'}),
        ariaLabelDialog:intl.formatMessage({id:'ag-grid-ariaLabelDialog'}),
        ariaLabelSelectField:intl.formatMessage({id:'ag-grid-ariaLabelSelectField'}),
        ariaLabelTooltip:intl.formatMessage({id:'ag-grid-ariaLabelTooltip'}),
        ariaLabelContextMenu:intl.formatMessage({id:'ag-grid-ariaLabelContextMenu'}),
        ariaLabelSubMenu:intl.formatMessage({id:'ag-grid-ariaLabelSubMenu'}),
        ariaLabelAggregationFunction:intl.formatMessage({id:'ag-grid-ariaLabelAggregationFunction'}),
        thousandSeparator:',',
        decimalSeparator:'.'
    }
}

export type PageResizeConfig = {
    uniqueIdentifier?:string,
    providedPageSizes?:number[],
    selectedSize?:number;
    emitPageSize?: (arg:number)=>void
};

/**
 * Injects a page size selector into the ag-grid component.
 * Simple use is to to not provide the PageSizeConfig, in which case
 * you must add the class edp-page-size-component to you AgGrid component,
 * and you will have the options 50, 100, 200, 500.
 * Optionally you can just specify the sizes, but you will still have to provide
 * the className provided above.
 * The complication of the emitPageSize and selectedSize is to cater  for detailed records in a master row.
 * @param event
 * @param intl
 * @param pageResizeConfig
 */
export const injectPageResizeIntoAgGrid = (event:GridReadyEvent,intl:IntlShape, pageResizeConfig?:PageResizeConfig) => {
    const pageSizes = pageResizeConfig?.providedPageSizes?.length ? pageResizeConfig.providedPageSizes: [50, 100, 200, 500];
    const uniqueIdentifier = pageResizeConfig?.uniqueIdentifier || 'edp-page-size-component';
    const defaultValue = pageResizeConfig?.selectedSize || pageSizes[0];

    const pagerDropSizeDown = (pageSetter:(newPageNumber:any)=>void)=> {
        const pagerSummaryList = document.querySelectorAll(`.${uniqueIdentifier} .ag-paging-panel.ag-unselectable`);

        if (pagerSummaryList && pagerSummaryList.length) {
            const lastPagerSummary = pagerSummaryList[pagerSummaryList.length-1];

            if (lastPagerSummary.firstElementChild.id !== 'pagerSize') {
                const insertedDiv = document.createElement("span");
                insertedDiv.setAttribute('id','pagerSize');
                lastPagerSummary.insertBefore(insertedDiv,lastPagerSummary.firstElementChild);

                const querySelector = createRoot(document.querySelector(`.${uniqueIdentifier} #pagerSize`));
                if (querySelector) {
                    querySelector.render(<>
                        <select
                            data-testid={`${uniqueIdentifier}-grid-main-page-selector`}
                            onChange={pageSetter}
                            className={`${styles.pageSizeSelector}`}
                            defaultValue={defaultValue}>
                            {
                                pageSizes.map(size=>
                                    <option
                                        key={`${uniqueIdentifier}-page-size-key-${size}`}
                                        data-testid={`${uniqueIdentifier}-grid-main-page-option-${size}`} value={size}>
                                        {`${size} ${intl.formatMessage({id:'info-portal-records-per-page'})}`}
                                    </option>)
                            }
                        </select>
                    </>, querySelector);
                }
            }
        }
    }

    const pageNumSetter = (val:any)=> {
        const newSize = Number(val.target?.value);
        if (pageResizeConfig) {
            pageResizeConfig.selectedSize = newSize;
            if (pageResizeConfig.emitPageSize) {
                pageResizeConfig.emitPageSize(newSize);
            }
        }
        event.api!.paginationSetPageSize(newSize);
    };

    pagerDropSizeDown(pageNumSetter);
    event.api!.paginationSetPageSize(pageResizeConfig?.selectedSize || pageSizes[0]);
}

export function processCellForClipboard(params:ProcessCellForExportParams<any>) {
    const colDef = params.column.getColDef();
    if (colDef.valueFormatter) {
        // @ts-ignore
        return colDef.valueFormatter({
            ...params,
            data: params.node?.data,
            colDef
        })
    }

    return params.value;
}

export class PersonalizeGridView {
    private gridName:string;
    private originalColumns: (ColDef<any> | ColGroupDef<any>)[];
    private currentView: (ColDef<any> | ColGroupDef<any>)[];
    private gridApi:GridApi<any>;

    constructor(gridName:string, originalColumns:(ColDef<any> | ColGroupDef<any>)[]) {
        this.gridName = gridName;
        this.originalColumns = originalColumns;
        this.currentView = originalColumns.slice();
    }

    public initPersonalizedView(gridApi:GridApi<any>) {
        this.gridApi = gridApi;
        this.applyPersonalizedView();
    }

    public getView(): (ColDef<any> | ColGroupDef<any>)[] {
        return this.currentView;
    }

    public hasPersonalizedView(): boolean {
        const cachedValue = localStorage.getItem(this.gridName);
        return !!cachedValue;
    }

    public reset() {
        this.currentView = this.originalColumns.slice();
        for (const currentViewElement of this.currentView) {
            currentViewElement['hide'] = false;
        }
        this.gridApi.setColumnDefs(this.currentView);
    }

    public applyPersonalizedView() {
        if (this.hasPersonalizedView()) {
            const cachedValue = localStorage.getItem(this.gridName);
            if (cachedValue) {
                const savedColumnDefinitions = JSON.parse(cachedValue);
                if (savedColumnDefinitions.length) {
                    const newColumnDefinitions:(ColDef | ColGroupDef)[] = [];
                    for (const column of this.currentView) {
                        newColumnDefinitions.push( {...column, hide:true});
                    }

                    for (let i = 0; i < savedColumnDefinitions.length; i++) {
                        const columnDefinition = savedColumnDefinitions[i];
                        for (let j=0;j< newColumnDefinitions.length;j++) {
                            if (newColumnDefinitions[j]['colId'] === columnDefinition.colId) {
                                newColumnDefinitions[j] = {
                                    ...newColumnDefinitions[j],
                                    ...columnDefinition,
                                    hide:false
                                }
                                if (i!==j) {
                                    const swap = newColumnDefinitions[i];
                                    newColumnDefinitions[i] = newColumnDefinitions[j];
                                    newColumnDefinitions[j] = swap;
                                }
                            }
                        }
                    }



                    this.gridApi?.setColumnDefs(newColumnDefinitions);
                    this.currentView = newColumnDefinitions;
                }
            }
        }
    }

    public savePersonalizedView() {
        if (this.gridApi) {
            const filteredColumns =
                this.gridApi.getColumnDefs()
                    .filter(item=>!item['hide'])
                    .filter(item=>item['colId']);

            this.currentView = this.gridApi?.getColumnDefs();

            const filteredFieldSubset = filteredColumns.map(item=> {
                let sortIndex = item['sortIndex'];
                if (!sortIndex && sortIndex!==0) {
                    sortIndex = undefined;
                }
                return {
                    colId:item['colId'],
                    width:item['width'] || undefined,
                    sort:item['sort'] || undefined,
                    sortIndex
                }
            });

            localStorage.setItem(this.gridName, JSON.stringify(filteredFieldSubset));
        }
    }
}

export function filterParamWithUserAmountFormatComparator(){
    return {
        suppressAndOrCondition:true
    }
}

export function filterParamWithUserDateFormatComparator(user:PortalUser){
    const cellValueFormat = getDateFormatOrDefault(user);
    return {
        suppressAndOrCondition:true,
        browserDatePicker:true,
        comparator: (filterLocalDateAtMidnight: Date, dateAsString: string) => {
            if (dateAsString == null) return -1;

            const cellDate = dayjs(dateAsString, cellValueFormat).toDate();

            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
            }

            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            }

            if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        }
    }
}

export function filterParamAmountFormattedAsStringUsingPreferences(user:PortalUser, intl:IntlShape) {

    const filterValueToNumber = (filterValue:string) => {
        if (filterValue) {
            try {
                return parseFloat(filterValue);
            }
            catch(e) {
                //silently fail....
            }
        }

        return null;
    }

    const cellValueToNumber = (cellValue:string) => {
        if (cellValue) {
            const newCellValue = cellValue.replaceAll(user.thousandSeparator, '');
            if (user.decimalSeparator !== '.') {
                return parseFloat(newCellValue.replaceAll(user.decimalSeparator, '.'));
            }
            return parseFloat(newCellValue);
        }

        return null;
    }

    return {
        suppressAndOrCondition:true,
        filterOptions:[
            {
                displayKey: 'equals',
                displayName: intl.formatMessage({id:'ag-grid-equals'}),
                predicate: (filterValues:string[], cellValue:string) => {
                    if (filterValues?.length || cellValue) {
                        return filterValueToNumber(filterValues[0]) === cellValueToNumber(cellValue);
                    }

                    return false;
                }
            },
            {
                displayKey: 'notEqual',
                displayName: intl.formatMessage({id:'ag-grid-notEqual'}),
                predicate: (filterValues:string[], cellValue:string) => {
                    if (filterValues?.length || cellValue) {
                        return filterValueToNumber(filterValues[0]) !== cellValueToNumber(cellValue);
                    }

                    return false;
                }
            },
            {
                displayKey: 'lessThan',
                displayName: intl.formatMessage({id:'ag-grid-lessThan'}),
                predicate: (filterValues:string[], cellValue:string) => {
                    if (filterValues?.length || cellValue) {
                        return filterValueToNumber(filterValues[0]) > cellValueToNumber(cellValue);
                    }

                    return false;
                }
            },
            {
                displayKey: 'lessThanOrEqual',
                displayName: intl.formatMessage({id:'ag-grid-lessThanOrEqual'}),
                predicate: (filterValues:string[], cellValue:string) => {
                    if (filterValues?.length || cellValue) {
                        return filterValueToNumber(filterValues[0]) >= cellValueToNumber(cellValue);
                    }

                    return false;
                }
            },
            {
                displayKey: 'greaterThan',
                displayName: intl.formatMessage({id:'ag-grid-greaterThan'}),
                predicate: (filterValues:string[], cellValue:string) => {
                    if (filterValues?.length || cellValue) {
                        return filterValueToNumber(filterValues[0]) < cellValueToNumber(cellValue);
                    }

                    return false;
                }
            },
            {
                displayKey: 'greaterThanOrEqual',
                displayName: intl.formatMessage({id:'ag-grid-greaterThanOrEqual'}),
                predicate: (filterValues:string[], cellValue:string) => {
                    if (filterValues?.length || cellValue) {
                        return filterValueToNumber(filterValues[0]) <= cellValueToNumber(cellValue);
                    }

                    return false;
                }
            },
            {
                displayKey: 'inRange',
                displayName: intl.formatMessage({id:'ag-grid-inRange'}),
                numberOfInputs:2,
                predicate: (filterValues:string[], cellValue:string) => {
                    if (filterValues?.length ===2 || cellValue) {
                        const cellValueAsNumber = cellValueToNumber(cellValue);
                        return filterValueToNumber(filterValues[0]) <= cellValueAsNumber && filterValueToNumber(filterValues[1]) >= cellValueAsNumber;
                    }

                    return false;
                }
            },
            {
                displayKey: 'blank',
                displayName: intl.formatMessage({id:'blank'}),
                predicate: (_:string[], cellValue:string) => {
                    if (cellValue) {
                        return false;
                    }

                    return true;
                }
            },
            {
                displayKey: 'notBlank',
                displayName: intl.formatMessage({id:'ag-grid-notBlank'}),
                predicate: (_:string[], cellValue:string) => {
                    if (cellValue) {
                        return true;
                    }

                    return false;
                }
            }
        ]
    }
}

function GridFilterDatePicker(onDateChanged:()=>void, setDate: (date:Date)=>void, intl:IntlShape) {
    const format = sessionStorage.getItem("date-format") || 'YYYY-MM-DD';

    const onChange = (date:Dayjs)=> {

        if (date) {
            setDate(date.toDate());
        }
        else {
            setDate(null);
        }

        onDateChanged();
    }

    return <DatePicker
        format={format}
        onChange={onChange}
        placeholder={intl.formatMessage({id:'select-date'})}
        locale={getRangePickerLocalization(intl, localStorage.getItem("locale") || "en")}
    />
}

export class CustomDateComponent implements IDateComp {
    params!: IDateParams;
    eGui!: HTMLDivElement;
    date: Date;
    intl:IntlShape;

    init(params: IDateParams) {
        this.params = params;
        const settingDate = (date:Date)=>this.date = date;

        this.eGui = document.createElement('div');
        const theRoot = createRoot(this.eGui);
        theRoot.render(GridFilterDatePicker(this.params.onDateChanged, settingDate,CustomDateComponent.prototype.intl));
    }

    getGui() {
        return this.eGui;
    }

    getDate() {
        return this.date;
    }

    setDate(date: Date) {
        this.date = date;
    }
}
