import React from "react";
import { saveAs } from "save-as";
import { renderInvoiceCellValue } from "../../components/decorators/decorator";
import PartnerNameCellRenderer from "./decorator/PartnerNameCellRenderer";
import DocumentDownloadedCellRenderer from "./decorator/DocumentDownloadedCellRenderer";
import BtnDownloadCellRenderer from "./BtnDownloadCellRenderer";
import { IntlShape } from "react-intl/src/types";
import {PortalUser} from "../../components";
import {
  convertFromSpecifiedDateFormatToUserDefined,
  convertFromUserSpecifiedDateFormatToExpected
} from "../users/UserFormatPreferenceServices";
import {getI18n} from "../../utils/Utils";

export const MYDOCS_TABLE_HEADERS = (intl: IntlShape, user:PortalUser) => [
  {
    headerName: intl.formatMessage({ id: "freight_panel_grid_reference", defaultMessage: "Reference" }),
    field: "reference",
    colId: "reference",
    filter: true,
    width: 360,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    autoHeight: true,
    tooltipField: "reference"
  },
  {
    headerName: intl.formatMessage({ id: "freight_panel_grid_companyCode", defaultMessage: "COC" }),
    field: "companyCode",
    colId: "companyCode",
    filter: true,
    width: 100
  },
  {
    headerName: intl.formatMessage({ id: "freight_panel_grid_partnerno", defaultMessage: "Partner no" }),
    field: "partnerno",
    colId: "partnerno",
    filter: true,
    width: 160,
    tooltipField: "partnerno"
  },
  {
    headerName: intl.formatMessage({ id: "freight_panel_grid_partnerName", defaultMessage: "Partner name" }),
    field: "partnerName",
    colId: "partnerName",
    filter: true,
    width: 320,
    cellRenderer: (params) => {
      return renderInvoiceCellValue(params.value) !== null ? renderInvoiceCellValue(params.value) : null;
    }
  },
  {
    headerName: intl.formatMessage({ id: "freight_panel_grid_azFilename", defaultMessage: "AZ filename" }),
    field: "azDownloadFileName",
    colId: "azFilename",
    width: 240,
    filter: true,
    cellRenderer: PartnerNameCellRenderer
  },
  {
    headerName: intl.formatMessage({ id: "freight_panel_grid_dateReceived", defaultMessage: "File received" }),
    colId: "docDate",
    comparator:(a:string,b:string)=> {
      if (a && b) {
        const aInYYYYMMDD = convertFromUserSpecifiedDateFormatToExpected(user, a, "YYYY-MM-DD");
        const bInYYYYMMDD = convertFromUserSpecifiedDateFormatToExpected(user, b, "YYYY-MM-DD");
        return aInYYYYMMDD.localeCompare(bInYYYYMMDD);
      }
      return 0;
    },
    valueGetter: (params) => {
      if (params.data.dateReceived) {
        const dateInYYYY_MM_DD = params.data.dateReceived.substring(0, 10);
        //return convertFromSpecifiedDateFormatToUserDefined(user, dateInYYYY_MM_DD, "YYYY-MM-DD");
        return convertFromSpecifiedDateFormatToUserDefined(user, dateInYYYY_MM_DD, "YYYY-MM-DD");
      }
      return "";
    },
    width: 140,
    filter: false
  },
  {
    headerName: intl.formatMessage({ id: "freight_panel_grid_orderArea", defaultMessage: "Order Area" }),
    field: "area",
    colId: "orderArea",
    filter: true,
    width: 120
  },
  {
    headerName: intl.formatMessage({ id: "freight_panel_grid_downloaded", defaultMessage: "Downloaded" }),
    field: "downloaded",
    colId:"downloaded",
    width: 170,
    valueGetter: (params) => {
      return params.data.downloaded === 0 ? getI18n("general-edp-no", "NO", intl) : getI18n("general-edp-yes", "YES", intl)
    }
  },
  {
    headerName: intl.formatMessage({ id: "freight_panel_grid_status", defaultMessage: "Status" }),
    field: "status",
    colId: "status",
    filter: true,
    width: 320
  },
  {
    colId:"action",
    headerName: intl.formatMessage({ id: "freight_panel_grid_action", defaultMessage: "Action" }),
    filter: false,
    cellRenderer: BtnDownloadCellRenderer,
    width: 100
  }
];

export const base64toFile = async (b64Data, filename,mimeType) => {
  const sliceSize = 512;

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: mimeType });
  saveAs(blob, filename);
};

export const saveFileAs = async (byteArray:Uint8Array, filename,mimeType) => {
  const sliceSize = 512;

  const byteArrays = [];
  byteArrays.push(byteArray);

  const blob = new Blob(byteArrays, { type: mimeType });
  saveAs(blob, filename);
};

export const base64toZipFile = async (b64Data, filename) => {
  await base64toFile(b64Data, filename, "application/zip");
};
