import React, { FunctionComponent, useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Tabs, Spin, message } from "antd";

import { IAppComponentProps } from "../../components";
import SurveyAgreements from "./surveyAgreements/survey-agreements.component";
import { useIntl } from "react-intl";
import {StateContainer} from "./utilities/StateContainer";


type ISurveyInvoiceAgreementStepProps = {
  agreementTypeId?: number;
  groupId?: string;
  next?: any;
  prev?: any;
  onStatusUpdate?: any;
  stateContainer?: StateContainer;
} & IAppComponentProps;

const SurveyInvoiceAgreementStep: FunctionComponent<ISurveyInvoiceAgreementStepProps> = (props) => {
  const intl = useIntl();
  const { TabPane } = Tabs;
  const [form] = Form.useForm();
  const agreementData = {approverName :""
  , approverEmail: ""
  ,agreementStatusId :undefined
  ,agreementDocId :undefined
  ,agreementStatusDate: undefined
,formValuesChanged: false};

const handleNextClick = () =>
{
  if (agreementData.formValuesChanged)
  {
    message.error(intl.formatMessage({
      id: "agreement-dataChangedSaveFailure",
      defaultMessage: "Form values changed, save first"
      }))
  }
  else{
    props.next();
  }

}

const handlePrevClick = () =>
{
  if (agreementData.formValuesChanged)
  {
    message.error(intl.formatMessage({
      id: "agreement-dataChangedSaveFailure",
      defaultMessage: "Form values changed, save first"
      }))
  }
  else{
    props.prev();
  }

}



   return (
    <>
      <Card title={intl.formatMessage({
        id: "agreement-tab-title",
        defaultMessage: "eInvoice Agreement"
        })}
        size={"small"} 
        className="ml-10 ml25">
        <SurveyAgreements form={form} 
                          agreementData={agreementData} 
                          agreementTypeId={props.agreementTypeId} 
                          groupId={props.groupId} 
                          onStatusUpdate={props.onStatusUpdate}
                          {...props} />

       {/* <Row gutter={8} justify={"end"}>
          <Col xs={24} sm={6} md={4}>
            <Button type="default" size="large" className="w-100 mt10" onClick={handlePrevClick}>
              {props.intl.formatMessage({
                id: "survey-main-general-previous-button",
                defaultMessage: "PREVIOUS"
                })}
            </Button>
          </Col>


          <Col xs={24} sm={6} md={4}>
            <Button
              type="primary"
              size="large"
              className="w-100 mt10"
              onClick={handleNextClick}
            >
              {props.intl.formatMessage({
                id: "survey-main-general-next-button",
                defaultMessage: "NEXT"
                })}
            </Button>
          </Col>
        </Row>*/}
      </Card>
    </>
  );

};

export default SurveyInvoiceAgreementStep;
