import React, {useState} from "react";
import { PortalFormItemSelectFormat } from "./FormUtils";
import { useQuery } from "@apollo/client";
import { getDocumentFormat } from "../pages/documents/__generated__/getDocumentFormat";
import { SELECT_FORMAT_QUERY } from "../pages/documents/queries";
import { IAppComponentProps } from "./index";
import { useIntl } from "react-intl";
import { getI18n } from "../utils/Utils";
import { useLocation } from "react-router-dom";
import { useAppSelector  } from "../main/hooks"

export const Format: React.FunctionComponent<IAppComponentProps> = (props) => {

  const { data } = useQuery<getDocumentFormat>(SELECT_FORMAT_QUERY,
    { fetchPolicy: "network-only", 
    variables: { select: "FORMAT", username: props.currentUser } }
  );
  const intl = useIntl();
  const docOverviewFiltersState = useAppSelector((state) => state.documentOverview);
  const location = useLocation();
  const isFromCaptureDocument = location.state && location.state.isFromCaptureDocument;

  const [formatSelectedValue, setFormatSelectedValue] = useState(isFromCaptureDocument ? docOverviewFiltersState.documentOverviewFilters.formatId: null);

  return (
    <>
      <PortalFormItemSelectFormat
        type={"single"}
        label={getI18n("welcome-Format", "Format", intl)}
        name={"formatId"}
        placeHolder= {getI18n( "welcome-Format", "Format", intl)}
        getDocumentFormat={data}
        defaultValue={isFromCaptureDocument ? docOverviewFiltersState.documentOverviewFilters.formatId  :null} 
        value={formatSelectedValue}
        onChange={(value) => setFormatSelectedValue(value)}
      />
    </>
  );
};
