import React, { useEffect, useState } from 'react';
import { EntitlementFormProps, GetEntitlementRowStyle } from './EntitlementInterfaces';
import { Button, Card, Col, Form, Input, message, Row, Spin } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import { getAgGridLocalization } from '../../info-portal/components/grid/GridUtil';
import { IntlShape } from 'react-intl';
import { GridApi } from 'ag-grid-community';
import { B2xRoles, B2xRolesInput } from 'src/__generated__/types';
import { useGetB2XRolesQuery } from './queries.generated';
import { getI18n } from '../../../utils/Utils';
import {
    UpdateEntitlementB2XRolesMutationVariables,
    useUpdateEntitlementB2XRolesMutation,
} from './mutations.generated';
import { ReloadOutlined, SaveOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { setB2XRoleUpdated, updateMasterDataUpdated } from './redux/IStoredEntitlementsSlice';

function B2X_ROLE_TABLE_HEADERS(intl: IntlShape, onDelete: any, onEdit: any) {
    return [
        {
            headerName: intl.formatMessage({ id: 'entitlements-grid-header-action' }),
            filter: false,
            cellRenderer: 'buttonRenderer',
            cellRendererParams: {
                onClick: onEdit,
                label: 'Edit',
                type: 'primary',
            },
            width: 100,
        },
        {
            headerName: intl.formatMessage({ id: 'entitlements-grid-header-action' }),
            filter: false,
            cellRenderer: 'buttonRenderer',
            cellRendererParams: {
                onClick: onDelete,
                label: 'Delete',
                type: 'delete',
            },
            width: 110,
        },
        {
            headerName: intl.formatMessage({ id: 'entitlements-grid-header-name' }),
            field: 'userB2xrole',
            colId: 'userB2xrole',
            width: 300,
            filter: 'agMultiColumnFilter',
        },
        {
            headerName: intl.formatMessage({ id: 'entitlements-grid-header-description' }),
            field: 'description',
            colId: 'description',
            width: 500,
            filter: 'agMultiColumnFilter',
        },
        {
            headerName: getI18n('entitlements-master-data-group-phrase-id-grid-header-name', 'Phrase Id', intl),
            field: 'phraseId',
            colId: 'phraseId',
            width: 300,
            filter: 'agMultiColumnFilter',
        },
        {
            headerName: intl.formatMessage({ id: 'entitlements-grid-header-insert-user' }),
            field: 'insertUser',
            colId: 'insertUser',
            width: 130,
            filter: 'agMultiColumnFilter',
            cellRenderer: (params) => (params.value ? params.value.toUpperCase() : ''),
        },
        {
            headerName: intl.formatMessage({ id: 'entitlements-grid-header-insert-time' }),
            field: 'insertTime',
            colId: 'insertTime',
            width: 200,
            filter: 'agDateColumnFilter',
        },
        {
            headerName: intl.formatMessage({ id: 'entitlements-grid-header-update-user' }),
            field: 'updateUser',
            colId: 'updateUser',
            width: 130,
            filter: 'agMultiColumnFilter',
            cellRenderer: (params) => (params.value ? params.value.toUpperCase() : ''),
        },
        {
            headerName: intl.formatMessage({ id: 'entitlements-grid-header-update-time' }),
            field: 'updateTime',
            colId: 'updateTime',
            width: 200,
            filter: 'agDateColumnFilter',
        },
        {
            headerName: getI18n('entitlements-grid-header-volume', '#Times used', intl),
            field: 'volume',
            colId: 'volume',
            width: 150,
            filter: 'agNumberColumnFilter',
        },
    ];
}

const EntitlementMasterDataEditB2XRolesComponent: React.FunctionComponent<EntitlementFormProps> = (props) => {
    const [form] = Form.useForm();
    const [newB2XRoles, setNewB2XRoles] = useState<B2xRolesInput[]>([]);
    const [updatedB2XRoles, setUpdatedB2XRoles] = useState<B2xRolesInput[]>([]);
    const [deletedB2XRoles, setDeletedB2XRoles] = useState<B2xRolesInput[]>([]);
    const [gridApi, setGridApi] = useState<GridApi>(undefined);
    const [b2xRoles, setB2XRoles] = useState<B2xRolesInput[]>([]);
    const [updateMode, setUpdateMode] = useState<boolean>(false);
    const [refreshLoading, setRefreshLoading] = useState(false);
    const dispatch = useDispatch();

    const [UpdateEntitlementB2XRolesMutation, { data: updateResponse, loading: updateLoading, error: updateError }] =
        useUpdateEntitlementB2XRolesMutation({
            variables: {
                b2xRoles: [] as B2xRolesInput[],
            },
        });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const exists = b2xRoles.find((b2xRole) => b2xRole.userB2xrole === value);
        if (exists && !updateMode) {
            message.error('Object Class already exists');
        }
        form.setFieldsValue({ phraseId: 'entitlement_b2xRole-'.concat(form.getFieldValue('userB2xrole')) });
    };

    const addB2XRole = () => {
        const exists = b2xRoles.find((b2xRole) => b2xRole.userB2xrole === form.getFieldValue('userB2xrole'));
        if (!exists) {
            //insert new entitlement
            const userB2xrole = form.getFieldValue('userB2xrole');
            const description = form.getFieldValue('description');
            const phraseId = form.getFieldValue('phraseId');
            const insertUser = props.currentUser.username;
            const updateUser = props.currentUser.username;
            const updateAction = 'insert';
            const newRecord = { userB2xrole, description,phraseId, insertUser, updateUser, updateAction, volume: 0 };
            setNewB2XRoles([...newB2XRoles, newRecord]);
            setB2XRoles([...b2xRoles, newRecord]);
            form.setFieldValue('userB2xrole', '');
            form.setFieldValue('description', '');
            form.setFieldValue('phraseId', '');
            dispatch(updateMasterDataUpdated(true));
        } else if (updateMode) {
            const userB2xrole = form.getFieldValue('userB2xrole');
            const id = exists.id;
            const description = form.getFieldValue('description');
            const phraseId = form.getFieldValue('phraseId');
            const updateUser = props.currentUser.username;
            const updateAction = 'update';
            const updatedRecord = {id,userB2xrole, description, phraseId, insertUser: exists.insertUser, insertTime: exists.insertTime, updateUser, updateTime: exists.updateTime, updateAction, volume: exists.volume};
            dispatch(updateMasterDataUpdated(true));
            setUpdatedB2XRoles([...updatedB2XRoles,updatedRecord]);
            setB2XRoles(
                b2xRoles.map((item) =>
                    item.userB2xrole === userB2xrole
                        ? updatedRecord
                        : item
                )
            );
            form.setFieldValue('userB2xrole', '');
            form.setFieldValue('description', '');
            form.setFieldValue('phraseId', '');
            setUpdateMode(false);
        } else {
            message.error('Object Class already exists');
        }
    };

    const cancelB2XRole = () => {
        form.setFieldValue('userB2xrole', '');
        form.setFieldValue('description', '');
        setUpdateMode(false);
    };

    const onGridReady = (params) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
    };

    const ButtonRenderer = (props) => {
        const handleClick = () => {
            props.onClick(props);
        };

        return (
            <Button onClick={handleClick} className={`btn btn-${props.type}`}>
                {props.label}
            </Button>
        );
    };

    const components = {
        buttonRenderer: ButtonRenderer,
    };

    const onDeleteLine = (params) => {
        if (params.data.volume > 0) {
            message.error(
                `Object Class was used ${params.data.volume} times in Entitlement Rules and cannot be delete`
            );
            return;
        }
        params.api.applyTransaction({ remove: [params.data] });
        const newList = b2xRoles.filter((b2xRole) => b2xRole.id !== params.data.id);
        setB2XRoles(newList);
        //If not insert then also add to deleted list
        if (params.data.updateAction !== 'insert') {
            const userB2xrole = params.data.userB2xrole;
            const id = params.data.id;
            const description = params.data.description;
            const phraseId = params.data.phraseId;
            const updateUser = props.currentUser.username;
            const updateAction = 'delete';
            setDeletedB2XRoles([...deletedB2XRoles, { id, userB2xrole, description, phraseId, updateUser, updateAction }]);
            dispatch(updateMasterDataUpdated(true));
        }
    };

    const onEditLine = (params) => {
        form.setFieldValue('userB2xrole', params.data.userB2xrole);
        form.setFieldValue('description', params.data.description);
        form.setFieldValue('phraseId', params.data.phraseId);
        if (params.data.updateAction !== 'insert') {
            setUpdateMode(true);
        }
    };

    const {
        data: b2xData,
        loading: b2xLoading,
        error: b2xError,
        refetch: b2xRoleRefresh,
    } = useGetB2XRolesQuery({
        variables: {},
    });

    if (b2xError) {
        console.dir(b2xError);
        message.error('Error loading B2X Roles');
    }

    React.useEffect(() => {
        props.onLoading(b2xLoading);
        if (!b2xLoading && b2xData) {
            setB2XRoles(b2xData.getB2XRoles);
        }
    }, [b2xLoading, b2xData]);

    const saveB2XRoles = () => {
        //save all entitlements
        //call mutation for save
        const toBeUpdatedB2XRoles = [...newB2XRoles, ...updatedB2XRoles, ...deletedB2XRoles];
        if (toBeUpdatedB2XRoles && toBeUpdatedB2XRoles.length > 0) {
            UpdateEntitlementB2XRolesMutation({
                variables: { b2xRoles: toBeUpdatedB2XRoles } as UpdateEntitlementB2XRolesMutationVariables,
            })
                .then((response) => {
                    console.dir(response);
                    //find the entitlements in responds in the entitlementTypes and update the list
                    const updatedB2XRoles = response.data.updateEntitlementB2XRoles;
                    const updatedList = b2xRoles.map((b2xRole) => {
                        const updatedB2XRole = updatedB2XRoles.find(
                            (updated) => updated.userB2xrole === b2xRole.userB2xrole
                        );
                        return updatedB2XRole ? { ...updatedB2XRole, updateAction: null } : b2xRole;
                    });
                    setB2XRoles(updatedList);
                    setNewB2XRoles([]);
                    setUpdatedB2XRoles([]);
                    setDeletedB2XRoles([]);
                    dispatch(updateMasterDataUpdated(false));
                    dispatch(setB2XRoleUpdated(true));
                    message.success(
                        getI18n(
                            'entitlement-master-data-edit-success-update-message',
                            'Updated successfully',
                            props.intl
                        )
                    );
                })
                .catch((error) => {
                    console.dir(error);
                });
        } else {
            message.error('Nothing to update');
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        message.error('Failed to add entitlements.');
    };

    const refreshData = () => {
        setRefreshLoading(true);
        b2xRoleRefresh().then(({ data }) => {
            setB2XRoles(data.getB2XRoles);
            if (gridApi) {
                gridApi.setRowData(data.getB2XRoles);
            }
            setRefreshLoading(false);
        }).catch(() => {
            setRefreshLoading(false);
        });
    };

    return (
        <>
            <Spin spinning={updateLoading || b2xLoading || refreshLoading}>
                <Card>
                    <Form form={form} layout="vertical" onFinish={addB2XRole} onFinishFailed={onFinishFailed}>
                        <Row gutter={24} className={'pl20'}>
                            <Col span={2}>
                                <Button type={'primary'} onClick={saveB2XRoles} icon={<SaveOutlined />}>
                                    Save all Object Classes
                                </Button>
                            </Col>
                            <Col span={3}>
                                <Button type="default" icon={<ReloadOutlined />} onClick={refreshData}>
                                    Refresh
                                </Button>
                            </Col>
                        </Row>
                        <Row gutter={24} className={'pl20'}>
                            <Col span={5}>
                                <Form.Item
                                    name={'userB2xrole'}
                                    key={'userB2xrole'}
                                    label={'B2X Role Name'}
                                    rules={[{ required: !updateMode, message: 'Please input the B2X Role!' }]}
                                >
                                    <Input
                                        type="text"
                                        onChange={handleInputChange}
                                        placeholder="B2X Role Name"
                                        readOnly={updateMode}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                    name={'description'}
                                    key={'description'}
                                    label={'Role Description'}
                                    rules={[{ required: true, message: 'Please input the description!' }]}
                                >
                                    <Input
                                        type="text"
                                        name="description"
                                        onChange={handleInputChange}
                                        placeholder="Role Description"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item name={"phraseId"} key={"phraseId"} label={"Phrase Id"} rules={[{ required: true, message: 'Please input the Phrase Id!' }]}>
                                    <Input
                                        type="text"
                                        placeholder="Phrase Id"
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Button type="primary" onClick={() => form.submit()}>
                                    {updateMode ? 'Update B2X Role' : 'Add B2X Role'}
                                </Button>
                            </Col>
                            <Col span={2}>
                                <Button type="primary" onClick={cancelB2XRole}>
                                    {updateMode ? 'Cancel Update' : 'Clear'}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    <Row gutter={24} className={'pl20'}>
                        <Col span={22}>
                            <div
                                style={{ flex: 'auto', height: '500px', paddingTop: '10px' }}
                                className="ag-theme-balham"
                            >
                                <AgGridReact
                                    defaultColDef={{
                                        enableValue: true,
                                        sortable: true,
                                        resizable: true,
                                    }}
                                    rowData={b2xRoles}
                                    columnDefs={B2X_ROLE_TABLE_HEADERS(props.intl, onDeleteLine, onEditLine)}
                                    localeText={getAgGridLocalization(props.intl)}
                                    sideBar={false}
                                    rowClass="agGridRows"
                                    suppressMenuHide={true}
                                    debug={false}
                                    pagination={true}
                                    paginationPageSize={15}
                                    suppressRowClickSelection={true}
                                    onGridReady={onGridReady}
                                    rowSelection={'multiple'}
                                    components={components}
                                    getRowStyle={GetEntitlementRowStyle}
                                />
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Spin>
        </>
    );
};

export default EntitlementMasterDataEditB2XRolesComponent;
