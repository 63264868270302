import {IAppComponentProps} from "../../../components";
import {Button, ConfigProvider, Form, Input, notification, Switch, Typography, message} from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import React, {useEffect, useState} from "react";
import {Form_Item} from "../../e-cap/componets/FormItem-component";
import {RuleSet} from "../../e-cap/util/RuleResultCache";
import styles from "../styling/survey.module.css"
import {
    getConnectivityPeppolDetailsForSurvey_getConnectivityPeppolDetailsForSurvey as ConnectivityPeppolDetails
} from "../__generated__/getConnectivityPeppolDetailsForSurvey";
import {getPeppolDetailsForSurvey, updateSurveyConnectivityPeppolDetails} from "./SurveyConnectivityService";
import {SurveyConnectivityPeppolInput} from "../../../../__generated__/globalTypes";
import {
    saveConnectivityPeppolDetailsForSurvey_saveConnectivityPeppolDetailsForSurvey as UpdatedPeppolDetails
} from "../__generated__/saveConnectivityPeppolDetailsForSurvey";
import Title from "antd/lib/typography/Title";
import enUS from "antd/lib/locale/en_US";
import deDe from "antd/lib/locale/de_DE";
import { useAppDispatch, useAppSelector } from "../../../main/hooks";
import { MODE } from "../IStoredSurvey";
import { resetSurveyDataLoading, setSurveyDataLoading } from "../IStoredSurveySlice";
import { getI18n } from "../../../utils/Utils";

function fetchRule(fieldName: string, isTest:boolean): RuleSet {
    const result = {
        required: false,
        picture: "",
        readOnly: false,
        hidden: false,
        formatMessage: null,
        infoMessage: null
    };
    if (!isTest && fieldName === "receivingID") {
        result.required = true;
    }
    if (!isTest && fieldName === "sendingID") {
        result.required = true;
    }

    return result;
}

type SurveyConnectivityPeppolProperties = {
    groupId?: any;
    onStatusUpdate?: () => void;
} & IAppComponentProps;


export function SurveyConnectivityPeppol({groupId, intl, distinctEntitlements,onStatusUpdate}: SurveyConnectivityPeppolProperties) {
    // const [loading, isLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const [isProduction,setIsProduction] = useState<boolean>(true);
    const [testDetails, setTestDetails] = useState<SurveyConnectivityPeppolInput>(null);
    const [productionDetails, setProductionDetails] = useState<SurveyConnectivityPeppolInput>(null);
    const [form] = Form.useForm();

    const surveyState = useAppSelector((state) => state.survey);

    const isViewMode = () => {
      return surveyState.survey.surveyMode === MODE.VIEW ? true : false
    }
  
    const isOverViewMode = () => {
      return surveyState.survey.surveyMode === MODE.OVERVIEW ? true : false
    }
  
  const isReadOnly = () => {
    return isViewMode() || isOverViewMode();
   }

    const copyFromResponse = (response:ConnectivityPeppolDetails|UpdatedPeppolDetails) => {
        let peppolInputDetails:SurveyConnectivityPeppolInput = {};

        if (response) {
            peppolInputDetails = {
                environment: response.environment,
                receivingPeppolId: response.receivingPeppolId,
                receivingPeppolIdComment: response.receivingPeppolIdComment,
                sendingPeppolId: response.sendingPeppolId,
                sendingPeppolIdComment: response.sendingPeppolIdComment,
            }
        }

        return peppolInputDetails
    }

    useEffect(() => {
        if (!testDetails && !productionDetails) {
            const getDetails = (pepolType:'TEST'|'PRODUCTION',list:ConnectivityPeppolDetails[])=> {
                const existingDetails = list.find(item=>item.environment?.toUpperCase() === pepolType);
                const response = copyFromResponse(existingDetails);

                if (!existingDetails) {
                    response.environment = pepolType;
                }

                return response;
            }

            // isLoading(true)
            dispatch(setSurveyDataLoading(true));
            getPeppolDetailsForSurvey(groupId)
                .then(response=> {
                    const testDetails = getDetails('TEST',response);
                    const productionDetails = getDetails('PRODUCTION',response);
                    setTestDetails(testDetails);
                    setProductionDetails(productionDetails);
                    if (isProduction) {
                        form.setFieldsValue({
                            receivingID: productionDetails?.receivingPeppolId,
                            receivingIDComment: productionDetails?.receivingPeppolIdComment,
                            sendingID: productionDetails?.sendingPeppolId,
                            sendingIDComment: productionDetails?.sendingPeppolIdComment
                        })
                    }
                    else {
                        form.setFieldsValue({
                            receivingID: testDetails?.receivingPeppolId,
                            receivingIDComment: testDetails?.receivingPeppolIdComment,
                            sendingID: testDetails?.sendingPeppolId,
                            sendingIDComment: testDetails?.sendingPeppolIdComment
                        })
                    }
                })
                .catch(() => {
                    dispatch(resetSurveyDataLoading(true));
                  })
                .finally(() => {
                    dispatch(setSurveyDataLoading(false));
                });
        }
    },[])

    const onFinish = (values: any) => {
        const receivingID = values['receivingID'];
        const receivingIDComment = values['receivingIDComment'];
        const sendingID = values['sendingID'];
        const sendingIDComment = values['sendingIDComment'];

        if (!receivingID && !sendingID) {
            notification.error({message: intl.formatMessage({id:'survey-connectivity-peppol-receiving-sending-empty'})});
            return;
        }

        async function updatePeppolDetails() {
            const peppolDetails = !isProduction? testDetails: productionDetails;
            peppolDetails.sendingPeppolId = sendingID;
            peppolDetails.sendingPeppolIdComment = sendingIDComment;
            peppolDetails.receivingPeppolId = receivingID;
            peppolDetails.receivingPeppolIdComment = receivingIDComment;
            const result = await updateSurveyConnectivityPeppolDetails(groupId, peppolDetails);
            if (!isProduction) {
                setTestDetails(copyFromResponse(result))
            }
            else {
                setProductionDetails(copyFromResponse(result));
            }
        }

        // isLoading(true)
        dispatch(setSurveyDataLoading(true));
        updatePeppolDetails()
        .then(() => {
            message.success(getI18n("survey-connectivity-save-contact-success-message", "Connectivity Information saved successfully" , intl));
        })
        .catch(() => {
            dispatch(resetSurveyDataLoading(true));
            message.error(getI18n("survey-connectivity-save-contact-failed-message", "Failed to save connectivity information" , intl));
          })
        .finally(() => {
            // isLoading(false)
            dispatch(setSurveyDataLoading(false));
            if (onStatusUpdate) {
                onStatusUpdate();
            }
        });
    }

    const toggleTestAndProd = (isProductionArg:boolean)=> {
        setIsProduction(isProductionArg);
        if (!isProductionArg) {
            setProductionDetails({
                ...productionDetails,
                receivingPeppolId: form.getFieldValue('receivingID'),
                receivingPeppolIdComment: form.getFieldValue('receivingIDComment'),
                sendingPeppolId: form.getFieldValue('sendingID'),
                sendingPeppolIdComment: form.getFieldValue('sendingIDComment')
            })
            form.setFieldsValue({
                receivingID: testDetails?.receivingPeppolId,
                receivingIDComment: testDetails?.receivingPeppolIdComment,
                sendingID: testDetails?.sendingPeppolId,
                sendingIDComment: testDetails?.sendingPeppolIdComment
            })
        } else {
            setTestDetails({
                ...testDetails,
                receivingPeppolId: form.getFieldValue('receivingID'),
                receivingPeppolIdComment: form.getFieldValue('receivingIDComment'),
                sendingPeppolId: form.getFieldValue('sendingID'),
                sendingPeppolIdComment: form.getFieldValue('sendingIDComment')
            })
            form.setFieldsValue({
                receivingID: productionDetails?.receivingPeppolId,
                receivingIDComment: productionDetails?.receivingPeppolIdComment,
                sendingID: productionDetails?.sendingPeppolId,
                sendingIDComment: productionDetails?.sendingPeppolIdComment
            })
        }
    }

    const modifyReceiveSendIDLabels = (id:string)=> {
        const textWithPlaceHolder = intl.formatMessage({id});
        const PRODUCTION_TEXT = `${intl.formatMessage({id:'survey-connectivityInfo-production-btn-text'})} PEPPOL`;
        const TEST_TEXT = `${intl.formatMessage({id:'survey-connectivityInfo-test-btn-text'})} PEPPOL`;
        return textWithPlaceHolder.replace('_PLACE_HOLDER_',!isProduction ? TEST_TEXT: PRODUCTION_TEXT);
    }

    return <>
        <ConfigProvider locale={localStorage.getItem("locale") === "en" ? enUS : deDe}>
        <div>
            <Typography>
                <Paragraph>{intl.formatMessage({
                    id: "survey-connectivity-peppol-tab-heading",
                })}</Paragraph>
            </Typography>
        </div>

        {/* <Spin spinning={loading} size={"large"}> */}
            <div style={{marginTop:"10px", marginBottom:"10px"}}>
                <label>
                    {intl.formatMessage({
                        id: "survey-connectivityInfo-environment-prompt",
                        defaultMessage: "Do you want to capture the PRODUCTION or TEST environment details?"
                    })}
                </label>
                <Switch className={"ml10"}
                        checkedChildren={intl.formatMessage({
                            id: "survey-connectivityInfo-production-selection"
                        })}
                        unCheckedChildren={intl.formatMessage({
                            id: "survey-as2-test-detail"
                        })}
                        defaultChecked={isProduction}
                        onChange={(value) => toggleTestAndProd(value)} />
            </div>
            <Form
                labelAlign={"left"}
                onFinish={onFinish}
                form={form}
            >
                <div className={styles.peppolLabelWithTwoFieldsContainer}>
                    <span style={{fontWeight:"bold"}}>{intl.formatMessage({id:'survey-peppol-details'})}</span>
                    <span style={{fontWeight:"bold"}}>{intl.formatMessage({id:'survey-peppol-comments'})}</span>
                </div>

                <div className={styles.peppolLabelWithTwoFieldsContainer}>
                    <Form.Item
                        labelCol={{span: 15}}
                        wrapperCol={{span: 16}}
                        label={modifyReceiveSendIDLabels('survey-connectivity-peppol-receiving-id-form-label')}
                        name={'receivingID'}
                        rules={[fetchRule('receivingID', !isProduction)]}>
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        style={{width:"50%"}}
                        name={'receivingIDComment'}>
                        <Input/>
                    </Form.Item>
                </div>

                <div className={styles.peppolLabelWithTwoFieldsContainer}>
                    <Form.Item
                        labelCol={{span: 15}}
                        wrapperCol={{span: 16}}
                        label={modifyReceiveSendIDLabels('survey-connectivity-peppol-sending-id-form-label')}
                        name={'sendingID'}
                        rules={[fetchRule('sendingID', !isProduction)]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        style={{width:"50%"}}
                        name={'sendingIDComment'}>
                        <Input/>
                    </Form.Item>
                </div>
                <div hidden={isReadOnly()}>
                    <Button type="primary" htmlType="submit">
                        {intl.formatMessage({id: "survey-main-general-save-button"})}
                    </Button>
                </div>
            </Form>
        {/* </Spin> */}
        </ConfigProvider>

    </>
}