import React, {FunctionComponent, useEffect, useState} from "react";
import {Button, Card, Col, Form, Input, notification, Row, Spin} from "antd";

import {IAppComponentProps} from "../../components";
import {PageContainer} from "@ant-design/pro-layout";
import client from "../../config/GraphQLApolloClient";
import {QUERY_SURVEY_GOLIVE} from "./queries";
import {useIntl} from "react-intl";
import {getGroupId} from "../../utils";
import {getGoLiveDates_getGoLiveDates} from "./__generated__/getGoLiveDates";
import {StateContainer} from "./utilities/StateContainer";
import Paragraph from "antd/es/typography/Paragraph";
import {convertFromSpecifiedDateFormatToUserDefined} from "../users/UserFormatPreferenceServices";

type ISurveyGoLiveStepProps = {
    prev?: any;
    stateContainer?: StateContainer;
} & IAppComponentProps;

const SurveyGoLiveStep: FunctionComponent<ISurveyGoLiveStepProps> = (props) => {
    const intl = useIntl();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(undefined);
    const [goLiveData, setGoLiveData] = useState<getGoLiveDates_getGoLiveDates>(undefined);

    useEffect(() => {

        const groupId = getGroupId();
        if (goLiveData === undefined) {
            setLoading(true);
            client
                .query({
                    query: QUERY_SURVEY_GOLIVE,
                    variables: {groupId},
                    fetchPolicy: "network-only"
                }).then((response) => {
                // eslint-disable-next-line no-console
                console.dir(response.data.getGoLiveDates);
                setGoLiveData(response.data.getGoLiveDates);
                const formData = {...response.data.getGoLiveDates};
                if (response.data.getGoLiveDates) {
                    if (response.data.getGoLiveDates?.submissionDate?.length>10) {
                        formData.submissionDate =
                            `${convertFromSpecifiedDateFormatToUserDefined(props.currentUser, response.data.getGoLiveDates.submissionDate.substring(0,10),"YYYY-MM-DD")} ${response.data.getGoLiveDates.submissionDate.substring(10)}`;
                    }
                    if (response.data.getGoLiveDates?.confirmedGoLiveDate?.length>10) {
                        formData.confirmedGoLiveDate =
                            `${convertFromSpecifiedDateFormatToUserDefined(props.currentUser, response.data.getGoLiveDates.confirmedGoLiveDate.substring(0,10),"YYYY-MM-DD")} ${response.data.getGoLiveDates.confirmedGoLiveDate.substring(10)}`;
                    }
                }

                form.setFieldsValue(formData);
                setLoading(false);
            })
                .catch((err) => {
                    notification.error(err);
                    setLoading(false);
                })
        }

    }, [goLiveData]);

    return (
        <>
            <h3>{intl.formatMessage({id: 'Welcome-title'})}</h3>
            {
                intl.formatMessage({id: 'go-live-service-provider-contact'}).split('\\n')
                    .map((item, i) => <p key={i} style={{fontWeight:"bold"}}>{item}</p>)
            }

            <Card title="Go-Live" size={"small"} className="ml-10">
                <Spin spinning={loading} size={"large"}>

                    <Row gutter={8}>
                        <Col span={8}>
                            <Form
                                form={form}
                                name="basic"
                                labelCol={{span: 10}}
                                wrapperCol={{span: 16}}
                                labelAlign={"left"}
                                labelWrap={true}
                                initialValues={{remember: true}}
                                onFinish={() => {
                                }}
                                onFinishFailed={() => {
                                }}
                            >
                                <Form.Item
                                    label={intl.formatMessage({
                                        id: "goLive-submissionDate-col",
                                        defaultMessage: "Your submission date is"
                                    })}
                                    name="submissionDate"
                                >
                                    <Input readOnly={true} type="text"/>
                                </Form.Item>
                                {/*             <Form.Item
                  label={intl.formatMessage({
                    id: "goLive-provisionalGoLiveDate-col",
                    defaultMessage: "Your provisional go-live date is"
                    })}
                  name="provisionalGoLiveDate"
              >
                <Input readOnly={true}  type="Text" />
              </Form.Item>*/}
                                <Form.Item
                                    label={intl.formatMessage({
                                        id: "goLive-confirmedGoLiveDate-col",
                                        defaultMessage: "Your confirmed go-live date is"
                                    })}
                                    name="confirmedGoLiveDate"
                                >
                                    <Input readOnly={true} type="Text"/>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                    {/*<Row gutter={8} justify={"end"}>
            <Col xs={24} sm={6} md={4}>
              <Button type="default" size="large" className="w-100 mt10" onClick={props.prev}>
                {props.intl.formatMessage({
                  id: "survey-main-general-previous-button",
                  defaultMessage: "PREVIOUS"
                  })}
              </Button>
            </Col>
          </Row>*/}
                </Spin>
            </Card>
        </>
    );

};

export default SurveyGoLiveStep;
