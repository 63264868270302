import { gql } from "@apollo/client";


export const UPDATE_MULTIPLE_DOWNLOAD_STATUS = gql`
    mutation updateMultipleDownloadStatus($documentDownloadInputs: [DocumentDownloadInput!]!){
        updateMultipleDownloadStatus(documentDownloadInputs: $documentDownloadInputs)
    }
`;

export const UPDATE_SINGLE_DOWNLOAD_STATUS = gql`
    mutation updateSingleDownloadStatus($documentDownloadInput: DocumentDownloadInput!){
        updateSingleDownloadStatus(documentDownloadInput: $documentDownloadInput)
    }
`;
