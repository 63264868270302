import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    Col,
    Collapse,
    ConfigProvider,
    Form,
    notification,
    Row,
    Select,
    Switch,
    Typography,
    message
} from "antd";

import {IAppComponentProps} from "../../../components";
import {useIntl} from "react-intl";
import enUS from "antd/lib/locale/en_US";
import deDe from "antd/lib/locale/de_DE";
import {cleanFormValues, FormItem_VDA_Row, getInitialFormValues} from "./_SurveyComponents";
import {gql, useMutation, useQuery} from "@apollo/client";
import {PARTNER_TYPE, SELECTED_COMPANY_CODE} from "../../../constants";
import {StateContainer} from "../utilities/StateContainer";
import {getSurveyChannelBySurveyId} from "../eDocumentsType/DocumentTypeService";
import {Portal_getSurveyChannels_Portal_getSurveyChannels} from "../__generated__/Portal_getSurveyChannels";
import { MODE } from "../IStoredSurvey";
import { useAppDispatch, useAppSelector } from "../../../main/hooks";
import { resetSurveyDataLoading, setSurveyDataLoading } from "../IStoredSurveySlice";
import { getSurveyConnectivityVDASvc } from "./SurveyConnectivityService";
import { getI18n } from "../../../utils/Utils";


const MUTATE_VDA_DETAILS = gql`
    mutation updateSurveyConnectivityVda($groupId:String!,$surveyConnectivityVDAInput: SurveyConnectivityVDAInput!) {
        updateSurveyConnectivityVda(groupId: $groupId,surveyConnectivityVDAInput: $surveyConnectivityVDAInput) {
            surveyConnectivityId
            surveyId
            environment
            dcInvoiceEdiIdentifier
            dcInvoiceEdiIdentifierComment
            dcInvoiceEdiQualifier
            dcInvoiceEdiQualifierComment
            dcPadEdiIdentifier
            dcPadEdiIdentifierComment
            dcPadEdiQualifier
            dcPadEdiQualifierComment
        }
    }
`;

const {Text} = Typography;
const {Panel} = Collapse;
const {Option} = Select;

type MandatoryFields = {
    isInvoiceIdentifierMandatory: boolean;
    isInvoiceQualifierMandatory: boolean;
    isPadIdentifierMandatory: boolean;
    isPadQualifierMandatory: boolean;
    initialized:boolean;
}

type ISurveyConnectivityInfoVDA = {
    groupId?: any;
    onStatusUpdate?: any;
    stateContainer?: StateContainer;
    surveyMasterData?: any;
} & IAppComponentProps;

export const SurveyConnectivityInfoVDAForm: React.FunctionComponent<ISurveyConnectivityInfoVDA> = (props) => {
    const [form] = Form.useForm();
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const [dataLoaded, isDataLoaded] = useState<boolean>(false);
    const [productionActive, isProductionActive] = useState<boolean>(true);
    const [surveyConnectivityVDAData, setSurveyConnectivityVDAData] = useState([]);
    const [saveVDADetails] = useMutation(MUTATE_VDA_DETAILS);
    const [mandatoryFields, setMandatoryFields] = useState<MandatoryFields>({
        isInvoiceIdentifierMandatory: false,
        isInvoiceQualifierMandatory: false,
        isPadIdentifierMandatory: false,
        isPadQualifierMandatory: false,
        initialized: false
    });

    const surveyState = useAppSelector((state) => state.survey);

    const isViewMode = () => {
      return surveyState.survey.surveyMode === MODE.VIEW ? true : false
    }
  
    const isOverViewMode = () => {
      return surveyState.survey.surveyMode === MODE.OVERVIEW ? true : false
    }
  
  const isReadOnly = () => {
    return isViewMode() || isOverViewMode();
   }
  

    //const {data, error, refetch} = useQuery(FETCH_VDA_DETAILS, {variables: {groupId: props.groupId}});


    const populateForm = () => {
        if (productionActive) {
            const filter = surveyConnectivityVDAData?.filter(item => item.environment === "PRODUCTION");
            form.setFieldsValue(filter?.length > 0 && filter[0]);
        } else {
            const filter = surveyConnectivityVDAData?.filter(item => item.environment === "TEST");
            form.setFieldsValue(filter?.length > 0 && filter[0]);
        }

    };

    useEffect(()=> {
        const getSurveyChannels = async () => {

            const promises = props.surveyMasterData.map(item => getSurveyChannelBySurveyId(Number(item.surveySummaryRecord.surveyId), localStorage.getItem(PARTNER_TYPE), localStorage.getItem(SELECTED_COMPANY_CODE), intl));
            return Promise.all(promises).then(allSurveyResults => {
                const allSurveyChannels = [];
                for (const surveyChannels of allSurveyResults) {
                    allSurveyChannels.push(...surveyChannels);
                }

                return allSurveyChannels;
            })
        }

        const mapSurveyChannelsToMandatoryObject = (surveyChannels:Portal_getSurveyChannels_Portal_getSurveyChannels[])=> {
            let paymentAdviceHasVDA = false;
            let incomingDocsHaveVDA = false;
            let outgoingDocsHaveVDA = false;
            for (const surveyChannel of surveyChannels) {
                if (!surveyChannel.processCode || ! surveyChannel.format) {
                    continue;
                }

                if (surveyChannel.processCode === 'PAD') {
                    paymentAdviceHasVDA = paymentAdviceHasVDA || surveyChannel.format.indexOf('VDA') >= 0;
                }
                else if (surveyChannel.processCode.match(/(INC|ECAP)/)) {
                    incomingDocsHaveVDA = incomingDocsHaveVDA || surveyChannel.format.indexOf('VDA') >= 0;
                }
                else {
                    outgoingDocsHaveVDA = outgoingDocsHaveVDA || surveyChannel.format.indexOf('VDA') >= 0;
                }
            }

            return {
                isInvoiceIdentifierMandatory: incomingDocsHaveVDA || outgoingDocsHaveVDA,
                isInvoiceQualifierMandatory: incomingDocsHaveVDA || outgoingDocsHaveVDA,
                isPadIdentifierMandatory: paymentAdviceHasVDA,
                isPadQualifierMandatory: paymentAdviceHasVDA,
                initialized:true
            }
        };

        getSurveyChannels()
            .then(mapSurveyChannelsToMandatoryObject)
            .then(setMandatoryFields);
    },[])

    const loadVDAData = () => {
        dispatch(setSurveyDataLoading(true));
        getSurveyConnectivityVDASvc(props.groupId).then(response => {
            isDataLoaded(true);
            setSurveyConnectivityVDAData(response?.getSurveyConnectivityVda);
            if (response?.getSurveyConnectivityVda.length > 0) {
                const initialValues = getInitialFormValues(response?.getSurveyConnectivityVda, "environment");
                form.setFieldsValue(initialValues);
            }
        })
        .catch(() => {
            dispatch(resetSurveyDataLoading(true));
        })
        .finally(() => {
            dispatch(setSurveyDataLoading(false));
        });
    }

    useEffect(
        () => {
            // isLoading(true);
            if (!dataLoaded)
            {
                loadVDAData();
            }

            // const onCompleted = data => {
            //     // isLoading(false)
            //     isDataLoaded(true);
            //     dispatch(setSurveyDataLoading(false));
            //     setSurveyConnectivityVDAData(data?.getSurveyConnectivityVda);
            //     if (data?.getSurveyConnectivityVda.length > 0) {
            //         const initialValues = getInitialFormValues(data?.getSurveyConnectivityVda, "environment");
            //         form.setFieldsValue(initialValues);
            //     }
            // };
            // if (onCompleted) {
            //     if (onCompleted && !dataLoaded && !error) {
            //         onCompleted(data);
            //     }
            // }

        },
        [dataLoaded]
    )
    ;

    const onFinish = (data) => {
        dispatch(setSurveyDataLoading(true));
        // isLoading(true);
        const cleanValues = cleanFormValues(data, productionActive ? "PRODUCTION" : "TEST");
        saveVDADetails({
            variables: {
                groupId: props.groupId,
                surveyConnectivityVDAInput: {
                    environment: productionActive ? "PRODUCTION" : "TEST",
                    ...cleanValues
                }
            }
        })
            .then(response => {
                loadVDAData();
                // refetch({groupId: props.groupId}).then(results => {
                //     if (results?.data.getSurveyConnectivityVda.length > 0) {
                //         const initialValues = getInitialFormValues(results?.data.getSurveyConnectivityVda, "environment");
                //         form.setFieldsValue(initialValues);
                //     }
                // });
                // isLoading(false);
                props.onStatusUpdate();
                message.success(getI18n("survey-connectivity-save-contact-success-message", "Connectivity Information saved successfully" , intl));
                })
            .catch(error => {
                // isLoading(false);
                dispatch(resetSurveyDataLoading(true));
                console.error(error);
                message.error(getI18n("survey-connectivity-save-contact-failed-message", "Failed to save connectivity information" , intl));
            }).finally(() => {
                populateForm();
                dispatch(setSurveyDataLoading(false));
            });
    };

    const productionVDAHeaderDetails = [
        "",
        "",
        intl.formatMessage({
            id: "survey-connectivityInfo-vda-header-production-comment",
            defaultMessage: "Comments"
        }),
        intl.formatMessage({
            id: "survey-connectivityInfo-vda-header-production-instruction",
            defaultMessage: "Instruction"
        })
    ];

    const ediIdentifierProd = intl.formatMessage({
        id: "survey-connectivityInfo-vda-prod-invoice-edi-identifier",
        defaultMessage: "Invoice PRODUCTION EDI identifier"
    });
    const ediIdentifierTest = intl.formatMessage({
        id: "survey-connectivityInfo-vda-test-invoice-edi-identifier",
        defaultMessage: "Invoice TEST EDI identifier"
    });

    const ediInstructionProd = intl.formatMessage({
        id: "survey-connectivityInfo-vda-prod-invoice-edi-identifier-instruction",
        defaultMessage: "Capture INVOICE PROD ID"
    });
    const ediInstructionTest = intl.formatMessage({
        id: "survey-connectivityInfo-vda-test-invoice-edi-identifier-instruction",
        defaultMessage: "Capture INVOICE TEST ID"
    });

    const vdaInvoiceEdiQualifierProd = intl.formatMessage({
        id: "survey-connectivityInfo-vda-prod-invoice-edi-qualifier",
        defaultMessage: "Invoice PRODUCTION EDI qualifier"
    });

    const vdaInvoiceEdiQualifierTest = intl.formatMessage({
        id: "survey-connectivityInfo-vda-test-invoice-edi-qualifier",
        defaultMessage: "Invoice TEST EDI qualifier"
    });

    const vdaInvoiceEdiQualifierInstructionProd = intl.formatMessage({
        id: "survey-connectivityInfo-vda-prod-invoice-edi-qualifier-instruction",
        defaultMessage: "Capture INVOICE PROD QUALIFIER"
    });
    const vdaInvoiceEdiQualifierInstructionTest = intl.formatMessage({
        id: "survey-connectivityInfo-vda-test-invoice-edi-qualifier-instruction",
        defaultMessage: "Capture INVOICE TEST QUALIFIER"
    });

    const vdaPaymentAdviceEdiIdentifierProd = intl.formatMessage({
        id: "survey-connectivityInfo-vda-prod-payment-advice-edi-identifier",
        defaultMessage: "Payment advice PRODUCTION  EDI identifier"
    });
    const vdaPaymentAdviceEdiIdentifierTest = intl.formatMessage({
        id: "survey-connectivityInfo-vda-test-payment-advice-edi-identifier",
        defaultMessage: "Payment advice TEST EDI identifier"
    });

    const vdaPaymentAdviceEdiIdentifierInstructionProd =
        intl.formatMessage({
            id: "survey-connectivityInfo-vda-prod-payment-advice-edi-identifier-instruction",
            defaultMessage: "Capture PAD PROD ID"
        });
    const vdaPaymentAdviceEdiIdentifierInstructionTest =
        intl.formatMessage({
            id: "survey-connectivityInfo-vda-test-payment-advice-edi-identifier-instruction",
            defaultMessage: "Capture PAD TEST ID"
        });
    const vdaPaymentAdviceEdiQualifierProd =
        intl.formatMessage({
            id: "survey-connectivityInfo-vda-prod-payment-advice-edi-qualifier",
            defaultMessage: "Payment advice PRODUCTION EDI qualifier"
        });
    const vdaPaymentAdviceEdiQualifierTest = intl.formatMessage({
        id: "survey-connectivityInfo-vda-test-payment-advice-edi-qualifier",
        defaultMessage: "Payment advice TEST EDI qualifier"
    });
    const vdaPaymentAdviceEdiQualifierInstructionProd =
        intl.formatMessage({
            id: "survey-connectivityInfo-vda-prod-payment-advice-edi-qualifier-instruction",
            defaultMessage: "Capture PAD PROD QUALIFIER"
        });
    const vdaPaymentAdviceEdiQualifierInstructionTest = intl.formatMessage({
        id: "survey-connectivityInfo-vda-test-payment-advice-edi-qualifier-instruction",
        defaultMessage: "Capture PAD TEST QUALIFIER"
    });


    function resetForm() {
        form.resetFields();
    }

    const setFormData = (production: boolean) => {
        const prodVDAData = surveyConnectivityVDAData?.filter(item => item.environment === "PRODUCTION");
        const testVDAData = surveyConnectivityVDAData?.filter(item => item.environment === "TEST");


        if (prodVDAData?.length > 0 && production) {
            form.setFieldsValue(prodVDAData[0]);
        } else if (prodVDAData?.length === 0 && production) {
            resetForm();
        }

        if (testVDAData?.length > 0 && !production) {
            form.setFieldsValue(testVDAData[0]);
        } else if (testVDAData?.length === 0 && !production) {
            resetForm();
        }
    };


    return (
        <>
            <ConfigProvider locale={localStorage.getItem("locale") === "en" ? enUS : deDe}>
                {/* <Spin spinning={loading || !mandatoryFields.initialized} size={"large"}> */}
                    <Row>
                        <Col span={24}>
                            <Text>{intl.formatMessage({
                                id: "survey-connectivityInfo-vda-descriptionLine1",
                                defaultMessage: "You have selected VDA as the communication channel."
                            })}</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Text>{intl.formatMessage({
                                id: "survey-connectivityInfo-vda-descriptionLine2",
                                defaultMessage: "Please provide the required information for VDA connectivity between you and our eInvoice service provider:"
                            })}</Text>
                        </Col>
                    </Row>
                    <Row>
                        <div>
                            <label>
                                {intl.formatMessage({
                                    id: "survey-connectivityInfo-environment-prompt",
                                    defaultMessage: "Do you want to capture the PRODUCTION or TEST environment details?"
                                })}
                            </label>
                            <Switch className={"ml10"}
                                    checkedChildren={intl.formatMessage({
                                        id: "survey-connectivityInfo-production-selection",
                                        defaultMessage: "Production Detail"
                                    })}
                                    unCheckedChildren={intl.formatMessage({
                                        id: "survey-as2-test-detail",
                                        defaultMessage: "Test Detail"
                                    })}
                                    defaultChecked={true}
                                    onChange={(value) => {
                                        isProductionActive(value);
                                        setFormData(value);
                                    }}/>
                        </div>
                    </Row>

                    <Form
                        form={form}
                        labelWrap={true}
                        labelAlign={"left"}
                        onFinish={onFinish}

                    >
                        <Card size={"small"} title={"VDA Details"}>

                            <Row>
                                <Col span={24}>
                                    <table className="form--table"
                                            style={{tableLayout: "fixed", width: "100%"}}>
                                        <thead>

                                        {productionVDAHeaderDetails.map(item => <th key={item}>{item} </th>)}

                                        </thead>
                                        <tbody>
                                        <FormItem_VDA_Row isProduction={productionActive}
                                                          label={productionActive ? ediIdentifierProd : ediIdentifierTest}
                                                          instruction={productionActive ? ediInstructionProd : ediInstructionTest}
                                                          name="dcInvoiceEdiIdentifier" required={mandatoryFields.isInvoiceIdentifierMandatory}
                                                          requiredMessage={intl.formatMessage({
                                                              id: "generic-requiredField-message",
                                                              defaultMessage: "Please enter required field"
                                                          })}
                                                          type="text"/>

                                        <FormItem_VDA_Row isProduction={productionActive}
                                                          label={productionActive ? vdaInvoiceEdiQualifierProd : vdaInvoiceEdiQualifierTest}
                                                          instruction={productionActive ? vdaInvoiceEdiQualifierInstructionProd : vdaInvoiceEdiQualifierInstructionTest}
                                                          name="dcInvoiceEdiQualifier"
                                                          required={mandatoryFields.isInvoiceQualifierMandatory}
                                                          requiredMessage={intl.formatMessage({
                                                              id: "generic-requiredField-message",
                                                              defaultMessage: "Please enter required field"
                                                          })}
                                                          type="text"/>

                                        {localStorage.getItem(PARTNER_TYPE) === "V" &&
                                            <FormItem_VDA_Row isProduction={productionActive}
                                                              label={productionActive ? vdaPaymentAdviceEdiIdentifierProd : vdaPaymentAdviceEdiIdentifierTest}
                                                              instruction={productionActive ? vdaPaymentAdviceEdiIdentifierInstructionProd : vdaPaymentAdviceEdiIdentifierInstructionTest}
                                                              name="dcPadEdiIdentifier" required={mandatoryFields.isPadIdentifierMandatory}
                                                              requiredMessage={intl.formatMessage({
                                                                  id: "generic-requiredField-message",
                                                                  defaultMessage: "Please enter required field"
                                                              })}
                                                              type="text"/>
                                        }
                                        {localStorage.getItem(PARTNER_TYPE) === "V" &&
                                            <FormItem_VDA_Row isProduction={productionActive}
                                                              label={productionActive ? vdaPaymentAdviceEdiQualifierProd : vdaPaymentAdviceEdiQualifierTest}
                                                              instruction={productionActive ? vdaPaymentAdviceEdiQualifierInstructionProd : vdaPaymentAdviceEdiQualifierInstructionTest}
                                                              name="dcPadEdiQualifier"
                                                              required={mandatoryFields.isPadQualifierMandatory} requiredMessage={intl.formatMessage({
                                                id: "generic-requiredField-message",
                                                defaultMessage: "Please enter required field"
                                            })} type="text"/>
                                        }
                                        <tr>
                                            <td colSpan={3}>
                                                <Form.Item wrapperCol={{offset: 8, span: 16}}>
                                                    <Button type="primary" htmlType="submit" disabled={isReadOnly()}>
                                                        {intl.formatMessage({
                                                            id: "survey-connectivityInfo-vda-button-save",
                                                            defaultMessage: "SAVE VDA DETAILS"
                                                        })}
                                                    </Button>
                                                </Form.Item>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Card>
                    </Form>
                {/* </Spin> */}
            </ConfigProvider>

        </>
    );
};


export default SurveyConnectivityInfoVDAForm;