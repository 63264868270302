import { gql } from "@apollo/client";


export const REMOVE_SELECTED_PARTNER_TO_SURVEY_PROCESS = gql`
    mutation Portal_RemoveSurvey($surveyIds: [SurveyMasterDataType!]!){
        Portal_RemoveSurvey(surveyIds: $surveyIds)
    }
`;

export const ADD_SELECTED_PARTNER_TO_SURVEY_PROCESS = gql`
    query Portal_AddSurveyData($surveyIds: [SurveyMasterDataType!]!,$groupId: String!){
        Portal_AddSurveyData(surveyIds: $surveyIds, groupId: $groupId) {
            surveyId
            surveyStatus
            surveyStatusPhraseId
            surveySentDate
            insertTime
            updateTime
            surveyStartDate
            assignedPartnerUserId
            assignedPartnerUser
            partnerNo
            partnerName
            companyCode
            categoryDescription
            categoryPhraseId
            groupId
            masterDataAcceptedDate
            agreementTypeId
            email
            partnerId
            masterDataStatusPhraseId
            surveyVolume
            partnerVolume
            includedSurveyIds
            includedPartnerIds
        }
    }
`;

export const GET_SELECTED_PARTNER_TO_SURVEY_PROCESS = gql`
    query Portal_GetSurveyData($surveyIds: [SurveyMasterDataType!]!,$groupId: String!){
        Portal_GetSurveyData(surveyIds: $surveyIds, groupId: $groupId) {
            surveyId
            surveyStatus
            surveyStatusPhraseId
            surveySentDate
            insertTime
            updateTime
            surveyStartDate
            assignedPartnerUserId
            assignedPartnerUser
            partnerNo
            partnerName
            companyCode
            categoryDescription
            categoryPhraseId
            groupId
            masterDataAcceptedDate
            agreementTypeId
            email
            partnerId
            masterDataStatusPhraseId
            surveyVolume
            partnerVolume
            includedSurveyIds
            includedPartnerIds
        }
    }
`


export const GET_SURVEY_PARTNER_MASTER_DATA_BY_GROUP_ID = gql`
    query Portal_GetSurveyPartnerMasterData($groupId: String!){
        Portal_GetSurveyPartnerMasterData(groupId: $groupId) {
            partnerRecord{
                partnerAddressesList {
                    partnerNo
                    partnerName
                    city
                    countryCode
                    pobox
                    postalCode
                    postalcodePobox
                    street1
                    street2
                    updateTime
                }
                partnerVatCodes{
                    country
                    partnerName
                    partnerNo
                    updateTime
                    vatCode
                }
                taxId
            }
            surveySummaryRecord{
                agreementTypeId
                assignedPartnerUser
                assignedPartnerUserId
                categoryDescription
                categoryPhraseId
                companyCode
                email
                groupId
                masterDataAcceptedDate
                partnerId
                partnerName
                partnerNo
                surveySentDate
                insertTime
                updateTime
                surveyId
                surveyStartDate
                surveyStatus
                surveyStatusPhraseId
                masterDataStatusPhraseId
                surveyVolume
                partnerVolume
                includedSurveyIds
                includedPartnerIds
            }
        }
    }
`;


export const GET_SURVEY_PARTNER_ACCEPT_MASTER_DATA = gql`
    mutation Portal_AcceptMasterData($surveyIds: [SurveyMasterStatusInput!]!){
        Portal_AcceptMasterData(surveyIds: $surveyIds)
    }
`;

export const GET_SURVEY_PARTNER_CONTACT_MASTER_DATA_BY_GROUP_ID = gql`
    query Portal_GetSurveyContactMasterData($groupId: String!){
        Portal_GetSurveyContactMasterData(groupId: $groupId) {
            assignedPartnerUserId
            b2bUserHasAccessToPartner
            companyCode
            contactUserId
            email
            firstName
            groupId
            hasB2BUser
            hasEdocUser
            lastName
            partnerNo
            responsibleUser
            user_Role
        }
    }
`;