import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateEntitlementMutationVariables = Types.Exact<{
  entitlementRule: Types.EntitlementRuleInput;
}>;


export type UpdateEntitlementMutation = { __typename?: 'Mutation', updateEntitlement: { __typename?: 'EntitlementReportDetail', ruleId?: number | null, allowedOrBlocked?: string | null, company?: string | null, partner?: string | null, b2xRole?: string | null, department?: string | null, enterpriseRole?: string | null, objectClass?: string | null, otherEntitlementsOnRule?: string | null, ruleDescription?: string | null, userId?: string | null, groupIds?: Array<string> | null, insertTime?: string | null, updateTime?: string | null, insertUser?: string | null, updateUser?: string | null } };

export type UpdateEntitlementTypeMutationVariables = Types.Exact<{
  entitlementTypes: Array<Types.EntitlementInput> | Types.EntitlementInput;
}>;


export type UpdateEntitlementTypeMutation = { __typename?: 'Mutation', updateEntitlementType: Array<{ __typename?: 'Entitlement', entitlement?: string | null, description?: string | null, phraseId?: string | null, insertTime?: string | null, insertUser?: string | null, updateTime?: string | null, updateUser?: string | null, groupId?: string | null, mode?: string | null }> };

export type UpdateEntitlementObjectClassMutationVariables = Types.Exact<{
  objectClasses: Array<Types.ObjectClassInput> | Types.ObjectClassInput;
}>;


export type UpdateEntitlementObjectClassMutation = { __typename?: 'Mutation', updateEntitlementObjectClass: Array<{ __typename?: 'ObjectClass', userObjectClass?: string | null, description?: string | null, phraseId?: string | null, insertTime?: string | null, insertUser?: string | null, updateTime?: string | null, updateUser?: string | null, updateAction?: string | null, volume?: number | null }> };

export type UpdateEntitlementB2XRolesMutationVariables = Types.Exact<{
  b2xRoles: Array<Types.B2xRolesInput> | Types.B2xRolesInput;
}>;


export type UpdateEntitlementB2XRolesMutation = { __typename?: 'Mutation', updateEntitlementB2XRoles: Array<{ __typename?: 'B2xRoles', userB2xrole?: string | null, description?: string | null, phraseId?: string | null, insertTime?: string | null, insertUser?: string | null, updateTime?: string | null, updateUser?: string | null, updateAction?: string | null, volume?: number | null }> };

export type UpdateEntitlementGroupsMutationVariables = Types.Exact<{
  entitlementGroups: Array<Types.EntitlementGroupInput> | Types.EntitlementGroupInput;
}>;


export type UpdateEntitlementGroupsMutation = { __typename?: 'Mutation', updateEntitlementGroups: Array<{ __typename?: 'EntitlementGroup', groupId?: string | null, description?: string | null, phraseId?: string | null, insertTime?: string | null, insertUser?: string | null, updateTime?: string | null, updateUser?: string | null, updateAction?: string | null, volume?: number | null }> };


export const UpdateEntitlementDocument = gql`
    mutation updateEntitlement($entitlementRule: EntitlementRuleInput!) {
  updateEntitlement(entitlementRule: $entitlementRule) {
    ruleId
    allowedOrBlocked
    company
    partner
    b2xRole
    department
    enterpriseRole
    objectClass
    otherEntitlementsOnRule
    ruleDescription
    userId
    groupIds
    insertTime
    updateTime
    insertUser
    updateUser
  }
}
    `;
export type UpdateEntitlementMutationFn = Apollo.MutationFunction<UpdateEntitlementMutation, UpdateEntitlementMutationVariables>;

/**
 * __useUpdateEntitlementMutation__
 *
 * To run a mutation, you first call `useUpdateEntitlementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntitlementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntitlementMutation, { data, loading, error }] = useUpdateEntitlementMutation({
 *   variables: {
 *      entitlementRule: // value for 'entitlementRule'
 *   },
 * });
 */
export function useUpdateEntitlementMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEntitlementMutation, UpdateEntitlementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEntitlementMutation, UpdateEntitlementMutationVariables>(UpdateEntitlementDocument, options);
      }
export type UpdateEntitlementMutationHookResult = ReturnType<typeof useUpdateEntitlementMutation>;
export type UpdateEntitlementMutationResult = Apollo.MutationResult<UpdateEntitlementMutation>;
export type UpdateEntitlementMutationOptions = Apollo.BaseMutationOptions<UpdateEntitlementMutation, UpdateEntitlementMutationVariables>;
export const UpdateEntitlementTypeDocument = gql`
    mutation updateEntitlementType($entitlementTypes: [EntitlementInput!]!) {
  updateEntitlementType(entitlementTypes: $entitlementTypes) {
    entitlement
    description
    phraseId
    insertTime
    insertUser
    updateTime
    updateUser
    groupId
    mode
  }
}
    `;
export type UpdateEntitlementTypeMutationFn = Apollo.MutationFunction<UpdateEntitlementTypeMutation, UpdateEntitlementTypeMutationVariables>;

/**
 * __useUpdateEntitlementTypeMutation__
 *
 * To run a mutation, you first call `useUpdateEntitlementTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntitlementTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntitlementTypeMutation, { data, loading, error }] = useUpdateEntitlementTypeMutation({
 *   variables: {
 *      entitlementTypes: // value for 'entitlementTypes'
 *   },
 * });
 */
export function useUpdateEntitlementTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEntitlementTypeMutation, UpdateEntitlementTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEntitlementTypeMutation, UpdateEntitlementTypeMutationVariables>(UpdateEntitlementTypeDocument, options);
      }
export type UpdateEntitlementTypeMutationHookResult = ReturnType<typeof useUpdateEntitlementTypeMutation>;
export type UpdateEntitlementTypeMutationResult = Apollo.MutationResult<UpdateEntitlementTypeMutation>;
export type UpdateEntitlementTypeMutationOptions = Apollo.BaseMutationOptions<UpdateEntitlementTypeMutation, UpdateEntitlementTypeMutationVariables>;
export const UpdateEntitlementObjectClassDocument = gql`
    mutation updateEntitlementObjectClass($objectClasses: [ObjectClassInput!]!) {
  updateEntitlementObjectClass(objectClasses: $objectClasses) {
    userObjectClass
    description
    phraseId
    insertTime
    insertUser
    updateTime
    updateUser
    updateAction
    volume
  }
}
    `;
export type UpdateEntitlementObjectClassMutationFn = Apollo.MutationFunction<UpdateEntitlementObjectClassMutation, UpdateEntitlementObjectClassMutationVariables>;

/**
 * __useUpdateEntitlementObjectClassMutation__
 *
 * To run a mutation, you first call `useUpdateEntitlementObjectClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntitlementObjectClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntitlementObjectClassMutation, { data, loading, error }] = useUpdateEntitlementObjectClassMutation({
 *   variables: {
 *      objectClasses: // value for 'objectClasses'
 *   },
 * });
 */
export function useUpdateEntitlementObjectClassMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEntitlementObjectClassMutation, UpdateEntitlementObjectClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEntitlementObjectClassMutation, UpdateEntitlementObjectClassMutationVariables>(UpdateEntitlementObjectClassDocument, options);
      }
export type UpdateEntitlementObjectClassMutationHookResult = ReturnType<typeof useUpdateEntitlementObjectClassMutation>;
export type UpdateEntitlementObjectClassMutationResult = Apollo.MutationResult<UpdateEntitlementObjectClassMutation>;
export type UpdateEntitlementObjectClassMutationOptions = Apollo.BaseMutationOptions<UpdateEntitlementObjectClassMutation, UpdateEntitlementObjectClassMutationVariables>;
export const UpdateEntitlementB2XRolesDocument = gql`
    mutation updateEntitlementB2XRoles($b2xRoles: [B2xRolesInput!]!) {
  updateEntitlementB2XRoles(b2xRoles: $b2xRoles) {
    userB2xrole
    description
    phraseId
    insertTime
    insertUser
    updateTime
    updateUser
    updateAction
    volume
  }
}
    `;
export type UpdateEntitlementB2XRolesMutationFn = Apollo.MutationFunction<UpdateEntitlementB2XRolesMutation, UpdateEntitlementB2XRolesMutationVariables>;

/**
 * __useUpdateEntitlementB2XRolesMutation__
 *
 * To run a mutation, you first call `useUpdateEntitlementB2XRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntitlementB2XRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntitlementB2XRolesMutation, { data, loading, error }] = useUpdateEntitlementB2XRolesMutation({
 *   variables: {
 *      b2xRoles: // value for 'b2xRoles'
 *   },
 * });
 */
export function useUpdateEntitlementB2XRolesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEntitlementB2XRolesMutation, UpdateEntitlementB2XRolesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEntitlementB2XRolesMutation, UpdateEntitlementB2XRolesMutationVariables>(UpdateEntitlementB2XRolesDocument, options);
      }
export type UpdateEntitlementB2XRolesMutationHookResult = ReturnType<typeof useUpdateEntitlementB2XRolesMutation>;
export type UpdateEntitlementB2XRolesMutationResult = Apollo.MutationResult<UpdateEntitlementB2XRolesMutation>;
export type UpdateEntitlementB2XRolesMutationOptions = Apollo.BaseMutationOptions<UpdateEntitlementB2XRolesMutation, UpdateEntitlementB2XRolesMutationVariables>;
export const UpdateEntitlementGroupsDocument = gql`
    mutation updateEntitlementGroups($entitlementGroups: [EntitlementGroupInput!]!) {
  updateEntitlementGroups(entitlementGroups: $entitlementGroups) {
    groupId
    description
    phraseId
    insertTime
    insertUser
    updateTime
    updateUser
    updateAction
    volume
  }
}
    `;
export type UpdateEntitlementGroupsMutationFn = Apollo.MutationFunction<UpdateEntitlementGroupsMutation, UpdateEntitlementGroupsMutationVariables>;

/**
 * __useUpdateEntitlementGroupsMutation__
 *
 * To run a mutation, you first call `useUpdateEntitlementGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntitlementGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntitlementGroupsMutation, { data, loading, error }] = useUpdateEntitlementGroupsMutation({
 *   variables: {
 *      entitlementGroups: // value for 'entitlementGroups'
 *   },
 * });
 */
export function useUpdateEntitlementGroupsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEntitlementGroupsMutation, UpdateEntitlementGroupsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEntitlementGroupsMutation, UpdateEntitlementGroupsMutationVariables>(UpdateEntitlementGroupsDocument, options);
      }
export type UpdateEntitlementGroupsMutationHookResult = ReturnType<typeof useUpdateEntitlementGroupsMutation>;
export type UpdateEntitlementGroupsMutationResult = Apollo.MutationResult<UpdateEntitlementGroupsMutation>;
export type UpdateEntitlementGroupsMutationOptions = Apollo.BaseMutationOptions<UpdateEntitlementGroupsMutation, UpdateEntitlementGroupsMutationVariables>;