import React, { FunctionComponent, useState } from "react";
import { Button,  Col, Row, Tabs, Form, Input, Typography, Spin, message,  } from "antd";
import { IAppComponentProps } from "../../../components";
import { LDAP_PARTNER_BY_PARTNERNO, LDAP_USER_BY_USERID } from "./queries";
import client from "../../../config/GraphQLApolloClient";
import { entitlementLookup, filteredEntitlementLookup } from "../../../main/entitlementService";
import { getUserEntitlementResultByUserId_getUserEntitlementResultByUserId } from "./__generated__/getUserEntitlementResultByUserId";
import { getUserEntitlementResultByUserIdAndFilter_getUserEntitlementResultByUserIdAndFilter } from "./__generated__/getUserEntitlementResultByUserIdAndFilter";

const { Text } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;

function EntitlementPartnerElement(props: any): JSX.Element {
    return (
        <>
            <Row>
                <Col span={4}>{props.partner.partnerNo}</Col>
                <Col span={4}>{props.partner.companies?.map(c => c.companyCode).join(', ')}</Col>
                <Col span={8}>{props.partner.partnerName}</Col>
                <Col span={8}>{props.partner.entitlements?.join(', ')}</Col>
            </Row>
        </>
    )
}

function EntitlementElement(props: any): JSX.Element {
    const [partnerName, setPartnerName] = useState("");
    if (props.entitlement === null || props.entitlement === undefined) {
        return (
            <>
            </>
        )
    }    
    return (
        <>
            <Row>
                <Col span={4}><label>Partner name</label></Col>
                <Col span={10}>
                    <Input 
                    type={"text"} 
                    value={partnerName}
                    onChange={(e) => setPartnerName(e.target.value)}
                    />                  
                </Col>
            </Row>            
            <Row>
                <Col span={4}><Text strong={true}>Entitlements:</Text></Col>
                <Col span={20}>
                    {props.entitlement.distinctEntitlements.map(entitlement => (
                        <Button key={entitlement}
                            onClick={() => props.onClick(props.user.uid, entitlement, partnerName)}
                        >{entitlement}</Button>
                    ))}
                </Col>
            </Row>
            {/* <h4>Partner details</h4>
            <Col span={24}>
                <Row>
                    <Col span={4}><Text strong={true}>Partner No</Text></Col>
                    <Col span={4}><Text strong={true}>Partner Companies</Text></Col>
                    <Col span={8}><Text strong={true}>Partner Name</Text></Col>
                    <Col span={8}><Text strong={true}>Entitlements</Text></Col>
                </Row>
            </Col>
            <Col span={24}>
                {props.entitlement.partners.map(partner => (
                    <EntitlementPartnerElement partner={partner} key={partner.partnerOu} />
                ))}
            </Col> */}

        </>
    )
}

function UserElement(props: any): JSX.Element {
    if (props.user === null || props.user === undefined) {
        return (
            <Text strong={true}>NOT FOUND</Text>
        )
    }
    return (
        <>
            <Row>
                <Col span={4}><Text strong={true}>Login Name:</Text></Col>
                <Col span={4}>{props.user.loginName}</Col>
                <Col span={4}><Text strong={true}>Mail:</Text></Col>
                <Col span={8}>{props.user.mail}</Col>
            </Row>
            <Row>
                <Col span={4}><Text strong={true}>Name:</Text></Col>
                <Col span={4}>{props.user.givenName}</Col>
                <Col span={4}><Text strong={true}>Surname:</Text></Col>
                <Col span={8}>{props.user.sn}</Col>
            </Row>
            <Row>
                <Col span={4}><Text strong={true}>Department Number:</Text></Col>
                <Col span={4}>{props.user.departmentNumber}</Col>
                <Col span={4}><Text strong={true}>Internal User:</Text></Col>
                <Col span={8}>{props.user.internalUser ? 'true' : 'false'}</Col>
            </Row>
            <Row>
                <Col span={4}><Text strong={true}>Object Class:</Text></Col>
                <Col span={20}>{props.user.objectClass?.join(', ')}</Col>
            </Row>
            <Row>
                <Col span={4}><Text strong={true}>Permission Role:</Text></Col>
                <Col span={20}>{props.user.permissionrole?.join(', ')}</Col>
            </Row>
        </>
    )
}

function PartnerUserElement(props: any): JSX.Element {
    return (
        <>
            <Row>
                <Col span={8}>{props.user.givenName}</Col>
                <Col span={8}>{props.user.sn}</Col>
                <Col span={8}>{props.user.mail}</Col>
            </Row>
        </>
    );

}

function PartnerElement(props: any): JSX.Element {
    return (
        <>
            <h3>Partner Details</h3>
            <Row>
                <Col span={6}><Text>Partner Name</Text></Col>
                <Col span={6}><Text>{props.partner.partnerName}</Text></Col>
            </Row>
            <Row>
                <Col span={6}><Text>Telephone</Text></Col>
                <Col span={6}><Text>{props.partner.telephoneNumber}</Text></Col>
            </Row>
            <Row>
                <Col span={6}><Text>Partner Name</Text></Col>
                <Col span={6}><Text>{props.partner.partnerName}</Text></Col>
            </Row>
            <Row>
                <Col span={6}><Text>Currency</Text></Col>
                <Col span={6}><Text>{props.partner.currency}</Text></Col>
            </Row>
            <Row>
                <Col span={6}><Text>Country Code</Text></Col>
                <Col span={6}><Text>{props.partner.countryCodePlate}</Text></Col>
            </Row>
            <h3>Master Partner Admins</h3>
            <Row>
                <Col span={8}><Text strong={true}>Name</Text></Col>
                <Col span={8}><Text strong={true}>Surname</Text></Col>
                <Col span={8}><Text strong={true}>Mail</Text></Col>
            </Row>
            {props.partner.masterAdminUsers.map(user => (
                <PartnerUserElement user={user} key={user.uid} />
            ))}
            <h3>Master Partner Admin Deputies</h3>
            <Row>
                <Col span={8}><Text strong={true}>Name</Text></Col>
                <Col span={8}><Text strong={true}>Surname</Text></Col>
                <Col span={8}><Text strong={true}>Mail</Text></Col>
            </Row>
            {props.partner.masterAdminDeputyUsers.map(user => (
                <PartnerUserElement user={user} key={user.uid} />
            ))}
            <h3>Local Partner Admins</h3>
            <Row>
                <Col span={8}><Text strong={true}>Name</Text></Col>
                <Col span={8}><Text strong={true}>Surname</Text></Col>
                <Col span={8}><Text strong={true}>Mail</Text></Col>
            </Row>
            {props.partner.localAdminUsers.map(user => (
                <PartnerUserElement user={user} key={`${user.uid}admin`} />
            ))}
        </>
    );

}

const LookupUser: FunctionComponent<IAppComponentProps> = (props) => {

    const [partnerDetail, setPartnerDetail] = useState([]);
    const [partnerEmails, setPartnerEmails] = useState([]);
    const [userDetail, setUserDetail] = useState();
    const [entitlementDetail, setEntitlementDetail] = useState<getUserEntitlementResultByUserId_getUserEntitlementResultByUserId>();
    const [filteredEntitlementDetail, setFilteredEntitlementDetail] = useState<getUserEntitlementResultByUserIdAndFilter_getUserEntitlementResultByUserIdAndFilter>();
    const [userDetailLoading, setUserDetailLoading] = useState(false);
    const [entitlementFilterError, setEntitlementFilterError] = useState("");



    const onPartnerSearch = (values: any) => {
        client.query({
            fetchPolicy: "network-only",
            query: LDAP_PARTNER_BY_PARTNERNO,
            variables: { partnerNo: values.partnerNo, authentication: localStorage.getItem('id_token') }
        })
            .then((response) => {
                setPartnerDetail(response.data.getLdapPartnerResultByPartnerNo);
            })
            .catch((err) => console.error(err))
            ;
    }

    const onMultiParterSearch = (values: any) => {        
        const partnerNumbers = values.partnerList.split('\n');
        let remaining = partnerNumbers.length;
        const retrievedItems = [];
        values.partnerList.split('\n').forEach(partnerNo => {            
            client.query({
                fetchPolicy: "network-only",
                query: LDAP_PARTNER_BY_PARTNERNO,
                variables: { partnerNo, authentication: localStorage.getItem('id_token') }
            })
                .then((response) => {
                    const partner = response.data.getLdapPartnerResultByPartnerNo[0];                    
                    const summary = {
                        partnerNo: partner.ou,
                        partnerName: partner.partnerName,
                        adminName: "NOT DEFINED",
                        adminEmail: "NOT DEFINED",
                        adminType: "NOT DEFINED"
                    }
                    let found = false;
                    if (partner.masterAdminUsers?.length > 0) {
                        summary.adminName = `${partner.masterAdminUsers[0].givenName}  ${partner.masterAdminUsers[0].sn}`;
                        summary.adminEmail = partner.masterAdminUsers[0].mail;
                        summary.adminType = "MASTER ADMIN";
                        found = true;
                    }
                    if (!found && partner.masterAdminDeputyUsers?.length > 0) {
                        summary.adminName = `${partner.masterAdminDeputyUsers[0].givenName}  ${partner.masterAdminDeputyUsers[0].sn}`;
                        summary.adminEmail = partner.masterAdminDeputyUsers[0].mail;
                        summary.adminType = "MASTER ADMIN DEPUTY";
                        found = true;
                    }
                    if (!found && partner.localAdminUsers?.length > 0) {
                        summary.adminName = `${partner.localAdminUsers[0].givenName}  ${partner.localAdminUsers[0].sn}`;
                        summary.adminEmail = partner.localAdminUsers[0].mail;
                        summary.adminType = "MASTER ADMIN DEPUTY";
                        found = true;
                    }
                    retrievedItems.push(summary);
                    remaining--;
                    // console.error(remaining);
                    if (remaining <= 0) {
                        setPartnerEmails(retrievedItems);
                    }
                })
                .catch((err) => {
                    console.error(err);
                    remaining--;
                    if (remaining <= 0) {                        
                        setPartnerEmails(retrievedItems);
                    }
                })
                ;
        });
    }

    const onUserSearch = (values: any) => {
        setUserDetailLoading(true);
        client.query({
            fetchPolicy: "network-only",
            query: LDAP_USER_BY_USERID,
            variables: { userId: values.userId, authentication: localStorage.getItem('id_token') }
        })
            .then((response) => {
                setUserDetail(response.data.getLdapUserResultByUserId);
                Promise.resolve(entitlementLookup(response.data.getLdapUserResultByUserId.uid))
                       .then((response) => {setEntitlementDetail(response.getUserEntitlementResultByUserId)})
                       .catch((err) => {message.error(err)});                
                setFilteredEntitlementDetail(null);
                setUserDetailLoading(false);
            })
            .catch((err) => {
                console.error(err)
                setUserDetailLoading(false);
            })
            ;
    }

    const handleFilteredEntitlementLookup = (userId: string, entitlement: string, partnerName: String) => {
        setUserDetailLoading(true);
        Promise.resolve(filteredEntitlementLookup(userId, entitlement, partnerName))
        .then((response) => {setFilteredEntitlementDetail(response.getUserEntitlementResultByUserIdAndFilter)})
        .catch((err) => {message.error(err)});
        setUserDetailLoading(false);
    }


    return (
        <>
            <h3>Lookup partner users on Ldap.</h3>
            <Tabs defaultActiveKey={"1"}>
                <TabPane tab="find partner" key={"1"}>
                    <Row>
                        <Col span={24}>
                            <Form
                                onFinish={onPartnerSearch}>
                                <Form.Item
                                    label="Partner Number"
                                    name="partnerNo"
                                    rules={[{ required: true, message: "Please enter partner number to find" }]}
                                >
                                    <Input type={"text"} />
                                </Form.Item>
                                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                    <Button type="primary" htmlType="submit">
                                        Find Partner
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                    {partnerDetail.map(partner => (
                        <PartnerElement partner={partner} key={partner.partnerNo} />
                    ))}
                </TabPane>
                <TabPane tab="find user" key={"2"}>
                    <Spin spinning={userDetailLoading} size={"large"}>
                        <Row>
                            <Col span={24}>
                                <Form onFinish={onUserSearch}>
                                    <Form.Item
                                        label="User name or email"
                                        name="userId"
                                    >
                                        <Input type={"text"} />
                                    </Form.Item>
                                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                        <Button type="primary" htmlType="submit">
                                            Find User
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                        <h3>User details</h3>
                        <UserElement user={userDetail} />
                        <h3>User entitlements</h3>
                        <EntitlementElement entitlement={entitlementDetail} user={userDetail} onClick={handleFilteredEntitlementLookup} />
                        <span>{entitlementFilterError}</span>
                        <h4>Partner details</h4>
                        <Col span={24}>
                            <Row>
                                <Col span={4}><Text strong={true}>Partner No</Text></Col>
                                <Col span={4}><Text strong={true}>Partner Companies</Text></Col>
                                <Col span={8}><Text strong={true}>Partner Name</Text></Col>
                                <Col span={8}><Text strong={true}>Entitlements</Text></Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            {filteredEntitlementDetail && filteredEntitlementDetail.partners.map(partner => (
                                <EntitlementPartnerElement partner={partner} key={partner.partnerOu} />
                            ))}
                        </Col>
                    </Spin>
                </TabPane>
                <TabPane tab="find bulk partner admin users" key={3}>
                    <Row>
                        <Col span={24}>
                            <Form onFinish={onMultiParterSearch}>
                                <Form.Item
                                    label="Partner List"
                                    name="partnerList"
                                >
                                    <TextArea rows={10} />
                                </Form.Item>
                                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                    <Button type="primary" htmlType="submit">
                                        Find Admin Users
                                    </Button>
                                </Form.Item>
                            </Form>
                            <table>
                                <thead>
                                    <tr>
                                        <th>partner no</th>
                                        <th>partner name</th>
                                        <th>partner contact type</th>
                                        <th>partner contact name</th>
                                        <th>partner contact email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {partnerEmails.map(partner => (
                                        <tr key={partner.partnerNo}>
                                            <td>{partner.partnerNo}</td>
                                            <td>{partner.partnerName}</td>
                                            <td>{partner.adminType}</td>
                                            <td>{partner.adminName}</td>
                                            <td>{partner.adminEmail}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <button onClick={() => setPartnerEmails([])}>CLEAR</button>
                        </Col>
                    </Row>
                </TabPane>
            </Tabs>
        </>
    )
}

export default LookupUser;