import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import {  Card, Col, Row, Spin, Steps, Tabs } from "antd";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { IAppComponentProps } from "../../components";
import SurveyMasterDataStep from "./SurveyMasterDataStep";
import SurveyInvoiceAgreementStep from "./SurveyInvoiceAgreementStep";
import SurveyDocumentTypeStep from "./SurveyDocumentTypeStep";
import SurveyConnectivityInfoStep from "./SurveyConnectivityInfoStep";
import SurveyVerifySubmitStep from "./SurveyVerifySubmitStep";
import SurveyGoLiveStep from "./SurveyGoLiveStep";
import {
  SELECTED_GROUP_ID,
  SELECTED_PARTNER_NO,
  SELECTED_SURVEY_ID
} from "../../constants";
import {
  getSurveyContactDataByGroupId,
  getSurveyPartnerDataByGroupId
} from "./surveyMasterData/SurveyMasterDataUtils";
import { getSurveyVerifyData, getVerifyReportPortion } from "./SurveyVerifySubmit/SurveyVerifyAndSubmitService";
import {
  getSurveyVerifyReport
}
  from "./SurveyVerifySubmit/__generated__/getSurveyVerifyReport";
import SurveyIssuesComponent from "./SurveyIssues-component";
import { setViewMode } from "../../utils";
import { useIntl } from "react-intl";
import { getI18n } from "../../utils/Utils";
import {StateContainer} from "./utilities/StateContainer";
import { useAppDispatch, useAppSelector } from "../../main/hooks";
import { setSurveyDataLoading, resetSurveyDataLoading } from "./IStoredSurveySlice";


const SurveyMainWizard: FunctionComponent<IAppComponentProps> = (props) => {
  const dispatch = useAppDispatch();
  const currentUser = props.currentUser;
  const [current, setCurrent] = React.useState(0);
  //valid statuses are error, finish, process, wait
  const [surveyMasterData, setSurveyMasterData] = useState([]);
  const [agreementTypeId, setAgreementTypeId] = useState<number>(0);
  const surveyState = useAppSelector((state) => state.survey);
  //
  const [selectedGroupId, setSelectedGroupId] = useState(undefined);
  const [surveySummaryMasterData, setSurveySummaryMasterData] = useState([]);
  const [surveyContactMasterData, setSurveyContactMasterData] = useState([]);
 // const [surveyPartnerProcessChannelData, setSurveyPartnerProcessChannelData] = useState([]);
  
  const [masterDataPortionStatus, setMasterDataPortionStatus] = useState("");
  const [masterDataPortionIssues, setMasterDataPortionIssues] = useState([]);

  const [eDocPortionStatus, setEDocPortionStatus] = useState("");
  const [eDocPortionIssues, setEDocPortionIssues] = useState([]);

  const [eConPortionStatus, setEConPortionStatus] = useState("");
  const [eConPortionIssues, setEConPortionIssues] = useState([]);

  const [agreementsPortionStatus, setAgreementsPortionStatus] = useState("");
  const [agreementsPortionIssues, setAgreementsPortionIssues] = useState([]);

  const [verifySubmitPortionStatus, setVerifySubmitPortionStatus] = useState("");
  const [verifySubmitPortionIssues, setVerifySubmitPortionIssues] = useState([]);

  const [goLivePortionStatus, setGoLivePortionStatus] = useState("");
  const [goLivePortionIssues, setGoLivePortionIssues] = useState([]);

  const [masterDataPortionStatusId, setMasterDataPortionStatusId] = useState(0);
  const [eDocPortionStatusId, setEDocPortionStatusId] = useState(0);
  const [eConPortionStatusId, setEConPortionStatusId] = useState(0);
  const [agreementsPortionStatusId, setAgreementsPortionStatusId] = useState(0);
  const [verifySubmitPortionStatusId, setVerifySubmitPortionStatusId] = useState(0);
  const [goLivePortionStatusId, setGoLivePortionStatusId] = useState(0);
  const stateContainer = useRef<StateContainer>(new StateContainer())


  const intl = useIntl();

  const { TabPane } = Tabs;

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const { Step } = Steps;
  const groupID = localStorage.getItem(SELECTED_GROUP_ID);

const getStatusData = () => 
{
  //isLoading(true);
  dispatch(setSurveyDataLoading(true));

    getSurveyPartnerDataByGroupId(groupID).then(masterData => {
        setSurveyMasterData(masterData);
        setAgreementTypeId(masterData[0].surveySummaryRecord.agreementTypeId);
    });

  Promise.resolve(getSurveyVerifyData(groupID)
    .then((response: getSurveyVerifyReport) => {
      const masterData = getVerifyReportPortion(response, 1);
      const eDoc = getVerifyReportPortion(response, 2);
      const eCon = getVerifyReportPortion(response, 3);
      const agreements = getVerifyReportPortion(response, 4);
      const verifySubmit = getVerifyReportPortion(response, 5);
      const goLive = getVerifyReportPortion(response, 6);
      if (response.getSurveyVerifyReport.canEdit === false) {
        setViewMode(true);
      }

      setMasterDataPortionStatus(getI18n(masterData.status, "Status not decoded", intl));
      const masterDataIssuesTranslated = [];
      masterData.issues.forEach((item) => {
        masterDataIssuesTranslated.push(getI18n(item, "Issue not decoded: ".concat(item), intl));
      })
      setMasterDataPortionIssues(masterDataIssuesTranslated);

      setEDocPortionStatus(getI18n(eDoc.status, "Issue not decoded: ".concat(eDoc.status), intl));
      const eDocIssuesTranslated = [];
      eDoc.issues.forEach((item) => {
        eDocIssuesTranslated.push(getI18n(item, "Issue not decoded: ".concat(item), intl));
      })
      setEDocPortionIssues(eDocIssuesTranslated);

      setEConPortionStatus(getI18n(eCon.status, "Status not decoded: ".concat(eCon.status), intl));
      const eConIssuesTranslated = [];
      eCon.issues.forEach((item) => {
        eConIssuesTranslated.push(getI18n(item, "Issue not decoded: ".concat(item), intl));
      })
      setEConPortionIssues(eConIssuesTranslated);

      setAgreementsPortionStatus(getI18n(agreements.status, "Status not decoded: ".concat(agreements.status), intl));
      const agreementsIssuesTranslated = [];
      agreements.issues.forEach((item) => {
        agreementsIssuesTranslated.push(getI18n(item, "Issue not decoded: ".concat(item), intl));
      })
      setAgreementsPortionIssues(agreementsIssuesTranslated);

      setVerifySubmitPortionStatus(getI18n(verifySubmit.status, "Status not decoded: ".concat(verifySubmit.status), intl));
      const verifySubmitIssuesTranslated = [];
      verifySubmit.issues.forEach((item) => {
        verifySubmitIssuesTranslated.push(getI18n(item, "Issue not decoded: ".concat(item), intl));
      })
      setVerifySubmitPortionIssues(verifySubmitIssuesTranslated);

      setGoLivePortionStatus(getI18n(goLive.status, "Status not decoded: ".concat(goLive.status), intl));
      const goLiveIssuesTranslated = [];
      goLive.issues.forEach((item) => {
        goLiveIssuesTranslated.push(getI18n(item, "Issue not decoded: ".concat(item), intl));
      })
      setGoLivePortionIssues(goLiveIssuesTranslated);
      //
      setMasterDataPortionStatusId(masterData.statusId);
      setEDocPortionStatusId(eDoc.statusId);
      setEConPortionStatusId(eCon.statusId);
      setAgreementsPortionStatusId(agreements.statusId);
      setVerifySubmitPortionStatusId(verifySubmit.statusId);
      setGoLivePortionStatusId(goLive.statusId);
      //isLoading(false);
    }))
    .catch(e => {
      console.error(e);
      dispatch(resetSurveyDataLoading(false));
    })
    .finally(() => {
      dispatch(setSurveyDataLoading(false));
    });
    //isLoading(false);
}

  useEffect(() => {
    localStorage.removeItem(SELECTED_PARTNER_NO);
    //localStorage.removeItem(SELECTED_COMPANY_CODE);
    localStorage.removeItem(SELECTED_SURVEY_ID);
    getStatusData();
    }, []);

  useEffect(() => {
    setSelectedGroupId(groupID);
    dispatch(setSurveyDataLoading(true));
    //isLoading(true);

    //Getting Master Data
    Promise.resolve(getSurveyContactDataByGroupId(groupID))
      .then(contactData => {
          setSurveyContactMasterData(contactData);
          //isLoading(false);
        }
      )
      .catch(e => {
        console.error(e);
        dispatch(resetSurveyDataLoading(true));
      })
      .finally(() => {
        dispatch(setSurveyDataLoading(false));
      });

    dispatch(setSurveyDataLoading(true));
    Promise.resolve(getSurveyPartnerDataByGroupId(groupID))
      .then(masterData => {
        setSurveyMasterData(masterData);
        setAgreementTypeId(masterData[0].surveySummaryRecord.agreementTypeId);
        //isLoading(false);
      }).catch(e => {
        console.error(e);
        dispatch(resetSurveyDataLoading(true));
      })
      .finally(() => {
        dispatch(setSurveyDataLoading(false));
      });


  }, [surveySummaryMasterData]);

  const onStatusUpdate = () => {
    getStatusData();
  }

  const steps = [
    {
      title: props.intl.formatMessage({
        id: "survey-main-wizard-masterdata-title",
        defaultMessage: "Master Data"
        })
      ,
      statusDescription: (masterDataPortionStatus),
      statusId: (masterDataPortionStatusId),
      content: (
              <div>
                <SurveyIssuesComponent portionSequence={1} groupId={selectedGroupId} verifyData={masterDataPortionIssues}/>
                <SurveyMasterDataStep surveyMasterData={surveyMasterData} surveyContactMasterData={surveyContactMasterData}
                                  intl={props.intl} next={next} currentUser={props.currentUser} distinctEntitlements={props.distinctEntitlements}
                                  onStatusUpdate={onStatusUpdate}
                                  stateContainer={stateContainer.current}
                />
              </div>
            )
    },

    {
      title: props.intl.formatMessage({
        id: "survey-main-wizard-edoc-types-title",
        defaultMessage: "Document Types"
        })
      ,
      statusDescription: (eDocPortionStatus),
      statusId: (eDocPortionStatusId),
      content: (
                <div>
                      <SurveyIssuesComponent portionSequence={2} groupId={selectedGroupId} verifyData={eDocPortionIssues}/>
                      <SurveyDocumentTypeStep surveyMasterData={surveyMasterData}
                                      //  surveyChannelDataData={surveyPartnerProcessChannelData}
                                        intl={props.intl}
                                        next={next} prev={prev}
                                        currentUser={props.currentUser}
                                        distinctEntitlements={props.distinctEntitlements}
                                        onStatusUpdate={onStatusUpdate}
                                        stateContainer={stateContainer.current}
                      />
                </div>
                )
    },
    {
      title:  props.intl.formatMessage({
        id: "survey-main-wizard-conn-info-title",
        defaultMessage: "Connectivity Info"
        })
      ,
      statusDescription: (eConPortionStatus),
      statusId: (eConPortionStatusId),
      content: (
                <div>
                  <SurveyIssuesComponent portionSequence={3} groupId={selectedGroupId} verifyData={eConPortionIssues}/>
                  <SurveyConnectivityInfoStep intl={props.intl} next={next} prev={prev}
                                                currentUser={props.currentUser}
                                                distinctEntitlements={props.distinctEntitlements}
                                                groupId={selectedGroupId}
                                                surveyMasterData={surveyMasterData}
                                                onStatusUpdate={onStatusUpdate}
                                                stateContainer={stateContainer.current}
                  />
                </div>
       )
    },
    {
      title: props.intl.formatMessage({
        id: "survey-main-wizard-einv-agreement-title",
        defaultMessage: "eInvoice Agreement"
        })
      ,
      statusDescription: (agreementsPortionStatus),
      statusId: (agreementsPortionStatusId),
      content: (
              <div>
                <SurveyIssuesComponent portionSequence={4} groupId={selectedGroupId} verifyData={agreementsPortionIssues}/>
                <SurveyInvoiceAgreementStep intl={props.intl} next={next} prev={prev} currentUser={props.currentUser}
                                            distinctEntitlements={props.distinctEntitlements}
                                            agreementTypeId={agreementTypeId} groupId={selectedGroupId} 
                                            onStatusUpdate={onStatusUpdate}
                                            stateContainer={stateContainer.current}
                />
              </div>
                )
    },
    {
      title: props.intl.formatMessage({
        id: "survey-main-wizard-verify-submit-title",
        defaultMessage: "Verify & Submit"
      })
      ,
      statusDescription: (verifySubmitPortionStatus),
      statusId: (verifySubmitPortionStatusId),
      content: (
        <div>
          <SurveyIssuesComponent portionSequence={5} groupId={selectedGroupId} verifyData={verifySubmitPortionIssues} />
          <SurveyVerifySubmitStep 
              intl={props.intl} 
              next={next} 
              prev={prev} 
              currentUser={props.currentUser} 
              distinctEntitlements={props.distinctEntitlements} 
              onStatusUpdate={onStatusUpdate} 
              stateContainer={stateContainer.current}/>
        </div>
      )
    },
    {
      title: props.intl.formatMessage({
        id: "survey-main-wizard-go-live-title",
        defaultMessage: "Go-Live"
      })
      ,
      statusDescription: (goLivePortionStatus),
      statusId: (goLivePortionStatusId),
      content: (
        <div>
          <SurveyIssuesComponent portionSequence={6} groupId={selectedGroupId} verifyData={goLivePortionIssues} />
          <SurveyGoLiveStep
              intl={props.intl}
              prev={prev}
              currentUser={props.currentUser}
              distinctEntitlements={props.distinctEntitlements}
              stateContainer={stateContainer.current}/>
        </div>
      )

    }

  ];

  const onChange = current => {
    setCurrent(current);
  };

  
  return (
    <>

      <Spin spinning={surveyState.survey.surveyDataLoading > 0} size={"large"}>
        <Card key="1" className="pl-5" size={"small"}>
          <Row gutter={24}>
            <Col md={22} sm={12} xs={24}>
              <Steps type={"navigation"} current={current} size={"default"} className="py15 py-md-15"
                     onChange={onChange} >
                {steps.map(item => (
                  <Step key={item.title} 
                  title={item.title} 
                  progressDot={true} 
                  description={item.statusDescription}
                  //status={(item.statusId === 2 || item.statusId === 3 || item.statusId === 9) ? "process" : ( item.statusId === 5 ? "finish": "error")}
                  />
                ))}
              </Steps>
              <div className="steps-content">{steps[current].content}</div>
            </Col>
          </Row>
        </Card>
      </Spin>

    </>
  );

};

export default SurveyMainWizard;