import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetEntitlementReportQueryVariables = Types.Exact<{
  entitlementFilter: Types.EntitlementFilter;
}>;


export type GetEntitlementReportQuery = { __typename?: 'Query', getEntitlementReport: Array<{ __typename?: 'EntitlementReport', rowId?: number | null, groupId?: string | null, description?: string | null, details?: Array<{ __typename?: 'EntitlementReportDetail', ruleId?: number | null, allowedOrBlocked?: string | null, company?: string | null, partner?: string | null, b2xRole?: string | null, department?: string | null, enterpriseRole?: string | null, objectClass?: string | null, otherEntitlementsOnRule?: string | null, actualEntitlements?: string | null, ruleDescription?: string | null, userId?: string | null, groupIds?: Array<string> | null, insertTime?: string | null, updateTime?: string | null, insertUser?: string | null, updateUser?: string | null }> | null }> };

export type GetEntitlementAuditReportQueryVariables = Types.Exact<{
  entitlementFilter: Types.EntitlementFilter;
}>;


export type GetEntitlementAuditReportQuery = { __typename?: 'Query', getEntitlementAuditReport: Array<{ __typename?: 'EntitlementAuditReport', rowId?: number | null, ruleId?: number | null, entitlement?: string | null, entitlementDescription?: string | null, groupId?: string | null, mode?: string | null, latestAuditTime?: string | null, details?: Array<{ __typename?: 'EntitlementAuditReportDetail', ruleId?: number | null, allowedOrBlocked?: string | null, company?: string | null, partner?: string | null, b2xRole?: string | null, department?: string | null, enterpriseRole?: string | null, objectClass?: string | null, otherEntitlementsOnRule?: string | null, ruleDescription?: string | null, userId?: string | null, insertUser?: string | null, updateUser?: string | null, insertTime?: string | null, updateTime?: string | null, auditTime?: string | null, auditAction?: string | null, auditId?: number | null }> | null }> };

export type GetAllEntitlementsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllEntitlementsQuery = { __typename?: 'Query', getAllEntitlements: Array<{ __typename?: 'Entitlement', entitlement?: string | null, description?: string | null, phraseId?: string | null, insertTime?: string | null, updateTime?: string | null, updateUser?: string | null, insertUser?: string | null, groupId?: string | null, mode?: string | null, volume?: number | null, presentInRules?: string | null, rulesWithWildcards?: string | null }> };

export type GetB2XRolesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetB2XRolesQuery = { __typename?: 'Query', getB2XRoles: Array<{ __typename?: 'B2xRoles', id?: number | null, userB2xrole?: string | null, description?: string | null, phraseId?: string | null, insertUser?: string | null, insertTime?: string | null, updateUser?: string | null, updateTime?: string | null, updateAction?: string | null, volume?: number | null }> };

export type GetObjectClassListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetObjectClassListQuery = { __typename?: 'Query', getObjectClassList: Array<{ __typename?: 'ObjectClass', id?: number | null, userObjectClass?: string | null, description?: string | null, phraseId?: string | null, insertUser?: string | null, insertTime?: string | null, updateUser?: string | null, updateTime?: string | null, updateAction?: string | null, volume?: number | null }> };

export type GetEntitlementGroupListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetEntitlementGroupListQuery = { __typename?: 'Query', getEntitlementGroupList: Array<{ __typename?: 'EntitlementGroup', groupId?: string | null, description?: string | null, phraseId?: string | null, insertUser?: string | null, insertTime?: string | null, updateUser?: string | null, updateTime?: string | null, updateAction?: string | null, volume?: number | null }> };

export type GetEntitlementRulesQueryVariables = Types.Exact<{
  ruleIds: Array<Types.Scalars['Float']['input']> | Types.Scalars['Float']['input'];
}>;


export type GetEntitlementRulesQuery = { __typename?: 'Query', getEntitlementRules: Array<{ __typename?: 'EntitlementRule', ruleId?: number | null, company?: string | null, partner?: string | null, userB2XRole?: string | null, userDepartment?: string | null, userObjectClass?: string | null, userPermissionRole?: string | null, userEnterpriseRole?: string | null, userId?: string | null, entitlements?: string | null, allowFlag?: string | null, insertUser?: string | null, updateUser?: string | null, insertTime?: string | null, updateTime?: string | null, description?: string | null, groupId?: string | null }> };

export type GetEntitlementReportByRuleIdsQueryVariables = Types.Exact<{
  ruleIds: Array<Types.Scalars['Float']['input']> | Types.Scalars['Float']['input'];
}>;


export type GetEntitlementReportByRuleIdsQuery = { __typename?: 'Query', getEntitlementReportByRuleIds: Array<{ __typename?: 'EntitlementReport', rowId?: number | null, groupId?: string | null, description?: string | null, details?: Array<{ __typename?: 'EntitlementReportDetail', ruleId?: number | null, allowedOrBlocked?: string | null, company?: string | null, partner?: string | null, b2xRole?: string | null, department?: string | null, enterpriseRole?: string | null, objectClass?: string | null, otherEntitlementsOnRule?: string | null, actualEntitlements?: string | null, ruleDescription?: string | null, groupIds?: Array<string> | null }> | null }> };


export const GetEntitlementReportDocument = gql`
    query getEntitlementReport($entitlementFilter: EntitlementFilter!) {
  getEntitlementReport(entitlementFilter: $entitlementFilter) {
    rowId
    groupId
    description
    details {
      ruleId
      allowedOrBlocked
      company
      partner
      b2xRole
      department
      enterpriseRole
      objectClass
      otherEntitlementsOnRule
      actualEntitlements
      ruleDescription
      userId
      groupIds
      insertTime
      updateTime
      insertUser
      updateUser
    }
  }
}
    `;

/**
 * __useGetEntitlementReportQuery__
 *
 * To run a query within a React component, call `useGetEntitlementReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntitlementReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntitlementReportQuery({
 *   variables: {
 *      entitlementFilter: // value for 'entitlementFilter'
 *   },
 * });
 */
export function useGetEntitlementReportQuery(baseOptions: Apollo.QueryHookOptions<GetEntitlementReportQuery, GetEntitlementReportQueryVariables> & ({ variables: GetEntitlementReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEntitlementReportQuery, GetEntitlementReportQueryVariables>(GetEntitlementReportDocument, options);
      }
export function useGetEntitlementReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEntitlementReportQuery, GetEntitlementReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEntitlementReportQuery, GetEntitlementReportQueryVariables>(GetEntitlementReportDocument, options);
        }
export function useGetEntitlementReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEntitlementReportQuery, GetEntitlementReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEntitlementReportQuery, GetEntitlementReportQueryVariables>(GetEntitlementReportDocument, options);
        }
export type GetEntitlementReportQueryHookResult = ReturnType<typeof useGetEntitlementReportQuery>;
export type GetEntitlementReportLazyQueryHookResult = ReturnType<typeof useGetEntitlementReportLazyQuery>;
export type GetEntitlementReportSuspenseQueryHookResult = ReturnType<typeof useGetEntitlementReportSuspenseQuery>;
export type GetEntitlementReportQueryResult = Apollo.QueryResult<GetEntitlementReportQuery, GetEntitlementReportQueryVariables>;
export const GetEntitlementAuditReportDocument = gql`
    query getEntitlementAuditReport($entitlementFilter: EntitlementFilter!) {
  getEntitlementAuditReport(entitlementFilter: $entitlementFilter) {
    rowId
    ruleId
    entitlement
    entitlementDescription
    groupId
    mode
    latestAuditTime
    details {
      ruleId
      allowedOrBlocked
      company
      partner
      b2xRole
      department
      enterpriseRole
      objectClass
      otherEntitlementsOnRule
      ruleDescription
      userId
      insertUser
      updateUser
      insertTime
      updateTime
      auditTime
      auditAction
      auditId
    }
  }
}
    `;

/**
 * __useGetEntitlementAuditReportQuery__
 *
 * To run a query within a React component, call `useGetEntitlementAuditReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntitlementAuditReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntitlementAuditReportQuery({
 *   variables: {
 *      entitlementFilter: // value for 'entitlementFilter'
 *   },
 * });
 */
export function useGetEntitlementAuditReportQuery(baseOptions: Apollo.QueryHookOptions<GetEntitlementAuditReportQuery, GetEntitlementAuditReportQueryVariables> & ({ variables: GetEntitlementAuditReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEntitlementAuditReportQuery, GetEntitlementAuditReportQueryVariables>(GetEntitlementAuditReportDocument, options);
      }
export function useGetEntitlementAuditReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEntitlementAuditReportQuery, GetEntitlementAuditReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEntitlementAuditReportQuery, GetEntitlementAuditReportQueryVariables>(GetEntitlementAuditReportDocument, options);
        }
export function useGetEntitlementAuditReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEntitlementAuditReportQuery, GetEntitlementAuditReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEntitlementAuditReportQuery, GetEntitlementAuditReportQueryVariables>(GetEntitlementAuditReportDocument, options);
        }
export type GetEntitlementAuditReportQueryHookResult = ReturnType<typeof useGetEntitlementAuditReportQuery>;
export type GetEntitlementAuditReportLazyQueryHookResult = ReturnType<typeof useGetEntitlementAuditReportLazyQuery>;
export type GetEntitlementAuditReportSuspenseQueryHookResult = ReturnType<typeof useGetEntitlementAuditReportSuspenseQuery>;
export type GetEntitlementAuditReportQueryResult = Apollo.QueryResult<GetEntitlementAuditReportQuery, GetEntitlementAuditReportQueryVariables>;
export const GetAllEntitlementsDocument = gql`
    query getAllEntitlements {
  getAllEntitlements {
    entitlement
    description
    phraseId
    insertTime
    updateTime
    updateUser
    insertUser
    groupId
    mode
    volume
    presentInRules
    rulesWithWildcards
  }
}
    `;

/**
 * __useGetAllEntitlementsQuery__
 *
 * To run a query within a React component, call `useGetAllEntitlementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEntitlementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEntitlementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllEntitlementsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllEntitlementsQuery, GetAllEntitlementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllEntitlementsQuery, GetAllEntitlementsQueryVariables>(GetAllEntitlementsDocument, options);
      }
export function useGetAllEntitlementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllEntitlementsQuery, GetAllEntitlementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllEntitlementsQuery, GetAllEntitlementsQueryVariables>(GetAllEntitlementsDocument, options);
        }
export function useGetAllEntitlementsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllEntitlementsQuery, GetAllEntitlementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllEntitlementsQuery, GetAllEntitlementsQueryVariables>(GetAllEntitlementsDocument, options);
        }
export type GetAllEntitlementsQueryHookResult = ReturnType<typeof useGetAllEntitlementsQuery>;
export type GetAllEntitlementsLazyQueryHookResult = ReturnType<typeof useGetAllEntitlementsLazyQuery>;
export type GetAllEntitlementsSuspenseQueryHookResult = ReturnType<typeof useGetAllEntitlementsSuspenseQuery>;
export type GetAllEntitlementsQueryResult = Apollo.QueryResult<GetAllEntitlementsQuery, GetAllEntitlementsQueryVariables>;
export const GetB2XRolesDocument = gql`
    query getB2XRoles {
  getB2XRoles {
    id
    userB2xrole
    description
    phraseId
    insertUser
    insertTime
    updateUser
    updateTime
    updateAction
    volume
  }
}
    `;

/**
 * __useGetB2XRolesQuery__
 *
 * To run a query within a React component, call `useGetB2XRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetB2XRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetB2XRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetB2XRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetB2XRolesQuery, GetB2XRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetB2XRolesQuery, GetB2XRolesQueryVariables>(GetB2XRolesDocument, options);
      }
export function useGetB2XRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetB2XRolesQuery, GetB2XRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetB2XRolesQuery, GetB2XRolesQueryVariables>(GetB2XRolesDocument, options);
        }
export function useGetB2XRolesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetB2XRolesQuery, GetB2XRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetB2XRolesQuery, GetB2XRolesQueryVariables>(GetB2XRolesDocument, options);
        }
export type GetB2XRolesQueryHookResult = ReturnType<typeof useGetB2XRolesQuery>;
export type GetB2XRolesLazyQueryHookResult = ReturnType<typeof useGetB2XRolesLazyQuery>;
export type GetB2XRolesSuspenseQueryHookResult = ReturnType<typeof useGetB2XRolesSuspenseQuery>;
export type GetB2XRolesQueryResult = Apollo.QueryResult<GetB2XRolesQuery, GetB2XRolesQueryVariables>;
export const GetObjectClassListDocument = gql`
    query getObjectClassList {
  getObjectClassList {
    id
    userObjectClass
    description
    phraseId
    insertUser
    insertTime
    updateUser
    updateTime
    updateAction
    volume
  }
}
    `;

/**
 * __useGetObjectClassListQuery__
 *
 * To run a query within a React component, call `useGetObjectClassListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetObjectClassListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetObjectClassListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetObjectClassListQuery(baseOptions?: Apollo.QueryHookOptions<GetObjectClassListQuery, GetObjectClassListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetObjectClassListQuery, GetObjectClassListQueryVariables>(GetObjectClassListDocument, options);
      }
export function useGetObjectClassListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetObjectClassListQuery, GetObjectClassListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetObjectClassListQuery, GetObjectClassListQueryVariables>(GetObjectClassListDocument, options);
        }
export function useGetObjectClassListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetObjectClassListQuery, GetObjectClassListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetObjectClassListQuery, GetObjectClassListQueryVariables>(GetObjectClassListDocument, options);
        }
export type GetObjectClassListQueryHookResult = ReturnType<typeof useGetObjectClassListQuery>;
export type GetObjectClassListLazyQueryHookResult = ReturnType<typeof useGetObjectClassListLazyQuery>;
export type GetObjectClassListSuspenseQueryHookResult = ReturnType<typeof useGetObjectClassListSuspenseQuery>;
export type GetObjectClassListQueryResult = Apollo.QueryResult<GetObjectClassListQuery, GetObjectClassListQueryVariables>;
export const GetEntitlementGroupListDocument = gql`
    query getEntitlementGroupList {
  getEntitlementGroupList {
    groupId
    description
    phraseId
    insertUser
    insertTime
    updateUser
    updateTime
    updateAction
    volume
  }
}
    `;

/**
 * __useGetEntitlementGroupListQuery__
 *
 * To run a query within a React component, call `useGetEntitlementGroupListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntitlementGroupListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntitlementGroupListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEntitlementGroupListQuery(baseOptions?: Apollo.QueryHookOptions<GetEntitlementGroupListQuery, GetEntitlementGroupListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEntitlementGroupListQuery, GetEntitlementGroupListQueryVariables>(GetEntitlementGroupListDocument, options);
      }
export function useGetEntitlementGroupListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEntitlementGroupListQuery, GetEntitlementGroupListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEntitlementGroupListQuery, GetEntitlementGroupListQueryVariables>(GetEntitlementGroupListDocument, options);
        }
export function useGetEntitlementGroupListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEntitlementGroupListQuery, GetEntitlementGroupListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEntitlementGroupListQuery, GetEntitlementGroupListQueryVariables>(GetEntitlementGroupListDocument, options);
        }
export type GetEntitlementGroupListQueryHookResult = ReturnType<typeof useGetEntitlementGroupListQuery>;
export type GetEntitlementGroupListLazyQueryHookResult = ReturnType<typeof useGetEntitlementGroupListLazyQuery>;
export type GetEntitlementGroupListSuspenseQueryHookResult = ReturnType<typeof useGetEntitlementGroupListSuspenseQuery>;
export type GetEntitlementGroupListQueryResult = Apollo.QueryResult<GetEntitlementGroupListQuery, GetEntitlementGroupListQueryVariables>;
export const GetEntitlementRulesDocument = gql`
    query getEntitlementRules($ruleIds: [Float!]!) {
  getEntitlementRules(ruleIds: $ruleIds) {
    ruleId
    company
    partner
    userB2XRole
    userDepartment
    userObjectClass
    userPermissionRole
    userEnterpriseRole
    userId
    entitlements
    allowFlag
    insertUser
    updateUser
    insertTime
    updateTime
    description
    groupId
  }
}
    `;

/**
 * __useGetEntitlementRulesQuery__
 *
 * To run a query within a React component, call `useGetEntitlementRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntitlementRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntitlementRulesQuery({
 *   variables: {
 *      ruleIds: // value for 'ruleIds'
 *   },
 * });
 */
export function useGetEntitlementRulesQuery(baseOptions: Apollo.QueryHookOptions<GetEntitlementRulesQuery, GetEntitlementRulesQueryVariables> & ({ variables: GetEntitlementRulesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEntitlementRulesQuery, GetEntitlementRulesQueryVariables>(GetEntitlementRulesDocument, options);
      }
export function useGetEntitlementRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEntitlementRulesQuery, GetEntitlementRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEntitlementRulesQuery, GetEntitlementRulesQueryVariables>(GetEntitlementRulesDocument, options);
        }
export function useGetEntitlementRulesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEntitlementRulesQuery, GetEntitlementRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEntitlementRulesQuery, GetEntitlementRulesQueryVariables>(GetEntitlementRulesDocument, options);
        }
export type GetEntitlementRulesQueryHookResult = ReturnType<typeof useGetEntitlementRulesQuery>;
export type GetEntitlementRulesLazyQueryHookResult = ReturnType<typeof useGetEntitlementRulesLazyQuery>;
export type GetEntitlementRulesSuspenseQueryHookResult = ReturnType<typeof useGetEntitlementRulesSuspenseQuery>;
export type GetEntitlementRulesQueryResult = Apollo.QueryResult<GetEntitlementRulesQuery, GetEntitlementRulesQueryVariables>;
export const GetEntitlementReportByRuleIdsDocument = gql`
    query getEntitlementReportByRuleIds($ruleIds: [Float!]!) {
  getEntitlementReportByRuleIds(ruleIds: $ruleIds) {
    rowId
    groupId
    description
    details {
      ruleId
      allowedOrBlocked
      company
      partner
      b2xRole
      department
      enterpriseRole
      objectClass
      otherEntitlementsOnRule
      actualEntitlements
      ruleDescription
      groupIds
    }
  }
}
    `;

/**
 * __useGetEntitlementReportByRuleIdsQuery__
 *
 * To run a query within a React component, call `useGetEntitlementReportByRuleIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntitlementReportByRuleIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntitlementReportByRuleIdsQuery({
 *   variables: {
 *      ruleIds: // value for 'ruleIds'
 *   },
 * });
 */
export function useGetEntitlementReportByRuleIdsQuery(baseOptions: Apollo.QueryHookOptions<GetEntitlementReportByRuleIdsQuery, GetEntitlementReportByRuleIdsQueryVariables> & ({ variables: GetEntitlementReportByRuleIdsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEntitlementReportByRuleIdsQuery, GetEntitlementReportByRuleIdsQueryVariables>(GetEntitlementReportByRuleIdsDocument, options);
      }
export function useGetEntitlementReportByRuleIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEntitlementReportByRuleIdsQuery, GetEntitlementReportByRuleIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEntitlementReportByRuleIdsQuery, GetEntitlementReportByRuleIdsQueryVariables>(GetEntitlementReportByRuleIdsDocument, options);
        }
export function useGetEntitlementReportByRuleIdsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEntitlementReportByRuleIdsQuery, GetEntitlementReportByRuleIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEntitlementReportByRuleIdsQuery, GetEntitlementReportByRuleIdsQueryVariables>(GetEntitlementReportByRuleIdsDocument, options);
        }
export type GetEntitlementReportByRuleIdsQueryHookResult = ReturnType<typeof useGetEntitlementReportByRuleIdsQuery>;
export type GetEntitlementReportByRuleIdsLazyQueryHookResult = ReturnType<typeof useGetEntitlementReportByRuleIdsLazyQuery>;
export type GetEntitlementReportByRuleIdsSuspenseQueryHookResult = ReturnType<typeof useGetEntitlementReportByRuleIdsSuspenseQuery>;
export type GetEntitlementReportByRuleIdsQueryResult = Apollo.QueryResult<GetEntitlementReportByRuleIdsQuery, GetEntitlementReportByRuleIdsQueryVariables>;