import React, { FunctionComponent, useMemo, useRef, useState } from "react";
import { Button,  Col, Row,  Form,  Typography, Spin, notification, message } from "antd";
import { IAppComponentProps } from "../../../components";
import { useIntl } from "react-intl";
import { IFileInformation, PortalBlobFileInput } from "../../../components/InputBlobFile";
import { useLazyQuery, useQuery } from "@apollo/client";
import { SAVE_SURVEY_CONNECTIVITY_TESTFILE, UPLOAD_BLOB_FILE } from "../mutations";
import client from "../../../config/GraphQLApolloClient";
import { SURVEY_TESTFILE_REPORT_QUERY } from "../queries";
import { getConnectivityTestFileReport } from "../__generated__/getConnectivityTestFileReport";
import { AgGridReact } from "ag-grid-react";
import { FirstDataRenderedEvent, GridApi, GridOptions } from "ag-grid-community";
import Paragraph from "antd/lib/typography/Paragraph";
import Link from "antd/lib/typography/Link";
import { downloadSurveyDocument, downloadSurveyDocumentVariables } from "../__generated__/downloadSurveyDocument";
import { DOWNLOAD_SURVEY_DOCUMENT_QUERY_BY_BLOB_ID } from "../../survey/surveyAgreements/queries";
import { saveAs } from "save-as";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { getI18n } from "../../../utils/Utils";
import { getAgGridLocalization } from "../../info-portal/components/grid/GridUtil";
import {getDefinitionFormatChannelHelp} from "../eDocumentsType/documentTypeUtils";
import { useAppSelector } from "../../../main/hooks";
import { MODE } from "../IStoredSurvey";
import {
    convertFromSpecifiedDateFormatToUserDefined,
    convertFromUserSpecifiedDateFormatToExpected
} from "../../users/UserFormatPreferenceServices";

import {Buffer} from "buffer";

const { Text } = Typography;

type ISurveyConnectivityInfoTestFileProps = {
    groupId?: any;
    onStatusUpdate?: any;
} & IAppComponentProps;

const SurveyConnectivityTestFile: FunctionComponent<ISurveyConnectivityInfoTestFileProps> = (props) => {

    const intl = useIntl();
    const [fileBase64Data, setFileBase64Data] = useState<IFileInformation>(null);
    const [uploading, setUploading] = useState(false);
    const [testFileData, setTestFileData] = useState([]);
    const [gridApi, setGridApi] = useState<GridApi>(undefined);
    const [gridApi2, setGridApi2] = useState<GridApi>(undefined);
    const [selectedRow, setSelectedRow] = useState(null)
    const [fileForm] = Form.useForm();

    const surveyState = useAppSelector((state) => state.survey);

    const isViewMode = () => {
      return surveyState.survey.surveyMode === MODE.VIEW ? true : false
    }
  
    const isOverViewMode = () => {
      return surveyState.survey.surveyMode === MODE.OVERVIEW ? true : false
    }
  
  const isReadOnly = () => {
    return isViewMode() || isOverViewMode();
   }
  

    const [downloadDocument, {}] = useLazyQuery<downloadSurveyDocument, downloadSurveyDocumentVariables>(DOWNLOAD_SURVEY_DOCUMENT_QUERY_BY_BLOB_ID, {
        fetchPolicy: "network-only", onError: (error) => console.log(error)
      });

    const TESTFILE_TABLE_HEADERS = [
        { headerName:  props.intl.formatMessage({id:"survey-customer-channel-type-grid-header-processCode"}), field: "processCodeName", colId: "processCode", checkboxSelection: true, headerCheckboxSelection: false,
            cellRenderer: 'agGroupCellRenderer',
            cellRendererFramework: (params) => {
            return params.data.processCodeName?.concat(" [",params.data.processCode,"]");
          }
         },
        { headerName: props.intl.formatMessage({id:"survey-test-document-format"}), field: "documentFormat", colId: "documentFormat" },
        { headerName: props.intl.formatMessage({id:"survey-test-version"}), field: "currentVersionNumber", colId: "currentVersionNumber" },
        { headerName: props.intl.formatMessage({id:"survey-test-file-status"}), field: "fileStatus", colId: "fileStatus" },
        { headerName: props.intl.formatMessage({id:"survey-test-validation-status"}), field: "surveyValidationStatusDescr", colId: "surveyValidationStatusDescr"
        ,cellRendererFramework: (params) => {
            if (params.data.validationStatus !== null && params.data.validationStatus !== undefined)
            {
                return params.data.validationStatus?.trim().concat(" - ").concat(getI18n(params.data.validationStatusPhraseId  , params.data.validationStatus?.trim().concat(" - ").concat(params.data.surveyValidationStatusDescr), intl));
            }
            return "";
            
          }
        },
        {
            headerName: props.intl.formatMessage({id:"survey-test-latest-uploaded"}),
            colId: "insertTime",
            comparator:(a:string,b:string)=> {
                if (a && b) {
                    const aInYYYYMMDD = convertFromUserSpecifiedDateFormatToExpected(props.currentUser, a.substring(0,10), "YYYY-MM-DD");
                    const bInYYYYMMDD = convertFromUserSpecifiedDateFormatToExpected(props.currentUser, b.substring(0,10), "YYYY-MM-DD");
                    return aInYYYYMMDD.localeCompare(bInYYYYMMDD);
                }
                return 0;
            },
            valueGetter: (params) => {
                if (params.data.insertTime) {
                    const dateInYYYY_MM_DD = params.data.insertTime.substring(0, 10);
                    return `${convertFromSpecifiedDateFormatToUserDefined(props.currentUser, dateInYYYY_MM_DD, "YYYY-MM-DD")} ${ params.data.insertTime.substring(10)}`;
                }
                return "";
            },
        },
        {
            headerName: props.intl.formatMessage({id:"survey-test-latest-submission"}),
            colId: "submittedForValidation" ,
            comparator:(a:string,b:string)=> {
                if (a && b) {
                    const aInYYYYMMDD = convertFromUserSpecifiedDateFormatToExpected(props.currentUser, a.substring(0,10), "YYYY-MM-DD");
                    const bInYYYYMMDD = convertFromUserSpecifiedDateFormatToExpected(props.currentUser, b.substring(0,10), "YYYY-MM-DD");
                    return aInYYYYMMDD.localeCompare(bInYYYYMMDD);
                }
                return 0;
            },
            valueGetter: (params) => {
                if (params.data.submittedForValidation) {
                    const dateInYYYY_MM_DD = params.data.submittedForValidation.substring(0, 10);
                    return `${convertFromSpecifiedDateFormatToUserDefined(props.currentUser, dateInYYYY_MM_DD, "YYYY-MM-DD")} ${ params.data.submittedForValidation.substring(10)}`;
                }
                return "";
            },
        }
    ];

    const TESTFILE_DETAIL_TABLE_HEADERS = [
        { headerName: props.intl.formatMessage({id:"survey-customer-channel-type-grid-header-processCode"}), field: "processCodeName", colId: "processCode", checkboxSelection: true, headerCheckboxSelection: false,
            cellRendererFramework: (params) => {
            return params.data.processCodeName?.concat(" [",params.data.processCode,"]");
          }
         },
        { headerName: props.intl.formatMessage({id:"survey-test-document-format"}), field: "documentFormat", colId: "documentFormat" },
        { headerName: props.intl.formatMessage({id:"survey-test-version"}), field: "currentVersionNumber", colId: "currentVersionNumber" },
        { headerName: props.intl.formatMessage({id:"survey-test-file-status"}), field: "fileStatus", colId: "fileStatus" },
        { headerName: props.intl.formatMessage({id:"survey-test-validation-status"}), field: "surveyValidationStatusDescr", colId: "surveyValidationStatusDescr"
        ,cellRendererFramework: (params) => {
            return getI18n(params.data.validationStatus.trim().concat(" - ").concat(params.data.validationStatusPhraseId)  , params.data.validationStatus.trim().concat(" - ").concat(params.data.surveyValidationStatusDescr), intl);
          }
        },
        {
            headerName: props.intl.formatMessage({id:"survey-test-latest-uploaded"}),
            colId: "insertTime",
            comparator:(a:string,b:string)=> {
                if (a && b) {
                    const aInYYYYMMDD = convertFromUserSpecifiedDateFormatToExpected(props.currentUser, a.substring(0,10), "YYYY-MM-DD");
                    const bInYYYYMMDD = convertFromUserSpecifiedDateFormatToExpected(props.currentUser, b.substring(0,10), "YYYY-MM-DD");
                    return aInYYYYMMDD.localeCompare(bInYYYYMMDD);
                }
                return 0;
            },
            valueGetter: (params) => {
                if (params.data.insertTime) {
                    const dateInYYYY_MM_DD = params.data.insertTime.substring(0, 10);
                    return `${convertFromSpecifiedDateFormatToUserDefined(props.currentUser, dateInYYYY_MM_DD, "YYYY-MM-DD")} ${ params.data.insertTime.substring(10)}`;
                }
                return "";
            },
        },
        {
            headerName: props.intl.formatMessage({id:"survey-test-latest-submission"}),
            colId: "submittedForValidation",
            comparator:(a:string,b:string)=> {
                if (a && b) {
                    const aInYYYYMMDD = convertFromUserSpecifiedDateFormatToExpected(props.currentUser, a.substring(0,10), "YYYY-MM-DD");
                    const bInYYYYMMDD = convertFromUserSpecifiedDateFormatToExpected(props.currentUser, b.substring(0,10), "YYYY-MM-DD");
                    return aInYYYYMMDD.localeCompare(bInYYYYMMDD);
                }
                return 0;
            },
            valueGetter: (params) => {
                if (params.data.submittedForValidation) {
                    const dateInYYYY_MM_DD = params.data.submittedForValidation.substring(0, 10);
                    return `${convertFromSpecifiedDateFormatToUserDefined(props.currentUser, dateInYYYY_MM_DD, "YYYY-MM-DD")} ${ params.data.submittedForValidation.substring(10)}`;
                }
                return "";
            },
        }
    ];

    /**load initial data from api if available */
    const { data, loading } = useQuery<getConnectivityTestFileReport>(SURVEY_TESTFILE_REPORT_QUERY,
        {
            fetchPolicy: "network-only",
            variables: { groupId: props.groupId },
            onCompleted: (response) => {   
                setTestFileData(response.getConnectivityTestFileReport);
            }
        }
    );

    const saveTestFileDetail = (groupId: string, testFile: any) => {
        client.mutate({
            fetchPolicy: "no-cache",
            mutation: SAVE_SURVEY_CONNECTIVITY_TESTFILE,
            variables: { groupId, testFile },
        }).then((response) => {            
            setTestFileData(response.data.saveConnectivityTestFile);
            setSelectedRow(null);
        }).catch((error) => {
            console.error(error);
        })
    }

    const onFinish = (values: any) => {        
        if (selectedRow.validationStatus !== "S")
        {
            //upload blobfile to tfblob and azure
            setUploading(true)
            const blobFile = {
                base64FileData: fileBase64Data.fileData,
                fileName: fileBase64Data.fileName,
                mimeType: fileBase64Data.mimeType,
                requiresAuthentication: true,
                rolesAllowed: ""
            }
            client.mutate({
                fetchPolicy: "no-cache",
                mutation: UPLOAD_BLOB_FILE,
                variables: { blobFile },
            })
                .then((response) => {                
                    const testFileDetail = {
                        testFileId: null,
                        documentFormatId: selectedRow.documentFormatId,
                        processCodeId: selectedRow.processCodeId,
                        surveyId: selectedRow.surveyId,
                        groupId: selectedRow.groupId,
                        versionNumber: selectedRow.currentVersionNumber,
                        fileBlobId: response.data.uploadBlobFile.blobId,
                        fileStatus: "Uploaded",
                        fileError: null,
                        submittedForValidation: null,
                        validationReference: null,
                        validationStatus: null,
                        validationPassed: null,
                        validationResultBlobId: null

                    }
                    saveTestFileDetail(props.groupId, testFileDetail);
                    message.success(getI18n("survey-connectivity-save-contact-success-message", "Connectivity Information saved successfully" , intl));
                })
                .catch((error) => {
                    console.error(error);
                    message.error(getI18n("survey-connectivity-save-contact-failed-message", "Failed to save connectivity information" , intl));
                })
                .finally(() => {
                    setUploading(false);
                    //force issues component to refresh
                    props.onStatusUpdate();
                    }
                )
                ;
        }
        else{
            notification.error({message: getI18n("survey-test-file-pending-validation-error-message","You cannot upload new Test file if previous file was not validated yet",intl)});
        }
        

        //make entry in survey_test_file for file
    }

    const onGridReady = (params) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
    };

    const onGrid2Ready = (params) => {
        setGridApi2(params.api);
        params.api.sizeColumnsToFit();
    };

    const selectRow = () => {
        //console.log("Selected Row:");
        //console.dir(gridApi!.getSelectedRows());
        const selectedRows = gridApi!.getSelectedRows();
        if (selectedRows.length > 0) {            
            setSelectedRow(selectedRows[0]);
        }else{
            setSelectedRow(null);
        }
        setFileBase64Data(null);
        fileForm.resetFields();

    };

    const select2Row = () => {
        //console.log("Selected Row:");
        //console.dir(gridApi2!.getSelectedRows());
        const selectedRows = gridApi2!.getSelectedRows();
        if (selectedRows.length > 0) {            
            setSelectedRow(selectedRows[0]);
        }else{
            setSelectedRow(null);
        }

    };


    const downloadTestFile = () => {
        let fileBlobId = selectedRow?.fileBlobId;
        //If no master column was selected then check if detail column was selected, master will override detail selection
        if (fileBlobId !== null)
        {
            gridApi.forEachDetailGridInfo(function(detailGridApi) {
                console.log(detailGridApi.api.getSelectedRows()[0]);
                if (detailGridApi.api.getSelectedRows()[0] )
                {
                    fileBlobId = detailGridApi.api.getSelectedRows()[0].fileBlobId;
                }
              });
        }
        
        if (fileBlobId !== null)
        {
            //console.log(`Download blob id: ${fileBlobId}`);
            downloadDocument({ variables: { blobId: Number(fileBlobId) } }).then(response => {
                if (response.data && response.data.downloadSurveyDocument)
                {
                    const mimeType = response.data.downloadSurveyDocument.value.mimeType;
                    const decodedData = Buffer.from(response.data.downloadSurveyDocument.value.encodedFileData, "base64");
                    const blob = new Blob([decodedData], { type: mimeType });
                    saveAs(blob, response.data.downloadSurveyDocument.value.fileName);
                }
                else{
                    notification.error({message: getI18n("survey-test-file-download-failed-error-message","Download file failed",intl)});
                }
                
            });
        }
        else{
            notification.error({message: "No test file to download"});
        }
        
      };

      const downloadTestFileReport = () => {
        let fileBlobId = selectedRow?.validationResultBlobId;
        //If no master column was selected then check if detail column was selected, master will override detail selection
        if (fileBlobId !== null)
        {
            gridApi.forEachDetailGridInfo(function(detailGridApi) {
                //console.log(detailGridApi.api.getSelectedRows()[0]);
                if (detailGridApi.api.getSelectedRows()[0] )
                {
                    fileBlobId = detailGridApi.api.getSelectedRows()[0].validationResultBlobId;
                }
              });
        }
        
        if (fileBlobId !== null)
        {
            //console.log(`Download blob id: ${fileBlobId}`);
            downloadDocument({ variables: { blobId: Number(fileBlobId) } }).then(response => {
                if (response.data && response.data.downloadSurveyDocument)
                {
                    const mimeType = response.data.downloadSurveyDocument.value.mimeType;
                    const decodedData = Buffer.from(response.data.downloadSurveyDocument.value.encodedFileData, "base64");
                    const blob = new Blob([decodedData], { type: mimeType });
                    saveAs(blob, response.data.downloadSurveyDocument.value.fileName);
                }
                else{
                    notification.error({message: getI18n("survey-test-file-download-failed-error-message","Download file failed",intl)});
                }
            });
        }
        else 
        {
            notification.error({message: getI18n("survey-test-file-report-not-available-error-message","No test file Report available to download",intl)});
        }
        
      };

      const detailGridOptions = useMemo(() => ({
        rowSelection: "single",
        suppressRowClickSelection: true,
        enableRangeSelection: false,
        pagination: true,
        paginationAutoPageSize: true,
        onRowSelected: {select2Row},
        onGridReady: {onGrid2Ready},
        columnDefs: TESTFILE_DETAIL_TABLE_HEADERS,
        defaultColDef: {
            sortable: true,
            flex: 1
        }
    }), []);

    const detailCellRendererParams = useMemo(() => ({
        detailGridOptions,
        getDetailRowData: params => params.successCallback(params.data.subList === null ? [] : params.data.subList)
    }), []);

    function onFirstDataRendered(params: FirstDataRenderedEvent) {
        // arbitrarily expand a row for presentational purposes
        setTimeout(function () {
            if (!params.api.getDisplayedRowAtIndex(0).hasChildren())
            {
                return;
            }
          params.api.getDisplayedRowAtIndex(0)!.setExpanded(true);
        }, 0);
      }

    return (
        <>

            <Row gutter={24}>
                <Col>
                    <Text disabled={true}>GroupId: {props.groupId}</Text>
                </Col>
            </Row>
        
            <Row gutter={24}>
                <Col>
                    <Typography>
                        <Paragraph>{intl.formatMessage({ id: "survey-connectivityInfo-testfile-descriptionLine1"})}</Paragraph>
                    </Typography>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col>
                    <Typography>
                        <Paragraph>
                            <Link href={getDefinitionFormatChannelHelp()} target="_blank" className="link-black">
                            <QuestionCircleOutlined style={{ fontSize: "20px", color: "#444444" }} className={"mr10"} />
                                {intl.formatMessage({ id: "survey-connectivityInfo-testfile-info-url-text", defaultMessage: "Link To Download Guideline and Sample File" })}
                            </Link>
                        </Paragraph>
                    </Typography>
                </Col>
            </Row>
            <Row>
                <Col span={2}>
                    <Form.Item>
                        <Button type={"link"}   onClick={() => downloadTestFile()}>{props.intl.formatMessage({id:'survey-connectivity-test-download-test-file-button-1'})}</Button>
                    </Form.Item>
                </Col>
                <Col span={16}>
                    <Form.Item>
                        <Button type={"link"}   onClick={() => downloadTestFileReport()}>{props.intl.formatMessage({id:'survey-connectivity-test-download-test-file-button-2'})}</Button>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={20} className="mb5">
                    <div style={{ flex: "auto", height: "250px", paddingTop: "0px" }} className="ag-theme-balham">
                        <AgGridReact
                            defaultColDef={{
                                enableValue: true,
                                sortable: true,
                                resizable: true
                            }}
                            rowData={testFileData}
                            columnDefs={TESTFILE_TABLE_HEADERS}
                            masterDetail={true}
                            detailCellRendererParams={detailCellRendererParams}
                            // detailCellRenderer={detailCellRenderer}
                            localeText={getAgGridLocalization(intl)}
                            sideBar={false}
                            rowClass="agGridRows"
                            rowSelection="single"
                            suppressMenuHide={true}
                            debug={false}
                            pagination={true}
                            paginationPageSize={15}
                            suppressRowClickSelection={true}
                            onGridReady={onGridReady}
                            onRowSelected={selectRow}
                            onFirstDataRendered={onFirstDataRendered}
                        />
                    </div>
                </Col>
            </Row>
            <div style={{ display: (selectedRow !== null ) ? 'block' : 'none' }}>
            
                <Row>
                    <Text>
                        {intl.formatMessage({ id: "survey-connectivityInfo-testfile-uploadMessage"
                                            , defaultMessage: "Upload Test file for process {processCode} in format {documentFormat}"},
                                            {"processCode": selectedRow?.processCode, "documentFormat": selectedRow?.documentFormat}
                                            )
                        }
                    </Text>
                </Row>
                <Row>
                    <Spin spinning={uploading} size={"large"}>
                    <Form
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        labelAlign={"left"}
                        onFinish={onFinish}
                        form={fileForm}
                    >
                        <Form.Item
                            label={props.intl.formatMessage({id:'survey-test-file'})}
                            name={"fileData"}
                            required={true}
                        >
                            <PortalBlobFileInput onChange={setFileBase64Data} />
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 8, span: 16 }} hidden={isReadOnly() || fileBase64Data === null}>
                            <Button type="primary" htmlType="submit">
                                Upload File
                            </Button>
                        </Form.Item>
                    </Form>
                    </Spin>
                </Row>
            </div>
      
        </>
    )
}

export default SurveyConnectivityTestFile;