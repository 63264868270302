import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {ArrowLeftOutlined, SaveOutlined} from "@ant-design/icons";
import {Button, Col, Form, Input, Row, Select, SelectProps, Spin, Tabs, Tooltip, Typography} from "antd";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import {getFormats, SUPPLIER_DOC_TYPES} from "./documentTypeUtils";
import {GridApi} from "ag-grid-community";
import "./SurveySupplierDocumentType.css";
import {PARTNER_TYPE, SELECTED_COMPANY_CODE, SELECTED_SURVEY_ID} from "../../../constants";
import {insertSurveyChannels} from "./DocumentTypeService";
import {createSelectCellEditor, SELECT_TYPE} from "./SelectCellEditor";
import {useLazyQuery} from "@apollo/client";
import {GET_ALL_DEFAULT_PROCESS_CHANNEL_FORMATS} from "./queries";
import {showErrorWithHeaderNotification, showSuccessWithHeaderNotification} from "../../Notification";
import {StateContainer} from "../utilities/StateContainer";
import {useAppSelector} from "../../../main/hooks";
import {MODE} from "../IStoredSurvey";
import {cloneDeep} from "lodash";

export const FormatRowEditor = createSelectCellEditor(
  {selectType: SELECT_TYPE.FORMAT}
);


export const ChannelRowEditor = createSelectCellEditor(
  {selectType: SELECT_TYPE.CHANNEL}
);

type ISurveySupplierDocumentTypeTabProps = {
  next?: any;
  back?: any;
  channelData?: any;
  selectedPartnerNumber?: string;
  selectedPartnerCompanyCode?: string;
  surveyId: number;
  selectedTab: SUPPLIER_DOC_TYPES;
  onStatusUpdate?: any;
  stateContainer?: StateContainer;
  includedSurveys?: string,
  key: any;
}

type IProcessedFormatChannelOption = {
  processCode: string,
  selectFormat: string,
  channelOption: SelectProps["options"]
};
const SurveySupplierDocumentTypeTab: React.FunctionComponent<ISurveySupplierDocumentTypeTabProps> = (props) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [gridApi, setGridApi] = useState<GridApi>(undefined);
  const {TabPane} = Tabs;
  const [loading, isLoading] = useState(false);
  const [formatLoading, isFormatLoading] = useState(false);
  const [channelLoading, isChannelLoading] = useState(false);
  const [saveClicked, isSaveClicked] = useState(false);
  const [sbi_updatedChannel, setAbi_updatedChannel] = useState(undefined);
  const [channels, setChannels] = useState([]);
  const [processedFormatChannelOptions, setProcessedFormatChannelOptions] = useState<IProcessedFormatChannelOption[]>([]);

  const [getChannelData, {}] = useLazyQuery(GET_ALL_DEFAULT_PROCESS_CHANNEL_FORMATS);

  const surveyState = useAppSelector((state) => state.survey);

  const isViewMode = () => {
    return surveyState.survey.surveyMode === MODE.VIEW
  }

  const isOverViewMode = () => {
    return surveyState.survey.surveyMode === MODE.OVERVIEW
  }

  const isReadOnly = () => {
    return isViewMode() || isOverViewMode();
  }


  const selectHint = intl.formatMessage({
    id: "survey-supplier-doc-type-tab-grid-channel-format-select-hint",
    defaultMessage: "Select"
  });

  const processChannelHeaderDetails = [intl.formatMessage({
    id: "survey-supplier-doc-type-tab-grid-header-process-code",
    defaultMessage: "Process Code"
  }),
    intl.formatMessage({
      id: "survey-supplier-doc-type-tab-grid-header-process-code-description",
      defaultMessage: "Process Code Description"
    }),
    intl.formatMessage({
      id: "survey-supplier-doc-type-tab-grid-header-format",
      defaultMessage: "Current Format"
    }), intl.formatMessage({
      id: "survey-supplier-doc-type-tab-grid-header-channel",
      defaultMessage: "Current Channel"
    }), intl.formatMessage({
      id: "survey-supplier-doc-type-tab-grid-header-updated-format",
      defaultMessage: "Updated Format"
    }), intl.formatMessage({
      id: "survey-supplier-doc-type-tab-grid-header-updated-channel",
      defaultMessage: "Updated Channel"
    })];


  const save = () => {
    const inputData = [];
    let channelSelected: boolean = false;

    channels.map(item => {
      if (props.selectedTab === SUPPLIER_DOC_TYPES.INCOMING) {
        if (form.getFieldValue("INC-updatedFormat") !== undefined) {
          channelSelected = true;
        }
      }
      if (props.selectedTab === SUPPLIER_DOC_TYPES.OUTGOING) {
        if (form.getFieldValue(`${item.processCode}-updatedFormat`) !== undefined) {
          channelSelected = true;
        }
      }
      if (props.selectedTab === SUPPLIER_DOC_TYPES.PAYMENT_ADVICE) {
        if (form.getFieldValue("PAD-updatedFormat") !== undefined) {
          channelSelected = true;
        }
      }
    });

    if (channelSelected) {
      isLoading(true);
      channels.filter(process => process.processCode !== "ECAP").map(channel => {
        let selectedChannel = undefined;
        let selectedProvider = undefined;

        if (form.getFieldValue(`${channel.processCode}-updatedChannel`)) {
          const selectedOption = form.getFieldValue(`${channel.processCode}-updatedChannel`).split("-");
          selectedChannel = selectedOption[0];
          selectedProvider = selectedOption[1];
        }
        inputData.push({
          surveyId: Number(localStorage.getItem(SELECTED_SURVEY_ID)),
          surveyChannelId: channel.surveyChannelId,
          processCodeId: channel.processCodeId,
          documentType: channel.documentType,
          format: form.getFieldValue(`${channel.processCode}-updatedFormat`) ? form.getFieldValue(`${channel.processCode}-updatedFormat`).split("-")[0] : channel.format,
          channel: selectedChannel ? selectedChannel : channel.channel,
          provider: selectedProvider ? selectedProvider : channel.provider,
          channelDefault: form.getFieldValue(`${channel.processCode}-updatedChannel`) !== undefined
            || form.getFieldValue(`${channel.processCode}-updatedChannel`) !== undefined
        });
      });


      Promise.resolve(insertSurveyChannels(props.includedSurveys, inputData)).then(data => {
        const incomingChannels = [];
        const outgoingChannels = [];
        const paymentAdviceChannels = [];
        if (Array.isArray(data)) {
          data.forEach(item => {
            const updatedChannel = {surveyChannelId: item.surveyChannelId, ...item};

            const selectedProcess =  props.channelData.filter(fltr => fltr.processCode === item.processCode);

            updatedChannel.formatOptions = selectedProcess[0]?.formatOptions;

            if (item.processCode === "INC" || item.processCode === "ECAP") {
              incomingChannels.push(updatedChannel);
            } else if (item.processCode === "PAD") {
              paymentAdviceChannels.push(updatedChannel);
            } else {
              outgoingChannels.push(updatedChannel);
            }
          });

        }

        if (props.selectedTab === SUPPLIER_DOC_TYPES.OUTGOING) {
          setChannels(outgoingChannels);
        }


        if (props.selectedTab === SUPPLIER_DOC_TYPES.INCOMING) {
          setChannels(incomingChannels);
        }


        if (props.selectedTab === SUPPLIER_DOC_TYPES.PAYMENT_ADVICE) {
          setChannels(paymentAdviceChannels);
        }

        isSaveClicked(true);

        props.onStatusUpdate();

        showSuccessWithHeaderNotification(
          intl.formatMessage({
            id: "survey-eDocument-successfully",
            defaultMessage: "Successfully"
          }),
          intl.formatMessage({
            id: "survey-eDocument-supplier-export-mode-channel-save-pass-message",
            defaultMessage: "EDI Channel Format successfully saved"
          }));
        isLoading(false);
      }).catch(error => {
        showErrorWithHeaderNotification(
          intl.formatMessage({
            id: "Download Error",
          }),
          intl.formatMessage({
            id: "survey-eDocument-supplier-export-mode-channel-save-fail-error",
            defaultMessage: error
          }));
        console.error(error);
        isLoading(false);
      });
    } else {
      showErrorWithHeaderNotification(
        intl.formatMessage({
          id: "Download Error",
        }),
        intl.formatMessage({
          id: "survey-eDocument-supplier-export-mode-channel-not-selected-error",
          defaultMessage: "NO EDI Channel selected"
        }));
    }

  };

  const {Title} = Typography;

  const onSurveyFormatChanged = (value: string) => {
    if (value) {
      isChannelLoading(true);

      const channelOptions: SelectProps["options"] = [];
      const processCodeFormat = value.split("-");
      const fieldName = `${processCodeFormat[1]}-updatedChannel`;

     form.resetFields([fieldName]);



      Promise.resolve(getFormats(localStorage.getItem(PARTNER_TYPE), localStorage.getItem(SELECTED_COMPANY_CODE), processCodeFormat[1],
        processCodeFormat[0] === "ECAP" || processCodeFormat[1] === "INC" ? "IN" : "OUT")).then(formatsData => {
        formatsData.format.filter(flt => flt.formatName === processCodeFormat[0]).map((item) => {
          item.channel.map((channelItem) => {
            channelOptions.push({
              value: `${channelItem.channelName}-${channelItem.provider}`,
              label: channelItem.channelName
            });
          });
        });
        // they will be always one entry per processCode
        if (processedFormatChannelOptions.filter(item => item.processCode === processCodeFormat[1]).length === 1) {
          processedFormatChannelOptions[processedFormatChannelOptions.findIndex(item => item.processCode === processCodeFormat[1])].channelOption = channelOptions;
          setProcessedFormatChannelOptions(processedFormatChannelOptions);

        }
        setAbi_updatedChannel(channelOptions);
        isChannelLoading(false);
      });
    }
  };


  useEffect(() => {
    if (channels?.length === 0) {
      const processedFormatChannelOptions: IProcessedFormatChannelOption[] = [];
      setChannels(props.channelData);
      props.channelData?.map(item => {
        processedFormatChannelOptions.push({
          processCode: item.processCode,
          selectFormat: "PDF",
          channelOption: [{value: "PORTAL-BMW", label: "PORTAL"}, {value: "SMTP-OPENTEXT", label: "SMTP"}]
        });
      });
      setProcessedFormatChannelOptions(processedFormatChannelOptions);

    }
  }, [channels]);

  return (
    <>
      <Spin spinning={loading} size={"large"}>
        <Form form={form}
              onFinish={save}>
          <Row gutter={24} justify={"start"}>
            <Col className="mb5">
              <Title level={5}>{intl.formatMessage({
                id: "survey-supplier-doc-type-tab-selected-partner-title",
                defaultMessage: "Selected Partner:"
              })} {props.selectedPartnerNumber} - {props.selectedPartnerCompanyCode}</Title>
            </Col>
          </Row>
          <Row gutter={24} justify={"start"}>
            <Col className="mb5">
              <Tooltip title={intl.formatMessage({id: 'survey-supplier-doc-type-back'})} key={"backKey"}>
                <ArrowLeftOutlined style={{fontSize: "20px", color: "#4a576d", cursor: "pointer"}}
                                   onClick={props.back}/>
              </Tooltip>
            </Col>
            <div hidden={isReadOnly()}>
              <Col className="mb5">
                <Tooltip title={intl.formatMessage({id: 'survey-supplier-doc-type-save'})}
                         key={"backKey"}>
                  <Button type="text" size="middle" className="w-100" htmlType={"submit"}>
                    <SaveOutlined style={{fontSize: "20px", color: "#4a576d", cursor: "pointer"}}/>
                  </Button>
                </Tooltip>
              </Col>
            </div>

            <div style={{flex: "auto", paddingTop: "0px"}}>

              <table className="styled-table" style={{tableLayout: "fixed", width: "100%"}}>
                <thead>
                <tr key={"header-key"}>
                  {processChannelHeaderDetails.map(item => <th key={item}>{item} </th>)}
                </tr>
                </thead>
                <tbody>
                {channels && channels.map((item, index) =>
                  <tr key={`tr${index}`}>
                    <td key={`${item.processCodeName}ID-${index}`}
                        id={`id_${item.channel}-ID-${index}`}> {item.processCodeName}</td>
                    <td key={`${item.processCode}-ID-${index}`}
                        id={`id_${item.channel}-ID-${index}`}> {item.processCode}</td>
                    <td key={`${item.format}-ID-${index}`}
                        id={`id_${item.channel}-ID-${index}`}> {item.format}</td>
                    <td key={`${item.channel}-ID-${index}`}
                        id={`id_${item.channel}-ID-${index}`}> {item.channel}</td>
                    <td key={`${item.processCode}-${item.updatedFormat}ID-${index}`}
                        id={`id_${item.processCode}-${item.updatedFormat}ID-${index}`}>
                      <Form.Item
                        label=""
                        name={`${item.processCode}-updatedFormat`}
                        wrapperCol={{span: 20}}
                      >
                        {item.processCode === "ECAP" ?
                          <Input size={"small"} disabled={true} defaultValue={"ECAP"}
                                 key={`input-key_${item.processCode}-${item.updatedChannel}ID-${index}`}/> :
                          <Select size={"small"} disabled={isReadOnly()}
                                  placeholder={selectHint}
                                  onChange={onSurveyFormatChanged}
                                  loading={formatLoading} allowClear={true}
                                  key={`select-key_${item.processCode}-${item.updatedChannel}ID-${index}`}>
                            {item.formatOptions?.map((entry) => (
                              <Select.Option key={`key-${entry}-${item.processCode}`} id={`id-${entry}-${item.processCode}`}
                                             value={`${entry}-${item.processCode}`}>
                                {entry}
                              </Select.Option>
                            ))}
                          </Select>
                        }
                      </Form.Item>

                    </td>
                    <td key={`${item.processCode}-${item.updatedChannel}ID-${index}`}
                        id={`id_${item.processCode}-${item.updatedChannel}ID-${index}`}>
                      <Form.Item
                        label=""
                        name={`${item.processCode}-updatedChannel`}
                        wrapperCol={{span: 20}}
                        rules={[{required: form.getFieldValue(`${item.processCode}-updatedFormat`) !== undefined}]}>
                        {item.processCode === "ECAP" ?
                          <Input size={"small"} disabled={true} defaultValue={"PORTAL"}
                                 key={`input-key_${item.processCode}-${item.updatedChannel}ID-${index}`}/> :
                          <Select size={"small"} disabled={isReadOnly()}
                                  placeholder={selectHint} allowClear={true}
                                  loading={channelLoading}
                                  key={`select-key_${item.processCode}-${item.updatedChannel}ID-${index}`}>
                            {processedFormatChannelOptions && processedFormatChannelOptions
                              .filter(entry => entry.processCode === item.processCode)
                              .map(process => process.channelOption
                                .map(channels =>
                                  <Select.Option
                                    key={`${channels}-${item.processCode}`}
                                    value={channels.value}>
                                    {channels.label}
                                  </Select.Option>
                                ))}
                          </Select>
                        }
                      </Form.Item>

                    </td>
                  </tr>)}
                </tbody>
              </table>

            </div>
          </Row>
        </Form>
      </Spin>
    </>
  );
};

export default SurveySupplierDocumentTypeTab;