import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStoreMissingInvoicesFilters } from './IStoredMissingInvoices';

interface MissingInvoicesState {
    missingInvoicesFilters: IStoreMissingInvoicesFilters;
}

const initialState: MissingInvoicesState = {
    
    missingInvoicesFilters: {       
        receptionDateFrom: undefined,
        receptionDateTo: undefined,
    }
};


const missingInvoicesSlice = createSlice({
    name: 'MissingInvoicesFilters',
    initialState,
    reducers: {
    updateMissingInvoicesFilters(state, action: PayloadAction<IStoreMissingInvoicesFilters>) {
    state.missingInvoicesFilters= action.payload
}
}},)

export const {
    updateMissingInvoicesFilters
} = missingInvoicesSlice.actions;
export default missingInvoicesSlice.reducer ;
