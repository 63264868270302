import { gql } from "@apollo/client";


export const ALL_AGREEMENTS_FOR_SURVEY_TYPE = gql`
    query getSurveyAgreements($agreementTypeId: Float!){
        getSurveyAgreementDocList(portalAgreementTypeId: $agreementTypeId) {
            agreementDescription
            agreementId
            agreementName
            agreementTypeId
            blobId
            languageCode
        }
    }
`;

export const ALL_AGREEMENT_STATUS_CODES = gql`
    query getSurveyAgreementStatusCodes{
        getSurveyAgreementStatusList {
            id
            agreementStatus,
            phraseId
        }
    }
`;

export const GET_AGREEMENT_APPROVAL_DATA = gql`
    query getSurveyAgreementApprovalData($groupId: String!){
        getSurveyAgreementApprovalData(groupId: $groupId) {
            agreementApproverEmail
            agreementApproverName
            agreementStatusId
            agreementStatusDate
        }
    }
`;


export const DOWNLOAD_SURVEY_DOCUMENT_QUERY_BY_BLOB_ID = gql`
    query downloadSurveyDocument($blobId: Float!){
        downloadSurveyDocument(blobId : $blobId)
        {
            success
            value {
                encodedFileData
                fileName
                mimeType
                }
            }
    }
`;

