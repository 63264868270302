import { Input } from "antd";
import client from "../../../config/GraphQLApolloClient";
import { MY_DOCS_DRAFT_DATA } from "../queries";
import { DraftDocumentList, DraftDocumentListVariables } from "../__generated__/DraftDocumentList";
import { getI18n } from "../../../utils/Utils";
import { IntlShape } from "react-intl/src/types";


export const getDraftDocument = (username: string, input, intl: IntlShape) => {

  return new Promise<DraftDocumentList>((resolve, reject) => {
    client.query<DraftDocumentList, DraftDocumentListVariables>({
      query: MY_DOCS_DRAFT_DATA,
      variables: { draftListInput: input },

      fetchPolicy: "network-only"
    })
      .then((draftdocuments) => {

        const translatedDaftDocumentList = [];

        draftdocuments.data?.DraftDocumentList.forEach(item => {
          translatedDaftDocumentList.push({ ...item, documentType: getI18n(item.phraseId, item.documentType, intl) });
        });

        const response: DraftDocumentList = { DraftDocumentList: translatedDaftDocumentList };
        resolve(
          response
        );
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};