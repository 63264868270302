import { gql } from "@apollo/client";

export const ADD_PORTAL_USER = gql`
    mutation createUser($portalUserInput: PortalUserInput!) {
        addPortalUser(portalUserInput: $portalUserInput) {
            username
            firstname
            lastname
            email
            roleName
        }
    }
`
export const ASSIGN_PARTNER = gql`
    mutation assignPartner($assignPartner: AssignPartner!) {
        assignPartner(assignPartner: $assignPartner) {
            partnerId
            partnerNo
            partnerName
            category
        }
    }
`;

export const UPDATE_USER_SETTINGS = gql`
    mutation SetUserPreferencesAPI($preferences: SetUserPreferences!) {
        mutateUserPreferences(preferences: $preferences) {
            language
            decimalSeparator
            thousandSeparator
            dateFormat
            userSettingsId
        }
    }
`;
