import React, { useEffect, useState } from 'react';
import { IAppComponentProps } from '../../../components';
import {
    Button,
    Card,
    Col,
    ConfigProvider,
    Divider,
    Form,
    Input,
    message,
    Row,
    Spin,
    Statistic,
    Switch,
    Tabs,
    TabsProps,
    Typography,
} from 'antd';
import enUS from 'antd/lib/locale/en_US';
import { PageContainer } from '@ant-design/pro-layout';
import styles from '../../../styles/scss/portal.scss';
import { INCOMING_DOCS_DETAILS_COLS } from './utils';
import { GridApi } from 'ag-grid-community';
import DocumentDetailGrid from './DocumentDetailGrid';
import client from '../../../config/GraphQLApolloClient';
import { REPROCESS_DOCUMENT_RECORDS } from './mutations';
import DocumentDetailDatasource from './DocumentDetailDatasource';
import { useIntl } from "react-intl";

const gridOptions = {
    INCOMING_DOCS_DETAILS_COLS,

    // use the server-side row model
    rowModelType: 'infinite',

    // fetch 15 rows at a time
    cacheBlockSize: 15,

    // only keep 10 blocks of rows
    maxBlocksInCache: 10,

    sideBar: true,

    enableColResize: true,
    enableSorting: true,
    enableFilter: true,
    showLoadingOverlay: true,

    columnTypes: {
        dimension: {
            enableRowGroup: true,
            enablePivot: true,
        },
        valueColumn: {
            width: 150,
            aggFunc: 'sum',
            enableValue: true,
            cellClass: 'number',
            allowedAggFuncs: ['avg', 'sum', 'min', 'max'],
        },
    },
};

const InfoLogMain: React.FunctionComponent<IAppComponentProps> = (props) => {
    const [form] = Form.useForm();
    const intl = useIntl();

    const [documentDetailGridApi, setDocumentDetailGridApi] = useState<GridApi>(undefined);
    const [loading, isLoading] = useState(false);
    const [documentDetailDatasource, setDocumentDetailDatasource] = useState<DocumentDetailDatasource>(undefined);

    const [activeTabKey, setActiveTabKey] = useState('1');
    const currentUser = props.currentUser;
    const [gridParams, setGridParams] = useState<any>();

    useEffect(() => {
        if (documentDetailDatasource === undefined) {
            setDocumentDetailDatasource(new DocumentDetailDatasource(gridOptions, 30));
        }
    }, [documentDetailDatasource]);

    const { TabPane } = Tabs;
    const sizes: React.CSSProperties = {
        height: '150%',
        width: '98%',
    };

    const onDocumentDetailExport = () => {
        if (documentDetailGridApi !== undefined)
        {
            documentDetailGridApi.exportDataAsExcel({
                onlySelectedAllPages: true,
            });
        }
    };

    //Required to use Server side data data model
    const onDetailGridReady = (params) => {
        // console.log("documentDetailDatasource");
        // console.dir(documentDetailDatasource);
        params.api.setDatasource(documentDetailDatasource);
        setDocumentDetailGridApi(params.api);
        setGridParams(params);

        // Show loading overlay when the data source starts loading
        documentDetailDatasource.on('isLoadingChanged', isLoading => {
            // console.log(`isloading: ${isLoading}`);
            if (isLoading) {
                params.api.showLoadingOverlay();
            } else {
                params.api.hideOverlay();
                params.columnApi.autoSizeAllColumns();
            }
        });
    };

    const refreshData = () => {
        
        const days = parseInt(form.getFieldValue('processErrorsDays'));
        setDocumentDetailDatasource(new DocumentDetailDatasource(gridOptions, days));
        documentDetailDatasource.setDays(days);
        if (documentDetailGridApi !== undefined) {
            documentDetailGridApi.setDatasource(documentDetailDatasource);
        }

        // Show loading overlay
        if (documentDetailGridApi) {
            documentDetailGridApi.showLoadingOverlay();
        }

          // Return a Promise that resolves when the data fetch is complete
        return new Promise(resolve => {
            documentDetailDatasource.on('isLoadingChanged', isLoading => {
            if (!isLoading) {
                resolve(documentDetailDatasource);
            }
            });
        });
    };

    const refreshGrid = () => {
        refreshData().then(() => onDetailGridReady(gridParams));
    }

    const onReprocessDocumentData = () => {
        //
        const selectedData = documentDetailGridApi.getSelectedRows();
        //console.dir(selectedData);
        const processingDocumentIdList = [];
        selectedData.map((item) => {
            processingDocumentIdList.push({ ecDocumentId: item.id});
        });
        isLoading(true);
        client
            .mutate({
                mutation: REPROCESS_DOCUMENT_RECORDS,
                variables: { documentIdList: processingDocumentIdList },
            })
            .then((response) => {
                //console.log('Response from server');
                //console.dir(response);
                isLoading(false);
                message.info(`${response.data?.reprocessECDocuments} records re-processed`);
                // Call onDetailGridReady after refreshData has finished fetching data
                refreshGrid();
            })
            .catch((error) => {
                isLoading(false);
                message.error(`${error}`);
            });

    }

    const onTabChange = (key) => {
        setActiveTabKey(key);
    };

    const items: TabsProps['items'] = [
        {
            key: "1",
            label: 'Document Overview',
            children: (
                <div>
                    <Row gutter={24}>
                        <Col span={2}>
                            <Button type="primary" onClick={onReprocessDocumentData}>
                                Reprocess Selected
                            </Button>
                        </Col>
                        <Col span={2}>
                            <Button
                                type="primary"
                                onClick={onDocumentDetailExport}
                                className={'mr25'}
                            >
                                Export selected
                            </Button>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col md={24} sm={12} xs={24}>
                            <div style={{ flex: 'auto', height: '516px', paddingTop: '10px' }}>
                                <DocumentDetailGrid
                                    currentUser={currentUser}
                                    intl={props.intl}
                                    loading={loading}
                                    isLoading={isLoading}
                                    onGridReady={onDetailGridReady}
                                    rowData={documentDetailDatasource}
                                    distinctEntitlements={props.distinctEntitlements}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            ),
        },
    ];


    return (
        <ConfigProvider locale={enUS}>
            <Spin spinning={loading} size={'large'}>
                <PageContainer
                    title={false}
                    content={''}
                    extraContent={''}
                    className={styles.users}
                    style={{ background: 'white' }}
                >
                    <Form size={'middle'} form={form} layout={'vertical'} initialValues={{ processErrorsDays: 30 }}>
                        <div style={{ ...sizes, minHeight: '200px' }} className="pl15 ag-theme-balham">
                            <Row gutter={24}>
                                <Col md={24} sm={12} xs={24}>
                                    <div className="pl15">
                                        <h1> {intl.formatMessage({id: "info-log-dashboard-title", 
                                                defaultMessage: "DOCUMENT TECHNICAL DASHBOARD"
                                             })}
                                        </h1>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={2}>
                                    <Form.Item
                                        name="processErrorsDays"
                                        id="processErrorsDays"
                                        label={intl.formatMessage({id: "info-log-dashboard-processing-days", 
                                        defaultMessage: "Processing Days"
                                     })}    
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={2}>
                                    <Button type="primary" onClick={refreshGrid} className={'mr25'}>
                                        Refresh Data
                                    </Button>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col md={24} sm={12} xs={24}>
                                    <Tabs
                                        defaultActiveKey={'1'}
                                        className="user-tabs"
                                        activeKey={activeTabKey}
                                        items={items}
                                        onChange={(key) => {
                                            onTabChange(key);
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </PageContainer>
            </Spin>
        </ConfigProvider>
    );
};

export default InfoLogMain;
