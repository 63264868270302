import React, { useState } from 'react';
import { EntitlementFormProps, GetEntitlementRowStyle } from './EntitlementInterfaces';
import { Button, Card, Col, Form, Input, message, Row, Spin } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import { getAgGridLocalization } from '../../info-portal/components/grid/GridUtil';
import { IntlShape } from 'react-intl';
import { GridApi } from 'ag-grid-community';
import { EntitlementGroup, EntitlementGroupInput } from '../../../__generated__/types';
import { useGetEntitlementGroupListQuery } from './queries.generated';
import { getI18n } from '../../../utils/Utils';
import { UpdateEntitlementGroupsMutationVariables, useUpdateEntitlementGroupsMutation } from './mutations.generated';
import { ReloadOutlined, SaveOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { setGroupUpdated, updateMasterDataUpdated } from './redux/IStoredEntitlementsSlice';

function GROUP_TABLE_HEADERS(intl: IntlShape, onDelete: any, onEdit: any) {
    return [
        {
            headerName: intl.formatMessage({ id: 'entitlements-grid-header-action' }),
            filter: false,
            cellRenderer: 'buttonRenderer',
            cellRendererParams: {
                onClick: onEdit,
                label: 'Edit',
                type: 'primary',
            },
            width: 100,
        },
        {
            headerName: intl.formatMessage({ id: 'entitlements-grid-header-action' }),
            filter: false,
            cellRenderer: 'buttonRenderer',
            cellRendererParams: {
                onClick: onDelete,
                label: 'Delete',
                type: 'delete',
            },
            width: 110,
        },
        {
            headerName: intl.formatMessage({ id: 'entitlements-master-data-group-group-id-grid-header-name' }),
            field: 'groupId',
            colId: 'groupId',
            width: 200,
            filter: 'agMultiColumnFilter',
        },
        {
            headerName: intl.formatMessage({ id: 'entitlements-master-data-group-description-grid-header-name' }),
            field: 'description',
            colId: 'description',
            width: 300,
            filter: 'agMultiColumnFilter',
        },
        {
            headerName: intl.formatMessage({ id: 'entitlements-master-data-group-phrase-id-grid-header-name' }),
            field: 'phraseId',
            colId: 'phraseId',
            width: 300,
            filter: 'agMultiColumnFilter',
        },

        {
            headerName: intl.formatMessage({ id: 'entitlements-grid-header-insert-user' }),
            field: 'insertUser',
            colId: 'insertUser',
            width: 150,
            filter: 'agMultiColumnFilter',
            cellRenderer: (params) => (params.value ? params.value.toUpperCase() : '')
        },
        {
            headerName: intl.formatMessage({ id: 'entitlements-grid-header-insert-time' }),
            field: 'insertTime',
            colId: 'insertTime',
            width: 200,
            filter: 'agDateColumnFilter',
        },
        {
            headerName: intl.formatMessage({ id: 'entitlements-grid-header-update-user' }),
            field: 'updateUser',
            colId: 'updateUser',
            width: 150,
            filter: 'agMultiColumnFilter',
            cellRenderer: (params) => (params.value ? params.value.toUpperCase() : '')
        },
        {
            headerName: intl.formatMessage({ id: 'entitlements-grid-header-update-time' }),
            field: 'updateTime',
            colId: 'updateTime',
            width: 200,
            filter: 'agDateColumnFilter',
        },
        {
            headerName: getI18n('entitlements-grid-header-volume', '#Times used', intl),
            field: 'volume',
            colId: 'volume',
            width: 150,
            filter: 'agNumberColumnFilter',
        },
    ];
}

const EntitlementMasterDataEditGroupsComponent: React.FunctionComponent<EntitlementFormProps> = (props) => {
    const [form] = Form.useForm();

    const [gridApi, setGridApi] = useState<GridApi>(undefined);
    const [groupList, setGroupList] = useState<EntitlementGroup[]>([]);
    const [newGroups, setNewGroups] = useState<EntitlementGroup[]>([]);
    const [deletedGroups, setDeletedGroups] = useState<EntitlementGroupInput[]>([]);
    const [updatedGroups, setUpdatedGroups] = useState<EntitlementGroupInput[]>([]);
    const [updateMode, setUpdateMode] = useState<boolean>(false);
    const [refreshLoading, setRefreshLoading] = useState(false);
    const dispatch = useDispatch();

    const [UpdateEntitlementGroupsMutation, { data: updateResponse, loading: updateLoading, error: updateError }] =
        useUpdateEntitlementGroupsMutation({
            variables: {
                entitlementGroups: [] as EntitlementGroupInput[],
            },
        });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        //check if Group already exists in the list
        const exists = groupList.find((group) => group.groupId === value);
        if (exists && !updateMode) {
            message.error('Group Id already exists');
        }
        form.setFieldsValue({ phraseId: 'entitlement-group-'.concat(form.getFieldValue('groupId')) });
        form.setFieldsValue({ groupId: form.getFieldValue('groupId').toUpperCase() });
    };

    const addGroup = () => {
        const exists = groupList.find((group) => group.groupId === form.getFieldValue('groupId'));

        if (!exists) {
            //insert new entitlement
            const groupId = form.getFieldValue('groupId');
            const description = form.getFieldValue('description');
            const phraseId = form.getFieldValue('phraseId');
            const insertUser = props.currentUser.username;
            const updateUser = props.currentUser.username;
            const updateAction = 'insert';
            const newRecord = { groupId, description, phraseId, insertUser, updateUser, updateAction, volume: 0 };
            setNewGroups([...newGroups,newRecord]);
            setGroupList([...groupList, newRecord]);
            form.setFieldValue('groupId', '');
            form.setFieldValue('description', '');
            form.setFieldValue('phraseId', '');
            dispatch(updateMasterDataUpdated(true));
        } else if (updateMode) {
            const groupId = form.getFieldValue('groupId');
            const description = form.getFieldValue('description');
            const phraseId = form.getFieldValue('phraseId');
            const updateUser = props.currentUser.username;
            const updateAction = 'update';
            const updatedRecord = {groupId,description,phraseId,insertUser: exists.insertUser,insertTime: exists.insertTime, updateTime: exists.updateTime,updateUser,updateAction,volume: exists.volume};
            setUpdatedGroups([...updatedGroups,updatedRecord]);
            setGroupList(
                groupList.map((item) =>
                    item.groupId === groupId
                        ? updatedRecord
                        : item
                )
            );
            form.setFieldValue('groupId', '');
            form.setFieldValue('description', '');
            form.setFieldValue('phraseId', '');
            setUpdateMode(false);
            dispatch(updateMasterDataUpdated(true));
        } else {
            message.error('Group already exists');
        }
    };

    const cancelUpdate = () => {
        setUpdateMode(false);
        form.setFieldValue('groupId', '');
        form.setFieldValue('description', '');
        form.setFieldValue('phraseId', '');
    };

    const onGridReady = (params) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
    };

    const ButtonRenderer = (props) => {
        const handleClick = () => {
            props.onClick(props);
        };

        return (
            <Button onClick={handleClick} className={`btn btn-${props.type}`}>
                {props.label}
            </Button>
        );
    };

    const components = {
        buttonRenderer: ButtonRenderer,
    };

    const onDeleteLine = (params) => {
        if (params.data.volume > 0) {
            message.error(
                `Group was used ${params.data.volume} times in Entitlement Rules and cannot be delete`
            );
            return;
        }
        params.api.applyTransaction({ remove: [params.data] });
        const newList = groupList.filter(
            (group) => group.groupId !== params.data.groupId
        );
        setGroupList(newList);
        //If not insert then also add to deleted list
        if (params.data.updateAction !== 'insert') {
            const groupId = params.data.groupId;
            const description = params.data.description;
            const phraseId = params.data.phraseId;
            const updateUser = props.currentUser.username;
            const updateAction = 'delete';
            setDeletedGroups([
                ...deletedGroups,
                { groupId, description, phraseId, updateUser, updateAction },
            ]);
            dispatch(updateMasterDataUpdated(true));
        }
    };

    const onEditLine = (params) => {
        form.setFieldValue('groupId', params.data.groupId);
        form.setFieldValue('description', params.data.description);
        form.setFieldValue('phraseId', params.data.phraseId);
        if (params.data.updateAction !== 'insert') {
            setUpdateMode(true);
        }
    };

    const {
        data: groupData,
        loading: groupLoading,
        error: groupError,
        refetch: groupRefresh,
    } = useGetEntitlementGroupListQuery({
        variables: {},
    });

    const saveGroup = () => {
        //save all Groups
        //call mutation for save
        const toBeUpdatedGroups = [...newGroups, ...updatedGroups, ...deletedGroups];
        if (toBeUpdatedGroups && toBeUpdatedGroups.length > 0) {
            UpdateEntitlementGroupsMutation({
                variables: { entitlementGroups: toBeUpdatedGroups } as UpdateEntitlementGroupsMutationVariables,
            })
                .then((response) => {
                    //find the entitlements in responds in the entitlementTypes and update the list
                    const updatedGroups = response.data.updateEntitlementGroups;
                    const updatedList = groupList.map((group) => {
                        const updatedGroup = updatedGroups.find((updated) => updated.groupId === group.groupId);
                        return updatedGroup ? { ...updatedGroup, updateAction: null } : group;
                    });
                    setGroupList(updatedList);
                    setNewGroups([]);
                    setUpdatedGroups([]);
                    setDeletedGroups([]);
                    dispatch(updateMasterDataUpdated(false));
                    dispatch(setGroupUpdated(true));

                    message.success(
                        getI18n(
                            'entitlement-master-data-edit-success-update-message',
                            'Updated successfully',
                            props.intl
                        )
                    );
                })
                .catch((error) => {
                    console.dir(error);
                });
        } else {
            message.error('Nothing to update');
        }
    };

    React.useEffect(() => {
        props.onLoading(groupLoading);
        if (!groupLoading && groupData) {
            setGroupList(groupData.getEntitlementGroupList);
        }
    }, [groupLoading, groupData]);

    const refreshData = () => {
        setRefreshLoading(true);
        groupRefresh().then(({ data }) => {
            setGroupList(data.getEntitlementGroupList);
            if (gridApi) {
                gridApi.setRowData(data.getEntitlementGroupList);
            }
            setRefreshLoading(false);
        }).catch(() => {
            setRefreshLoading(false);
        });
    };


    return (
        <>
            <Spin spinning={updateLoading || groupLoading|| refreshLoading}>
                <Card>
                    <Form form={form} layout="vertical" onFinish={addGroup}>
                        <Row gutter={24} className={'pl20'}>
                            <Col span={2}>
                                <Button type={'primary'} onClick={saveGroup} icon={<SaveOutlined />}>
                                    Save all Groups
                                </Button>
                            </Col>
                            <Col span={3}>
                                <Button type="default" icon={<ReloadOutlined />} onClick={refreshData}>
                                    Refresh
                                </Button>
                            </Col>
                        </Row>
                        <Row gutter={24} className={'pl20'}>
                            <Col span={2}>
                                <Form.Item
                                    name={'groupId'}
                                    key={'groupId'}
                                    rules={[{ required: !updateMode, message: 'Please input the Group Id!' }]}
                                    label={props.intl.formatMessage({
                                        id: 'entitlements-master-data-group-group-id-grid-header-name',
                                    })}
                                >
                                    <Input type="text" onChange={handleInputChange} placeholder="Group Id" readOnly={updateMode}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name={'description'}
                                    key={'description'}
                                    rules={[{ required: true, message: 'Please input the Description!' }]}
                                    label={props.intl.formatMessage({
                                        id: 'entitlements-master-data-group-description-grid-header-name',
                                    })}
                                >
                                    <Input type="text" placeholder="Group Description" />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name={'phraseId'}
                                    key={'phraseId'}
                                    rules={[{ required: true, message: 'Please input the Phrase Id!' }]}
                                    label={props.intl.formatMessage({
                                        id: 'entitlements-master-data-group-phrase-id-grid-header-name',
                                    })}
                                >
                                    <Input type="text" placeholder="Phrase Id" />
                                </Form.Item>
                            </Col>
                            <Col span={1}>
                                <Button type="primary" onClick={() => form.submit()}>
                                    {updateMode ? 'Update Group' : 'Add Group'}
                                </Button>
                            </Col>
                            <Col span={1}>
                                <Button type="primary" onClick={cancelUpdate}>
                                    {updateMode ? 'Cancel Update' : 'Clear'}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    <Row gutter={24} className={'pl20'}>
                        <Col span={22}>
                            <div
                                style={{ flex: 'auto', height: '500px', paddingTop: '10px' }}
                                className="ag-theme-balham"
                            >
                                <AgGridReact
                                    defaultColDef={{
                                        enableValue: true,
                                        sortable: true,
                                        resizable: true,
                                    }}
                                    rowData={groupList}
                                    columnDefs={GROUP_TABLE_HEADERS(props.intl, onDeleteLine, onEditLine)}
                                    localeText={getAgGridLocalization(props.intl)}
                                    sideBar={false}
                                    rowClass="agGridRows"
                                    suppressMenuHide={true}
                                    debug={false}
                                    pagination={true}
                                    paginationPageSize={15}
                                    suppressRowClickSelection={true}
                                    onGridReady={onGridReady}
                                    rowSelection={'multiple'}
                                    components={components}
                                    getRowStyle={GetEntitlementRowStyle}
                                />
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Spin>
        </>
    );
};

export default EntitlementMasterDataEditGroupsComponent;
