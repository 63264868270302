import React, { FunctionComponent, useEffect, useState } from "react";
import { Button, Card, Checkbox, Col, Form, Input, Row, Select, Spin, Typography, message } from "antd";
import dayjs from 'dayjs';
import { getSurveyAgreementsDocs
    , downloadSurveyDocumentByBlobId } from "./SurveyAgreementService";
import Paragraph from "antd/es/typography/Paragraph";
import { getSurveyAgreements_getSurveyAgreementDocList } from "./__generated__/getSurveyAgreements";
import { getSurveyAgreementApprovalInfo, 
    updateAgreementInfoOperation,
    updateAgreementDownloadedOperation} 
    from "../surveyAgreements/SurveyAgreementService";
import "./survey-agreements.styles.css";
import { FormInstance } from "antd/lib/form/hooks/useForm";
import { useIntl } from "react-intl";
import { saveAs  } from "save-as";
import { getI18n } from "../../../utils/Utils";
import { useAppDispatch, useAppSelector } from "../../../main/hooks";
import { MODE } from "../IStoredSurvey";
import { resetSurveyDataLoading, setSurveyDataLoading } from "../IStoredSurveySlice";
import {convertFromSpecifiedDateFormatToUserDefined} from "../../users/UserFormatPreferenceServices";
import {IAppComponentProps} from "../../../components";

import {Buffer} from "buffer";

const { Option } = Select;

type AgreementData = {
  approverName: string;
  approverEmail: string;
  agreementStatusId: number;
  agreementDocId: number;
  agreementStatusDate: string;
  formValuesChanged: boolean;
}

type ISurveyAgreementsData = {
  form: FormInstance;
  agreementTypeId: number;
  groupId: string;
  agreementData: AgreementData;
  onStatusUpdate: any;
} & IAppComponentProps;


const SurveyAgreements: FunctionComponent<ISurveyAgreementsData> = ({
                                                                      form,
                                                                      agreementTypeId,
                                                                      groupId,
                                                                      agreementData, 
                                                                      onStatusUpdate,
                                                                      ...props
                                                                    }) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const [approverName, setApproverName] = useState<string>(agreementData?.approverName);
    const [approverEmail, setApproverEmail] = useState<string>(agreementData?.approverEmail);
    const [agreementStatusDate, setAgreementStatusDate] = useState<string>(agreementData?.agreementStatusDate);
    const [agreementStatusIdSelected, setAgreementStatusIdSelected] = useState<number>(agreementData?.agreementStatusId);
    const [agreementDocIdSelected, setAgreementDocIdSelected] = useState<number>(agreementData?.agreementDocId);
    const [agreementDocList, setAgreementDocList] = useState<getSurveyAgreements_getSurveyAgreementDocList[]>([]);
    // const [surveyAgreementStatusData, setSurveyAgreementStatusData] = useState<getSurveyAgreementStatusCodes_getSurveyAgreementStatusList[]>([]);
    // const [loading, isLoading] = useState(false);
    const dateTimeFormat = "YYYY-MM-DD HH:mm:ss";
    const [agreementAccepted, setAgreementAccepted] = useState(true);

    
    const surveyState = useAppSelector((state) => state.survey);

    const isViewMode = () => {
    return surveyState.survey.surveyMode === MODE.VIEW ? true : false
    }

    const isOverViewMode = () => {
    return surveyState.survey.surveyMode === MODE.OVERVIEW ? true : false
    }

    const isReadOnly = () => {
        return isViewMode() || isOverViewMode();
       }

    
    const loadDocumentDropDown = () => {
        Promise.resolve(getSurveyAgreementsDocs(agreementTypeId))
        .then((data) => {
          setAgreementDocList(data.getSurveyAgreementDocList);
        })
        .catch((err) => {
          message.error(intl.formatMessage({
              id: "agreement-documentLoadFailure",
              defaultMessage: "Unable to load Agreement document list"
              }))
        });
    }
    
    // const loadStatusDropDown = () => {
    // Promise.resolve(getSurveyAgreementStatusCodeList())
    // .then((data) => {
    //     const statusData =  data.getSurveyAgreementStatusList;
    //     setSurveyAgreementStatusData(statusData);
        
    //     const status = statusData.filter((item) => item.id === agreementData.agreementStatusId).map(filteredItem => (filteredItem.agreementStatus));
    //     form.setFieldsValue({ agreementStatusSelect: status });
    // })
    // .catch((err) => {
    //     message.error(intl.formatMessage({
    //         id: "agreement-statusLoadFailure",
    //         defaultMessage: "Unable to load status data"
    //         }))
    // });
    // }

  useEffect(() => {
    dispatch(setSurveyDataLoading(true));
    Promise.resolve(getSurveyAgreementApprovalInfo(groupId))
    .then((data) => {
        agreementData.approverName = data.getSurveyAgreementApprovalData.agreementApproverName;
        agreementData.approverEmail = data.getSurveyAgreementApprovalData.agreementApproverEmail;
        agreementData.agreementStatusId = data.getSurveyAgreementApprovalData.agreementStatusId;
        agreementData.agreementStatusDate = data.getSurveyAgreementApprovalData.agreementStatusDate;
        if (agreementData.agreementStatusDate === undefined || agreementData.agreementStatusDate === null)
        {
            const statusDateTime = new Date();
            const statusDateTimeString = dayjs(statusDateTime).format(dateTimeFormat);
            setAgreementStatusDate(convertFromSpecifiedDateFormatToUserDefined(props.currentUser,statusDateTimeString.substring(0,10),"YYYY-MM-DD"));
            agreementData.agreementStatusDate = statusDateTimeString;
        }  
        else {

            setAgreementStatusDate(convertFromSpecifiedDateFormatToUserDefined(props.currentUser,agreementData.agreementStatusDate?.substring(0,10),"YYYY-MM-DD"));
        }
        
        //   setLastDocName(agreementDocList.filter((item) => item.agreementId === agreementDocIdSelected).map(filteredItem => (filteredItem.agreementName)));
        form.setFieldsValue({ approverNameFld: agreementData.approverName });
        form.setFieldsValue({ approverEmailFld: agreementData.approverEmail });
        setApproverEmail(agreementData.approverEmail);
        setApproverName(agreementData.approverName);
        console.log(`agreementData.agreementStatusId: ${agreementData.agreementStatusId}`);
        if (agreementData.agreementStatusId === 1)
        {
            console.log("set to accepted");
            setAgreementAccepted(true);
        }
        else
        {
            console.log("set to  NOT accepted");
            setAgreementAccepted(false);
        }
        console.log(`Agreement accepted: ${agreementAccepted}`);
        setAgreementStatusIdSelected(agreementData.agreementStatusId);
        loadDocumentDropDown();
        ;
        // loadStatusDropDown();
    })
    .catch((err) => {
        console.error(err);
        message.error(intl.formatMessage({
            id: "agreement-formLoadFailure",
            defaultMessage: "Unable to load form data"
        }));
        dispatch(resetSurveyDataLoading(true));
    })
    .finally(() => {
        dispatch(setSurveyDataLoading(false));
      });
  }, []);

  const updateDocumentDownloadedFlag  = async () => {
    // isLoading(true);
    dispatch(setSurveyDataLoading(true));
    Promise.resolve(updateAgreementDownloadedOperation(groupId))
        .then(() => {
            message.success(intl.formatMessage({
                id: "agreement-surveyDownloadFlagSuccess",
                defaultMessage: "Survey Data updated successfully"
                }))
            // isLoading(false);
            // Update the progress bar status
            onStatusUpdate();
        })
        .catch((err) => {
            // isLoading(false);
            console.error(err);
            message.success(intl.formatMessage({
                id: "agreement-surveyDownloadFlagFailure",
                defaultMessage: "Survey Data updated failed"
                }))
            dispatch(resetSurveyDataLoading(true));
        })
        .finally(() => {
            dispatch(setSurveyDataLoading(false));
          });
  }

  const handleDownload = () => {
        if (agreementDocIdSelected !== null && agreementDocIdSelected !== undefined)
        {
            // isLoading(true);
            dispatch(setSurveyDataLoading(true));
            Promise.resolve(downloadSurveyDocumentByBlobId(agreementDocIdSelected))
                .then((data) => {
                    const mimeType = data.downloadSurveyDocument.value.mimeType;
                    const decodedData = Buffer.from(data.downloadSurveyDocument.value.encodedFileData, 'base64');
                    const blob = new Blob([decodedData], {type: mimeType});
                    saveAs(blob,data.downloadSurveyDocument.value.fileName);
                    message.success(intl.formatMessage({
                        id: "agreement-downloadSuccess",
                        defaultMessage: "Agreement Document downloaded"
                        }))
                    if (!isReadOnly())
                    {
                        updateDocumentDownloadedFlag();
                    }
                })
                .catch((err) => {
                // isLoading(false);
                dispatch(resetSurveyDataLoading(true));
                message.error(intl.formatMessage({
                    id: "agreement-downloadFailure",
                    defaultMessage: "Agreement Document Download failed"
                    }))
                console.error(err);
                })
                .finally(() => {
                    dispatch(setSurveyDataLoading(false));
                });
        }
        else
        {
            message.error(intl.formatMessage({
                id: "agreement-download-no-file-Selected-Failure",
                defaultMessage: "No file selected to download"
                }))
        }
    };
    
  const handleAgreementDocSelected = (value, option) => {
    setAgreementDocIdSelected(option.key);
    agreementData.agreementDocId = option.key;
  };

  const handleChangeApproverEmail = (event) => {
    agreementData.approverEmail = event.target.value;
    setApproverEmail(event.target.value);
    agreementData.formValuesChanged = true;
  };

  const handleChangeApproverName = (event) => {
    agreementData.approverName = event.target.value;
    setApproverName(event.target.value);
    agreementData.formValuesChanged = true;
  };

  const handleSubmit = () => {
     
    if (agreementAccepted
      && approverName !== undefined && approverName != null && approverName !== ""
      && approverEmail !== undefined && approverEmail != null && approverEmail !== ""
      )
      {
      
        // isLoading(true);
        dispatch(setSurveyDataLoading(true));
        //We can only get here if agreement was accepted and based on https://atc.bmwgroup.net/jira/browse/EINVV2-10679 we changed the select list to CheckBox so we now interpret the field to 1 if selected.
        setAgreementStatusIdSelected(1);
        Promise.resolve(updateAgreementInfoOperation(groupId, approverName, approverEmail, agreementStatusIdSelected,agreementStatusDate))
            .then((data) => {
                console.error(data);
                message.success(intl.formatMessage({
                    id: "agreement-saveSuccess",
                    defaultMessage: "Agreement Data saved successfully"
                    }));
                agreementData.formValuesChanged = false;
                // Update the progress bar status
                onStatusUpdate();
            //     isLoading(false);
            })
            .catch((err) => {
                // isLoading(false);
                dispatch(resetSurveyDataLoading(true));
                console.error(`Failure on component: ${err}`);
                message.error(intl.formatMessage({
                    id: "agreement-saveFailure",
                    defaultMessage: "Agreement Data cannot be saved, did you remember to download the agreement first"
                    }));
            })
            .finally(() => {
                dispatch(setSurveyDataLoading(false));
              });
    } else {
        message.error(intl.formatMessage({
            id: "agreement-saveFail",
            defaultMessage: "Unable to save without completed form"
            }));
    }

  };

  const onFinish = (values: any) => {
    handleSubmit();
  };

  const onAgreementChange = (e) => {
    console.log(`event : ${e.target.checked}`);
    setAgreementAccepted(e.target.checked);
    console.log(`Agreement accepted changed : ${e.target.checked}`);
    if (e.target.checked)
    {
        setAgreementStatusIdSelected(1);
    }
    else
    {
        setAgreementStatusIdSelected(0);
    }
  }



    return (
      <Card>
          {/* <Spin spinning={loading} size={"large"}> */}
            <div>
                  <Row gutter={8}>
                      <Col xs={24} sm={6} md={16}>
                          <Typography>
                              {/* <Row>
                                 <SurveyIssuesComponent portionSequence={4} groupId={groupId} loading={loading}/>
                              </Row> */}
                              <Paragraph>
                                <Row>
                                  {intl.formatMessage({
                                    id: "agreement-headertext-line1",
                                    defaultMessage: "We ask for your review and approval of the eInvoice agreement 'Terms and conditions for the electronic exchange of billing documents between Partners "
                                    })}
                                </Row>
                                <Row>
                                   {intl.formatMessage({
                                    id: "agreement-headertext-line2",
                                    defaultMessage: "and companies of the BMW Group'. To view the agreement, please click Download."
                                    })}
                                </Row>
                              </Paragraph>
                          </Typography>
                      </Col>
                  </Row>
                <div>
                <Form noValidate={true}
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 14 }}
                    labelAlign={"left"}
                    form={form}
                    initialValues={{ remember: true }}
                    size={"middle"}
                    layout={"horizontal"}
                    onFinish={onFinish}
                >
                    <Form.Item

                        label={intl.formatMessage({
                            id: "agreement-DocSelect-col",
                            defaultMessage: "BMW Group eInvoice Agreement Document"
                            })}
                        name="agreementDocSelected"
                        rules={[{ required: false , message: intl.formatMessage({
                            id: "agreement-required-field",
                            defaultMessage: "This is a required field"
                            })
                        }]}
                        
                    >
                        <Select
                            placeholder={intl.formatMessage({
                                id: "agreement-DocSelected-placeholder",
                                defaultMessage: "Select Agreement"
                                })}
                            onSelect={handleAgreementDocSelected}
                            allowClear={true}
                        >
                            {agreementDocList.map((item) => (
                                <Option key={`${item.blobId}`} value={item.agreementName}>
                                    {item.agreementDescription}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item 
                        label={intl.formatMessage({
                            id: "agreement-StatusSelect-col",
                            defaultMessage: "Do you agree to the BMW Group eInvoice Agreement"
                            })}
                        name="agreementStatusSelect"
                        // rules={[{ required: true, message: intl.formatMessage({
                        //     id: "agreement-required-field",
                        //     defaultMessage: "This is a required field"
                        //     }) }]}
                        
                    >
                        <Checkbox checked={agreementAccepted} disabled={isReadOnly()} onChange={onAgreementChange}/>

                        {/* <Select
                        placeholder={intl.formatMessage({
                            id: "agreement-StatusId-placeholder",
                            defaultMessage: "Select option"
                            })}
                        onSelect={handleAgreementStatusIdSelected}
                        allowClear={true}
                        disabled={isReadOnly()}
                        >
                            {surveyAgreementStatusData.map((item) => (
                            <Option key={`${item.id}`} value={item.agreementStatus}>
                              {getI18n(item.phraseId, item.agreementStatus, intl)}
                            </Option>
                            ))}
                        </Select> */}
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 8 }}
                                label={intl.formatMessage({
                                id: "agreement-approverNameFld-col",
                                defaultMessage: "eInvoice Agreement Approver Name"
                                })}
                                name="approverNameFld"
                                rules={[{ required: true, message: intl.formatMessage({
                                id: "agreement-required-field",
                                defaultMessage: "This is a required field"
                                })
                                    }]}>
                        <Input
                        placeholder={intl.formatMessage({
                            id: "agreement-required-name-placeholder",
                            defaultMessage: "eInvoice Agreement Approver Name"
                            })}
                        value={approverName}
                        type="text"
                        onChange={handleChangeApproverName}
                        readOnly={isReadOnly()}
                        />
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 8 }}
                                label={intl.formatMessage({
                                id: "agreement-approverEmailFld-col",
                                defaultMessage: "eInvoice Agreement Approver email"
                                })}
                                name="approverEmailFld"
                                rules={[{ required: true, message: intl.formatMessage({
                                id: "agreement-required-field",
                                defaultMessage: "This is a required field"
                                })
                                },
                                { type: 'email', message: intl.formatMessage({id: "survey-connectivityInfo-invalid-email",
                                      defaultMessage: "Email must include one '@' symbol, a domain, no spaces, and only valid characters."
                                    })
                                  }]}>
                        <Input
                        placeholder={intl.formatMessage({
                            id: "agreement-required-email-placeholder",
                            defaultMessage: "eInvoice Agreement Approver Email"
                            })}
                        value={approverEmail}
                        type="email"
                        onChange={handleChangeApproverEmail}
                        readOnly={isReadOnly()}
                        />
                    </Form.Item>
                    <Form.Item 
                        wrapperCol={{ span: 3 }}
                        label={intl.formatMessage({
                            id: "agreement-statusDate-col",
                            defaultMessage: "eInvoice Agreement Approval Date"
                            })}
                        name="statusDate">
                        <span>
                            <Input
                                readOnly={true}
                                value={agreementStatusDate}
                            />
                        </span>
                </Form.Item>
                <Form.Item
                        label={""}
                    >
                        <Row gutter={12}>
                            <Col span={12} offset={6}>
                                <Button
                                    type="primary"
                                    size="middle"
                                    className="w-100 mt10"
                                    onClick={handleDownload}
                                >
                                {intl.formatMessage({
                                    id: "agreement-download-button",
                                    defaultMessage: "DOWNLOAD SELECTED DOCUMENT"
                                })}
                                
                                </Button>
                            </Col>
                        </Row>
                    </Form.Item>
                <Form.Item label={""} hidden={isReadOnly()}>
                        <Row gutter={12}>
                        <Col span={8} offset={6}>
                            <Button
                                type="primary"
                                size="middle"
                                className="w-100 mt10"
                                htmlType={"submit"}
                            >
                                {intl.formatMessage({
                                    id: "survey-main-general-save-button",
                                    defaultMessage: "SAVE"
                                    })}
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
          </div>
        </div>
      {/* </Spin> */}
    </Card>
  );
};

export default SurveyAgreements;
