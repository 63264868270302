import React, {useEffect, useState} from "react";
import {IAppComponentProps} from "../../../components";
import {Button, FormInstance, Modal, notification, Tooltip, Upload, UploadProps} from "antd";
import {DeleteOutlined, DownloadOutlined, InfoCircleOutlined, UploadOutlined} from "@ant-design/icons";
import {BlobFileInput} from "../../../../__generated__/globalTypes";
import styles from "../styling/ecap.module.css";
import {SupportingDocumentService} from "../services/SupportingDocumentService";
import client from "../../../config/GraphQLApolloClient";
import {SupportingDocumentResponse} from "../services/model/SupportingDocumentsModel";
import {base64toFile} from "../../documents/DocumentUtils";
import {useAppDispatch, useAppSelector} from "../../../main/hooks";
import {uploadBlobFile_uploadBlobFile as TFBlobResponse} from "../../survey/__generated__/uploadBlobFile";
import {MODE} from "../IStoredDocumentRecord";
import {
    clearStateAttachments,
    setAttachments,
    setLoading,
    updateAttAchmentUploadsLeft
} from "../../e-cap/IStoredDocumentRecordSlice";

const ALLOWED_MIME_TYPES = [
    // 'text/plain',
    // 'application/msword',
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    // 'application/vnd.ms-excel',
    // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    // 'application/vnd.ms-powerpoint',
    // 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/pdf',
    'image/tiff',
    //'image/gif',
    'image/jpeg',
    'image/png',
    //'image/bmp',
    // 'text/csv',
    // 'application/zip',
    // 'application/vnd.oasis.opendocument.presentation',
    // 'application/vnd.oasis.opendocument.spreadsheet',
    // 'application/vnd.oasis.opendocument.text',
    // 'application/epub+zip',
];

export class AttachmentModel {
    blobResponse:TFBlobResponse;
    supportingDocument:SupportingDocumentResponse;
    constructor( blobResponse:TFBlobResponse, supportingDocument:SupportingDocumentResponse) {
        this.blobResponse = blobResponse;
        this.supportingDocument = supportingDocument;
    }

    retrieveBlobId() {
        return this.supportingDocument ? this.supportingDocument.blobId : this.blobResponse.blobId;
    }

    retrieveFileName() {
        return this.supportingDocument && this.supportingDocument.fileName ? this.supportingDocument.fileName : this.blobResponse.fileName;
    }
}

type AttachmentProperties = {
    form:FormInstance<any>,
    clearCallback:(arg:()=>void)=>()=>void,
    deleteAttachmentCallback:(arg:()=>void)=>()=>void,
    numberOfAttachmentsCallback:(arg:()=>number)=>()=>void,
    saveAttachmentsCallback:(arg:(doc:number)=>Promise<boolean[]>)=>void
} & IAppComponentProps;

function uploadDocument(blob:BlobFileInput) {
    const service = new SupportingDocumentService(client);
    return service.uploadFile(blob);
}

function performAttachment(docId:number, blobResponse:TFBlobResponse): Promise<SupportingDocumentResponse> {
    const service = new SupportingDocumentService(client);
    return service.associateWithBlob({
            documentId: docId,
            blobId: blobResponse.blobId,
            insertUser: blobResponse.insertUser,
        });
}

function retrieveDocuments(docId:number) {
    const service = new SupportingDocumentService(client);
    return service.retrieveAllAttachedSupportingDocuments(docId);
}

export function Attachment(props:AttachmentProperties) {
    const MAX_FILES = 4;
    const MAX_FILE_SIZE = 2_000_000;
    const MIN_FILE_SIZE = 10;

    const { document } = useAppSelector((state) => state.document);
    //const [attachmentState,setState] = useState<{attachmentList:AttachmentModel[]}>({attachmentList:[]});
    //const [uploadsLeft,setUploadsLeft] = useState<number>(MAX_FILES);
    const [loaded,setLoaded] = useState<boolean>(false);
    const [toggle,setToggle] = useState<boolean>(false);
    const rerender = ()=>setToggle(current => !toggle);
    const dispatch = useAppDispatch();

    const clearAttachments = ()=> {
        const service = new SupportingDocumentService(client);
        const promises:Promise<boolean>[] = [];
        for (let i = 0; i < document.attachments.length; i++) {
            const attachmentModel = document.attachments[i];
            if (attachmentModel && attachmentModel.blobResponse && !attachmentModel.supportingDocument) {
                promises.push(service.deleteBlob(attachmentModel.blobResponse));
            }
            else {
                promises.push(Promise.resolve(false));
            }
        }

        Promise.all(promises).then(values => {
            const newArray:AttachmentModel[] = [];
            for (let i = 0; i < values.length; i++) {
                if (!values[i]) {
                    newArray.push(document.attachments[i]);
                }
                else {
                    console.log('Deleted ',document.attachments[i].blobResponse);
                }
            }

            dispatch(clearStateAttachments());
            rerender();
        });
    };

    const deleteAttachments = ()=> {
        const service = new SupportingDocumentService(client);
        const promises:Promise<boolean>[] = [];
        for (let i = 0; i < document.attachments.length; i++) {
            const attachmentModel = document.attachments[i];
            if (attachmentModel && attachmentModel.supportingDocument) {
                promises.push(service.deleteSupportingDocument(attachmentModel.supportingDocument.attachmentId)
                    .then(_=> service.deleteBlob({
                        __typename: "TFBlobResponse",
                        blobId: attachmentModel.supportingDocument.blobId,
                        downloadLink: null,
                        fileName: null,
                        insertTime: null,
                        insertUser: null,
                        mimeType: null,
                        requiresAuthentication: false,
                        rolesAllowed: null,
                        updateTime: null,
                        updateUser: null
                    })));
            }
            else {
                promises.push(service.deleteBlob(attachmentModel.blobResponse));
            }
        }

        Promise.all(promises).then(values => {
            const newArray:AttachmentModel[] = [];
            for (let i = 0; i < values.length; i++) {
                if (!values[i]) {
                    newArray.push(document.attachments[i]);
                }
                else {
                    console.log('Deleted ',document.attachments[i].blobResponse);
                }
            }

            dispatch(setAttachments(newArray))
            rerender();
        });
    };

    const saveAttachments = (docId: number):Promise<boolean[]> => {
        const newService = new SupportingDocumentService(client);
        const promises: Promise<boolean>[] = [];
        console.log("Save attachments");
        console.dir(document.attachments);
        document.attachments.forEach((attachment) => {
            const blobResponse = attachment.blobResponse;
            if (blobResponse && !attachment.supportingDocument) {
                const promise = newService
                    .associateWithBlob({
                        documentId: docId,
                        blobId: blobResponse.blobId,
                        insertUser: blobResponse.insertUser,
                    })
                    .then((result) => {
                        attachment.supportingDocument = result;
                        attachment.supportingDocument.fileName = blobResponse.fileName;
                        attachment.supportingDocument.blobPath = blobResponse.downloadLink;
                        console.log(`Successfully attached supporting doc`,result);
                        return true;
                    })
                    .catch((error) => {
                        console.error(error);
                        console.log('Could not attach document ', docId);
                        return false;
                    });
                promises.push(promise);
            }
            else {
                promises.push(Promise.resolve(true));
            }
        });

        return Promise.all(promises);
    };

    const hasDocumentBeenSaved = ()=> document && document.documentId ? true: false;

    useEffect(()=> {
        if (!loaded) {
            setLoaded(true);
            props.clearCallback(clearAttachments);
            props.saveAttachmentsCallback(saveAttachments)
            props.numberOfAttachmentsCallback(()=> document.attachments.length);
        }
        if (hasDocumentBeenSaved()) {
            dispatch(updateAttAchmentUploadsLeft(MAX_FILES));
            retrieveDocuments(document.documentId).then(docs=> {
                if (docs && docs.map) {
                    const previouslyUploadedDocs =
                        docs.map(doc=>new AttachmentModel(null,doc));
                    rerender();
                    dispatch(setAttachments(previouslyUploadedDocs));
                    dispatch(updateAttAchmentUploadsLeft(Math.max(0,MAX_FILES - previouslyUploadedDocs.length)));
                }
            });
        }
        console.log(`Attachments left : ${document.attachmentUploadsLeft}`);
        console.log(`Document mode ${document.documentMode}`);
    },[document.documentMode]);



    const customerBeforeUpload = (file:any,_):boolean =>{

        if (!ALLOWED_MIME_TYPES.includes(file.type)) {
            console.log('Unsupported file type',file.name,file.type);
            notification.error({
                message:  props.intl.formatMessage({'id':"ecap-attachment-upload-error",'defaultMessage': "Upload error"}),
                description:  props.intl.formatMessage({'id':"ecap-attachment-invalid-mime-type",'defaultMessage': "Unsupported file type. Allowed File Extension pdf, png, jpeg, tiff."})
            });

            return false;
        }

        if (typeof file.size === "number") {
            console.log('File size', file.size);
            if (file.size >= MAX_FILE_SIZE) {
                notification.error({
                    message:  props.intl.formatMessage({'id':"ecap-attachment-upload-error",'defaultMessage': "Upload error"}),
                    description:  props.intl.formatMessage({'id':"ecap-attachment-invalid-file-size",'defaultMessage': "Max file size that can be uploaded is limited to 2mb"})
                });
                return false;
            }
            if (file.size < MIN_FILE_SIZE) {
                notification.error({
                    message:  props.intl.formatMessage({'id':"ecap-attachment-upload-error",'defaultMessage': "Upload error"}),
                    description:  props.intl.formatMessage({'id':"ecap-attachment-min-file-error",'defaultMessage': "Cannot upload an empty file"})
                });
                return false;
            }
        }

        const reader = new FileReader();

        reader.onload = e=> {
            dispatch(setLoading(true));
            const firstIndex = (e.target.result && ((String)(e.target.result)).indexOf('base64,')) || -1;
            const content = firstIndex>0? (String)(e.target.result).substring(firstIndex+7): (String)(e.target.result);

            const blob: BlobFileInput =
                {
                    base64FileData: content,
                    fileName: file.name,
                    mimeType: file.type,
                    requiresAuthentication:true,
                };

            uploadDocument(blob).then((result:TFBlobResponse)=> {

                function appendAttachment(newAttachment:AttachmentModel) {
                    console.log("add new attachment");
                    console.dir(newAttachment);
                    const newList = [...document.attachments,newAttachment];
                    rerender();
                    console.log("attach document, new list");
                    console.dir(newList);
                    dispatch(setAttachments(newList));
                    dispatch(updateAttAchmentUploadsLeft(Math.max(0, MAX_FILES - newList.length)));
                }

                if (hasDocumentBeenSaved()) {
                    console.log(`Has been saved ${document.documentId}`);
                    performAttachment(document.documentId, result).then(response=> {
                        appendAttachment(new AttachmentModel(result,response));
                    });
                }
                else {
                    console.log(`Has not been saved`);
                    appendAttachment(new AttachmentModel(result,null));
                }
                dispatch(setLoading(false));
            })
        }

        reader.readAsDataURL(file);
        return false;
    }

    const uploadProps: UploadProps = {
        beforeUpload:customerBeforeUpload,
        maxCount: document.attachmentUploadsLeft,
    };

    const deleteDocument = (index:number) => {
        if (document?.documentMode === MODE.VIEW) {
            console.log('In view mode, cannot delete');
            return;
        }

        let confirmDeletion = props.intl.formatMessage({
            id: 'e-cap-doc-attachment-delete-confirmation-label',
            defaultMessage: 'Attachment "X" will be permanently deleted from the database. Do you want to continue?',
        });
        confirmDeletion = confirmDeletion.replace('X',document.attachments[index].retrieveFileName())
        Modal.confirm({
            title: props.intl.formatMessage({
                id: 'e-cap-doc-attachment-delete-confirmation-title',
                defaultMessage: 'Delete Attachment',
            }),
            content: confirmDeletion,
            okText: props.intl.formatMessage({
                id: 'e-cap-capture-document-cancel-confirm-modal-yes',
                defaultMessage: 'YES',
            }),
            cancelText: props.intl.formatMessage({
                id: 'e-cap-capture-document-cancel-confirm-modal-no',
                defaultMessage: 'NO',
            }),
            onOk: () => {
                const service = new SupportingDocumentService(client);

                function updateAttachmentList() {
                    const leftArray = document.attachments.slice(0, index);
                    const rightArray = document.attachments.slice(index + 1);
                    const newList = [...leftArray, ...rightArray];
                    rerender();
                    dispatch(setAttachments(newList));
                    dispatch(updateAttAchmentUploadsLeft(Math.max(0, MAX_FILES - newList.length)));
                }

                if (document.attachments[index].supportingDocument) {
                    service.deleteSupportingDocument(document.attachments[index].supportingDocument.attachmentId).then(_=> {
                        console.log(`Successfully deleted ${document.attachments[index].supportingDocument}`);
                        if (document.attachments[index].blobResponse) {
                            service.deleteBlob(document.attachments[index].blobResponse);
                        }
                        else {
                            service.deleteBlob({
                                __typename: "TFBlobResponse",
                                downloadLink: "",
                                fileName: "",
                                insertTime: "",
                                insertUser: "",
                                mimeType: "",
                                requiresAuthentication: false,
                                rolesAllowed: "",
                                updateTime: "",
                                updateUser: "",
                                blobId:document.attachments[index].supportingDocument.blobId});
                        }

                        updateAttachmentList();
                    }).catch(error=> {
                        console.error(`Failed to delete ${document.attachments[index].supportingDocument}`);
                    });
                }
                else {
                    service.deleteBlob(document.attachments[index].blobResponse);
                    updateAttachmentList();
                }
            },
        });
    };

    const downloadDocument = (attachment:AttachmentModel)=> {
        const service = new SupportingDocumentService(client);
        service.downloadDocumentByBlobId(attachment.retrieveBlobId()).then(response=> {
            if (response?.success === "true") {
                if (response?.value.encodedFileData) {
                    base64toFile(
                        response.value.encodedFileData,
                        response.value.fileName,
                        response.value.mimeType);
                }
            }
        })
    }

    return (
        <div>
            <div key={"Attachments"}>
                <Upload {...uploadProps} fileList={[]}>


                    <Button icon={<UploadOutlined/>}
                            disabled={document.attachmentUploadsLeft<1 || (document.documentMode !== undefined && document.documentMode !== MODE.EDIT)}>{ props.intl.formatMessage({'id':'ecap-upload-doc','defaultMessage': "Upload"})}</Button>

                    <Tooltip title={props.intl.formatMessage({'id':'attachment-info','defaultMessage':"Supporting documents for the invoice can be attached here.\n" +
                        "A maximum of 4 files not exceeding 2mb each can be attached.\n" +
                        "The following file types can be attached: 'pdf', 'png', 'jpeg', 'tiff'"})}>
                        <InfoCircleOutlined style={{marginLeft:"5px"}} />
                    </Tooltip>

                </Upload>
                <div key={"attachmentList"} id="attachmentList">
                    {document.attachments.map((attachedDocument, index) =>
                        <div key={`attachment_blob_${index}`} className={styles.attachmentContainer}>
                            <div><p style={{fontSize: "18px"}}>{attachedDocument.retrieveFileName()}</p></div>
                            <div>
                                <Tooltip title={ props.intl.formatMessage({'id':'welcome-to-download','defaultMessage': "Download"})}>
                                    {/* eslint-disable-next-line no-alert */}
                                    <DownloadOutlined style={{fontSize: "18px"}}
                                                    onClick={() => downloadDocument(attachedDocument)}/>
                                </Tooltip>
                                <Tooltip title={ props.intl.formatMessage({'id':'ecap-delete-doc','defaultMessage': "Delete"})}>
                                    <DeleteOutlined hidden={document?.documentMode === MODE.VIEW || document?.documentMode === MODE.OVERVIEW} style={{fontSize: "18px"}}
                                                    onClick={() => deleteDocument(index)}/>
                                </Tooltip>
                            </div>
                        </div>
                    )}
                </div>

            </div>
        </div>);
}