import { IAppComponentProps } from "../../../components";
import React from "react";
import { Card, Checkbox, Col, Collapse, Form, FormInstance, Input, Row, Select } from "antd";
import { getI18n } from "../../../utils/Utils";
import { FormattedMessage, useIntl } from "react-intl";
import { CaretRightOutlined } from "@ant-design/icons";
import { gql, useQuery } from "@apollo/client";
import { Supplier_AllSupplierLegalFormTypes } from "./__generated__/Supplier_AllSupplierLegalFormTypes";
import { ECAPAddressInfo } from "../componets/ECAP-Address-component";
import { hasFiscalRepresentativeChecked, inLiquidationChecked, updateCollapsedState, updateFiscalCountryCode, updateFiscalRegionCode, updateLegalFormTypeId } from "../IStoredDocumentRecordSlice";
import { useAppDispatch, useAppSelector } from "../../../main/hooks";
import { Form_Item } from "../componets/FormItem-component";
import { getControlRuleset } from "../RulesEngine";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import {augmentWithMaximumLength} from "../util/RuleResultCache";



type SuppliersBankingDetailsProps = {
  form : FormInstance<any>;
} & IAppComponentProps;

const SUPPLIER_LEGAL_FORM_TYPE = gql`
    query Supplier_AllSupplierLegalFormTypes {
        getAllLegalFormTypes{
            legalFormTypeId
            legalFormType
            phraseId
        }
    }
`;

const SuppliersBankingDetails: React.FunctionComponent<SuppliersBankingDetailsProps> = (props) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const {  document } = useAppSelector((state) => state.document);
  const  rules = useAppSelector((state) => state.rules);

  const { data: legalFormData, loading } = useQuery<Supplier_AllSupplierLegalFormTypes>(SUPPLIER_LEGAL_FORM_TYPE, {
    fetchPolicy: "network-only", onError: (error) => console.log(error)
  });

  const onClickedHasFiscalRepresentative = (e : CheckboxChangeEvent)  => 
  {
    dispatch(hasFiscalRepresentativeChecked(e.target.checked))
    if (e.target.checked === false)
    {
      const supplierDetail = props.form.getFieldValue("supplierDetails");
      supplierDetail.fiscalRepresentativeAddressStreet = undefined;
      supplierDetail.fiscalRepresentativeAddressCountry = undefined;
      supplierDetail.fiscalRepresentativeAddressCountryCode = undefined;
      supplierDetail.fiscalRepresentativeAddressPostalCode = undefined;
      supplierDetail.fiscalRepresentativeAddressRegion = undefined;
      supplierDetail.fiscalRepresentativeAddressRegionCode = undefined;
      supplierDetail.fiscalRepresentativeAddressCity = undefined;
      dispatch(updateFiscalCountryCode(undefined));
      dispatch(updateFiscalRegionCode(undefined));
      
    }
  }

  const onClickedInLiquidation = (e : CheckboxChangeEvent)  => 
  {
    dispatch(inLiquidationChecked(e.target.checked));
    if (e.target.checked === false)
    {
      const supplierDetail = props.form.getFieldValue("supplierDetails");
      supplierDetail.liquidationRemark = undefined;
    }
  }


  return (
    <>
      <Collapse
        defaultActiveKey={2}
        className={"ml2"}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        activeKey={document.collapsedState}
        onChange={(newActiveKeys) => {
          // otherwise panels couldn't be open/closed by click
          dispatch(updateCollapsedState(newActiveKeys));
}}
      >
        <Collapse.Panel key={5.6}
                        forceRender={true}
                        header={getI18n("supplierdetails-supplier-compliance-panel-header",
                                                  "Compliance Data", intl)}>
          <Card key={"supplierComplianceKey"} size={"small"}
                className={"ml-10"}>
            <Row gutter={24}>
              <Col span={10}>
                <Form_Item name={["supplierDetails", "managingDirector"]}
                           label={getI18n("supplierdetails-supplier-compliance-directors","Managing Directors",intl)}
                           placeholder={getI18n("supplierdetails-supplier-compliance-directors","Managing Directors",intl)}
                           itemRules={augmentWithMaximumLength(getControlRuleset("Legal_Form_Type_MD", props.form, rules, document),200,intl)}
                />
                <Form_Item name={["supplierDetails", "companyRegistrationNumber"]}
                           label={getI18n("supplierdetails-supplier-compliance-companyRegistrationNumber","Company Reg. No.",intl)}
                           placeholder={getI18n("supplierdetails-supplier-compliance-companyRegistrationNumber","Company Reg. No.",intl)}
                           itemRules={getControlRuleset("Legal_Form_Type_CRN", props.form, rules, document)}
                           />
                <Form_Item name={["supplierDetails", "placeOfRegistration"]}
                           label={getI18n("supplierdetails-supplier-compliance-companyRegistrationPlace","Place of Registration",intl)}
                           placeholder={getI18n("supplierdetails-supplier-compliance-companyRegistrationPlace","Place of Registration",intl)}
                           itemRules={augmentWithMaximumLength(getControlRuleset("PLACE_OF_LEGAL_SEAT", props.form, rules, document), 200, intl)}
                />
                <Form.Item valuePropName="checked" name={["supplierDetails", "inLiquidation"]}
                           label={getI18n("supplierdetails-supplier-compliance-companyInLiquidation","Company is in Liquidation",intl)}
                                                    >
                           
                  <Checkbox onChange={onClickedInLiquidation} />
                </Form.Item>
                <Form.Item valuePropName="checked" name={["supplierDetails", "hasFiscalRepresentative"]}
                           label={getI18n("supplierdetails-supplier-compliance-companyHasFiscalRepresentative",
                             "Company has Fiscal Representative", intl)}>

                  <Checkbox onChange={onClickedHasFiscalRepresentative} />
                </Form.Item>

              </Col>

              <Col span={10}>
                <Form_Item name={["supplierDetails", "chairmanOfSupervisoryBoard"]}
                           label={getI18n("supplierdetails-supplier-compliance-chairmanOfSupervisoryBoard","Chairman of the Supervisory Board",intl)}
                           placeholder={getI18n("supplierdetails-supplier-compliance-chairmanOfSupervisoryBoard","Chairman of the Supervisory Board",intl)}
                           itemRules={augmentWithMaximumLength(getControlRuleset("Legal_Form_Type_CSB", props.form, rules, document),200, intl)}
                           />
                <Form_Item name={["supplierDetails", "companyRegistryData"]}
                           label={getI18n("supplierdetails-supplier-compliance-companyRegistryData","Company Registry Data",intl)}
                           placeholder={getI18n("supplierdetails-supplier-compliance-companyRegistryData","Company Registry Data",intl)}
                           itemRules={getControlRuleset("Legal_Form_Type_CRD", props.form, rules, document)}
                           />
                <Form_Item name={["supplierDetails", "legalFormTypeId"]}
                           label={getI18n("supplierdetails-supplier-compliance-legalForm",
                                                    "Legal Form", intl)}
                           itemRules={getControlRuleset("LEGAL_FORM", props.form, rules, document)}
                           >
                      <Select
                        size={"middle"}
                        showSearch={true}
                        placeholder={getI18n("supplierdetails-supplier-compliance-legalForm","Legal Form",intl)}
                        defaultActiveFirstOption={true}
                        filterOption={false}
                        notFoundContent={null}
                        loading={loading}
                        onChange={(value => dispatch(updateLegalFormTypeId(value)))}
                        allowClear={true}>
                        {legalFormData &&
                        legalFormData.getAllLegalFormTypes?.map((item) => (
                          <Select.Option key={`${item.legalFormType}_${item.legalFormTypeId}`}
                                         value={item.legalFormTypeId}>
                            {getI18n(item.phraseId, item.legalFormType, intl)}
                          </Select.Option>
                        ))}
                      </Select>
                  </Form_Item>
                  <Form_Item name={["supplierDetails", "liquidationRemark"]}
                             label={getI18n("supplierdetails-supplier-compliance-remarkOnLiquidation","Remark on Liquidation",intl)}
                             placeholder={getI18n("supplierdetails-supplier-compliance-remarkOnLiquidation","Remark on Liquidation",intl)}
                             itemRules={getControlRuleset("In_Liquidation_checked", props.form, rules, document)}
                           />
                   <Form_Item name={["supplierDetails", "fiscalRepresentativeName"]}
                              label={getI18n("supplierdetails-supplier-compliance-fiscalRepresentativeName","Fiscal Representative Name",intl)}
                              placeholder={getI18n("supplierdetails-supplier-compliance-fiscalRepresentativeName","Fiscal Representative Name",intl)}
                              itemRules={getControlRuleset("Fiscal_Representative_checked", props.form, rules, document)}
                           />
                  <Form_Item name={["supplierDetails", "fiscalRepresentativeVatId"]}
                             label={getI18n("supplierdetails-supplier-compliance-fiscalRepresentativeVatId",
                                                      "Fiscal Representative VAT ID", intl)}
                             placeholder={getI18n("supplierdetails-supplier-compliance-fiscalRepresentativeVatId","Fiscal Representative VAT ID",intl)}
                             itemRules={getControlRuleset("Fiscal_Representative_checked", props.form, rules, document)}
                           />
              </Col>
            </Row>
            {/* <Row gutter={24}> */}
              <ECAPAddressInfo streetFldName={["supplierDetails", "fiscalRepresentativeAddressStreet"]}
                               streetFldLabel={intl.formatMessage({
                                 id: "supplierdetails-supplier-compliance-fiscalRepresentativeAddressStreet",
                                 defaultMessage: "Fiscal Representative Address Street"
                               })}
                               streetFldRule="Fiscal_Representative_checked"
                               countryDisplayFldName={["supplierDetails", "fiscalRepresentativeAddressCountry"]}
                               countryFldLabel={intl.formatMessage({
                                 id: "supplierdetails-supplier-compliance-fiscalRepresentativeAddressCountryCode",
                                 defaultMessage: "Fiscal Representative Address Country Code"
                               })}
                               countryCodeFldName={["supplierDetails", "fiscalRepresentativeAddressCountryCode"]}
                               countryFldRule='Fiscal_Representative_checked'
                               postalCodeFldName={["supplierDetails", "fiscalRepresentativeAddressPostalCode"]}
                               postalCodeFldLabel={intl.formatMessage({
                                 id: "supplierdetails-supplier-compliance-fiscalRepresentativeAddressPostalCode",
                                 defaultMessage: "Fiscal Representative Address PostalCode"
                               })}
                               postalCodeFldRule="Fiscal_Representative_checked"
                               regionDisplayFldName={["supplierDetails", "fiscalRepresentativeAddressRegion"]}
                               regionCodeFldName={["supplierDetails", "fiscalRepresentativeAddressRegionCode"]}
                               regionCodeFldLabel={intl.formatMessage({
                                 id: "supplierdetails-supplier-compliance-fiscalRepresentativeAddressRegion",
                                 defaultMessage: "Fiscal Representative Address Region"
                               })}
                               regionCodeFldRule="Fiscal_Address_US_OR_Canada"
                               cityFldName={["supplierDetails", "fiscalRepresentativeAddressCity"]}
                               cityFldLabel={intl.formatMessage({
                                 id: "supplierdetails-supplier-compliance-fiscalRepresentativeAddressCity",
                                 defaultMessage: "Fiscal Representative Address City"
                               })}
                               cityFldRule="Fiscal_Representative_checked"
                               editable={true}
                               noTitle={true}
                               form={props.form} />

          </Card>
        </Collapse.Panel>
      </Collapse>
    </>
  );
};

export default SuppliersBankingDetails;