import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStoredSurvey,  MODE, SurveyManagementInputs } from './IStoredSurvey';
import { SurveyOverviewFilter } from '__generated__/globalTypes';

export const DESTROY_SESSION = 'DESTROY_SESSION';
export const DESTROY_SURVEY_OVERVIEW = 'DESTROY_SURVEY_OVERVIEW';

interface SurveyState {
    survey: IStoredSurvey;
}

const initialState: SurveyState = {
    survey: {
        selectedSurveyManagement: undefined,
        surveyMode: undefined,
        managementSearchCriteria: undefined,
        surveyOverviewCriteria: undefined,
        surveyDataLoading: 0,
        collapsedState: undefined
    },
};


function destroyStateSynchronous(state) {
    return new Promise(resolve => {
        state.survey = initialState.survey;
    });
}


const surveySlice = createSlice({
    name: 'survey',
    initialState,
    reducers: {
        destroyState(state, action: PayloadAction<string>) {
            if (action.payload === DESTROY_SESSION) {
                destroyStateSynchronous(state);
            }
            else if (action.payload === DESTROY_SURVEY_OVERVIEW) {
                state.survey.surveyOverviewCriteria = undefined;
                state.survey.surveyMode = undefined;
            }
        },
        updateSelectedSurveyManagement(state, action: PayloadAction<string>) {
            state.survey.selectedSurveyManagement = action.payload;
        },
        setSurveyMode(state, action: PayloadAction<MODE>) {
            state.survey.surveyMode = action.payload;
        },
        updateSurveyManagementCriteria(state, action: PayloadAction<SurveyManagementInputs>) {
            state.survey.managementSearchCriteria = action.payload;
        },
        updateSurveyOverviewCriteria(state, action: PayloadAction<SurveyOverviewFilter>) {
            state.survey.surveyOverviewCriteria = action.payload;
        },
        setSurveyDataLoading(state, action: PayloadAction<boolean>) {
            if (action.payload)
            {
                state.survey.surveyDataLoading++;
            }
            else{
                state.survey.surveyDataLoading--;
                if (state.survey.surveyDataLoading <= 0)
                {
                    state.survey.surveyDataLoading = 0;
                }
            }
            console.log(`surveyDataLoading : ${state.survey.surveyDataLoading}`);
        },
        resetSurveyDataLoading(state, action: PayloadAction<boolean>) {
            state.survey.surveyDataLoading = 0;
        },
        updateSurveyCollapsedState(state, action: PayloadAction<string | string[]>) {
            state.survey.collapsedState = action.payload;
        },
    },
});

export const {
    updateSelectedSurveyManagement,
    setSurveyMode,
    destroyState,
    updateSurveyManagementCriteria,
    updateSurveyOverviewCriteria,
    setSurveyDataLoading,
    resetSurveyDataLoading,
    updateSurveyCollapsedState
} = surveySlice.actions;
export default surveySlice.reducer;
