import { SurveyOverviewFilter } from "__generated__/globalTypes";

export enum MODE {
  EDIT = 1,
  VIEW,
  OVERVIEW,
}

export interface SurveyManagementInputs {
  partnerNo: string,
  partnerName: string,
  companyCode: string,
  email: string,
  surveyStatusId: number,
  surveyId: number
}


export interface IStoredSurvey {
  selectedSurveyManagement: string
  surveyMode: MODE.VIEW | MODE.EDIT | MODE.OVERVIEW;
  managementSearchCriteria: SurveyManagementInputs;
  surveyOverviewCriteria: SurveyOverviewFilter;
  surveyDataLoading: number;
  collapsedState: string | string[];
}