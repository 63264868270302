import React, {useEffect, useRef, useState} from "react";
import styles from "./styling/infoportal.module.css";
import {FormInstance, Radio, Select, Space, Spin} from "antd";
import {
    DropDownDataSource,
    dropDownOverloadedConstructor,
    DropDownSelection,
    DropDownSelectionMode,
    DropDownSelectType,
    DropDownUpdater,
    ExternalStateUpdateCallback,
    Initialization,
    InternalReport,
    LabelLocation,
    OnChangeListener,
    ReportRegion,
    SelectionFilter,
    Validator
} from "../InfoPortalInterfaces";
import {IAppComponentProps} from "../../../components";
import {Form_Item} from "../../e-cap/componets/FormItem-component";
import {getBusinessAreaCodeData} from "../../e-cap/services/LookupServices";
import {
    getDeliveryStatuses,
    getInvoiceDirection,
    getInvoiceProcess,
    getInvoiceStatuses, getMarket,
    getOverdue,
    getPlantInfo,
    getProcurement,
    getReportTypes,
    getSettlementTypes,
    getStatus,
    getType
} from "../services/Selectors";
import {getCurrencyListData} from "../../e-cap/services/SelectServices";
import {IntlShape} from "react-intl/src/types";
import {
    companiesLookupByEntitlement,
    externalPartnersLookupByEntitlement,
    filteredEntitlementLookupForSelf,
    partnerLookupByCompanyCodeAndPartnerFilter
} from "../../../main/entitlementService";
import {
    getUserEntitlementResultByUserId_getUserEntitlementResultByUserId_partners as Partner
} from "../../../main/__generated__/getUserEntitlementResultByUserId";
import {isExternalUser, isNotNull} from "../../../utils/Utils";
import {
    getBusinessAreaList_getBusinessAreaList as BusinessAreaItem
} from "../../e-cap/__generated__/getBusinessAreaList";
import {useAppSelector} from "../../../main/hooks";

function translateText(item: any, propertyName: string, intl: IntlShape) {
    if (intl && item && item.phraseId) {
        // return intl.formatMessage({id: item.phraseId,defaultMessage:item[propertyName]});
    }

    if (!item) {
        return '';
    }

    return `${item[propertyName]}`;
}

function sortByDescription(array:DropDownSelection[]) {
    return array.sort((a,b)=>a.description.toLowerCase().localeCompare(b.description.toLowerCase()));
}

function extractIDFromConcatenatedKey(dashConcatenatedKeys:string[]|string) {
    if (Array.isArray(dashConcatenatedKeys)) {
        return dashConcatenatedKeys?.filter(item => isNotNull(item))
            .map(item => item.split('-'))
            .filter(items => items.length > 1)
            .map(items => items[1]);
    }

    if (isNotNull(dashConcatenatedKeys)) {
        const strings = dashConcatenatedKeys.split('-');
        if (strings.length>1) {
            return [strings[1]];
        }
    }

    return [];
}

type DropDownProperties =
    {
        initialization: Initialization,
        form: FormInstance<any>,
        initialValue?: any,
        selectionType?: DropDownSelectType,
        selectionMode?: DropDownSelectionMode,
        labelLocation?: LabelLocation,
        externalStateUpdater?: ExternalStateUpdateCallback<any>,
        validator?: Validator,
        onSelectionChange?: OnChangeListener<string[]>,
        reportName?: string,
        displayColon?: boolean,
        customLabel?: string,
        customFormFieldName?: string,
        filterOptions?: (input: string, option: any) => boolean | boolean,
        customDescription?: (arg:DropDownSelection)=>string,
        sortByDescription?:boolean,
        disabled?:boolean,
    }
    & IAppComponentProps;

type BaseDropDownProperties =
    {
        dataSource?: DropDownDataSource,
        selectionFilter?: SelectionFilter,
        intl,
        label: string,
        placeHolder: string,
        fieldName: string,
        dropDownProperties: DropDownProperties
        isDebug?: boolean,
        dropDownUpdater?: DropDownUpdater,
    }
    & IAppComponentProps;

function BaseDropDown(
    {
        label,
        placeHolder,
        fieldName,
        dataSource,
        selectionFilter,
        isDebug,
        intl,
        dropDownUpdater,
        dropDownProperties: {
            initialization,
            form,
            initialValue,
            onSelectionChange,
            selectionType,
            selectionMode,
            labelLocation,
            validator,
            filterOptions,
            displayColon,
            disabled
        }
    }: BaseDropDownProperties) {
    const [list, setList] = useState<DropDownSelection[]>([]);
    const [isLoading,setIsLoading] = useState<boolean>(false);
    const { rerenderAfterLanguageChange } = useAppSelector((state) => state.globalStateReducer);
    const keyDownTimerID = useRef<any>(null);

    const selectValue = (val) => {
        if (isDebug) {
            console.debug(val);
        }

        if (Array.isArray(val)) {
            onSelectionChange?.performAction(val);
        } else {
            if (val?.target) {
                onSelectionChange?.performAction([val.target.value]);
            } else  {
                onSelectionChange?.performAction([val]);
            }
        }
    }

    const handleKeyUp = (e) => {
        if (selectionFilter) {
            if (keyDownTimerID.current) {
                clearTimeout(keyDownTimerID.current);
            }

            keyDownTimerID.current = setTimeout(() => {
                setIsLoading(true);
                keyDownTimerID.current = null;
                selectionFilter.onTextChange(e.target?.value, list,setList).finally(()=> {
                   setIsLoading(false);
                });

            },2000);
        }
    };

    function setInitialValue(list?:DropDownSelection[]) {
        if (initialValue) {
            const listIDs = list?.map(item=>item.id);
            let newInitialValue = [];
            let containsValue = false;

            if (Array.isArray(initialValue)) {
                initialValue.forEach(indValue=> {
                   if (listIDs.includes(indValue)) {
                       newInitialValue.push(indValue);
                   }
                });
                containsValue = initialValue.length>0;
            }
            else {
                if (listIDs.includes(initialValue)) {
                    newInitialValue = initialValue;
                    containsValue = true;
                }
            }

            if (!form.getFieldValue(fieldName) && containsValue) {
                const initial = {};
                initial[fieldName] = newInitialValue;
                form.setFieldsValue(initial);
            }
        }
    }

    useEffect(() => {
        if (dropDownUpdater) {
            dropDownUpdater.dropDownUpdater = setList;
            dropDownUpdater.valueUpdater = (vals: string[]) => {
                const initial = {};
                initial[fieldName] = vals;
                form.setFieldsValue(initial);
            };
        }

        if (dataSource) {
            const initID = initialization.beginInitialize();
            const promise = dataSource.fetchData();
            if (promise) {
                promise.then(result=> {
                    setList(result);
                    setInitialValue(result);
                })
                    .catch(console.error)
                    .finally(() => {
                        initialization.finishInitialize(initID);
                    });
            }
            else {
                console.warn(`Search for ${fieldName} returned an undefined.`);
                initialization.finishInitialize(initID);
                setInitialValue();
            }
        }
    }, [rerenderAfterLanguageChange])

    function wrapInSpinner(displayDropDown:()=>JSX.Element) {
        return <Spin data-testid={isLoading? `not-finished-loading-${fieldName}-selector`:`finished-loading-${fieldName}-selector`} size={"large"} spinning={isLoading}>
            {displayDropDown()}
        </Spin>
    }

    function displayAsDropDown() {
        return <div className={styles[fieldName]}>


            <Form_Item label={label} name={fieldName} itemRules={validator?.fetchRule(fieldName)}>
                <Select
                    size={"middle"}
                    showSearch={true}
                    disabled={disabled}
                    onKeyUp={handleKeyUp}
                    mode={selectionMode === DropDownSelectionMode.MULTIPLE ? "multiple" : null}
                    style={{width: "100%"}}
                    placeholder={placeHolder}
                    defaultActiveFirstOption={true}

                    filterOption={filterOptions}
                    notFoundContent={null}
                    onChange={selectValue}
                    allowClear={true}>
                    {list.map((item,idx) => (
                        <Select.Option data-testid={`${idx+1}_${fieldName}_selector_${item.id}`}
                                       key={`${fieldName}_${item.id}`} value={item.id}>
                            {`${item.description || intl.formatMessage({id:item.phraseId})}`}
                        </Select.Option>
                    ))}
                </Select>
            </Form_Item>

        </div>;
    }

    function displayAsRadioButtons() {
        return <div className={`info-portal-radio-div`}>

            {(labelLocation !== LabelLocation.LEFT) &&
                <p className={`${validator?.isMandatory(fieldName) ? `${styles.pseudoMandatory}` : 'not-mandatory'} info-radio-label top`}>{label}</p>}
            {(labelLocation === LabelLocation.LEFT) && <span
                className={`${validator?.isMandatory(fieldName) ? `${styles.pseudoMandatory}` : 'not-mandatory'} info-radio-label left`}>{label}</span>}

            <div style={{display: "inline-block", marginLeft: "10px"}}>
                <Form_Item name={'reportType'} label={''} itemRules={validator?.fetchRule(fieldName)}>
                    <Radio.Group onChange={selectValue}>
                        <Space direction={"vertical"}>
                            {list.map(reportType =>
                                <Radio data-testid={`${fieldName}_${reportType.id}`} className="info-radio" key={`${reportType.id}`} value={reportType.id}>
                                    {reportType.description || intl.formatMessage({id:reportType.phraseId})}
                                </Radio>)}
                        </Space>
                    </Radio.Group>
                </Form_Item>
            </div>
        </div>;
    }

    return selectionType === DropDownSelectType.RADIO ? wrapInSpinner(displayAsRadioButtons) : wrapInSpinner(displayAsDropDown);
}

export function BusinessAreaListSelection(props: DropDownProperties) {
    function generateDescription(item: BusinessAreaItem) {
        return `${item?.businessAreaCode}-${item?.businessAreaName}`
    }

    function sortBusinessArea(arg1: BusinessAreaItem, arg2: BusinessAreaItem) {
        return generateDescription(arg1).localeCompare(generateDescription(arg2));
    }

    const dataSource: DropDownDataSource = {
        fetchData(): Promise<DropDownSelection[]> {
            return getBusinessAreaCodeData()
                .then(result => result.getBusinessAreaList)
                .then(result => {
                    if (result) {
                        const arrayCopy = [...result];
                        return arrayCopy.sort(sortBusinessArea);
                    }
                    return result;
                })
                .then(result => result.map(item => dropDownOverloadedConstructor(item.businessAreaCode, generateDescription(item))));
        }
    }

    return <BaseDropDown dataSource={dataSource}
                         label={props.intl.formatMessage({id: 'info-portal-business-area-selection'})}
                         placeHolder={props.intl.formatMessage({id: "survey-management-grid-header-select"})}
                         fieldName={'businessAreaCode'} dropDownProperties={props} intl={props.intl}/>
}

const distinctPartners = (companies: Partner[]) => {
    const newArray: Partner[] = [];
    const repeatedKeys = {};

    companies.forEach(item => {
        const key = `${item.partnerId}`;
        if (!repeatedKeys[key]) {
            newArray.push(item);
            repeatedKeys[key] = true;
        }
    });

    return newArray;
};

const distinctDropDowns = (dropDowns: DropDownSelection[]) => {
    if (dropDowns) {
        const newArray: DropDownSelection[] = [];
        const repeatedKeys = {};

        dropDowns.forEach(item => {
            const key = `${item.id}`;
            if (!repeatedKeys[key]) {
                newArray.push(item);
                repeatedKeys[key] = true;
            }
        });

        return newArray;
    }

    return dropDowns;
};

type CompanySelectionProperties = {
    onSelectionChange: OnChangeListener<string[]>,
    companyEntitlement: string,
    customFilter?:(item:any)=>boolean,
} & DropDownProperties;

export function CompanySelection(props: CompanySelectionProperties) {

    const dataSource: DropDownDataSource = {
        fetchData(): Promise<DropDownSelection[]> {
            return companiesLookupByEntitlement(props.companyEntitlement)
                .then(result => result.filter(item => isNotNull(item.companyCode)).filter(item=> !props.customFilter || props.customFilter(item)).sort((a, b) => a.companyCode.localeCompare(b.companyCode)))
                .then(result => result.map(item => dropDownOverloadedConstructor(`${item.companyId}-${item.companyCode}`, `${item.companyCode}-${translateText(item, 'companyName', props.intl)}`)));
        }
    }

    const label = props.customLabel || props.intl.formatMessage({id: "grid-heading-company"});

    return <BaseDropDown dataSource={dataSource}
                         label={label}
                         placeHolder={props.intl.formatMessage({id: "survey-management-grid-header-select"})}
                         fieldName={'company'} dropDownProperties={props}
                         intl={props.intl}/>
}

export function DeliveredStatusSelection(props: DropDownProperties) {
    const dataSource: DropDownDataSource = {
        fetchData(): Promise<DropDownSelection[]> {
            return getDeliveryStatuses(props.currentUser.user_id, props.distinctEntitlements)
                .then(result => result.map(item => dropDownOverloadedConstructor(item.id, item.description)))
                .then(sortByDescription);
        }
    }

    return <BaseDropDown dataSource={dataSource}
                         label={props.intl.formatMessage({id: 'delivery-status'})}
                         placeHolder={props.intl.formatMessage({id: "survey-management-grid-header-select"})}
                         fieldName={'deliveredStatus'} dropDownProperties={props} intl={props.intl}/>
}

type InvoiceStatusSelectionProperties = { reportName: string, isPOFlip?:boolean } & DropDownProperties;

export function InvoiceStatusSelection(props: InvoiceStatusSelectionProperties) {
    const selectDescription: (item:DropDownSelection)=>string
        = props.customDescription || ((item)=>item.description);
    const dataSource: DropDownDataSource = {
        fetchData(): Promise<DropDownSelection[]> {
            return getInvoiceStatuses(props.currentUser?.user_id, props.distinctEntitlements, props.reportName)
                .then(result => result.map(item => dropDownOverloadedConstructor(item.id, selectDescription(item))).filter(item=>!props.isPOFlip || item.id!=='RE3')).then(result=> {
                   if (props.sortByDescription) {
                       return sortByDescription(result);
                   }
                   return result;
                });
        }
    }

    const label = props.customLabel || props.intl.formatMessage({id: "info-portal-select-invoice-status"});
    return <BaseDropDown dataSource={dataSource}
                         label={label}
                         placeHolder={props.intl.formatMessage({id: "survey-management-grid-header-select"})}
                         fieldName={"invoiceStatus"} dropDownProperties={props} intl={props.intl}/>;
}

export function StatusSelection(props: InvoiceStatusSelectionProperties) {
    const dataSource: DropDownDataSource = {
        fetchData(): Promise<DropDownSelection[]> {
            return getStatus(props.currentUser?.user_id, props.distinctEntitlements)
                .then(result => result.map(item => dropDownOverloadedConstructor(item.id, `${item.description}`)));
        }
    };

    return <BaseDropDown dataSource={dataSource}
                         label={props.intl.formatMessage({id: "select-status", defaultMessage: "Status"})}
                         placeHolder={props.intl.formatMessage({id: "survey-management-grid-header-select"})}
                         fieldName={"status"} dropDownProperties={props} intl={props.intl}/>;
}

export function OverdueStatusSelection(props: InvoiceStatusSelectionProperties) {
    const selectDescription: (item:DropDownSelection)=>string
        = props.customDescription || ((item)=>item.description);

    const dataSource: DropDownDataSource = {
        fetchData(): Promise<DropDownSelection[]> {
            return getOverdue(props.currentUser?.user_id, props.distinctEntitlements)
                .then(result => result.map(item => dropDownOverloadedConstructor(item.id, selectDescription(item))));
        }
    };

    return <BaseDropDown dataSource={dataSource}
                         label={props.intl.formatMessage({id: "overdue-status", defaultMessage: "Overdue Status"})}
                         placeHolder={props.intl.formatMessage({id: "survey-management-grid-header-select"})}
                         fieldName={"overdueStatus"} dropDownProperties={props} intl={props.intl}/>;
}

export function ProcurementSelection(props: InvoiceStatusSelectionProperties) {
    const dataSource: DropDownDataSource = {
        fetchData(): Promise<DropDownSelection[]> {
            return getProcurement(props.currentUser?.user_id, props.distinctEntitlements)
                .then(result => result.map(item => dropDownOverloadedConstructor(item.id, `${item.description}`)));
        }
    };

    return <BaseDropDown dataSource={dataSource}
                         label={props.intl.formatMessage({id: "select-procurement", defaultMessage: "Procurement"})}
                         placeHolder={props.intl.formatMessage({id: "survey-management-grid-header-select"})}
                         fieldName={"procurement"} dropDownProperties={props} intl={props.intl}/>;
}

export function TypeSelection(props: InvoiceStatusSelectionProperties) {
    const dataSource: DropDownDataSource = {
        fetchData(): Promise<DropDownSelection[]> {
            return getType(props.currentUser?.user_id, props.distinctEntitlements, props.intl)
                .then(result => result.map(item => dropDownOverloadedConstructor(item.id, `${item.description}`)));
        }
    };

    return <BaseDropDown dataSource={dataSource}
                         label={props.intl.formatMessage({id: "edoc-notification-header-type", defaultMessage: "Type"})}
                         placeHolder={props.intl.formatMessage({id: "survey-management-grid-header-select"})}
                         fieldName={"type"} dropDownProperties={props} intl={props.intl}/>;
}

type PartnerSelectionProperties =
    {
        companyIds: string[],
        partnerEntitlement: string,
        externalStateUpdater: ExternalStateUpdateCallback<{ companyIds: string[], newVal: string[] }>,
        customFilter?:(item:any)=>boolean,
    } &
    DropDownProperties;

export function PartnerSelection(props: PartnerSelectionProperties) {
    const [dropDownUpdater, _] = useState<DropDownUpdater>(new DropDownUpdater());
    const partnerFilter = useRef<string>('');

    const PAGE_SIZE = 50;

    function getEntitlement() {
        return props.partnerEntitlement;
    }

    const isThisAnExternalUser = ()=> {
        return isExternalUser(props.distinctEntitlements)
    }

    function getDefaultPartnerValueOnFirstRender() {
        const partners = props.form.getFieldValue('partner');
        if (partners) {
            if (typeof partners === "string") {
                return [partners];
            }
            return partners;
        }

        const theInitialValue = props.initialValue;

        if (theInitialValue) {
            if (typeof theInitialValue === "string") {
                return [theInitialValue];
            }
        }

        return theInitialValue;
    }

    function getDefaultCompanyValueOnFirstRender() {
        const company = props.form.getFieldValue('company');
        if (Array.isArray(company)) {
            if (company && company.length) {
                return extractIDFromConcatenatedKey(company);
            }
        }
        else if (typeof company === "string") {
            return extractIDFromConcatenatedKey(company);
        }

        return extractIDFromConcatenatedKey(props.companyIds);
    }

    const externalUserPartners = async ()=> {
        const companyInitialValue = getDefaultCompanyValueOnFirstRender() || [];

        const companySet:{[key:string]:boolean} =
            await companiesLookupByEntitlement(props.partnerEntitlement).then(companies=> {
            return companies.reduce((prev,curr)=> {
                prev[`${curr.companyCode}`]=false
                return prev;
            },{})
        })

        Object.keys(companySet).forEach(key=> {
          companySet[key] =  companyInitialValue.length === 0 || companyInitialValue.includes(key);
        })

        const partners = await externalPartnersLookupByEntitlement(props.currentUser.uid, props.partnerEntitlement,1,PAGE_SIZE)
        const filteredPartners = partners.filter(partner=> {
            const partnerCompanies = partner.companies;
            if (partnerCompanies?.length) {
                for (const partnerCompany of partnerCompanies) {
                    if (partnerCompany.companyCode) {
                        if (companySet[`${partnerCompany.companyCode}`]) {
                            if (props.customFilter) {
                                return props.customFilter(partner);
                            }
                            return true;
                        }
                    }
                }

                console.debug(
                    `filtering out ${partner.partnerName} Because none of it's companies are in the list of selectable companies for report`,
                    `[${partnerCompanies?.map(company => company.companyCode)?.join() || ''}]`,'selectable companies',
                    companySet);

                return false;
            }

            console.debug(
                `filtering out ${partner.partnerName} Because it doesn't have any companies`,'selectable companies', companySet);

            return false;
        });

        return filteredPartners
    }

    const partnerFilterHandler: SelectionFilter = {
        onTextChange(text: string, dropdownList: DropDownSelection[], optionsSetter: (arg: DropDownSelection[]) => void) {
            function canSearch() {
                if (text && text.length>2) {
                    return true;
                }

                return false;
            }

            if (canSearch()) {
                partnerFilter.current = text;
                return filteredEntitlementLookupForSelf(getEntitlement(),partnerFilter.current, 1, PAGE_SIZE).then(response=>response?.getSelfEntitlementResultByFilter.partners)
                    .then(result=> result || [])
                    .then(result => result.filter(partner=>!props.customFilter || props.customFilter(partner)).map(item => dropDownOverloadedConstructor(`${item.partnerNo}`, `${item.partnerNo} - ${translateText(item, 'partnerName', props.intl)}`)))
                    .then(distinctDropDowns)
                    .then(sortByDescription)
                    .then(result => {
                        const newResult = [...result].sort((a, b) => a.description.localeCompare(b.description));
                        optionsSetter(newResult);
                        return result;
                    })
            }
            else if (isThisAnExternalUser()) {
                return externalUserPartners()
                    .then(result=> result || [])
                    .then(result => result.map(item => dropDownOverloadedConstructor(`${item.partnerNo}`, `${item.partnerNo} - ${translateText(item, 'partnerName', props.intl)}`)))
                    .then(distinctDropDowns)
                    .then(sortByDescription)
            }

            return Promise.resolve();
        }
    };

    const dataSource: DropDownDataSource = {
        fetchData(): Promise<DropDownSelection[]> {
            const initialValue = getDefaultPartnerValueOnFirstRender();
            const companyInitialValue = getDefaultCompanyValueOnFirstRender();
            if (initialValue) {
                if (!isThisAnExternalUser()) {
                    if (Array.isArray(initialValue) && Array.isArray(companyInitialValue)) {
                        const allPromises =
                            Promise.all(initialValue
                                .map(item => item.indexOf('-') > 0 ? item.split('-')[1]: item)
                                .filter(isNotNull)
                                .filter(item=>item.length>2)
                                .map(partnerNumber =>
                                    partnerLookupByCompanyCodeAndPartnerFilter(getEntitlement(), partnerNumber, companyInitialValue,1, PAGE_SIZE)
                                        .then(result=> result || [])
                                        .then(result => result.map(item => dropDownOverloadedConstructor(`${item.partnerNo}`, `${item.partnerNo} - ${translateText(item, 'partnerName', props.intl)}`)))
                                        .then(distinctDropDowns)))

                        return allPromises.then(allResults=> {
                            const toReturn = [];
                            allResults.forEach(result=> {
                                toReturn.push(...result);
                            });

                            return sortByDescription(toReturn);
                        })
                    }
                }
                else {
                    return externalUserPartners()
                        .then(result=> result || [])
                        .then(result => result.map(item => dropDownOverloadedConstructor(`${item.partnerNo}`, `${item.partnerNo} - ${translateText(item, 'partnerName', props.intl)}`)))
                        .then(distinctDropDowns)
                        .then(sortByDescription)
                }
            }
            else if (isThisAnExternalUser()) {
                return externalUserPartners()
                    .then(result=> result || [])
                    .then(result => result.map(item => dropDownOverloadedConstructor(`${item.partnerNo}`, `${item.partnerNo} - ${translateText(item, 'partnerName', props.intl)}`)))
                    .then(distinctDropDowns)
                    .then(sortByDescription)
            }

            return Promise.resolve([]);
        }
    };

    props.externalStateUpdater.register({
        performAction({companyIds, newVal}: { companyIds: string[], newVal: string[] }) {
            if (companyIds && companyIds.length) {
                const searchParameters = [];
                const initialValue = getDefaultPartnerValueOnFirstRender();
                const companyInitialValue = extractIDFromConcatenatedKey(companyIds);

                if (partnerFilter.current && partnerFilter.current.length>2) {
                    searchParameters.push(partnerFilter.current);
                }

                if (initialValue) {
                    initialValue.filter(item => item.indexOf('-') > 0)
                        .map(item => item.split('-')[1])
                        .filter(isNotNull)
                        .filter(item=>item.length>2)
                        .filter(item=> {
                            searchParameters.push(item);
                        });
                }

                if (companyInitialValue && companyInitialValue.length && searchParameters.length) {
                    const allPromises =
                        Promise.all(searchParameters
                            .map(partnerNumber =>
                                partnerLookupByCompanyCodeAndPartnerFilter(getEntitlement(), partnerNumber, companyInitialValue,1, PAGE_SIZE)
                                    .then(result=> result || [])
                                    .then(result => result.map(item => dropDownOverloadedConstructor(`${item.partnerNo}`, `${item.partnerNo} - ${translateText(item, 'partnerName', props.intl)}`)))
                                    .then(distinctDropDowns)))

                    allPromises.then(allResults=> {
                        const toReturn = [];
                        allResults.forEach(result=> {
                            toReturn.push(...result);
                        });

                        return sortByDescription(toReturn);
                    }).then(result => {
                        const reducedPartners =
                            result.reduce((prev, curr) => {
                                prev[curr.id] = true;
                                return prev;
                            }, {});
                        const updatedValues = newVal? newVal.filter(val => reducedPartners[val]) : [];
                        dropDownUpdater.valueUpdater(updatedValues);
                    });
                }
            }
            else {
                dropDownUpdater.valueUpdater([]);
            }
        }
    });

    const label = props.customLabel || props.intl.formatMessage({id: "management-partnerno-placeholder"});
    const fieldName = props.customFormFieldName || 'partner';
    return <BaseDropDown dataSource={dataSource}
                         selectionFilter={partnerFilterHandler}
                         dropDownUpdater={dropDownUpdater}
                         label={label}
                         placeHolder={props.intl.formatMessage({id: "survey-management-grid-header-select"})}
                         fieldName={fieldName} dropDownProperties={props} intl={props.intl}/>
}

type ReportSelectionProperties = { region: ReportRegion|InternalReport, reportLabel?:string } & DropDownProperties;

export function ReportSelection(props: ReportSelectionProperties) {
    const dataSource: DropDownDataSource = {
        fetchData(): Promise<DropDownSelection[]> {
            const reportTypes = getReportTypes(props.region, props.distinctEntitlements, props.intl);
            return reportTypes;
        }
    }

    return <BaseDropDown dataSource={dataSource}
                         label={`${props.intl.formatMessage({id: props.reportLabel || 'select-your-report'})} :`}
                         placeHolder={props.intl.formatMessage({id: "survey-management-grid-header-select"})}
                         fieldName={'reportType'} dropDownProperties={props} intl={props.intl}/>
}

type PlantInfoSelectionProperties = { reportName: string } & DropDownProperties;

export function PlantInfoSelection(props: PlantInfoSelectionProperties) {
    const dataSource: DropDownDataSource = {
        fetchData(): Promise<DropDownSelection[]> {
            return getPlantInfo(props.currentUser?.user_id, props.distinctEntitlements, props.reportName);
        }
    }

    return <BaseDropDown dataSource={dataSource}
                         label={props.intl.formatMessage({id: 'info-plant-heading'})}
                         placeHolder={props.intl.formatMessage({id: "survey-management-grid-header-select"})}
                         fieldName={'plantInfo'} dropDownProperties={props} intl={props.intl}/>
}

export function SettlementTypeSelection(props: DropDownProperties) {

    const dataSource: DropDownDataSource = {
        fetchData(): Promise<DropDownSelection[]> {
            return getSettlementTypes(props.currentUser?.user_id, props.distinctEntitlements)
                .then(result => result.map(item => dropDownOverloadedConstructor(item.id, `${item.id} - ${item.description}`)));
        }
    };
    return <BaseDropDown dataSource={dataSource}
                         label={props.intl.formatMessage({id: 'info-portal-settlement-type'})}
                         placeHolder={props.intl.formatMessage({id: "survey-management-grid-header-select"})}
                         fieldName={'settlementType'} dropDownProperties={props} intl={props.intl}/>
}

export function CurrencySelection(props: DropDownProperties) {
    const dataSource: DropDownDataSource = {
        fetchData(): Promise<DropDownSelection[]> {
            return getCurrencyListData()
                .then(result => result.getCurrencyList)
                .then(currencies => currencies.map(currency => dropDownOverloadedConstructor(currency.id, `${currency.id}-${currency.currencyName}`)))
        }
    }

    return <BaseDropDown dataSource={dataSource}
                         label={props.intl.formatMessage({id: 'header-details-currency-title'})}
                         placeHolder={props.intl.formatMessage({id: "survey-management-grid-header-select"})}
                         fieldName={'currency'} dropDownProperties={props} intl={props.intl}/>
}

export function InvoiceDirectionSelection(props: DropDownProperties) {
    const dataSource: DropDownDataSource = {
        fetchData(): Promise<DropDownSelection[]> {
            return getInvoiceDirection(props.currentUser.user_id, props.distinctEntitlements);
        }
    }

    return <BaseDropDown dataSource={dataSource}
                         label={props.intl.formatMessage({id: 'invoice-direction'})}
                         placeHolder={props.intl.formatMessage({id: "survey-management-grid-header-select"})}
                         fieldName={'invoiceDirection'} dropDownProperties={props} intl={props.intl}/>
}

export function InvoiceProcessSelection(props: DropDownProperties) {
    const dataSource: DropDownDataSource = {
        fetchData(): Promise<DropDownSelection[]> {
            return getInvoiceProcess(props.currentUser.user_id, props.distinctEntitlements)
              .then(results => results.map(item => dropDownOverloadedConstructor(item.id, `${item.id} - ${item?.description}`)));
        }
    }

    return <BaseDropDown dataSource={dataSource}
                         label={props.intl.formatMessage({id: 'invoice-process'})}
                         placeHolder={props.intl.formatMessage({id: "survey-management-grid-header-select"})}
                         fieldName={'invoiceProcess'} dropDownProperties={props} intl={props.intl}/>
}

export function InvoicingPartySelection(props: PartnerSelectionProperties) {
    const [dropDownUpdater, _] = useState<DropDownUpdater>(new DropDownUpdater());
    const partnerFilter = useRef<string>('');

    const PAGE_SIZE = 50;

    function getDefaultPartyOnInitialLoad() {
        const party = props.form.getFieldValue('invoicingParty');
        if (party && party.length) {
            return party;
        }

        return props.initialValue;
    }

    const partnerFilterHandler: SelectionFilter = {
        onTextChange(text: string, dropdownList: DropDownSelection[], optionsSetter: (arg: DropDownSelection[]) => void) {

            function canSearch() {
                if (text && text.length>2) {
                    if (partnerFilter.current && text.includes(partnerFilter.current) && dropdownList && dropdownList.length < PAGE_SIZE) {
                        return false;
                    }

                    return true;
                }

                return false;
            }


            if (canSearch()) {
                partnerFilter.current = text;
                return filteredEntitlementLookupForSelf(props.partnerEntitlement,partnerFilter.current,1,50).then(result=>result?.getSelfEntitlementResultByFilter?.partners)
                    .then(result=> result || [])
                    .then(distinctPartners)
                    .then(result => result.map(item => dropDownOverloadedConstructor(`${item.partnerId}-${item.partnerNo}`, `${item.partnerNo} - ${translateText(item, 'partnerName', props.intl)}`)))
                    .then(sortByDescription)
                    .then(result => {
                        const newResult = [...result].sort((a, b) => a.description.localeCompare(b.description));
                        optionsSetter(newResult);
                        return result;
                    })
            }

            return Promise.resolve();
        }
    };

    const dataSource: DropDownDataSource = {
        fetchData(): Promise<DropDownSelection[]> {
            const party = getDefaultPartyOnInitialLoad();
            if (Array.isArray(party)) {
                const allPromises =
                    Promise.all(party
                        .filter(item => item.indexOf('-') > 0)
                        .map(item => item.split('-')[1])
                        .filter(isNotNull)
                        .filter(item=>item.length>2)
                        .map(partnerNumber =>
                            filteredEntitlementLookupForSelf(props.partnerEntitlement,partnerNumber,1,50).then(result=>result?.getSelfEntitlementResultByFilter?.partners)
                                .then(result=> result || [])
                                .then(distinctPartners)
                                .then(result => result.map(item => dropDownOverloadedConstructor(`${item.partnerId}-${item.partnerNo}`, `${item.partnerNo} - ${translateText(item, 'partnerName', props.intl)}`)))));

                return allPromises.then(allResults=> {
                    const toReturn = [];
                    allResults.forEach(result=> {
                        toReturn.push(...result);
                    });

                    return sortByDescription(toReturn);
                })
            }
            return Promise.resolve([]);
        }
    };

    props.externalStateUpdater.register({
        performAction(_: number[]) {
            //do nothing....
        }
    });

    return <BaseDropDown dataSource={dataSource}
                         dropDownUpdater={dropDownUpdater}
                         selectionFilter={partnerFilterHandler}
                         label={props.intl.formatMessage({id: 'info-portal-invoicing-party'})}
                         placeHolder={props.intl.formatMessage({id: "survey-management-grid-header-select"})}
                         fieldName={'invoicingParty'} dropDownProperties={props}
                         intl={props.intl}/>
}

export function descriptionFilter(input: string, option: any) {
    return `${option['children']}`.toLowerCase().indexOf(input.toLowerCase()) >= 0;
}

