import { IAppComponentProps } from '../../../components';
import React, { useEffect, useState } from 'react';
import { Col, Form, FormInstance, Input, message, Row, Select, Spin } from 'antd';
import { getI18n } from '../../../utils/Utils';
import {
    allCompanyInfoPerCategory, allCompanyInfoPerCategoryAndSystem,
    allVatInfoByCompanyId,
    getCompanyAddressDetails,
    getPartnerVatInfoData,
} from '../services/PartnerService';
import { allVatCodesByCompanyId } from '../__generated__/allVatCodesByCompanyId';
import { useAppDispatch, useAppSelector } from '../../../main/hooks';
import {
    setBmwCompanyCity,
    setBmwCompanyPostalCode,
    setBmwCompanyStreet1,
    setBmwCompanyStreet2,
    setBmwCompanyTaxId,
    setCompanyCountryCode,
    setCompanyName,
    setCompanyVatCode,
    setCompanyVatCountryCode,
    setLoading,
    setPartnerAddressCity,
    setPartnerAddressCountryCode,
    setPartnerAddressPostalCode,
    setPartnerAddressRegionCode,
    setPartnerAddressStreet1,
    setPartnerAddressStreet2,
    setPartnerName,
    setPartnerVatCode,
    setPartnerVatSelectOptions,
    updateAgreementHistory,
    updateBMWBusinessAreaId,
    updateBMWBusinessUnitId,
    updateBMWRetailOutlet,
    updateCompanyCode,
    updateCompanyCountryCode,
    updateCompanyPartnerId,
    updateCompanyVatCodeId,
    updatePartnerNo,
    updatePartnerVatCodeId,
    updateSupplier,
    updateSupplierCountryCode,
} from '../IStoredDocumentRecordSlice';
import { Form_Item } from '../componets/FormItem-component';
import { getControlRuleset } from '../RulesEngine';
import { getBusinessAreaList, getBusinessAreaList_getBusinessAreaList } from '../__generated__/getBusinessAreaList';
import { getBusinessUnitList, getBusinessUnitList_getBusinessUnitList } from '../__generated__/getBusinessUnitList';
import { getBusinessAreaCodeData, getBusinessUnitCodeData } from '../services/LookupServices';
import { initFields } from '../capture-document-helper';
import { getDocumentRecord } from '../__generated__/getDocumentRecord';
import { useLazyQuery } from '@apollo/client';
import {
    getPartnerAddressByPartnerId,
    getPartnerAddressByPartnerIdVariables,
} from '../__generated__/getPartnerAddressByPartnerId';
import { GET_PARTNER_ADDRESS_DETAILS,  INVOICE_AGREEMENT_HISTORY_V2 } from '../queries';
import { GET_invoice_agreement_history_V2, GET_invoice_agreement_history_V2Variables } from '../__generated__/GET_invoice_agreement_history_V2';
import { cloneDeep } from 'lodash';
import { useGetCompanyDefaultTaxIdQuery } from '../queries.generated';
import { getCompanyInfoByCompanyPartnerNoCategory } from '../__generated__/getCompanyInfoByCompanyPartnerNoCategory';
import { updateSelectedPartner } from '../IStoredPartnerRecordSlice';

const { Option } = Select;

type BMWCompanyHeaderProps = {
    documentRecord: getDocumentRecord;
    form: FormInstance;
    fillPartnerVatDetails: (partnerNo: string, companyCode: string) => any;
    businessUnitSetter?: (arg: (areaId: number) => any) => void;

} & IAppComponentProps;

const BMWCompanyHeader: React.FunctionComponent<BMWCompanyHeaderProps> = (props) => {
    const dispatch = useAppDispatch();
    const intl = props.intl;
    const form = props.form;
    const [vatList, setVatList] = useState<allVatCodesByCompanyId>();
    const [componentLoaded, setComponentLoaded] = useState<boolean>(false);
    const [businessAreaListData, setBusinessAreaListData] = useState<getBusinessAreaList>();
    const [businessAreaList, setBusinessAreaList] = useState<getBusinessAreaList_getBusinessAreaList[]>();
    const [businessUnitListData, setBusinessUnitListData] = useState<getBusinessUnitList>();
    const [businessUnitList, setBusinessUnitList] = useState<getBusinessUnitList_getBusinessUnitList[]>();
    const { document } = useAppSelector((state) => state.document);
    const { partnerRecord } = useAppSelector((state) => state.partnerRecord);
    const { ecapEntitlementPartner } = useAppSelector((state) => state.ecapEntitlementPartner);
    const rules = useAppSelector((state) => state.rules);
    const [bmwCompanyCountryCode, setBMWCompanyCountryCode ] = useState(undefined);
    const [bmwCompanyCode, setBMWCompanyCode ] = useState(undefined);
    const [bmwAlternativeCompanyTaxId,setBmwAlternativeCompanyTaxId] = useState(undefined);
    const [alternativeLoading,setAlternativeLoading] =  useState(false);
    const [companyCodeDropDownList,setCompanyCodeDropDownList] =  useState<{companyCode: string,companyName: string}[]>(undefined);

    const [getAgreementHistory, {}] = useLazyQuery<
        GET_invoice_agreement_history_V2,
        GET_invoice_agreement_history_V2Variables
    >(INVOICE_AGREEMENT_HISTORY_V2, {
        fetchPolicy: 'network-only',
        onError: (error) => console.log(error),
    });

    const { data: companyDefaultTaxIdData, loading: companyDefaultTaxIdDataLoading, error: companyDefaultTaxIdDataError } = useGetCompanyDefaultTaxIdQuery({
        variables: {
           companyCode: bmwCompanyCode,
           companyVatCountryCode: bmwCompanyCountryCode
        },
        skip: !bmwCompanyCode || !bmwCompanyCountryCode
      });

    if (companyDefaultTaxIdDataError)
    {
        message.error( `Error! ${companyDefaultTaxIdDataError.message}`);
        console.log(`error: ${companyDefaultTaxIdDataError.message}`);
    }
    if(companyDefaultTaxIdDataLoading && !document.loading)
    {
        dispatch(setLoading(true));
        setAlternativeLoading(true);
    }
    else if (!companyDefaultTaxIdDataLoading && alternativeLoading)
    {
        //This is to handle the case where this process did not trigger the loading flag then we don't want the spinner to be disabled if other data still loads
        setAlternativeLoading(false);
        dispatch(setLoading(false));
    }

    const getDropDownLists = () => {
        if (!businessAreaListData) {
            Promise.resolve(getBusinessAreaCodeData())
                .then((response) => {
                    setBusinessAreaListData(response);
                    console.log("business area response");
                    console.dir(response);
                    const businessAreaListSorted: getBusinessAreaList_getBusinessAreaList[] = cloneDeep(response.getBusinessAreaList);
                    businessAreaListSorted.sort((x, y) => {
                        return x.businessAreaName === y.businessAreaName
                            ? 0
                            : x.businessAreaName > y.businessAreaName
                            ? 1
                            : -1;
                    });
                    console.log("sorted list");
                    console.dir(businessAreaListSorted);
                    setBusinessAreaList(businessAreaListSorted);
                })
                .catch((err) => {
                    console.error(err);
                });
        }

        if (!businessUnitListData) {
            Promise.resolve(getBusinessUnitCodeData())
                .then((response) => {
                    setBusinessUnitListData(response);

                    if (props.businessUnitSetter) {
                        const updateBusinessUnit = (areaId: number) => {
                            const businessUnitList = response.getBusinessUnitList.filter((item) => {
                                return item.businessArea.id === areaId;
                            });
                            setBusinessUnitList(businessUnitList);
                        };

                        props.businessUnitSetter(updateBusinessUnit);
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }

        if (
            businessAreaListData !== undefined &&
            businessAreaListData.getBusinessAreaList !== undefined &&
            document.bmwBusinessAreaId !== null &&
            document.bmwBusinessAreaId !== undefined
        ) {
            const businessArea = businessAreaListData.getBusinessAreaList.find((item) => {
                if (item.id === document?.bmwBusinessAreaId) {
                    return item;
                }
            });
            form.setFieldsValue({ bmwBusinessArea: businessArea?.businessAreaName });
            const businessUnitList = businessUnitListData?.getBusinessUnitList.filter((item) => {
                return item.businessArea.id === businessArea?.id;
            });
            setBusinessUnitList(businessUnitList);
        }

        if (
            businessUnitListData !== undefined &&
            businessUnitListData.getBusinessUnitList !== undefined &&
            document.bmwBusinessUnitId !== null &&
            document.bmwBusinessUnitId !== undefined
        ) {
            //console.log(`get business unit data : ${document.bmwBusinessUnitId}`);
            const businessUnit = businessUnitListData.getBusinessUnitList.find((item) => {
                if (item.id === document?.bmwBusinessUnitId) {
                    return item;
                }
            });
            form.setFieldsValue({ bmwBusinessUnit: businessUnit?.businessUnitName });
        }
    };

    const getCompanyAddress = (companyId: number) => {
        //TODO We need clarity on what address type to return to front end (Shelly?)
        //console.log(`Get Address for Company Id : ${companyId}`);
        dispatch(setLoading(true));
        Promise.resolve(getCompanyAddressDetails(companyId, 'P'))
            .then((response) => {
                // eslint-disable-next-line no-console
                //console.log(`Company Address details for Company Id: ${companyId}`);
                // eslint-disable-next-line no-console
                //console.dir(response);
                if (response.getCompanyAddressDetailsByCompanyId !== undefined) {
                    props.form.setFieldsValue({
                        companyStreet1: response.getCompanyAddressDetailsByCompanyId.at(0).street1,
                    });
                    props.form.setFieldsValue({
                        companyPostalCode: response.getCompanyAddressDetailsByCompanyId.at(0).postalCode,
                    });
                    props.form.setFieldsValue({
                        companyCountry: response.getCompanyAddressDetailsByCompanyId.at(0).country,
                    });
                    props.form.setFieldsValue({ companyCity: response.getCompanyAddressDetailsByCompanyId.at(0).city });
                    dispatch(updateCompanyCountryCode(response.getCompanyAddressDetailsByCompanyId.at(0).countryCode));
                    dispatch(setBmwCompanyCity(response.getCompanyAddressDetailsByCompanyId.at(0).city));
                    dispatch(setBmwCompanyPostalCode(response.getCompanyAddressDetailsByCompanyId.at(0).postalCode));
                    dispatch(setBmwCompanyStreet1(response.getCompanyAddressDetailsByCompanyId.at(0).street1));
                    dispatch(setBmwCompanyStreet2(response.getCompanyAddressDetailsByCompanyId.at(0).street2));
                    dispatch(setCompanyCountryCode(response.getCompanyAddressDetailsByCompanyId.at(0).countryCode));
                } else {
                    props.form.setFieldsValue({ companyStreet1: undefined });
                    props.form.setFieldsValue({ companyPostalCode: undefined });
                    props.form.setFieldsValue({ companyCountry: undefined });
                    props.form.setFieldsValue({ companyCity: undefined });
                }
                dispatch(setLoading(false));
            })
            .catch((err) => {
                console.error(err);
                props.form.setFieldsValue({ companyStreet1: undefined });
                props.form.setFieldsValue({ companyPostalCode: undefined });
                props.form.setFieldsValue({ companyCountry: undefined });
                props.form.setFieldsValue({ companyCity: undefined });
                dispatch(setLoading(false));
            });
    };

    const getCompanyVatList = (companyId: number) => {
        //
        // isLoading(true);
        dispatch(setLoading(true));
        Promise.resolve(allVatInfoByCompanyId(companyId))
            .then((response) => {
                setVatList(response);
                if (response === null || response.allVatCodesByCompanyId.length === 0)
                {
                    props.form.setFieldsValue({ companyVatCode: undefined});
                    dispatch(updateCompanyVatCodeId(undefined));
                    dispatch(setCompanyVatCode(undefined))
                    dispatch(updateCompanyVatCodeId(undefined));
                    dispatch(setCompanyVatCountryCode(undefined));
                }
                else
                if (response.allVatCodesByCompanyId.length === 1) {
                    props.form.setFieldsValue({ companyVatCode: response.allVatCodesByCompanyId.at(0).vatCode });
                    //Update state with the auto selected company VAT Id
                    dispatch(updateCompanyVatCodeId(response.allVatCodesByCompanyId.at(0).id));
                    dispatch(setCompanyVatCode(response.allVatCodesByCompanyId.at(0).vatCode))
                    dispatch(setCompanyVatCountryCode(response.allVatCodesByCompanyId.at(0).countryCode.id));
                    setBMWCompanyCountryCode(response.allVatCodesByCompanyId.at(0).countryCode.id);
                    //console.log(`Selected VAT code Id : ${response.allVatCodesByCompanyId.at(0).id}`);
                } else {
                    //When component is first initiated do not clear fields so we don't override fields set in edit mode 
                    if (componentLoaded) {
                        console.log("Clear Vat code");
                        props.form.setFieldsValue({ companyVatCode: undefined });
                        //Clear state value as well
                        dispatch(updateCompanyVatCodeId(undefined));
                        dispatch(setCompanyVatCode(undefined))
                        dispatch(updateCompanyVatCodeId(undefined));
                        dispatch(setCompanyVatCountryCode(undefined));
                        setBMWCompanyCountryCode(undefined);
                        setBMWCompanyCode(undefined);
                        setComponentLoaded(true);
                    }
                }
                // isLoading(false);
                dispatch(setLoading(false));
            })
            .catch((err) => {
                console.error(err);
                // isLoading(false);
                dispatch(setLoading(false));
            });
    };

    const getPartnerVatList = (partnerNo: string, companyCode: string) => {
        dispatch(setLoading(true));
        Promise.resolve(getPartnerVatInfoData(partnerNo,companyCode))
            .then ((response) => {
                console.log("Set VAT drop down from BMWParnerHeader - > getPartnerVatList")
                console.dir(response?.getPartnerVATInfoByPartnerNo?.vatCodes);
                // props.setVatDropDown(response?.getPartnerVATInfoByPartnerNo?.vatCodes);
                dispatch(setPartnerVatSelectOptions(response?.getPartnerVATInfoByPartnerNo?.vatCodes));
                dispatch(setLoading(false));
            })
            .catch ((err) => {
                console.error(`Unable to get partner VAT list for ${partnerNo} and Company Code: ${companyCode}`);
                dispatch(setLoading(false));
            })

    }

    const [getPartnerAddressDetails, {}] = useLazyQuery<getPartnerAddressByPartnerId, getPartnerAddressByPartnerIdVariables>(
        GET_PARTNER_ADDRESS_DETAILS,
        {
            fetchPolicy: 'network-only',
            onError: (error) => console.log(error),
        }
    );

    const getCompanyCodeDropdownList = () => {
        let list = [];
        if(ecapEntitlementPartner) {
            ecapEntitlementPartner.map(partners => {
                partners && partners.companies.map(company => {
                    list.push({ companyCode: company.companyCode, companyName: company.companyName })
                });
            });

            list = Array.from(list.reduce((acc, item) => (item && item["companyCode"] && acc.set(item["companyCode"], item), acc),
                new Map()).values());

            setCompanyCodeDropDownList(list);
        }
    }

    async function getCompanyData(companyCode: string, partnerNo: string, systemCode: string) {
        return new Promise<getCompanyInfoByCompanyPartnerNoCategory>((resolve, reject) => {
            Promise.resolve(allCompanyInfoPerCategoryAndSystem(companyCode, partnerNo,systemCode,'V'))
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                console.error(err);
                dispatch(setLoading(false));
                reject(err)
            }).finally (() => {
                dispatch(setLoading(false));
            });
        });
      }
    
      const getAlternativeTaxId = (companyCode: string,partnerNo: string, systemCode : string) => {
        dispatch(setLoading(true));
        getCompanyData(companyCode,partnerNo, systemCode).then( (response) => {
            if (response.getCompanyInfoByCompanyPartnerNoCategory.taxId) {
                setBmwAlternativeCompanyTaxId(response.getCompanyInfoByCompanyPartnerNoCategory.taxId);
                props.form.setFieldsValue({ companyTaxCode: response.getCompanyInfoByCompanyPartnerNoCategory.taxId });
            }
        }).catch(() => {dispatch(setLoading(false));})
      }

    const refreshCompanyInfo = (companyCode: string, partnerNo: string,systemCode: string,clearFields: boolean) => {
        //
        //isLoading(true);
        dispatch(setLoading(true));
        dispatch(updateCompanyVatCodeId(undefined));
        dispatch(setCompanyVatCode(undefined));
        //
        const { supplierDetails } = form.getFieldsValue()
        dispatch(setPartnerVatCode(undefined));
        supplierDetails.partnerVatCode = undefined;
        form.setFieldsValue({ supplierDetails });
        form.setFieldsValue({ companyVatCode: undefined });
        dispatch(updatePartnerVatCodeId(undefined));
        getCompanyData(companyCode,partnerNo, systemCode).then( (response) => {
            if (document.partnerNo !== undefined) {
                props.fillPartnerVatDetails(partnerNo, companyCode);
            }
            //
            const compItem = response.getCompanyInfoByCompanyPartnerNoCategory;
            if (compItem) {
                //get agreement history for partner company, default system_code to MDB
                getAgreementHistory({ variables: { partnerNo,  companyCode, systemCode: 'MDB'} }).then(
                    (response) => {
                        dispatch(updateAgreementHistory(response.data?.getAgreementHistoryV2));
                    }
                );

                dispatch(updateCompanyPartnerId(compItem?.companyPartnerId));
                dispatch(updateSupplier(compItem?.partnerId));
                dispatch(updatePartnerNo(compItem?.partnerNo));
                dispatch(setPartnerName(compItem?.partnerName));
                const companyId = compItem?.companyId;

                getCompanyVatList(companyId);
                getCompanyAddress(companyId);
                if (clearFields)
                {
                    //clear the conditional fields
                    //
                    dispatch(updateBMWBusinessAreaId(undefined));
                    dispatch(updateBMWBusinessUnitId(undefined));
                    dispatch(updateBMWRetailOutlet(false));
                    props.form.setFieldsValue({
                        bmwRetailOutlet: getI18n('ecap-capture-bmw-retail-outlet-choice-no', 'No', intl),
                    });
                    props.form.setFieldsValue({ bmwBusinessArea: undefined });
                    props.form.setFieldsValue({ bmwBusinessUnit: undefined });
                //
                }

                const taxId = compItem.taxId;
                props.form.setFieldsValue({ companyTaxCode: taxId });
                //TAX/VAT refactor
                dispatch(setBmwCompanyTaxId(compItem.taxId));
                //Save the alternative Tax id for cases where user change the BMW Company VAT code so that we can restore the TAX Id if there was a previous Default TAX Id
                setBmwAlternativeCompanyTaxId(compItem.taxId)
                dispatch(setCompanyName(compItem.companyName));
                //If no relationship to table t_md_Company_Info info exists then the partner relationship will not be set
                dispatch(
                    updateCompanyPartnerId(response.getCompanyInfoByCompanyPartnerNoCategory.companyPartnerId)
                );
                dispatch(updateSupplier(response.getCompanyInfoByCompanyPartnerNoCategory.partnerId));
                //console.log(`Supplier address : ${response.getCompanyInfoByCompanyPartnerNo.partnerId}`);
                dispatch(setLoading(true));
                getPartnerAddressDetails({
                    variables: {
                        partnerId: response.getCompanyInfoByCompanyPartnerNoCategory.partnerId,
                        pageSize: 1,
                        pageNumber: 1,
                    },
                }).then((response) => {
                    const address = response.data?.getPartnerAddressByPartnerId[0];
                    //console.log("Supplier address : ");
                    //console.dir(address);
                    const fields = props.form.getFieldsValue();
                    fields.supplierStreet1 = address?.street1;
                    fields.supplierCity = address?.city;
                    fields.supplierCountry = address?.country;
                    fields.supplierPostalCode = address?.postalCode;
                    fields.supplierRegionCode = address?.regionCode;
                    
                    //store countryCode in state to use for region rule
                    dispatch(updateSupplierCountryCode(address?.countryCode));
                    dispatch(setPartnerAddressCity(address?.city));
                    dispatch(setPartnerAddressCountryCode(address?.country));
                    dispatch(setPartnerAddressPostalCode(address?.postalCode));
                    dispatch(setPartnerAddressRegionCode(address?.regionCode));
                    dispatch(setPartnerAddressStreet1(address?.street1));
                    dispatch(setPartnerAddressStreet2(address?.street2));

                    props.form.setFieldsValue({ ...fields });
                    dispatch(setLoading(false));
                });
            } else {
                props.form.setFieldsValue({ companyTaxCode: undefined });
            }
        }
        )
        
    };

    const onSelectCompanyCode = (e) => {
        // eslint-disable-next-line no-console
        console.log(`Company code selected : ${e}`);
        const selectedEcapEntitlementPartner =  ecapEntitlementPartner.filter(item => item.companies.some(c => c.companyCode === e));

        //Update document record state
        if (selectedEcapEntitlementPartner[0]?.partnerNo) {
            dispatch(updateCompanyCode(e));
            dispatch(updateSelectedPartner(selectedEcapEntitlementPartner[0]));
            setBMWCompanyCode(e);
            //get company partner record
            refreshCompanyInfo(e, selectedEcapEntitlementPartner[0].partnerNo,selectedEcapEntitlementPartner[0].systemCode,true);
        }
        setBMWCompanyCountryCode(undefined);
    };

    const OnSelectBusinessArea = (value: number) => {
        // const businessAreaIds = value.split("_");
        // const businessAreaId = Number.parseInt(businessAreaIds[0]);
        dispatch(updateBMWBusinessAreaId(value));
        //clear the Business Unit field as new Area was selected
        form.setFieldsValue({ bmwBusinessUnit: undefined });
        dispatch(updateBMWBusinessUnitId(undefined));
        //fill Business Unit list using selected Business Area
        const businessUnitList = businessUnitListData?.getBusinessUnitList.filter((item) => {
            return item.businessArea.id === value;
        });
        businessUnitList.sort((x, y) => {
            return x.businessUnitName === y.businessUnitName
                ? 0
                : x.businessUnitName > y.businessUnitName
                ? 1
                : -1;
        });
        setBusinessUnitList(businessUnitList);
    };

    const OnSelectBusinessUnit = (value: number) => {
        // const businessUnitIds = value.split("_");
        // const businessUnitId = Number.parseInt(businessUnitIds[0]);
        console.log(`Selected Business Unit id : ${value}`);
        dispatch(updateBMWBusinessUnitId(value));
    };

    const onBMWOutlet = (e) => {
        if (e === '1') {
            //console.log("setting bmw outlet to true");
            dispatch(updateBMWRetailOutlet(true));
        } else {
            //console.log("Setting bmw outlet to false");
            dispatch(updateBMWRetailOutlet(false));
            dispatch(updateBMWBusinessAreaId(undefined));
            dispatch(updateBMWBusinessUnitId(undefined));
            form.setFieldsValue({ bmwBusinessUnit: undefined });
            form.setFieldsValue({ bmwBusinessArea: undefined });
        }
    };

    const OnSelectCompanyVatCode = (key, value) => {
        const vatCode: string = value.key;
        const vatCodeSplit = vatCode.split('-');
        const vatCodeSelected = vatList?.allVatCodesByCompanyId.filter((vatCode) => {
            return vatCode.id === Number.parseInt(vatCodeSplit[0])
        })
        dispatch(updateCompanyVatCodeId(Number(vatCodeSplit[0])));
        dispatch(setCompanyVatCode(vatCodeSelected.at(0).vatCode));
        dispatch(setCompanyVatCountryCode(vatCodeSelected.at(0).countryCode.id));
        props.form.setFieldsValue({ companyVatCode: value.value });
        setBMWCompanyCountryCode(vatCodeSelected.at(0).countryCode.id);
    };

    useEffect(() => {
        if (companyDefaultTaxIdData && companyDefaultTaxIdData.getCompanyDefaultTaxId.taxId ) {
            dispatch(setBmwCompanyTaxId(companyDefaultTaxIdData.getCompanyDefaultTaxId.taxId));
            props.form.setFieldsValue({ companyTaxCode: companyDefaultTaxIdData.getCompanyDefaultTaxId.taxId });
        }
        else if (bmwAlternativeCompanyTaxId){
            //Restore the TAX Id from the Company info call if its not undefined
            dispatch(setBmwCompanyTaxId(bmwAlternativeCompanyTaxId));
            props.form.setFieldsValue({ companyTaxCode: bmwAlternativeCompanyTaxId });
        }
        else if (document?.companyCode && document?.partnerNo && document?.systemCode){
            getAlternativeTaxId(document?.companyCode,document?.partnerNo, document?.systemCode);
        }
    }, [companyDefaultTaxIdData]);

    useEffect(() => {
        getDropDownLists();
        getCompanyCodeDropdownList();
        if (partnerRecord?.companies?.length === 1) {
            props.form.setFieldsValue({
                companyCodeDisplayFld: partnerRecord.companies
                    .at(0)
                    .companyCode.concat(' - '.concat(partnerRecord.companies.at(0).companyName)),
            });
            //VAT/TAX refactor to stop chaining of events on load
            getCompanyVatList(partnerRecord.companies.at(0).companyId);
            getPartnerVatList(document.partnerNo,partnerRecord.companies.at(0).companyCode);
            setBMWCompanyCode(partnerRecord.companies.at(0).companyCode);
            if (!document.documentId) {
                onSelectCompanyCode(partnerRecord.companies.at(0).companyCode);
            }
        } else if (!componentLoaded) {
            //Ensure that we do not call company code select when no company code exists (this was done so that the tooltip shows when no selection was made yet)
            if (document.companyCode !== undefined && partnerRecord?.companies) {
                //VAT/TAX refactor to stop chaining of events on load
                //find previously selected company code in list partnerRecord.companies
                const companyCodes = partnerRecord.companies?.filter((company) =>  {return company.companyCode === document.companyCode });
                setBMWCompanyCode(document.companyCode);
                if (companyCodes !== undefined)
                {
                    //There should only be one
                    getCompanyVatList(companyCodes.at(0).companyId);
                }
                
                getPartnerVatList(document.partnerNo,document.companyCode);
                if (document.partnerNo && document.companyCode) {
                    props.fillPartnerVatDetails(document.partnerNo,document.companyCode);
                }
                //OnSelectCompanyCode(document.companyCode);
            } else {
                props.form.setFieldsValue({ companyCodeDisplayFld: undefined });
            }
        }

        if (document.bmwIsRetailOutlet === true) {
            form.setFieldsValue({
                bmwRetailOutlet: getI18n('ecap-capture-bmw-retail-outlet-choice-yes', 'Yes', intl),
            });
        } else {
            form.setFieldsValue({
                bmwRetailOutlet: getI18n('ecap-capture-bmw-retail-outlet-choice-no', 'No', intl),
            });
        }
        initFields(form, partnerRecord, props.documentRecord,);
    }, [partnerRecord,ecapEntitlementPartner]);

    return (
        <>
            <Row gutter={24}>
                <Col span={10}>
                    <Form.Item
                        label={
                            getI18n("ecap-dropdown-bmw-company-code-label", 'Company', intl)
                        }
                        name={'companyCodeDisplayFld'}
                        required={true}
                    >
                        {companyCodeDropDownList && companyCodeDropDownList.length > 1 ? (
                            <Select
                                onSelect={onSelectCompanyCode}
                                showSearch={true}
                                loading={document.loading > 0}
                                placeholder={getI18n(
                                    'ecap-dropdown-companyCodes-placeholder',
                                    'Enter Company Code',
                                    intl
                                )}
                                size={'middle'}
                                allowClear={true}
                            >
                                {
                                    companyCodeDropDownList &&
                                    companyCodeDropDownList.map((item) => (
                                        <Option
                                            key={`${item.companyCode}_${item.companyName}`}
                                            value={item.companyCode}
                                            data-testid={item.companyCode}
                                        >
                                            {item.companyCode} - {item.companyName}
                                        </Option>
                                    ))}
                            </Select>
                        ) : (
                            <Input readOnly={true} type={'text'} />
                        )}
                    </Form.Item>
                </Col>

                <Col span={7}>
                    <Form.Item
                        label={
                            getI18n("ecap-dropdown-bmw-company-vat-label", 
                                'BMW Company VAT Code', intl)
                        }
                        name={'companyVatCode'}
                        key={'companyVatCode'}
                        rules={[{required:vatList && vatList.allVatCodesByCompanyId && vatList.allVatCodesByCompanyId.length >= 1}]}
                    >
                        {vatList && vatList.allVatCodesByCompanyId && vatList.allVatCodesByCompanyId.length > 1 ? (
                            <Select
                                onSelect={OnSelectCompanyVatCode}
                                loading={document.loading > 0}
                                showSearch={true}
                                placeholder={getI18n(
                                    'ecap-dropdown-bmw-company-vatCode-placeholder',
                                    'BMW Company VAT Code',
                                    intl
                                )}
                                size={'middle'}
                                allowClear={true}
                            >
                                {vatList &&
                                    vatList.allVatCodesByCompanyId &&
                                    vatList.allVatCodesByCompanyId.map((item) => (
                                        <Option
                                            key={`${item.id}-${item.vatCode}`}
                                            value={item.vatCode}
                                            data-testid={item.vatCode}
                                        >
                                            {item.vatCode.concat(' , (').concat(item.countryCode.country).concat(')')}
                                        </Option>
                                    ))}
                            </Select>
                        ) : (
                            <Input readOnly={true} type={'text'} />
                        )}
                    </Form.Item>
                </Col>
                <Col span={7}>
                    <Form.Item
                        label={
                            getI18n("ecap-dropdown-bmw-company-tax-label", 'BMW Company Tax ID', intl)}
                        name={'companyTaxCode'}
                        key={'taxCode'}
                    >
                        <Input readOnly={true} type={'text'} />
                    </Form.Item>
                </Col>
            </Row>

            {document.companyCode === 'A1' && (
                <Row gutter={24} className={'mt20'}>
                    <Col span={10}>
                        <Form_Item
                            label={
                                getI18n(
                                    "supplierdetails-supplier-bmw-retail-outlet",
                                    "BMW Retail Outlet/ Service Centre", intl
                                )
                            }
                            name="bmwRetailOutlet"
                        >
                            <Select onSelect={onBMWOutlet}>
                                <Option key={'ecap-capture-bmw-retail-outlet1'} value="1" data-testid={1}>
                                    {getI18n('ecap-capture-bmw-retail-outlet-choice-yes', 'Yes', intl)}
                                </Option>
                                <Option key={'ecap-capture-bmw-retail-outlet2'} value="0" data-testid={0}>
                                    {getI18n('ecap-capture-bmw-retail-outlet-choice-no', 'No', intl)}
                                </Option>
                            </Select>
                        </Form_Item>
                    </Col>
                    <Col span={7}>
                        {document.bmwIsRetailOutlet && (
                            <Form_Item
                                label={getI18n('ecap-dropdown-businessArea-label', 'BMW Business Area', intl)}
                                name="bmwBusinessArea"
                                itemRules={getControlRuleset('BMW_Business_Area_Mandatory', form, rules, document)}
                            >
                                <Select
                                    onSelect={OnSelectBusinessArea}
                                    className=""
                                    loading={document.loading > 0}
                                    showSearch={true}
                                    placeholder={getI18n(
                                        'ecap-dropdown-businessArea-label',
                                        'BMW Business Areas',
                                        intl
                                    )}
                                >
                                    {businessAreaList &&
                                        businessAreaList.map((item) => (
                                            <Option
                                                key={`${item.id}_${item.businessAreaCode}`}
                                                value={item.id}
                                                data-testid={item.businessAreaName}
                                            >
                                                {item.businessAreaName}
                                            </Option>
                                        ))}
                                </Select>
                            </Form_Item>
                        )}
                    </Col>
                    <Col span={7}>
                        {document.bmwIsRetailOutlet === true && (
                            <Form_Item
                                label={getI18n('ecap-dropdown-businessUnit-label', 'BMW Business Unit', intl)}
                                name="bmwBusinessUnit"
                                itemRules={getControlRuleset('BMW_Business_Unit_Mandatory', form, rules, document)}
                            >
                                <Select
                                    onSelect={OnSelectBusinessUnit}
                                    loading={document.loading > 0}
                                    className=""
                                    showSearch={true}
                                    placeholder={getI18n('ecap-dropdown-businessUnit-label', 'BMW Business Unit', intl)}
                                >
                                    {businessUnitList &&
                                        businessUnitList.map((item) => (
                                            <Option
                                                key={`${item.id}_${item.businessUnitCode}`}
                                                value={item.id}
                                                data-testid={item.businessUnitCode}
                                            >
                                                {item.businessUnitName}
                                            </Option>
                                        ))}
                                </Select>
                            </Form_Item>
                        )}
                    </Col>
                </Row>
            )}
        </>
    );
};

export default BMWCompanyHeader;
