import React, {FunctionComponent, useEffect, useState} from "react";
import {Card, Checkbox, Col, Collapse, DatePicker, Form, FormInstance, Input, message, Row, Select, Tooltip} from "antd";
import {useIntl} from "react-intl";

import {createdNestedValue, getI18n, readNestedFormValue} from "../../../utils/Utils";
import styles from "../styling/ecap.module.css";
import {getCurrencyListData} from "../services/SelectServices";
import {IAppComponentProps} from "../../../components";
import {CaretRightOutlined, InfoCircleOutlined} from "@ant-design/icons";
import HeaderNotes from "./HeaderNotes";
import {
    setDeliveryDateInError,
    setIsOrderNumberNotRequired,
    updateCollapsedState,
    updateOrderNumber
} from "../IStoredDocumentRecordSlice";
import {useAppDispatch, useAppSelector} from '../../../main/hooks';
import {RuleResultCache} from "../util/RuleResultCache";
import {Form_Item} from "../componets/FormItem-component";
import {getDateFormatOrDefault} from "../../users/UserFormatPreferenceServices";
import dayjs from "dayjs";
import {MODE} from "../IStoredDocumentRecord";
import { getDuplicateDocumentNumberFlag } from "./HeaderServices";
import DuplicateDocumentNoWarning from './DuplicateDocumentNoWarningModal';


type HeaderProperties = {
    onCurrencyChange?: any,
    onOrderNumberChange?: any,
    onDeliveryNumberChange?: any,
    form: FormInstance<any>,
    templateMode: boolean
} & IAppComponentProps;

function getCurrencies(): Promise<string[]> {
    return getCurrencyListData().then(result => result.getCurrencyList.map(item => item.id));
}

function WrapNotes(props: { headerProps: HeaderProperties }) {
    const {Panel} = Collapse;
    const dispatch = useAppDispatch();
    const {document} = useAppSelector((state) => state.document);

    return (<Card className={styles.headerNotesWrapper}>
        <Row gutter={24} className="mt10">
            <Col>
                <Collapse
                    activeKey={document.collapsedState}
                    onChange={(newActiveKeys) => {
                        // otherwise panels couldn't be open/closed by click
                        dispatch(updateCollapsedState(newActiveKeys));
                    }}
                    bordered={false}
                    expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}
                    expandIconPosition={"left"}>
                    <Panel forceRender={true} header={props.headerProps.intl.formatMessage({
                        'id': 'e-cap-capture-document-header-notes-panel-title',
                        'defaultMessage': 'Header Notes'
                    })} key={'headerNoteKey'}>
                        <HeaderNotes form={props.headerProps.form} intl={props.headerProps.intl}/>
                    </Panel>
                </Collapse>
            </Col>
        </Row>
    </Card>);
}

type HeaderState = { orderNotApplicable: boolean };

const HeaderDetails: FunctionComponent<HeaderProperties> = (props) => {
    const {Option} = Select;
    const dispatch = useAppDispatch();
    const {document} = useAppSelector((state) => state.document);
    const rules = useAppSelector((state) => state.rules);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const {partnerRecord} = useAppSelector((state) => state.partnerRecord);

    const [flag, setFlag] = useState<boolean>(false);
    const rerender = () => setFlag(prev => !prev);

    const intl = useIntl();
    const [currencies, setCurrencies] = useState<string[]>([]);
    const [headerState, setHeaderState] = useState<HeaderState>({orderNotApplicable: false});
    const [isChecked, setIsChecked] = useState(false);

    const currencyChange = (e) => {
        const updateVal = createdNestedValue('document.currencyCodeId', e);
        props.form.setFieldsValue(updateVal);
        if (props.onCurrencyChange) {
            props.onCurrencyChange(e);
        }
    };
    const closeModal = () => {
        setIsModalVisible(false);
    };
    const clearDocumentNumberAndCloseModal= () => {
        const formDocument = props.form.getFieldValue('document');
        formDocument.documentNumber = "";
        setIsModalVisible(false);
    };

    const storeOderNumber = (e) => {
        dispatch(updateOrderNumber(e.target.value));
    };

    const checkForDuplicatesInCurrentYear = async (e) => {
        const documentNumberInput = e.target.value;
        const partnerNumberInput = partnerRecord.partnerNo;
        if (!partnerNumberInput) {
            const formDocument = props.form.getFieldValue('document');
            formDocument.documentNumber = "";
            message.error(getI18n("e-cap-capture-document-duplicate-document-number-check-enter-supplier-no" ,"Please enter a Supplier Number", intl));
            return;
        }
        if (documentNumberInput !== undefined || documentNumberInput !== "") {
            await getDuplicateDocumentNumberFlag(documentNumberInput, partnerNumberInput)
                .then((response) => {
                    console.log(getI18n("e-cap-capture-document-duplicate-document-number-check-success",
                        "Checking for duplicate Document Numbers in Current Year", intl
                    ));
                    const duplicateDocumentNumberInCurrentYearWarning = ((response as any).getDuplicateDocumentNumberFlag.duplicateDocumentNumberInCurrentYearWarning);

                    if(duplicateDocumentNumberInCurrentYearWarning){
                        setIsModalVisible(true);

                    }
                })
                .catch((err) => {
                    console.error(err);
                    message.error(getI18n("e-cap-capture-document-duplicate-document-number-check-failed",
                        "Checking for duplicate Document Numbers in Current Year Failed", intl
                    ));
                });
        }
    };

    const enableDisableOrderNumber = (e) => {
        if (e.target.checked) {
            const nestedValue = createdNestedValue('document.orderNumber', null);
            nestedValue['document']['paymentTerms'] = '';
            nestedValue['orderNumberNA'] = 'ORDER_NUM_DISABLED';
            props.form.setFieldsValue(nestedValue);
        } else {
            const nestedValue = createdNestedValue('document.paymentTerms', intl.formatMessage({id: 'ecap-header-notes-payment-terms-default-text'}));
            nestedValue['orderNumberNA'] = 'ORDER_NUM_ENABLED';
            props.form.setFieldsValue(nestedValue);
        }

        setHeaderState({orderNotApplicable: e.target.checked});
        if (props.onCurrencyChange) {
            props.onCurrencyChange(e);
        }
        const newCheckedValue = !isChecked;
        setIsChecked(newCheckedValue);
        dispatch(setIsOrderNumberNotRequired(newCheckedValue));
    };

    useEffect(() => {
        if (!loaded) {
            if (document.documentMode === undefined && props.templateMode) {
                //If new template is created then default the document number to (template)
                //Jira ticket : EINVV2-11945
                const formDocument = props.form.getFieldValue('document');
                console.log(`documentNumber: ${formDocument?.documentNumber}`);
                console.log(`documentDate: ${formDocument?.documentDate}`);
                if (formDocument?.documentNumber === undefined) {
                    //don't create element if component don't have focus
                    try {
                        formDocument.documentNumber = "";
                    } catch (e) {

                    }

                }
    
                if (formDocument?.documentDate === undefined)
                {
                    //don't create element if component don't have focus
                    try {
                        formDocument.documentDate = dayjs();
                    } catch (e) {

                    }

                }


            }

            if (document.isOrderNumberNotRequired) {
                setIsChecked(document.isOrderNumberNotRequired);
                const initialize = {}
                initialize['orderNumberNA'] = 'ORDER_NUM_DISABLED';
                props.form.setFieldsValue(initialize);
            }
            else {
                const initialize = {}
                initialize['orderNumberNA'] = 'ORDER_NUM_ENABLED';
                props.form.setFieldsValue(initialize);
            }

            getCurrencies().then(result => {
                setCurrencies(result);
                setLoaded(true);
            });

        }

    }, [document.documentId]);

    function deliveryDateValidation(propertyCache: RuleResultCache) {
        const documentDate: dayjs.Dayjs = readNestedFormValue(props.form, 'document.documentDate');
        const deliveryDate: dayjs.Dayjs = readNestedFormValue(props.form, 'document.deliveryDate');

        if (documentDate && deliveryDate && propertyCache.isMandatory('Service_Date')) {
            if (deliveryDate.startOf('d').isAfter(documentDate.endOf('d'))) {
                //I added a check to see if the value requires changing else it creates an infinite loop update
                if (!document.deliveryDateInError) {
                    dispatch(setDeliveryDateInError(true));
                }

                return props.intl.formatMessage({
                    id: 'ecap-service-date-validation',
                    defaultMessage: "Delivery / Service date cannot be after invoice date"
                });
            }
            if (document.deliveryDateInError) {
                dispatch(setDeliveryDateInError(false));
            }
        } else if (!deliveryDate && propertyCache.isMandatory('Service_Date')) {
            return props.intl.formatMessage({
                id: 'ecap-service-date-required',
                defaultMessage: "Delivery / Service date is required"
            });
        }

        return null;
    }

    function deliveryDateChange() {
        props?.onDeliveryNumberChange();
        rerender();
    }

    return new RuleResultCache(rules, props.form, document, ['Order_Number', 'Service_Date', 'Delivery_Service_Number', 'Always_Mandatory', 'Order_Number_Format', 'Order_Number_NA', 'Delivery_Service_Number_Format', 'Document_Number_Format', 'Template_Non_Mandatory_Else_Mandatory'], props.intl, undefined, props.templateMode).render(propertyCache => {
        return (<>
            <div id="header_main_div">
                <div>
                    <div style={{display: "inline-block", width: "140px", margin: "0px 5px"}}>
                        <span hidden={!propertyCache.isMandatory('Always_Mandatory')}
                              className={styles.mandatoryField}>*</span>{props.intl.formatMessage({
                        'id': "header-details-currency-title",
                        'defaultMessage': "Currency"
                    })}
                    </div>
                    <div style={{display: "inline-block", width: "140px", margin: "0px 5px"}}
                         hidden={propertyCache.isHidden('Template_Non_Mandatory_Else_Mandatory')}>
                        <span hidden={!propertyCache.isMandatory('Template_Non_Mandatory_Else_Mandatory')}
                              className={styles.mandatoryField}>*</span>{props.intl.formatMessage({
                        'id': "header-details-doc-no-title",
                        'defaultMessage': "Document Number"
                    })}
                    </div>
                    <div style={{display: "inline-block", width: "140px", margin: "0px 5px"}}
                         hidden={propertyCache.isHidden('Template_Non_Mandatory_Else_Mandatory')}>
                        <span hidden={!propertyCache.isMandatory('Template_Non_Mandatory_Else_Mandatory')}
                              className={styles.mandatoryField}>*</span> {props.intl.formatMessage({
                        'id': "header-details-doc-date-title",
                        'defaultMessage': "Document Date"
                    })}
                    </div>

                    <div style={{display: "inline-block", width: "140px", margin: "0px 5px"}}
                         hidden={propertyCache.isHidden('Order_Number')}>
                        <span hidden={!propertyCache.isMandatory('Order_Number')}
                              className={styles.mandatoryField}>*</span>{props.intl.formatMessage({
                        'id': "header-details-ord-no-title",
                        'defaultMessage': "Order Number"
                    })}
                        <Tooltip title={propertyCache.formatInfoMessage('Order_Number_Format')}>
                            <InfoCircleOutlined hidden={propertyCache.isReadOnly('Order_Number')}
                                                style={{marginLeft: "5px"}}/>
                        </Tooltip>
                    </div>
                    <div style={{display: "inline-block", width: "210px", margin: "0px 5px"}}
                         hidden={propertyCache.isHidden('Delivery_Service_Number')}>
                        <div style={{overflowWrap: "anywhere"}}>
                            <span hidden={!propertyCache.isMandatory('Delivery_Service_Number')}
                                  className={styles.mandatoryField}>*</span>
                            {props.intl.formatMessage({
                                'id': "header-details-del-no-title",
                                'defaultMessage': "Delivery/Service Number"
                            })}
                            <Tooltip title={propertyCache.formatInfoMessage('Delivery_Service_Number')}>
                                <InfoCircleOutlined hidden={propertyCache.isReadOnly('Delivery_Service_Number')}
                                                    style={{marginLeft: "5px"}}/>
                            </Tooltip>
                        </div>
                    </div>
                    <div style={{display: "inline-block", width: "140px", margin: "0px 5px"}}
                         hidden={propertyCache.isHidden('Service_Date')}>
                        <span hidden={!propertyCache.isMandatory('Service_Date')}
                              className={styles.mandatoryField}>*</span>
                        {props.intl.formatMessage({
                            'id': "header-details-del-date-title",
                            'defaultMessage': "Delivery/Service Date"
                        })}
                        <Tooltip title={props.intl.formatMessage({
                            'id': "service-date-info-message",
                            'defaultMessage': "The service date is the date when the service was rendered to BMW. The delivery date is when the goods were delivered to BMW"
                        })}>
                            <InfoCircleOutlined style={{marginLeft: "5px"}}
                                                hidden={propertyCache.isReadOnly('Service_Date')}/>
                        </Tooltip>
                    </div>
                </div>

                <div>
                    <div style={{display: "inline-block", width: "140px", margin: "0px 5px"}}>
                        <Form.Item name={["document", "currencyCodeId"]}
                                   className={styles.roundedBorder} hidden={true}>
                            <Input/>
                        </Form.Item>
                        <Form_Item
                            name={"headerCurrency"}
                            label={''}
                            itemRules={propertyCache.fetchRule('Always_Mandatory')}>
                            <Select
                                loading={!(currencies && currencies.length > 0)}
                                showSearch={true}
                                disabled={propertyCache.isReadOnly('Always_Mandatory') || document?.documentMode === MODE.VIEW}
                                placeholder={intl.formatMessage({
                                    id: "e-cap-capture-currency",
                                    defaultMessage: 'Select'
                                })} onSelect={currencyChange}>
                                {
                                    currencies.map((currency, index) => (
                                        <Option className={styles.currencySelect}
                                                key={`${index}_${currency}`} value={currency}
                                                data-testid={currency}>
                                            {currency}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form_Item>
                    </div>

                    <div style={{display: "inline-block", width: "140px", margin: "0px 5px"}}>
                        <Form_Item name={["document", "documentNumber"]} label={''}
                                   formatMessage={propertyCache.formatMessage('Document_Number_Format')}
                                   itemRules={propertyCache.fetchRule('Template_Non_Mandatory_Else_Mandatory', 'Document_Number_Format')}>
                            <Input placeholder="" disabled={propertyCache.isReadOnly('Template_Non_Mandatory_Else_Mandatory')} onBlur={checkForDuplicatesInCurrentYear}/>
                        </Form_Item>
                    </div>

                    <div style={{display: "inline-block", width: "140px", margin: "0px 5px"}}>
                        <Form_Item name={["document", "documentDate"]} label={''}
                                   itemRules={propertyCache.fetchRule('Template_Non_Mandatory_Else_Mandatory')}>
                            <DatePicker
                                format={getDateFormatOrDefault(props.currentUser)}
                                disabledDate={(current) => current.isAfter(dayjs())}
                                onChange={() => rerender()}
                                placeholder=""
                                style={{width: "100%"}}
                                inputReadOnly={true}
                            />
                        </Form_Item>
                    </div>
                    <div style={{display: "inline-block", width: "140px", margin: "0px 5px"}}>
                        <Form_Item name={["document", "orderNumber"]} label={''}
                                   formatMessage={propertyCache.formatMessage('Order_Number_Format')}
                                   itemRules={propertyCache.fetchRule('Order_Number', 'Order_Number_Format')}>
                            <Input placeholder="" onKeyUp={(e) => props?.onOrderNumberChange()}
                                   disabled={propertyCache.isReadOnly('Order_Number')} onChange={storeOderNumber}/>
                        </Form_Item>
                    </div>
                    <div style={{display: "inline-block", width: "210px", margin: "0px 5px"}}>
                        <Form_Item name={["document", "deliveryNumber"]}
                                   label={''}
                                   formatMessage={propertyCache.formatMessage('Delivery_Service_Number_Format')}
                                   itemRules={propertyCache.fetchRule('Delivery_Service_Number', 'Delivery_Service_Number_Format')}>
                            <Input placeholder=""
                                   disabled={propertyCache.isReadOnly('Delivery_Service_Number')}
                                   onKeyUp={(e)=>props?.onDeliveryNumberChange()}/>
                        </Form_Item>
                    </div>
                    <div style={{display:"inline-block", width:"140px",margin:"0px 5px"}}>
                        <Form.Item name={["document","deliveryDate"]}
                                   label={''}
                            //validateStatus={deliveryDateValidation(propertyCache)?'error':'success'}
                                   rules={[{
                                       required: propertyCache.isMandatory('Service_Date'),
                                       message: props.intl.formatMessage({
                                           id: 'ecap-service-date-required',
                                           defaultMessage: "Delivery / Service date is required"
                                       })
                                   }]}
                            //help={deliveryDateValidation(propertyCache)}
                        >
                            <DatePicker
                                format={getDateFormatOrDefault(props.currentUser)}
                                placeholder=""
                                disabled={propertyCache.isReadOnly('Service_Date')}
                                disabledDate={(current) => current.isAfter(dayjs())}
                                onChange={() => deliveryDateChange()}
                                style={{width: "100%"}}
                                inputReadOnly={true}
                            />
                        </Form.Item>
                    </div>
                </div>

                <div>
                    <div style={{display: "inline-block", width: "140px", margin: "0px 5px"}}>&nbsp;</div>
                    <div style={{display: "inline-block", width: "140px", margin: "0px 5px"}}>&nbsp;</div>
                    <div style={{display: "inline-block", width: "140px", margin: "0px 5px"}}>&nbsp;</div>
                    <div style={{display: "inline-block", width: "140px", margin: "0px 5px"}}>
                        <div>
                            <Form_Item name={["document","isOrderNumberNotRequired"]} label={''}>
                                <Checkbox checked={isChecked} onChange={enableDisableOrderNumber}
                                          disabled={propertyCache.isReadOnly('Order_Number_NA')}>
                                    {props.intl.formatMessage({
                                        'id': 'ecap-order-number-not-available',
                                        'defaultMessage': 'Order Number not available'
                                    })}
                                </Checkbox>
                            </Form_Item>

                            <Form.Item name={'orderNumberNA'} hidden={true}>
                                <Input/>
                            </Form.Item>
                        </div>
                    </div>
                    <div style={{display: "inline-block", width: "140px", margin: "0px 5px"}}>&nbsp;</div>
                    <div style={{display: "inline-block", width: "140px", margin: "0px 5px"}}>&nbsp;</div>
                </div>

            </div>

            <div hidden={!headerState.orderNotApplicable}>
                {props.intl.formatMessage({
                    'id': 'header-details-order-number-na-disclaimer',
                    'defaultMessage': "A missing order number might lead to rejection of the invoice. Please enter the “Contact Person” and the “Department” for the “Ship To / Service Delivery” party in the Section “Shipping Information"
                })}
            </div>

            <DuplicateDocumentNoWarning
                isModalVisible={isModalVisible}
                handleNoInModal={clearDocumentNumberAndCloseModal}
                handleYesInModal={closeModal}
                handleCancelInModal={closeModal}
            />
            <WrapNotes headerProps={props}/>
        </>);
    })

};


export default HeaderDetails;