import React, { useEffect, useState } from "react";
import { Button, Col, ConfigProvider, Form, Row, Spin, Tabs } from "antd";
import { PageContainer } from "@ant-design/pro-layout";
import { IAppComponentProps } from "../../components";
import styles from "../../styles/scss/portal.scss";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { USER_TABLE_HEADERS } from "./UserUtils";
import { CustomRender } from "../../components/CustomRender";
import { ALL_PORTAL_USERS } from "./queries";
import client from "../../config/GraphQLApolloClient";
import AddUsersComponent from "./AddUserModal";
import { GridApi } from "ag-grid-community";
import BtnUserActionCellRenderer from "./BtnUserActionCellRenderer";
import enUS from "antd/lib/locale/en_US";
import { showErrorNotification } from "../Notification";
import { ADD_PORTAL_USER } from "./mutations";
import LookupUser from "./userLookup/LookupUser";


export const renderAsDate = (record) => <CustomRender record={record} renderAs="date" />;
export const renderAsDateTime = (record) => <CustomRender record={record} renderAs="dateTime" />;
export const renderAsTags = (record) => <CustomRender record={record} renderAs="tags" />;

const Users: React.FunctionComponent<IAppComponentProps> = (props) => {
  const currentUser = props.currentUser;
  const [form] = Form.useForm();
  const [loading, isLoading] = useState(false);
  const [submitLoading, isSubmitLoading] = useState(false);
  const [visible, isVisible] = useState(false);
  const [userData, setUserData] = useState(undefined);
  const [allPortalUsers, setAllPortalUsers] = useState(undefined);
  const [userGridApi, setUserGridApi] = useState<GridApi>(undefined);


  useEffect(() => {
    if (userData === undefined) {
      isLoading(true);
      Promise.all([
        client.query({
          query: ALL_PORTAL_USERS,
          fetchPolicy: "cache-first"
        })
      ])
        .then(([userData]) => {
          setAllPortalUsers(userData.data.getAllPortalUsers);
          setUserData(userData.data.getAllPortalUsers);
          isLoading(false);
        })
        .catch((error) => {
          isLoading(false);
          showErrorNotification(`${error}`);
        });
    }
  }, [userData]);

  const { TabPane } = Tabs;
  const sizes: React.CSSProperties = {
    height: "100%",
    width: "98%"
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  };

  const onFinish = (values: any) => {


  };

  const allUsers = () => {
    setUserData(allPortalUsers);

  };

  const pendingUsers = () => {
    setUserData(allPortalUsers.filter(x => x.roleName === "system_Pending"));
  };

  const onCancel = () => {
    isVisible(false);
  };

  const onOk = (values: any) => {
    delete values["__typename"];
    delete values["fromDate"];
    delete values["user_id"];
    delete values["role_id"];
    isSubmitLoading(true);
    client.mutate({
      mutation: ADD_PORTAL_USER,
      variables: {
        portalUserInput: values
      }

    }).then(() => {
      client.query({
        query: ALL_PORTAL_USERS,
        variables: {
            portalUserInput: {
              email: "",
              firstname: "",
              lastname: "",
              username: "",
              roleName: ""
            }
          },
          fetchPolicy: "network-only"
        }).then(response => {
        setAllPortalUsers(response.data.getAllPortalUsers);
        setUserData(response.data.getAllPortalUsers);
        isLoading(false);
        isVisible(false);
        isSubmitLoading(false);
      }).catch(error => {
          console.error(error);
          showErrorNotification(error);
          isSubmitLoading(false);
        });
      }
    ).catch(error => {
      console.error(error);
      showErrorNotification(error);
      isSubmitLoading(false);
    });

  };

  const addUser = () => {
    isVisible(true);
  };

  const clearForm = () => {
    form.resetFields();
  };
  const onUsersGridReady = (params) => {
    setUserGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  return (
    <ConfigProvider locale={enUS}>
      <PageContainer
        title={false}
        content={""}
        extraContent={""}
        className={styles.users}
        style={{ background: "white" }}
      >
        <div style={{ ...sizes, minHeight: "200px" }} className="pl15 ag-theme-balham">
          <Spin spinning={loading} size={"large"}>
            <Row gutter={24}>
              <Col md={24} sm={12} xs={24}>
                <div className="pl15">
                  <h1>User Management</h1>
                </div>
              </Col>
            </Row>

            <Row gutter={24}>

              <Col md={24} sm={12} xs={24}>
                <Tabs defaultActiveKey={"1"} className="users-tabs">
                  <TabPane tab="My current users" key="1">
                    <Row gutter={24}>
                      <Col md={24} sm={12} xs={24}>
                        <Button type="primary" onClick={allUsers} className={"mr5"}>
                          All Users
                        </Button>
                        <Button type="primary" onClick={pendingUsers}>
                          All Pending Users
                        </Button>
                      </Col>
                    </Row>
                    <div style={{ flex: "auto", height: "500px", paddingTop: "10px" }}>
                      <AgGridReact
                        defaultColDef={{
                          enableValue: true,
                          sortable: true,
                          resizable: true
                        }}
                        columnDefs={USER_TABLE_HEADERS}
                        rowData={userData}

                        sideBar={false}
                        rowClass="agGridRows"
                        suppressMenuHide={false}
                        debug={false}
                        pagination={true}
                        paginationPageSize={15}
                        suppressRowClickSelection={true}
                        onGridReady={onUsersGridReady}
                        frameworkComponents={{
                          btnUserActionCellRenderer: BtnUserActionCellRenderer
                        }}
                      />
                    </div>
                  </TabPane>
                  <TabPane tab="Lookup Partner User" key="2">
                    <Row>
                      <Col span={24}>
                        <LookupUser intl={props.intl} currentUser={props.currentUser} distinctEntitlements={props.distinctEntitlements}/>
                      </Col>
                    </Row>     
                  </TabPane>
                </Tabs>
              </Col>


            </Row>

          </Spin>

          <AddUsersComponent
            onOk={onOk}
            onCancel={onCancel}
            visible={visible}
            selectedUser={userGridApi ? userGridApi.getSelectedRows()[0] : undefined}
            currentUser={currentUser}
            distinctEntitlements={props.distinctEntitlements}
            intl={props.intl}
            submitLoading={submitLoading}
          />
        </div>
      </PageContainer>
    </ConfigProvider>
  );
};

export default Users;
