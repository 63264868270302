import React, { FunctionComponent, useEffect, useState } from "react";  
import { IAppComponentProps } from ".";
import { entitlementExists } from "../main/entitlementService";

export type IAppEntitlementProps = { entitlementToCheck: string, intl: any } & IAppComponentProps;
export type IAppEntitlementBooleanProps = { render: boolean, children: IAppEntitlementProps };


/**
 * Checks if the "entitlementToCheck" exists in "distinctEntitlements"
 * If it does - the child components will be rendered.
 * @param props 
 * @returns child elements to render
 */

/**
 * If render is true, the child components are rendered.
 * @param props render
 * @returns 
 */
 export const RenderIf: FunctionComponent<IAppEntitlementBooleanProps> = (props) => {
    return (
        <>
            {props.render ? props.children : null}
        </>
    )
}

export const RenderIfEntitled: FunctionComponent<IAppEntitlementProps> = (props) => {

    const showChildren = entitlementExists(props.entitlementToCheck, props.distinctEntitlements);

    return (
        <>
            <RenderIf render={showChildren}>
                {props.children}
            </RenderIf>
        </>
    )
}


export default RenderIfEntitled;