import { IAppComponentProps } from "../../components";
import React, { useEffect, useState } from "react";
import { Badge, Button, Col, DatePicker, Form, Row, Select, Space } from "antd";
import { PortalFormItem } from "../../components/FormUtils";
import { FormattedMessage, useIntl } from "react-intl";
import { useLazyQuery, useQuery } from "@apollo/client";
import { selectOption, selectOptionVariables } from "../users/__generated__/selectOption";
import { SELECT_OPTION_QUERY } from "../users/queries";
import { getI18n } from "../../utils/Utils";
import RenderIfEntitled from "../../components/EntitlementComponents";
import { companiesLookupByEntitlement } from "../../main/entitlementService";
import _ from "lodash";
import { PartnerLookup } from "../../components/PartnerLookup";
import { CompanyLookup } from "../../components/CompanyLookup";
import {getRangePickerLocalization} from "../info-portal/components/grid/CalendarUtils";
import {getDateFormatOrDefault} from "../users/UserFormatPreferenceServices";
import {
  getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners
} from "../../main/__generated__/getSelfEntitlementResultByFilter";

type MyDocumentSearchFormProps = {
  count: number;
  onExport: any;
  onDownloadDocuments: any;
  onSearch: any;

} & IAppComponentProps;

const Freight_Entitlement = "display@freight";
const partnerResultsPageNumber: number = 1;
const partnerResultsPageSize: number = 50;



const MyDocumentSearchForm: React.FunctionComponent<MyDocumentSearchFormProps> = (props) => {

  const [form] = Form.useForm();
  const intl = useIntl();
  const [orderAreas, setOrderAreas] = useState<selectOption>(undefined);
  //getCompaniesByEntitlement
  const [partnerNo, setPartnerNo] = useState(undefined);


  const [getOrderArea] = useLazyQuery<selectOption, selectOptionVariables>(SELECT_OPTION_QUERY,
    { fetchPolicy: "network-only", variables: { select: "AREA", username: props.currentUser.username } }
  );


  useEffect(() => {

    if (!orderAreas) {
      getOrderArea().then(response => setOrderAreas(response?.data));
    }

  }, []);

  const onSearch = () => {
    console.dir(form.getFieldsValue());
    props.onSearch(form.getFieldsValue());
  };


  const onClear = () => {
    // eslint-disable-next-line no-console
    form.resetFields();
  };

  const onExport = () => {
    props.onExport();
  };

  const downloadDocuments = () => {
    props.onDownloadDocuments(form.getFieldsValue());
  };
  const { Option } = Select;

  const handlePartnerChange = (value: string) => {
    console.log(`Selected supplier: ${value}`);
    setPartnerNo(value);
  }

  const onlyShowFreightPartners =
      (data: getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners[]) =>
          Array.from(data.filter(item => item?.category === 'Z').values());

  return (
    <>
      <Form size={"middle"} layout={"vertical"} form={form} onFinish={() => onSearch}>
        <Row gutter={24} style={{ alignContent: "flex-end", marginTop: "50" }}>
          <Col span={5}>
            <Form.Item name={"fromDate"} key={"fromDate"} label={getI18n("freight_panel_form_fromDate", 
            "Document From Date", intl) }>
              <DatePicker
                format={getDateFormatOrDefault(props.currentUser)}
                placeholder={intl.formatMessage({
                  id: "freight_panel_form_fromDate",
                  defaultMessage: "Document From Date"
                })}
                allowClear={true}
                data-testid={"fromDate"}
                style={{ width: "100%" }}
                locale={getRangePickerLocalization(intl, localStorage.getItem("locale"))}
              />
            </Form.Item>
          </Col>
          <PortalFormItem
            label={getI18n("freight_panel_form_reference", "Reference", intl)}
            name={"reference"}
            placeHolder={getI18n("freight_panel_form_reference", "Reference", intl)} />

          <Col span={5}>
            <Form.Item name={"orderArea"} key={"orderAreaKey"}
                       label={getI18n("freight_panel_form_orderArea", "Order Area", intl)}>
              <Select
                placeholder={getI18n("freight_panel_form_orderArea", "Order Area" , intl)}
                allowClear={true}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.props.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
                }
                optionFilterProp="children"
                showSearch={true}

              >
                {orderAreas && orderAreas.getSelectDropDown &&
                orderAreas.getSelectDropDown.map((oderAreaItem) => (
                  <Option key={`${oderAreaItem.id}_${oderAreaItem.name}orderArea`} value={oderAreaItem.value}>
                    {oderAreaItem.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <CompanyLookup
              intl={props.intl}
              currentUser={props.currentUser}
              entitlement={Freight_Entitlement}
              distinctEntitlements={props.distinctEntitlements}
          />

          <Col span={5}>
            <Form.Item name={"dateTo"} key={"dateTo"} label={intl.formatMessage({
              id: "freight_panel_form_toDate",
              defaultMessage: "Document To Date"
            })}>
              <DatePicker
                format={getDateFormatOrDefault(props.currentUser)}
                placeholder={getI18n("freight_panel_form_toDate", "Document To Date", intl)}
                allowClear={true}
                data-testid={"dateTo"}
                style={{ width: "100%" }}
                locale={getRangePickerLocalization(intl, localStorage.getItem("locale"))}
              />
            </Form.Item>
          </Col>
          <PartnerLookup 
                  intl={props.intl} 
                  entitlement={Freight_Entitlement} 
                  onChange={handlePartnerChange}
                  currentUser={props.currentUser}
                  distinctEntitlements={props.distinctEntitlements}
                  filter={onlyShowFreightPartners}
                  colSpan={5}
                  />
          <Col span={5}>
            <Form.Item name={"downloaded"} label={intl.formatMessage({
              id: "freight_panel_form_downloaded-status",
              defaultMessage: "Download Status"
            })}>
              <Select
                showSearch={true}
                placeholder={intl.formatMessage({
                  id: "freight_panel_form_downloaded-status",
                  defaultMessage: "Download Status"
                })} allowClear={true}>
                <Select.Option key={"kYesOption"} value="YES">{intl.formatMessage({
                  id: "general-edp-yes",
                  defaultMessage: "Yes"
                })}
                </Select.Option>
                <Select.Option key={"kNoOption"} value="NO">{intl.formatMessage({
                  id: "general-edp-no",
                  defaultMessage: "No"
                })}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col xs={24} sm={6} md={4}>
            <Button type={"primary"} htmlType="submit" size="large" className="w-100 mt10" onClick={onSearch}
                    data-testid={"btn-search"}>
              {getI18n("freight_panel_form_button-search", "SEARCH", intl )}
            </Button>
          </Col>
          <Col xs={24} sm={6} md={4}>
            <Button type="primary" onClick={onClear} size="large" className="w-100 mt10" data-testid={"btn-clear"}>
              {getI18n("freight_panel_form_button-clear", "CLEAR", intl)}
            </Button>
          </Col>
          <Col xs={24} sm={6} md={4}>
            <Button type="primary" onClick={onExport} size="large" className="w-100 mt10" data-testid={"btn-export"}>
              {getI18n("freight_panel_form_export-list", "EXPORT LIST", intl)}
            </Button>
          </Col>
          <RenderIfEntitled entitlementToCheck="download@freight" intl={intl} distinctEntitlements={props.distinctEntitlements}>
            <Col xs={24} sm={6} md={4}>
              <Button type="primary" onClick={downloadDocuments} size="large"
                      className="w-100 mt10" data-testid={"btn-download"}>
                {getI18n("freight_panel_form_download", "DOWNLOAD", intl)}
              </Button>
            </Col>
          </RenderIfEntitled>
          <Col xs={24} sm={6} md={4}>
            <Button type="primary" size="large" className="w-100 mt10" data-testid={"btn-count"}>
              {getI18n("freight_panel_form_count", "COUNT", intl)}
            </Button>
          </Col>
          <Col>
            <Space>
              <Badge
                className="site-badge-count-109"
                count={props.count}
                style={{ backgroundColor: "#52c41a" }}
                title={"Total records"}
                overflowCount={999999}
              />
            </Space>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default MyDocumentSearchForm;