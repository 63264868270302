import { Alert, Button, Col, Form, Input, Modal, Radio, Row, Select } from "antd";
import { PortalCard } from "../../components/PortalCard";
import { UserType } from "./UserUtils";
import React, { useEffect, useState } from "react";
import { IAppComponentProps, PortalUser } from "../../components";

export interface IProps extends IAppComponentProps {
  onOk: any;
  onCancel: any;
  visible: any;
  selectedUser: any;
  submitLoading: boolean;
}

const AddUsersComponent: React.FunctionComponent<IProps> = ({
                                                              onOk,
                                                              onCancel,
                                                              visible,
                                                              selectedUser,
                                                              submitLoading
                                                            }: IProps) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [loading, isLoading] = useState<boolean>(false);
  const [userSearchResults, setUserSearchResults] = useState<PortalUser[]>(undefined);
  const [searchInput, setSearchInput] = useState(undefined);
  const [newUser, setNewUser] = useState(undefined);
  const [userType, setUserType] = useState(undefined);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    if (selectedUser) {
      form.setFieldsValue({
        userType: selectedUser.roleName ? selectedUser.roleName.toUpperCase() : undefined,
        username: selectedUser.username,
        email: selectedUser.email,
        firstname: selectedUser.firstname,
        lastname: selectedUser.lastname
      });
    } else {
      form.resetFields();
    }
  }, [selectedUser]);

  const onClear = () => {
    form.resetFields();
    setNewUser(undefined);
    setUserType(undefined);
  };

  const userTypeChange = (value) => {
    setUserType(value);
  };
  const submit = (value) => {
    if (value === undefined !!) {
      setError("Please select user details and a role");
    } else {
      onOk({ ...newUser, roleName: value });

    }
  };

  return (
    <>
      <Modal
        title={"Edit User Role"}
        onCancel={() => onCancel()}
        open={visible}
        width={"1300px"}
        mask={true}
        destroyOnClose={true}
        footer={[
          <Button key="back" onClick={onCancel}>
            Cancel
          </Button>,
          <Button key="btnClear" type="primary" onClick={onClear}>
            Clear
          </Button>,
          <Button key="submit" type="primary" onClick={() => submit(form.getFieldValue("userType"))}
                  loading={submitLoading} disabled={!userType}>
            Submit
          </Button>
        ]}
      >
        <Form size={"middle"} layout={"vertical"} form={form}>
          {error && <Alert message={error} type="error" showIcon={true} closable={true} />}
          <h5 className="mt20">Portal User profile details</h5>
          <Row gutter={24} style={{ alignContent: "flex-end", marginTop: "10" }}>
            <Col span={12}>
              <label>Name</label>
              <Form.Item name={"firstname"}>
                <Input placeholder={"firstname"} disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <label>Surname</label>
              <Form.Item name={"lastname"}>
                <Input placeholder={"last name"} disabled={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} style={{ alignContent: "flex-end", marginTop: "10" }}>
            <Col span={18}>
              <label>eMail</label>
              <Form.Item name={"email"}>
                <Input placeholder={"user email"} disabled={true} />
              </Form.Item>
            </Col>
          </Row>
          <h5 className="mt20">B2B Portal login Username and Role</h5>
          <Row gutter={24} style={{ alignContent: "flex-end", marginTop: "10" }}>
            <Col span={18}>
              <label>Username </label>
              <Form.Item name={"username"}>
                <Input placeholder={"Username"} width={100} disabled={true} />
              </Form.Item>
            </Col>
            <Form.Item name={"userType"} required={true}>
              <Radio.Group onChange={userTypeChange}>
                <Row gutter={24}>
                  <Col span={8}>
                    <div className="request-blocks-container mb0">
                      <PortalCard
                        description={"BMW Administrator"}
                        value={UserType.SYSTEM_ADMIN}
                        iconName="feature-team.svg"
                      />
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="request-blocks-container mb0">
                      <PortalCard
                        description={"Partner Administrator"}
                        value={UserType.PARTNER_ADMIN}
                        iconName="feature-team.svg"
                      />
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="request-blocks-container mb0">
                      <PortalCard
                        description={"eDocument Portal User"}
                        value={UserType.PARTNER_USER}
                        iconName="feature-team.svg"
                      />
                    </div>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default AddUsersComponent;
