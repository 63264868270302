import React, { useState } from "react";
import { Button } from "antd";
import AddUsersComponent from "./AddUserModal";
import { useIntl } from "react-intl";
// eslint-disable-next-line import/named
import { Navigate } from "react-router-dom"; //changed Redirect to Navigate

const BtnUserActionCellRenderer: React.FunctionComponent = (props) => {

  const [editUserVisible, isEditUserVisible] = useState(false);
  // @ts-ignore
  const [selectedUser, setSelectedUser] = useState(props.data);
  const [assignRedirect, setAssignRedirect] = useState(false);
  const [viewRedirect, setViewRedirect] = useState(false);
  const [loading, isLoading] = useState(false);
  const [submitLoading, isSubmitLoading] = useState(false);
  const intl = useIntl();

  const onEditUser = (e) => {
    // @ts-ignore
    setSelectedUser(props.data);
    isEditUserVisible(true);
  };
  const onAssignUser = (e) => {
    // @ts-ignore
    setSelectedUser(props.data);
    setAssignRedirect(true);

  };
  const onEditCancel = () => {
    // @ts-ignore
    setSelectedUser(props.data);
    isEditUserVisible(false);
  };
  const onOk = (values: any) => {
  };

  const onViewUser = (values: any) => {
    setViewRedirect(true);
  };

  return (

    <>
      {/* {assignRedirect && <Navigate push={true} to={{ pathname: "/assign", state: { selectedUser } }} />}
      {viewRedirect && <Navigate push={true} to={{ pathname: "/profile", state: { selectedUser } }} />} */}
      {assignRedirect && <Navigate to={"/assign"} state= { selectedUser } />}
      {viewRedirect && <Navigate to={"/profile"} state= { selectedUser } />}
      <span>
                <span style={{ marginRight: "10px" }}>
                    <Button type="primary" htmlType="button" size={"small"} loading={loading} onClick={onAssignUser}>
                        Assign{" "}
                    </Button>
                </span>
                <span style={{ marginRight: "10px" }}>
                    <Button type="primary" htmlType="button" size={"small"} onClick={onEditUser}>
                        Edit{" "}
                    </Button>
                </span>
              <span>
                    <Button type="primary" htmlType="button" size={"small"} onClick={onViewUser}>
                        View
                    </Button>
                </span>
            </span>
      <AddUsersComponent
        onOk={() => onOk}
        onCancel={onEditCancel}
        visible={editUserVisible}
        selectedUser={selectedUser}
        currentUser={undefined}
        distinctEntitlements={[]}
        intl={intl}
              submitLoading={submitLoading}
            />

        </>
  )
}

export default BtnUserActionCellRenderer
