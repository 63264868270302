import client from "../../../config/GraphQLApolloClient";
import { UPDATE_SURVEY_SUBMITTED } from "./mutations";
import { GET_SURVEY_VERIFY_REPORT } from "./queries";
import {getSurveyVerifyReport, getSurveyVerifyReport_getSurveyVerifyReport_portions } from "./__generated__/getSurveyVerifyReport";

export const getSurveyVerifyData = (groupId: string) => {
    return new Promise<getSurveyVerifyReport>((resolve, reject) => {
        client.query<getSurveyVerifyReport>({
          query: GET_SURVEY_VERIFY_REPORT,
          variables: {
            groupId
          }
          ,
          fetchPolicy: "network-only"
        })
          .then((serviceResponse) => {
            const response: getSurveyVerifyReport = { getSurveyVerifyReport: serviceResponse.data.getSurveyVerifyReport };
            resolve(
              response
            );
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      });
}

export const updateSurveySubmitData = (groupId) => {
    return new Promise((resolve,reject) => {
        client.mutate({
          mutation: UPDATE_SURVEY_SUBMITTED,
          variables: { groupId }
        }).then((serviceResponse) => {
            const response: getSurveyVerifyReport = { getSurveyVerifyReport: serviceResponse.data.getSurveyVerifyReport };
            resolve(
              response
            );
          })
          .catch((err) => {
          console.error(err);
          reject(err);
        });
      });
  }

export const getVerifyReportPortion = (verifyReport: getSurveyVerifyReport, portionSequence: Number): getSurveyVerifyReport_getSurveyVerifyReport_portions =>{
  const foundPortions:getSurveyVerifyReport_getSurveyVerifyReport_portions[] = verifyReport.getSurveyVerifyReport.portions
  .filter(obj => {return obj.portionSequence === portionSequence});
  if(foundPortions.length > 0){
    return foundPortions[0];
  }
  return null;  
}

