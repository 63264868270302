import React from "react";
import { useIntl } from "react-intl";
import { Col, Form, Select } from "antd";
import { useQuery } from "@apollo/client";
import { IAppComponentProps } from "./index";
import { getSurveyStatusList } from "../pages/survey/__generated__/getSurveyStatusList";
import { SURVEY_STATUS_LIST_QUERY } from "../pages/survey/queries";
import { getI18n } from "../utils/Utils";

export const SurveyStatus: React.FunctionComponent<IAppComponentProps> = (props) => {

  const intl = useIntl();
  const { Option } = Select;

  const { data } = useQuery<getSurveyStatusList>(SURVEY_STATUS_LIST_QUERY, {
    fetchPolicy: "cache-first"
  });


    return (
        <>
        <Col span={5}>
            <Form.Item name="surveyStatus" key="surveyStatus" label={intl.formatMessage({
                id: "agreement-statuscodes-label",
                defaultMessage: "Status Codes"
            })}>
                <Select
                  placeholder={intl.formatMessage({
                      id: "agreement-StatusId-placeholder",
                      defaultMessage: "Select option"
                  })}
                  allowClear={true}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.props.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
                  }
                  optionFilterProp="children"
                  showSearch={true}
                  data-testid={'surveyStatus'}
                >
                    {data && data.getSurveyStatusList && data.getSurveyStatusList.map((item) => (
                    <Option key={`${item.id}`} value={item.id} data-testid={item.id}>
                      {getI18n(item.phraseId, item.surveyStatus, intl)}
                    </Option>
                    ))}
                </Select>
            </Form.Item>
        </Col>
        </>
    );
};
