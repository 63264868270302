import React, { useEffect, useState } from 'react';
import { Form, message, Select, Spin } from 'antd';
import { useGetB2XRolesQuery } from './queries.generated';
import { useAppSelector } from '../../../main/hooks';
import { setB2XRoleUpdated } from './redux/IStoredEntitlementsSlice';
import { useDispatch } from 'react-redux';

const B2XRoleDropDown = (props: any) => {
    const dispatch = useDispatch();
    const [refreshLoading, setRefreshLoading] = useState(false);
    const entitlementState = useAppSelector((state) => state.entitlements);
    const [b2xRoles, setB2XRoles] = useState<any>([]);

    const onB2XRoleSelect = (value) => {
        props.setB2XRoleSelected(value);
    };

     const { data: b2xData, loading: b2xLoading, error: b2xError, refetch: refetchData } = useGetB2XRolesQuery({
       variables: {
       },
     });


     if (b2xError) message.error(b2xError);

     useEffect(() => {
        if (entitlementState?.entitlementSchema?.b2xRolesUpdated && ! b2xLoading)
        {
            setRefreshLoading(true);
            refetchData().then(({data}) => {
                dispatch(setB2XRoleUpdated(false));
                setB2XRoles(b2xData.getB2XRoles);
                setRefreshLoading(false);
            });
        }
        else
        if (b2xData) {
            setB2XRoles(b2xData.getB2XRoles);
        }
    }, [b2xLoading, b2xData, entitlementState?.entitlementSchema?.b2xRolesUpdated]);

    return (
        <Spin spinning={b2xLoading || refreshLoading}>
            <Form.Item key="b2xRoleComponent"name="b2xRole" label="B2X Role">
                <Select key={"b2xRoleSelect"} placeholder="B2X Role" allowClear={true} onSelect={onB2XRoleSelect}>
                    {b2xRoles?.map((role) => (
                        <Select.Option key={role.userB2xrole} value={role.userB2xrole}>
                            {role.description.concat(' (', role.userB2xrole, ')')}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        </Spin>
    );
};

export default B2XRoleDropDown;
