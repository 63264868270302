import { DocumentRecordInput } from "../../../../__generated__/globalTypes";
import client from "../../../config/GraphQLApolloClient";
import { MUTATE_ECAP_DOCUMENT_RECORD, UPDATE_ECAP_DOCUMENT_RECORD, UPDATE_ECAP_DOCUMENT_STATUS } from '../mutations';
import { GET_ECAP_DOCUMENT_RECORD } from "../queries";
import { updateDocumentRecord } from "../__generated__/updateDocumentRecord";
import { updateEcapDocumentStatus } from "../__generated__/updateEcapDocumentStatus";
import {
  mutateDocumentRecord,
  mutateDocumentRecord_mutateDocumentRecord,
  mutateDocumentRecordVariables,
} from '../__generated__/mutateDocumentRecord';
import { MODE } from "../IStoredDocumentRecord";
import { getDocumentRecord } from "../__generated__/getDocumentRecord";

export const getDocumentRecordData = (documentId: number, mode: MODE) => {
  // get the document record for specific document id
  return new Promise<getDocumentRecord>((resolve, reject) => {
    const stringMode = mode === 1 ? "EDIT" : "VIEW";
    client.query<getDocumentRecord>({
      query: GET_ECAP_DOCUMENT_RECORD,
      variables: { documentId, mode: stringMode },
      fetchPolicy: "network-only"
    }).then((response) => {
      const finalResponse: getDocumentRecord = { getDocumentRecord: response.data?.getDocumentRecord };
      resolve(finalResponse);
    }).catch((err) => {
      console.error(err);
      reject(err);
    });

  });
};

export async function createDraftDocument(document:DocumentRecordInput) {
  console.log('Create Document Request', document)
  return new Promise<mutateDocumentRecord_mutateDocumentRecord>((resolve, reject) => {
    client.mutate<mutateDocumentRecord,mutateDocumentRecordVariables>({
      mutation: MUTATE_ECAP_DOCUMENT_RECORD,
      variables: { documentRecord: document },
      fetchPolicy: "network-only"
    }).then((response) => {
      resolve(response.data.mutateDocumentRecord);
    })
  });
}

export const updateDocumentRecordData = (documentRecord: DocumentRecordInput) => {
  // Update the document record
  return new Promise<updateDocumentRecord>((resolve, reject) => {
    client.mutate<updateDocumentRecord>({
      mutation: UPDATE_ECAP_DOCUMENT_RECORD,
      variables: { documentRecord },
      fetchPolicy: "network-only"
    }).then((response) => {
      const finalResponse: updateDocumentRecord = { updateDocumentRecord: response.data?.updateDocumentRecord };
      resolve(finalResponse);
    }).catch((err) => {
      console.error(err);
      reject(err);
    });

  });
};


export const updateDocumentStatusData = (documentId: Number, documentStatusId: Number) => {
  // update document status id for specific document id
  return new Promise<updateEcapDocumentStatus>((resolve, reject) => {
    client.mutate<updateEcapDocumentStatus>({
      mutation: UPDATE_ECAP_DOCUMENT_STATUS,
      variables: {documentId, documentStatusId},
      fetchPolicy: "network-only"
    }).then((response) => {
      const finalResponse: updateEcapDocumentStatus = { updateEcapDocumentStatus: response.data?.updateEcapDocumentStatus };
      resolve(finalResponse);
    }).catch((err) => {
      console.error(err);
      reject(err);
    });

  });
};