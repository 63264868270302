import React, { useEffect, useState } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { useAppDispatch } from '../../../../main/hooks';
import { setCopyTemplate, setDocumentMode, setTemplateMode, updateDocumentId } from '../../IStoredDocumentRecordSlice';
import { FormInstance, message, Modal, Tooltip } from 'antd';
import {
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    EyeOutlined,
    FileOutlined,
    LineOutlined,
} from '@ant-design/icons';
import { MODE } from '../../IStoredDocumentRecord';
import { useMutation } from '@apollo/client';
import { getTemplateDocument } from './TemplateDocumentService';
import { IAppComponentProps } from '../../../../components';
import { isTemplateDocumentLoading, setTotalTemplateDocument } from '../../IStoredTemplateDocumentLoadingSlice';
import { FormattedMessage, useIntl } from 'react-intl';
import RenderIfEntitled from '../../../../components/EntitlementComponents';
import { getJwtPromise } from '../../../../main/OauthUtils';
import { entitlementLookup } from '../../../../main/entitlementService';
import { getI18n } from '../../../../utils/Utils';
import {
    COPY_ECAP_DOCUMENT_RECORD,
    DELETE_ECAP_DOCUMENT_RECORD,
} from '../../draft-documents/DraftDocumentGridIconRenderer';
import {
    ECAP_deleteDocument,
    ECAP_deleteDocumentVariables,
} from '../../draft-documents/__generated__/ECAP_deleteDocument';
import { ECAP_copyDocument, ECAP_copyDocumentVariables } from '../../draft-documents/__generated__/ECAP_copyDocument';
import {useNavigate} from "react-router-dom";

type RowOptionsProperties = {} & IAppComponentProps & ICellRendererParams;

const TemplateDocumentRowOptions: React.FunctionComponent<RowOptionsProperties> = (props) => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const navigate = useNavigate()

    // This is re-using the operations defined on the DRAFT document
    const [deleteDocumentRecord, {}] = useMutation<ECAP_deleteDocument, ECAP_deleteDocumentVariables>(
        DELETE_ECAP_DOCUMENT_RECORD
    );
    const [copyDocumentRecord, {}] = useMutation<ECAP_copyDocument, ECAP_copyDocumentVariables>(
        COPY_ECAP_DOCUMENT_RECORD
    );
    const [userDistinctEntitlements, setUserDistinctEntitlements] = useState<string[]>(undefined);

    const viewDocument = () => {
        dispatch(updateDocumentId(props.data.id));
        dispatch(setDocumentMode(MODE.VIEW));
        dispatch(setTemplateMode(true));
        navigate('/ecap/template-processing');
    };
    const editDocument = () => {
        dispatch(updateDocumentId(props.data.id));
        dispatch(setDocumentMode(MODE.EDIT));
        dispatch(setTemplateMode(true));
        navigate('/ecap/template-processing');
    };

    const createFromTemplate = () => {
        dispatch(updateDocumentId(props.data.id));
        dispatch(setDocumentMode(undefined));
        dispatch(setTemplateMode(false));
        dispatch(setCopyTemplate(true));
        //TODO I still need to set something else so that the capture-document knows that all ids should be cleared after load, actually the copy document should be called
        navigate('/ecap/document-processing');
    }

    const deleteDocument = () => {
        dispatch(isTemplateDocumentLoading(true));
        deleteDocumentRecord({ variables: { documentId: props.data.id } })
            .then((_) => {
                const templateListInput = JSON.parse(localStorage.getItem('templateListInput'));
                Promise.resolve(getTemplateDocument(props.currentUser, templateListInput, intl))
                    .then((data) => {
                        props.api?.setRowData(data.getTemplateDocumentList);
                        dispatch(setTotalTemplateDocument(data?.getTemplateDocumentList.length));
                        dispatch(isTemplateDocumentLoading(false));
                    })
                    .catch((err) => {
                        dispatch(isTemplateDocumentLoading(false));
                        console.error(err);
                    });
            })
            .catch((error) => {
                console.error(error);
                dispatch(isTemplateDocumentLoading(false));
            });
    };

    const confirmDelete = () => {
        Modal.confirm({
            title: getI18n('generic-confirm-text', 'Confirm', intl),
            icon: <ExclamationCircleOutlined />,
            content: getI18n('ecap-template-delete-question-text', 'Delete template document?', intl),
            okText: getI18n('general-edp-yes', 'Yes', intl),
            cancelText: getI18n('general-edp-no', 'No', intl),
            onOk: deleteDocument,
        });
    };

    const copyDocument = () => {
        //call backend service to copy document
        dispatch(isTemplateDocumentLoading(true));
        copyDocumentRecord({ variables: { documentId: props.data.id } })
            .then((data) => {
                console.log('After copy');
                console.dir(data);
                const templateListInput = JSON.parse(localStorage.getItem('templateListInput'));
                Promise.resolve(getTemplateDocument(props.currentUser, templateListInput, intl))
                    .then((data) => {
                        props.api?.setRowData(data.getTemplateDocumentList);
                        dispatch(setTotalTemplateDocument(data?.getTemplateDocumentList.length));
                        dispatch(isTemplateDocumentLoading(false));
                        message.info(
                            getI18n('ecap-template-document-copy-succeeded', 'Template copied successfully', intl)
                        );
                    })
                    .catch((err) => {
                        dispatch(isTemplateDocumentLoading(false));
                        console.error(err);
                        message.error(
                            getI18n('ecap-template-document-loading-failed', 'Loading of template list failed', intl)
                        );
                    });
            })
            .catch((error) => {
                console.error(error);
                dispatch(isTemplateDocumentLoading(false));
                message.error(getI18n('ecap-template-document-copy-failed', 'Template copy failed', intl));
            });
    };

    const getUserDistinctEntitlements = () => {
        // console.log("Get entitlements");
        const userName = getJwtPromise()?.sub;
        entitlementLookup(userName).then((data) => {
            const foundEntitlements = data?.getUserEntitlementResultByUserId?.distinctEntitlements;
            if (foundEntitlements === null || foundEntitlements === undefined || foundEntitlements.length === 0) {
                console.error('NO USER ENTITLEMENTS FOUND');
                setUserDistinctEntitlements([]);
            } else {
                setUserDistinctEntitlements(foundEntitlements);
            }
        });
    };

    useEffect(() => {
        if (userDistinctEntitlements === undefined) {
            getUserDistinctEntitlements();
        }
    }, [userDistinctEntitlements]);

    return (
        <div>
            <RenderIfEntitled
                entitlementToCheck="delete_tp@ecap"
                distinctEntitlements={userDistinctEntitlements}
                intl={intl}
            >
                <Tooltip
                    title={getI18n('draft-document-row-options-delete', 'Delete', intl)}
                >
                    <DeleteOutlined
                        style={{ marginLeft: '5px', cursor: 'pointer' }}
                        onClick={confirmDelete}
                        colSpan={3}
                    />
                </Tooltip>
            </RenderIfEntitled>

            <RenderIfEntitled
                entitlementToCheck="copy_tp@ecap"
                distinctEntitlements={userDistinctEntitlements}
                intl={intl}
            >
                <Tooltip title={getI18n('template-document-row-options-copy', 'Copy Template', intl)}>
                    <CopyOutlined style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={copyDocument} />
                </Tooltip>
            </RenderIfEntitled>

            <RenderIfEntitled
                entitlementToCheck="change_tp@ecap"
                distinctEntitlements={userDistinctEntitlements}
                intl={intl}
            >
                <Tooltip title={getI18n('draft-document-row-options-edit', 'Edit', intl)}>
                    <EditOutlined style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={editDocument} />
                </Tooltip>
            </RenderIfEntitled>
            <Tooltip title={getI18n('draft-document-row-options-view', 'View', intl)}>
                <EyeOutlined style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={viewDocument} />
            </Tooltip>
            <Tooltip title={getI18n('template-document-row-options-use-template','Create Document From Template', intl)}>
                <FileOutlined style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={createFromTemplate} />
            </Tooltip>
        </div>
    );
};

export default TemplateDocumentRowOptions;
