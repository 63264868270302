import { act } from '@testing-library/react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStoreEntitlementSearchFilters } from './IStoredEntitlements';
import { EntitlementFilter, EntitlementRule } from 'src/__generated__/types';

interface EntitlementState {
    entitlementSchema: IStoreEntitlementSearchFilters;
}

const initialState: EntitlementState = {
    entitlementSchema: {
        entitlementFilter: undefined,
        entitlementAuditFilter: undefined,
        newEntitlement: undefined,
        masterDataEdited: false,
        b2xRolesUpdated: false,
        entitlementTypesUpdated: false,
        groupsUpdated: false,
        ObjectClassUpdate: false,
    },
};

const entitlementSlice = createSlice({
    name: 'EntitlementFilters',
    initialState,
    reducers: {
        updateEntitlementFilters(state, action: PayloadAction<EntitlementFilter>) {
            state.entitlementSchema.entitlementFilter = action.payload;
        },
        updateEntitlementAuditFilters(state, action: PayloadAction<EntitlementFilter>) {
            state.entitlementSchema.entitlementAuditFilter = action.payload;
        },
        updateNewEntitlement(state, action: PayloadAction<EntitlementRule>) {
            state.entitlementSchema.newEntitlement = action.payload;
        },
        updateMasterDataUpdated(state, action: PayloadAction<boolean>) {
            state.entitlementSchema.masterDataEdited = action.payload;
        },
        setObjectClassUpdated(state, action: PayloadAction<boolean>) {
            state.entitlementSchema.ObjectClassUpdate= action.payload;
        },
        setGroupUpdated(state, action: PayloadAction<boolean>) {
            state.entitlementSchema.groupsUpdated = action.payload;
        },
        setB2XRoleUpdated(state, action: PayloadAction<boolean>) {
            state.entitlementSchema.b2xRolesUpdated = action.payload;
        },
        setEntitlementTypeUpdated(state, action: PayloadAction<boolean>) {
            state.entitlementSchema.entitlementTypesUpdated = action.payload;
        }

        
    },
});

export const { updateEntitlementFilters, 
    updateEntitlementAuditFilters, 
    updateNewEntitlement,
    updateMasterDataUpdated,
    setObjectClassUpdated,
    setGroupUpdated,
    setB2XRoleUpdated,
    setEntitlementTypeUpdated,
} = entitlementSlice.actions;
export default entitlementSlice.reducer;
