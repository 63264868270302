import React, { FunctionComponent, useEffect, useState } from "react";
import {Button, Col, Row, Tabs, Form, Typography, Collapse, Switch, Select, Radio, notification, message} from "antd";
import { useQuery } from "@apollo/client";

import { IAppComponentProps } from "../../../components";
import { SurveyConnectivityOFTP2Input, SurveyContactInput } from "../../../../__generated__/globalTypes";
import {  getSurveyConnectivityOftp2BySurveySvc, updateSurveyConnectivityOftp2 } from "./SurveyConnectivityService";
import { cleanFormValues, Form_Row, getInitialFormValues } from "./_SurveyComponents";
import { getSurveyConnectivityOftp2BySurvey, getSurveyConnectivityOftp2BySurveyVariables } from "../__generated__/getSurveyConnectivityOftp2BySurvey";
import { QUERY_SURVEY_CONNECTIVITY_OFTP2_BY_SURVEY } from "../queries";
import { useIntl } from "react-intl";
import {StateContainer} from "../utilities/StateContainer";
import { useAppDispatch, useAppSelector } from "../../../main/hooks";
import { MODE } from "../IStoredSurvey";
import { getI18n } from "../../../utils/Utils";

const { Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

import styles from "../styling/survey.module.css";
import { resetSurveyDataLoading, setSurveyDataLoading, updateSurveyCollapsedState } from "../IStoredSurveySlice";

type ISurveyConnectivityInfoContactProps = {
  groupId?: any;
  onStatusUpdate?: any;
  stateContainer?: StateContainer;
} & IAppComponentProps;


const SurveyConnectivityOFTP2: FunctionComponent<ISurveyConnectivityInfoContactProps> = (props) => {
  const dispatch = useAppDispatch();
  const { TabPane } = Tabs;
  const [form] = Form.useForm();
  // const [saving, setSaving] = useState(false);
  const [changed, setChanged] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [productionActive, setProductionActive] = useState(true);
  const intl = useIntl();
  const [flag,setFlag] = useState<boolean>(false);
  const envSelected = productionActive ?
    intl.formatMessage({ id: "survey-connectivityInfo-production-selection", defaultMessage: "Production Detail" }) :
    intl.formatMessage({ id: "survey-as2-test-detail", defaultMessage: "Test Detail" });

  const reload = ()=> setFlag(current=>!current);

  
 const surveyState = useAppSelector((state) => state.survey);

 const isViewMode = () => {
   return surveyState.survey.surveyMode === MODE.VIEW ? true : false
 }

 const isOverViewMode = () => {
   return surveyState.survey.surveyMode === MODE.OVERVIEW ? true : false
 }

 const isReadOnly = () => {
  return isViewMode() || isOverViewMode();
 }

  /**load initial data from api if available */
  // const { data, loading } = useQuery<getSurveyConnectivityOftp2BySurvey>(QUERY_SURVEY_CONNECTIVITY_OFTP2_BY_SURVEY,
  //   {
  //     fetchPolicy: "network-only",
  //     variables: { groupId: props.groupId },
  //     onCompleted: (response) => {
  //       const initialValues = getInitialFormValues(response.getSurveyConnectivityOftp2BySurvey, 'environment');
  //       form.setFieldsValue(initialValues);
  //     }
  //   }
  // );

  const loadData = () => {
    dispatch(setSurveyDataLoading(true));
    getSurveyConnectivityOftp2BySurveySvc(props.groupId)
      .then((response: getSurveyConnectivityOftp2BySurvey) => {
        if (response.getSurveyConnectivityOftp2BySurvey?.length > 0) {
          const initialValues = getInitialFormValues(response.getSurveyConnectivityOftp2BySurvey, 'environment');
          form.setFieldsValue(initialValues);
          setDataLoaded(true);
        }
      })
      .catch(() => {
        dispatch(resetSurveyDataLoading(true));
      })
      .finally(() => {
        dispatch(setSurveyDataLoading(false));
      });
  }


  const onFinish = (values: any) => {
    // setSaving(true);
    console.log("Saving OFTP2 details");
    dispatch(setSurveyDataLoading(true));
    values.environment = productionActive ? "PRODUCTION" : "TEST";
    const cleanValues = cleanFormValues(values, values.environment);

    const oftp2Info: SurveyConnectivityOFTP2Input = cleanValues;
    oftp2Info.environment = values.environment;
    oftp2Info.providerPushFixedLength = Number.parseInt(cleanValues.providerPushFixedLength);
    oftp2Info.partnerPullFixedLength = Number.parseInt(cleanValues.partnerPullFixedLength);
    oftp2Info.serverPort = Number.parseInt(cleanValues.serverPort);
    oftp2Info.sfidCode = cleanValues.sfidCode.trim();
    oftp2Info.serverAddress = cleanValues.serverAddress.trim();
    oftp2Info.sSIDCode = cleanValues.sSIDCode.trim();

    updateSurveyConnectivityOftp2(props.groupId, oftp2Info)
    .then(() => {
      message.success(getI18n("survey-connectivity-save-contact-success-message", "Connectivity Information saved successfully" , intl));
      loadData();
    })
      .catch((e) => {
        console.error(e);
        dispatch(resetSurveyDataLoading(true));
        message.error(getI18n("survey-connectivity-save-contact-failed-message", "Failed to save connectivity information" , intl));
      })
      .finally(() => {
        // setSaving(false);
        dispatch(setSurveyDataLoading(false));
        setChanged(false);
        //force issues component to refresh
        props.onStatusUpdate();
      });
  }

  const onChange = () => {
    setChanged(true);
      reload();
  }

  const isOriginatorForNonEDIMandatory = ()=> {
      const partnerPushFileType = form.getFieldValue("partnerPushFileTypes");
      return partnerPushFileType === 'BOTH' || partnerPushFileType === 'NON-EDI';
  }

    const isRecepientForNonEDIMandatory = () => {
        const partnerPushFileType = form.getFieldValue("partnerPushFileTypes");
        return partnerPushFileType === 'BOTH' || partnerPushFileType === 'NON-EDI';
    }

    const isAPRFForNonADIMandatory = () => {
        const partnerPushFileType = form.getFieldValue("partnerPushFileTypes");
        return partnerPushFileType === 'BOTH' || partnerPushFileType === 'NON-EDI';
    }

    function addNewError(newPanel, newShortInfoErrors) {
      const item = newShortInfoErrors.find((item) => item === newPanel);
      if (item === undefined) {
          newShortInfoErrors.push(newPanel);
      }
      return newShortInfoErrors;
  }

    const submitFormFailed = (errors) => {
      let newShortInfoErrors = [];
      console.error(errors);
      console.log(`result : ${Array.isArray(errors) && errors.length > 0}`);      if (errors !== null && Array.isArray(errors.errorFields) && errors.errorFields.length > 0)
      {
        notification.error({
          message:  props.intl.formatMessage({'id':"survey-connectivityOFTP2-required-fields-missing",'defaultMessage': "Not all required fields were completed, please check Data Transfer Parameters"})
        });
        errors.errorFields.map((el) => {
          const name: String = el.name.toString();
          console.log(`name : ${name}`);
          if (name.startsWith('providerPush')) {
            newShortInfoErrors = addNewError("DataTransferParametersTabPane", newShortInfoErrors);
            newShortInfoErrors = addNewError("ProviderPushPanel", newShortInfoErrors);
          }
          else if (name.startsWith('partnerPush'))
          {
            newShortInfoErrors = addNewError("DataTransferParametersTabPane", newShortInfoErrors);
            newShortInfoErrors = addNewError("TradingPartnerPushPanel", newShortInfoErrors);
          }
      });
      console.log("newShortInfoErrors");
      console.dir(newShortInfoErrors);
      dispatch(updateSurveyCollapsedState(newShortInfoErrors));
      }
    }

    useEffect(()=>{
      if (!dataLoaded)
      {
        console.log("Load data");
        loadData();
      }
      
    },[dataLoaded]);

  return (
    <>
      <Row>
        <Col>
          <Text disabled={true}>GroupId: {props.groupId}</Text>
        </Col>
      </Row>
      <Row gutter={[24, 10]}>
        <Col>
          <Text>{intl.formatMessage({ id: "survey-connectivityInfo-oftp2-descriptionLine1", defaultMessage: "You have selected OFTP2 as the communication channel." })}</Text>
        </Col>
      </Row>
      <Row gutter={[24, 10]}>
        <Col>
          <Text>{intl.formatMessage({ id: "survey-connectivityInfo-oftp2-descriptionLine2", defaultMessage: "Please provide the required information for OFTP2 connectivity between you and our eInvoice service provider:" })}</Text>
        </Col>
      </Row>
      <Row>
        <div>
          <label>
            {intl.formatMessage({
              id: "survey-connectivityInfo-environment-prompt",
              defaultMessage: "Do you want to capture the PRODUCTION or TEST environment details?"
            })}
          </label><Switch className={"ml20"}
                          checkedChildren={intl.formatMessage({
                            id: "survey-connectivityInfo-production-selection",
                            defaultMessage: "Production Detail"
                          })}
                          unCheckedChildren={intl.formatMessage({
                            id: "survey-as2-test-detail",
                            defaultMessage: "Test Detail"
                          })}
                          defaultChecked={true}
                          onChange={setProductionActive} />
        </div>
      </Row>

      <Form
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        labelAlign={"left"}
        labelWrap={true}
        onFinish={onFinish}
        onFinishFailed={submitFormFailed}
        form={form}
        onFieldsChange={onChange}
      >
        {/* <Spin spinning={loading || saving} size={"large"}> */}
          <Row gutter={24}>
            <Col span={24}>

              <Tabs defaultActiveKey={"1"} className="users-tabs ml20">
                <TabPane tab={intl.formatMessage({id: "survey-connectivityInfo-oftp2-serverDetail-title", defaultMessage: "OFTP Server Details"})} 
                          key="OFTPServerDetailsTabPane" 
                          className="pl-5" 
                          forceRender={true}>
                  <Collapse defaultActiveKey={"[1]"} 
                            activeKey={surveyState.survey.collapsedState} 
                            onChange={(newActiveKeys) => {
                              // otherwise panels couldn't be open/closed by click
                              dispatch(updateSurveyCollapsedState(newActiveKeys));
                            }}>
                    <Panel header={`${intl.formatMessage({id: "survey-connectivityInfo-oftp2-serverDetail-title", defaultMessage: "OFTP Server Details"      })} - ${envSelected}`} 
                            key="serverDetailsPanel" 
                            forceRender={true}>
                      <table className="form--table">
                        <colgroup>
                          <col span={1} style={{ "width": "55%" }} />
                          <col span={1} style={{ "width": "20%" }} />
                          <col span={1} style={{ "width": "25%" }} />
                        </colgroup>
                        <thead>
                        <tr>
                          <th>{intl.formatMessage({
                            id: "survey-connectivityInfo-oftp2-parameters-title",
                            defaultMessage: "OFTP2 Parameters"
                          })}</th>
                          <th>{intl.formatMessage({
                            id: "survey-connectivityInfo-commentHeader",
                            defaultMessage: "Comment"
                          })}</th>
                          <th>{intl.formatMessage({
                            id: "survey-connectivityInfo-InstructionHeader",
                            defaultMessage: "Instruction"
                          })}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <Form_Row
                          isProduction={productionActive}
                          label={intl.formatMessage({
                            id: "survey-connectivityInfo-oftp2-security-preference-prompt",
                            defaultMessage: "OFTP2 Production Server SFID Code"
                          })}
                          instruction={intl.formatMessage({
                            id: "survey-connectivityInfo-oftp2-security-preference-instruction",
                            defaultMessage: " "
                          })}
                          name="sfidCode"
                          required={true}
                          requiredMessage={intl.formatMessage({
                            id: "generic-requiredField-message",
                            defaultMessage: "Please enter required field"
                          })}
                        />
                        <Form_Row
                          isProduction={productionActive}
                          label={intl.formatMessage({
                            id: "survey-connectivityInfo-oftp2-security-preference-label",
                            defaultMessage: "Security Preference"
                          })}
                          instruction={intl.formatMessage({
                            id: "survey-connectivityInfo-oftp2-security-preference-instruction",
                            defaultMessage: " "
                          })}
                          name="securityPreference"
                          required={true}
                          requiredMessage={intl.formatMessage({
                            id: "generic-requiredField-message",
                            defaultMessage: "Please enter required field"
                          })}
                        >
                          <Select disabled={isReadOnly()}>
                            <Option value="TLS (Recommended)">
                              {intl.formatMessage({
                                id: "survey-connectivityInfo-oftp2-security-preference-option-tls",
                                defaultMessage: "TLS (Recommended)"
                              })}
                            </Option>
                            <Option value="Signing and/or encryption">
                              {intl.formatMessage({
                                id: "survey-connectivityInfo-oftp2-signing-and-encryption-option-tls",
                                defaultMessage: "Signing and/or encryption"
                              })}
                            </Option>
                            <Option value="TLS with signing and/or encryption">
                              {intl.formatMessage({
                                id: "survey-connectivityInfo-oftp2-tls-signing-and-encryption-option-tls",
                                defaultMessage: "TLS with signing and/or encryption"
                              })}
                            </Option>
                          </Select>
                        </Form_Row>
                        <Form_Row
                          isProduction={productionActive}
                          label={intl.formatMessage({
                            id: "survey-connectivityInfo-oftp2-fileExchangeMode-prompt",
                            defaultMessage: "File Exchange Mode"
                          })}
                          instruction={intl.formatMessage({
                            id: "survey-connectivityInfo-oftp2-fileExchangeMode-instruction",
                            defaultMessage: " "
                          })}
                          name="fileExchangeMode"
                          required={true}
                          requiredMessage={intl.formatMessage({
                            id: "generic-requiredField-message",
                            defaultMessage: "Please enter required field"
                          })}
                        >
                          <Select disabled={isReadOnly()}>
                            <Option value="You and Service Provider push files">
                              {intl.formatMessage({
                                id: "survey-connectivityInfo-oftp2-fileExchangeMode-option-bothPushPull",
                                defaultMessage: "You and Service Provider Push Files"
                              })}
                            </Option>
                            {/*    <Option value="You push and pull files">
                                {intl.formatMessage({ id: "survey-connectivityInfo-oftp2-fileExchangeMode-option-partnerPushPull", defaultMessage: "You Push And Pull Files" })}
                              </Option>
                              <Option value="Service Provider push and pull files">
                                {intl.formatMessage({ id: "survey-connectivityInfo-oftp2-fileExchangeMode-option-providerPushPull", defaultMessage: "Service Provider Push And Pull Files" })}
                              </Option>*/}
                          </Select>
                        </Form_Row>
                        <Form_Row
                          isProduction={productionActive}
                          label={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-serverAddress-prompt", defaultMessage: "Server Address" })}
                          instruction={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-serverAddress-instruction", defaultMessage: " " })}
                          name="serverAddress"
                          required={true}
                          requiredMessage={intl.formatMessage({
                            id: "generic-requiredField-message",
                            defaultMessage: "Please enter required field"
                          })}
                        />
                        <Form_Row
                          isProduction={productionActive}
                          label={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-serverPort-prompt", defaultMessage: "Server Port" })}
                          instruction={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-serverPort-instruction", defaultMessage: " " })}
                          name="serverPort"
                          required={true}
                          requiredMessage={intl.formatMessage({
                            id: "generic-requiredField-message",
                            defaultMessage: "Please enter required field"
                          })}
                          type="number"
                        />
                        <Form_Row
                          isProduction={productionActive}
                          label={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-ssidCode-prompt", defaultMessage: "SSID Code" })}
                          instruction={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-ssidCode-instruction", defaultMessage: " " })}
                          name="sSIDCode"
                          required={true}
                          requiredMessage={intl.formatMessage({
                            id: "generic-requiredField-message",
                            defaultMessage: "Please enter required field"
                          })}
                        />
                        <Form_Row
                          isProduction={productionActive}
                          label={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-passwordAvailable-prompt", defaultMessage: "Password Available" })}
                          instruction={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-passwordAvailable-instruction", defaultMessage: " " })}
                          name="passwordAvailable"
                          required={true}
                          requiredMessage={intl.formatMessage({
                            id: "generic-requiredField-message",
                            defaultMessage: "Please enter required field"
                          })}
                        >
                          <Radio.Group className={styles.yesNoStyling}>
                            <Radio.Button value={true}>{intl.formatMessage({id:'ecap-capture-bmw-retail-outlet-choice-yes'})}</Radio.Button>
                            <Radio.Button value={false}>{intl.formatMessage({id:'ecap-capture-bmw-retail-outlet-choice-no'})}</Radio.Button>
                          </Radio.Group>
                        </Form_Row>
                        <Form_Row
                          isProduction={productionActive}
                          label={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-tls-prompt", defaultMessage: "Use TLS" })}
                          instruction={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-tls-instruction", defaultMessage: " " })}
                          name="useTLS"
                          required={true}
                          requiredMessage={intl.formatMessage({
                            id: "generic-requiredField-message",
                            defaultMessage: "Please enter required field"
                          })}
                        >
                          <Radio.Group className={styles.yesNoStyling}>
                            <Radio.Button value={true}>{intl.formatMessage({id:'ecap-capture-bmw-retail-outlet-choice-yes'})}</Radio.Button>
                            <Radio.Button value={false}>{intl.formatMessage({id:'ecap-capture-bmw-retail-outlet-choice-no'})}</Radio.Button>
                          </Radio.Group>
                        </Form_Row>
                        <Form_Row
                          isProduction={productionActive}
                          label={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-cryptogtaphicAuth-prompt", defaultMessage: "Use Cryptographic Authentication" })}
                          instruction={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-cryptogtaphicAuth-instruction", defaultMessage: " " })}
                          name="useCryptographicAuthentication"
                          required={true}
                          requiredMessage={intl.formatMessage({
                            id: "generic-requiredField-message",
                            defaultMessage: "Please enter required field"
                          })}
                        >
                          <Radio.Group className={styles.yesNoStyling}>
                            <Radio.Button value={true}>{intl.formatMessage({id:'ecap-capture-bmw-retail-outlet-choice-yes'})}</Radio.Button>
                            <Radio.Button value={false}>{intl.formatMessage({id:'ecap-capture-bmw-retail-outlet-choice-no'})}</Radio.Button>
                          </Radio.Group>
                        </Form_Row>
                        <Form_Row
                          isProduction={productionActive}
                          label={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-certificateAvailable-prompt", defaultMessage: "Certificate available" })}
                          instruction={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-certificateAvailable-instruction", defaultMessage: " " })}
                          name="certificateAvailable"
                          required={true}
                          requiredMessage={intl.formatMessage({
                            id: "generic-requiredField-message",
                            defaultMessage: "Please enter required field"
                          })}
                        >
                          <Radio.Group className={styles.yesNoStyling}>
                            <Radio.Button value={true}>{intl.formatMessage({id:'ecap-capture-bmw-retail-outlet-choice-yes'})}</Radio.Button>
                            <Radio.Button value={false}>{intl.formatMessage({id:'ecap-capture-bmw-retail-outlet-choice-no'})}</Radio.Button>
                          </Radio.Group>
                        </Form_Row>
                        </tbody>
                      </table>
                    </Panel>
                  </Collapse>
                </TabPane>
                <TabPane tab={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-dataTransfer-title", defaultMessage: "Data Transfer Parameters" })} 
                        key="DataTransferParametersTabPane" 
                        className="pl-5"
                        forceRender={true}>
                  <Collapse defaultActiveKey={"[2]"} 
                    activeKey={surveyState.survey.collapsedState}
                    onChange={(newActiveKeys) => {
                      // otherwise panels couldn't be open/closed by click
                      dispatch(updateSurveyCollapsedState(newActiveKeys));
                    }}
                  >
                    <Panel header={`${intl.formatMessage({ id: "survey-connectivityInfo-oftp2-providerPushToPartner-title", defaultMessage: "Service Provider Pushes TO Trading Partner" })} - ${envSelected}`} 
                            key="ProviderPushPanel" 
                            forceRender={true}>
                      <table className="form--table">
                        <colgroup>
                          <col span={1} style={{ "width": "55%" }} />
                          <col span={1} style={{ "width": "20%" }} />
                          <col span={1} style={{ "width": "25%" }} />
                        </colgroup>
                        <thead>
                        <tr>
                          <th>{intl.formatMessage({ id: "survey-connectivityInfo-oftp2-parameters-title", defaultMessage: "OFTP2 Parameters" })}</th>
                          <th>{intl.formatMessage({ id: "survey-connectivityInfo-commentHeader", defaultMessage: "Comment" })}</th>
                          <th>{intl.formatMessage({ id: "survey-connectivityInfo-InstructionHeader", defaultMessage: "Instruction" })}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <Form_Row
                          isProduction={productionActive}
                          label={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-providerPushToPartner-sfid-prompt", defaultMessage: "SFID Destination" })}
                          instruction={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-providerPushToPartner-sfid-instruction", defaultMessage: " " })}
                          name="providerPushSFIDDestination"
                          required={true}
                          requiredMessage={intl.formatMessage({
                            id: "generic-requiredField-message",
                            defaultMessage: "Please enter required field"
                          })}
                        />
                        <Form_Row
                          isProduction={productionActive}
                          label={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-providerPushToPartner-sfiddsn-prompt", defaultMessage: "SFIDDSN (VFN)" })}
                          instruction={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-providerPushToPartner-sfiddsn-instruction", defaultMessage: " " })}
                          name="providerPushSFIDDSNVFN"
                          required={true}
                          requiredMessage={intl.formatMessage({
                            id: "generic-requiredField-message",
                            defaultMessage: "Please enter required field"
                          })}
                        />
                        <Form_Row
                          isProduction={productionActive}
                          label={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-providerPushToPartner-advancedCompression-prompt", defaultMessage: "Use Advanced Compression" })}
                          instruction={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-providerPushToPartner-advancedCompression-instruction", defaultMessage: " " })}
                          name="providerPushAdvancedCompression"
                          required={true}
                          requiredMessage={intl.formatMessage({
                            id: "generic-requiredField-message",
                            defaultMessage: "Please enter required field"
                          })}
                        >
                          <Radio.Group className={styles.yesNoStyling}>
                            <Radio.Button value={true}>{intl.formatMessage({id:'ecap-capture-bmw-retail-outlet-choice-yes'})}</Radio.Button>
                            <Radio.Button value={false}>{intl.formatMessage({id:'ecap-capture-bmw-retail-outlet-choice-no'})}</Radio.Button>
                          </Radio.Group>
                        </Form_Row>
                        <Form_Row
                          isProduction={productionActive}
                          label={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-providerPushToPartner-dataformat-prompt", defaultMessage: "Data Format" })}
                          instruction={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-providerPushToPartner-dataformat-instruction", defaultMessage: " " })}
                          name="providerPushDataFormat"
                          required={true}
                          requiredMessage={intl.formatMessage({
                            id: "generic-requiredField-message",
                            defaultMessage: "Please enter required field"
                          })}
                        >
                          <Select disabled={isReadOnly()}>
                            <Option value="Text">{intl.formatMessage({ id: "survey-connectivityInfo-oftp2-dataFormat-option-text", defaultMessage: "Text" })}</Option>
                            <Option value="Unstructured (Recommended)">{intl.formatMessage({ id: "survey-connectivityInfo-oftp2-dataFormat-option-unstructured", defaultMessage: "Unstructured (Recommended)" })}</Option>
                            <Option value="Fixed">{intl.formatMessage({ id: "survey-connectivityInfo-oftp2-dataFormat-option-fixed", defaultMessage: "Fixed" })}</Option>
                          </Select>
                        </Form_Row>
                        <Form_Row
                          isProduction={productionActive}
                          label={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-providerPushToPartner-fixedlengthSize-prompt", defaultMessage: "If Fixed - max length" })}
                          instruction={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-providerPushToPartner-fixedlengthSize-instruction", defaultMessage: " " })}
                          name="providerPushFixedLength"
                          required={false}
                          type="number"
                        />
                        <Form_Row
                          isProduction={productionActive}
                          label={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-providerPushToPartner-encryptSign-prompt", defaultMessage: "Encrypt Or Sign Files" })}
                          instruction={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-providerPushToPartner-encryptSign-instruction", defaultMessage: " " })}
                          name="providerPushEnryptOrSign"
                          required={false}
                        >
                          <Select disabled={isReadOnly()}>
                            <Option value="Encrypted">{intl.formatMessage({ id: "survey-connectivityInfo-oftp2-dataEncryption-option-encrypted", defaultMessage: "Encrypted" })}</Option>
                            <Option value="Siged">{intl.formatMessage({ id: "survey-connectivityInfo-oftp2-dataEncryption-option-signed", defaultMessage: "Signed" })}</Option>
                            <Option value="Both">{intl.formatMessage({ id: "survey-connectivityInfo-oftp2-dataEncryption-option-signedAndEncrypted", defaultMessage: "Both" })}</Option>
                            <Option value="Neither">{intl.formatMessage({ id: "survey-connectivityInfo-oftp2-dataEncryption-option-none", defaultMessage: "Neither" })}</Option>
                          </Select>
                        </Form_Row>
                        <Form_Row
                          isProduction={productionActive}
                          label={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-providerPushToPartner-encryptionAlgorithm-prompt", defaultMessage: "Encryption Algorithm" })}
                          instruction={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-providerPushToPartner-encryptionAlgorithm-instruction", defaultMessage: " " })}
                          name="providerPushEnryptionAlgorithm"
                          required={false}
                          requiredMessage={intl.formatMessage({
                            id: "generic-requiredField-message",
                            defaultMessage: "Please enter required field"
                          })}
                        >
                          <Select disabled={isReadOnly()}>
                            <Option value="3DES">3DES</Option>
                            <Option value="AES256">AES256</Option>
                          </Select>
                        </Form_Row>
                        <Form_Row
                          isProduction={productionActive}
                          label={intl.formatMessage({
                            id: "survey-connectivityInfo-oftp2-providerPushToPartner-expectEerp-prompt",
                            defaultMessage: "Will you SEND EERP"
                          })}
                          instruction={intl.formatMessage({
                            id: "survey-connectivityInfo-oftp2-providerPushToPartner-expectEerp-instruction",
                            defaultMessage: " "
                          })}
                          name="providerPushEERP"
                          required={true}
                          requiredMessage={intl.formatMessage({
                            id: "generic-requiredField-message",
                            defaultMessage: "Please enter required field"
                          })}
                        >
                          <Radio.Group className={styles.yesNoStyling}>
                            <Radio.Button value={true}>{intl.formatMessage({id:'ecap-capture-bmw-retail-outlet-choice-yes'})}</Radio.Button>
                            <Radio.Button value={false}>{intl.formatMessage({id:'ecap-capture-bmw-retail-outlet-choice-no'})}</Radio.Button>
                          </Radio.Group>
                        </Form_Row>
                        <Form_Row
                          isProduction={productionActive}
                          label={intl.formatMessage({
                            id: "survey-connectivityInfo-oftp2-providerPushToPartner-signing-algorithm-prompt",
                            defaultMessage: "Signing Algorithm"
                          })}
                          instruction={intl.formatMessage({
                            id: "survey-connectivityInfo-oftp2-providerPushToPartner-signing-algorithm-instruction",
                            defaultMessage: " "
                          })}
                          name="providerPushSigningAlgorithm"
                          required={false}
                          requiredMessage={intl.formatMessage({
                            id: "generic-requiredField-message",
                            defaultMessage: "Please enter required field"
                          })}
                        >
                          <Select disabled={isReadOnly()}>
                            <Option value="SHA1">SHA1</Option>
                            <Option value="SHA 256">SHA 256</Option>
                            <Option value="SHA 512">SHA 512</Option>
                          </Select>
                        </Form_Row>
                        <Form_Row
                          isProduction={productionActive}
                          label={intl.formatMessage({
                            id: "survey-connectivityInfo-oftp2-providerPushToPartner-data-encoding-prompt",
                            defaultMessage: "Data Encoding"
                          })}
                          instruction={intl.formatMessage({
                            id: "survey-connectivityInfo-oftp2-providerPushToPartner-data-encoding-instruction",
                            defaultMessage: " "
                          })}
                          name="providerPushDataEncoding"
                          required={true}
                          requiredMessage={intl.formatMessage({
                            id: "generic-requiredField-message",
                            defaultMessage: "Please enter required field"
                          })}
                        >
                          <Select disabled={isReadOnly()}>
                            <Option value="ASCII">{intl.formatMessage({id:'survey-connectivityInfo-oftp2-ascii-recommended'})}</Option>
                            <Option value="EBCDIC">EBCDIC</Option>
                          </Select>
                        </Form_Row>
                        </tbody>
                      </table>
                    </Panel>

                    {/*  <Panel header={`${intl.formatMessage({ id: "survey-connectivityInfo-oftp2-partnerPullsFromProvider-title", defaultMessage: "Trading Partner pulls files from Service Provider" })} - ${envSelected}`} key="3" forceRender={true}>
                      <table className="form--table">
                        <colgroup>
                          <col span={1} style={{ "width": "55%" }} />
                          <col span={1} style={{ "width": "20%" }} />
                          <col span={1} style={{ "width": "25%" }} />
                        </colgroup>
                        <thead>
                          <tr>
                            <th>{intl.formatMessage({ id: "survey-connectivityInfo-oftp2-parameters-title", defaultMessage: "OFTP2 Parameters" })}</th>
                            <th>{intl.formatMessage({ id: "survey-connectivityInfo-commentHeader", defaultMessage: "Comment" })}</th>
                            <th>{intl.formatMessage({ id: "survey-connectivityInfo-InstructionHeader", defaultMessage: "Instruction" })}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <Form_Row
                            isProduction={productionActive}
                            label={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-partnerPullsFromProvider-sfiddsn-prompt", defaultMessage: "SFIDDSN (VFN)" })}
                            instruction={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-partnerPullsFromProvider-sfiddsn-instruction", defaultMessage: " " })}
                            name="partnerPullSFIDDSNVFN"
                            required={true}
                          />
                          <Form_Row
                            isProduction={productionActive}
                            label={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-partnerPullsFromProvider-dataformat-prompt", defaultMessage: "Data Format" })}
                            instruction={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-partnerPullsFromProvider-dataformat-instruction", defaultMessage: " " })}
                            name="partnerPullDataFormat"
                            required={false}
                          >
                            <Select disabled={isReadOnly()}>
                              <Option value="Text">{intl.formatMessage({ id: "survey-connectivityInfo-oftp2-dataFormat-option-text", defaultMessage: "Text" })}</Option>
                              <Option value="Unstructured (Recommended)">{intl.formatMessage({ id: "survey-connectivityInfo-oftp2-dataFormat-option-unstructured", defaultMessage: "Unstructured (Recommended)" })}</Option>
                              <Option value="Fixed">{intl.formatMessage({ id: "survey-connectivityInfo-oftp2-dataFormat-option-fixed", defaultMessage: "Fixed" })}</Option>
                            </Select>
                          </Form_Row>
                          <Form_Row
                            isProduction={productionActive}
                            label={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-partnerPullsFromProvider-fixedlengthSize-prompt", defaultMessage: "If Fixed - max length" })}
                            instruction={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-partnerPullsFromProvider-fixedlengthSize-instruction", defaultMessage: " " })}
                            name="partnerPullFixedLength"
                            required={true}
                            type="number"
                          />
                          <Form_Row
                            isProduction={productionActive}
                            label={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-partnerPullsFromProvider-advancedCompression-prompt", defaultMessage: "Use Advanced Compression" })}
                            instruction={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-partnerPullsFromProvider-advancedCompression-instruction", defaultMessage: " " })}
                            name="partnerPullAdvancedCompression"
                            required={false}
                            type="checkbox"
                          />
                          <Form_Row
                            isProduction={productionActive}
                            label={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-partnerPullsFromProvider-encryptSign-prompt", defaultMessage: "Encrypt Or Sign Files" })}
                            instruction={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-partnerPullsFromProvider-encryptSign-instruction", defaultMessage: " " })}
                            name="partnerPullEnryptOrSign"
                            required={true}
                          >
                            <Select disabled={isReadOnly()}>
                              <Option value="Encrypted">{intl.formatMessage({ id: "survey-connectivityInfo-oftp2-dataEncryption-option-encrypted", defaultMessage: "Encrypted" })}</Option>
                              <Option value="Siged">{intl.formatMessage({ id: "survey-connectivityInfo-oftp2-dataEncryption-option-signed", defaultMessage: "Signed" })}</Option>
                              <Option value="Both">{intl.formatMessage({ id: "survey-connectivityInfo-oftp2-dataEncryption-option-signedAndEncrypted", defaultMessage: "Both" })}</Option>
                              <Option value="Neither">{intl.formatMessage({ id: "survey-connectivityInfo-oftp2-dataEncryption-option-none", defaultMessage: "Neither" })}</Option>
                            </Select>
                          </Form_Row>
                          <Form_Row
                            isProduction={productionActive}
                            label={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-partnerPullsFromProvider-encryptionAlgorithm-prompt", defaultMessage: "Encryption Algorithm" })}
                            instruction={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-partnerPullsFromProvider-encryptionAlgorithm-instruction", defaultMessage: " " })}
                            name="partnerPullEnryptionAlgorithm"
                            required={true}
                          >
                            <Select disabled={isReadOnly()}>
                              <Option value="3DES">3DES</Option>
                              <Option value="AES256">AES256</Option>
                            </Select>
                          </Form_Row>
                          <Form_Row
                            isProduction={productionActive}
                            label={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-partnerPullsFromProvider-expectEerp-prompt", defaultMessage: "Will you SEND EERP" })}
                            instruction={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-partnerPullsFromProvider-expectEerp-instruction", defaultMessage: " " })}
                            name="partnerPullEERP"
                            required={false}
                            type="checkbox"
                          />
                        </tbody>
                      </table>
                    </Panel>
                    <Panel header={`${intl.formatMessage({ id: "survey-connectivityInfo-oftp2-providerPullsFromPartner-title", defaultMessage: "Service Provider pulls files from Trading Partner" })} - ${envSelected}`} key="4" forceRender={true}>
                      <table className="form--table">
                        <colgroup>
                          <col span={1} style={{ "width": "55%" }} />
                          <col span={1} style={{ "width": "20%" }} />
                          <col span={1} style={{ "width": "25%" }} />
                        </colgroup>
                        <thead>
                          <tr>
                            <th>{intl.formatMessage({ id: "survey-connectivityInfo-oftp2-parameters-title", defaultMessage: "OFTP2 Parameters" })}</th>
                            <th>{intl.formatMessage({ id: "survey-connectivityInfo-commentHeader", defaultMessage: "Comment" })}</th>
                            <th>{intl.formatMessage({ id: "survey-connectivityInfo-InstructionHeader", defaultMessage: "Instruction" })}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <Form_Row
                            isProduction={productionActive}
                            label={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-providerPullsFromPartner-frequency-prompt", defaultMessage: "Pull Frequency" })}
                            instruction={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-providerPullsFromPartner-frequency-instruction", defaultMessage: "Indicate how often the Service provider should poll you for new files." })}
                            name="providerPullFrequency"
                            required={true}
                          >
                            <Select disabled={isReadOnly()}>
                              <Option value="Daily">{intl.formatMessage({ id: "survey-connectivityInfo-oftp2-frequency-option-daily", defaultMessage: "Daily" })}</Option>
                              <Option value="Hourly">{intl.formatMessage({ id: "survey-connectivityInfo-oftp2-frequency-option-hourly", defaultMessage: "Hourly" })}</Option>
                            </Select>
                          </Form_Row>
                          <Form_Row
                            isProduction={productionActive}
                            label={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-providerPullsFromPartner-encrypt-prompt", defaultMessage: "Are Pulled Files Encrypted" })}
                            instruction={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-providerPullsFromPartner-encrypt-instruction", defaultMessage: " " })}
                            name="providerPullEnrypted"
                            required={false}
                            type="checkbox"
                          />
                          <Form_Row
                            isProduction={productionActive}
                            label={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-providerPullsFromPartner-fileSigning-prompt", defaultMessage: "Require Or Prohibet Signing Files" })}
                            instruction={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-providerPullsFromPartner-fileSigning-instruction", defaultMessage: " " })}
                            name="providerPullSigning"
                            required={true}
                          >
                            <Select disabled={isReadOnly()}>
                              <Option value="Optional">{intl.formatMessage({ id: "survey-connectivityInfo-oftp2-signing-option-optional", defaultMessage: "Optional" })}</Option>
                              <Option value="Required">{intl.formatMessage({ id: "survey-connectivityInfo-oftp2-signing-option-required", defaultMessage: "Required" })}</Option>
                              <Option value="Prohibited">{intl.formatMessage({ id: "survey-connectivityInfo-oftp2-signing-option-prohibited", defaultMessage: "Prohibited" })}</Option>
                            </Select>
                          </Form_Row>
                        </tbody>
                      </table>
                    </Panel>
                    */}
                    <Panel header={`${intl.formatMessage({ id: "survey-connectivityInfo-oftp2-partnerPushToProvider-title", defaultMessage: "Trading Partner pushes files to Service Provider" })} - ${envSelected}`} 
                            key="TradingPartnerPushPanel" 
                            forceRender={true}>
                      <table className="form--table">
                        <colgroup>
                          <col span={1} style={{ "width": "55%" }} />
                          <col span={1} style={{ "width": "20%" }} />
                          <col span={1} style={{ "width": "25%" }} />
                        </colgroup>
                        <thead>
                        <tr>
                          <th>{intl.formatMessage({ id: "survey-connectivityInfo-oftp2-parameters-title", defaultMessage: "OFTP2 Parameters" })}</th>
                          <th>{intl.formatMessage({ id: "survey-connectivityInfo-commentHeader", defaultMessage: "Comment" })}</th>
                          <th>{intl.formatMessage({ id: "survey-connectivityInfo-InstructionHeader", defaultMessage: "Instruction" })}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <Form_Row
                          isProduction={productionActive}
                          label={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-partnerPushToProvider-fileTypes-prompt", defaultMessage: "File Types" })}
                          instruction={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-partnerPushToProvider-fileTypes-instruction", defaultMessage: " " })}
                          name="partnerPushFileTypes"
                          required={true}
                        >
                          <Select disabled={isReadOnly()}>
                            <Option value="EDI">EDI</Option>
                            <Option value="NON-EDI">{intl.formatMessage({id:'survey-connectivityInfo-oftp2-non-edi'})}</Option>
                            <Option value="BOTH">{intl.formatMessage({id:'survey-connectivityInfo-oftp2-both'})}</Option>
                          </Select>
                        </Form_Row>
                        <Form_Row
                          isProduction={productionActive}
                          label={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-partnerPushToProvider-originator-prompt", defaultMessage: "Originator - for non EDI" })}
                          instruction={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-partnerPushToProvider-originator-instruction", defaultMessage: " " })}
                          name="partnerPushNonEDIOriginator"
                          required={isOriginatorForNonEDIMandatory()}
                        />
                        <Form_Row
                          isProduction={productionActive}
                          label={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-partnerPushToProvider-recipient-prompt", defaultMessage: "Recipient - for non EDI" })}
                          instruction={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-partnerPushToProvider-recipient-instruction", defaultMessage: " " })}
                          name="partnerPushNonEDIRecipient"
                          required={isRecepientForNonEDIMandatory()}
                        />
                        <Form_Row
                          isProduction={productionActive}
                          label={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-partnerPushToProvider-aprf-prompt", defaultMessage: "APRF - for non EDI" })}
                          instruction={intl.formatMessage({ id: "survey-connectivityInfo-oftp2-partnerPushToProvider-aprf-instruction", defaultMessage: " " })}
                          name="partnerPushNonEDIAPRF"
                          required={isAPRFForNonADIMandatory()}
                        />
                        <Form_Row
                          isProduction={productionActive}
                          label={intl.formatMessage({
                            id: "survey-connectivityInfo-oftp2-partnerPushToProvider-vfnSender-prompt",
                            defaultMessage: "VFN used to send"
                          })}
                          instruction={intl.formatMessage({
                            id: "survey-connectivityInfo-oftp2-partnerPushToProvider-vfnSender-instruction",
                            defaultMessage: " "
                          })}
                          name="partnerPushVFN"
                          required={false}
                        />
                        <Form_Row
                          isProduction={productionActive}
                          label={intl.formatMessage({
                            id: "survey-connectivityInfo-oftp2-providerPushToPartner-data-encoding-prompt",
                            defaultMessage: "Data Encoding"
                          })}
                          instruction={intl.formatMessage({
                            id: "survey-connectivityInfo-oftp2-providerPushToPartner-data-encoding-instruction",
                            defaultMessage: " "
                          })}
                          name="partnerPushDataEncoding"
                          required={true}
                          requiredMessage={intl.formatMessage({
                            id: "generic-requiredField-message",
                            defaultMessage: "Please enter required field"
                          })}
                        >
                          <Select disabled={isReadOnly()}>
                            <Option value="ASCII">{intl.formatMessage({id:'survey-connectivityInfo-oftp2-ascii-recommended'})}</Option>
                            <Option value="EBCDIC">EBCDIC</Option>
                          </Select>
                        </Form_Row>
                        </tbody>
                      </table>
                    </Panel>
                  </Collapse>
                </TabPane>
              </Tabs>


            </Col>
          </Row>
        {/* </Spin> */}
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit" disabled={!changed} className={"mt10"}>
                {intl.formatMessage({
                  id: "survey-connectivityInfo-oftp2-save",
                  defaultMessage: "Save OFTP2 Detail"
                })}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

    </>
  )
}

export default SurveyConnectivityOFTP2;