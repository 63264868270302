// @ts-ignore
import React, { useState } from "react";
// @ts-ignore
import { useIntl } from "react-intl";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { getGroupId } from "../../../../utils";
import { notification, Spin, Tooltip } from "antd";
import client from "../../../../config/GraphQLApolloClient";
import { GET_SURVEY_PARTNER_ACCEPT_MASTER_DATA } from "../queries";
import { getSurveyVerifyData, getVerifyReportPortion } from "../../SurveyVerifySubmit/SurveyVerifyAndSubmitService";
import { getSurveyVerifyReport } from "../../SurveyVerifySubmit/__generated__/getSurveyVerifyReport";
import reactDom from "react-dom";
import { SELECTED_GROUP_ID } from "../../../../constants";
import { ICellRendererParams } from "ag-grid-community";
import { getI18n } from "../../../../utils/Utils";
import { MODE } from "../../IStoredSurvey";
import { useAppSelector } from '../../../../main/hooks'; 


type IActionIconMasterDataCellRendererProps = {
  accept?: any;
  reject?: any;
} & ICellRendererParams

export enum MASTER_DATA_STATUS {
  NO = 1,
  YES = 2,
  QUERIED = 3,

}

const ActionIconMasterDataCellRenderer: React.FunctionComponent<IActionIconMasterDataCellRendererProps> = (props) => {
  const [loading, isLoading] = useState(false);
  const groupId = getGroupId();

  const intl = useIntl();
  const acceptButton = intl.formatMessage({
    id: "survey-masterData-grid-accept-tooltip-title",
    defaultMessage: "Accept Master Data"
  });

  const surveyState = useAppSelector((state) => state.survey);
  const isViewMode = () => {
    return surveyState.survey.surveyMode === MODE.VIEW ? true : false
  }

  const queryButton = intl.formatMessage({
    id: "survey-masterData-grid-query-tooltip-title",
    defaultMessage: "Query Master Data"
  });

  const updateMasterData = () => {
    const surveyId = props.data.surveyId;
    const surveyIds = [{ surveyId, masterDataStatusId: MASTER_DATA_STATUS.YES }];
    client.mutate({
      mutation: GET_SURVEY_PARTNER_ACCEPT_MASTER_DATA,
      variables: { surveyIds },
      fetchPolicy: "network-only"
    }).then((response) => {
      const rowData = props.data;
      const newData = { partnerNo: rowData.partnerNo, ...rowData };
      newData.masterDataAcceptedDate = "YES";
      if (props.api) {
        const rowNode = props.api.getRowNode(props.node.id);
        rowNode.setData(newData);
      }
      notification.success({
        message: intl.formatMessage({
          id: "masterdata-accept-successfully-title",
          defaultMessage: "Successfully"
        }),
        description: response.data.Portal_AcceptMasterData
      });

      Promise.resolve(getSurveyVerifyData(localStorage.getItem(SELECTED_GROUP_ID)))
        .then((response: getSurveyVerifyReport) => {
          const masterData = getVerifyReportPortion(response, 1);

          const stepDescription = Array.prototype.slice.call(document.querySelectorAll(".ant-steps-item-description"))[0];
          reactDom.render(
            <div className="ant-steps-item-description">
              {getI18n(masterData.status, "Status not decoded", intl)}
            </div>,
            stepDescription
          );
          const masterDataIssuesTranslated = [];
          masterData.issues.forEach((item) => {
            masterDataIssuesTranslated.push(getI18n(item, "Issue not decoded", intl));
          });
          reactDom.render(
            <label>{masterDataIssuesTranslated?.join(", ")}</label>,
            document.querySelector("#portionSequence-1")
          );
          isLoading(false);
        });


    }).catch((err) => {
      console.error(err);
      notification.error({
        message: intl.formatMessage({
          id: "masterdata-accept-error-title",
          defaultMessage: "Error"
        }),
        description:
          intl.formatMessage({
            id: "masterdata-accept-error-message",
            defaultMessage: "Fail to accept master data"
          })
      });
      isLoading(false);
    });
  };

  const onAccept = () => {
    isLoading(true);

    //  updateMasterData();
    Promise.resolve(getSurveyVerifyData(groupId)
      .then((response: getSurveyVerifyReport) => {
        //Get Survey master data portion
        const masterData = getVerifyReportPortion(response, 1);
        if (masterData !== undefined && masterData.issues !== undefined && masterData.canSubmit) {
          updateMasterData();
        } else if (masterData !== undefined && masterData.issues !== undefined && masterData.issues.length > 0) {
          const masterDataIssuesTranslated = [];
          masterData.issues.forEach((item) => {
            masterDataIssuesTranslated.push(getI18n(item, "Issue not decoded", intl));
          });
          notification.error({
            message: intl.formatMessage({
              id: "masterdata-accept-error-title",
              defaultMessage: "Error"
            }),
            description: masterDataIssuesTranslated.join(",")

          });
        } else {
          notification.error({
            message: intl.formatMessage({
              id: "masterdata-accept-error-title",
              defaultMessage: "Error"
            }),
            description:
              intl.formatMessage({
                id: "masterdata-accept-error-unresolved-error",
                defaultMessage: `Fail to accept master data, cannot find master data`
              })
          });
        }
        isLoading(false);
      })).catch(e => {
        console.error(e);
        notification.error({
          message: intl.formatMessage({
            id: "masterdata-accept-error-title",
            defaultMessage: "Error"
          }),
          description:
            intl.formatMessage({
              id: "masterdata-accept-error-message",
              defaultMessage: "Fail to accept master data"
            })
        });
        isLoading(false);
      }
    );
  };


  const onReject = () => {
    // @ts-ignore
    const surveyId = props.data.surveyId;

    isLoading(true);
    const surveyIds = [{ surveyId, masterDataStatusId: MASTER_DATA_STATUS.QUERIED }];
    client.mutate({
      mutation: GET_SURVEY_PARTNER_ACCEPT_MASTER_DATA,
      variables: { surveyIds },
      fetchPolicy: "network-only"
    }).then((response) => {
      notification.success({
        message: intl.formatMessage({
          id: "masterdata-accept-successfully-title",
          defaultMessage: "Successfully"
        }),
        description: response.data.Portal_AcceptMasterData
      });

      isLoading(false);
    }).catch((err) => {
      console.error(err);
      notification.error({
        message: intl.formatMessage({
          id: "masterdata-query-error-title",
          defaultMessage: "Error"
        }),
        description:
          intl.formatMessage({
            id: "masterdata-query-error-message",
            defaultMessage: "Fail to query master data"
          })
      });
      isLoading(false);
    });
  };

  return (
    <>
      <Spin spinning={loading} size={"small"}>
        <span hidden={isViewMode()}>
          <span style={{ marginRight: "30px" }}>
            <Tooltip title={queryButton}>
            <CloseOutlined onClick={onReject} style={{ fontSize: "15px", cursor: "pointer" }} />
              </Tooltip>
          </span>
          <span style={{ marginRight: "10px" }}>
            <Tooltip title={acceptButton}>
            <CheckOutlined onClick={onAccept} style={{ fontSize: "15px", cursor: "pointer" }} />
            </Tooltip>
          </span>
        </span>
      </Spin>
    </>
  );
};

export default ActionIconMasterDataCellRenderer;