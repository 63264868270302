

//Document Date formatter helper
function documentDateFormatter(params) {
    console.log("Date formatter");
    console.dir(params);
    if (params.data !== undefined && params.data.documentDate !== undefined)
    {
        const dateAsString = params.data.documentDate;
    const dateParts = dateAsString.split('/');
    return `${dateParts[0]} - ${dateParts[1]} - ${dateParts[2]}`;
    }
    return undefined;
    
}

//Insert Time formatter helper
function insertTimeFormatter(params) {
    console.log("Date formatter");
    console.dir(params);
    if (params.data !== undefined && params.data.insertTime !== undefined)
    {
        const dateAsString = params.data.insertTime;
        const dateParts = dateAsString.split('/');
        return `${dateParts[0]} - ${dateParts[1]} - ${dateParts[2]}`;
    }
    return undefined;
    
}

//Date Received formatter helper
function dateReceivedFormatter(params) {
    console.log("Date formatter");
    console.dir(params);
    if (params.data !== undefined && params.data.insertTime !== undefined)
    {
        const dateAsString = params.data.insertTime;
    const dateParts = dateAsString.split('/');
    return `${dateParts[0]} - ${dateParts[1]} - ${dateParts[2]}`;
    }
    return undefined;
    
}

// HELPER FOR DATE COMPARISON
function _monthToNum(date) {
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }

    const yearNumber = date.substring(6, 10);
    const monthNumber = date.substring(3, 5);
    const dayNumber = date.substring(0, 2);

    const result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    // 29/08/2004 => 20040829
    return result;
}

// DATE COMPARATOR FOR SORTING
function dateComparator(date1, date2) {
    const date1Number = _monthToNum(date1);
    const date2Number = _monthToNum(date2);

    if (date1Number === null && date2Number === null) {
        return 0;
    }
    if (date1Number === null) {
        return -1;
    }
    if (date2Number === null) {
        return 1;
    }

    return date1Number - date2Number;
}

function comparatorFunc(filterLocalDateAtMidnight, cellValue)  {
    if (cellValue == null) {
        return 0;
    }
    const dateParts = cellValue.split('/');
    const year = Number(dateParts[2]);
    const month = Number(dateParts[1]) - 1;
    const day = Number(dateParts[0]);
    const cellDate = new Date(year, month, day);

    if (cellDate < filterLocalDateAtMidnight) {
        return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
    }
    return 0;
}

//Its important to use camel case for the field name that matches the equivalent snake case variable used in the DB table else
//sorting and filtering would not work when using the server side data model
//Please see the AG grid documentation for what setting to use for the "filter" setting.
//eg.  agNumberColumnFilter for numbers or agTextColumnFilter for string values
export const INCOMING_DOCS_DETAILS_COLS = [
    {
        headerName: 'Document incoming id',
        field: 'id',
        colId: 'd.ec_Document_Id',
        checkboxSelection: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true,
        },
    },
    {
        headerName: 'Processed',
        field: 'processingStatusFlag',
        colId: 'd.Processing_Status_Flag',
        filter: 'agTextColumnFilter',
    },
    //  {
    //     headerName: 'Process code Id',
    //     field: 'processCodeId',
    //     filter: 'agTextColumnFilter',
    //     filterParams: {
    //         buttons: ['reset'],
    //         debounceMs: 1000,
    //         suppressAndOrCondition: true,
    //     },
    // },
    {
        headerName: 'Document No',
        field: 'documentNumber',
        colId: 'd.document_Number',
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true,
        },
    },
    {
        headerName: 'Document Date',
        field: 'documentDate',
        colId: 'd.document_Date',
        comparator: dateComparator,
        filter: 'agDateColumnFilter',
        floatingFilter: true,
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        filterParams: {
            debounceMs: 500,
            suppressAndOrCondition: true,
            comparator: comparatorFunc
             ,
        },
        cellRenderer: (params) => {
            return params.value ? params.value.substring(0, 10) : '';
        },
    },
    { headerName: 'Gross Amount', field: 'grossAmount', filter: false },
    { headerName: 'Tax Amount', field: 'taxAmount', filter: false },
    {
        headerName: 'Message Country Code',
        field: 'messageCountryCode',
        colId: 'd.message_Country_Code',
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true,
        },
    },
    {
        headerName: 'Message File Name',
        field: 'messageFileName',
        colId: 'd.Message_File_Name',
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true,
        },
    },
    {
        headerName: 'Message Id',
        field: 'messageId',
        colId: 'd.Message_Id',
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true,
        },
    },
    {
        headerName: 'Insert time',
        field: 'insertTime',
        colId: 'd.Insert_Time',
        comparator: dateComparator,
        filter: 'agDateColumnFilter',
        floatingFilter: true,
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        filterParams: {
            debounceMs: 500,
            suppressAndOrCondition: true,
            comparator: comparatorFunc
             ,
        },
        cellRenderer: (params) => {
            return params.value ? params.value.substring(0, 10) : '';
        },
    },
    {
        headerName: 'Last Response File',
        field: 'lastUploadedFileName',
        colId: 'b.file_name',
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true,
        },
    },
    {
        headerName: 'Last Response File time',
        field: 'lastUploadedFileTime',
        colId: 'b.insert_time',
        comparator: dateComparator,
        filter: 'agDateColumnFilter',
        floatingFilter: true,
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        filterParams: {
            debounceMs: 500,
            suppressAndOrCondition: true,
            comparator: comparatorFunc
            ,
        },
        cellRenderer: (params) => {
            return params.value ? params.value.substring(0, 10) : '';
        },
    }
];


export const PENDING_DOCS_COLS = [
    {
        headerName: 'Document id',
        field: 'documentId',
        checkboxSelection: true,
        headerCheckboxSelection: false,
        filter: true,
    },
    { headerName: 'Log object id', field: 'logObjectId', filter: false },
    { headerName: 'Partner no', field: 'partnerno', filter: false },
    { headerName: 'Process code', field: 'processCode', filter: false },
    { headerName: 'Reference', field: 'reference', filter: false },
    { headerName: 'Date received', field: 'dateReceived', filter: false },
    { headerName: 'Download link', field: 'downloadLink', filter: false },
    { headerName: 'Downloaded', field: 'downloaded', filter: false },

    { headerName: 'Quantity', field: 'quantity', filter: false },
    { headerName: 'Amount', field: 'amount', filter: false },
    { headerName: 'Currency', field: 'currency', filter: false },
    { headerName: 'Blob id', field: 'blobId', filter: false },
    { headerName: 'Status', field: 'status', filter: false },
    { headerName: 'Area', field: 'area', filter: false },
    { headerName: 'File name', field: 'fileName', filter: false },
    { headerName: 'File prefix', field: 'filePrefix', filter: false },

    { headerName: 'Insert user', field: 'insertUser', filter: false },
    { headerName: 'Update user', field: 'updateUser', filter: false },

    {
        headerName: 'Insert ts',
        field: 'insertTs',
        filter: false,
        cellRenderer: (params) => {
            return params.value ? params.value.substring(0, 16) : '';
        },
    },
    {
        headerName: 'Updatetime',
        field: 'updatetime',
        filter: false,
        cellRenderer: (params) => {
            return params.value ? params.value.substring(0, 16) : '';
        },
    },
];
