import React from 'react'
import dayjs from 'dayjs';
import { Link } from 'react-router-dom'
import { FormattedDate, FormattedTime } from 'react-intl'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Button, Tag } from 'antd'

interface IProps {
    record: any;
    renderAs: 'checked' | 'date' | 'bold' | 'dateTime' | 'link' | 'tag' | 'tags' | 'button';
}

/**
 * Usage:
 *
 *  To render an IntegrateTable-Cell as a date, make sure to define your columns in a ".tsx" file.
 *  The following imports are necessary:
 *
 *      import React from 'react';
 *      import {CustomRender} from 'api/__to_refactor/client/components';
 *
 *  The best way is no to define a constant for this renderer:
 *
 *      const renderAsDate = (record) => <CustomRender record={record} renderAs="date" />;
 *
 *  Now use this constant in the columns as render:
 *
 *      ...{
 *          key: 'ats',
 *          title: 'ATS',
 *          dataIndex: 'ats',
 *          render: renderAsDate,
 *      },...
 */

export class CustomRender extends React.Component<IProps, {}> {
  public render () {
    const { record, renderAs } = this.props

    switch (renderAs) {
      case 'date':
        return record ? <span>{dayjs(new Date(record)).format('YYYY-MM-DD')}</span> : null
      case 'bold':
        return <b>{record}</b>
      case 'tag':
        return record ? <Tag key={record}>{record}</Tag> : null
      case 'link':
        return record.path && record.title ? <Link to={record.path}>{record.title}</Link> : null
      case 'checked':
        return record ? <CheckOutlined/> : <CloseOutlined/>
      case 'button':
        return record
          ? (
                    <Button type="primary" htmlType="button" size={'small'}>
                        {record}
                    </Button>


            )
          : null
      case 'tags':
        return record && Array.isArray(record)
          ? record.map((x) => (
                        <Tag style={{ margin: '1px' }} key={x}>
                            {x}
                        </Tag>
          ))
          : null
      case 'dateTime':
        return record
          ? (
                    <span>
                        {/*<FormattedDate value={record}/> <FormattedTime value={record}/>*/}
                      {dayjs(new Date(record)).format("YYYY-MM-DD  hh:mm:ss")}
                    </span>
            )
          : null
    }
  }
}
