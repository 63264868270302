import { IAppComponentProps } from "../../components";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, DatePicker, Form, Input, notification, Row, Space, Tooltip } from "antd";
import { useIntl } from "react-intl";
import { DeleteOutlined, EditOutlined, FolderOutlined, FolderViewOutlined, FormOutlined, ArrowLeftOutlined  } from "@ant-design/icons";
import { getI18n } from "../../utils/Utils";
import dayjs from 'dayjs';
import RenderIfEntitled from "../../components/EntitlementComponents";
import { SurveyCreateRequest, SurveyOverviewFilter } from "__generated__/globalTypes";
import { createSurveyMutation, getSurveyOverviewData } from "./SurveyUtils";
import { PartnerLookup } from "../../components/PartnerLookup";
import { SurveyStatus } from "../../components/SurveyStatus";
import { CompanyLookup } from "../../components/CompanyLookup";
import { REMOVE_SELECTED_PARTNER_TO_SURVEY_PROCESS } from "./surveyMasterData/queries";
import { showErrorNotification } from "../Notification";
import client from "../../config/GraphQLApolloClient";
import { addSelectedSurveyMasterData, getSelectedSurveyMasterData } from "./surveyMasterData/SurveyMasterDataUtils";
import { PARTNER_TYPE, SELECTED_GROUP_ID } from "../../constants";
import _ from "lodash";
import { SelectedSurveyGroupID } from "./SurveyOverview";
import { useAppDispatch, useAppSelector } from '../../main/hooks';
import { MODE } from "./IStoredSurvey";
import { DESTROY_SURVEY_OVERVIEW, destroyState, resetSurveyDataLoading, setSurveyDataLoading, setSurveyMode, updateSurveyOverviewCriteria } from "./IStoredSurveySlice";
import {getDateFormatOrDefault} from "../users/UserFormatPreferenceServices";
import {useNavigate} from "react-router-dom";

type SurveyOverviewSearchFormProps = {
  setSurveyOverviewData: any;
  onRefresh: any;
  selectedSurveyIds: any;
} & IAppComponentProps;

const sizes: React.CSSProperties = {
  height: "100%",
  width: "98%"
};

const SurveyOverviewSearchForm: React.FunctionComponent<SurveyOverviewSearchFormProps> = (props) => {

  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const intl = useIntl();
  const navigate = useNavigate();
  const Survey_Overview_Entitlement = "display@survey";
  const [partnerNo, setPartnerNo] = useState(undefined);
  const [partnerType, setPartnerType] = useState<string>();
  const surveyState = useAppSelector((state) => state.survey);
  
  const isViewMode = () => {
    return surveyState.survey.surveyMode === MODE.VIEW ? true : false
  }
  
  const isOverViewMode = () => {
    return surveyState.survey.surveyMode === MODE.OVERVIEW ? true : false
  }

  const isReadOnly = () => {
    return isViewMode() || isOverViewMode();
   }

  const completedSurveyError = intl.formatMessage({
    id: "survey-overview-completed-error",
    defaultMessage:
      "This Survey is COMPLETED/LIVE and NO edit is possible.  To display the Survey, select the VIEW icon"
  });

  const notStartedSurveyError = intl.formatMessage({
    id: "survey-overview-not-started-error",
    defaultMessage:
      "This Survey was not Created yet and NO edit is possible.  To Request the Survey, select the Request Survey icon"
  });

  const notStartedAndNewSurveyCountError = intl.formatMessage({
    id: "survey-overview-maximum-surveys-error",
    defaultMessage:
        "You are restricted to a maximum of 15 records per Bulk Survey."
  });

  const mixedSurveyError = intl.formatMessage({
    id: "survey-overview-mixed-agreement-error",
    defaultMessage:
      "These eInvoice Agreements are not compatible, please only select entries with the same eInvoice Agreement."
  });
  const selectSurveyError = intl.formatMessage({
    id: "survey-overview-Selection-error",
    defaultMessage: "Please select Survey before you continue"
  });
  const selectCustomerPartnerSurveyError = intl.formatMessage({
    id: "survey-overview-Customer-Partner-Selection-error",
    defaultMessage: "Suppliers and Customers can not be on the same survey"
  });


  const startOrEditSurveyTooltipTitle = intl.formatMessage({
    id: "survey-overview-startOrEdit-tooltip-title",
    defaultMessage: "Complete or Edit Survey"
  });

  const viewSurveyTooltipTitle = intl.formatMessage({
    id: "survey-overview-view-tooltip-title",
    defaultMessage: "View Survey"
  });

  const cancelSurveyTooltipTitle = intl.formatMessage({
    id: "survey-overview-cancel-overview-tooltip-title",
    defaultMessage: "Return to Overview"
  });

  const resetSurveyTooltipTitle = intl.formatMessage({
    id: "survey-overview-reset-tooltip-title",
    defaultMessage: "Reset Survey"
  });

  const removeSurveyTooltipTitle = intl.formatMessage({
    id: "survey-overview-remove-tooltip-title",
    defaultMessage: "Remove Survey"
  });

  const changeSurveyTooltipTitle = intl.formatMessage({
    id: "survey-overview-change-tooltip-title",
    defaultMessage: "Survey Change Request"
  });

  const requestSurveyTooltipTitle = intl.formatMessage({
    id: "survey-overview-request-tooltip-title",
    defaultMessage: "Request Survey"
  });

  const fetchSurveyData = (surveyOverviewFilter: SurveyOverviewFilter) => {
    //
    console.dir(`surveyOverviewFilter : ${surveyOverviewFilter}`);
    dispatch(updateSurveyOverviewCriteria(surveyOverviewFilter));
    //
    dispatch(setSurveyDataLoading(true));
    Promise.resolve(getSurveyOverviewData(surveyOverviewFilter)).then((data) => {
      //props.setSurveyLoading(false);
      props.setSurveyOverviewData(data);
    })
    .catch(() => {
      dispatch(resetSurveyDataLoading(true));
    })
    .finally(() => {
      dispatch(setSurveyDataLoading(false));
    });
  };

  const onSearch = async (partnerType: string) => {

    //console.log(`PartnerType: ${partnerType}`);
    //props.setSurveyLoading(true);
    const values = { ...form.getFieldsValue() };
    // If survey status id is 12 (No Started) then make sure company code was also selected
    //console.log(`Status code: ${values.surveyStatus}`);
    //console.log(`Company Code: ${values.companyCode}`);
    if (values.surveyStatus === "12" && (values.companyCode === undefined || values.companyCode === null)) {
      notification.info({ message: getI18n("Survey-overview-company-code-required", "company Code is required when searching for 'NOT STARTED' records", intl) });
    } else {
      const surveyOverviewFilter: SurveyOverviewFilter = {};
      //console.log(`From date: ${values.fromDate}`);
      //console.log(`To date: ${values.toDate}`);
      if (values.fromDate !== null && values.fromDate !== undefined && !_.isEmpty(values.fromDate)) {
        surveyOverviewFilter.fromDate = dayjs(values.fromDate).format("YYYY-MM-DD HH:mm:s");
      }
      if (values.toDate !== null && values.toDate !== undefined && !_.isEmpty(values.toDate)) {
        surveyOverviewFilter.toDate = dayjs(values.toDate).format("YYYY-MM-DD HH:mm:s");
      }
      surveyOverviewFilter.companyCode = values.companyCode;
      surveyOverviewFilter.partnerNo = values.partnerNo;
      surveyOverviewFilter.surveyStatusId = Number.parseInt(values.surveyStatus);
      surveyOverviewFilter.userId = values.userId;
      surveyOverviewFilter.partnerType = partnerType;
      fetchSurveyData(surveyOverviewFilter);
    }
  };

  const handlePartnerChange = (value: string) => {
    //console.log(`Selected supplier: ${value}`);
    setPartnerNo(value);
  };

  const fetchCustomer = async () => {
    setPartnerType("C");
    onSearch("C");
  };

  const fetchSuppliers = async () => {
    setPartnerType("V");
    onSearch("V");
  };

  const setSelectedSurveys = (surveyMode: MODE) => {
    const readOnly: boolean = surveyMode === MODE.OVERVIEW || surveyMode === MODE.VIEW;
    const completedSurvey = props.selectedSurveyIds.filter(
      (item) => item.surveyStatusId === 10 || item.surveyStatusId === 9
    );

    const notStartedCount = props.selectedSurveyIds.filter(
      (item) => item.surveyStatusId === 12
    );

    const notStartableSurveyCount = props.selectedSurveyIds.filter(
      (item) => item.surveyStatusId !== 3 && item.surveyStatusId !== 4
    );

    const newNotStartedCount = props.selectedSurveyIds.filter(
        (item) => item.surveyStatusId === 12 || item.surveyStatusId === 3
    ).length;

    //console.log(`not Startable Count: ${notStartableSurveyCount}`);
    if (notStartableSurveyCount !== undefined && notStartableSurveyCount.length > 0 && !readOnly) {
      showErrorNotification(getI18n("survey-overview-not-correct-state-to-start", "COMPLETE OR EDIT SURVEY can only be executed on a MD Record with a status of NEW or IN PROGRESS", intl));
      return;
    }

    if (newNotStartedCount>15) {
      showErrorNotification(notStartedAndNewSurveyCountError);
      return;
    }
    //console.log(`notStartedCount: ${notStartedCount}`);

    let isUniqueAgreement = true;
    let agreementTypeId = undefined;
    props.selectedSurveyIds.map((item) => {
      if (agreementTypeId === undefined) {
        agreementTypeId = item.agreementTypeId;
      } else if (item.agreementTypeId !== agreementTypeId) {
        isUniqueAgreement = false;
      }
    });
    if (notStartedCount.length > 0 && !readOnly) {
      showErrorNotification(notStartedSurveyError);
    } else if (newNotStartedCount>15) {
      showErrorNotification(notStartedAndNewSurveyCountError);
    } else if (completedSurvey.length > 0 && !readOnly) {
      showErrorNotification(completedSurveyError);
    } else if (!isUniqueAgreement && !readOnly) {
      showErrorNotification(mixedSurveyError);
    } else if (props.selectedSurveyIds.length === 0) {
      showErrorNotification(selectSurveyError);
    } else {
      const totalSupplier = _.filter(props.selectedSurveyIds, (item) => item.categoryCode === "V").length;
      const totalCustomer = _.filter(props.selectedSurveyIds, (item) => item.categoryCode === "C").length;
      if (totalCustomer > 0 && totalSupplier > 0) {
        showErrorNotification(selectCustomerPartnerSurveyError);
      } else {
        if (totalCustomer === props.selectedSurveyIds.length) {
          localStorage.setItem(PARTNER_TYPE, "C");
        }
        if (totalSupplier === props.selectedSurveyIds.length) {
          localStorage.setItem(PARTNER_TYPE, "V");
        }
        if (props.selectedSurveyIds && Array.isArray(props.selectedSurveyIds)) {
          // props.setSurveyLoading(true);
          const surveyMasterDataInput = [];
          let groupID = undefined;
          props.selectedSurveyIds
            .filter((item) => item.groupID === null)
            .forEach((item) => {
              item.includedSurveyIds
                ?.split(",")
                .map((surveyId) => surveyMasterDataInput.push({ surveyId: Number(surveyId) }));
              if (surveyMasterDataInput.length === 0) {
                surveyMasterDataInput.push({ surveyId: Number(item.surveyId) });
              }
            });
          const groupIds = props.selectedSurveyIds.filter(
            (item) => item.groupID !== null || item.groupID !== undefined
          );
          groupID = groupIds.length > 0 ? groupIds[0].groupID : undefined;

          //when NOT in read only mode then create survey or add more surveys to group id
          //console.log(`isReadOnly: ${readOnly}`);
          if (!readOnly) {
            //console.log(`Go to survey master data :edit mode`);
            dispatch(setSurveyDataLoading(true));
            Promise.resolve(addSelectedSurveyMasterData(groupID, surveyMasterDataInput)).then((id) => {
              // props.setSurveyLoading(false);
              localStorage.setItem(SELECTED_GROUP_ID, id);
              navigate("/survey/survey-master-data");
            }).catch(() => {
              dispatch(resetSurveyDataLoading(true));
            })
            .finally(() => {
              dispatch(setSurveyDataLoading(false));
            });
          }
          else if (groupID === undefined || groupID === null || groupID === "") {
            //props.setSurveyLoading(false);
            notification.error({
              message: "ERROR",
              description: intl.formatMessage({
                id: "survey-overview-error-cannotView-survey",
                defaultMessage: "You cannot view a survey that was not created yet"
              })
            });
          } else {
            //console.log("Go to survey master data (view mode)")
            // props.setSurveyLoading(false);
            localStorage.setItem(SELECTED_GROUP_ID, groupID);
            navigate("/survey/survey-master-data");
            // Promise.resolve(getSelectedSurveyMasterData(groupID, surveyMasterDataInput)).then((id) => {
            //     props.setSurveyLoading(false);
            //     localStorage.setItem(SELECTED_GROUP_ID, id);
            //     navigate("/survey/survey-master-data");
            //   });
          }
        }
      }
    }
  };

  const createSurvey = async (action) => {
    dispatch(setSurveyDataLoading(true));
    // props.setSurveyLoading(true);
    //console.log("Selected Survey Ids:");
    const selectedRecords: SelectedSurveyGroupID[] = props.selectedSurveyIds;
    // console.dir(selectedRecords);
    const surveyCreateRequests: SurveyCreateRequest[] = [];
    let statusCode = 12;
    if (action === "Change") {
      statusCode = 9;
    }
    selectedRecords.filter((item) => item.surveyStatusId === statusCode).forEach((item) => {
      const surveyCreateRequest: SurveyCreateRequest = {};
      surveyCreateRequest.category = item.categoryCode;
      surveyCreateRequest.companyCode = item.companyCode;
      surveyCreateRequest.partnerNo = item.partnerNo;
      surveyCreateRequest.systemCode = item.systemCode; //this is not required according to Adri
      surveyCreateRequest.surveyTypeId = 1; //EINV default
      surveyCreateRequest.groupId = item.groupID;
      surveyCreateRequest.surveyStatusId = item.surveyStatusId;
      surveyCreateRequests.push(surveyCreateRequest);
    });
    // console.dir(surveyCreateRequests);
    if (surveyCreateRequests.length > 0) {
      Promise.resolve(createSurveyMutation(surveyCreateRequests)).then((surveyResponse) => {
        //console.log("Created Survey response");
        //console.dir(surveyResponse);
        // props.setSurveyLoading(false);
        let error = false;
        let errorText = "";
        if (Array.isArray(surveyResponse)) {
          surveyResponse.forEach((surveyItem) => {
            if (surveyItem.success === "0") {
              error = true;
              errorText = errorText.concat(" - \n").concat(surveyItem.errorText);
            }
          });
        }
        console.error(errorText);
        if (!error || errorText === "") {
          notification.info({ message: getI18n("Survey-overview-create-survey-success", "Survey created successfully", intl) });
          onSearch(partnerType);
        } else {
          notification.error({ message: errorText });
        }


      }).catch((error) => {
        console.error(error);
        // props.setSurveyLoading(false);
        dispatch(resetSurveyDataLoading(true));
        notification.error({ message: getI18n("Survey-overview-create-survey-error", "Backend error, Unable to create Survey", intl) });
      })
      .finally(() => {
        dispatch(setSurveyDataLoading(false));
      });
    } else {
      dispatch(resetSurveyDataLoading(true));
      // props.setSurveyLoading(false);
      if (action === "Change") {
        notification.error({ message: getI18n("Survey-overview-create-survey-invalid-status-change", "CHANGE REQUEST ON SURVEY can only be executed on a MD Record with a status of LIVE", intl) });
      } else {
        notification.error({ message: getI18n("Survey-overview-create-survey-invalid-status", "REQUEST SURVEY can only be executed on a MD Record with a status of NOT STARTED", intl) });
      }

    }


  };

    const showAll = async () => {
        // props.setSurveyLoading(true);
        const surveyOverviewFilter: SurveyOverviewFilter = {};
        fetchSurveyData(surveyOverviewFilter);
    };

  const startSurvey = () => {
    if (!isOverViewMode())
    {
      dispatch(setSurveyMode(MODE.EDIT));
    }
    setSelectedSurveys(MODE.EDIT);
  };

  const viewSurvey = () => {
    if (!isOverViewMode())
    {
      dispatch(setSurveyMode(MODE.VIEW));
    }
    setSelectedSurveys(MODE.VIEW);
  };

  const cancelOverviewSurvey = () => {
    dispatch(destroyState(DESTROY_SURVEY_OVERVIEW));
    navigate("/survey/survey-management");
  }

  const changeSurvey = async () => {
    createSurvey("Change");
  };

  const requestSurvey = async () => {
    createSurvey("Create");
  };


  const removeSurvey = async () => {

    if (props.selectedSurveyIds && Array.isArray(props.selectedSurveyIds)) {
      if (props.selectedSurveyIds.length === 0) {
        showErrorNotification(getI18n("Survey-overview-select-survey-error", "Please select Survey before you continue", intl));
        return;
      }

      let notInProgressSurveys = false;
      props.selectedSurveyIds.forEach((item) => {
        if (![4,3].includes(item.surveyStatusId)) {
          notInProgressSurveys = true;
        }
      });
      if (notInProgressSurveys) {
        showErrorNotification(getI18n("Survey-overview-remove-survey-not-possible", "REMOVE SURVEY can only be executed on a record with a status that is not Completed or Live", intl));
        return;
      }

      // props.setSurveyLoading(true);
      dispatch(setSurveyDataLoading(true));
      const cancelSurveyInput = [];
      await props.selectedSurveyIds.forEach((item) => cancelSurveyInput.push({ surveyId: Number(item.surveyID) }));

      client
        .mutate({
          mutation: REMOVE_SELECTED_PARTNER_TO_SURVEY_PROCESS,
          variables: { surveyIds: cancelSurveyInput },
          fetchPolicy: "network-only"
        })
        .then((response) => {
          notification.success({
            message: "SUCCESSFULLY",
            description: response.data?.Portal_RemoveSurvey
          });
        }).then(_ =>    fetchSurveyData(form.getFieldsValue()))
        .catch((err) => {
          dispatch(resetSurveyDataLoading(true));
          console.error(err);
          showErrorNotification(`Error ${err}`);
        })
        .finally(() => {
          dispatch(setSurveyDataLoading(false));
        });
    }
  };

  useEffect(() => {

    const surveyOverviewCriteria: SurveyOverviewFilter =  surveyState.survey.surveyOverviewCriteria;
    
    if (surveyOverviewCriteria !== undefined  && surveyState.survey.surveyMode !== MODE.OVERVIEW) {
        //console.log("set search criteria on survey overview");
        //console.dir(surveyOverviewCriteria);
        let fromDate;
        let toDate;
        let surveyStatus: string;
        if (surveyOverviewCriteria.fromDate !== undefined)
        {
          fromDate = dayjs(surveyOverviewCriteria.fromDate);
        }
        if (surveyOverviewCriteria.toDate !== undefined)
        {
          toDate = dayjs(surveyOverviewCriteria.toDate);
        }
        if (surveyOverviewCriteria.surveyStatusId > 0)
        {
          surveyStatus = surveyOverviewCriteria.surveyStatusId.toString();
        }
        form.setFieldsValue({companyCode: surveyOverviewCriteria.companyCode
            ,partnerNo: surveyOverviewCriteria.partnerNo
            ,surveyStatus
            ,userId: surveyOverviewCriteria.userId
            ,fromDate
            ,toDate
      });
      if (surveyState.survey.managementSearchCriteria !==  undefined && surveyState.survey.managementSearchCriteria.partnerNo !== undefined)
      {
        setPartnerNo(surveyState.survey.managementSearchCriteria.partnerNo);
      }
      
      //ensure we reset view mode
      if (surveyState.survey.surveyMode === MODE.VIEW)
      {
        dispatch(setSurveyMode(undefined));
      }
      
      props.onRefresh();
    }

    //This is supposed to be called when the component unmounts but it does not work as expected
    // return () : void => {
    //     console.log("Destroy survey state");
    //     dispatch(destroyState(DESTROY_SURVEY_OVERVIEW));
    // }
}, []);

  return (
    <>
      <Form
        size={"middle"}
        layout={"vertical"}
        form={form}
        onFinish={onSearch}
        autoComplete="off"
        className={"mt100"}
        
      >
        <div hidden={isOverViewMode()}>
          <RenderIfEntitled entitlementToCheck="edp@systemadmin" intl={props.intl}
                            distinctEntitlements={props.distinctEntitlements}>

              <Row gutter={24} className={"mt100"}>
                <PartnerLookup
                  intl={props.intl}
                  entitlement={Survey_Overview_Entitlement}
                  onChange={handlePartnerChange}
                  currentUser={props.currentUser}
                  distinctEntitlements={props.distinctEntitlements}
                  colSpan={7}
                />
                <Col span={3}>
                  <Form.Item
                    name={"fromDate"}
                    key={"fromDate"}
                    label={intl.formatMessage({
                      id: "survey-overview-from-date-label",
                      defaultMessage: "Date From"
                    })}
                  >
                    <DatePicker
                      format={getDateFormatOrDefault(props.currentUser)}
                      placeholder={getI18n(
                        "survey_overview-from-date-placeholder",
                        "Survey From Date",
                        intl
                      )}
                      style={{ width: 220 }}
                      allowClear={true}
                      data-testid={"fromDate"}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      disabledDate={(current) =>
                        current.isAfter(dayjs().subtract(0, "day"))
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item
                    name={"toDate"}
                    key={"toDate"}
                    label={intl.formatMessage({
                      id: "survey-overview-to_date-label",
                      defaultMessage: "Date To"
                    })}
                  >
                    <DatePicker
                      format={getDateFormatOrDefault(props.currentUser)}
                      placeholder={getI18n(
                        "survey_overview-date-to-placeholder",
                        "Survey To Date",
                        intl
                      )}
                      style={{ width: 220 }}
                      allowClear={true}
                      data-testid={"toDate"}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      disabledDate={(current) =>
                        current.isBefore(dayjs().subtract(3, "months")) ||
                        current.isAfter(dayjs().subtract(0, "day"))
                      }
                    />
                  </Form.Item>
                </Col>
                <SurveyStatus intl={props.intl} currentUser={props.currentUser} />
                <CompanyLookup
                  intl={props.intl}
                  currentUser={props.currentUser}
                  entitlement={Survey_Overview_Entitlement}
                  distinctEntitlements={props.distinctEntitlements}
                  colSpan={5}
                />
                <Col span={7}>
                  <Form.Item
                    name={"userId"}
                    key={"userId"}
                    label={intl.formatMessage({
                      id: "survey-overview-user-id-label",
                      defaultMessage: "Simulation External Id"
                    })}
                  >
                    <Input allowClear={true}
                          placeholder={intl.formatMessage({
                            id: "survey-overview-user-id-placeholder",
                            defaultMessage: "Enter the User id for data retrieval"
                          })}
                    />
                  </Form.Item>
                </Col>
              </Row>
          </RenderIfEntitled>
        </div>


          <div hidden={isOverViewMode()}  className={"mt50"}>
            <Row gutter={8}>
              <RenderIfEntitled entitlementToCheck="edp@systemadmin" intl={props.intl}
                                distinctEntitlements={props.distinctEntitlements}>
                <Col xs={24} sm={6} md={4} className="mb5 mt5 ">
                  <Button
                    type="primary"
                    size="middle"
                    className="w-100 mt10"
                    onClick={() => {
                      setPartnerType(null)
                      onSearch(null)}
                    }
                    // loading={props.surveyLoading}
                  >
                    {intl.formatMessage({
                      id: "survey-overview-search",
                      defaultMessage: "Search"
                    })}
                  </Button>
                </Col>
              </RenderIfEntitled>
              <Col xs={24} sm={6} md={4} className="mb5 mt5 ">
                <Button
                  type="primary"
                  size="middle"
                  className="w-100 mt10"
                  onClick={fetchCustomer}
                  // loading={props.surveyLoading}
                >
                  {intl.formatMessage({
                    id: "survey-overview-CustomerOnly",
                    defaultMessage: "Customer Only"
                  })}
                </Button>
              </Col>
              <Col xs={24} sm={6} md={4} className="mb5 mt5 ml5">
                <Button
                  type="primary"
                  size="middle"
                  className="w-100 mt10"
                  // loading={props.surveyLoading}
                  onClick={fetchSuppliers}
                >
                  {intl.formatMessage({
                    id: "survey-overview-SupplierOnly",
                    defaultMessage: "Supplier Only"
                  })}
                </Button>
              </Col>
              <Col xs={24} sm={6} md={4} className="mb5 mt5 ml5">
                <Button
                  type="primary"
                  size="middle"
                  className="w-100 mt10"
                  // loading={props.surveyLoading}
                  onClick={showAll}
                >
                  {intl.formatMessage({
                    id: "survey-overview-showAll",
                    defaultMessage: "Show All"
                  })}
                </Button>
              </Col>
            </Row>
          </div>
          <Row gutter={23} justify="start">
            <Col xs={24} sm={6} md={4} className="mb5 mt5">
              <Space align={"start"}>
                <div hidden={isOverViewMode()}>
                  <RenderIfEntitled
                      entitlementToCheck="complete@survey"
                      intl={intl}
                      distinctEntitlements={props.distinctEntitlements}
                  >

                    <Tooltip title={requestSurveyTooltipTitle} key={"requestSurvey"}>
                      <FolderOutlined
                          style={{fontSize: "25px", color: "#444444"}}
                          onClick={requestSurvey}
                      />
                    </Tooltip>

                  </RenderIfEntitled>
                </div>
                <div hidden={isOverViewMode()}>
                  <RenderIfEntitled
                      entitlementToCheck="complete@survey"
                      intl={intl}
                      distinctEntitlements={props.distinctEntitlements}
                  >

                    <Tooltip
                        title={startOrEditSurveyTooltipTitle}
                        key={"editStartSurvey"}
                    >
                      <EditOutlined
                          style={{
                            fontSize: "25px",
                            color: "#444444",
                            cursor: "pointer"
                          }}
                          onClick={startSurvey}
                      />
                    </Tooltip>

                  </RenderIfEntitled>
                </div>
                <div hidden={isOverViewMode()}>
                  <RenderIfEntitled
                      entitlementToCheck="complete@survey"
                      intl={intl}
                      distinctEntitlements={props.distinctEntitlements}
                  >

                    <Tooltip title={changeSurveyTooltipTitle} key={"changeSurvey"}>
                      <FormOutlined
                          style={{fontSize: "25px", color: "#444444"}}
                          onClick={changeSurvey}
                      />
                    </Tooltip>

                  </RenderIfEntitled>
                </div>
                <RenderIfEntitled
                    entitlementToCheck="display@survey"
                    intl={intl}
                    distinctEntitlements={props.distinctEntitlements}
                >
                  <Tooltip title={viewSurveyTooltipTitle} key={"viewSurvey"}>
                    <FolderViewOutlined
                        style={{fontSize: "25px", color: "#444444"}}
                        onClick={viewSurvey}
                    />
                  </Tooltip>
                </RenderIfEntitled>
                <div hidden={!isOverViewMode()}>
                  <RenderIfEntitled
                      entitlementToCheck="display@survey"
                      intl={intl}
                      distinctEntitlements={props.distinctEntitlements}
                  >
                    <Tooltip title={cancelSurveyTooltipTitle} key={"cancelOverviewMode"}>
                      <ArrowLeftOutlined
                          style={{fontSize: "25px", color: "#444444"}}
                          onClick={cancelOverviewSurvey}
                      />
                    </Tooltip>
                  </RenderIfEntitled>
                </div>
                <div hidden={isOverViewMode()}>
                  <RenderIfEntitled
                      entitlementToCheck="delete@survey"
                      intl={intl}
                      distinctEntitlements={props.distinctEntitlements}
                  >
                    <Tooltip title={removeSurveyTooltipTitle} key={"removeStartSurvey"}>
                      <DeleteOutlined
                          style={{fontSize: "25px", color: "#444444"}}
                          onClick={removeSurvey}
                      />
                    </Tooltip>
                  </RenderIfEntitled>
                </div>
              </Space>
            </Col>
          </Row>

      </Form>
    </>
  );
};
export default SurveyOverviewSearchForm;
