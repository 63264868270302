import React, { useEffect, useState } from "react";
import "./index.css";
import { PortalUser } from "../components";
import { Navigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import { clearStorage, getCurrentLoggedInUser } from "../main/OauthUtils";


const CallbackLayout = () => {
  const [currentUser, setCurrentUser] = useState<PortalUser>(undefined);
  const [userLoading, isUserLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const search = useLocation().hash;
  const urlSearchParams = queryString.parse(search);
  const access_token = urlSearchParams.access_token;
  const id_token = urlSearchParams.id_token;
  const token_type = urlSearchParams.token_type;
  const expires_in = urlSearchParams.expires_in;

  if (access_token !== null && access_token !== undefined && access_token !== "undefined") {
    localStorage.setItem("access_token", String(access_token));
    localStorage.setItem("id_token", String(id_token));
    localStorage.setItem("token_type", String(token_type));
    localStorage.setItem("expires_in", String(expires_in));
    if (!localStorage.getItem("locale")) {
      localStorage.setItem("locale", "en"); //we need to makes to set preferred lang from WebWAM next
    }
  }

  useEffect(() => {
    //sessionStorage.setItem('callBackAlreadyPerformed',"true");
    if (currentUser === undefined) {
      isUserLoading(true);
      Promise.resolve(getCurrentLoggedInUser())
        .then((data) => {
          const currentUser = { _id: data._id, ...data };
          setCurrentUser(currentUser);

        })
        .catch((err) => {
          //clearStorage();
          console.error(err);
        });
    }
  }, [currentUser]);

  return (

    <>
      {currentUser && <Navigate to={"/"} state={currentUser} />}
    </>)
    ;

};

export default CallbackLayout;
