import {IAppComponentProps} from "../../components";
import React, {useEffect, useState} from "react";
import {AgGridReact} from "ag-grid-react";
import {INCOMING_DOCS_DETAILS_COLS, PENDING_DOCS_COLS} from "./utils";
import {GridApi} from "ag-grid-community";
import client from "../../config/GraphQLApolloClient";
import { PENDING_DOCS_DATA} from "./queries";
import {showErrorNotification} from "../Notification";
import {Spin} from "antd";
import { useIntl } from "react-intl";

export interface IProps extends IAppComponentProps {
    isLoading: any
}

const PendingDocumentsComponent: React.FunctionComponent<IProps> = ({
                                                                        isLoading
                                                                  }: IProps) => {
    const [pendingDocsGridApi, setPendingDocsGridApi] = useState<GridApi>(undefined);
  const [pendingDocumentsData, setPendingDocumentsData] = useState(undefined);
  const intl = useIntl();

    useEffect(() => {
        if (pendingDocumentsData === undefined) {
            isLoading(true);
            client.query({
                query: PENDING_DOCS_DATA,
                fetchPolicy: "cache-first"
            })
                .then(response => {
                    setPendingDocumentsData(response.data.getPendingDocuments);
                    isLoading(false);
                })
                .catch((error) => {
                    isLoading(false);
                    showErrorNotification(`${error}`);
                });
        }
    }, [pendingDocumentsData]);

    const onPendingDocsGridReady = (params) => {
        setPendingDocsGridApi(params.api);
    //    params.api.sizeColumnsToFit();
    };

    return(
        <AgGridReact
            defaultColDef={{
                enableValue: true,
                sortable: true,
                resizable: true
            }}
            columnDefs={PENDING_DOCS_COLS(intl)}
            rowData={pendingDocumentsData === undefined || pendingDocumentsData === null ? [] : pendingDocumentsData}

            sideBar={false}
            rowClass="agGridRows"
            suppressMenuHide={true}
            debug={false}
            pagination={true}
            paginationPageSize={15}
            suppressRowClickSelection={true}
            onGridReady={onPendingDocsGridReady}
        />
    );
};
export default PendingDocumentsComponent;