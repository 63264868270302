import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Select, SelectProps, Typography } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { useIntl } from "react-intl";
import { SELECTED_COMPANY_CODE, SELECTED_PARTNER_NO } from "../../../constants";
import { showErrorWithHeaderNotification } from "../../Notification";
import { useAppSelector } from "../../../main/hooks";
import { MODE } from "../IStoredSurvey";

type SurveySupplierDocumentTypeBulkUpdateFormProps = {
  save: any;
  paymentAdvanceFormatData: any;
  outgoingFormatData: any;
  incomingDocumentsFormatData: any;
  channelData: any;
  paymentAdvanceFormatOptions: string[];
  outgoingFormatOptions: string[];
  incomingDocumentsFormatOptions: string[];
  outgoingFormat:string;
  outgoingChannel:string;
  paymentAdviceFormat:string;
  paymentAdviceChannel:string;
  incomingFormat:string;
  incomingChannel:string;
};


const SurveySupplierDocumentTypeBulkUpdateForm: React.FunctionComponent<SurveySupplierDocumentTypeBulkUpdateFormProps> = (props) => {
  const intl = useIntl();
  const [form] = Form.useForm();

  const surveyState = useAppSelector((state) => state.survey);

  const isViewMode = () => {
    return surveyState.survey.surveyMode === MODE.VIEW
  }

  const isOverViewMode = () => {
    return surveyState.survey.surveyMode === MODE.OVERVIEW
  }

const isReadOnly = () => {
  return isViewMode() || isOverViewMode();
 }

  const editMessage = intl.formatMessage({
    id: "survey-eDocument-supplier-edit-messages",
    defaultMessage: "Please select first the updated EDI Format and second the updated EDI Channel"
  });
  const outgoingDocumentsTabTitle = intl.formatMessage({
    id: "survey-eDocument-supplier-outgoingDocuments-tab-title",
    defaultMessage: "Outgoing Documents"
  });

  const paymentAdvicesTabTitle = intl.formatMessage({
    id: "survey-eDocument-supplier-paymentAdvices-tab-title",
    defaultMessage: "Payment Advices"
  });

  const incomingDocumentsTabTitle = intl.formatMessage({
    id: "survey-eDocument-supplier-incomingDocuments-tab-title",
    defaultMessage: "Incoming Documents"
  });
  const updatedChannelLabel = intl.formatMessage({
    id: "survey-eDocument-customer-updatedChannel-label",
    defaultMessage: "Current Channel"
  });

  const updatedChannelPlaceholder = intl.formatMessage({
    id: "survey-eDocument-customer-updatedChannel-placeholder",
    defaultMessage: "Current Channel"
  });

  const updatedFormatLabel = intl.formatMessage({
    id: "survey-eDocument-customer-updatedFormat-label",
    defaultMessage: "Current Format"
  });

  const updatedFormatPlaceholder = intl.formatMessage({
    id: "survey-eDocument-customer-updatedFormat-placeholder",
    defaultMessage: "Current Format"
  });

  const [outgoingChannelsOptions, setOutgoingChannelsOptions] = useState<SelectProps["options"]>([]);
  const [incomingDocumentsChannelsOptions, setIncomingDocumentsChannelsOptions] = useState<SelectProps["options"]>([]);
  const [paymentAdviceChannelsOptions, setPaymentAdviceChannelsOptions] = useState<SelectProps["options"]>([]);

  const onOutgoingFormatChange = (value) => {
    const channels = [];
    props.outgoingFormatData.filter(item => item.formatName === value).map(filtItem => filtItem.channel.forEach(channelData => {
      channels.push({ value: `${channelData.channelName}-${channelData.provider}`, label: channelData.channelName });
    }));
    form.setFieldsValue({outgoingUpdatedChannel: undefined});
    setOutgoingChannelsOptions(channels);
  };

  const onPaymentAdviceFormatChange = (value) => {
    const channels = [];
    form.setFieldsValue({paymentAdviceUpdatedChannel: undefined});
    props.paymentAdvanceFormatData.filter(item => item.formatName === value).map(filtItem => filtItem.channel.forEach(channelData => {
      channels.push({ value: `${channelData.channelName}-${channelData.provider}`, label: channelData.channelName });
    }));

    setPaymentAdviceChannelsOptions(channels);
  };
  const onIncomingDocumentsFormatChange = (value) => {
    const channels = [];
    props.incomingDocumentsFormatData.filter(item => item.formatName === value).map(filtItem => filtItem.channel.forEach(channelData => {
      channels.push({ value: `${channelData.channelName}-${channelData.provider}`, label: channelData.channelName });
    }));
    form.setFieldsValue({incomingDocumentsUpdatedChannel: undefined});
    setIncomingDocumentsChannelsOptions(channels);
  };

  useEffect(() => {

  }, []);

  const save = () => {
    if (!form.getFieldValue("outgoingUpdatedFormat") && !form.getFieldValue("outgoingUpdatedChannel")
      && !form.getFieldValue("paymentAdviceUpdatedFormat")
      && !form.getFieldValue("paymentAdviceUpdatedChannel")
      && !form.getFieldValue("incomingDocumentsUpdatedFormat")
      && !form.getFieldValue("incomingDocumentsUpdatedChannel")

    ) {
      showErrorWithHeaderNotification(
        intl.formatMessage({
          id: "Download Error",
        }),
        intl.formatMessage({
          id: "survey-eDocument-supplier-bulk-channel-not-selected-error",
          defaultMessage: "NO EDI Channel Format selected"
        }));
    } else {
      props.save(form.getFieldsValue());
    }

  };

  const outgoingChannelFormat = props.channelData?.filter(item => item.processCode !== "INC" && item.processCode !== "PAD" && item.processCode !== "ECAP");


  const updatedOutgoingChannel = new Set(outgoingChannelFormat?.map(item => item.updatedChannel))?.size === 1 ? outgoingChannelFormat[0]?.updatedChannel : intl.formatMessage({id:'miscellaneous'});
  const updatedOutgoingFormat = new Set(outgoingChannelFormat?.map(item => item.updatedFormat))?.size === 1 ? outgoingChannelFormat[0]?.updatedFormat : intl.formatMessage({id:'miscellaneous'});

  const updatedPaymentAdviceFormat = props.channelData?.filter(item => item.processCode === "PAD").length > 0 ?
    props.channelData?.filter(item => item.processCode === "PAD")[0]?.updatedFormat : "PDF";
  const updatedPaymentAdviceChannel = props.channelData?.filter(item => item.processCode === "PAD").length > 0 ?
    props.channelData?.filter(item => item.processCode === "PAD")[0]?.updatedChannel : "PORTAL";

  const updatedIncomingFormat = props.channelData?.filter(item => item.processCode === "INC").length > 0 ?
    props.channelData?.filter(item => item.processCode === "INC")[0]?.updatedFormat : "PDF";
  const updatedIncomingChannel = props.channelData?.filter(item => item.processCode === "INC").length > 0 ?
    props.channelData?.filter(item => item.processCode === "INC")[0]?.updatedChannel : "ECAP";

  useEffect(()=> {
    form.setFieldsValue( {
      outgoingFormat: props.outgoingFormat,
      outgoingChannel: props.outgoingChannel,
      paymentAdviceFormat: props.paymentAdviceFormat,
      paymentAdviceChannel: props.paymentAdviceChannel,
      incomingDocumentsFormat: props.incomingFormat,
      incomingDocumentsChannel: props.incomingChannel
    })
  })

  return (
    <>
      <Card size={"small"}>
        <Typography>
          <Paragraph>
            {editMessage}
          </Paragraph>
        </Typography>
        <Form size={"middle"} layout={"vertical"} form={form} initialValues={{
          outgoingFormat: updatedOutgoingFormat,
          outgoingChannel: updatedOutgoingChannel,
          paymentAdviceFormat: updatedPaymentAdviceFormat,
          paymentAdviceChannel: updatedPaymentAdviceChannel,
          incomingDocumentsFormat: updatedIncomingFormat,
          incomingDocumentsChannel: updatedIncomingChannel
        }}
              onFinish={save}>
          <Row gutter={24}>
            <Col span={6} flex={1}>
              <Typography.Title level={5}>
                {localStorage.getItem(SELECTED_PARTNER_NO)} - {localStorage.getItem(SELECTED_COMPANY_CODE)}
              </Typography.Title>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={4}>
              <Typography.Title level={5}>
                {outgoingDocumentsTabTitle}
              </Typography.Title>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={4}>
              <label>{intl.formatMessage({id:'survey-supplier-doc-type-simple-grid-header-format'})}</label>
              <Form.Item name={"outgoingFormat"} key={"outgoingFormatKey"} initialValue={"PDF"}>
                <Input value={"PDF"} disabled={true} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <label>{intl.formatMessage({id:'survey-supplier-doc-type-simple-grid-header-channel'})}</label>
              <Form.Item name={"outgoingChannel"} key={"outgoingChannelKey"} initialValue={"PORTAL"}>
                <Input value={"PORTAL"} disabled={true} />
              </Form.Item>
            </Col>

            <Col span={4}>
              <label>{updatedFormatLabel}</label>
              <Form.Item name={"outgoingUpdatedFormat"} key={"outgoingUpdatedOutgoingSupplierFormatKey"}>
                <Select placeholder={updatedFormatPlaceholder} allowClear={true}
                        optionFilterProp="children"
                        disabled={!props.outgoingFormatOptions}
                        showSearch={true}
                        data-testid="updatedOutgoingSupplierFormat"
                        onChange={onOutgoingFormatChange}>
                  {props.outgoingFormatOptions &&
                  props.outgoingFormatOptions.map((item, index) => (
                    <Select.Option key={`${item}_${index}`} value={item} data-testid={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

            </Col>
            <Col span={4}>
              <Form.Item name={"outgoingUpdatedChannel"} key={"updatedOutgoingSupplierChannelKey"}
                         rules={[{ required: form.getFieldsValue().outgoingUpdatedFormat !== undefined }]}
                         label={updatedChannelLabel}>
                <Select placeholder={updatedChannelPlaceholder} allowClear={true}
                        optionFilterProp="children"
                        disabled={!props.outgoingFormatOptions}
                        showSearch={true}
                        data-testid="updatedOutgoingSupplierChannel">

                  {outgoingChannelsOptions &&
                  outgoingChannelsOptions.map((item, index) => (
                    <Select.Option key={`${item.label}_${index}`} value={item.value} data-testid={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <Typography.Title level={5}>
                {paymentAdvicesTabTitle}
              </Typography.Title>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={4}>
              <label>{intl.formatMessage({id:'survey-supplier-doc-type-simple-grid-header-format'})}</label>
              <Form.Item name={"paymentAdviceFormat"} key={"paymentAdviceFormatKey"} initialValue={"PDF"}>
                <Input value={"PDF"} disabled={true} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <label>{intl.formatMessage({id:'survey-supplier-doc-type-simple-grid-header-channel'})}</label>
              <Form.Item name={"paymentAdviceChannel"} key={"paymentAdviceChannelKey"} initialValue={"PORTAL"}>
                <Input value={"PORTAL"} disabled={true} />
              </Form.Item>
            </Col>

            <Col span={4}>
              <label>{updatedFormatLabel}</label>
              <Form.Item name={"paymentAdviceUpdatedFormat"} key={"paymentAdviceUpdatedOutgoingSupplierFormatKey"}>
                <Select placeholder={updatedFormatPlaceholder} allowClear={true}
                        optionFilterProp="children"
                        disabled={!props.paymentAdvanceFormatOptions}
                        showSearch={true}
                        data-testid="paymentAdviceOutgoingSupplierFormat"
                        onChange={onPaymentAdviceFormatChange}>
                  {props.paymentAdvanceFormatOptions &&
                  props.paymentAdvanceFormatOptions.map((item, index) => (
                    <Select.Option key={`${item}_${index}`} value={item} data-testid={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

            </Col>
            <Col span={4}>
              <Form.Item name={"paymentAdviceUpdatedChannel"} key={"paymentAdviceOutgoingSupplierChannelKey"}
                         label={updatedChannelLabel}
                         rules={[{ required: form.getFieldsValue().paymentAdviceUpdatedFormat !== undefined }]}>
                <Select placeholder={updatedChannelPlaceholder} allowClear={true}
                        optionFilterProp="children"
                        disabled={!props.paymentAdvanceFormatOptions}
                        showSearch={true}
                        data-testid="paymentAdviceOutgoingSupplierChannel">
                  {paymentAdviceChannelsOptions &&
                  paymentAdviceChannelsOptions.map((item, index) => (
                    <Select.Option key={`${item.label}_${index}`} value={item.value} data-testid={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <Typography.Title level={5}>
                {incomingDocumentsTabTitle}
              </Typography.Title>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={4}>
              <label>{intl.formatMessage({id:'survey-supplier-doc-type-simple-grid-header-format'})}</label>
              <Form.Item name={"incomingDocumentsFormat"} key={"incomingDocumentsFormatKey"} initialValue={"PDF"}>
                <Input value={"PDF"} disabled={true} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <label>{intl.formatMessage({id:'survey-supplier-doc-type-simple-grid-header-channel'})}</label>
              <Form.Item name={"incomingDocumentsChannel"} key={"incomingDocumentsChannelKey"} initialValue={"ECAP"}>
                <Input value={"ECAP"} disabled={true} />
              </Form.Item>
            </Col>

            <Col span={4}>
              <label>{updatedFormatLabel}</label>
              <Form.Item name={"incomingDocumentsUpdatedFormat"} key={"incomingDocumentsSupplierFormatKey"}>
                <Select placeholder={updatedFormatPlaceholder} allowClear={true}
                        optionFilterProp="children"
                        showSearch={true}
                        data-testid="incomingDocumentsSupplierFormat"
                        onChange={onIncomingDocumentsFormatChange}>
                  {props.incomingDocumentsFormatOptions &&
                  props.incomingDocumentsFormatOptions.map((item, index) => (
                    <Select.Option key={`${item}_${index}`} value={item} data-testid={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

            </Col>
            <Col span={4}>
              {/*<label>{updatedChannelLabel}</label>*/}
              <Form.Item name={"incomingDocumentsUpdatedChannel"} key={"incomingDocumentsSupplierChannelKey"}
                         label={updatedChannelLabel}
                         rules={[{ required: form.getFieldsValue().incomingDocumentsUpdatedFormat !== undefined }]}>
                <Select placeholder={updatedChannelPlaceholder} allowClear={true}
                        optionFilterProp="children"
                        showSearch={true}
                        data-testid="incomingDocumentsSupplierChannel">
                  {incomingDocumentsChannelsOptions &&
                  incomingDocumentsChannelsOptions.map((item, index) => (
                    <Select.Option key={`${item.label}_${index}`} value={item.value} data-testid={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <div hidden={isReadOnly()}>
              <Col span={4} className="w-100 mt10">
                <Button type="primary" size="middle" className="w-100 mt10" htmlType={"submit"}
                        disabled={
                          form.getFieldsValue().paymentAdviceUpdatedChannel === undefined
                          && form.getFieldsValue().paymentAdviceUpdatedFormat === undefined
                          && form.getFieldsValue().outgoingUpdatedChannel === undefined
                          && form.getFieldsValue().outgoingUpdatedFormat === undefined
                          && form.getFieldsValue().incomingDocumentsUpdatedFormat === undefined
                          && form.getFieldsValue().incomingDocumentsUpdatedChannel === undefined
                        }>
                  {intl.formatMessage({
                    id: "survey-main-general-save-button",
                    defaultMessage: "SAVE"
                  })}
                </Button>
              </Col>
            </div>
          </Row>
        </Form>
      </Card>
    </>
  );
};

export default SurveySupplierDocumentTypeBulkUpdateForm;