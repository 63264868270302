import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import { useAppDispatch } from "../../main/hooks";
import { setDocumentMode, updateDocumentId } from "../e-cap/IStoredDocumentRecordSlice";
import { Button, Modal, Tooltip } from "antd";
import {EyeOutlined } from "@ant-design/icons";
import { MODE } from "../e-cap/IStoredDocumentRecord";
import { useIntl } from "react-intl";
import {Link, useNavigate} from "react-router-dom";

const RowOption: React.FunctionComponent<ICellRendererParams> = (props) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const navigate = useNavigate();

  const viewDocument = () => {
    //only if it ECAP
if(props.data.format === 'ECAP'){
  dispatch(updateDocumentId(props.data.id));
  dispatch(setDocumentMode(MODE.OVERVIEW));
  navigate("/ecap/document-processing");
  
  }
 else{
  
  Modal.warning({
    title: intl.formatMessage({
      id: "Warning-ECAP-Format",
      defaultMessage: "Warning-ECAP"
    }),
    content: intl.formatMessage({
      id: "Warning-ECAP-message",
      defaultMessage: "Only ECAP Format is Available to view. Please use the download button to view your documents"
    })
  });
  navigate("/documents/documents-overview");
 } };
  
  return (<div>
    
    <Tooltip title={props.data.format === 'ECAP'?'View the captured document':''}>
      {
         <Button type={props.data.format === 'ECAP'?'link':'text'}  onClick={(e)=>viewDocument()}> {props.data.documentNumber}</Button>
      }
    </Tooltip>

    </div>);
};

export default RowOption;

