import React, { FunctionComponent } from "react";
import { Button, Card, Col, Row, Spin, Tabs, Form } from "antd";

import { IAppComponentProps } from "../../components";
import SurveyVerifySubmit from "./SurveyVerifySubmit/SurveyVerifySubmit-component";
import { SELECTED_GROUP_ID } from "../../constants";
import { useIntl } from "react-intl";
import {StateContainer} from "./utilities/StateContainer";

type ISurveyVerifySubmitStepProps = {
  next?: any;
  prev?: any;
  onStatusUpdate: any;
  stateContainer?: StateContainer;
} & IAppComponentProps;

const SurveyVerifySubmitStep: FunctionComponent<ISurveyVerifySubmitStepProps> = (props) => {
  const intl = useIntl();
  const { TabPane } = Tabs;
  const [form] = Form.useForm();
  const groupID = localStorage.getItem(SELECTED_GROUP_ID);
  return (
    <>
      <Card title={intl.formatMessage({
        id: "verifySubmit-tab-title",
        defaultMessage: "Verify & Submit"
        })}
      size={"small"} bordered={true} className="ml-10">
        <SurveyVerifySubmit  form={form} groupId={groupID} onStatusUpdate={props.onStatusUpdate}/>
      </Card>
    </>
  );

};

export default SurveyVerifySubmitStep;
