import {IntlShape} from "react-intl/src/types";
import _extends from "@babel/runtime/helpers/esm/extends";
import EnglishCalendarLocale from "rc-picker/es/locale/en_GB";
import GermanCalendarLocale from "rc-picker/es/locale/de_DE";
import TimePickerLocale from 'antd/es/time-picker/locale/en_GB';
import {PickerLocale} from "antd/es/date-picker/generatePicker";

// Merge into a locale object


export function getRangePickerLocalization(intl:IntlShape, language:string):PickerLocale {
    //See interface rc-picker/es/interface.d.ts
    if (language === "de") {
        const germanLocale = {
            lang: _extends({
                shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
                shortWeekDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],

            }, GermanCalendarLocale),
            timePickerLocale: _extends({}, TimePickerLocale)
        };

        return germanLocale;
    }

    const englishLocale = {
        lang: _extends({
            shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            shortWeekDays: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],

        }, EnglishCalendarLocale),
        timePickerLocale: _extends({}, TimePickerLocale)
    };

    return englishLocale
}