// ______________________________________________________________________________
//
//       Project: e-Invoicing Documents Portal
//       File: https://atc.bmwgroup.net/bitbucket/scm/einvmrp/einvoicing-portal.git
//       Version: 0.0.1
// ______________________________________________________________________________
//
//    Created by: Lesley Netshiavhela <Lesley.Netshiavhela@partner.bmw.co.za>
//    Creation date:  2021/10/27
// ______________________________________________________________________________
//
//     Copyright: (C) BMW Group 2020, all rights reserved
// ______________________________________________________________________________
//
import React, { useEffect, useState } from "react";
import { IAppComponentProps, PortalUser } from "../../components";
import { Button, Col, ConfigProvider, Form, Input, Row } from "antd";
import { AgGridReact } from "ag-grid-react";
import { COMPANY_TABLE_HEADERS } from "./UserUtils";
import { ALL_ASSIGN_USER_RIGHTS, AssignRights } from "./queries";
import client from "../../config/GraphQLApolloClient";
import { GridApi } from "ag-grid-community";
import { omitDeep } from "../../utils/Utils";
import enUS from "antd/lib/locale/en_US";
import { PageContainer } from "@ant-design/pro-layout";
import styles from "../../styles/scss/portal.scss";
// eslint-disable-next-line import/named
import { useLocation, useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";


const UserProfile: React.FunctionComponent<IAppComponentProps> = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  // @ts-ignore
  const selectedUser: PortalUser = location.state.selectedUser;
  const [userRights, setUserRights] = useState(undefined);
  const [gridApi, setGridApi] = useState<GridApi>(undefined);

  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  useEffect(() => {
    if (!userRights) {
      client.query(
        {
          query: ALL_ASSIGN_USER_RIGHTS,
          variables: { userId: Number(selectedUser.user_id) },
          fetchPolicy: "cache-first"
        }).then((userRights) => {

        const rights: AssignRights[] = omitDeep(
          userRights.data?.getAllUserRights,
          (_, prop) => ["__typename"].includes(prop)
        );

        setUserRights(rights);
      });
    }

  }, [userRights]);


  return (
    <ConfigProvider locale={enUS}>
      <PageContainer
        title={false}
        content={""}
        extraContent={""}
        className={styles.assign}
        style={{ background: "white" }}
      >

        <Row gutter={24}>
          <Col md={22} sm={12} xs={24}>
            <div className="pl15">
              <h1>{`${selectedUser.firstname} ${selectedUser.lastname}`} 'S Profile</h1>
            </div>
          </Col>
        </Row>

        <Form layout={"vertical"} initialValues=
          {{
            "firstname": selectedUser.firstname, "lastname": selectedUser.lastname,
            "email": selectedUser.email, "username": selectedUser.username, "role": selectedUser.roleName
          }}>
          <h5 className="mb10 mt10 pl15">User Details</h5>
          <Row gutter={20} className="pl20">
            <Col span={6}>
              <Form.Item label={"First Name"} name={"firstname"}>
                <Input disabled={true} />
              </Form.Item>
            </Col>

            <Col span={6}>

              <Form.Item label={"Last Name"} name={"lastname"}>
                <Input disabled={true} />
              </Form.Item>
            </Col>

            <Col span={6}>

              <Form.Item label={"Email"} name={"email"}>
                <Input disabled={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20} className="pl20">

            <Col span={6}>
              <Form.Item label={"Login Name"} name={"username"}>
                <Input disabled={true} />
              </Form.Item>
            </Col>


            <Col span={6}>
              <Form.Item label={"User Role"} name={"role"}>
                <Input disabled={true} />
              </Form.Item>
            </Col>
          </Row>
        </Form>


        <Row gutter={24} className={"pl20"}>
          <Col span={22}>
            <div style={{ flex: "auto", height: "400px", paddingTop: "10px" }} className="ag-theme-balham">
              <AgGridReact
                defaultColDef={{
                  enableValue: true,
                  sortable: true,
                  resizable: true
                }}
                rowData={userRights}
                columnDefs={COMPANY_TABLE_HEADERS(props.intl)}

                sideBar={false}
                rowClass="agGridRows"
                suppressMenuHide={true}
                debug={false}
                pagination={true}
                paginationPageSize={10}
                suppressRowClickSelection={true}
                onGridReady={onGridReady}

              />
            </div>
          </Col>
        </Row>
        <Row gutter={24} className={"pl20"}>
          <Col span={6} xs={24} sm={6} md={4}>
            <Button type="default" size="large" className="w-100 mt10 mb2" onClick={() => navigate("/users")}>
              {props.intl.formatMessage({
                id: "survey-main-general-cancel-button",
                defaultMessage: "CANCEL"
                })}
            </Button>
          </Col>
        </Row>

      </PageContainer>
    </ConfigProvider>
  );
};

export default UserProfile;
