import {DocumentDownloadInputs} from "../../../__generated__/globalTypes";
import client from "../../config/GraphQLApolloClient";
import {DOWNLOAD_DOCUMENT_ECAP} from "./queries";
import JSZip from 'jszip';
import {saveAs} from "save-as";
import {
    downloadDocumentEcap, downloadDocumentEcap_downloadDocumentEcap_failureDocuments,
    downloadDocumentEcap_downloadDocumentEcap_successDocuments,
    downloadDocumentEcapVariables
} from "./__generated__/downloadDocumentEcap";
import {Buffer} from "buffer";

type ElapsedTime = {
    hours:number,
    minutes:number,
    seconds:number
};

function calculateElapsedTime(startTime:number, endTime:number):ElapsedTime {
    const elapsedTimeInSeconds = (endTime - startTime) / 1000;
    const hours = Math.floor(elapsedTimeInSeconds / 3600);
    const minutes = Math.floor((elapsedTimeInSeconds % 3600) / 60);
    const seconds = Math.floor(elapsedTimeInSeconds % 60);

    return {
        hours,
        minutes,
        seconds
    };
}

export type DownloadProgressProperties = {
    cancelling: boolean,
    progress:number
};

export type DownloadAndSaveParamProperties = {
    list: DocumentDownloadInputs[],
    fileName: string,
    batchSize?: number,
    downloadProgressProperties: DownloadProgressProperties
    progressNotifier?: () => void
};

function generateTimer(index:number, batchSize:number, maxIndex:number, downloadProgressProperties: DownloadProgressProperties, progressNotifier: () => void) {
    const TIMEOUT = 300_000;
    const NUMBER_OF_RUNS = 100;
    const INTERVAL = Math.floor(TIMEOUT / NUMBER_OF_RUNS);

    const nextIndex = Math.min(index + batchSize, maxIndex);

    let counter = 0;

    return setInterval(()=> {
        if (!downloadProgressProperties.cancelling) {
            counter+=1;
            const offset = Math.floor((nextIndex-index) *(Math.log(counter)/Math.log(100)));

            const progress = Math.round((index + offset) / maxIndex * 100);
            console.debug(`Running timer with params ${index}, next index ${nextIndex}, offset ${offset} and counter ${counter} setting progress to ${progress}`);
            downloadProgressProperties.progress = progress;
            if (progressNotifier) {
                progressNotifier()
            }
        }
    },INTERVAL);
}
export async function downloadAndSaveZipDocuments(param:DownloadAndSaveParamProperties) {
    if (!param?.list?.length || !param?.fileName || !param.downloadProgressProperties) {
        throw Error("The specified param is invalid. Mandatory fields, list and fileName expected");
    }

    const list = param.list;
    const fileName = param.fileName;
    const batchSize = param.batchSize || 100;
    const downloadProgressProperties = param.downloadProgressProperties;
    const progressNotifier = param.progressNotifier;

    downloadProgressProperties.cancelling = false;

    const zip = new JSZip();
    const successList: downloadDocumentEcap_downloadDocumentEcap_successDocuments[] = [];
    const failureList: downloadDocumentEcap_downloadDocumentEcap_failureDocuments[] = [];
    let errorMsg = null;

    const startTime = new Date().getTime();

    for (let i = 0; i < list.length; i += batchSize) {
        if (downloadProgressProperties.cancelling) {
            break;
        }

        downloadProgressProperties.progress = Math.round((i / list.length) * 100);

        const unzipper = new JSZip();
        const documentBatch = list.slice(i, Math.min(i + batchSize, list.length));

        const endTime = new Date().getTime();
        const elapsedTimeInSeconds = calculateElapsedTime(startTime, endTime);

        if (progressNotifier) {
            progressNotifier();
        }

        console.log(`Downloading batch. Elapsed time: ${elapsedTimeInSeconds.hours} hours, ${elapsedTimeInSeconds.minutes} minutes, ${elapsedTimeInSeconds.seconds} seconds. Percent complete ${Math.round((i / list.length) * 100)}%`);

        const interval = generateTimer(i, batchSize, list.length, downloadProgressProperties, progressNotifier);

        const blob:Blob =
            await client.query<downloadDocumentEcap, downloadDocumentEcapVariables>({
                query: DOWNLOAD_DOCUMENT_ECAP,
                variables: {
                    documentDownloadInputs: documentBatch,
                },
                fetchPolicy: 'network-only',
            }).then((response) => {
                successList.push(...response.data.downloadDocumentEcap.successDocuments);
                failureList.push(...response.data.downloadDocumentEcap.failureDocuments);

                if (response.data.downloadDocumentEcap.errorMessage) {
                    errorMsg = response.data.downloadDocumentEcap.errorMessage;
                    return null;
                }

                const encodedZipFileData = response.data.downloadDocumentEcap.encodedZipFileData;

                const mimeType = 'application/zip';
                const decodedData = Buffer.from(encodedZipFileData, 'base64');
                return new Blob([decodedData], {type: mimeType});
            }).catch(error=> {
                clearInterval(interval);
                throw error;
            })

        if (blob === null && errorMsg) {
            clearInterval(interval);
            break;
        }

        const listOfPromises = await unzipper.loadAsync(blob).then((contents) => {
            const files = Object.keys(contents.files);
            const promises = files.map(filename => {
                return contents.files[filename].async('blob').then(fileData => ({
                    filename,
                    data: fileData,
                }));
            });

            return Promise.all(promises)
        });

        listOfPromises.forEach((file) => {
            zip.file(file.filename, file.data);
        });

        clearInterval(interval);
    }

    if (!errorMsg && !downloadProgressProperties.cancelling) {
        //Business want to see the 100% completion before they see the save file dialog....
        downloadProgressProperties.progress = 99;
        if (progressNotifier) {
            progressNotifier();
        }
        await new Promise((resolve, reject) =>{
            setTimeout(()=> {
                resolve(null);
            }, 2000);
        });

        const result = await zip.generateAsync({type: 'blob'});
        saveAs(result, fileName);
    }

    downloadProgressProperties.progress = 100;
    if (progressNotifier) {
        progressNotifier();
    }

    return {
        data: {
            downloadDocumentEcap: {
                successDocuments: successList,
                failureDocuments: failureList
            }
        }
    }
}