import { Input } from "antd";
import client from "../../../../config/GraphQLApolloClient";
import { MY_DOCS_TEMPLATE_DATA } from "../queries";
import { getI18n } from "../../../../utils/Utils";
import { IntlShape } from "react-intl/src/types";
import { getTemplateDocumentList, getTemplateDocumentListVariables } from "../__generated__/getTemplateDocumentList";


export const getTemplateDocument = (username: string, input, intl: IntlShape) => {
  return new Promise<getTemplateDocumentList>((resolve, reject) => {
    client.query<getTemplateDocumentList, getTemplateDocumentListVariables>({
      query: MY_DOCS_TEMPLATE_DATA,
      variables: { templateListInput: input },

      fetchPolicy: "network-only"
    })
      .then((templateDocuments) => {

        const translatedTemplateDocumentList = [];

        templateDocuments.data?.getTemplateDocumentList.forEach(item => {
          translatedTemplateDocumentList.push({ ...item, documentType: getI18n(item.phraseId, item.documentType, intl) });
        });

        const response: getTemplateDocumentList = { getTemplateDocumentList: translatedTemplateDocumentList };
        resolve(
          response
        );
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};