import { AgGridReact } from 'ag-grid-react';
import { Button, Card, Col, Form, Input, Menu, message, notification, Row, Select, Spin } from 'antd';
import React, { useMemo, useRef, useState } from 'react';
import { EntitlementFilter, EntitlementReport, EntitlementRule } from '../../../__generated__/types';
import { IAppComponentProps } from '../../../components';
import { getAgGridLocalization, PersonalizeGridView } from '../../info-portal/components/grid/GridUtil';
import { GridApi } from 'ag-grid-community';
import ObjectClassComponent from './ObjectClassDropDown';
import B2XRoleDropDown from './B2XRoleDropDown';
import { DropDownSelectType } from './EntitlementInterfaces';
import { useGetEntitlementReportQuery } from './queries.generated';
import EntitlementEditRuleComponent from './EntitlementEditRuleComponent';
import CompanyCodeDropDown from './CompanyCodeDropDown';
import EntitlementGroupDropDown from './EntitlementGroupDropDown';
import { ClearOutlined, ReloadOutlined, SaveOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import EntitlementTypeDropDown from './EntitlementTypeDropDown';
import { useAppDispatch, useAppSelector } from '../../../main/hooks';
import { setB2XRoleUpdated, setEntitlementTypeUpdated, setGroupUpdated, setObjectClassUpdated, updateEntitlementFilters, updateNewEntitlement } from './redux/IStoredEntitlementsSlice';

export const ENTITLEMENT_RULES_TABLE_HEADERS = () => [
    {
        headerName: 'Entitlement Group',
        field: 'groupId',
        colId: 'groupId',
        cellRenderer: 'agGroupCellRenderer',
        filter: 'agMultiColumnFilter',
    },
    { headerName: 'Group Description', field: 'description', colId: 'description' },
];

const ENTITLEMENT_DETAIL_TABLE_HEADERS = () => [
    {
        headerName: 'Rule Id',
        field: 'ruleId',
        colId: 'ruleId',
        tooltipField: 'ruleId',
        headerTooltip: 'Double click on line to EDIT',
        width: 180,
        filter: 'agMultiColumnFilter',
    },
    {
        headerName: 'Allowed / Blocked',
        field: 'allowedOrBlocked',
        colId: 'allowedOrBlocked',
        width: 180,
        filter: 'agMultiColumnFilter',
    },
    // { headerName: "Partner List", field: "partner", coldId: "partner" },
    { headerName: 'B2X Roles', field: 'b2xRole', colId: 'b2xRole', filter: 'agMultiColumnFilter' },
    { headerName: 'Department Codes', field: 'department', colId: 'department', filter: 'agMultiColumnFilter' },
    { headerName: 'Company Codes', field: 'company', colId: 'company', filter: 'agMultiColumnFilter' },
    { headerName: 'Object Class', field: 'objectClass', colId: 'objectClass', filter: 'agMultiColumnFilter' },
    { headerName: 'User ID', field: 'userId', colId: 'userId', filter: 'agMultiColumnFilter' },
    {
        headerName: 'Other Entitlements on Rule',
        field: 'otherEntitlementsOnRule',
        colId: 'otherEntitlementsOnRule',
        filter: 'agMultiColumnFilter',
    },
    {
        headerName: 'Rule Description',
        field: 'ruleDescription',
        colId: 'ruleDescription',
        filter: 'agMultiColumnFilter',
    },
    { headerName: 'Present in Groups', field: 'groupIds', colId: 'groupIds', filter: 'agMultiColumnFilter' },
    { headerName: 'Insert Time', field: 'insertTime', colId: 'insert_time', filter: 'agMultiColumnFilter' },
    { headerName: 'Update Time', field: 'updateTime', colId: 'update_Time', filter: 'agMultiColumnFilter' },
    {
        headerName: 'Insert User',
        field: 'insertUser',
        colId: 'insert_User',
        cellRenderer: (params) => (params.value ? params.value.toUpperCase() : ''),
        filter: 'agMultiColumnFilter',
    },
    {
        headerName: 'Update User',
        field: 'updateUser',
        colId: 'update_User',
        cellRenderer: (params) => (params.value ? params.value.toUpperCase() : ''),
        filter: 'agMultiColumnFilter',
    },
];

type EntitlementFormProps = {
    onLoading: any;
} & IAppComponentProps;

const SearchEntitlementsComponent: React.FunctionComponent<EntitlementFormProps> = (props) => {
    const [form] = Form.useForm();
    const [searchFilter, setSearchFilter] = useState<EntitlementFilter>({});
    const [entitlementGroupSelected, setEntitlementGroupSelected] = useState<string[]>([]);
    const [objectClassSelected, setObjectClassSelected] = useState<string[]>([]);
    const [detailGridApi, setDetailGridApi] = useState<GridApi>(undefined);
    const [masterGridApi, setMasterGridApi] = useState<GridApi>(undefined);
    const [selectedDetailRow, setSelectedDetailRow] = useState(null);
    const [selectedMasterRow, setSelectedMasterRow] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [entitlementRuleToEdit, setEntitlementRuleToEdit] = useState<EntitlementRule>(null);
    const [refreshLoading, setRefreshLoading] = useState(false);
    const [entitlementReport, setEntitlementReport] = useState<EntitlementReport[]>([]);
    const entitlementState = useAppSelector((state) => state.entitlements);
    const dispatch = useAppDispatch();

    const masterGridView = useRef<PersonalizeGridView>(
        new PersonalizeGridView(
            'entitlement_maintenance_master_ag_grid_personalized_view',
            ENTITLEMENT_RULES_TABLE_HEADERS()
        )
    );

    const detailGridView = useRef<PersonalizeGridView>(
        new PersonalizeGridView(
            'entitlement_maintenance_detail_ag_grid_personalized_view',
            ENTITLEMENT_DETAIL_TABLE_HEADERS()
        )
    );

    const handleFormSearch = () => {
        console.log('Form search Values:', form.getFieldsValue());
        // Handle form search logic here
        setSearchFilter(form.getFieldsValue());
    };

    const onSave = (_: any) => {
        masterGridView?.current?.savePersonalizedView();
        detailGridView?.current?.savePersonalizedView();
        notification.info({ message: props.intl.formatMessage({ id: 'report-result-preferences-saved' }) });
    };

    const onApplyPerson = (_: any) => {
        masterGridView?.current?.applyPersonalizedView();
        detailGridView?.current?.applyPersonalizedView();
    };

    const onReset = (_: any) => {
        masterGridApi?.setFilterModel(null);
        detailGridApi?.setFilterModel(null);
        masterGridView?.current?.reset();
        detailGridView?.current?.reset();
    };

    const menu = (
        <Menu>
            <Menu.Item
                key="3"
                disabled={
                    !masterGridView.current.hasPersonalizedView() && !detailGridView.current.hasPersonalizedView()
                }
            >
                <UserOutlined onClick={onApplyPerson} />
                <a data-testid={'info-portal-personal'} onClick={onApplyPerson}>
                    {props.intl.formatMessage({ id: 'info-portal-personal', defaultMessage: 'Personal' })}
                </a>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="4">
                <SaveOutlined onClick={onSave} />

                <a data-testid={'info-portal-save'} onClick={onSave}>
                    {props.intl.formatMessage({ id: 'info-portal-save', defaultMessage: 'Save' })}
                </a>
            </Menu.Item>
        </Menu>
    );

    const onFieldChange = (e) => {
        dispatch(updateEntitlementFilters(form.getFieldsValue()));
    }

    const onEntitlementGroupSelect = () => {
        setEntitlementGroupSelected(form.getFieldValue('entitlementGroup'));
        onFieldChange(null);
    };

    const onEntitlementGroupClear = (key) => {
        setEntitlementGroupSelected([]);
    };

    const onObjectClassSelect = (value) => {
        // console.log('Selected Object Class:');
        // console.log(value);
        setObjectClassSelected(value);
        onFieldChange(null);
    };

    const onB2XRoleSelect = (value) => {
        onFieldChange(null)
    };

    const {
        data: entitlementReportRowData,
        loading: entitlementReportLoading,
        error: entitlementReportError,
        refetch: entitlementRulesRefresh,
    } = useGetEntitlementReportQuery({
        variables: {
            entitlementFilter: searchFilter,
        },
    });

    const onMasterGridReady = (params) => {
        // console.log('onMasterGridReady ready');
        setMasterGridApi(params.api);
        if (masterGridView.current.hasPersonalizedView) {
            masterGridView.current.initPersonalizedView(params.api);
        }
    };

    const onDetailGridReady = (params) => {
        setDetailGridApi(params.api);
        if (detailGridView.current.hasPersonalizedView) {
            detailGridView.current.initPersonalizedView(params.api);
        }
        // setTimeout(() => {
        //     if (detailGridView.current.hasPersonalizedView)
        //     {
        //         detailGridView.current.initPersonalizedView(params.api);
        //     }
        //     else
        //     {
        //         params.api.sizeColumnsToFit();
        //     }
        // }, 100); // Delay to ensure data is fully loaded
    };

    const onFirstDetailDataRendered = (params) => {
        // console.log("onFirstDataRendered");
        // setTimeout(() => {
        //     if (detailGridView.current.hasPersonalizedView)
        //     {
        //         detailGridView.current.initPersonalizedView(params.api);
        //         detailGridView.current.applyPersonalizedView();
        //     }
        //     else
        //     {
        //         params.api.sizeColumnsToFit();
        //     }
        // }, 100); // Delay to ensure data is fully loaded
    };

    const selectMasterRow = () => {
        const selectedRows = masterGridApi!.getSelectedRows();
        if (selectedRows.length > 0) {
            setSelectedMasterRow(selectedRows[0]);
        } else {
            setSelectedMasterRow(null);
        }
    };

    const selectDetailRow = () => {
        const selectedRows = detailGridApi!.getSelectedRows();
        if (selectedRows.length > 0) {
            setSelectedDetailRow(selectedRows[0]);
        } else {
            setSelectedDetailRow(null);
        }
    };

    const onDetailRowDoubleClicked = (params) => {
        //get master row record that corresponds to the detail record double clicked on
        const newRule: EntitlementRule = {
            allowFlag: params.data.allowedOrBlocked,
            company: params.data.company,
            description: params.data.ruleDescription,
            entitlements: params.data.otherEntitlementsOnRule,
            partner: params.data.partner,
            userB2XRole: params.data.b2xRole,
            userDepartment: params.data.department,
            userEnterpriseRole: params.data.enterpriseRole,
            userId: params.data.userId,
            userObjectClass: params.data.objectClass,
            userPermissionRole: params.data.permissionRole,
            groupId: params.data.groupIds,
            ruleId: params.data.ruleId,
        };
        setEntitlementRuleToEdit(newRule);
        setShowEditModal(true);
    };

    const refreshData = () => {
        setRefreshLoading(true);
        dispatch(setEntitlementTypeUpdated(true));
        dispatch(setGroupUpdated(true));
        dispatch(setObjectClassUpdated(true));
        dispatch(setB2XRoleUpdated(true));
        entitlementRulesRefresh()
            .then(({ data }) => {
                setEntitlementReport(data.getEntitlementReport);
                if (masterGridApi) {
                    masterGridApi.setRowData(data.getEntitlementReport);
                }
                setRefreshLoading(false);
            })
            .catch(() => {
                setRefreshLoading(false);
            });
    };

    const clearFilters = () => {
        form.resetFields();
        setSearchFilter({});
        setEntitlementGroupSelected([]);
        setObjectClassSelected([]);
        dispatch(updateEntitlementFilters({}));
    }

    const handleUpdateEntitlement = (updatedEntitlement) => {
       
        dispatch(updateNewEntitlement(undefined));
        refreshData();
        // console.dir(entitlementReport);
        // if (isArray(entitlementReport)) {
        //     const updatedList = entitlementReport
        //         ?.map((item) => (item.groupId === updatedEntitlement.groupId ? updatedEntitlement : item))
        //         .map((groupItems) =>
        //             groupItems.rules.map((rule) =>
        //                 rule.ruleId === updatedEntitlement.ruleId ? updatedEntitlement : rule
        //             )
        //         );
        //     if (masterGridApi) {
        //         masterGridApi.applyTransaction({ update: [updatedEntitlement] });
        //     }
        //     console.log('Updated list:');
        //     console.dir(updatedList);
        //     if (updatedList) {
        //         setEntitlementReport(updatedList);
        //         dispatch(updateNewEntitlement(undefined));
        //     }
        // }
    };

    const cancelEditModal = () => {
        setShowEditModal(false);
        if (entitlementState?.entitlementSchema?.newEntitlement) {
            handleUpdateEntitlement(entitlementState.entitlementSchema.newEntitlement);
        }
    };

    const detailGridOptions = useMemo(
        () => ({
            rowSelection: 'single',
            suppressRowClickSelection: true,
            enableRangeSelection: false,
            pagination: true,
            rowClass: 'agGridRows',
            paginationAutoPageSize: true,
            onRowSelected: selectDetailRow,
            onGridReady: onDetailGridReady,
            onFirstDataRendered: onFirstDetailDataRendered,
            // columnDefs: DETAIL_TABLE_HEADERS,
            columnDefs: detailGridView.current.getView(),
            onRowDoubleClicked: onDetailRowDoubleClicked,
            api: detailGridApi,
            defaultColDef: {
                sortable: true,
                flex: 1,
                resizable: true,
            },
        }),
        []
    );

    const detailCellRendererParams = useMemo(
        () => ({
            detailGridOptions,
            getDetailRowData: (params) =>
                params.successCallback(params.data.details === null ? [] : params.data.details),
        }),
        []
    );

    if (entitlementReportError) {
        message.error(`Error! ${entitlementReportError.message}`);
        console.log(`error: ${entitlementReportError.message}`);
    }

    React.useEffect(() => {
        props.onLoading(entitlementReportLoading);
        if (!entitlementReportLoading && entitlementReportRowData) {
            setEntitlementReport(entitlementReportRowData.getEntitlementReport);
        }
        if (entitlementState?.entitlementSchema?.entitlementFilter)
        {
            form.setFieldsValue(entitlementState?.entitlementSchema?.entitlementFilter);
        }
    }, [entitlementReportLoading, entitlementReportRowData]);

    return (
        <>
            <Spin spinning={entitlementReportLoading || refreshLoading}>
                <Card>
                    <Form form={form} layout="vertical">
                        <Row gutter={24} className={'pl20'}>
                            <Col span={1}>
                                <Button
                                    type="primary"
                                    onClick={handleFormSearch}
                                    icon={<SearchOutlined />}
                                    className={'mr5'}
                                    disabled={entitlementReportLoading}
                                >
                                    Search
                                </Button>
                            </Col>
                            <Col span={1}>
                                <Button type="default" icon={<ReloadOutlined />} onClick={refreshData}>
                                    Refresh
                                </Button>
                            </Col>
                            <Col span={1}>
                                <Button type="default" icon={<ClearOutlined />} onClick={clearFilters}>
                                    Clear Filters
                                </Button>
                            </Col>
                        </Row>
                        <Row gutter={24} className={'pl20'}>
                            <Col span={4}>
                                <Form.Item name="userId" label="User Id">
                                    <Input placeholder="User Id" allowClear={true} onChange={onFieldChange}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="department" label="Department Code">
                                    <Input placeholder="Department Code" allowClear={true} onChange={onFieldChange}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <CompanyCodeDropDown
                                    intl={props.intl}
                                    currentUser={props.currentUser}
                                    distinctEntitlements={props.distinctEntitlements}
                                    selectType={DropDownSelectType.Single}
                                    onSelect={onFieldChange}
                                />
                            </Col>
                            <Col span={4}>
                                <EntitlementGroupDropDown
                                    setEntitlementGroupSelected={onEntitlementGroupSelect}
                                    dropDownSelectType={DropDownSelectType.Multiple}
                                    onGroupClear={onEntitlementGroupClear}
                                />
                            </Col>
                            <Col span={4}>
                                <EntitlementTypeDropDown
                                    entitlementGroupSelected={entitlementGroupSelected}
                                    form={form}
                                    options={[]}
                                    onChange={onFieldChange}
                                />
                            </Col>
                            <Col span={4}>
                                <Form.Item name="allowedOrBlocked" label="Allowed Or Blocked">
                                    <Select placeholder="Allowed Or Blocked" allowClear={true} onSelect={onchange}>
                                        <Select.Option value="A">Allowed</Select.Option>
                                        <Select.Option value="B">Blocked</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <ObjectClassComponent
                                    setObjectClassSelected={onObjectClassSelect}
                                    selectType={DropDownSelectType.Single}
                                    onLoading={props.onLoading}
                                />
                            </Col>
                            <Col span={4}>
                                <B2XRoleDropDown setB2XRoleSelected={onB2XRoleSelect} onLoading={props.onLoading} />
                            </Col>
                        </Row>
                    </Form>
                    <EntitlementEditRuleComponent
                        intl={props.intl}
                        showModal={showEditModal}
                        currentUser={props.currentUser}
                        onCancel={cancelEditModal}
                        onLoading={props.onLoading}
                        entitlementRuleToEdit={entitlementRuleToEdit}
                        distinctEntitlements={props.distinctEntitlements}
                        handleUpdateEntitlement={handleUpdateEntitlement}
                    />
                    {/* <Row gutter={24} style={{ paddingTop: '5px' }}>
                    <Col style={{ paddingRight: '5px' }}>
                        <Dropdown.Button
                            data-testid={'view-result-customization'}
                            overlay={menu}
                            trigger={['click']}
                            icon={downIcon()}
                            size={'middle'}
                        >
                            <EyeOutlined />
                            {props.intl.formatMessage({
                                id: 'info-portal-view',
                                defaultMessage: 'View',
                            })}
                        </Dropdown.Button>
                    </Col>
                    <Col style={{ paddingRight: '5px' }}>
                        <Tooltip
                            title={props.intl.formatMessage({ id: 'reset-filter' })}>
                            <Button
                                data-testid={'view-result-customization-reset'}
                                onClick={onReset} size={'middle'}
                                icon={<ReloadOutlined
                                    className={moduleStyles.simpleFlipRefresh} />}>
                                {props.intl.formatMessage({ id: 'info-portal-reset' })}
                            </Button>
                        </Tooltip>
                    </Col>
                </Row> */}
                    <Row gutter={24}>
                        <Col span={20} className="mb5">
                            <div
                                style={{ flex: 'auto', height: '500px', paddingTop: '0px' }}
                                className="ag-theme-balham"
                            >
                                <AgGridReact
                                    defaultColDef={{
                                        enableValue: true,
                                        sortable: true,
                                        resizable: true,
                                    }}
                                    // columnDefs={ENTITLEMENT_RULES_TABLE_HEADERS()}
                                    columnDefs={masterGridView.current.getView()}
                                    rowData={entitlementReport || []}
                                    localeText={getAgGridLocalization(props.intl)}
                                    sideBar={false}
                                    api={masterGridApi}
                                    onGridSizeChanged={() => masterGridApi.sizeColumnsToFit()}
                                    enableBrowserTooltips={true}
                                    rowClass="agGridRows"
                                    rowSelection="single"
                                    suppressMenuHide={true}
                                    debug={false}
                                    pagination={true}
                                    paginationPageSize={15}
                                    suppressRowClickSelection={true}
                                    onGridReady={onMasterGridReady}
                                    onRowSelected={selectMasterRow}
                                    masterDetail={true}
                                    detailCellRendererParams={detailCellRendererParams}
                                />
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Spin>
        </>
    );
};

export default SearchEntitlementsComponent;
