import client from "../../config/GraphQLApolloClient";
import { MY_DOCS_DATA } from "./queries";
import { getDocumentList, getDocumentListVariables } from "./__generated__/getDocumentList";


export const getDocumentsData = (username: string, input) => {
  console.log(input);
  return new Promise<getDocumentList>((resolve, reject) => {
    client.query<getDocumentList, getDocumentListVariables>({
      query: MY_DOCS_DATA,
      variables: {
        documentInput: input
      },
      fetchPolicy: "network-only"
    })
      .then((documents) => {
        const response: getDocumentList = { getPartnerDocuments: documents.data.getPartnerDocuments };
        resolve(
          response
        );
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};