import client from "../../config/GraphQLApolloClient";
import { getNotifications } from "../survey/__generated__/getNotifications";
import { getNotificationCount } from "../survey/__generated__/getNotificationCount";
import { getBannerDetailsData } from "../survey/__generated__/getBannerDetailsData"
import { QUERY_SURVEY_NOTIFICATIONS, QUERY_SURVEY_NOTIFICATION_COUNT, QUERY_GET_BANNER_DETAILS } from "../survey/queries";
import { ADD_BANNER_DATA, EDIT_BANNER_DATA, DELETE_BANNER_DATA, UPDATE_NOTIFICATION_READ_DATA, CREATE_NOTIFICATION_TEMPLATE_DATA, ADD_NOTIFICATION_DATA } from "../survey/mutations";
import { NotificationInput, BannerEditRequest, AddBannerInput, NotificationTemplateInput, AddNotificationInput } from "../../../__generated__/globalTypes"
//import { UPDATE_NOTIFICATION_READ_DATA,  } from "../survey/mutations";
//import { NotificationInput, NotificationTemplateInput, AddNotificationInput } from "../../../__generated__/globalTypes";

export const getUserNotifications = () =>
{
    return new Promise<getNotifications>((resolve, reject) => {
        client.query<getNotifications>({
            query: QUERY_SURVEY_NOTIFICATIONS,
            variables: {},
            fetchPolicy: "network-only"
        })
          .then((response) => {
            const notifications: getNotifications = { getNotifications: response.data.getNotifications };
            resolve(
                notifications
            );
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      });
}

export const getUserNotificationCount = () =>
{
    return new Promise<getNotificationCount>((resolve, reject) => {
        client.query<getNotificationCount>({
          query: QUERY_SURVEY_NOTIFICATION_COUNT,
          variables: {},
          fetchPolicy: "network-only"
        })
          .then((response) => {
            const count: getNotificationCount = { getNotificationCount: response.data?.getNotificationCount };
            resolve(
              count
            );
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
    });
}

export const updateNotifications = (notificationData: NotificationInput[]) => {
  return new Promise((resolve, reject) => {
    client.mutate({
      mutation: UPDATE_NOTIFICATION_READ_DATA,
      variables: { authentication: window.localStorage.getItem("id_token"), notificationInput: notificationData }
    }).then((response) => {
        resolve(response.data)
    }).catch((err) => {
      console.error(err);
      reject(err);
    });

  });
};

export const createNotificationTemplateData = (notificationTemplateData: NotificationTemplateInput) => {
  return new Promise((resolve, reject) => {
    client.mutate({
      mutation: CREATE_NOTIFICATION_TEMPLATE_DATA,
      variables: {authentication: window.localStorage.getItem("id_token"), notificationTemplateInput: notificationTemplateData }
    }).then((response) => {
        resolve(response.data)
    }).catch((err) => {
      console.error(err);
      reject(err);
    });

  });
};

export const addNotificationData = (notificationData: AddNotificationInput) => {
  return new Promise((resolve, reject) => {
    client.mutate({
      mutation: ADD_NOTIFICATION_DATA,
      variables: {authentication: window.localStorage.getItem("id_token"), addNotificationInput: notificationData }
    }).then((response) => {
        resolve(response.data)
    }).catch((err) => {
      console.error(err);
      reject(err);
    });

  });
};

export const getBannerDetails = () =>
{
    return new Promise<getBannerDetailsData>((resolve, reject) => {
        client.query<getBannerDetailsData>({
          query: QUERY_GET_BANNER_DETAILS,
          variables: {},
          fetchPolicy: "network-only"
        })
          .then((response) => {
            const count: getBannerDetailsData = { getBannerDetailsData: response.data?.getBannerDetailsData };
            resolve(
              count
            );
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
    });
}

export const addBannerData = (addBannerInputData: AddBannerInput) => {
  return new Promise((resolve, reject) => {
    client.mutate({
      mutation: ADD_BANNER_DATA,
      variables: {authentication: window.localStorage.getItem("id_token"), addBannerInput : addBannerInputData }
    }).then((response) => {
        resolve(response.data)
    }).catch((err) => {
      console.error(err);
      reject(err);
    });

  });
};

export const editBannerData = (bannerEditRequestData: BannerEditRequest) => {
  return new Promise((resolve, reject) => {
    client.mutate({
      mutation: EDIT_BANNER_DATA,
      variables: {authentication: window.localStorage.getItem("id_token"), bannerEditRequest : bannerEditRequestData}
    }).then((response) => {
        resolve(response.data)
    }).catch((err) => {
      console.log("****************")
      console.error(err);
      reject(err);
    });

  });
};

export const deleteBannerData = (bannerId: number) => {
  return new Promise((resolve, reject) => {
    client.mutate({
      mutation: DELETE_BANNER_DATA,
      variables: {authentication: window.localStorage.getItem("id_token"), bannerId}
    }).then((response) => {
        resolve(response.data)
    }).catch((err) => {
      console.error(err);
      reject(err);
    });

  });
};