import React, { useEffect, useState } from "react";
import { Col, Collapse, Row, Select, Spin } from "antd";
import { useIntl } from "react-intl";
import { ECAPAddressInfo } from "../componets/ECAP-Address-component";
import { ECAPContactPerson } from "../componets/ECAP-ContactPerson-component";
import { getDocumentRecord } from "../__generated__/getDocumentRecord";
import { CaretRightOutlined } from "@ant-design/icons";
import { getI18n } from "../../../utils/Utils";
import { IAppComponentProps } from "../../../components";
import { FormInstance } from "antd/es/form/Form";
import { useAppDispatch, useAppSelector } from "../../../main/hooks";
import { updateCollapsedState } from "../IStoredDocumentRecordSlice";
import { gql } from "@apollo/client";

const { Panel } = Collapse;
const { Option } = Select;

type CompanyDetailsProps = {
  form: FormInstance<any>;
  documentRecord: getDocumentRecord;
} & IAppComponentProps;

const CompanyComponent: React.FunctionComponent<CompanyDetailsProps> = (props) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const form = props.form;
  const [loading, isLoading] = useState(false);
  const { document } = useAppSelector((state) => state.document);
  //get the state of the selected partner record
  const { partnerRecord } = useAppSelector((state) => state.partnerRecord);
  const rules = useAppSelector((state) => state.rules);

  const initFields = () => {

    if (props.documentRecord !== undefined) {
      if (partnerRecord && partnerRecord.companies && partnerRecord.companies.length === 1) {
        form.setFieldsValue({
          companyCode: partnerRecord.companies
            .at(0)
            .companyCode.concat(" - ".concat(partnerRecord.companies.at(0).companyName))
        });
      }

      //Set BMW contact details
      form.setFieldsValue({
        companyContactName: props.documentRecord?.getDocumentRecord?.document?.bmwContactName
      });
      form.setFieldsValue({
        companyContactDepartment: props.documentRecord?.getDocumentRecord?.document?.bmwContactDepartment
      });
      form.setFieldsValue({
        companyContactTelephone: props.documentRecord?.getDocumentRecord?.document?.bmwContactTelephone
      });
      form.setFieldsValue({
        companyContactEmail: props.documentRecord?.getDocumentRecord?.document?.bmwContactEmail
      });
    }
  };

  useEffect(() => {
    initFields();
  }, []);

  return (
    // <RenderIf render={document.partnerId !== undefined}>
    <Spin spinning={loading} size={"large"}>
      <Row key={"companyDetailsRow"} gutter={24}>
        <Col span={24}>
          <Collapse
            activeKey={document.collapsedState}
            onChange={(newActiveKeys) => {
              // otherwise panels couldn't be open/closed by click
              dispatch(updateCollapsedState(newActiveKeys));
            }}
            defaultActiveKey={1}
            className={"ml-10"}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          >
            <Panel forceRender={true} key={"companyPanel"}
                   header={getI18n("bmwcompany-datails-panel-header", "BMW Company", intl)}>

              <Collapse
                defaultActiveKey={2}
                activeKey={document.collapsedState}
                onChange={(newActiveKeys) => {
                  // otherwise panels couldn't be open/closed by click
                  dispatch(updateCollapsedState(newActiveKeys));
                }}
                className={"ml2"}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              >
                <Panel
                  forceRender={true}
                  key={1.1}
                  header={getI18n("general-address-title", "BMW Company Address", intl)}
                >
                  <ECAPAddressInfo
                    streetFldName="companyStreet1"
                    countryDisplayFldName="companyCountry"
                    countryCodeFldName="companyCountryCode"
                    postalCodeFldName="companyPostalCode"
                    regionDisplayFldName="companyRegion"
                    regionCodeFldName="companyRegionCode"
                    cityFldName="companyCity"
                    form={form}
                    editable={false}
                    noTitle={true}
                    noRegionField={true}
                  />
                </Panel>
                <Panel
                  forceRender={true}
                  key={1.2}
                  header={getI18n("general-contact-person-title", "BMW Company Contact Person", intl)}
                >
                  <ECAPContactPerson
                    contactNameFldName={["document", "bmwContactName"]}
                    contactDepartmentFldName={["document", "bmwContactDepartment"]}
                    contactTelephoneFldName={["document", "bmwContactTelephone"]}
                    contactEmailFldName={["document", "bmwContactEmail"]}
                    form={form}
                    noTitle={true}
                    rules={rules}
                    document={document}
                  />
                </Panel>
              </Collapse>
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </Spin>
    // </RenderIf>
  );
};

export default CompanyComponent;