import { gql } from "@apollo/client";


export const GET_ALL_DEFAULT_PROCESS = gql`
    query getDefaultProcessCodes($partnerType : String!,$companyCode : String!){
        getDefaultProcessCodes(partnerType: $partnerType,companyCode: $companyCode) {
            processCodeId
            processCode
            processCodeName
            format
            channel
            updatedFormat
            updatedChannel
            defaultAlternative
            direction
            formatOptions
        }
    }
`;

export const GET_PROCESS_DETAILS = gql`
    query getProcessCodeName($processCodes : [SurveyDocumentProcessChannelCodeInput!]!){
        getProcessCodeName(processCodes: $processCodes) {
            processCodeId
            processCode
            processCodeName
            phraseId
            format
            channel
            updatedFormat
            updatedChannel
            defaultAlternative
            direction
        }
    }
`;

export const GET_ALL_DEFAULT_PROCESS_CHANNEL_FORMATS = gql`
    query getDefaultChannelFormat($partnerType : String!,$companyCode : String!,$processCode : String!,$direction : String!){
        getDefaultChannelFormat(partnerType: $partnerType,companyCode: $companyCode, processCode: $processCode, direction: $direction) {
            partnerType
            companyCode
            processCode
            direction
            format {
                formatName
                channel{
                    channelName
                    defaultAlternative
                    provider
                }
            }
        }
    }
`;

export const INSERT_SURVEY_CHANNELS = gql`
    mutation insertSurveyChannels($includedSurveyIds: String!, $partnerType: String!, $surveyChannels : [SurveyChannelInput!]!){
        insertSurveyChannels(includedSurveyIds:$includedSurveyIds,partnerType: $partnerType,surveyChannels: $surveyChannels) {
            surveyId
            surveyChannelId
            processCodeId
            documentType
            format
            channel
            defaultFormat
            defaultChannel
            channelTypeId
            formatId
            documentTypeId
            channelDefault
            acceptedDate
            processCodeName
            processCode
            provider
        }
    }
`;