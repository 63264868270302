import { Spin, Tabs } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { IAppComponentProps } from '../../../components';
import { getI18n } from '../../../utils/Utils';
import SearchEntitlementsComponent from './EntitlementsSearchComponent';
import CreateEntitlement from './EntitlementCreateComponent';
import EntitlementMasterData from './MasterDataTabComponent';
import EntitlementAuditComponent from './EntitlementAuditComponent';
import RenderIfEntitled from '../../../components/EntitlementComponents';
import { entitlementExists } from '../../../main/entitlementService';
import { ADMIN_ENTITLEMENT, EDIT_ENTITLEMENT, VIEW_ENTITLEMENT } from './EntitlementInterfaces';
import AppliedEntitlementsTabComponent from './AppliedEntitlementsTabComponent';

const EntitlementsMain: React.FunctionComponent<IAppComponentProps> = (props) => {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);

    const onLoading = (loading) => {
        setLoading(loading);
    };

    const tabItems = [
        {
            defaultActiveKey: '1',
            className:"users-tabs ml20",
            key: '1',
            label: getI18n('entitlements-dashboard-search-entitlements-tab','Search Entitlements',props.intl),
            children:   
                <SearchEntitlementsComponent
                    intl={props.intl}
                    onLoading={onLoading}
                    currentUser={props.currentUser}
                    distinctEntitlements={props.distinctEntitlements}
                />,
        },
        {
            className:"users-tabs ml20",
            key: '2',
            label: getI18n('entitlements-dashboard-applied-entitlements-tab','Applied Entitlements',props.intl),
            children:   
                <AppliedEntitlementsTabComponent
                    intl={props.intl}
                    onLoading={onLoading}
                    currentUser={props.currentUser}
                    distinctEntitlements={props.distinctEntitlements}
                />,
        },
        ...(entitlementExists(EDIT_ENTITLEMENT, props.distinctEntitlements )
            ? [
                  {
                      key: '3',
                      label: getI18n('entitlements-dashboard-create-access-tab', 'Create Entitlements', intl),
                      children: 
                        <RenderIfEntitled
                            entitlementToCheck={EDIT_ENTITLEMENT}
                            distinctEntitlements={props.distinctEntitlements}
                            intl={props.intl}
                        >
                            <CreateEntitlement
                                intl={props.intl}
                                onLoading={onLoading}
                                currentUser={props.currentUser}
                                distinctEntitlements={props.distinctEntitlements}
                            />
                        </RenderIfEntitled>
                      ,
                  },
              ]
            : []),
            ...(entitlementExists(ADMIN_ENTITLEMENT, props.distinctEntitlements )
            ? [
                  {
                      key: '4',
                      label: getI18n('entitlements-dashboard-config-tab', 'Master Data', intl),
                      children: 
                        <RenderIfEntitled
                        entitlementToCheck={ADMIN_ENTITLEMENT}
                        distinctEntitlements={props.distinctEntitlements}
                        intl={props.intl}
                        >
                            <EntitlementMasterData
                                intl={props.intl}
                                onLoading={onLoading}
                                currentUser={props.currentUser}
                                distinctEntitlements={props.distinctEntitlements}
                            />
                        </RenderIfEntitled>
                      ,
                  },
              ]
            : []),
            ...(entitlementExists(ADMIN_ENTITLEMENT, props.distinctEntitlements )
            ? [
                  {
                      key: '5',
                      label: getI18n('entitlements-dashboard-audit-tab', 'Entitlement Audit', intl),
                      children:
                        <RenderIfEntitled
                            entitlementToCheck={ADMIN_ENTITLEMENT}
                            distinctEntitlements={props.distinctEntitlements}
                            intl={props.intl}
                        >
                            <EntitlementAuditComponent
                                intl={props.intl}
                                onLoading={onLoading}
                                currentUser={props.currentUser}
                                distinctEntitlements={props.distinctEntitlements}
                            />
                        </RenderIfEntitled>
                      ,
                  },
              ]
            : []),
    ];

    return (
        <>
            <RenderIfEntitled
                entitlementToCheck={VIEW_ENTITLEMENT}
                distinctEntitlements={props.distinctEntitlements}
                intl={props.intl}
            >
                <Spin size="large" spinning={loading}>
                    <h1>Entitlement Maintenance</h1>
                    <Tabs defaultActiveKey="1" items={tabItems} />
                </Spin>
            </RenderIfEntitled>
        </>
    );
};

export default EntitlementsMain;
