import React, { useEffect, useState } from 'react';
import { Form, message, Select, Spin } from 'antd';
import { useGetEntitlementGroupListQuery } from './queries.generated';
import { EntitlementGroup } from 'src/__generated__/types';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../main/hooks';
import { setGroupUpdated } from './redux/IStoredEntitlementsSlice';

const key = 'entitlementGroup';

const EntitlementGroupDropDown = (props: any) => {
    const [entitlementGroups, setEntitlementGroups] = useState<EntitlementGroup[]>([]);
    const dispatch = useDispatch();
    const entitlementState = useAppSelector((state) => state.entitlements);
    const [refreshLoading, setRefreshLoading] = useState(false);

    const onGroupSelect = (value) => {
        props.setEntitlementGroupSelected(key,value);
    }

const { data, loading, error, refetch: refetchData } = useGetEntitlementGroupListQuery({
       variables: {
       },
     });

    if (error) message.error(error);

    useEffect(() => {
        if (entitlementState?.entitlementSchema?.groupsUpdated && !loading)
        {
            setRefreshLoading(true);
            refetchData().then(({data}) => {
                dispatch(setGroupUpdated(false));
                setEntitlementGroups([...data.getEntitlementGroupList, {groupId:"ANY", description: "ANY Group"} ]);
                setRefreshLoading(false);
            });
        }
        else 
        if (!loading && (!entitlementGroups || entitlementGroups.length ===0) && data)
        {
            setEntitlementGroups([...data.getEntitlementGroupList, {groupId:"ANY", description: "ANY Group"} ]);
        }
    }, [loading, entitlementGroups, entitlementState?.entitlementSchema?.groupsUpdated]);


    return (
        <Spin spinning={loading || refreshLoading}>
            <Form.Item
                name="entitlementGroup"
                label="Entitlement Groups"
                rules={[{ required: props.required, message: 'Please select entitlement groups' }]}
            >
                <Select
                    mode={props?.dropDownSelectType}
                    placeholder="Select entitlement groups"
                    style={{ width: '100%' }} // This is a workaround for the select dropdown not expanding to full width
                    onSelect={onGroupSelect}
                    onDeselect={onGroupSelect}
                    onClear={props.onGroupClear}
                    allowClear={true}
                    showSearch={true}
                    filterOption={(input, option) =>
                        option?.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {entitlementGroups?.map((entitlementGroup) => (
                        <Select.Option key={entitlementGroup.groupId} value={entitlementGroup.groupId}>
                            {entitlementGroup.description.concat(' (', entitlementGroup.groupId, ')')}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        </Spin>
    );
};

export default EntitlementGroupDropDown;
