import * as Types from '../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserEntitlementResultByUserIdQueryVariables = Types.Exact<{
  userId: Types.Scalars['String']['input'];
  authentication: Types.Scalars['String']['input'];
  authRedirectURL: Types.Scalars['String']['input'];
}>;


export type GetUserEntitlementResultByUserIdQuery = { __typename?: 'Query', getUserEntitlementResultByUserId?: { __typename?: 'UserEntitlementResult', distinctEntitlements?: Array<string> | null, appliedRuleIds?: Array<number> | null, partners?: Array<{ __typename?: 'EntitlementPartner', partnerNo?: string | null, partnerName?: string | null, partnerOu?: string | null, partnerId?: number | null, partnerInDB?: boolean | null, entitlements?: Array<string> | null, category?: string | null, appliedRuleIds?: Array<number> | null, companies?: Array<{ __typename?: 'EntitlementCompany', companyCode?: string | null, companyName?: string | null, companyId?: number | null }> | null }> | null } | null };

export type GetEcapUserEntitlementResultByUserIdQueryVariables = Types.Exact<{
  userId: Types.Scalars['String']['input'];
  authentication: Types.Scalars['String']['input'];
}>;


export type GetEcapUserEntitlementResultByUserIdQuery = { __typename?: 'Query', getEcapUserEntitlementResultByUserId?: { __typename?: 'UserEntitlementResult', distinctEntitlements?: Array<string> | null, appliedRuleIds?: Array<number> | null, partners?: Array<{ __typename?: 'EntitlementPartner', partnerNo?: string | null, partnerName?: string | null, partnerOu?: string | null, partnerId?: number | null, partnerInDB?: boolean | null, entitlements?: Array<string> | null, category?: string | null, appliedRuleIds?: Array<number> | null, companies?: Array<{ __typename?: 'EntitlementCompany', companyCode?: string | null, companyName?: string | null, companyId?: number | null }> | null }> | null } | null };

export type GetEcapUserCaptureEntitlementResultByUserIdQueryVariables = Types.Exact<{
  userId: Types.Scalars['String']['input'];
  authentication: Types.Scalars['String']['input'];
}>;


export type GetEcapUserCaptureEntitlementResultByUserIdQuery = { __typename?: 'Query', getEcapUserCaptureEntitlementResultByUserId?: { __typename?: 'EcapUserEntitlementResult', distinctEntitlements?: Array<string> | null, appliedRuleIds?: Array<number> | null, partners?: Array<{ __typename?: 'EcapEntitlementPartner', partnerNo?: string | null, partnerName?: string | null, partnerOu?: string | null, systemCode?: string | null, partnerId?: number | null, partnerInDB?: boolean | null, entitlements?: Array<string> | null, category?: string | null, appliedRuleIds?: Array<number> | null, companies?: Array<{ __typename?: 'EntitlementCompany', companyCode?: string | null, companyName?: string | null, companyId?: number | null }> | null }> | null } | null };

export type EdpPartnerAndCompanyQueryVariables = Types.Exact<{
  userId: Types.Scalars['String']['input'];
  entitlement: Types.Scalars['String']['input'];
  pageNumber: Types.Scalars['Float']['input'];
  pageSize: Types.Scalars['Float']['input'];
}>;


export type EdpPartnerAndCompanyQuery = { __typename?: 'Query', getUserEntitlementResultByUserIdAndEntitlement?: { __typename?: 'UserEntitlementResult', distinctEntitlements?: Array<string> | null, appliedRuleIds?: Array<number> | null, partners?: Array<{ __typename?: 'EntitlementPartner', partnerNo?: string | null, partnerName?: string | null, partnerOu?: string | null, partnerId?: number | null, partnerInDB?: boolean | null, entitlements?: Array<string> | null, category?: string | null, appliedRuleIds?: Array<number> | null, companies?: Array<{ __typename?: 'EntitlementCompany', companyCode?: string | null, companyName?: string | null, companyId?: number | null }> | null }> | null } | null };

export type GetUserEntitlementResultByUserIdAndFilterQueryVariables = Types.Exact<{
  userId: Types.Scalars['String']['input'];
  entitlement: Types.Scalars['String']['input'];
  partnerName: Types.Scalars['String']['input'];
  authentication: Types.Scalars['String']['input'];
}>;


export type GetUserEntitlementResultByUserIdAndFilterQuery = { __typename?: 'Query', getUserEntitlementResultByUserIdAndFilter?: { __typename?: 'UserEntitlementResult', distinctEntitlements?: Array<string> | null, appliedRuleIds?: Array<number> | null, partners?: Array<{ __typename?: 'EntitlementPartner', partnerNo?: string | null, partnerName?: string | null, partnerOu?: string | null, partnerInDB?: boolean | null, partnerId?: number | null, entitlements?: Array<string> | null, category?: string | null, appliedRuleIds?: Array<number> | null, companies?: Array<{ __typename?: 'EntitlementCompany', companyCode?: string | null, companyName?: string | null, companyId?: number | null }> | null }> | null } | null };

export type GetDocumentUserEntitlementResultByUserIdQueryVariables = Types.Exact<{
  userId: Types.Scalars['String']['input'];
  entitlement: Types.Scalars['String']['input'];
}>;


export type GetDocumentUserEntitlementResultByUserIdQuery = { __typename?: 'Query', getDocumentUserEntitlementResultByUserId?: { __typename?: 'UserEntitlementResult', distinctEntitlements?: Array<string> | null, appliedRuleIds?: Array<number> | null, partners?: Array<{ __typename?: 'EntitlementPartner', partnerNo?: string | null, partnerName?: string | null, partnerOu?: string | null, partnerInDB?: boolean | null, partnerId?: number | null, entitlements?: Array<string> | null, category?: string | null, appliedRuleIds?: Array<number> | null, companies?: Array<{ __typename?: 'EntitlementCompany', companyCode?: string | null, companyName?: string | null, companyId?: number | null }> | null }> | null } | null };

export type GetEcapSelfEntitlementResultByFilterQueryVariables = Types.Exact<{
  entitlement: Types.Scalars['String']['input'];
  partnerName: Types.Scalars['String']['input'];
  pageNumber: Types.Scalars['Float']['input'];
  pageSize: Types.Scalars['Float']['input'];
}>;


export type GetEcapSelfEntitlementResultByFilterQuery = { __typename?: 'Query', getEcapSelfEntitlementResultByFilter?: { __typename?: 'EcapUserEntitlementResult', distinctEntitlements?: Array<string> | null, appliedRuleIds?: Array<number> | null, partners?: Array<{ __typename?: 'EcapEntitlementPartner', partnerNo?: string | null, partnerName?: string | null, systemCode?: string | null, partnerOu?: string | null, partnerId?: number | null, partnerInDB?: boolean | null, entitlements?: Array<string> | null, category?: string | null, appliedRuleIds?: Array<number> | null, companies?: Array<{ __typename?: 'EntitlementCompany', companyCode?: string | null, companyName?: string | null, companyId?: number | null }> | null }> | null } | null };

export type GetSelfEntitlementResultByFilterQueryVariables = Types.Exact<{
  entitlement: Types.Scalars['String']['input'];
  partnerName: Types.Scalars['String']['input'];
  pageNumber: Types.Scalars['Float']['input'];
  pageSize: Types.Scalars['Float']['input'];
}>;


export type GetSelfEntitlementResultByFilterQuery = { __typename?: 'Query', getSelfEntitlementResultByFilter?: { __typename?: 'UserEntitlementResult', distinctEntitlements?: Array<string> | null, appliedRuleIds?: Array<number> | null, partners?: Array<{ __typename?: 'EntitlementPartner', partnerNo?: string | null, partnerName?: string | null, partnerOu?: string | null, partnerId?: number | null, partnerInDB?: boolean | null, entitlements?: Array<string> | null, category?: string | null, appliedRuleIds?: Array<number> | null, companies?: Array<{ __typename?: 'EntitlementCompany', companyCode?: string | null, companyName?: string | null, companyId?: number | null }> | null }> | null } | null };

export type GetPartnersByCompanyCodesAndFilterQueryVariables = Types.Exact<{
  entitlement: Types.Scalars['String']['input'];
  partnerFilter: Types.Scalars['String']['input'];
  companyCodes: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
  pageNumber: Types.Scalars['Float']['input'];
  pageSize: Types.Scalars['Float']['input'];
}>;


export type GetPartnersByCompanyCodesAndFilterQuery = { __typename?: 'Query', getPartnersByCompanyCodesAndFilter?: Array<{ __typename?: 'EntitledPartner', partnerNo?: string | null, partnerName?: string | null, partnerId?: number | null, category?: string | null }> | null };

export type GetCompaniesByEntitlementQueryVariables = Types.Exact<{
  entitlement: Types.Scalars['String']['input'];
}>;


export type GetCompaniesByEntitlementQuery = { __typename?: 'Query', allCompaniesForEntitlement?: Array<{ __typename?: 'EntitledCompany', companyId?: number | null, companyName?: string | null, companyCode?: string | null }> | null };


export const GetUserEntitlementResultByUserIdDocument = gql`
    query getUserEntitlementResultByUserId($userId: String!, $authentication: String!, $authRedirectURL: String!) {
  getUserEntitlementResultByUserId(
    userId: $userId
    authentication: $authentication
    authRedirectURL: $authRedirectURL
  ) {
    partners {
      partnerNo
      partnerName
      partnerOu
      partnerId
      partnerInDB
      entitlements
      companies {
        companyCode
        companyName
        companyId
      }
      category
      appliedRuleIds
    }
    distinctEntitlements
    appliedRuleIds
  }
}
    `;

/**
 * __useGetUserEntitlementResultByUserIdQuery__
 *
 * To run a query within a React component, call `useGetUserEntitlementResultByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEntitlementResultByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEntitlementResultByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      authentication: // value for 'authentication'
 *      authRedirectURL: // value for 'authRedirectURL'
 *   },
 * });
 */
export function useGetUserEntitlementResultByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserEntitlementResultByUserIdQuery, GetUserEntitlementResultByUserIdQueryVariables> & ({ variables: GetUserEntitlementResultByUserIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserEntitlementResultByUserIdQuery, GetUserEntitlementResultByUserIdQueryVariables>(GetUserEntitlementResultByUserIdDocument, options);
      }
export function useGetUserEntitlementResultByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserEntitlementResultByUserIdQuery, GetUserEntitlementResultByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserEntitlementResultByUserIdQuery, GetUserEntitlementResultByUserIdQueryVariables>(GetUserEntitlementResultByUserIdDocument, options);
        }
export function useGetUserEntitlementResultByUserIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserEntitlementResultByUserIdQuery, GetUserEntitlementResultByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserEntitlementResultByUserIdQuery, GetUserEntitlementResultByUserIdQueryVariables>(GetUserEntitlementResultByUserIdDocument, options);
        }
export type GetUserEntitlementResultByUserIdQueryHookResult = ReturnType<typeof useGetUserEntitlementResultByUserIdQuery>;
export type GetUserEntitlementResultByUserIdLazyQueryHookResult = ReturnType<typeof useGetUserEntitlementResultByUserIdLazyQuery>;
export type GetUserEntitlementResultByUserIdSuspenseQueryHookResult = ReturnType<typeof useGetUserEntitlementResultByUserIdSuspenseQuery>;
export type GetUserEntitlementResultByUserIdQueryResult = Apollo.QueryResult<GetUserEntitlementResultByUserIdQuery, GetUserEntitlementResultByUserIdQueryVariables>;
export const GetEcapUserEntitlementResultByUserIdDocument = gql`
    query getEcapUserEntitlementResultByUserId($userId: String!, $authentication: String!) {
  getEcapUserEntitlementResultByUserId(
    userId: $userId
    authentication: $authentication
  ) {
    partners {
      partnerNo
      partnerName
      partnerOu
      partnerId
      partnerInDB
      entitlements
      companies {
        companyCode
        companyName
        companyId
      }
      category
      appliedRuleIds
    }
    distinctEntitlements
    appliedRuleIds
  }
}
    `;

/**
 * __useGetEcapUserEntitlementResultByUserIdQuery__
 *
 * To run a query within a React component, call `useGetEcapUserEntitlementResultByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEcapUserEntitlementResultByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEcapUserEntitlementResultByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      authentication: // value for 'authentication'
 *   },
 * });
 */
export function useGetEcapUserEntitlementResultByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetEcapUserEntitlementResultByUserIdQuery, GetEcapUserEntitlementResultByUserIdQueryVariables> & ({ variables: GetEcapUserEntitlementResultByUserIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEcapUserEntitlementResultByUserIdQuery, GetEcapUserEntitlementResultByUserIdQueryVariables>(GetEcapUserEntitlementResultByUserIdDocument, options);
      }
export function useGetEcapUserEntitlementResultByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEcapUserEntitlementResultByUserIdQuery, GetEcapUserEntitlementResultByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEcapUserEntitlementResultByUserIdQuery, GetEcapUserEntitlementResultByUserIdQueryVariables>(GetEcapUserEntitlementResultByUserIdDocument, options);
        }
export function useGetEcapUserEntitlementResultByUserIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEcapUserEntitlementResultByUserIdQuery, GetEcapUserEntitlementResultByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEcapUserEntitlementResultByUserIdQuery, GetEcapUserEntitlementResultByUserIdQueryVariables>(GetEcapUserEntitlementResultByUserIdDocument, options);
        }
export type GetEcapUserEntitlementResultByUserIdQueryHookResult = ReturnType<typeof useGetEcapUserEntitlementResultByUserIdQuery>;
export type GetEcapUserEntitlementResultByUserIdLazyQueryHookResult = ReturnType<typeof useGetEcapUserEntitlementResultByUserIdLazyQuery>;
export type GetEcapUserEntitlementResultByUserIdSuspenseQueryHookResult = ReturnType<typeof useGetEcapUserEntitlementResultByUserIdSuspenseQuery>;
export type GetEcapUserEntitlementResultByUserIdQueryResult = Apollo.QueryResult<GetEcapUserEntitlementResultByUserIdQuery, GetEcapUserEntitlementResultByUserIdQueryVariables>;
export const GetEcapUserCaptureEntitlementResultByUserIdDocument = gql`
    query getEcapUserCaptureEntitlementResultByUserId($userId: String!, $authentication: String!) {
  getEcapUserCaptureEntitlementResultByUserId(
    userId: $userId
    authentication: $authentication
  ) {
    partners {
      partnerNo
      partnerName
      partnerOu
      systemCode
      partnerId
      partnerInDB
      entitlements
      companies {
        companyCode
        companyName
        companyId
      }
      category
      appliedRuleIds
    }
    distinctEntitlements
    appliedRuleIds
  }
}
    `;

/**
 * __useGetEcapUserCaptureEntitlementResultByUserIdQuery__
 *
 * To run a query within a React component, call `useGetEcapUserCaptureEntitlementResultByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEcapUserCaptureEntitlementResultByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEcapUserCaptureEntitlementResultByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      authentication: // value for 'authentication'
 *   },
 * });
 */
export function useGetEcapUserCaptureEntitlementResultByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetEcapUserCaptureEntitlementResultByUserIdQuery, GetEcapUserCaptureEntitlementResultByUserIdQueryVariables> & ({ variables: GetEcapUserCaptureEntitlementResultByUserIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEcapUserCaptureEntitlementResultByUserIdQuery, GetEcapUserCaptureEntitlementResultByUserIdQueryVariables>(GetEcapUserCaptureEntitlementResultByUserIdDocument, options);
      }
export function useGetEcapUserCaptureEntitlementResultByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEcapUserCaptureEntitlementResultByUserIdQuery, GetEcapUserCaptureEntitlementResultByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEcapUserCaptureEntitlementResultByUserIdQuery, GetEcapUserCaptureEntitlementResultByUserIdQueryVariables>(GetEcapUserCaptureEntitlementResultByUserIdDocument, options);
        }
export function useGetEcapUserCaptureEntitlementResultByUserIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEcapUserCaptureEntitlementResultByUserIdQuery, GetEcapUserCaptureEntitlementResultByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEcapUserCaptureEntitlementResultByUserIdQuery, GetEcapUserCaptureEntitlementResultByUserIdQueryVariables>(GetEcapUserCaptureEntitlementResultByUserIdDocument, options);
        }
export type GetEcapUserCaptureEntitlementResultByUserIdQueryHookResult = ReturnType<typeof useGetEcapUserCaptureEntitlementResultByUserIdQuery>;
export type GetEcapUserCaptureEntitlementResultByUserIdLazyQueryHookResult = ReturnType<typeof useGetEcapUserCaptureEntitlementResultByUserIdLazyQuery>;
export type GetEcapUserCaptureEntitlementResultByUserIdSuspenseQueryHookResult = ReturnType<typeof useGetEcapUserCaptureEntitlementResultByUserIdSuspenseQuery>;
export type GetEcapUserCaptureEntitlementResultByUserIdQueryResult = Apollo.QueryResult<GetEcapUserCaptureEntitlementResultByUserIdQuery, GetEcapUserCaptureEntitlementResultByUserIdQueryVariables>;
export const EdpPartnerAndCompanyDocument = gql`
    query EDPPartnerAndCompany($userId: String!, $entitlement: String!, $pageNumber: Float!, $pageSize: Float!) {
  getUserEntitlementResultByUserIdAndEntitlement(
    userId: $userId
    entitlement: $entitlement
    pageNumber: $pageNumber
    pageSize: $pageSize
  ) {
    partners {
      partnerNo
      partnerName
      partnerOu
      partnerId
      partnerInDB
      entitlements
      companies {
        companyCode
        companyName
        companyId
      }
      category
      appliedRuleIds
    }
    distinctEntitlements
    appliedRuleIds
  }
}
    `;

/**
 * __useEdpPartnerAndCompanyQuery__
 *
 * To run a query within a React component, call `useEdpPartnerAndCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useEdpPartnerAndCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEdpPartnerAndCompanyQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      entitlement: // value for 'entitlement'
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useEdpPartnerAndCompanyQuery(baseOptions: Apollo.QueryHookOptions<EdpPartnerAndCompanyQuery, EdpPartnerAndCompanyQueryVariables> & ({ variables: EdpPartnerAndCompanyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EdpPartnerAndCompanyQuery, EdpPartnerAndCompanyQueryVariables>(EdpPartnerAndCompanyDocument, options);
      }
export function useEdpPartnerAndCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EdpPartnerAndCompanyQuery, EdpPartnerAndCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EdpPartnerAndCompanyQuery, EdpPartnerAndCompanyQueryVariables>(EdpPartnerAndCompanyDocument, options);
        }
export function useEdpPartnerAndCompanySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EdpPartnerAndCompanyQuery, EdpPartnerAndCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EdpPartnerAndCompanyQuery, EdpPartnerAndCompanyQueryVariables>(EdpPartnerAndCompanyDocument, options);
        }
export type EdpPartnerAndCompanyQueryHookResult = ReturnType<typeof useEdpPartnerAndCompanyQuery>;
export type EdpPartnerAndCompanyLazyQueryHookResult = ReturnType<typeof useEdpPartnerAndCompanyLazyQuery>;
export type EdpPartnerAndCompanySuspenseQueryHookResult = ReturnType<typeof useEdpPartnerAndCompanySuspenseQuery>;
export type EdpPartnerAndCompanyQueryResult = Apollo.QueryResult<EdpPartnerAndCompanyQuery, EdpPartnerAndCompanyQueryVariables>;
export const GetUserEntitlementResultByUserIdAndFilterDocument = gql`
    query getUserEntitlementResultByUserIdAndFilter($userId: String!, $entitlement: String!, $partnerName: String!, $authentication: String!) {
  getUserEntitlementResultByUserIdAndFilter(
    userId: $userId
    entitlement: $entitlement
    partnerName: $partnerName
    authentication: $authentication
  ) {
    partners {
      partnerNo
      partnerName
      partnerOu
      partnerInDB
      partnerId
      entitlements
      companies {
        companyCode
        companyName
        companyId
      }
      category
      appliedRuleIds
    }
    distinctEntitlements
    appliedRuleIds
  }
}
    `;

/**
 * __useGetUserEntitlementResultByUserIdAndFilterQuery__
 *
 * To run a query within a React component, call `useGetUserEntitlementResultByUserIdAndFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEntitlementResultByUserIdAndFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEntitlementResultByUserIdAndFilterQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      entitlement: // value for 'entitlement'
 *      partnerName: // value for 'partnerName'
 *      authentication: // value for 'authentication'
 *   },
 * });
 */
export function useGetUserEntitlementResultByUserIdAndFilterQuery(baseOptions: Apollo.QueryHookOptions<GetUserEntitlementResultByUserIdAndFilterQuery, GetUserEntitlementResultByUserIdAndFilterQueryVariables> & ({ variables: GetUserEntitlementResultByUserIdAndFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserEntitlementResultByUserIdAndFilterQuery, GetUserEntitlementResultByUserIdAndFilterQueryVariables>(GetUserEntitlementResultByUserIdAndFilterDocument, options);
      }
export function useGetUserEntitlementResultByUserIdAndFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserEntitlementResultByUserIdAndFilterQuery, GetUserEntitlementResultByUserIdAndFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserEntitlementResultByUserIdAndFilterQuery, GetUserEntitlementResultByUserIdAndFilterQueryVariables>(GetUserEntitlementResultByUserIdAndFilterDocument, options);
        }
export function useGetUserEntitlementResultByUserIdAndFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserEntitlementResultByUserIdAndFilterQuery, GetUserEntitlementResultByUserIdAndFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserEntitlementResultByUserIdAndFilterQuery, GetUserEntitlementResultByUserIdAndFilterQueryVariables>(GetUserEntitlementResultByUserIdAndFilterDocument, options);
        }
export type GetUserEntitlementResultByUserIdAndFilterQueryHookResult = ReturnType<typeof useGetUserEntitlementResultByUserIdAndFilterQuery>;
export type GetUserEntitlementResultByUserIdAndFilterLazyQueryHookResult = ReturnType<typeof useGetUserEntitlementResultByUserIdAndFilterLazyQuery>;
export type GetUserEntitlementResultByUserIdAndFilterSuspenseQueryHookResult = ReturnType<typeof useGetUserEntitlementResultByUserIdAndFilterSuspenseQuery>;
export type GetUserEntitlementResultByUserIdAndFilterQueryResult = Apollo.QueryResult<GetUserEntitlementResultByUserIdAndFilterQuery, GetUserEntitlementResultByUserIdAndFilterQueryVariables>;
export const GetDocumentUserEntitlementResultByUserIdDocument = gql`
    query getDocumentUserEntitlementResultByUserId($userId: String!, $entitlement: String!) {
  getDocumentUserEntitlementResultByUserId(
    userId: $userId
    entitlement: $entitlement
  ) {
    partners {
      partnerNo
      partnerName
      partnerOu
      partnerInDB
      partnerId
      entitlements
      companies {
        companyCode
        companyName
        companyId
      }
      category
      appliedRuleIds
    }
    distinctEntitlements
    appliedRuleIds
  }
}
    `;

/**
 * __useGetDocumentUserEntitlementResultByUserIdQuery__
 *
 * To run a query within a React component, call `useGetDocumentUserEntitlementResultByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentUserEntitlementResultByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentUserEntitlementResultByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      entitlement: // value for 'entitlement'
 *   },
 * });
 */
export function useGetDocumentUserEntitlementResultByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentUserEntitlementResultByUserIdQuery, GetDocumentUserEntitlementResultByUserIdQueryVariables> & ({ variables: GetDocumentUserEntitlementResultByUserIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentUserEntitlementResultByUserIdQuery, GetDocumentUserEntitlementResultByUserIdQueryVariables>(GetDocumentUserEntitlementResultByUserIdDocument, options);
      }
export function useGetDocumentUserEntitlementResultByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentUserEntitlementResultByUserIdQuery, GetDocumentUserEntitlementResultByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentUserEntitlementResultByUserIdQuery, GetDocumentUserEntitlementResultByUserIdQueryVariables>(GetDocumentUserEntitlementResultByUserIdDocument, options);
        }
export function useGetDocumentUserEntitlementResultByUserIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetDocumentUserEntitlementResultByUserIdQuery, GetDocumentUserEntitlementResultByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDocumentUserEntitlementResultByUserIdQuery, GetDocumentUserEntitlementResultByUserIdQueryVariables>(GetDocumentUserEntitlementResultByUserIdDocument, options);
        }
export type GetDocumentUserEntitlementResultByUserIdQueryHookResult = ReturnType<typeof useGetDocumentUserEntitlementResultByUserIdQuery>;
export type GetDocumentUserEntitlementResultByUserIdLazyQueryHookResult = ReturnType<typeof useGetDocumentUserEntitlementResultByUserIdLazyQuery>;
export type GetDocumentUserEntitlementResultByUserIdSuspenseQueryHookResult = ReturnType<typeof useGetDocumentUserEntitlementResultByUserIdSuspenseQuery>;
export type GetDocumentUserEntitlementResultByUserIdQueryResult = Apollo.QueryResult<GetDocumentUserEntitlementResultByUserIdQuery, GetDocumentUserEntitlementResultByUserIdQueryVariables>;
export const GetEcapSelfEntitlementResultByFilterDocument = gql`
    query getEcapSelfEntitlementResultByFilter($entitlement: String!, $partnerName: String!, $pageNumber: Float!, $pageSize: Float!) {
  getEcapSelfEntitlementResultByFilter(
    entitlement: $entitlement
    partnerName: $partnerName
    pageNumber: $pageNumber
    pageSize: $pageSize
  ) {
    partners {
      partnerNo
      partnerName
      systemCode
      partnerOu
      partnerId
      partnerInDB
      entitlements
      companies {
        companyCode
        companyName
        companyId
      }
      category
      appliedRuleIds
    }
    distinctEntitlements
    appliedRuleIds
  }
}
    `;

/**
 * __useGetEcapSelfEntitlementResultByFilterQuery__
 *
 * To run a query within a React component, call `useGetEcapSelfEntitlementResultByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEcapSelfEntitlementResultByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEcapSelfEntitlementResultByFilterQuery({
 *   variables: {
 *      entitlement: // value for 'entitlement'
 *      partnerName: // value for 'partnerName'
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetEcapSelfEntitlementResultByFilterQuery(baseOptions: Apollo.QueryHookOptions<GetEcapSelfEntitlementResultByFilterQuery, GetEcapSelfEntitlementResultByFilterQueryVariables> & ({ variables: GetEcapSelfEntitlementResultByFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEcapSelfEntitlementResultByFilterQuery, GetEcapSelfEntitlementResultByFilterQueryVariables>(GetEcapSelfEntitlementResultByFilterDocument, options);
      }
export function useGetEcapSelfEntitlementResultByFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEcapSelfEntitlementResultByFilterQuery, GetEcapSelfEntitlementResultByFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEcapSelfEntitlementResultByFilterQuery, GetEcapSelfEntitlementResultByFilterQueryVariables>(GetEcapSelfEntitlementResultByFilterDocument, options);
        }
export function useGetEcapSelfEntitlementResultByFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEcapSelfEntitlementResultByFilterQuery, GetEcapSelfEntitlementResultByFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEcapSelfEntitlementResultByFilterQuery, GetEcapSelfEntitlementResultByFilterQueryVariables>(GetEcapSelfEntitlementResultByFilterDocument, options);
        }
export type GetEcapSelfEntitlementResultByFilterQueryHookResult = ReturnType<typeof useGetEcapSelfEntitlementResultByFilterQuery>;
export type GetEcapSelfEntitlementResultByFilterLazyQueryHookResult = ReturnType<typeof useGetEcapSelfEntitlementResultByFilterLazyQuery>;
export type GetEcapSelfEntitlementResultByFilterSuspenseQueryHookResult = ReturnType<typeof useGetEcapSelfEntitlementResultByFilterSuspenseQuery>;
export type GetEcapSelfEntitlementResultByFilterQueryResult = Apollo.QueryResult<GetEcapSelfEntitlementResultByFilterQuery, GetEcapSelfEntitlementResultByFilterQueryVariables>;
export const GetSelfEntitlementResultByFilterDocument = gql`
    query getSelfEntitlementResultByFilter($entitlement: String!, $partnerName: String!, $pageNumber: Float!, $pageSize: Float!) {
  getSelfEntitlementResultByFilter(
    entitlement: $entitlement
    partnerName: $partnerName
    pageNumber: $pageNumber
    pageSize: $pageSize
  ) {
    partners {
      partnerNo
      partnerName
      partnerOu
      partnerId
      partnerInDB
      entitlements
      companies {
        companyCode
        companyName
        companyId
      }
      category
      appliedRuleIds
    }
    distinctEntitlements
    appliedRuleIds
  }
}
    `;

/**
 * __useGetSelfEntitlementResultByFilterQuery__
 *
 * To run a query within a React component, call `useGetSelfEntitlementResultByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfEntitlementResultByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfEntitlementResultByFilterQuery({
 *   variables: {
 *      entitlement: // value for 'entitlement'
 *      partnerName: // value for 'partnerName'
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetSelfEntitlementResultByFilterQuery(baseOptions: Apollo.QueryHookOptions<GetSelfEntitlementResultByFilterQuery, GetSelfEntitlementResultByFilterQueryVariables> & ({ variables: GetSelfEntitlementResultByFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSelfEntitlementResultByFilterQuery, GetSelfEntitlementResultByFilterQueryVariables>(GetSelfEntitlementResultByFilterDocument, options);
      }
export function useGetSelfEntitlementResultByFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSelfEntitlementResultByFilterQuery, GetSelfEntitlementResultByFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSelfEntitlementResultByFilterQuery, GetSelfEntitlementResultByFilterQueryVariables>(GetSelfEntitlementResultByFilterDocument, options);
        }
export function useGetSelfEntitlementResultByFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSelfEntitlementResultByFilterQuery, GetSelfEntitlementResultByFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSelfEntitlementResultByFilterQuery, GetSelfEntitlementResultByFilterQueryVariables>(GetSelfEntitlementResultByFilterDocument, options);
        }
export type GetSelfEntitlementResultByFilterQueryHookResult = ReturnType<typeof useGetSelfEntitlementResultByFilterQuery>;
export type GetSelfEntitlementResultByFilterLazyQueryHookResult = ReturnType<typeof useGetSelfEntitlementResultByFilterLazyQuery>;
export type GetSelfEntitlementResultByFilterSuspenseQueryHookResult = ReturnType<typeof useGetSelfEntitlementResultByFilterSuspenseQuery>;
export type GetSelfEntitlementResultByFilterQueryResult = Apollo.QueryResult<GetSelfEntitlementResultByFilterQuery, GetSelfEntitlementResultByFilterQueryVariables>;
export const GetPartnersByCompanyCodesAndFilterDocument = gql`
    query getPartnersByCompanyCodesAndFilter($entitlement: String!, $partnerFilter: String!, $companyCodes: [String!]!, $pageNumber: Float!, $pageSize: Float!) {
  getPartnersByCompanyCodesAndFilter(
    entitlement: $entitlement
    partnerFilter: $partnerFilter
    companyCodes: $companyCodes
    pageNumber: $pageNumber
    pageSize: $pageSize
  ) {
    partnerNo
    partnerName
    partnerId
    category
  }
}
    `;

/**
 * __useGetPartnersByCompanyCodesAndFilterQuery__
 *
 * To run a query within a React component, call `useGetPartnersByCompanyCodesAndFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnersByCompanyCodesAndFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnersByCompanyCodesAndFilterQuery({
 *   variables: {
 *      entitlement: // value for 'entitlement'
 *      partnerFilter: // value for 'partnerFilter'
 *      companyCodes: // value for 'companyCodes'
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetPartnersByCompanyCodesAndFilterQuery(baseOptions: Apollo.QueryHookOptions<GetPartnersByCompanyCodesAndFilterQuery, GetPartnersByCompanyCodesAndFilterQueryVariables> & ({ variables: GetPartnersByCompanyCodesAndFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPartnersByCompanyCodesAndFilterQuery, GetPartnersByCompanyCodesAndFilterQueryVariables>(GetPartnersByCompanyCodesAndFilterDocument, options);
      }
export function useGetPartnersByCompanyCodesAndFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartnersByCompanyCodesAndFilterQuery, GetPartnersByCompanyCodesAndFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPartnersByCompanyCodesAndFilterQuery, GetPartnersByCompanyCodesAndFilterQueryVariables>(GetPartnersByCompanyCodesAndFilterDocument, options);
        }
export function useGetPartnersByCompanyCodesAndFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPartnersByCompanyCodesAndFilterQuery, GetPartnersByCompanyCodesAndFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPartnersByCompanyCodesAndFilterQuery, GetPartnersByCompanyCodesAndFilterQueryVariables>(GetPartnersByCompanyCodesAndFilterDocument, options);
        }
export type GetPartnersByCompanyCodesAndFilterQueryHookResult = ReturnType<typeof useGetPartnersByCompanyCodesAndFilterQuery>;
export type GetPartnersByCompanyCodesAndFilterLazyQueryHookResult = ReturnType<typeof useGetPartnersByCompanyCodesAndFilterLazyQuery>;
export type GetPartnersByCompanyCodesAndFilterSuspenseQueryHookResult = ReturnType<typeof useGetPartnersByCompanyCodesAndFilterSuspenseQuery>;
export type GetPartnersByCompanyCodesAndFilterQueryResult = Apollo.QueryResult<GetPartnersByCompanyCodesAndFilterQuery, GetPartnersByCompanyCodesAndFilterQueryVariables>;
export const GetCompaniesByEntitlementDocument = gql`
    query getCompaniesByEntitlement($entitlement: String!) {
  allCompaniesForEntitlement(entitlement: $entitlement) {
    companyId
    companyName
    companyCode
  }
}
    `;

/**
 * __useGetCompaniesByEntitlementQuery__
 *
 * To run a query within a React component, call `useGetCompaniesByEntitlementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesByEntitlementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesByEntitlementQuery({
 *   variables: {
 *      entitlement: // value for 'entitlement'
 *   },
 * });
 */
export function useGetCompaniesByEntitlementQuery(baseOptions: Apollo.QueryHookOptions<GetCompaniesByEntitlementQuery, GetCompaniesByEntitlementQueryVariables> & ({ variables: GetCompaniesByEntitlementQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompaniesByEntitlementQuery, GetCompaniesByEntitlementQueryVariables>(GetCompaniesByEntitlementDocument, options);
      }
export function useGetCompaniesByEntitlementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompaniesByEntitlementQuery, GetCompaniesByEntitlementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompaniesByEntitlementQuery, GetCompaniesByEntitlementQueryVariables>(GetCompaniesByEntitlementDocument, options);
        }
export function useGetCompaniesByEntitlementSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCompaniesByEntitlementQuery, GetCompaniesByEntitlementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCompaniesByEntitlementQuery, GetCompaniesByEntitlementQueryVariables>(GetCompaniesByEntitlementDocument, options);
        }
export type GetCompaniesByEntitlementQueryHookResult = ReturnType<typeof useGetCompaniesByEntitlementQuery>;
export type GetCompaniesByEntitlementLazyQueryHookResult = ReturnType<typeof useGetCompaniesByEntitlementLazyQuery>;
export type GetCompaniesByEntitlementSuspenseQueryHookResult = ReturnType<typeof useGetCompaniesByEntitlementSuspenseQuery>;
export type GetCompaniesByEntitlementQueryResult = Apollo.QueryResult<GetCompaniesByEntitlementQuery, GetCompaniesByEntitlementQueryVariables>;