import React, {FunctionComponent, useRef, useState} from "react";
import {PageContainer} from "@ant-design/pro-layout";
import {Col, ConfigProvider, Modal, notification, Spin, Tooltip} from "antd";
import "./../Welcome.css";
import "./SurveyOverview.css";
import {IAppComponentProps} from "../../components";
import {AgGridReact} from "ag-grid-react";
import {GridApi} from "ag-grid-community";
import {SURVEY_MANAGEMENT_TABLE_HEADERS} from "./SurveyUtils";
import {QUERY_SURVEY_MANAGEMENT_DATA} from "./queries";
import SurveyManagementForm from "./SurveyManagementForm";
import client from "../../config/GraphQLApolloClient";
import {FolderViewOutlined, ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import {Link, useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";
import RenderIfEntitled from "../../components/EntitlementComponents";
import {getAgGridLocalization} from "../info-portal/components/grid/GridUtil";
import {FormInstance} from "antd/lib/form/Form";
import enUS from "antd/lib/locale/en_US";
import deDe from "antd/lib/locale/de_DE";
import { useAppDispatch } from '../../main/hooks';
import { setSurveyMode, updateSelectedSurveyManagement } from "./IStoredSurveySlice";
import { MODE } from "./IStoredSurvey";


const SurveyManagement: FunctionComponent<IAppComponentProps> = (props) => {
    const dispatch = useAppDispatch();
    const currentUser = props.currentUser;
    const [gridApi, setGridApi] = useState<GridApi>(undefined);
    const [isLoading, setLoading] = useState(false);
    const [surveyManagementData, setSurveyManagementData] = useState(undefined);
    const intl = useIntl();
    const navigate = useNavigate();
    const formInstance = useRef<FormInstance<any>>(null);

    const updateFormInstance = (form: FormInstance<any>) => {
        formInstance.current = form;
    }

    const onGridReady = (params) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
    };

    const fetchData = (input) => {
        setLoading(true);
        client
            .query({
                query: QUERY_SURVEY_MANAGEMENT_DATA,
                variables: {surveyCriteriaType: input},
                fetchPolicy: "network-only"
            }).then((response) => {
            setSurveyManagementData(response.data.getSurveyManagementList);
            setLoading(false);
        })
            .catch((err) => {
                notification.error(err);
                setLoading(false);
            });
        setLoading(true);
    }

    const onSearch = (values) => {
        const surveyManagementInputs: any = {
            partnerNo: values.partnerNo === undefined ? null : values.partnerNo,
            partnerName: values.partnerName === undefined ? null : values.partnerName,
            companyCode: values.companyCode === undefined ? null : values.companyCode,
            email: values.email === undefined ? null : values.email,
            surveyStatusId: values.surveyStatus === undefined ? null : parseFloat(values.surveyStatus),
            surveyId: values.surveyId !== undefined ? parseFloat(values.surveyId) : null,
            filterOption: values.filterOption !== undefined ? values.filterOption : null
        };

        fetchData(surveyManagementInputs);
    };

    const onViewClick = () => {
        const selectedRow = gridApi.getSelectedRows();
        if (selectedRow.length > 0) {
            dispatch(setSurveyMode(MODE.OVERVIEW));
            dispatch(updateSelectedSurveyManagement(JSON.stringify(selectedRow)));
            //window.localStorage.setItem('SurveyManagementView', 'selected');
            //window.localStorage.setItem('selectedSurveyManagement', JSON.stringify(selectedRow));
            navigate("/survey/survey-overview");
        } else {
            Modal.warning({
                title: intl.formatMessage({
                    id: "management-viewsurvey-link-title",
                }),
                content: intl.formatMessage({
                    id: "management-please-select-survey",
                })
            });
        }
    }

    const performSearch = () => {
        if (formInstance.current) {
            formInstance.current.submit();
        }
    }
    const resetFields = () => {
        if (formInstance.current) {
            formInstance.current.resetFields();
        }
    }

    return (
        <>
            <ConfigProvider locale={localStorage.getItem("locale") === "en" ? enUS : deDe}>
                <PageContainer title={intl.formatMessage({
                    id: "management-page-title",
                    defaultMessage: "Survey Management"
                })}
                               content={intl.formatMessage({
                                   id: "management-page-content",
                               })}
                               extraContent={""}
                >

                    <Spin spinning={isLoading} size={"large"}>
                            <Col md={24} sm={12} xs={24}>
                                <SurveyManagementForm
                                    onSearch={onSearch}
                                    bubbleFormInstance={updateFormInstance}
                                    currentUser={currentUser}
                                    intl={props.intl}
                                    distinctEntitlements={props.distinctEntitlements}
                                    hideButtons={true}/>

                                <div>
                                    <Tooltip title={intl.formatMessage({'id': 'info-portal-search'})}>
                                        <SearchOutlined
                                            style={{margin: "5px", fontSize: "25px", color: "#444444"}}
                                            onClick={performSearch}
                                        />
                                    </Tooltip>

                                    <Tooltip title={intl.formatMessage({'id': 'do-clear'})}>
                                        <ReloadOutlined
                                            style={{
                                                margin: "5px",
                                                fontSize: "25px",
                                                color: "#444444",
                                                transform: "scaleX(-1)"
                                            }}
                                            onClick={resetFields}
                                        />
                                    </Tooltip>

                                    <RenderIfEntitled entitlementToCheck="display@survey" intl={intl}
                                                      distinctEntitlements={props.distinctEntitlements}>

                                        {/*<Link to={"/survey/surveyOverview"}>*/}
                                        <Tooltip title={intl.formatMessage({
                                            id: "management-viewsurvey-link-title",
                                            defaultMessage: "View Survey"
                                        })} key={"viewSurvey"}>
                                            <FolderViewOutlined onClick={onViewClick} style={{
                                                fontSize: "25px",
                                                color: "#444444",
                                                margin: "5px"
                                            }}/>
                                        </Tooltip>
                                        {/*</Link>*/}
                                    </RenderIfEntitled>

                                </div>

                                <div className="pl15 ag-theme-balham"  style={{height: "400px"}}>

                                        <AgGridReact
                                            defaultColDef={{
                                                enableValue: true,
                                                sortable: true,
                                                resizable: true
                                            }}
                                            api={gridApi}
                                            columnDefs={SURVEY_MANAGEMENT_TABLE_HEADERS(intl, currentUser)}
                                            rowData={surveyManagementData === undefined ? [] : surveyManagementData}

                                            sideBar={false}
                                            rowClass="agGridRows"
                                            localeText={getAgGridLocalization(intl)}
                                            suppressMenuHide={true}
                                            debug={false}
                                            onGridSizeChanged={() => gridApi.sizeColumnsToFit()}
                                            onGridReady={onGridReady}
                                            rowSelection={"single"}
                                            pagination={true}
                                            paginationPageSize={15}
                                            suppressRowClickSelection={true}
                                            enableBrowserTooltips={true}
                                        />
                                    </div>
                            </Col>
                    </Spin>
                </PageContainer>
            </ConfigProvider>
        </>
    );
};
export default SurveyManagement;
