import React from "react";
import { PortalFormItemSelectDocType } from "./FormUtils";
import { useQuery } from "@apollo/client";
import { getDocumentType } from "../pages/documents/__generated__/getDocumentType";
import { SELECT_ALL_DOCTYPE_QUERY, SELECT_FILTERED_DOCTYPE_QUERY, SELECT_PROVIDER_DOCTYPE_QUERY } from "../pages/documents/queries";
import { useIntl, WrappedComponentProps } from "react-intl";
import { getI18n } from "../utils/Utils";
import { getAllDocumentTypes } from "../pages/documents/__generated__/getAllDocumentTypes";
import { getFilteredDocumentTypes } from "../pages/documents/__generated__/getFilteredDocumentTypes";
import { getProviderDocumentType } from "../pages/documents/__generated__/getProviderDocumentType";

export type IAppComponentProps = 
{ currentUser?: any; 
  distinctEntitlements?: string[];
  bmwTypesOnly?: boolean;
} & WrappedComponentProps;

export const FsmDocumentType: React.FunctionComponent<IAppComponentProps> = (props) => {
  const intl = useIntl();
  const documentType: getDocumentType = { getDocumentType: [] };
  const documentTypeList = [];

  const { data } = useQuery<getFilteredDocumentTypes>( SELECT_FILTERED_DOCTYPE_QUERY,
    {
      fetchPolicy: "cache-first",
      variables: { entitlement: "fsm_overview@documents" },
      onError: (error) => console.error(error)
    } 
  );
  
     //Get only BMW Document types (serviceProviderId = 1)
     const { data: partialData } = useQuery<getProviderDocumentType>(
      SELECT_PROVIDER_DOCTYPE_QUERY,
      {
        
          fetchPolicy: 'cache-first',
          variables: { serviceProviderId: 1 },
          onError: (error) => console.error(error)
      }
  );

  if (!props.bmwTypesOnly)
  {
    if (data?.getFilteredDocumentTypes) {
      data.getFilteredDocumentTypes.forEach(item => {
        documentTypeList.push({ ...item, documentType: getI18n(item.phraseId, item.documentType, intl) });
      });
      documentType.getDocumentType = documentTypeList;
    }
  }
  else
  {
    if (partialData?.getProviderDocumentType) {
      partialData.getProviderDocumentType.forEach(item => {
        documentTypeList.push({ ...item, documentType: getI18n(item.phraseId, item.documentType, intl)});
      });
      documentType.getDocumentType = documentTypeList;
    }
  }

  return (
    <>
      <PortalFormItemSelectDocType
        type={"single"}
        label={intl.formatMessage({ id: "welcome-document-type", defaultMessage: "Document Type" })}
        name={"documentTypeId"}
        placeHolder={intl.formatMessage({
          id: "welcome-document-type",
          defaultMessage: "Document Type"
        })}
        getDocumentType={documentType} colSpan={5} />
    </>
  );
};