import React from "react";

const portionIssuesStyle = {color: 'red'};

type SurveyIssueType = {
    portionSequence: number;
    groupId: string;
    verifyData: any;
  };

const SurveyIssuesComponent = (props: SurveyIssueType ) => {

    return (
        <a style={portionIssuesStyle}> 
            <label id={`portionSequence-${props.portionSequence}`}>{props.verifyData?.join(", ")}</label>
        </a>
    )

}

export default SurveyIssuesComponent;