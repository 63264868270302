import React, { useState } from 'react';
import { EntitlementFormProps } from './EntitlementInterfaces';
import { GetAllEntitlementsQuery } from './queries.generated';
import { Button, Checkbox, Col, Form, Row } from 'antd';

type EntitlementAdvanceFormProps = {
    entitlementTypes: GetAllEntitlementsQuery;
    selectedGroups: string[];
    onValuesChange: any;
} & EntitlementFormProps;

const EntitlementAdvanceGroups: React.FunctionComponent<EntitlementAdvanceFormProps> = (props) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    // Group entitlements by groupId
    const groupedEntitlements = props?.entitlementTypes?.getAllEntitlements
        .filter((entitlement) => props?.selectedGroups?.includes(entitlement?.groupId))
        .reduce((acc, entitlement) => {
            if (!acc[entitlement.groupId]) {
                acc[entitlement.groupId] = [];
            }
            acc[entitlement.groupId].push(entitlement);
            return acc;
        }, {});

    return (
        <>
            <Button onClick={toggleExpand}>
                {isExpanded ? 'Hide Advance Entitlement Selection' : 'Show Advance Entitlement Selection'}
            </Button>
            {isExpanded && (
                <div>
                    <Form.Item name={'advanceSelectedEntitlements'} key={'advanceSelectedEntitlements'}>
                        <Checkbox.Group name="advanceSelectedEntitlements">
                            {Object.keys(groupedEntitlements).map((groupId) => (
                                <div key={groupId}>
                                    <h3>Group {groupId}</h3>
                                    {groupedEntitlements[groupId].map((entitlement) => (
                                        <Row key={entitlement.entitlement}>
                                            <Col span={24}>
                                                <Checkbox
                                                    value={entitlement.entitlement}
                                                    onChange={props.onValuesChange}
                                                >
                                                    {entitlement.entitlement.concat(' - ', entitlement.description)}
                                                </Checkbox>
                                            </Col>
                                        </Row>
                                    ))}
                                </div>
                            ))}
                        </Checkbox.Group>
                    </Form.Item>
                </div>
            )}
        </>
    );
};

export default EntitlementAdvanceGroups;
