import React from "react";
import { Col, Row, Card, FormInstance } from "antd";
import { useIntl } from "react-intl";
import { Form_Item } from "./FormItem-component";
import { getControlRuleset, getDefaultDisabledRuleset, getDefaultNotRequiredRuleset, getDefaultRequiredRuleset } from "../RulesEngine";
import { getI18n } from "../../../utils/Utils";
import { GET_ECAP_DOCUMENT_RECORD } from "../queries";
import { getFilteredRulesWithRuleType } from "../__generated__/getFilteredRulesWithRuleType";
import { IStoredDocumentRecord } from "../IStoredDocumentRecord";

type contactDetailsProps = {
  form: FormInstance<any>;
  contactNameFldName: any;
  contactDepartmentFldName: any;
  contactTelephoneFldName: any;
  contactEmailFldName: any;
  noTitle?: boolean;
  rules: any;
  document: IStoredDocumentRecord;
};

export function ECAPContactPerson(props: contactDetailsProps): JSX.Element {
  const intl = useIntl();
  return (
    <Card bordered={true} size={"small"}
          title={props.noTitle ? "" : getI18n("general-contact-person-title", "Contact Person", intl)}>
      <Row gutter={24}>
        <Col span={10}>
          <Form_Item label={getI18n("general-contact-person-name-label", "Name", intl)}
                     placeholder={getI18n("general-contact-person-name-label", "Name", intl)}
                     name={[props.contactNameFldName[0], props.contactNameFldName[1]]}
                     itemRules={getControlRuleset("SHIPPED_TO_CONTACT", props.form, props.rules, props.document, null)} />
            <Form_Item
            label={getI18n("general-contact-person-telephone-label", "Telephone", intl)}
            placeholder={getI18n("general-contact-person-telephone-label", "Telephone", intl)}
            name={props.contactTelephoneFldName}
            itemRules={getControlRuleset(props.contactTelephoneFldName, props.form, props.rules, props.document)} />
        </Col>

        <Col span={10}>
        <Form_Item
            label={getI18n("general-contact-person-department-label", "Department", intl)}
            placeholder={getI18n("general-contact-person-department-label", "Department", intl)}
            name={props.contactDepartmentFldName} itemRules={getControlRuleset("SHIPPED_TO_CONTACT", props.form, props.rules, props.document, null)} />
        <Form_Item
            label={getI18n("general-contact-person-email-label", "E-Mail", intl)}
            placeholder={getI18n("general-contact-person-email-label", "E-Mail", intl)}
            name={props.contactEmailFldName}
            itemRules={getControlRuleset(props.contactNameFldName, props.form, props.rules, props.document)} />
        </Col>
      </Row>
    </Card>
  );
}