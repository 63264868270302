import React, { useEffect, useState } from "react";
import { Input, Select, Tooltip, Col, Form, Modal, Row, Button, Alert, Card, DatePicker, Spin, Typography, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useIntl } from "react-intl";
import { getI18n } from "../../../utils/Utils";
import { addBannerData, editBannerData } from "../NotificationService";
import dayjs from 'dayjs';

type AddBannerModalProps = {
    isModalVisible: any;
    handleCancel: any;
    bannerValues: any;
    afterClose: any;
    adding: Boolean;
}

type FormData = {
    bannerId: number,


    bannerShowStart: string,
    bannerShowEnd: string,
    bannerHeadingDe: string,
    bannerHeadingEn: string,
    bannerMessageDe: string,
    bannerMessageEn: string,
    startTime: string,
    endTime: string,
    messageType: string,
}



const AddBannerModal: React.FunctionComponent<AddBannerModalProps> = (props) => {
    const [form] = Form.useForm()
    const intl = useIntl();
    const [loading, setLoading] = useState<boolean>(false);
    const [formData, setFormData] = useState<FormData>(props.bannerValues);
    const [alertMessage, setAlertMessage] = useState<string>("Please enter all required fields");
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [messageType, setMessageType] = useState([

        {
            value: 'warning',
            id: 'warning',
        },
        {
            value: 'info',
            id: 'info',
        },
        {
            value: 'success',
            id: 'success',
        },
        {
            value: 'error',
            id: 'error',
        }
    ]);

    const validateForm = () => {
          if ((formData === null || formData === undefined) || formData?.bannerShowStart === undefined  || formData?.bannerShowStart === "" ||
         formData?.bannerShowEnd === undefined || formData?.bannerShowEnd === "" || formData?.messageType === undefined || formData?.messageType === "") { 
            setShowAlert(true)  
            return true
        }
    }

    // useEffect(() => {

    // }, [])

    const handleChange = (event) => {
        setFormData(prevFormData => {
            return { ...prevFormData, [event.target.id]: event.target.value }
        })
    }

    const handleSelectChange = (val) => {
        setFormData(prevFormData => {
            return { ...prevFormData, "messageType": val }
        })
    }
    const handleBannerShowStartDateChange = (val) => {

        setFormData(prevFormData => {
            return { ...prevFormData, "bannerShowStart": val?.format('YYYY-MM-DD HH:mmZ') }
        })

    }

    const handleBannerShowEndDateChange = (val) => {

        setFormData(prevFormData => {
            return { ...prevFormData, "bannerShowEnd": val?.format('YYYY-MM-DD HH:mmZ') }
        })
    }

    const handleStartTimeChange = (val) => {
        setFormData(prevFormData => {
            return { ...prevFormData, "startTime": val?.format('YYYY-MM-DD HH:mmZ') }
        })
    }

    const handleEndTimeChange = (val) => {
        setFormData(prevFormData => {
            return { ...prevFormData, "endTime": val?.format('YYYY-MM-DD HH:mmZ') }
        })
    }

    const handleSubmit = () => {
        const formDataWithBannerId = formData;
        formDataWithBannerId.bannerId = props.bannerValues.bannerId;
            
        if (props.adding) {
        if (validateForm()) {     
            return          
        } }
        setShowAlert(false);
        props.adding ? 
         Promise.resolve(addBannerData(formData))
            .then(() => {
                message.success(getI18n("banner-AddDataSuccess",
                    "Banner Added Successfully", intl
                ));
                
                form.resetFields();
                props.afterClose();
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                message.error(getI18n("banner-AddDataFailure",
                    "Add Banner failed", intl
                ));
                props.afterClose();
            })            
            :

            
           
            Promise.resolve(editBannerData(formDataWithBannerId))
                .then(() => {
                    message.success(getI18n(
                        "Banner-EditDataSuccess",
                        "Banner Edit Successfully", intl
                    ));
                    form.resetFields();
                    props.afterClose();
                    setLoading(false);
                })
                .catch((err) => {
                    console.error(err);
                    message.error(getI18n(
                        "banner-EditDataFailure",
                        "Edit Banner Failed", intl
                    ));
                    form.resetFields();
                    props.handleCancel();
                    setLoading(false);
                });
        setFormData(null);
        form.resetFields();
        props.handleCancel();
    }

    const handleCancelInModal = () => {
        setFormData(null);
        setShowAlert(false)  
        // form.resetFields();
        props.handleCancel();
         }

    return (
        <>
        <Spin spinning={loading} size="large" />
            <Modal
                title={props.adding ? getI18n("banner-management-modal-Add-Banner", "Add Banner", intl) :
                    getI18n("banner-management-modal-title-edit-banner", "Edit Banner", intl)}
                afterClose={props.afterClose}
                onCancel={() => handleCancelInModal()}
                //  onOk={() => onOk(form)}
                open={props.isModalVisible}
                width={'900px'}
                mask={true}
                footer={[
                    <Button key="back"
                        onClick={handleCancelInModal}
                    >
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary"
                        onClick={handleSubmit}
                    >Submit
                    </Button>
                ]}
            >
                <Form size={"middle"} layout={"vertical"} form={form} >
                    <Row gutter={24} style={{ alignContent: "flex-end", marginTop: "50" }} />
                    <Card
                        title={props.adding ? getI18n("banner-management-modal-Add-Banner-Details", "Add Banner Details", intl) :
                        getI18n("banner-management-modal-edit-banner-details", "Edit Banner Details", intl) }>
                        <Row gutter={24}>
                            <Form.Item
                                name={"bannerShowStart"}
                                key={"bannerShowStart"}
                                label={getI18n("banner-management-modal-banner-show-start", "Banner Show Start", intl)}
                                required={true}
                            >
                                <DatePicker
                                    showTime={{ format: 'HH:mm' }}
                                    format={"YYYY-MM-DD HH:mm"}
                                    placeholder={getI18n("banner-management-modal-banner-show-start", "Banner Show Start", intl)}
                                    allowClear={true}
                                    data-testid={"bannerShowStart"}
                                    id={"bannerShowStart"}
                                    // style={{ width: "30%", marginLeft: 40 }}
                                    onChange={handleBannerShowStartDateChange}
                                    defaultValue={props.adding ? null : dayjs(props.bannerValues?.bannerShowStart)}
                                />
                            </Form.Item>
                            <Form.Item
                                name={"bannerShowEnd"}
                                key={"bannerShowEnd"}
                                label={getI18n("banner-management-add-modal-banner-show-end", "Banner Show End", intl)}
                                required={true}
                            >
                                <DatePicker
                                    showTime={{ format: 'HH:mm' }}
                                    format={"YYYY-MM-DD HH:mm"}
                                    placeholder={getI18n("banner-management-modal-banner-show-end", "Banner Show End", intl)}
                                    allowClear={true}
                                    data-testid={"bannerShowEnd"}
                                    id={"bannerShowEnd"}
                                    onChange={handleBannerShowEndDateChange}
                                    defaultValue={props.adding ? null : dayjs(props.bannerValues?.bannerShowEnd)}
                                />
                            </Form.Item>
                            <Form.Item
                                name={"bannerStartTime"}
                                key={"bannerStartTime"}
                                label={getI18n("banner-management-add-modal-banner-start-time", "Display Start Time", intl)}>                            
                                <DatePicker
                                    showTime={{ format: 'HH:mm' }}
                                    format={"YYYY-MM-DD HH:mm"}
                                    placeholder={getI18n("banner-management-modal-banner-start-time", "Display Start Time", intl)}
                                    allowClear={true}
                                    data-testid={"startTime"}
                                    name={"startTime"}
                                    onChange={handleStartTimeChange}
                                    defaultValue={props.adding ? null : props.bannerValues?.startTime === null ? null : dayjs(props.bannerValues?.startTime)}
                                />
                            </Form.Item>
                            <Form.Item
                                name={"bannerEndTime"}
                                key={"bannerEndTime"}
                                label={getI18n("banner-management-add-modal-banner-end-time", "Display End Time", intl)}
                            >
                                <DatePicker
                                    showTime={{ format: 'HH:mm' }}
                                    format={"YYYY-MM-DD HH:mm"}
                                    placeholder={getI18n("banner-management-modal-banner-end-time", "Display End Time", intl)}
                                    allowClear={true}
                                    data-testid={"endTime"}
                                    name={"endTime"}
                                    onChange={handleEndTimeChange}
                                    defaultValue={props.adding ? null : props.bannerValues?.endTime === null ? null : dayjs(props.bannerValues?.endTime)}
                                />
                            </Form.Item>
                            <Form.Item
                                name={"bannerMessageType"}
                                key={"bannerMessageType"}
                                label={getI18n("banner-management-add-modal-message-type", "Message Type", intl)}
                                required={true}>
                                <Select
                                    size="middle"
                                    style={{ width: 180 }}
                                    options={messageType}
                                    onChange={handleSelectChange}
                                    defaultValue={props.bannerValues?.messageType}
                                />
                            </Form.Item>
                        </Row>
                    </Card>
                    <Card title={props.adding ? getI18n("banner-management-modal-add-banner-message", "Add Banner Message", intl) :
                        getI18n("banner-management-modal-edit-banner-message", "Edit Banner Message", intl)}>
                        <Row>
                            <Form.Item
                                name={"bannerHeadingEn"}
                                key={"bannerHeadingEn"}
                                label={getI18n("banner-management-add-modal-label-banner-heading-english", "Banner Heading English", intl)}
                            >
                                <Input
                                    placeholder={getI18n("banner-management-modal-banner-banner-heading-english", "Banner Heading English", intl)}
                                    id={'bannerHeadingEn'}
                                    onChange={handleChange}
                                    style={{ width: 300, height: 40 }} allowClear={true} bordered={true}
                                    defaultValue={props.bannerValues?.bannerHeadingEn}
                                />
                            </Form.Item>
                            <Form.Item
                                name={"bannerHeadingDe"}
                                key={"bannerHeadingDe"}
                                label={getI18n("banner-management-add-modal-label-banner-heading-german", "Banner Heading German", intl)}
                            >
                                <Input
                                    placeholder={getI18n("banner-management-modal-banner-banner-heading-german", "Banner Heading German", intl)}
                                    id={'bannerHeadingDe'}
                                    onChange={handleChange}
                                    style={{ width: 300, height: 40 }} allowClear={true}
                                    defaultValue={props.bannerValues?.bannerHeadingDe}
                                />
                            </Form.Item>
                        </Row>
                        <Row>
                            <Form.Item
                                name={"bannerMessageEn"}
                                key={"bannerMessageEn"}
                                label={getI18n("banner-management-add-modal-label-banner-message-english", "Banner Message English", intl)}
                            >
                                <TextArea
                                    placeholder={getI18n("banner-management-modal-banner-message-english", "Banner Message English", intl)}
                                    id={"bannerMessageEn"}
                                    onChange={handleChange}
                                    style={{ width: 700 }} allowClear={true}
                                    defaultValue={props.bannerValues?.bannerMessageEn}
                                />
                            </Form.Item>
                        </Row>
                        <Row>
                            <Form.Item
                                name={"bannerMessageDe"}
                                key={"bannerMessageDe"}
                                label={getI18n("banner-management-add-modal-label-banner-message-german", "Banner Message German", intl)}
                            >
                                <TextArea
                                    placeholder={getI18n("banner-management-modal-banner-message-german", "Banner Message German", intl)}
                                    id={"bannerMessageDe"}
                                    onChange={handleChange}
                                    style={{ width: 700, marginTop: 10 }} allowClear={true}
                                    defaultValue={props.bannerValues?.bannerMessageDe}
                                />
                            </Form.Item>
                        </Row>
                    </Card>
                </Form>
                {showAlert && <Alert message={alertMessage} type={"error"} showIcon={true} closable={true} />}

            </Modal >
        </>
    )
}

export default AddBannerModal;
