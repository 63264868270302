import React, {FunctionComponent, useState} from "react";
import TextArea from "antd/es/input/TextArea";
import {Card, DatePicker, FormInstance, Input, Tooltip} from "antd";

import styles from "../styling/ecap.module.css";
import {IAppComponentProps} from "../../../components";
import {useAppSelector} from "../../../main/hooks";
import {Form_Item} from "../componets/FormItem-component";
import {RuleResultCache} from "../util/RuleResultCache";
import {InfoCircleOutlined} from "@ant-design/icons";

type HeaderNoteProperties = { form: FormInstance<any>} & IAppComponentProps

const HeaderNotes: FunctionComponent<HeaderNoteProperties> = (props) => {
    const [footer, setFooter] = useState<string>('');
    const [flag,setFlag] = useState<boolean>(false);
    const rerender = ()=> setFlag(prev=>!prev);

    const { document } = useAppSelector((state) => state.document);
    const rules  = useAppSelector((state) => state.rules);

    const agreementReferenceEventHandler = ()=> {
        props.form.resetFields([["document","originalInvoiceNumber"]])
        rerender();
    }

    const invoiceEventHandler = ()=> {
        props.form.resetFields([["document","agreementReference"]])
        rerender();
    }

    return new RuleResultCache(rules,props.form,document,['Early_Payment_Mandatory','Original_Invoice','Agreement_Reference','Footer_Text'],props.intl).render(propertyCache=> {
        return ( <Card key={"headerNotesCardKey"} size={"small"} className={"ml-15"}>
                <div className={styles.headerNotesFirstRowContainer}>
                    <div><span hidden={!propertyCache.isMandatory('Original_Invoice')} className={styles.mandatoryField}>*</span>{props.intl.formatMessage({'id':'header-notes-original-invoice-no-title','defaultMessage':'Original Invoice number'})} </div>
                    <div><span hidden={!propertyCache.isMandatory('Agreement_Reference')} className={styles.mandatoryField}>*</span>{props.intl.formatMessage({'id':'header-notes-ref-agreement-title','defaultMessage':'Reference to an Agreement'})} </div>
                    <div>{props.intl.formatMessage({'id':'header-notes-payment-terms-title','defaultMessage':'Payment Terms'})}</div>
                    <div>
                        {props.intl.formatMessage({'id':'header-notes-footer-text-title','defaultMessage':'Footer Text'})}

                        <Tooltip title={props.intl.formatMessage({'id':"footer-info-message",'defaultMessage':"A maximum of 450 characters may be entered"})}>
                            <InfoCircleOutlined style={{marginLeft:"5px"}}/>
                        </Tooltip>
                    </div>

                    <div>
                        <Form_Item name={["document","originalInvoiceNumber"]}
                                   label={''}
                                   itemRules={propertyCache.fetchRule('Original_Invoice')}>
                            <Input onChange={(e)=>invoiceEventHandler()} disabled={propertyCache.isReadOnly('Original_Invoice')}/>
                        </Form_Item>
                    </div>

                    <div>
                        <Form_Item name={["document","agreementReference"]}
                                   label={''}
                                   itemRules={propertyCache.fetchRule('Agreement_Reference')}>
                            <Input onKeyUp={(e)=>agreementReferenceEventHandler()} disabled={propertyCache.isReadOnly('Agreement_Reference')}/>
                        </Form_Item>
                    </div>
                    <div>
                        <Form_Item name={["document","paymentTerms"]}
                                   label={''}>
                            <TextArea rows={2}
                                      maxLength={450}
                                      readOnly={true}
                                      defaultValue={props.intl.formatMessage({id:'ecap-header-notes-payment-terms-default-text'})}/>
                        </Form_Item>
                    </div>
                    <div>
                        <Tooltip title={footer}>
                            <Form_Item name={["document","footerText"]}
                                       label={''}
                                       itemRules={propertyCache.fetchRule('Footer_Text')}
                            >
                                <TextArea rows={3}
                                          onChange={event => setFooter(event.target.value)}
                                          allowClear={true}/>
                            </Form_Item>
                        </Tooltip>
                    </div>
                </div>

                <div className={styles.headerNotesSecondRowContainer}>
                    <div hidden={propertyCache.isHidden('Early_Payment_Mandatory')}>
                        <span hidden={!propertyCache.isMandatory('Early_Payment_Mandatory')} className={styles.mandatoryField}>*</span>
                        {props.intl.formatMessage({'id':'header-notes-early-payment-title','defaultMessage':'Early Payment Description'})}
                    </div>
                    <div hidden={propertyCache.isHidden('Early_Payment_Mandatory')}>
                        <span hidden={!propertyCache.isMandatory('Early_Payment_Mandatory')} className={styles.mandatoryField}>*</span>
                        {props.intl.formatMessage({'id':'header-notes-discount-date-title','defaultMessage':'Early Payment Discount Date'})}
                    </div>
                    <div hidden={propertyCache.isHidden('Early_Payment_Mandatory')}>
                        <span hidden={!propertyCache.isMandatory('Early_Payment_Mandatory')} className={styles.mandatoryField}>*</span>
                        {props.intl.formatMessage({'id':'header-notes-discount-due-title','defaultMessage':'Discount Due Date'})}
                    </div>
                    <div>
                        <Form_Item name={["document","earlyPaymentDescription"]}
                                   label={''}
                                   itemRules={propertyCache.fetchRule('Early_Payment_Mandatory')}/>
                    </div>

                    <div>
                        <Form_Item name={["document","earlyPaymentDiscountDate"]}
                                   label={''}
                                   itemRules={propertyCache.fetchRule('Early_Payment_Mandatory')}>
                            <DatePicker
                                format={"YYYY-MM-DD"}
                                placeholder=""
                                style={{width: "100%"}}
                                inputReadOnly={true}
                            />
                        </Form_Item>
                    </div>
                    <div>
                        <Form_Item name={["document","earlyPaymentDiscountDueDate"]}
                                   label={''}
                                   itemRules={propertyCache.fetchRule('Early_Payment_Mandatory')}>
                            <DatePicker
                                format={"YYYY-MM-DD"}
                                placeholder=""
                                style={{width: "100%"}}
                                inputReadOnly={true}
                            />
                        </Form_Item>
                    </div>
                </div>

            </Card>

        );
    });
};

export default HeaderNotes;