import __ from "lodash";
import client from '../../../config/GraphQLApolloClient';
import { EAI_CREATED_DOCUMENT_LIST, INCOMING_INFOLOG_DOCUMENT_COUNT } from './queries';
import { EventEmitter } from 'events';

const where = (args) => {
  let whereClause ="";
  const values = {};
  const mapper = [];
    Object.keys(args).forEach((fieldName) => {
        console.log(`Field :${fieldName}`);
        const value = args[fieldName];
        console.log('criteria');
        console.dir(value);
        if (value.filterType === 'date')
        {
          const dateFrom = value['dateFrom'];
          const dateTo = value['dateTo'];
          const type = value['type'];
          console.log(`dateFrom: ${dateFrom}`);
          console.log(`dateTo: ${dateTo}`);
          console.log(`type: ${type}`);
          if (__.isEmpty(whereClause))
          {
            if (type === 'equal')
            {
              whereClause = dateFrom.concat("")
            }
            
          }
        }
    });

    return '';
};

const pageSize: number = 15;

class DocumentDetailDatasource extends EventEmitter{
    gridOptions : any;
    numberOfRows : any;
    days : any;
    isLoading: boolean;
    

    constructor(gridOptions, days) {
        super();
        this.gridOptions = gridOptions;
        this.numberOfRows = 0;
        this.days = days;
        this.isLoading = false;
    }

    setDays(days) 
    {
        this.days = days;
    }

    setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading;
        this.emit('isLoadingChanged', isLoading);
      }

    getRows(params) {
        this.setIsLoading(true);
        const jsonRequest = JSON.stringify(params, null, 2);
        console.log(jsonRequest);
        console.log('Params');
        console.dir(params);
        console.log(`Get detail data for ${this.days} number of days`);
        let filterModel = '';
        let dataModel = {};
        dataModel = {sortModel : params.sortModel, filterModel: JSON.stringify(params.filterModel)};
        console.log("data model:");
        console.dir(dataModel);
        if (params.filterModel !== undefined) {
            filterModel = where(params.filterModel);
        }
        console.log(`filter model: ${filterModel}`);

        // query GraphQL endpoint
        client
            .query({
                query: EAI_CREATED_DOCUMENT_LIST,
                fetchPolicy: 'network-only',
                variables: {
                    days: this.days,
                    pageNumber: Number.parseInt(String(params.startRow === undefined ? 0 : params.startRow / pageSize)),
                    pageSize,
                    dataModel
                },
            })
            .then((response) => {
                //console.dir(response);
                const rows = response.data.getEAICreatedDocumentDetailList;
                // determine last row to size scrollbar and last block size correctly
                let lastRow = -1;
                //console.log(`Cache Block size: ${this.gridOptions.cacheBlockSize}`);
                if (rows.length < this.gridOptions.cacheBlockSize) {
                    if (params.startRow === undefined) {
                        lastRow = rows.length;
                    } else {
                        lastRow = params.startRow + rows.length;
                    }
                    params.successCallback(rows, lastRow);
                    this.setIsLoading(false);
                } else {
                    //This can be a static count or dynamic like i have it here in this example
                    //or we juts send -1 all time when more records exists but then we dont know how many pages exists in advance
                    if (this.numberOfRows === 0) {
                        client
                            .query({
                                query: INCOMING_INFOLOG_DOCUMENT_COUNT,
                                fetchPolicy: 'network-only',
                                variables: { days: this.days },
                            })
                            .then((getLogInfoDocumentCount) => {
                                console.log('count of rows');
                                console.dir(getLogInfoDocumentCount.data.getLogInfoDocumentCount);
                                lastRow = getLogInfoDocumentCount.data.getLogInfoDocumentCount;
                                console.log(`Last row: ${lastRow}`);
                                this.numberOfRows = lastRow;
                                params.successCallback(rows, lastRow);
                                this.setIsLoading(false);
                            })
                            .catch((error) => {
                                this.setIsLoading(false);
                                console.error(error);
                            });
                    } else {
                        lastRow = this.numberOfRows;
                        params.successCallback(rows, lastRow);
                        this.setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                this.setIsLoading(false);
                console.error(err);
                params.failCallback();
            });
    }
}

export default DocumentDetailDatasource;
