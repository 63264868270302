import {renderAsDate} from "../users/Users";
import { IntlShape } from "react-intl/src/types";
import { getI18n } from '../../utils/Utils';

export const INCOMING_DOCS_SUMMARY_COLS =(intl: IntlShape) => [
    {
        headerName: getI18n("freight-documents-dashboard-process-errors", "Process Errors", intl),
        field: 'processError',
        colId: 'processError',
        filter: 'agMultiColumnFilter',
        checkboxSelection: true,
         headerCheckboxSelection: true
    },
    {   
        headerName: getI18n("freight-documents-dashboard-volume", 'Volume', intl),
        field: 'volume',
        colId: 'volume',
        filter: true,
    },
    {
        headerName: getI18n("freight-documents-first-occurance", 'First occurance', intl),
        field: 'firstOccurance',
        colId: 'firstOccurance',
        filter: true,
        cellRendererFramework: (params) => {
            return params.value ? params.value.substring(0,16) : '';
        },
    },
    {
        headerName: getI18n("freight-documents-last-occurance", 'Last occurance', intl),
        field: 'lastOccurance',
        colId: 'lastOccurance',
        filter: true,
        cellRendererFramework: (params) => {
            return params.value ? params.value.substring(0,16) : '';
        },
    },
    ];

export const INCOMING_DOCS_DETAILS_COLS =(intl: IntlShape) => [
                {headerName: getI18n("freight-documents-document-incoming-id", 'Document incoming id', intl),
                field: 'documentIncomingId',
                checkboxSelection: true,headerCheckboxSelection: true, filter: true},
                {headerName: getI18n("freight-documents-log-object-id", 'Log object id', intl),
                field: 'logObjectId', filter: false},
                {headerName: getI18n("freight-documents-company-code", 'Company code', intl), 
                field: 'companyCode', filter: false},
                {headerName: getI18n("freight-documents-partner-no", 'Partner no', intl), 
                field: 'partnerNo', filter: false},
                {headerName: getI18n("freight-documents-process-code", 'Process code', intl), 
                field: 'processCode', filter: false},
                {headerName: getI18n("freight-documents-area", 'Area', intl), 
                field: 'area', filter: false},
                {headerName: getI18n("freight-documents-file-name", 'File name', intl), 
                field: 'fileName', filter: false},
                // {headerName: 'Insert user',field: 'insertUser', filter: false},
                {headerName: getI18n("freight-documents-insert-time", 'Insert time', intl), 
                field: 'insertTime', filter: false,  cellRendererFramework: (params) => {
                return params.value ? params.value.substring(0,16) : '';
                  },},
                {headerName: getI18n("freight-documents-reference", 'Reference', intl), 
                field: 'reference', filter: false},
                {headerName: getI18n("freight-documents-date-received", 'Date received', intl), 
                field: 'dateReceived', filter: false,  cellRendererFramework: (params) => {
                        return params.value ? params.value.substring(0,10) : '';
                    },},
                {headerName: getI18n("freight-documents-dashboard-process-errors", 'Process Errors', intl), 
                field: 'processError', filter: 'agMultiColumnFilter'},
];

export const PENDING_DOCS_COLS =(intl: IntlShape) => [
    {headerName: getI18n("freight-documents-document-id", 'Document id', intl), 
    field: 'documentId', checkboxSelection: true,headerCheckboxSelection: true, filter: true},
    {headerName: getI18n("freight-documents-log-object-id", 'Log object id', intl), 
    field: 'logObjectId', filter: false},
    {headerName: getI18n("freight-documents-partner-no", 'Partner no', intl), 
    field: 'partnerno', filter: false},
    {headerName: getI18n("freight-documents-process-code", 'Process code', intl), 
    field: 'processCode', filter: false},
    {headerName: getI18n("freight-documents-reference", 'Reference', intl), 
    field: 'reference', filter: false},
    {headerName: getI18n("freight-documents-date-received", 'Date received', intl),
    field: 'dateReceived', filter: false},
    {headerName: getI18n("freight-documents-download-link", 'Download link', intl),
    field: 'downloadLink', filter: false},
    {headerName: getI18n("freight-documents-downloaded", 'Downloaded', intl),
    field: 'downloaded', filter: false},
    {headerName: getI18n("freight-documents-quantity", 'Quantity', intl),
    field: 'quantity', filter: false},
    {headerName: getI18n("freight-documents-amount", 'Amount', intl),
    field: 'amount', filter: false},
    {headerName: getI18n("freight-documents-currency", 'Currency', intl),
    field: 'currency', filter: false},
    {headerName: getI18n("freight-documents-blobid", 'Blob id', intl),
    field: 'blobId', filter: false},
    {headerName: getI18n("freight-documents-status", 'Status', intl),
    field: 'status', filter: false},
    {headerName: getI18n("freight-documents-area", 'Area', intl),
    field: 'area', filter: false},
    {headerName: getI18n("freight-documents-file-name", 'File name', intl),
    field: 'fileName', filter: false},
    {headerName: getI18n("freight-documents-file-prefix", 'File prefix', intl),
    field: 'filePrefix', filter: false},

    {headerName: getI18n("freight-documents-insert-user", 'Insert user', intl),
    field: 'insertUser', filter: false},
    {headerName: getI18n("freight-documents-update-user", 'Update user', intl),
    field: 'updateUser', filter: false},

    {headerName: getI18n("freight-documents-insert-ts", 'Insert ts', intl),
    field: 'insertTs', filter: false,  cellRendererFramework: (params) => {
            return params.value ? params.value.substring(0,16) : '';
        },},
    {headerName: getI18n("freight-documents-update-time", 'Updatetime', intl),
    field: 'updatetime', filter: false,  cellRendererFramework: (params) => {
            return params.value ? params.value.substring(0,16) : '';
        },}
];
