import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DESTROY_SESSION } from "./IStoredDocumentRecordSlice";
import { EcapEntitlementPartner } from '../../__generated__/types';

interface EcapPartnerEntitlementPartnerState {
  ecapEntitlementPartner: EcapEntitlementPartner[];
}

const initialState: EcapPartnerEntitlementPartnerState = {
  ecapEntitlementPartner: undefined
};

const ecapEntitlementPartnerSlice = createSlice({
  name: "partnerRecord",
  initialState,
  reducers: {
    updateSelectedEcapEntitlementPartner(state, action: PayloadAction<EcapEntitlementPartner[]>) {
      const partnerRecord = action.payload;
      state.ecapEntitlementPartner = partnerRecord;
    },
    destroyPartnerState(state, action: PayloadAction<string>) {
      if (action.payload === DESTROY_SESSION) {
        state.ecapEntitlementPartner = initialState.ecapEntitlementPartner;
      }
    }
  }
});

export const { updateSelectedEcapEntitlementPartner, destroyPartnerState } = ecapEntitlementPartnerSlice.actions;
export default ecapEntitlementPartnerSlice.reducer;