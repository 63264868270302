import React from "react";
import { Col, Form, Input, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useIntl } from "react-intl";

const style = { background: 'white', padding: '8px 0' };
const styleComplete = { background: 'green', padding: '8px 0'  };
const styleIncomplete = { background: 'orange', padding: '8px 0' };

const VerifySubmitRow = ({inputColLabel,inputColName, inputColValue, textAreaName, textAreaValue, completeFlag }) => {

    const intl = useIntl();

    return (
        <Row gutter={18}>
            <Col className="gutter-row" span={8}>
                <div style={style}>
                    <Form.Item
                        label={inputColLabel}
                        name={inputColName}>
                        <Input style={completeFlag ? styleComplete: styleIncomplete}
                            value={inputColValue}
                            type="text"
                            readOnly={true}
                            contentEditable={false}
                        />
                    </Form.Item>
                </div>
            </Col>
            <Col className="gutter-row" span={16}>
                <div style={style}>
                    <Form.Item
                        name={textAreaName}
                        >
                        <TextArea 
                            readOnly={true} 
                            rows={2} 
                        >{textAreaValue}
                        </TextArea>
                    </Form.Item>
                </div>
            </Col>
        </Row>
    );
}

export default VerifySubmitRow;