import 'regenerator-runtime/runtime';
import { Workbook  } from 'exceljs';

export interface ExcelSheetColumn {
    header:string;
    key:string
    width?:number;
    outlineLevel?:number;
    horizontalAlignment?:'left'|'center'|'right';
    color?:string,
    backgroundColor?:string;
}

function updateCellAlignment(headings: ExcelSheetColumn[], workSheet) {
    for (let i = 0; i < headings.length; i++) {
        const excelSheetColumn = headings[i];
        if (excelSheetColumn.horizontalAlignment) {
            const column = workSheet.getColumn(excelSheetColumn.key);
            column.eachCell(function (cell, rowNumber: number) {
                if (rowNumber > 0) {
                    cell.alignment = {horizontal: excelSheetColumn.horizontalAlignment}
                }
            });
        }
    }
}

function updateHeadingColors(headings: ExcelSheetColumn[], workSheet) {
    const headingRow = workSheet.getRow(1);

    for (let i = 0; i < headings.length; i++) {
        const excelSheetColumn = headings[i];
        const cell = headingRow.getCell(i+1);
        cell.fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:excelSheetColumn.color || 'FF0000FF'},
            bgColor:{argb:'FF0000FF'}
        };
    }
}

export async function createInfoPortalReport(sheetName:string, headings:ExcelSheetColumn[], rowData:any[]) {
    const workbook = new Workbook();
    workbook.creator = 'system';
    workbook.lastModifiedBy = 'system';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();

    const workSheet = workbook.addWorksheet(sheetName);
    workSheet.columns = headings

    for (let i = 0; i < rowData.length; i++) {
        const rowDatum = rowData[i];
        workSheet.addRow({...rowDatum,id:i+1});
    }

    updateCellAlignment(headings, workSheet);
    updateHeadingColors(headings, workSheet);

    return workbook.xlsx.writeBuffer();
}