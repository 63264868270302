import React, {useEffect, useState} from "react";
import { IAppComponentProps } from "../../components";
import { Button, Card, Col, ConfigProvider, Divider, Form, Row, Spin, Statistic, Switch, Tabs, Typography } from "antd";
import enUS from "antd/lib/locale/en_US";
import {PageContainer} from "@ant-design/pro-layout";
import styles from "../../styles/scss/portal.scss";
import {AgGridReact} from "ag-grid-react";
import {INCOMING_DOCS_SUMMARY_COLS} from "./utils";
import {GridApi} from "ag-grid-community";
import client from "../../config/GraphQLApolloClient";
import { showErrorNotification, showSuccessNotification } from "../Notification";
import {
  INCOMING_DOCS_SUMMARY_DATA,
  PENDING_DOCS_DATA, PENDING_DOCS_VOLUME,
  REPROCESS_INCOMING_DOCUMENTS
} from "./queries";
import DocumentDetailComponent from "./DocumentDetailGrid";
import PendingDocumentsComponent from "./PendingFilesGrid";
import {ArrowDownOutlined, ArrowUpOutlined} from "@ant-design/icons";
import { useIntl } from "react-intl";
import { getI18n } from '../../utils/Utils';

const Dashboard: React.FunctionComponent<IAppComponentProps> = (props) => {
  const intl = useIntl();
  const [dashboardGridApi, setDashboardGridApi] = useState<GridApi>(undefined);
  const [documentDetailGridApi, setDocumentDetailGridApi] = useState<GridApi>(undefined);
  const [pendingFilesGridApi, setPendingFilesGridApi] = useState<GridApi>(undefined);
  const [loading, isLoading] = useState(false);
  const [incomingDocsSummaryData, setIncomingDocsSummaryData] = useState(undefined);
  const [selectedProcessError, setSelectedProcessError] = useState(undefined);
  const [reprocessStatus, setReprocessStatus] = useState(undefined);
  const [processErrorsCount, setProcessErrorsCount] = useState(undefined);
  const [pendingDocsCount, setPendingDocsCount] = useState(undefined);
  const [activeTabKey, setActiveTabKey] = useState("1");
  const currentUser = props.currentUser;

  useEffect(() => {
    if (incomingDocsSummaryData === undefined) {
      isLoading(true);
      Promise.all([
        client.query({
          query: INCOMING_DOCS_SUMMARY_DATA,
          fetchPolicy: "cache-first"
        }),
        client.query({
          query: PENDING_DOCS_DATA,
          fetchPolicy: "cache-first"
        }),
        client.query({
          query: PENDING_DOCS_VOLUME,
          fetchPolicy: "cache-first"
        })
      ])
        .then(([incomingDocsSummaryData, pendingDocumentsData, pendingDocsVolume]) => {
          setProcessErrorsCount(incomingDocsSummaryData.data.getIncomingDocumentsSummary.length);
          setPendingDocsCount(pendingDocsVolume.data.getUnavailableDocsVolume.volume);
          setIncomingDocsSummaryData(incomingDocsSummaryData.data.getIncomingDocumentsSummary);
          isLoading(false);
        })
        .catch((error) => {
          isLoading(false);
          showErrorNotification(`${error}`);
        });
        }
    }, [incomingDocsSummaryData]);

    const { TabPane } = Tabs;
    const sizes: React.CSSProperties = {
        height: "150%",
        width: "98%"
    };

    const onDashboardGridReady = (params) => {
      setDashboardGridApi(params.api);
      params.api.sizeColumnsToFit();
    };

  const onDocumentSummaryExport = () => {
    dashboardGridApi.exportDataAsExcel({
      onlySelectedAllPages: true
    });
  };

  const onDocumentDetailExport = () => {
    documentDetailGridApi.exportDataAsExcel({
      onlySelectedAllPages: true
    });
  };

  const onPendingFilesExport = () => {
    pendingFilesGridApi.exportDataAsExcel({
      onlySelectedAllPages: true
    });
  };

  const onDetailGridReady = (params) => {
    setDocumentDetailGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const onReprocess = () => {
    if (reprocessStatus === undefined) {
      isLoading(true);
      client.query({
        query: REPROCESS_INCOMING_DOCUMENTS,
        fetchPolicy: "cache-first"
      })
        .then(reprocessStatus => {
          setReprocessStatus(reprocessStatus.data.value.reprocessDocumentsIncoming);
          isLoading(false);
                    showSuccessNotification(reprocessStatus);
                })
                .catch((error) => {
                    isLoading(false);
                  showErrorNotification(`${error}`);
                });
    }
  }

  const onCellDoubleClicked = (data) => {
    setActiveTabKey("2");
    setSelectedProcessError(data.value);
  };

  const onTabChange = (key) => {
    setActiveTabKey(key);
  };

  const onSwitchChanged = (checked) => {
    isLoading(true);
    if (checked) {
      const filteredDocSummaryData = incomingDocsSummaryData.filter((data) => !(data.processError).includes("Duplicate"));
      setIncomingDocsSummaryData(filteredDocSummaryData);
      isLoading(false);
    } else {
      client.query({
        query: INCOMING_DOCS_SUMMARY_DATA,
        fetchPolicy: "cache-first"
      }).then((response) => {
        setIncomingDocsSummaryData(response.data.getIncomingDocumentsSummary);
        isLoading(false);
      }).catch((error) => {
        isLoading(false);
        showErrorNotification(`${error}`);
      });

    }
  };

  return (

    <ConfigProvider locale={enUS}>
      <Spin spinning={loading} size={"large"}>
        <PageContainer
          title={false}
          content={""}
          extraContent={""}
          className={styles.users}
                style={{ background: "white" }}
            >
                <div style={{ ...sizes, minHeight: "200px" }} className="pl15 ag-theme-balham">
                        <Row gutter={24}>
                            <Col md={24} sm={12} xs={24}>
                                <div className="pl15" >
                                  <h1>{getI18n("freight-documents-dashboard-title", "FREIGHT DOCUMENTS", intl)}               
                                  </h1>
                                </div>
                            </Col>
                        </Row>

                    <div className="dashboard-statistics-card">
                        <Row gutter={16}>
                            <Col span={12}>
                                <Card>
                                  <Statistic
                                    title={<strong> {getI18n("freight-documents-dashboard-process-errors", "Process Errors", intl)} </strong>}
                                    value={processErrorsCount}
                                    valueStyle={{
                                      color: "#cf1322",
                                      padding: "20px",
                                      background: "#F5F5F5",
                                      fontWeight: "bold"
                                    }}

                                  />
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card>
                                  <Statistic
                                    title={<strong> {getI18n("freight-documents-dashboard-pending-files", 
                                    'Pending files', intl)} </strong>}
                                    value={pendingDocsCount}
                                    valueStyle={{
                                      color: "#cf1322",
                                      padding: "20px",
                                      background: "#F5F5F5",
                                      fontWeight: "bold"
                                    }}
                                  />
                                </Card>
                            </Col>
                        </Row>
                    </div>

                        <Row gutter={24}>

                          <Col md={24} sm={12} xs={24}>
                            <Tabs defaultActiveKey={"1"} className="user-tabs" activeKey={activeTabKey}
                                  onChange={(key) => {
                                    onTabChange(key);
                                  }}>
                              <TabPane tab={getI18n("freight-documents-dashboard-process-errors", "Process Errors", intl)} key="1">
                                <Row gutter={24}>
                                  <Col md={24} sm={12} xs={24}>
                                    <Button type="primary" onClick={onDocumentSummaryExport} className={"mr25"}>
                                    {getI18n("freight-documents-dashboard-export-selected", 'Export selected', intl)}
                                    </Button>
                                    <Switch checkedChildren={getI18n("freight-documents-dashboard-show-duplicates", "Show duplicates", intl)} unCheckedChildren=
                                    {getI18n("freight-documents-dashboard-hide-duplicates", 'Hide duplicates', intl)}
                                 
                                            onChange={onSwitchChanged} />
                                  </Col>

                                </Row>
                                <Row gutter={24}>
                                            <Col md={24} sm={12} xs={24}>
                                        <div style={{ flex: "auto", height: "500px", paddingTop: "10px" }}>
                                            <AgGridReact
                                              defaultColDef={{
                                                    enableValue: true,
                                                    sortable: true,
                                                    resizable: true
                                                }}
                                              columnDefs={INCOMING_DOCS_SUMMARY_COLS(intl)}
                                              rowData={incomingDocsSummaryData}

                                              sideBar={false}
                                              rowClass="agGridRows"
                                              suppressMenuHide={true}
                                              debug={false}
                                              pagination={true}
                                              paginationPageSize={15}
                                              suppressRowClickSelection={true}
                                              onGridReady={onDashboardGridReady}
                                              onCellDoubleClicked={onCellDoubleClicked}
                                              rowSelection={"multiple"}
                                            />
                                        </div>
                                            </Col>
                                </Row>
                              </TabPane>
                              <TabPane tab={getI18n("freight-documents-dashboard-document-details",
                               'Document details', intl)} key="2">
                                <Row gutter={24}>
                                  <Col md={24} sm={12} xs={24} span={24}>
                                    <Button type="primary" onClick={onDocumentDetailExport} className={"mr25"}>
                                    {getI18n("freight-documents-dashboard-export-selected",
                               'Export selected', intl)}
                                    </Button>
                                    <Button type="primary" onClick={onReprocess}>
                                    {getI18n("freight-documents-dashboard-reprocess-all",
                               'Reprocess All', intl)}
                                    </Button>
                                  </Col>
                                </Row>
                                <Row gutter={24}>
                                  <Col md={24} sm={12} xs={24}>
                                    <div style={{ flex: "auto", height: "500px", paddingTop: "10px" }}>
                                      <DocumentDetailComponent
                                        selectedProcessError={selectedProcessError}
                                        currentUser={currentUser}
                                        intl={props.intl}
                                        loading={loading}
                                        isLoading={isLoading}
                                        onGridReady={onDetailGridReady}
                                        distinctEntitlements={props.distinctEntitlements}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                              </TabPane>
                              <TabPane tab={getI18n("freight-documents-dashboard-pending-files",
                               'Pending files', intl)
                                } key="3">
                                <Row gutter={24}>
                                  <Col md={24} sm={12} xs={24}>
                                    <Button type="primary" onClick={() => {
                                    }} className={"mr5"}>
                                      {getI18n("freight-documents-dashboard-refresh",
                               'Refresh', intl)}                                                       
                                    </Button>
                                  </Col>
                                </Row>
                                <Row gutter={24}>
                                  <Col md={24} sm={12} xs={24}>
                                    <div style={{ flex: "auto", height: "500px", paddingTop: "10px" }}>
                                                    <PendingDocumentsComponent
                                                        currentUser={currentUser}
                                                        intl={props.intl}
                                                        isLoading={isLoading}
                                                        distinctEntitlements={props.distinctEntitlements}
                                                />
                                                </div>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </Tabs>
                            </Col>
                        </Row>
                </div>
            </PageContainer>
        </Spin>
        </ConfigProvider>
    );
}

export default Dashboard;