import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Menu, Spin } from "antd";
import React from "react";
import HeaderDropdown from "../HeaderDropdown";
import styles from "./index.less";
import { IAppComponentProps, PortalUser } from "../index";
import UserProfileModal from "./UserProfileModal";
import NewUserProfileModal from "./NewUserProfileModal";
import client from "../../config/GraphQLApolloClient";
import { getRealm, QUERY_GET_WEBEAM_SESSION_LOGOUT_URL } from "../../main/OauthUtils";
import { entitlementExists } from "../../main/entitlementService";
import { getUserEntitlementResultByUserId_getUserEntitlementResultByUserId_partners } from "src/main/__generated__/getUserEntitlementResultByUserId";

export type GlobalHeaderRightProps = {
  currentUser?: PortalUser;
  distinctEntitlements?: string[];
    entitlementPartner?:getUserEntitlementResultByUserId_getUserEntitlementResultByUserId_partners[];
  menu?: boolean;
} & IAppComponentProps;

class AvatarDropdown extends React.Component<GlobalHeaderRightProps> {

  constructor(props) {
    super(props);
  }

  state = {
    visible: false
  };
  clearStorage = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("token_type");
    localStorage.removeItem("token_type");
    localStorage.removeItem("expires_in");
  };

  onLogOutMenuClick = () => {
    const idToken = localStorage.getItem("id_token");
    client.query({
      query: QUERY_GET_WEBEAM_SESSION_LOGOUT_URL,
      variables: { tokenId: idToken, realm: getRealm() },
      fetchPolicy: "network-only"
    }).then(response => {
      this.clearStorage();
      window.location.assign(`${response.data.OAuth_getWebEamSessionLogoutUrl}`);
      // history.push("/logout");
    });


    //history.push("/logout");
  };

  onProfileMenuClick = () => {
    this.setState({ visible: true });
  };

  onOK = () => {
    this.setState({ visible: false });
  };

  onCancel = () => {
    this.setState({ visible: false });
  };

  render() {
    const {
      currentUser = {
        avatar: "",
        name: ""
      }
    } = this.props;
    const menuHeaderDropdown = (
      <>
        <Menu className={styles.menu} selectedKeys={[]}>

          <Menu.Item key="center" onClick={this.onProfileMenuClick}>
            <UserOutlined />
            {this.props.intl.formatMessage({ id: "user-profile-modal-heading" })}
          </Menu.Item>
          <Menu.Item key="logout" onClick={this.onLogOutMenuClick}>
            <LogoutOutlined />  {this.props.intl.formatMessage({ id: "user-profile-logout" })}
          </Menu.Item>
        </Menu>
      </>

    );

    return currentUser && currentUser.firstname ? (
      <>
        <HeaderDropdown overlay={menuHeaderDropdown}>
                <span className={`${styles.action} ${styles.account}`}>
                   <UserOutlined className={styles.avatar} style={{ paddingRight: "8px" }} />
                    <span className={`${styles.name} anticon`}>{currentUser.displayName}</span>
                </span>
        </HeaderDropdown>

        <NewUserProfileModal
            onCancel={() => this.onCancel()}
            visible={this.state.visible}
            selectedUser={currentUser}
            currentUser={currentUser}
            intl={this.props.intl}
            distinctEntitlements ={this.props.distinctEntitlements}
            entitlementPartner ={this.props.entitlementPartner}
        />
      </>
    ) : (
      <span className={`${styles.action} ${styles.account}`}>
                <Spin
                  size="small"
                  style={{
                    marginLeft: 8,
                    marginRight: 8
                  }}
                />
            </span>
    );


  }
}

export default AvatarDropdown;
