import React, {useEffect, useState} from "react";
import {IntlProvider} from "react-intl";
import translations from "../translations";
import {getTranslationsByLanguageCode} from "../translations/translationService";
import {getCurrentLocale} from "../utils";
import {useAppDispatch, useAppSelector} from "./hooks";
import {rerenderAfterLanguageChange} from "../store/IEDPGlobalSlice";

const AsyncIntlProvider = ({ children }) => {
  const [translatedMessages, setTranslatedMessages] = useState(translations[ getCurrentLocale()]);
  const { languageChange } = useAppSelector((state) => state.globalStateReducer);
  const dispatch = useAppDispatch();
  const [initialLoad,setInitialLoad] = useState<boolean>(true);

  useEffect(() => {
    if (!initialLoad) {
      getTranslationsByLanguageCode(getCurrentLocale(), translations[getCurrentLocale()])//get db translations and merge into translatedmessages
          .then((response: any) => {
            setTranslatedMessages(response);//set messages to new merged data
            setTimeout(()=> {
              dispatch(rerenderAfterLanguageChange())
            },2_000)
          })
          .catch((error: any) => {
            console.error(error);
          });
    }
  }, [languageChange]);

  useEffect(()=> {
    getTranslationsByLanguageCode(getCurrentLocale(), translations[getCurrentLocale()])//get db translations and merge into translatedmessages
        .then((response: any) => {
          setTranslatedMessages(response);//set messages to new merged data
          setInitialLoad(false);
        })
        .catch((error: any) => {
          console.error(error);
        });
  },[])

    return (
    <IntlProvider key={"en"} locale={ getCurrentLocale()} messages={translatedMessages}>
      {children}
    </IntlProvider>
  );
};

export default AsyncIntlProvider;