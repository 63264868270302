import React from "react";
import {useQuery} from "@apollo/client";
import {
    selectOptionCoCodeEntitlement,
    selectOptionCoCodeEntitlementVariables
} from "../pages/users/__generated__/selectOptionCoCodeEntitlement";
import {SELECT_OPTION_CO_CODE_ENTITLEMENT_QUERY} from "../pages/users/queries";
import {IAppComponentProps} from "./index";
import {getI18n} from "../utils/Utils";
import {useIntl} from "react-intl";
import {useLocation} from "react-router-dom";
import {useAppSelector} from "../main/hooks";
import {Col, Form, Select} from "antd";

type DocumentOverviewCompanyCodeProps = {
    isFsm: boolean;
} & IAppComponentProps;

const { Option } = Select;
export const DocumentOverviewBmwCompanyCode: React.FunctionComponent<DocumentOverviewCompanyCodeProps> = (props) => {
    const { data } = useQuery<selectOptionCoCodeEntitlement, selectOptionCoCodeEntitlementVariables>(SELECT_OPTION_CO_CODE_ENTITLEMENT_QUERY, {
        fetchPolicy: "no-cache",
        variables: { uid: props.currentUser?.uid , entitlement: props.isFsm? "fsm_overview@documents": "overview@documents"}
    });

    const intl = useIntl();
    const docOverviewFiltersState = useAppSelector((state) => props.isFsm? null:state.documentOverview);
    const location = useLocation();
    const isFromCaptureDocument = !props.isFsm && location.state && location.state.isFromCaptureDocument;

    return (
        <Col span={5}>
            <Form.Item name={'bmwCompanyIdList'} key={'bmwCompanyIdList'} label={getI18n("management-companycode-label-Ecap", "BMW Company", intl)}>
                <Select placeholder={getI18n("management-companycode-label-Ecap", "BMW Company", intl)} mode={'multiple'} allowClear={true}
                        optionFilterProp="children"
                        showSearch={true}
                        data-testid={'bmwCompanyIdList'}
                        defaultValue={isFromCaptureDocument?docOverviewFiltersState.documentOverviewFilters.bmwCompanyIdList:null}
                >
                    {data && data.getSelectDropDownFromCoCodeEntitlement &&
                        data.getSelectDropDownFromCoCodeEntitlement.map((item) => (
                            <Option key={`${item.id}_${item.name}`} value={item.id} data-testid={item.id}>
                                {item.name}
                            </Option>
                        ))}
                </Select>
            </Form.Item>
        </Col>
    );
};
