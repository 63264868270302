import {gql} from "@apollo/client";
import client from "../config/GraphQLApolloClient";
import {Operation} from "@apollo/client/link/core/types";

export interface GraphQLQueryLoggerInput {
  operationName: string | null;
  variables: string | null;
  actionName: string | null;
  userName: string | null;
  partnerNumberList: string[] | null;
}

const EXCLUDED_QUERIES: string[] = ["logGraphQLQuery",
  "OAuth_getWebEamValidateToken", "validateUser", "getAllUserRights",
  "getTranslations", "getNotificationCount", "GetDropDownValues",
  "getPortalUsers", "selectOption", "getUserEntitlementResultByUserId"];

const
  LOG_GRAPH_QL_QUERY = gql`
      mutation logGraphQLQuery($graphQLQueryInput : GraphQLQueryInput!){
          logGraphQLQuery(graphQLQueryInput: $graphQLQueryInput)
      }
  `;

class GraphQLQueryLogger {

  constructor(public username: string) {
  }

  public logGraphQL = (operation: Operation) => {

    if (!EXCLUDED_QUERIES.includes(operation.operationName) && this.username !== null
      && this.username !== undefined
      && this.username !== "undefined") {
      const partnerList = this.extractPartnerNumbers(operation);

      const input: GraphQLQueryLoggerInput = {
        actionName: operation?.getContext()?.headers?.actionName || operation.operationName,
        operationName: operation.operationName,
        variables: JSON.stringify(operation.variables),
        userName: this.username,
        partnerNumberList: partnerList
      };

      client.mutate({
        mutation: LOG_GRAPH_QL_QUERY,
        variables: { graphQLQueryInput: input },
        fetchPolicy: "network-only"
      }).then(console.debug).catch(console.error);
    }
  };

  private extractPartnerNumbers(operation: Operation) {
    let partnerList: string[] = [];

    if (operation.variables.partnerNo && Array.isArray(operation.variables.partnerNo)) {
      partnerList = operation.variables.partnerNo;
    } else if (operation.variables.partnerNo && !Array.isArray(operation.variables.partnerNo)) {
      partnerList = [operation.variables.partnerNo];
    } else {
      const partnerField = operation?.getContext()?.headers?.partnerField;
      if (partnerField) {
        const value = this.readNestedValue(operation.variables, partnerField)
        if (value) {
          if (Array.isArray(value)) {
            partnerList = value;
          }
          else {
            partnerList = [...value.split(',')]
          }
        }
      }
    }

    return partnerList;
  }

  private readNestedValue = (variables:any, nestedProp:string)=> {
    let data = variables;
    const nestedProperties = nestedProp.split('.');
    let result = undefined;

    if (typeof variables === "object") {
      for (let i = 0; i < nestedProperties.length; i++) {
        result = data[nestedProperties[i]];
        if (!result) {
          break;
        }
        if (typeof result === "object") {
          data = result;
        }
      }
    }

    return result;
  }
}

export default GraphQLQueryLogger;