// ______________________________________________________________________________
//
//       Project: e-Invoicing Documents Portal
//       File: https://atc.bmwgroup.net/bitbucket/scm/einvmrp/einvoicing-portal.git
//       Version: 0.0.1
// ______________________________________________________________________________
//
//    Created by: Lesley Netshiavhela <Lesley.Netshiavhela@partner.bmw.co.za>
//    Creation date:  2021/10/27
// ______________________________________________________________________________
//
//     Copyright: (C) BMW Group 2020, all rights reserved
// ______________________________________________________________________________
//
import React, { useState } from "react";
import { IAppComponentProps, PortalUser } from "../../components";
import { Col, ConfigProvider, Form, Input, Row, Steps } from "antd";
import enUS from "antd/lib/locale/en_US";
import { PageContainer } from "@ant-design/pro-layout";
import styles from "../../styles/scss/portal.scss";
import AssignPartners from "./AssignPartners";
// eslint-disable-next-line import/named
import { useLocation } from "react-router-dom";
import AssignCompanyCode from "./AssignCompanyCode";
import { AssignRights } from "./queries";

const Assign: React.FunctionComponent<IAppComponentProps> = (props) => {
  const location = useLocation();
  // @ts-ignore
  const selectedUser: PortalUser = location.state.selectedUser;
  const [current, setCurrent] = React.useState(0);
  const [assignedPartners, setAssignedPartner] = useState<AssignRights[]>(undefined);

  const submitAssignedPartners = (assignedPartners: AssignRights[]) => {
    setAssignedPartner(assignedPartners);
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const { Step } = Steps;

  const steps = [
    {
      title: "Assign Partner",
      content: (
        <AssignPartners intl={props.intl} submitValues={(values, action) => {
          submitAssignedPartners(values);
          if (action === "next") {
            next();
          }
        }} selectedUser={selectedUser} assignedPartners={assignedPartners} currentUser={props.currentUser} distinctEntitlements={props.distinctEntitlements} />)
    },
    {
      title: "Assign Company Codes",
      content: (<AssignCompanyCode intl={props.intl} assignedPartners={assignedPartners} next={next} prev={prev}
                                   selectedUser={selectedUser}
                                   currentUser={props.currentUser}
                                   distinctEntitlements={props.distinctEntitlements} />)
    }

  ];

  return (
    <>
      <PageContainer
        title={false}
        content={""}
        extraContent={""}
        className={styles.assign}
        style={{ background: "white" }}
      >

        <Row gutter={24}>
          <Col md={22} sm={12} xs={24}>
            <div className="pl15">
              <h1>Assign User</h1>
            </div>
          </Col>
        </Row>

        <Form layout={"vertical"} initialValues=
          {{
            "firstname": selectedUser.firstname, "lastname": selectedUser.lastname,
            "email": selectedUser.email, "username": selectedUser.username
          }}>
          <h5 className="mb10 mt10 pl15">Selected User Details</h5>
          <Row gutter={20} className="pl20">
            <Col span={6}>
              <Form.Item label={"First Name"} name={"firstname"}>
                <Input disabled={true} />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item label={"Last Name"} name={"lastname"}>
                <Input disabled={true} />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item label={"Email"} name={"email"}>
                <Input disabled={true} />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item label={"User Name"} name={"username"}>
                <Input disabled={true} />
              </Form.Item>
            </Col>

          </Row>

        </Form>


        <Row gutter={24} className="pl20">
          <Col md={22} sm={12} xs={24}>
            <Steps current={current} size={"default"} className="py15 py-md-15">
              {steps.map(item => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content">{steps[current].content}</div>
          </Col>
        </Row>
      </PageContainer>
    </>
  );
};

export default Assign;
