import { Modal, Tabs } from 'antd';
import React, { useState } from 'react';
import { EntitlementFormProps } from './EntitlementInterfaces';
import { getI18n } from '../../../utils/Utils';
import EntitlementMasterDataEditComponent from './MasterDataEditComponent';
import EntitlementMasterDataEditObjectClassComponent from './MasterDataEditObjectClassComponent';
import EntitlementMasterDataEditB2XRolesComponent from './MasterDataEditB2XRolesComponent';
import RenderIfEntitled from '../../../components/EntitlementComponents';
import EntitlementMasterDataEditGroupsComponent from './MasterDataEditGroupsComponent';
import { useAppSelector } from '../../../main/hooks';
import { useDispatch } from 'react-redux';
import { updateMasterDataUpdated } from './redux/IStoredEntitlementsSlice';

const EntitlementMasterDataTabs: React.FunctionComponent<EntitlementFormProps> = (props) => {
    const [activeKey, setActiveKey] = useState('1');
    const [prevActiveKey, setPrevActiveKey] = useState('1');
    const entitlementState = useAppSelector((state) => state.entitlements);
    const dispatch = useDispatch();

    const handleTabChange = (key) => {
        if (entitlementState.entitlementSchema.masterDataEdited) {
            Modal.confirm({
                title: 'Unsaved Changes',
                content: 'You have unsaved changes. Cancel to stay on current tab or discard changes?',
                onOk: () => {
                    // Discard changes
                    dispatch(updateMasterDataUpdated(false))
                    setActiveKey(key);
                    setPrevActiveKey(key);
                },
                onCancel: () => {
                    // Stay on prev prev tab
                    setActiveKey(prevActiveKey);
                },
            });
        } else {
            setActiveKey(key);
        }
    };

    const tabItems = [
        {
            defaultActiveKey: '1',
            className: 'users-tabs ml20',
            key: '1',
            label: getI18n(
                'entitlements-dashboard-master-data-entitlements-edit-tab',
                'Edit Entitlement types',
                props.intl
            ),
            children: <EntitlementMasterDataEditComponent intl={props.intl} onLoading={props.onLoading} currentUser={props.currentUser}  />,
        },
        {
            className: 'users-tabs ml20',
            key: '2',
            label: getI18n(
                'entitlements-dashboard-master-data-object-class-edit-tab',
                'Edit Object Classes',
                props.intl
            ),
            children: <EntitlementMasterDataEditObjectClassComponent intl={props.intl} onLoading={props.onLoading} currentUser={props.currentUser} />,
        },
        {
            className: 'users-tabs ml20',
            key: '3',
            label: getI18n('entitlements-dashboard-master-date-b2x-roles-edit-tab', 'Edit B2X Roles', props.intl),
            children: <EntitlementMasterDataEditB2XRolesComponent intl={props.intl} onLoading={props.onLoading} currentUser={props.currentUser} />,
        },
        {
            className: 'users-tabs ml20',
            key: '4',
            label: getI18n(
                'entitlements-dashboard-master-data-groups-edit-tab',
                'Edit Entitlement Groups',
                props.intl
            ),
            children: <EntitlementMasterDataEditGroupsComponent intl={props.intl} onLoading={props.onLoading} currentUser={props.currentUser}/>,
        },
    ];

    return (
        <>
            <RenderIfEntitled
                entitlementToCheck="admin@entitlements"
                distinctEntitlements={props.distinctEntitlements}
                intl={props.intl}
            >
                <h3>Master Data Maintenance</h3>
                <Tabs activeKey={activeKey} defaultActiveKey="1" items={tabItems} onChange={handleTabChange}/>
            </RenderIfEntitled>
        </>
    );
};

export default EntitlementMasterDataTabs;
