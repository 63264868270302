import React, { FunctionComponent } from "react";
import { Tabs } from "antd";

import { IAppComponentProps } from "../../components";
import "./SurveyDocumentTypeStep.css";
import SurveySuppliersDocumentType from "./eDocumentsType/SurveySuppliersDocumentType";
import { PARTNER_TYPE } from "../../constants";
import SurveyCustomerDocumentType from "./eDocumentsType/SurveyCustomerDocumentType";
import {StateContainer} from "./utilities/StateContainer";

const { TabPane } = Tabs;

type ISurveyDocumentTypeStepProps = {
  next?: any;
  prev?: any;
  surveyMasterData: any;
//  surveyChannelDataData: any;
  onStatusUpdate: any;
  stateContainer?: StateContainer;
} & IAppComponentProps;

const SurveyDocumentTypeStep: FunctionComponent<ISurveyDocumentTypeStepProps> = (props) => {


  const partnerType = localStorage.getItem(PARTNER_TYPE);



  return (
    <>
      <div className={"ml-10 ag-theme-balham"}>
        {partnerType === "V" ?
          <SurveySuppliersDocumentType surveyMasterData={props.surveyMasterData} next={props.next}
                                       prev={props.prev}
                                       onStatusUpdate={props.onStatusUpdate} stateContainer={props.stateContainer}/> :
          <SurveyCustomerDocumentType surveyMasterData={props.surveyMasterData}
                                      next={props.next} prev={props.prev} onStatusUpdate={props.onStatusUpdate} stateContainer={props.stateContainer} />}
      </div>
    </>
  );

};

export default SurveyDocumentTypeStep;
