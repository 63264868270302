import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners } from "../../main/__generated__/getSelfEntitlementResultByFilter";

interface DraftDocumentLoadingState {
  loading: boolean;
  total: number;
}

const initialState: DraftDocumentLoadingState = { loading: false, total: undefined };

const draftDocumentLoadingSlice = createSlice({
  name: "DraftDocumentLoading",
  initialState,
  reducers: {
    isDraftDocumentLoading(state, action: PayloadAction<boolean>) {
      const loading = action.payload;
      state.loading = loading;
    },
    setTotalDraftDocument(state, action: PayloadAction<number>) {
      state.total = action.payload;
    }
  }
});

export const { isDraftDocumentLoading, setTotalDraftDocument } = draftDocumentLoadingSlice.actions;
export default draftDocumentLoadingSlice.reducer;