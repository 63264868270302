import React from 'react';
import { Form, Input, Row, Col } from 'antd';
import { useIntl } from 'react-intl';
import { RenderIf } from '../../../components/EntitlementComponents';
import {Rule} from "rc-field-form/lib/interface";

export interface FormItemParameters {
    label: string | JSX.Element;
    placeholder?: string;
    name: any;
    requiredMessage?: string;
    formatMessage?: string|string[];
    tooltip?: string;
    type?: string;
    children?: any;
    itemRules?: any;
}

// The property values are set via the "antd" form.setFieldsValue(initialValues) method
export function Form_Item(props: FormItemParameters): JSX.Element {
    const intl = useIntl();
    const requiredMessage = props.requiredMessage
        ? props.requiredMessage
        : intl.formatMessage({
              id: 'generic-requiredField-message',
              defaultMessage: `Please Provide Required Field`,
          });
    const valueProp = props.type === 'checkbox' ? 'checked' : 'value';
    //These are typically settings coming from Rules engine
    // eslint-disable-next-line no-console
    //console.debug('Applied Rules from rules Engine');
    // eslint-disable-next-line no-console
    //console.debug(props);
    const pictureList: RegExp[] = [];
    const formatMessage:string[] = [];
    const rule = props.itemRules;
    const isReadOnly = rule?.readOnly === undefined ? false : rule?.readOnly; //text boxes need the readOnly flag to disable the field without loosing contrast
    const isRequired = rule?.required === undefined ? false : rule?.required; //for all components
    const isHidden = rule?.hidden === undefined ? false : rule?.hidden;

    if (rule?.picture) {
        if (Array.isArray(rule.picture)) {
            let messageArray = [];
            if (Array.isArray( props.formatMessage) || Array.isArray(rule.formatMessage)) {
                messageArray = props.formatMessage || rule.formatMessage;
            }

            rule.picture.forEach((item,idx) => {
                pictureList.push(new RegExp(item));
                if (idx<messageArray.length) {
                    formatMessage.push(messageArray[idx]);
                }
                else {
                    formatMessage.push(intl.formatMessage({
                        id: 'general-format-field-error',
                        defaultMessage: `The field do not match the required format`,
                    }))
                }
            });
        }
        else {
            pictureList.push(new RegExp(rule.picture));

            const extractedFormatMessage = props.formatMessage || rule.formatMessage;
            if (extractedFormatMessage && !Array.isArray( extractedFormatMessage)) {
                formatMessage.push(extractedFormatMessage);
            }
            else {
                formatMessage.push(intl.formatMessage({
                    id: 'general-format-field-error',
                    defaultMessage: `The field do not match the required format`,
                }))
            }
        }
    }

    let inputItem;
    if (props.type === 'checkbox') {
        inputItem =
            props.children === undefined ? (
                <Input disabled={isReadOnly} className="mr10" type={'checkbox'} />
            ) : (
                props.children
            );
    } else {
        inputItem =
            props.children === undefined ? (
                <Input readOnly={isReadOnly} className="mr10" type={props.type ? props.type : 'text'} placeholder={props.placeholder}/>
            ) : (
                props.children
            );
    }

    function retrieveRules() {
        const ruleArray:Rule[] = [{ required: isRequired, message: requiredMessage }];
        for (let i = pictureList.length - 1; i >= 0; i--) {
            ruleArray.push({ pattern: pictureList[i], message: formatMessage[i] });
        }
        return ruleArray;
    }

    return (
//change made for React 18 upgrade error, this needs to be tested once app is running
        <div>
        { !isHidden &&
        <Form.Item className={"info-portal-ant-form-item-label"}
                       label={props.label}
                       tooltip={props.tooltip}
                       name={props.name}
                       rules={retrieveRules()}
                       valuePropName={valueProp}
            >
              {inputItem}
            </Form.Item> }
            </div>

        // <RenderIf render={!isHidden}>
        //     {/*  <Row>
        // <Col>*/}
        //     <Form.Item className={"info-portal-ant-form-item-label"}
        //                label={props.label}
        //                tooltip={props.tooltip}
        //                name={props.name}
        //                rules={retrieveRules()}
        //                valuePropName={valueProp}
        //     >
        //       {inputItem}
        //     </Form.Item>
        // </RenderIf>
    );
}
