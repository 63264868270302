import { Card, Col, FormInstance, Row, Spin, Table } from 'antd';
import { IAppComponentProps } from '../../../components';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../main/hooks';
import { GET_invoice_agreement_history_getAgreementHistory } from '../supplier/__generated__/GET_invoice_agreement_history';
import Link from 'antd/lib/typography/Link';
import { saveAs } from 'save-as';
import { useLazyQuery } from '@apollo/client';
import { downloadSurveyDocument } from '../../survey/surveyAgreements/__generated__/downloadSurveyDocument';
import { downloadSurveyDocumentVariables } from '../../survey/__generated__/downloadSurveyDocument';
import { DOWNLOAD_SURVEY_DOCUMENT_QUERY_BY_BLOB_ID } from '../../survey/surveyAgreements/queries';
import { useIntl } from 'react-intl';
import { getI18n } from '../../../utils/Utils';

import { Buffer } from 'buffer';
import { convertFromSpecifiedDateFormatToUserDefined } from '../../users/UserFormatPreferenceServices';

type EInvoicingAgreementProps = {
    form: FormInstance<any>;
} & IAppComponentProps;

const EInvoicingAgreement: React.FunctionComponent<EInvoicingAgreementProps> = (props) => {
  const intl = useIntl();
  const { document } = useAppSelector((state) => state.document);
  const [agreementData, setAgreementData] = useState<GET_invoice_agreement_history_getAgreementHistory[]>([]);
  const [loading, isLoading] = useState<boolean>(false);
  const [downloadSurveyAgreement, {}] = useLazyQuery<downloadSurveyDocument, downloadSurveyDocumentVariables>(DOWNLOAD_SURVEY_DOCUMENT_QUERY_BY_BLOB_ID, {
    fetchPolicy: "network-only", onError: (error) => console.log(error)
  });

  useEffect(() => {
      const dataSource = [];

      function getDateAccepted(theDateAccepted:string) {

        if ((theDateAccepted?.length || 0) === 19) {
          return `${convertFromSpecifiedDateFormatToUserDefined(props.currentUser,theDateAccepted.substring(0, 10), 'YYYY-MM-dd')} ${theDateAccepted.substring(11, 19)}`
        }

        return theDateAccepted;
      }

      dataSource.push({
        key: document.agreementHistory?.agreementId,
        agreementVersion: document.agreementHistory?.agreementName,
        dateAccepted: getDateAccepted(document.agreementHistory?.agreementAcceptedDate?.substring(0, 19).replace("T", " ")),
        person: document.agreementHistory?.agreementAcceptedUser
      });
      setAgreementData(dataSource);

    },
    [document]
  );


  const downloadDocument = () => {
    isLoading(true);
    downloadSurveyAgreement({ variables: { blobId: Number(document.agreementHistory?.blobId) } }).then(response => {
      const mimeType = response.data.downloadSurveyDocument.value.mimeType;
      const decodedData = Buffer.from(response.data.downloadSurveyDocument.value.encodedFileData, "base64");
      const blob = new Blob([decodedData], { type: mimeType });
      saveAs(blob, response.data.downloadSurveyDocument.value.fileName);
      isLoading(false);
    });
  };

  const columns = [
    {
      title: getI18n("e-cap-capture-document-agreement-agreementVersion",
                               "eInvoicing Agreement Version", intl),
      dataIndex: "agreementVersion",
      key: "agreementVersion",
      render: (text: any) => <Link onClick={downloadDocument}>{text}</Link>
    },
    {
      title: getI18n("e-cap-capture-document-agreement-dateAccepted", "Date accepted", intl),
      dataIndex: "dateAccepted",
      key: "dateAccepted"
    },
    {
      title: getI18n("e-cap-capture-document-agreement-person", "Person", intl),
      dataIndex: "person",
      key: "person"
    }
  ];
  return (
    <>
      <Spin spinning={loading} size={"large"}>
        <Card key={"eInvoicingAgreementKey"} size={"small"}
              className={"ml-10"}>
          <Row gutter={24} className={"ml-10 mt-10"}>
            <Col span={20} className={"ml-10 mt-10"}>
              <Table dataSource={agreementData} columns={columns} pagination={false}
                     bordered={true} className={"ml-10 mt-10"} />
            </Col>
          </Row>
        </Card>
      </Spin>
    </>

  );
};

export default EInvoicingAgreement;