import {createSlice} from "@reduxjs/toolkit";

export interface IEDPGlobalState {
    reloadAppTrigger:number;
}

export const globalStateSlice = createSlice({
    name:'globalState',
    initialState: {
        languageChange:0,
        rerenderAfterLanguageChange:0
    },
    reducers: {
        triggerLanguageChange:(state)=> {
            state.languageChange+=1;
        },
        rerenderAfterLanguageChange:(state)=> {
            state.rerenderAfterLanguageChange+=1;
        }
    }
})

export const {triggerLanguageChange,rerenderAfterLanguageChange} = globalStateSlice.actions;

export default globalStateSlice.reducer;