import { gql } from '@apollo/client';

export const INCOMING_INFOLOG_DOCS_ERROR_DATA = gql`
    query getLogInfoErrors($days: Float!,
                            $pageNumber: Float!,
                            $pageSize: Float!,
                            $dataModel: DataModel!,
                            $selectedProcessError: String!) {
        getLogInfoErrors(days: $days,
                        pageNumber: $pageNumber,
                        pageSize: $pageSize,
                        dataModel: $dataModel,
                        selectedProcessError: $selectedProcessError) {
            logInfoId
            logObjId
            partnerNo
            company
            reference
            bmwIntReference
            extReference
            channel
            targetDirectory
            insertUser
            updateUser
            insertTs
            updatetime
            crossBorder
            originalLogObjId
            duplicate
            sdiNumber
            invoiceDate
            mProcess
            direction
            finalCompletion
            cStatus
            mProvider
            mFormat
            invStatus
            receivedDate
            completeDate
            mInvprocess
            mdProcessId
            ediAgreementDate
            systemId
            sysId
            processError
            eboxFolder
            apar
            documentType
            processCode
            grossAmount
            taxAmount
            currency
            partnerCountry
            partnerName
            vinNumber
            documentAvailable
            uuid
            fileName
        }
    }
`;

export const INCOMING_INFOLOG_FULL_ERROR_REPORT = gql`
    query getFullLogInfoErrorReport($days: Float!) {
        getFullLogInfoErrorReport(days: $days) {
            logInfoId
            logObjId
            partnerNo
            company
            reference
            bmwIntReference
            extReference
            channel
            targetDirectory
            insertUser
            updateUser
            insertTs
            updatetime
            crossBorder
            originalLogObjId
            duplicate
            sdiNumber
            invoiceDate
            mProcess
            direction
            finalCompletion
            cStatus
            mProvider
            mFormat
            invStatus
            receivedDate
            completeDate
            mInvprocess
            mdProcessId
            ediAgreementDate
            systemId
            sysId
            processError
            eboxFolder
            apar
            documentType
            processCode
            grossAmount
            taxAmount
            currency
            partnerCountry
            partnerName
            vinNumber
            documentAvailable
            uuid
            fileName
            sysIdPartnerId
            cpprocPartnerId
            cppartPartnerId
            sysIdExactMatch
            companyPartnerId
            invoiceDateOk
            insertDateOk
            fileNameOk
            formatOk
            uuidOk
            partnerNoOk
            currencyOk
            processCodeOk
            sysIdOk
            partnerCountryOk
            companyExists
            partnerExists
            formatExists
            openTextTypeExists
            partnerLinkedToCompany
            partnerLinkedToCompanyAndProcess
            partnerLinkedToCompanyAndSystemId
            failReason
        }
    }
`;



export const INCOMING_INFOLOG_DOCUMENT_COUNT = gql`
    query getLogInfoDocumentCount($days: Float!) {
        getLogInfoDocumentCount(days: $days)  
    }
`;


export const EAI_CREATED_DOCUMENT_LIST = gql`
    query getEAICreatedDocumentDetailList($days: Float!,
                                        $pageNumber: Float!,
                                        $pageSize: Float!,
                                        $dataModel: DataModel!) {
        getEAICreatedDocumentDetailList(days: $days,
                                        pageNumber: $pageNumber,
                                        pageSize: $pageSize,
                                        dataModel: $dataModel
                                        ) {
            id
            companyCode
            processCodeId
            companyPartnerId
            documentNumber
            documentDate
            grossAmount
            taxAmount
            insertUser
            insertTime
            updateTime
            messageCountryCode
            messageFileName
            messageId
            processingStatusFlag
            lastUploadedFileName
            lastUploadedFileTime

        }
    }
`;






