import { gql } from "@apollo/client";

export const RETRIEVE_DROPDOWN = gql`
    query GetDropDownValues($dropDownQuery: DropDownQuery!) {
        infoPortalSelect(query: $dropDownQuery) {
            id,
            text
        }
    }
`;

export const SEARCH_FOR_SR5_REPORT = gql`
    query InfoPortalQueryApi($sr5Request: SR5Request!) {
        findSR5ReportResults(sr5Query:  $sr5Request) {
            id,
            compcode,
            currency,
            keydate,
            maxrows,
            partnerno,
            totbalance,
            logsys,
            positions {
                amtdoccur,
                blinedate,
                cleardate,
                busarea,
                clrdocno,
                currency,
                docdate,
                docno,
                doctype,
                duedate,
                dunnlevel,
                headId,
                partnerno,
                pmntblock,
                pmnttrms,
                pstngdate,
                refdocno
            }
        }
    }
`;

export const SEARCH_FOR_NR5_REPORT = gql`
    query InfoPortalBalancesNR5Api($nr5Request: SR5Request!) {
        findNR5ReportResults(nr5Query:  $nr5Request) {
            id,
            logsys,
            compcode,
            currency,
            keydate,
            maxrows,
            partnerno,
            totbalance,
            positions {
                amtdoccur,
                blinedate,
                busarea,
                clrdocno,
                currency,
                docdate,
                docno,
                doctype,
                duedate,
                dunnlevel,
                headId,
                partnerno,
                cleardate,
                pmntblock,
                pmnttrms,
                pstngdate,
                refdocno
            }
        }
    }
`;

export const SEARCH_FOR_SR4_REPORT = gql`
    query InfoPortalPaymentReportApi($sr4Request: SR4Request!, $reportType: String!) {
        findPaymentReportResults(sr4Query:  $sr4Request, reportType: $reportType) {
            id,
            logsys
            compcode
            partnerno
            contactap
            paymadviceno
            paymdate
            totpaymamnt
            currency
            extinvoice
            bmwdocno
            documentdate
            bankaccount
            bankcode
            swift
            iban
            maxrows
            deliverynoteno
            positions {
                headId,
                bmwdocno,
                extinvoice,
                currency,
                documentdate,
                spaborderno,
                invoiceamount,
                settlementtype,
                headId,
                deliverynoteno
            }
        }
    }
`;

export const RETRIEVE_INVOICES_REPORT = gql`
    query RetrieveInvoiceReport($invoiceFilter:InvoiceFilter!,$reportName:String!) {
        retrieveInvoicesReport(invoiceFilter:$invoiceFilter,reportName:$reportName) {
            id,
            logsys,
            compcode,
            invstat,
            invoicestatus,
            busarea,
            partnerno,
            partnerinvno,
            bmwdocno,
            paymentnumber,
            docdate,
            gross,
            doccurrency,
            dunnlevel,
            companyname,
            settletype,
            invstatcode,
            invprocess,
            invdirection,
            invreceiptdate,
            paymentdate,
            cumulativeno,
            paymentterm,
            purchasename,
            purchasephone,
            taxrate,
            taxamount,
            totpaymamnt,
            purchasemail,
            recipientname,
            recipientphone,
            recipientmail,
            contactap,
            contactcomplaint,
            contactinvoice,
            doctype,
            duedate,
            net,
            basedate,
            dunnarchvie,
            grossamount,
            currency,
            extinvoice,
            documentdate,
            paymadviceno,
            paymdate,
            positions {
                headId,
                plantname,
                spaborderno,
                confirmationno,
                purchasename,
                purchasephone,
                plantcode,
                purchasemail,
                purchaseorderno,
                deliverynoteno,
                deliverynotedate,
                recipientname,
                recipientphone,
                receiptdate,
                recipientmail,
                duedate,
                materialdocno,
                taxrate,
                currency,
                netamount,
                purchaseorderitem
            },
            deliverynoteNo,
            purchaseOrderNo,
            maxrows,
            rejectiondate,
            fiscalyear,
            rejectionletteruri,
            rejectionLetterFileName,
            companycode,
            purchaseorderno,
            deliverynoteno,
            xSuiteRejectionUrl
        }
    }`
;

export const SEARCH_FOR_SR2_REPORT = gql`
    query InfoPortalSR2QueryApi($sr2Request: SR2Request!, $reportName: String!) {
        findSR2ReportResults(sr2Query:  $sr2Request,reportName:$reportName) {
            id
            logsys
            companyCode
            companyName
            partnerNumber
            partnerName
            purchaseOrderNumber
            purchaseorderdate
            materialDocNumber
            grScDate
            gjahr
            bmwDocNumber
            xblnrAlt
            invoiceDate
            invoicePostDate
            partnerInvoiceNumber
            settlementType
            deliveryNoteNumber
            deliveryNoteDate
            confirmationNumber
            grValue
            currency
            plantCode
            plantName
            businessAreaCode
            businessAreaName
            documentType
            invoiceVerificationContactEmail
            recipientName
            recipientEmail
            recipientTel
            spabNumber
            docdate
            quantity
            units
            materialNumber
            materialDescription
            grstatus
            ivContactEmail
            maxrows
            procurement
            purchaseOrderItem
            overduestatus
            deliverypostdate
            materialText
            grnumber
            netamount
            extinvoice
            bmwdocno
            documentdate
            positions{
                headId
                grscitem
                material
                materialtext
                grunit
                grqty
            }
        }
    }
`;
export const SEARCH_FOR_SR1_REPORT = gql`
    query InfoPortalSR1QueryApi($sr1Request: SR1Request!, $reportName: String!) {
        findSR1ReportResults(sr1Query:  $sr1Request,reportName:$reportName) {
            id
            logsys
            compname
            partnerlf
            partnerlfname
            settletype
            settletypetxt
            potype
            ponumber
            podate
            povalue
            currency
            podlvstattxt
            invstattxt
            pmnttrms
            termsdelivery
            contactponame
            contactpoemail
            itemname
            quantity
            pounit
            plant
            busarea
            busareaname
            invoicee
            maxrows
            positions{
                headId
                poNumber
                poItem
                material
                materialText
                quantity
                pounit
                nettitemvalue
                deliveryNoteDate
                plantName
                busArea
                changedate
                contactGrName
                contactGrEmail
                deliveryaddress
                invoicedValue
                invoiceQuantity
                openValue
                openQuantity
            }
        }
    }
`;

export const DOWNLOAD_DOC_BY_BLOB_ID_AND_APPLICATION_TYPE = gql`
    query retrieveDocumentByBlobIdAndApplicationType($blobId: Float!, $application: String!){
        retrieveDocumentByBlobIdAndApplicationType(blobId : $blobId, application: $application)
        {
            success
            value {
                encodedFileData
                fileName
                mimeType
            }
        }
    }
`;

export const SEARCH_FOR_CZ_REPORT = gql`
    query CZQueryApi($czRequest: CZGenericFilter!, $report:String!) {
        findCZReport(czgenericFilter:  $czRequest, reportName: $report) {
            id
            logsys
            czcompcode
            partnerno
            invoicestatus
            bmwdocno
            documentdate
            revstattxt
            invpostdate
            grossamount
            currency
            fapiaono
            fiscalyear
            revdocno
            originvoiceno
            originvoiceyear
            origfapiaono
            positions {
                ponumber
                poitem
                material
                materialtext
                materialtextzh
                pounit
                pounitprice
                quantity
                deliverynoteno
                grdate
                amount
                currency
                bmwdocno
                reitem
                supplierclaim
                plantname
            }
            maxrows
        }
    }
`;

export const RETRIEVE_DOWNLOADED_REJECTION_LETTERS = gql`
    query DownloadedRejectionLetterReport($invoiceFilter:InvoiceFilter!) {
        getDownloadedRejectionLetters(invoiceFilter:$invoiceFilter) {
            fileName
            companyCodeName
            downloadDate
            insertDate
            partnerNo
            documentNumber
            partnerInvoice
            username
            firstname
            lastname
        }
    }`
;

export const RETRIEVE_KPI_USAGE_AGGREGATE_STATS = gql`
    query GetInfoPortalAggregateUsageStats($stats:KPIAggregatesQuery!) {
        getKPIUsageForInfoPortal(kpiQuery:$stats) {
            dayOfYear
            reportName
            partnerName
            count
        }
    }`
;

export const SEARCH_FOR_LY7_REPORT = gql`
    query InfoPortalFreightReport($freightRequest:LY7Request!) {
        findLY7Report(ly7Request:$freightRequest) {
            compcode
            logsys
            partnerno
            ponumber
            itemstatus
            fsdnumber
            grnumber
            deliverypostdate
            netamount
            currency
            extinvoice
            bmwdocno
            maxrows
            documentdate
        }
    }`
;

export const SEARCH_FOR_LY6_REPORT = gql`
    query InfoPortalFixedCostReport($fixedCost:LY6Request!) {
        findLY6Report(ly6Request:$fixedCost) {
            compcode
            logsys
            partnerno
            ponumber
            fixedcostno
            invbaselinedate
            currency
            itemstatus
            extinvoice
            bmwdocno
            invpostdate
            overduestatus
            netamount
            maxrows
        }
    }`
;

export const SEARCH_FOR_LY5_REPORT = gql`
    query InfoPortalSupplierClaimsReport($supplierClaims:LY6Request!) {
        findLY5Report(ly5Request:$supplierClaims) {
            compcode
            logsys
            partnerno
            ponumber
            claimstatus
            poposition
            materialno
            description
            supplclaimno
            givolume
            matshareamnt
            handsurchamnt
            totalamnt
            currency
            extinvoice
            bmwdocno
            invpostdate
            overduestatus
            maxrows
        }
    }`
;

export const RETRIEVE_REVALUATION_REPORT = gql`
    query RetrieveRevaluationReport($revaluationFilter:RevaluationFilter!) {
        findLY4Report(revaluationFilter:$revaluationFilter) {
            compcode
            logsys
            partnerno
            status
            purchaseordernumber
            poposition
            materialnumber
            description
            revalnumber
            revaltype
            oldnetamount
            newnetamount
            difference
            total
            currency
            documentdate
            bmwdocnumber
            grnumber
            overduestatus
            changedon
            maxrows
            extinvoice
            positions {
                revalnumber
                deliverynote
                grnumber
                material
                materialtext
                oldnet
                newnet
                diffnet
                currency
            }
        }
    }`
;

export const DOWNLOAD_REJECTION_LETTER_BY_URL = gql`
    query XSuiteDownload($fetchBlobRequest: FetchBlobRequest!){
        retrieveRejectionLetterByURL(fetchBlobRequest : $fetchBlobRequest)
        {
            encodedFileData
            fileName
            mimeType
        }
    }
`;

export const SEARCH_FOR_CR1_REPORT = gql`
    query CORAPI($cr1Query: CR1Filter!) {
        findCR1ReportResults(cr1Query:  $cr1Query) {
            id
            logsys
            companyCode
            companyName
            partnerNumber
            partnerName
            lfStreet
            lfCountry
            lfPostalCode
            lfCity
            vatNr
            poNumber
            materialNumber
            materialDescription
            deliveryNoteNumber
            deliveryNoteDate
            materialDocNumber
            gjahr
            grScItem
            grScDate
            grQuantity
            grUnit
            partnerLl
            partnerLlName
            llStreet
            llCountry
            llPostalCode
            llCity
            plantCode
            plantName
            plantAddress
            boardMemberName
            maxrows
            blobId
            documentCreated
            documentCreationDate
            yearMonth
            positions {
                logsys
                pos
                poNumber
                material
                materialText
                deliveryNote
                deliveryNoteDate
                materialDoc
                gjahr
                grScItem
                grScDate
                grQty
                grUnit
                plant
                plantName
                plantAddress
                boardMemberName
                companyName
            }
        }
    }
`;

export const LIST_ALL_CONFIG = gql`
    query FetchSapConfiguration {
        getSapSystemConfig {
            reportName
            description
            systemStatusList {
                id,
                userName,
                secretPlaceholder,
                url,
                path,
                active
            }
        }
    }
`;

export const UPDATE_SAP_CONFIGURATION = gql`
    query UpdateSapConfiguration($sapConfig: SapSystemUpdateInput!) {
        updateSapSystemConfig(input: $sapConfig) {
            reportName
            description
            systemStatusList {
                url,
                path,
                active
            }
        }
    }
`;

export const CREATE_CONFIRMATION_OF_ARRIVAL_DOCUMENT = gql`
    query CreateConfirmationOfArrivalPDF($input: CR1PDFRequest!, $language: String!) {
        createConfirmationOfArrivalPDF(input: $input, language: $language) {
            blobId
            creationTime
        }
    }
`;