import { gql } from "@apollo/client";


export const GET_SURVEY_VERIFY_REPORT = gql`
    query getSurveyVerifyReport($groupId: String!){
        getSurveyVerifyReport(groupId: $groupId) {
            groupId
            canSubmit
            canEdit
            submitReason
            surveys
            portions
            {
                portionName
                portionSequence
                statusId
                status
                canEdit
                canSubmit
                issues
                canUpdate
            }
            channelTypes
            {
                channelTypeId
                channelType
                channelDescription
                incomingChannel
                outgoingChannel
                phraseId
                processCodeId
                incomingId
            }
            formatTypes{
                documentFormatId
                documentFormat
                formatDescription
                phraseId
                incomingId
            }
        }
    }
`;


