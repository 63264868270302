import React, { useState } from 'react';
import { DropDownSelectType, EntitlementFormProps, GetEntitlementRowStyle } from './EntitlementInterfaces';
import { Button, Card, Col, Form, Input, message, Row, Select, Spin } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import { getAgGridLocalization } from '../../info-portal/components/grid/GridUtil';
import { IntlShape } from 'react-intl';
import { GridApi } from 'ag-grid-community';
import { EntitlementInput } from '../../../__generated__/types';
import { useGetAllEntitlementsQuery } from './queries.generated';
import { UpdateEntitlementTypeMutationVariables, useUpdateEntitlementTypeMutation } from './mutations.generated';
import { getI18n } from '../../../utils/Utils';
import EntitlementGroupDropDown from './EntitlementGroupDropDown';
import { ReloadOutlined, SaveOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { setEntitlementTypeUpdated, setGroupUpdated, updateMasterDataUpdated } from './redux/IStoredEntitlementsSlice';

function ENTITLEMENT_TYPE_TABLE_HEADERS(intl: IntlShape, onDelete:any, onEdit:any) {
    return [
        {
            headerName: getI18n('entitlements-grid-header-action' , 'Action' , intl),
            filter: false,
            cellRenderer: 'buttonRenderer',
            cellRendererParams: {
                onClick: onEdit,
                label: 'Edit',
                type: 'primary',
            },
            width: 100,
        },
        {
            headerName: getI18n('entitlements-grid-header-action','Action',intl ),
            filter: false,
            cellRenderer: 'buttonRenderer',
            cellRendererParams: {
                onClick: onDelete,
                label: 'Delete',
                type: 'delete',
            },
            width: 110,
        },
        {
            headerName: getI18n('entitlements-grid-header-name', 'Name',intl ),
            field: 'entitlement',
            colId: 'entitlement',
            width: 300,
            filter: 'agMultiColumnFilter',
        },
        {
            headerName: getI18n('entitlements-grid-header-description','Description',intl ),
            field: 'description',
            colId: 'description',
            width: 300,
            filter: 'agMultiColumnFilter',
        },
        {
            headerName: getI18n('entitlements-master-data-group-phrase-id-grid-header-name','Phrase Id',intl ),
            field: 'phraseId',
            colId: 'phraseId',
            width: 300,
            filter: 'agMultiColumnFilter',
        },
        {
            headerName: getI18n('entitlements-grid-header-mode' ,'Description',intl ),
            field: 'mode',
            colId: 'mode',
            width: 100,
            filter: 'agMultiColumnFilter',
        },
        {
            headerName: getI18n('entitlements-master-data-group-group-id-grid-header-name' ,'Group Id',intl ),
            field: 'groupId',
            colId: 'groupId',
            width: 200,
            filter: 'agMultiColumnFilter',
        },
        {
            headerName: getI18n('entitlements-grid-header-insert-user' ,'Insert User',intl ),
            field: 'insertUser',
            colId: 'insertUser',
            width: 130,
            filter: 'agMultiColumnFilter',
            cellRenderer: (params) => (params.value ? params.value.toUpperCase() : '')
        },
        {
            headerName: getI18n('entitlements-grid-header-insert-time' ,'Insert Time',intl ),
            field: 'insertTime',
            colId: 'insertTime',
            width: 200,
            filter: 'agDateColumnFilter',
        },
        {
            headerName: getI18n('entitlements-grid-header-update-user' ,'Update User',intl ),
            field: 'updateUser',
            colId: 'updateUser',
            width: 150,
            filter: 'agMultiColumnFilter',
            cellRenderer: (params) => (params.value ? params.value.toUpperCase() : '')
        },
        {
            headerName: getI18n('entitlements-grid-header-update-time' ,'Update Time',intl ),
            field: 'updateTime',
            colId: 'updateTime',
            width: 200,
            filter: 'agDateColumnFilter',
        },
        {
            headerName: getI18n('entitlements-grid-header-volume' ,'#Times used',intl ),
            field: 'volume',
            colId: 'volume',
            width: 150,
            filter: 'agNumberColumnFilter',
        },
        {
            headerName: getI18n('entitlements-grid-header-presentInRules' ,'Present in rules',intl ),
            field: 'presentInRules',
            colId: 'presentInRules',
            width: 150,
            filter: 'agMultiColumnFilter',
        },
        {
            headerName: getI18n('entitlements-grid-header-rulesWithWildcards' ,'Rules with Wild cards',intl ),
            field: 'rulesWithWildcards',
            colId: 'rulesWithWildcards',
            width: 150,
            filter: 'agMultiColumnFilter',
        },
    ];
}

const EntitlementMasterDataEditComponent: React.FunctionComponent<EntitlementFormProps> = (props) => {
    const [form] = Form.useForm();
    const [newEntitlements, setNewEntitlements] = useState<EntitlementInput[]>([]);
    const [gridApi, setGridApi] = useState<GridApi>(undefined);
    const [entitlementTypeList, setEntitlementTypeList] = useState<EntitlementInput[]>([]);
    const [deletedEntitlements, setDeletedEntitlements] = useState<EntitlementInput[]>([]);
    const [updatedEntitlements, setUpdatedEntitlements] = useState<EntitlementInput[]>([]);
    const [updateMode, setUpdateMode] = useState<boolean>(false);
    const [refreshLoading, setRefreshLoading] = useState(false);
    const [nameUpdateAllowed, setNameUpdateAllowed] = useState<boolean>(false);
    const dispatch = useDispatch();

    const [updateEntitlementTypeMutation, { data: updateResponse, loading: updateLoading, error: updateError }] = useUpdateEntitlementTypeMutation({
        variables: {
           entitlementTypes: [] as EntitlementInput[],
        },
      });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        //check if entitlement does not already exists in the list
        const exists = entitlementTypeList.find((entitlement) => entitlement.entitlement === value);
        if (exists && !updateMode) {
           message.error('Entitlement already exists');
        }
        form.setFieldsValue({ phraseId: 'entitlement-'.concat(form.getFieldValue('entitlement')) });
     };

    const addEntitlement = () => {
        const exists = entitlementTypeList.find((entitlement) => entitlement.entitlement === form.getFieldValue("entitlement"));
      
        if (!exists)    {
            //insert new entitlement
            const entitlement = form.getFieldValue("entitlement");
            const description = form.getFieldValue("description")
            const phraseId = form.getFieldValue("phraseId")
            const mode = form.getFieldValue("mode")
            const groupId = form.getFieldValue("entitlementGroup")
            const insertUser = props.currentUser.username;
            const updateUser = props.currentUser.username;
            const updateAction = 'insert';
            const newRecord = {entitlement, description, phraseId,mode, groupId , insertUser, updateUser, updateAction, volume: 0}
            setNewEntitlements([ ...newEntitlements, newRecord]);
            setEntitlementTypeList([...entitlementTypeList, newRecord]);
            form.setFieldValue("entitlement", '');
            form.setFieldValue("description", '');
            form.setFieldValue("phraseId", '');
            form.setFieldValue("mode", undefined);
            form.setFieldValue("entitlementGroup", undefined);
            dispatch(updateMasterDataUpdated(true));
        }
        else if (updateMode)
        {
            const entitlement = form.getFieldValue("entitlement");
            const description = form.getFieldValue("description")
            const phraseId = form.getFieldValue("phraseId")
            const mode = form.getFieldValue("mode")
            const groupId = form.getFieldValue("entitlementGroup")
            const updateUser = props.currentUser.username;
            const updateAction = 'update';
            const updatedRecord: EntitlementInput = {entitlement, description,phraseId, mode, groupId, insertUser: exists.insertUser, insertTime: exists.insertTime, updateUser, updateTime: exists.updateTime, updateAction, volume: exists.volume};
            setUpdatedEntitlements([...updatedEntitlements, updatedRecord]);
            setEntitlementTypeList(entitlementTypeList.map(item => 
                item.entitlement === entitlement 
                    ? updatedRecord
                    : item
            ));
            form.setFieldValue("entitlement", '');
            form.setFieldValue("description", '');
            form.setFieldValue("phraseId", '');
            form.setFieldValue("mode", undefined);
            form.setFieldValue("entitlementGroup", undefined);
            setUpdateMode(false);
            dispatch(updateMasterDataUpdated(true));
        }
        else
        {
            message.error('Entitlement already exists');
        }
        
    };

    const cancelUpdate = () => {
        setUpdateMode(false);
        setNameUpdateAllowed(false);
        form.setFieldValue("entitlement", '');
        form.setFieldValue("description", '');
        form.setFieldValue("phraseId", '');
        form.setFieldValue("mode", undefined);
        form.setFieldValue("entitlementGroup", undefined
        );
    }

    const onGridReady = (params) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
    };

    const ButtonRenderer = (props) => {
        const handleClick = () => {
            props.onClick(props);
        };
    
        return (
            <Button onClick={handleClick} className={`btn btn-${props.type}`}>
                {props.label}
            </Button>
        );  
    };

    const components = {
        buttonRenderer: ButtonRenderer,
    };

    const onDeleteLine = (params) => {
        if (params.data.volume > 0 && params.data?.presentInRules !== params.data?.rulesWithWildcards)
        {
            message.error(`Entitlement was used ${params.data.volume} times in Entitlement Rules and cannot be delete`);
            return;
        }
        params.api.applyTransaction({ remove: [params.data] });
        const newList = entitlementTypeList.filter((entitlement) => entitlement.entitlement !== params.data.entitlement);
        setEntitlementTypeList(newList);
        //If not insert then also add to deleted list
        if (params.data.updateAction !== 'insert') {
            const entitlement = params.data.entitlement;
            const description = params.data.description;
            const mode = params.data.mode;
            const groupId = params.data.groupId;
            const updateUser = props.currentUser.username;
            const updateAction = 'delete';
            setDeletedEntitlements([...deletedEntitlements, {entitlement, description, mode, groupId , updateUser, updateAction}]);
            dispatch(updateMasterDataUpdated(true));
        }
    };

    const onEditLine = (params) => {
        form.setFieldValue("entitlement", params.data.entitlement);
        form.setFieldValue("description", params.data.description);
        form.setFieldValue("phraseId", params.data.phraseId ? params.data.phraseId : 'entitlement-'.concat(params.data.entitlement));
        form.setFieldValue("mode", params.data.mode);
        form.setFieldValue("entitlementGroup", params.data.groupId);
        if (params.data.updateAction !== 'insert' ) {
            setUpdateMode(true);
        }
        if ((params.data?.presentInRules === params.data?.rulesWithWildcards && params.data?.volume > 0) || params.data?.volume === 0)
        {
            message.info('Entitlement name can be updated as its not used anywhere or it was matched with wild card rule');
            setNameUpdateAllowed(true);
        }
        else{
            setNameUpdateAllowed(false);
        }
    };

    const { data: entitlementTypes, loading, error, refetch } = useGetAllEntitlementsQuery({
        variables: {},
        });

    React.useEffect(() => {
        props.onLoading(loading);
        if (!loading && entitlementTypes) {
            setEntitlementTypeList(entitlementTypes.getAllEntitlements);
        }
    }, [loading, entitlementTypes]);

    if (error) {
        console.dir(error);
        message.error('Error loading entitlements');
    }

    const saveEntitlement = () => {
        //save all entitlements
       //call mutation for save
       const toBeUpdatedEntitlements = [...newEntitlements, ...updatedEntitlements, ...deletedEntitlements];
       if (toBeUpdatedEntitlements && toBeUpdatedEntitlements.length > 0)
       {
        updateEntitlementTypeMutation({
            variables: { entitlementTypes: toBeUpdatedEntitlements } as UpdateEntitlementTypeMutationVariables,})
            .then((response) => {
                //find the entitlements in responds in the entitlementTypes and update the list
                const updatedEntitlements = response.data.updateEntitlementType;
                const updatedList = entitlementTypeList.map((entitlement) => {
                    const updatedEntitlement = updatedEntitlements.find((updated) => updated.entitlement === entitlement.entitlement);
                    return updatedEntitlement ? { ...updatedEntitlement, updateAction: null  } : entitlement;
                });
                setEntitlementTypeList(updatedList);
                setNewEntitlements([]);
                setUpdatedEntitlements([]);
                setDeletedEntitlements([]);
                dispatch(updateMasterDataUpdated(false));
                dispatch(setEntitlementTypeUpdated(true));
    
                message.success(
                    getI18n('entitlement-master-data-edit-success-update-message', 'Updated successfully', props.intl)
                );
            })
            .catch((error) => {console.dir(error);});
       }
       else
       {
        message.error("Nothing to update");
       }
    }

    const refreshData = () => {
        setRefreshLoading(true);
        dispatch(setGroupUpdated(true));
        refetch().then(({ data }) => {
            setEntitlementTypeList(data.getAllEntitlements);
            if (gridApi) {
                gridApi.setRowData(data.getAllEntitlements);
            }
            setRefreshLoading(false);
        }).catch(() => {
            setRefreshLoading(false);
        });
    };

    function entitlementNameRequiredRule () {
        if (nameUpdateAllowed) return true;
        return !updateMode
    }

 
     return (
        <>
        <Spin spinning={updateLoading || loading || refreshLoading}>
            <Card>
                <Form form={form} layout="vertical" onFinish={addEntitlement}>
                    <Row gutter={24} className={'pl20'}>
                        
                        <Col span={2}>
                            <Button type={'primary'} icon={<SaveOutlined />} onClick={saveEntitlement}>Save all Entitlements</Button>
                        </Col>
                        <Col span={3}>
                            <Button type="default" icon={<ReloadOutlined />} onClick={refreshData}>
                                Refresh
                            </Button>
                        </Col>
                    </Row>
                    <Row gutter={24} className={'pl20'}>
                        <Col span={3}>
                            <Form.Item name={"entitlement"} key={"entitlement"} label={"Entitlement Name"} 
                                rules={[{ required: (entitlementNameRequiredRule()), message: 'Please input the entitlement!' }]}>
                                <Input
                                    type="text"
                                    onChange={handleInputChange}
                                    placeholder="Entitlement Name"
                                    readOnly={!entitlementNameRequiredRule()}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name={"description"} key={"description"} label={"Description"} 
                                rules={[{ required: true, message: 'Please input the description!' }]}>
                                <Input
                                    type="text"
                                    placeholder="Entitlement Description"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name={"phraseId"} key={"phraseId"} label={"Phrase Id"} rules={[{ required: true, message: 'Please input the Phrase Id!' }]}>
                                <Input
                                    type="text"
                                    placeholder="Phrase Id"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item name={"mode"} key={"mode"} label={"Mode"} rules={[{ required: true, message: 'Please select the Mode!' }]}>
                                <Select>
                                    <Select.Option value="W">Write</Select.Option>
                                    <Select.Option value="R">Read</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                             <EntitlementGroupDropDown 
                                setEntitlementGroupSelected={(_) => {}}
                                onLoading={props.onLoading}
                                dropDownSelectType={DropDownSelectType.Single}
                                required={true}
                                onGroupClear={(_) => {}}
                             />
                        </Col>
                        <Col span={2}>
                            <Button type='primary' onClick={() => form.submit()} >{updateMode ? "Update Entitlement": "Add Entitlement"}</Button>
                        </Col>
                        <Col span={2}>
                            <Button type='primary' onClick={cancelUpdate}>{updateMode ? "Cancel Update": "Clear"}</Button>
                        </Col>
                        
                    </Row>
                </Form>
                <Row gutter={24} className={'pl20'}>
                    <Col span={22}>
                        <div style={{ flex: 'auto', height: '500px', paddingTop: '10px' }} className="ag-theme-balham">
                            <AgGridReact
                                defaultColDef={{
                                    enableValue: true,
                                    sortable: true,
                                    resizable: true,
                                }}
                                rowData={entitlementTypeList}
                                columnDefs={ENTITLEMENT_TYPE_TABLE_HEADERS(props.intl, onDeleteLine, onEditLine)}
                                localeText={getAgGridLocalization(props.intl)}
                                sideBar={false}
                                rowClass="agGridRows"
                                suppressMenuHide={true}
                                debug={false}
                                pagination={true}
                                paginationPageSize={15}
                                suppressRowClickSelection={true}
                                onGridReady={onGridReady}
                                rowSelection={'multiple'}
                                components={components}
                                getRowStyle={GetEntitlementRowStyle}
                            />
                        </div>
                    </Col>
                </Row>
            </Card>
        </Spin>
        </>
    );
};

export default EntitlementMasterDataEditComponent;