import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import { Button, Modal, Tooltip } from "antd";
import { useIntl } from "react-intl";

const MissingInvoicesRowOptionFileName: React.FunctionComponent<ICellRendererParams> = (props) => {
  const intl = useIntl();

  const getFileStatus = (params) => {
    const attachmentPending = params.data.attachmentPending;
    if (attachmentPending) return intl.formatMessage({id: 'missing-invoices-file-attachment-pending', defaultMessage : 'File attachment is pending'});
    return intl.formatMessage({id: 'missing-invoices-file-attachment-available', defaultMessage : 'File attachment is available'});
  }
  
  return (<div>
    
    <Tooltip title={getFileStatus(props)}>
      {
        <div> {props.data.fileName}</div>
      }
    </Tooltip>

    </div>);
};

export default MissingInvoicesRowOptionFileName;

