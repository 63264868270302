import React, {FunctionComponent, useEffect, useState} from "react";
import {IAppComponentProps} from "../../../../components";
import enUS from "antd/lib/locale/en_US";
import deDe from "antd/lib/locale/de_DE";
import {Button, Card, Checkbox, ConfigProvider, Form, FormInstance, Modal, Radio, Space, Spin, Tooltip} from "antd";
import {getAllSapAdminConfig, updateSapAdminConfig} from "../../services/ReportConfigurationService";
import {SapAdminConfig_SapSystemUpdate, SapAdminConfig_SystemStatus} from "../../graphql/__generated__/SapAdminConfig";
import styles from "../styling/infoportal.module.css";
import {getI18n} from "../../../../utils/Utils";

const ReportingConfiguration: FunctionComponent<IAppComponentProps> = (props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedConfig, setSelectedConfig] = useState<SapAdminConfig_SapSystemUpdate>(null);
    const [sapConfigurationList, setSapConfigurationList] = useState<SapAdminConfig_SapSystemUpdate[]>();

    useEffect(() => {
        setLoading(true);

        getAllSapAdminConfig().then((data:SapAdminConfig_SapSystemUpdate[]) => {
            setSapConfigurationList(data);
            data.forEach(item=> {
                if (item.reportName === "sr3") {
                    setSelectedConfig(item);
                }
            });
        }).finally(() => {
            setLoading(false);
        })
    }, []);

    const onSelectionChecked = (val, system: SapAdminConfig_SystemStatus) => {
        const updatedValue = {...selectedConfig};
        updatedValue.systemStatusList.forEach(item => {
            if (item.url === system.url && item.path === system.path) {
                item.active = val.target.checked;
            }
        });
        setSelectedConfig(updatedValue);
        const newConfigList = [...sapConfigurationList];
        newConfigList.forEach((item, idx) => {
            if (item.reportName === selectedConfig.reportName) {
                newConfigList[idx] = updatedValue;
            }
        });
        setSapConfigurationList(newConfigList);
    };

    const selectReport = (event) => {
        sapConfigurationList.forEach(item=> {
            if (item.reportName === event.target.value) {
                setSelectedConfig(item);
            }
        });
    }

    const submitData = ()=> {
        console.log(selectedConfig);

        setLoading(true);
        updateSapAdminConfig(selectedConfig).then(_=> getAllSapAdminConfig()).then((data:SapAdminConfig_SapSystemUpdate[]) => {
            setSapConfigurationList(data);
            data.forEach(item=> {
                if (item.reportName === selectedConfig.reportName) {
                    setSelectedConfig(item);
                }
            });
            Modal.success({
                title: getI18n(
                    'Download Success',
                    'Success', props.intl,
                ),
                content: getI18n(
                    'Download Success',
                    'Success',  props.intl,
                ),
                okText: 'OK',
            });
        }).catch(error=> {
           console.error(error);
            Modal.warning({
                title: getI18n(
                    'error-occurred-while-performing-save',
                    'Error occurred while saving', props.intl,
                ),
            });

        }).finally(()=> {
            setLoading(false);
        })
    }

    return <ConfigProvider locale={localStorage.getItem("locale") === "en" ? enUS : deDe}>
        <Spin size={"large"} spinning={loading}>

            <Card size={"small"} style={{backgroundColor: "#f1f3f5"}}>
                <h3>{props.intl.formatMessage({id:'report-admin-menu-label'})}</h3>
                <div className={styles.selfBilledSimpleSearchContainer} style={{marginLeft: "10px"}}>
                    <div hidden={!sapConfigurationList?.length}>
                        <h4>{props.intl.formatMessage({id:'cor-report-select'})}</h4>
                        <Radio.Group onChange={selectReport}
                                     defaultValue={'sr3'}>
                            <Space direction={"vertical"}>
                                {sapConfigurationList?.map(config =>
                                    <Radio data-testid={`${config.reportName}-config`}
                                           className="info-radio"
                                           key={`${config.reportName}`}
                                           value={config.reportName}>
                                        {`${config.reportName}-${config.description}`}
                                    </Radio>)}
                            </Space>
                        </Radio.Group>
                    </div>

                    <div hidden={!selectedConfig}>
                    <h4>{props.intl.formatMessage({id: 'enable-disable'})}</h4>
                        {
                            selectedConfig?.systemStatusList.map((system, idx) =>
                                <div key={`url-${idx}-for-${selectedConfig.reportName}`}>
                                    <Tooltip title={`(${system.userName}, ${system.secretPlaceholder})`}>
                                        <Checkbox key={`checkbox-${idx}-for-${selectedConfig.reportName}`}
                                                  defaultChecked={system.active}
                                                  onChange={e => onSelectionChecked(e, system)}>
                                            {`${system.url}${system.path}`}
                                        </Checkbox>
                                    </Tooltip>
                                </div>
                            )
                        }

                        <Button
                            type={'primary'}
                            onClick={submitData}
                            style={{marginTop:"20px"}}
                            hidden={!selectedConfig}>{props.intl.formatMessage({id:'survey-main-general-save-button'})}
                        </Button>
                    </div>

                </div>
            </Card>


        </Spin>
    </ConfigProvider>
};

export default ReportingConfiguration;