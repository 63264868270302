import { IAppComponentProps } from "../../../components";
import React from "react";
import { Card, Col, Collapse, FormInstance, Row } from "antd";
import { PortalFormItem } from "../../../components/FormUtils";
import { getI18n } from "../../../utils/Utils";
import { FormattedMessage, useIntl } from "react-intl";
import { CaretRightOutlined } from "@ant-design/icons";
import SupplierHeader from "./SupplierHeader";
import SupplierAddressDetails from "./SupplierAddressDetails";
import SupplierContactPersonDetails from "./SupplierContactPersonDetails";
import SupplierBankingDetails from "./SupplierBankingDetails";
import SupplierComplianceData from "./SupplierComplianceData";
import { useAppDispatch, useAppSelector } from "../../../main/hooks";
import { updateCollapsedState } from "../IStoredDocumentRecordSlice";

type SuppliersDetailsProps = {
  form: FormInstance<any>;
} & IAppComponentProps;

const SupplierDetails: React.FunctionComponent<SuppliersDetailsProps> = (props) => {
  const intl = useIntl();
  const { document } = useAppSelector((state) => state.document);
  const dispatch = useAppDispatch();

  return (
    <>
      <Row key={"supplierDetailsRow"} gutter={24}>
        <Col span={24}>
          <Collapse
            bordered={true}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            expandIconPosition={"left"} className={"ml-10 mt-10"}
            activeKey={document.collapsedState}
            onChange={(newActiveKeys) => {
              // otherwise panels couldn't be open/closed by click
              dispatch(updateCollapsedState(newActiveKeys));
            }}
          >

            <Collapse.Panel header={getI18n("supplierdetails-supplier-panel-header"
                                                      ,"Supplier Details", intl)}
                            key={"supplierDetailsPanelKey"} forceRender={true}>

              <SupplierAddressDetails key={"supplier-1"} currentUser={props.currentUser} intl={intl}
                                      form={props.form} distinctEntitlements={props.distinctEntitlements} />
              <SupplierContactPersonDetails key={"supplier-2"} currentUser={props.currentUser} intl={intl}
                                            distinctEntitlements={props.distinctEntitlements} />
              {/*<SupplierBankingDetails key={"supplier-4"} currentUser={props.currentUser} intl={intl}*/}
              {/*                        distinctEntitlements={props.distinctEntitlements} form={props.form} />*/}
              <SupplierComplianceData key={"supplier-5"} currentUser={props.currentUser} intl={intl}
                                      distinctEntitlements={props.distinctEntitlements} form={props.form} />
            </Collapse.Panel>
          </Collapse>
        </Col>
      </Row>
    </>
  );
};

export default SupplierDetails;