import client from "../../config/GraphQLApolloClient"
import { getUserGuides } from "./__generated__/getUserGuides";
import {downloadUserGuideDocument} from "./__generated__/downloadUserGuides"
import { ALL_USER_GUIDES, DOWNLOAD_USER_GUIDE_DOCUMENT_QUERY_BY_BLOB_ID } from "./queries";

export const getUserGuidesDocs = () => {
    return new Promise<getUserGuides>((resolve, reject) => {
        client.query<getUserGuides>({
            query: ALL_USER_GUIDES,
            fetchPolicy: "cache-first"
        })
            .then((documents) => {
                const response: getUserGuides = { getUserGuidesDocList: documents.data.getUserGuidesDocList };
                resolve(
                    response
                );
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
}

export const downloadUserGuideByBlobId = (blobId) => {
    return new Promise<downloadUserGuideDocument>((resolve, reject) => {
        client.query<downloadUserGuideDocument>({
          query: DOWNLOAD_USER_GUIDE_DOCUMENT_QUERY_BY_BLOB_ID,
          variables: {blobId: blobId !== undefined ? parseFloat(blobId): undefined},
          fetchPolicy: "network-only"
        })
          .then((documents) => {
            const response: downloadUserGuideDocument = { downloadUserGuideDocument: documents.data.downloadUserGuideDocument };
            resolve(
              response
            );
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      });
  }
