import { Input } from "antd";
import client from "../../config/GraphQLApolloClient";
import { MY_DOCS_LIST_DATA, MY_FSM_DOCS_LIST_DATA } from "./queries";
import { getPartnerDocsList, getPartnerDocsListVariables } from "./__generated__/getPartnerDocsList";
import { getFsmPartnerDocsList } from "./__generated__/getFsmPartnerDocsList";
import { IntlShape } from "react-intl/src/types";
import { getI18n } from "../../utils/Utils";


export const getDocumentsData = (username: string, input, intl: IntlShape) => {

  return new Promise<getPartnerDocsList>((resolve, reject) => {
    client.query<getPartnerDocsList, getPartnerDocsListVariables>({
      query: MY_DOCS_LIST_DATA,
      variables: { docListInput: input },

      fetchPolicy: "network-only"
    })
      .then((documents) => {
        const translatedDocumentList = [];

        documents.data?.getPartnerDocsList.forEach(item => {
          translatedDocumentList.push({
            ...item,
            status: getI18n(item.statusPhraseId, item.status, intl),
            documentType: getI18n(item.phraseId, item.documentType, intl)
          });
        });
        const response: getPartnerDocsList = { getPartnerDocsList: translatedDocumentList };
        resolve(
          response
        );
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

export const getFsmDocumentsData = (username: string, input, intl: IntlShape) => {

  return new Promise<getFsmPartnerDocsList>((resolve, reject) => {
    client.query<getFsmPartnerDocsList, getPartnerDocsListVariables>({
      query: MY_FSM_DOCS_LIST_DATA,
      variables: { docListInput: input },

      fetchPolicy: "network-only"
    })
      .then((documents) => {
        const translatedDocumentList = [];

        if (documents.data?.getFsmPartnerDocsList.length === 0) {
        }
        documents.data?.getFsmPartnerDocsList.forEach(item => {
          translatedDocumentList.push({
            ...item,
            status: getI18n(item.statusPhraseId, item.status, intl),
            documentType: getI18n(item.phraseId, item.documentType, intl)
          });
        });
        const response: getFsmPartnerDocsList = { getFsmPartnerDocsList: translatedDocumentList };
        resolve(
          response
        );
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};