import React, { useEffect, useState } from 'react';
import {
    downloadDocumentByBlobIdAndApplicationType,
    downloadRejectionLetterDocumentUsingUrl,
    DropDownSelectionMode,
    ExternalStateUpdateCallback,
    InfoPortalAgGridColumnDef,
    InfoPortalColumnDef,
    InfoPortalGridService,
    InfoPortalReport,
    InfoPortalSearchProperties,
    IValidator,
    LabelLocation,
    OnChangeListener,
    ReportViewConfig,
    utcDateToFormatTo_yyyy_MM_dd_string,
    Validator,
} from '../../../InfoPortalInterfaces';
import { Card, FormInstance, Modal, Spin, Tooltip } from 'antd';
import { CompanySelection, descriptionFilter, PartnerSelection } from '../../InfoPortalDropdowns';
import styles from '../../styling/infoportal.module.css';
import { RuleSet } from '../../../../e-cap/util/RuleResultCache';
import { IntlShape } from 'react-intl/src/types';
import { retrieveSR3LightReport } from '../../../services/ReportSearch';
import { FileOutlined } from '@ant-design/icons';
import { saveAs } from 'save-as';
import { PortalUser } from '../../../../../components';
import {
    dateComparatorUsingUserPreferences,
    getDateFormatOrDefault,
} from '../../../../users/UserFormatPreferenceServices';

import { Buffer } from 'buffer';
import {filterParamWithUserDateFormatComparator} from "../../grid/GridUtil";

function validatator(form: FormInstance<any>): Validator {
    const validator: IValidator = {
        fetchRule(fieldName: string): RuleSet {
            const result = {
                required: false,
                picture: '',
                readOnly: false,
                hidden: false,
                formatMessage: null,
                infoMessage: null,
            };
            if (fieldName === 'company' || fieldName === 'partner') {
                result.required = true;
            }
            return result;
        },
    };
    return new Validator(validator);
}

function RejectionLetterDownload(props: any, distinctEntitlements: string[], intl: IntlShape): JSX.Element {
    const [loading, setLoading] = useState<boolean>(false);

    function getApplication(uri: string) {
        if (uri) {
            const lastForwardSlashIndex = uri.lastIndexOf("/");
            if (lastForwardSlashIndex > 0) {
                const result = uri.substring(lastForwardSlashIndex + 1);
                if (/[\d]+/.test(result)) {
                    return 'ip'
                }
                return result;
            }
        }
        return null;
    }

    function getBlobId(uri: string) {
        if (uri) {
            const lastForwardSlashIndex = uri.lastIndexOf("/");
            if (lastForwardSlashIndex > 0) {
                const downloadIndex = uri.indexOf("download/");
                if (downloadIndex + 9 < lastForwardSlashIndex) {
                    const blobId = uri.substring(downloadIndex + 9, lastForwardSlashIndex);
                    if (/[\d]+/.test(blobId)) {
                        return parseInt(blobId);
                    }
                }
                else {
                    const blobId = uri.substring(lastForwardSlashIndex+1);
                    if (/[\d]+/.test(blobId)) {
                        return parseInt(blobId);
                    }
                }
            }

            return null;
        }
    }

    function downloadBlob(blobId: number, application:string) {
        setLoading(true);
        Promise.resolve(downloadDocumentByBlobIdAndApplicationType(blobId, application))
            .then((data) => {
                const mimeType = data.value.mimeType;
                const decodedData = Buffer.from(data.value.encodedFileData, 'base64');
                const blob = new Blob([decodedData], {type: mimeType});
                saveAs(blob, data.value.fileName);
            }).catch(error => {
            console.error(error);
            Modal.warning({
                title: intl.formatMessage({id: "Download Error",}),
                content: intl.formatMessage({
                    id: "rejection-letter-download-failed",
                })
            });
        }).finally(() => setLoading(false));
    }

    function downloadBlobByUrl(companyCode:string, partnerNumber:string, bmwInvoiceNumber:string, partnerInvoiceNumber:string, url:string) {
        setLoading(true);

        Promise.resolve(downloadRejectionLetterDocumentUsingUrl(companyCode, partnerNumber, bmwInvoiceNumber, partnerInvoiceNumber, url))
            .then((data) => {
                const mimeType = data.value.mimeType;
                const decodedData = Buffer.from(data.value.encodedFileData, 'base64');
                const blob = new Blob([decodedData], {type: mimeType});
                saveAs(blob, data.value.fileName);
            }).catch(error => {
            console.error(error);
            Modal.warning({
                title: intl.formatMessage({id: "Download Error",}),
                content: intl.formatMessage({
                    id: "rejection-letter-download-failed",
                })
            });
        }).finally(() => setLoading(false));
    }

    const blobId = getBlobId(props.data.rejectionletteruri || props.valueFormatted);
    const application = getApplication(props.data.rejectionletteruri || props.valueFormatted);

    if (blobId && application) {
        return <Spin data-testid={loading? `sr3_downloading_${blobId}`:`sr3_finished_downloading_${blobId}`} size={"large"} spinning={loading}>
            <Tooltip
                style={{textTransform:"capitalize"}}
                title={`${intl.formatMessage({id:'welcome-download-button'})}`}>
                <FileOutlined data-testid={`sr3_rejection_letter_${blobId}`} style={{cursor: "pointer"}} onClick={_ => downloadBlob(blobId, application)}/>
            </Tooltip>
        </Spin>
    }

    if (props.data.xSuiteRejectionUrl) {
        return <Spin data-testid={loading? `sr3_downloading_${props.data.bmwdocno}`:`sr3_finished_downloading_${props.data.bmwdocno}`} size={"large"} spinning={loading}>
            <Tooltip
                style={{textTransform:"capitalize"}}
                title={`${intl.formatMessage({id:'welcome-download-button'})}`}>
                <FileOutlined
                    data-testid={`sr3_rejection_letter_${props.data.bmwdocno}`}
                        style={{cursor: "pointer"}} onClick={_ => downloadBlobByUrl(props.data.compcode, props.data.partnerno,props.data.bmwdocno ,props.data.partnerinvno, props.data.xSuiteRejectionUrl)}/>
            </Tooltip>
        </Spin>
    }

    return <></>;
}

function SelfBilledInvoiceSimpleSearch({
                                           currentUser,
                                           intl,
                                           reportEntitlement,
                                           distinctEntitlements,
                                           initialization,
                                           form,
                                           initialValuesCache,
                                           rerenderDependentFields
                                       }: InfoPortalSearchProperties) {
    const [vertical, setVertical] = useState<boolean>(false);

    const validator = validatator(form);
    const partnerSelectionUpdate = new ExternalStateUpdateCallback<{ companyIds: string[], newVal: string[] }>();

    const companySelectionChange: OnChangeListener<string[]> = {
        performAction(companyIds: string[]) {
            const newVal = form.getFieldValue("partner") || [];
            partnerSelectionUpdate.invokeCallBack({companyIds, newVal});
        }
    };

    useEffect(() => {
        if (rerenderDependentFields) {
            const companyIds = form.getFieldValue("company");
            const newVal = form.getFieldValue("partner");
            partnerSelectionUpdate.invokeCallBack({companyIds, newVal});
        }
    });

    return <>
        <Card size={"small"} style={{backgroundColor: "#f1f3f5"}}>
            <div className={styles.selfBilledSimpleSearchContainer}>
                <div className={styles.singleColumnGrid}>
                    <CompanySelection
                        selectionMode={DropDownSelectionMode.MULTIPLE}
                        currentUser={currentUser}
                        initialValue={initialValuesCache?.getValue("company")}
                        intl={intl}
                        validator={validator}
                        displayColon={!vertical}
                        distinctEntitlements={distinctEntitlements}
                        initialization={initialization} form={form}
                        filterOptions={descriptionFilter}
                        onSelectionChange={companySelectionChange}
                        companyEntitlement={reportEntitlement}/>
                    <PartnerSelection
                        selectionMode={DropDownSelectionMode.MULTIPLE}
                        currentUser={currentUser} intl={intl}
                        validator={validator}
                        labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                        displayColon={!vertical}
                        initialValue={initialValuesCache?.getValue("partner")}
                        distinctEntitlements={distinctEntitlements}
                        initialization={initialization} form={form}
                        filterOptions={descriptionFilter}
                        companyIds={initialValuesCache?.getValue("company")}
                        externalStateUpdater={partnerSelectionUpdate}
                        partnerEntitlement={reportEntitlement}/>
                </div>
            </div>
        </Card>
    </>;
}

export class SR3AgGridColumnDef implements InfoPortalAgGridColumnDef {
    getDefaultColumnDefinitions(intl: IntlShape, user:PortalUser,distinctEntitlements: string[]): InfoPortalColumnDef[] {
        return [
            {field: "compcode", headerName: intl.formatMessage({"id": "grid-heading-company"})},
            {field: "partnerno", headerName: intl.formatMessage({"id": "grid-heading-partnerNumber"})},
            {field: "partnerinvno", headerName: intl.formatMessage({"id": "grid-heading-partnerInvoiceNumber"})},
            {field: "bmwdocno", headerName: intl.formatMessage({"id": "grid-heading-bmwInvoiceNumber"})},
            {
                field: "docdate",
                headerName: intl.formatMessage({"id": "grid-heading-documentDate"}),
                valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.docdate,getDateFormatOrDefault(user)),
                filterParams:filterParamWithUserDateFormatComparator(user),
                filter:'agDateColumnFilter',
                comparator: dateComparatorUsingUserPreferences(user)
            },
            {
                field: "rejectiondate",
                headerName: intl.formatMessage({"id": "grid-heading-rejectionDate"}),
                valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.rejectiondate,getDateFormatOrDefault(user)),
                filterParams:filterParamWithUserDateFormatComparator(user),
                filter:'agDateColumnFilter',
                comparator: dateComparatorUsingUserPreferences(user)
            },
            {
                field: "rejectionletteruri",
                headerName: intl.formatMessage({"id": "grid-heading-rejectionLetteravailablefor6months"}),
                cellRenderer: (props: any) => RejectionLetterDownload(props, distinctEntitlements, intl),
                exportFormatter: params => params.data.rejectionLetterFileName || `IP_RL_${params.data.compcode.substring(0,2)}_${params.data.partnerno}_${params.data.bmwdocno || params.data.partnerinvno}.pdf` || ''
            },
        ];
    }

    getShortViewColumns(): string[] {
        return [];
    }

    getLineItemColumnDefinitions(intl: IntlShape): InfoPortalColumnDef[] {
        return []
    }
}

export class SR3LightReport implements InfoPortalReport {
    private _config = new SR3AgGridColumnDef();

    getColumnsDefinition(): InfoPortalAgGridColumnDef {
        return this._config;
    }

    performSearch(form: FormInstance, currentUser: any, distinctEntitlements: string[]): Promise<InfoPortalGridService> {
        return retrieveSR3LightReport(form).then(result =>
            new InfoPortalGridService(
                new ReportViewConfig("SR3Light", this._config), result?.filter(item=>item?.rejectionletteruri), distinctEntitlements));
    }

    hasExpertSearch(): boolean {
        return false;
    }

    renderExpertSearch({
                           currentUser,
                           intl,
                           distinctEntitlements,
                           initialization,
                           form,
                           initialValuesCache,
                           rerenderDependentFields
                       }: InfoPortalSearchProperties): JSX.Element {
        return null;
    }

    renderSimpleSearch({
                           currentUser,
                           intl,
                           distinctEntitlements,
                           initialization,
                           form,
                           initialValuesCache,
                           rerenderDependentFields
                       }: InfoPortalSearchProperties): JSX.Element {
        return <SelfBilledInvoiceSimpleSearch initialization={initialization}
                                              currentUser={currentUser}
                                              intl={intl}
                                              form={form}
                                              distinctEntitlements={distinctEntitlements}
                                              rerenderDependentFields={rerenderDependentFields}
                                              initialValuesCache={initialValuesCache}
                                              reportEntitlement={this.getEntitlement()}/>;
    }

    getEntitlement(): string {
        return "display_sr@reports";
    }

    getReportName(): string {
        return "SR3";
    }
}