import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { Card, Form, FormInstance, Input, InputNumber, Select, Tooltip} from "antd";
import styles from "../styling/ecap.module.css";

import {v4 as uuidv4} from 'uuid';

import {CopyOutlined, DeleteOutlined, InfoCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {UnitOfMeasure} from "../services/model/UnitOfMeasure";
import {
    getCountryListData,
    getCurrencyListData,
    getExemptionTypes,
    getTaxLawReferences,
    getUnitOfMeasureTypes
} from "../services/SelectServices";
import {getCurrencyList_getCurrencyList} from "../__generated__/getCurrencyList";
import {TaxLawReference} from "../services/model/TaxLawReference";
import {getCountryList_getCountryList} from "../__generated__/getCountryList";
import {IAppComponentProps, PortalUser} from "../../../components";

import { useIntl } from "react-intl";
import {getI18n, readNestedFormValue, roundNumber} from "../../../utils/Utils";
import { useAppDispatch, useAppSelector } from "../../../main/hooks";
import {Form_Item} from "../componets/FormItem-component";
import {ExemptionType} from "../services/model/ExemptionType";
import {RuleDefinitions, RuleResultCache, RuleSet} from "../util/RuleResultCache";
import {IStoredDocumentRecord, IStoreLineItem, MODE} from "../IStoredDocumentRecord";
import {updateLineItem, updateLineItems} from "../IStoredDocumentRecordSlice";
import {AppDispatch} from "../../../store/store";
import {FieldData} from "rc-field-form/es/interface";
import {
    antDInputNumberFormatter,
    antDInputParser,
    convertToUserSpecifiedNumericFormat, FormatterWrapper
} from "../../users/UserFormatPreferenceServices";

export const PAGE_SIZE = 10;

interface RecordAppender {
    appendRecord(index: number, currentRecord: LineItemRecord, newRecord: LineItemRecord);
}

interface RecordDeleter {
    deleteRecord(index: number);
}

type LineItemProperties = {
    index: number,
    size: number,
    recordAppender: RecordAppender,
    recordDeleter: RecordDeleter,
    lineItemRecords: LineItemRecord[],
    unitOfMeasures: UnitOfMeasure[],
    taxlawReferences: TaxLawReference[],
    currencies: getCurrencyList_getCurrencyList[],
    currentUser:PortalUser,
    countries: getCountryList_getCountryList[],
    exemptions:ExemptionType[],
    onItemChange?: ()=>any,
    rules: RuleDefinitions,
    form: FormInstance<any>,
    document:IStoredDocumentRecord,
    dispatch:  AppDispatch
};

type LineItemsProperties = {
    form: FormInstance<any>,
    onItemChange?: ()=>any,
    refreshCallback?: (arg:()=>void)=>void,
    validationCallback?: (arg:(document:any)=>boolean)=>void,
    state:LineItemsState} & IAppComponentProps;

export class LineItemRecord {
    key: string;
    precision:number = 3;
    orderNumber: string;
    itemDescription: string;
    bmwPartNumber: string;
    deliveryOrServiceNumber: string;
    deliveryDate: string;
    quantityUnit: string;
    unitOfMeasureFreeText: string;
    quantity: number = 0;
    unitPrice: number = 0;
    unitPriceQuantity:number = 1;
    taxRate: number = 0;
    taxLawReference: string;
    exceptionType: string;
    exceptionReason: string;
    toolLocation: string;
    supplyingCountry: string;
    inventoryNumber: string;
    srmRefNumber: string;

    constructor() {
        this.createNewKey();
    }

    createNewKey() {
        this.key = uuidv4();
    }

    netAmount(): number {
        return parseFloat(roundNumber(this.quantity * this.unitPrice/this.unitPriceQuantity, this.precision));
    }

    taxAmount(): number {
        return parseFloat(roundNumber((this.taxRate / 100) * this.netAmount(), this.precision));
    }

    taxableAmount(): number {
        return this.netAmount();
    }

    grossAmount(): number {
        return this.taxAmount() + this.netAmount();
    }

    taxLawReferenceId():number {
        if (this.taxLawReference) {
            const lawRef = this.taxLawReference.split('_');
            if (lawRef.length===2) {
                return parseInt(lawRef[0])
            }
        }
        return null;
    }

    exemptionTypeid():number {
        if (this.exceptionType) {
            const exemptionType = this.exceptionType.split('_');
            if (exemptionType.length===2) {
                return parseInt(exemptionType[0])
            }
        }
        return null;
    }
    unitOfMeasureId():number {
        if (this.quantityUnit) {
            const uom = this.quantityUnit.split('_');
            if (uom.length===2) {
                return parseInt(uom[0])
            }
        }
        return null;
    }

    supplyingCountryId():string {
        if (this.supplyingCountry) {
            const supplyingCountry = this.supplyingCountry.split('_');
            if (supplyingCountry.length===2) {
                return supplyingCountry[0];
            }
        }

        return null;
    }
}

export class LineItemsState {
    lineItems: LineItemRecord[];
    pageNumber:number;
    constructor() {
        this.lineItems = [new LineItemRecord()];
        this.pageNumber = 1;
    }
}

function range(size: number, startAt = 0) {
    // @ts-ignore
    return [...Array(size).keys()].map(i => i + startAt);
}

export function mapLineItemPropertyToFieldName(index: number, property: string): string {
    return `line_item_${property}_${index%PAGE_SIZE}`;
}

function mapLineItemPropertyValuesToFormValues(index: number, lineItemRecord: LineItemRecord): any {
    const formValues = {};
    for (const lineItemRecordKey in lineItemRecord) {
        if (lineItemRecordKey !== 'key') {
            formValues[mapLineItemPropertyToFieldName(index, lineItemRecordKey)] = lineItemRecord[lineItemRecordKey];
        }
    }

    return formValues;
}

function getSelectedCurrency(currencies: getCurrencyList_getCurrencyList[], form: FormInstance<any>) {
    if (currencies) {
        const result = currencies.filter(currency => currency.id === form.getFieldValue('headerCurrency'));
        return result && result.length > 0 ? result[0] : null;
    }

    return null;
}

function getCurrencyMaxDecimals(currencies: getCurrencyList_getCurrencyList[], form: FormInstance<any>) {
    const selectedCurrency = getSelectedCurrency(currencies, form);
    let currMaxDecimals = 2;
    if (selectedCurrency) {
        if (selectedCurrency.decimalPlaces !== undefined) {
            currMaxDecimals = selectedCurrency.decimalPlaces;
        }
    }
    return currMaxDecimals;
}

const LineItem: FunctionComponent<LineItemProperties> = (props) => {
    const {Option} = Select;
    const intl = useIntl();

    const [lineItemRecord, _] = useState<LineItemRecord>(Object.assign(props.lineItemRecords[props.index]));
    const [flag,setFlag] = useState<boolean>(false);

    const unitPriceNumericFormatter = useRef<FormatterWrapper>(new FormatterWrapper());
    const rerender = ()=> setFlag(val=>!val);

    const updateState = ()=> {
        const record:IStoreLineItem = {...lineItemRecord};
        const recordUpdate = {index:props.index,istoreLineItem:record};
        props.dispatch(updateLineItem(recordUpdate))
    };

    const genericHandleChange = (propertyName, event) => {
        lineItemRecord[propertyName] = event.target.value;
        updateState();
    };

    const getUnitMaxDecimals = () => {
        const uom = lineItemRecord.unitOfMeasureId();
        const result = props.unitOfMeasures.filter(item => item.id === uom);
        const numberOfDecimals = result && result.length===1 && result[0].allowDecimal ? result[0].decimalPlaces : 3;
        return numberOfDecimals;
    };

    const numericHandleChange = (propertyName, value: number, precision: number) => {
        if ((value && value.toFixed) || value === 0) {
            lineItemRecord[propertyName] = precision === 0 ? Math.round(value) : parseFloat(value.toFixed(precision));
        }

        rerender();
        updateState();

        if (props.onItemChange) {
            props.onItemChange();
        }
    };

    const selectSupplierCountry = e => {
        lineItemRecord.supplyingCountry = e;
        rerender();
        updateState();
    }

    const fetchTaxLawReferences = ()=> {
        if (lineItemRecord.taxRate === 0) {
            return props.taxlawReferences.filter(item=>item.code !== 'S');
        }

        return props.taxlawReferences.filter(item=>item.code === 'S');
    };

    const selectTaxLawReference = e => {
        lineItemRecord.taxLawReference = e;

        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const exemptionTypes = fetchExemptionTypes();
        lineItemRecord.exceptionType = null;
        lineItemRecord.exceptionReason = null;
        const formValUpdate = {};
        formValUpdate[mapLineItemPropertyToFieldName(props.index, 'exceptionType')] = null;
        formValUpdate[mapLineItemPropertyToFieldName(props.index, 'exceptionReason')] = null;
        formValUpdate[`exemption_type_number_${props.index % PAGE_SIZE}`] = exemptionTypes.length;
        props.form.setFieldsValue(formValUpdate);

        rerender();
        updateState();
        if (props.onItemChange) {
            props.onItemChange();
        }
    }

    const fetchExemptionTypes = ()=> {
        const selectedTaxRef = (lineItemRecord.taxLawReference || '').split('_');

        let exemptionTypes = []

        if (selectedTaxRef.length===2) {
            // @ts-ignore
            // eslint-disable-next-line eqeqeq
            exemptionTypes = props.exemptions.filter(item=>item.taxLawReferenceId == selectedTaxRef[0]);
        }

        return exemptionTypes;
    };

    const fetchTaxReferenceValue = (id:number)=> {
        const matches = props.taxlawReferences?.filter(item=>item.id===id);
        if (matches && matches.length===1) {
            const reference = matches[0];
          return `${reference.id}_${getI18n(reference.phraseId, reference.description, intl)}`;
        }
        return null;
    }

    const selectExemptionType = e => {
        lineItemRecord.exceptionType = e;
        updateState();
        rerender();
    }

    function performInsert() {
        if (props.document.documentMode === MODE.VIEW) {
            return ()=> console.log('Cannot perform operation, in view mode');
        }

        return () => {
            //check if view only somehow
            props.recordAppender.appendRecord(props.index, lineItemRecord, new LineItemRecord());
        }
    }

    function performDelete() {
        if (props.document.documentMode === MODE.VIEW) {
            return ()=> console.log('Cannot perform operation, in view mode');
        }

        return () => props.recordDeleter.deleteRecord(props.index);
    }

    function performCopy() {
        if (props.document.documentMode === MODE.VIEW) {
            return ()=> console.log('Cannot perform operation, in view mode');
        }

        return () => props.recordAppender.appendRecord(props.index, lineItemRecord, Object.assign(new LineItemRecord(), {
            ...lineItemRecord,
            key: uuidv4()
        }));
    }

    const watchTaxRate = Form.useWatch(mapLineItemPropertyToFieldName(props.index, 'taxRate'), props.form);

    const ruleResultCache = new RuleResultCache(
        props.rules,
        props.form,
        props.document,
        ['LineItems_Zero_Tax_Rate_Mandatory','Part_Number_Format','Line_Item_Tools_Check','Line_Item_Tools_Location','Delivery_Service_Number_Line_Item','Order_Number_Line_Item' ,'Always_Mandatory','Exemption_Reason','Exemption_Type','Order_Number_Format','Delivery_Service_Number_Format','Item_Description'],
        intl,
        props.index);

    useEffect(()=>{
        if (watchTaxRate!== undefined && watchTaxRate!== null) {
            console.debug(`The tax rate has changed and the value is (${watchTaxRate})`);
            const currentTaxLawReferenceValue = props.form.getFieldValue(mapLineItemPropertyToFieldName(props.index, 'taxLawReference'));
            const exceptionReason = props.form.getFieldValue(mapLineItemPropertyToFieldName(props.index, 'exceptionReason'));
            console.debug(`Current tax law reference: ${currentTaxLawReferenceValue}`)
            console.debug(`Exception reason: ${exceptionReason}`)
            // eslint-disable-next-line eqeqeq
            if (watchTaxRate == 0) {
                const resetReference = fetchTaxReferenceValue(7);
                const fieldData:FieldData[] = [];
                if (lineItemRecord.taxLawReference === resetReference || !currentTaxLawReferenceValue) {
                    const exceptionReasonResult = ruleResultCache.fetchRule('Exemption_Reason');
                    lineItemRecord.taxLawReference = null;

                    fieldData.push({
                        name:mapLineItemPropertyToFieldName(props.index, 'taxLawReference'),
                        value:null,
                        errors:!exceptionReasonResult.required?[]:[intl.formatMessage({id:'generic-requiredField-message'})]
                    });
                }
                if (!exceptionReason) {
                    const exceptionReasonResult = ruleResultCache.fetchRule('Exemption_Reason');
                    fieldData.push({
                        name:mapLineItemPropertyToFieldName(props.index, 'exceptionReason'),
                        value:null,
                        errors:!exceptionReasonResult.required?[]:[intl.formatMessage({id:'generic-requiredField-message'})]
                    });
                }
                if (fieldData.length>0) {
                    props.form.setFields(fieldData);
                }
            }
        }
    },[watchTaxRate])

    useEffect(()=> {
        lineItemRecord.precision = getCurrencyMaxDecimals(props.currencies, props.form);
    });

    useEffect(()=> {
        console.log(`Rendering line item ${props.index}`);
    },[]);

    return ruleResultCache.render(propertyCache=> {
        return (
            <div
                className={`${styles.lineItemsContainer} ${props.index === (props.size - 1) ? styles.lineItemLastRowBorderBottom : 'no-bottom-border'}`}>
                <div className={styles.lineColumn1}>
                    <Tooltip title={intl.formatMessage({'id':'insert','defaultMessage':"Insert"})}>
                        <PlusCircleOutlined style={{marginLeft: "5px", cursor: "pointer"}}
                                            onClick={performInsert()}/>
                    </Tooltip>
                    <Tooltip title={intl.formatMessage({'id':'delete','defaultMessage':"Delete"})}>
                        <DeleteOutlined style={{marginLeft: "5px", cursor: "pointer"}}
                                        onClick={performDelete()}/>
                    </Tooltip>
                    <Tooltip title={intl.formatMessage({'id':'copy','defaultMessage':"Copy"})}>
                        <CopyOutlined
                            style={{marginLeft: "5px", cursor: "pointer"}}
                            onClick={performCopy()}/>
                    </Tooltip>
                </div>
                <div className={styles.lineColumn2_display}>
                    <p className={styles.lineColumn2_display}>{props.index + 1}</p>
                    </div>
                <div className={styles.lineColumn3} hidden={propertyCache.isHidden('Order_Number_Line_Item')}>
                    <Form_Item
                        name={mapLineItemPropertyToFieldName(props.index, 'orderNumber')}
                        formatMessage={propertyCache.formatMessage('Order_Number_Format')}
                        itemRules={propertyCache.fetchRule('Order_Number_Line_Item','Order_Number_Format')}
                        label={''}>
                        <Input
                            placeholder=""
                            disabled={propertyCache.isReadOnly('Order_Number_Line_Item') || props.document?.documentMode === MODE.VIEW}
                            allowClear={false}
                            defaultValue={lineItemRecord.orderNumber}
                            onChange={event => genericHandleChange('orderNumber', event)}/>
                    </Form_Item>
                </div>
                <div className={styles.lineColumn4}>
                    <Form_Item
                        name={mapLineItemPropertyToFieldName(props.index, 'itemDescription')}
                        label={''} itemRules={propertyCache.fetchRule('Item_Description')}>
                        <Tooltip title={lineItemRecord.itemDescription} mouseEnterDelay={2}>
                            <Input
                                placeholder=""
                                allowClear={false}
                                defaultValue={lineItemRecord.itemDescription}
                                onChange={event => genericHandleChange('itemDescription', event)}/>
                        </Tooltip>
                    </Form_Item>
                </div>
                <div className={styles.lineColumn5}>
                    <Form_Item name={mapLineItemPropertyToFieldName(props.index, 'bmwPartNumber')}
                               formatMessage={propertyCache.formatMessage('Part_Number_Format')}
                               label={''}  itemRules={propertyCache.fetchRule('Always_Mandatory','Part_Number_Format')}>
                        <Input
                            placeholder=""
                            allowClear={false}
                            defaultValue={lineItemRecord.bmwPartNumber}
                            onChange={event => genericHandleChange('bmwPartNumber', event)}/>
                    </Form_Item>
                </div>
                <div className={styles.lineColumn6} hidden={propertyCache.isHidden('Delivery_Service_Number_Line_Item')}>
                    <Form_Item
                        name={mapLineItemPropertyToFieldName(props.index, 'deliveryOrServiceNumber')}
                        formatMessage={propertyCache.formatMessage('Delivery_Service_Number_Format')}
                        itemRules={propertyCache.fetchRule('Delivery_Service_Number_Line_Item','Delivery_Service_Number_Format')}
                        label={''}>
                        <Input
                            placeholder=""
                            disabled={propertyCache.isReadOnly('Delivery_Service_Number_Line_Item') || props.document?.documentMode === MODE.VIEW}
                            allowClear={false}
                            defaultValue={lineItemRecord.deliveryOrServiceNumber}
                            onChange={event => genericHandleChange('deliveryOrServiceNumber', event)}/>
                    </Form_Item>
                </div>
                <div className={`${styles.lineColumn7}`}>
                    <Form_Item name={mapLineItemPropertyToFieldName(props.index, 'unitOfMeasureFreeText')}
                               label={''}  itemRules={propertyCache.fetchRule('Always_Mandatory')}>
                        <Input
                            placeholder=""
                            maxLength={3}
                            allowClear={false}
                            defaultValue={lineItemRecord.unitOfMeasureFreeText}
                            onChange={event => genericHandleChange('unitOfMeasureFreeText', event)}/>
                    </Form_Item>
                </div>
                <div className={styles.lineColumn8}>
                    <Form_Item name={mapLineItemPropertyToFieldName(props.index, 'quantity')}
                               label={''} itemRules={propertyCache.fetchRule('Always_Mandatory')}>
                        <InputNumber
                            size="small"
                            placeholder=""
                            min={0}
                            controls={false}
                            formatter={(value,info)=>antDInputNumberFormatter(props.currentUser, value,  info, 3)}
                            parser={(value)=> antDInputParser(props.currentUser,value)}
                            precision={3}
                            defaultValue={lineItemRecord.quantity}
                            onChange={newVal => numericHandleChange('quantity', newVal, getUnitMaxDecimals())}/>
                    </Form_Item>
                </div>
                <div className={styles.lineColumn9}>
                    <Form_Item name={mapLineItemPropertyToFieldName(props.index, 'unitPrice')}
                               label={''} itemRules={propertyCache.fetchRule('Always_Mandatory')}>
                        <InputNumber
                            placeholder=""
                            precision={getCurrencyMaxDecimals(props.currencies, props.form)}
                            formatter={(value,info)=>unitPriceNumericFormatter.current.antDInputNumberFormatter(props.currentUser, value,  info, getCurrencyMaxDecimals(props.currencies, props.form))}
                            parser={(value)=> unitPriceNumericFormatter.current.antDInputParser(props.currentUser,value)}
                            controls={false}
                            defaultValue={lineItemRecord.unitPrice}
                            onChange={newVal => numericHandleChange('unitPrice', newVal, getCurrencyMaxDecimals(props.currencies, props.form))}/>
                    </Form_Item>
                </div>
                <div className={styles.lineColumn11}>
                    <Form.Item>
                        <Input
                            className={'disabledNumeric'}
                            placeholder=""
                            disabled={true}
                            value={convertToUserSpecifiedNumericFormat(
                                props.currentUser,
                                lineItemRecord.netAmount(),
                                getCurrencyMaxDecimals(props.currencies, props.form))}/>
                    </Form.Item>
                </div>
                <div className={styles.lineColumn12}>
                    <Form_Item name={mapLineItemPropertyToFieldName(props.index, 'taxRate')}
                               label={''} itemRules={propertyCache.fetchRule('Always_Mandatory')}>
                        <InputNumber
                            placeholder=""
                            min={0}
                            formatter={(value,info)=>antDInputNumberFormatter(props.currentUser, value,  info)}
                            parser={(value)=> antDInputParser(props.currentUser,value)}
                            precision={2}
                            max={100}
                            controls={false}
                            defaultValue={lineItemRecord.taxRate}
                            onChange={newVal => {
                                if (newVal && newVal!==0) {
                                    const resetReference = fetchTaxReferenceValue(7);
                                    lineItemRecord.taxLawReference = resetReference;
                                    lineItemRecord.exceptionType = null;
                                    lineItemRecord.exceptionReason = null;

                                    props.form.setFields([
                                        {
                                            name: mapLineItemPropertyToFieldName(props.index, 'taxLawReference'),
                                            value:resetReference,
                                            errors:[]
                                        } ,
                                        {
                                            name: mapLineItemPropertyToFieldName(props.index, 'exceptionType'),
                                            value:null,
                                            errors:[]
                                        }
                                        ,
                                        {
                                            name: mapLineItemPropertyToFieldName(props.index, 'exceptionReason'),
                                            value:null,
                                            errors:[]
                                        }
                                    ]);
                                }

                                numericHandleChange('taxRate', newVal, 2);

                            }}/>
                    </Form_Item>
                </div>
                <div className={styles.lineColumn13}>
                    <Form.Item>
                        <Input
                            className={'disabledNumeric'}
                            placeholder=""
                            disabled={true}
                            value={
                                convertToUserSpecifiedNumericFormat(
                                    props.currentUser,
                                    lineItemRecord.taxAmount(),
                                    getCurrencyMaxDecimals(props.currencies, props.form))}/>
                    </Form.Item>
                </div>
                <div className={styles.lineColumn14}>
                    <Form.Item>
                        <Input
                            className={'disabledNumeric'}
                            placeholder=""
                            disabled={true}
                            value={
                                convertToUserSpecifiedNumericFormat(props.currentUser,
                                    lineItemRecord.grossAmount(),
                                    getCurrencyMaxDecimals(props.currencies, props.form))}/>
                    </Form.Item>
                </div>
                <div className={styles.lineColumn15}>
                    <Form_Item name={mapLineItemPropertyToFieldName(props.index, 'taxLawReference')}
                               label={''}
                               itemRules={propertyCache.fetchRule('Exemption_Reason')}>
                        <Select
                            size="small"
                            loading={!(props.taxlawReferences && props.taxlawReferences.length > 0)}
                            showSearch={true}
                            disabled={propertyCache.isReadOnly('LineItems_Zero_Tax_Rate_Mandatory') || props.document?.documentMode === MODE.VIEW}
                            placeholder={intl.formatMessage({'id':'select-option','defaultMessage':'Select'})}
                            defaultValue={lineItemRecord.taxLawReference}
                            onSelect={selectTaxLawReference}>
                            {
                                props.taxlawReferences &&
                                fetchTaxLawReferences().map((taxLawReference, index) => (
                                  <Option className={styles.currencySelect}
                                          key={`${props.index}_${index}_tax_law_reference`}
                                          value={`${taxLawReference.id}_${getI18n(taxLawReference.phraseId, taxLawReference.description, intl)}`}>
                                    {getI18n(taxLawReference.phraseId, taxLawReference.description, intl)}
                                  </Option>
                                ))
                            }
                        </Select>
                    </Form_Item>
                </div>
                <div className={styles.lineColumn16}>
                    <Form.Item name={`exemption_type_number_${props.index % PAGE_SIZE}`} hidden={true}>
                        <Input />
                    </Form.Item>
                    <Form_Item name={mapLineItemPropertyToFieldName(props.index, 'exceptionType')}
                               label={''}
                               itemRules={propertyCache.fetchRule('Exemption_Type')}>
                        <Select
                            size="small"
                            loading={!(props.exemptions && props.exemptions.length > 0)}
                            showSearch={true}
                            disabled={propertyCache.isReadOnly('Exemption_Type') || props.document?.documentMode === MODE.VIEW}
                            placeholder={intl.formatMessage({'id':'select-option','defaultMessage':'Select'})}
                            defaultValue={lineItemRecord.exceptionType}
                            onSelect={selectExemptionType}>
                            {
                                props.exemptions &&
                                fetchExemptionTypes().map((exemptionType, index) => (
                                    <Option className={styles.currencySelect}
                                            key={`${props.index}_${index}_exemption_types`}
                                            value={`${exemptionType.id}_${exemptionType.description}`}>
                                      {getI18n(exemptionType.phraseId, exemptionType.description, intl)}
                                    </Option>
                                ))
                            }
                        </Select>
                    </Form_Item>
                </div>
                <div className={styles.lineColumn17}>
                    <Form_Item name={mapLineItemPropertyToFieldName(props.index, 'exceptionReason')}
                               label={''}
                               itemRules={propertyCache.fetchRule('Exemption_Reason')}>
                        <Input
                            placeholder=""
                            allowClear={false}
                            disabled={propertyCache.isReadOnly('Exemption_Reason') || props.document?.documentMode === MODE.VIEW}
                            defaultValue={lineItemRecord.exceptionReason}
                            onChange={event => genericHandleChange('exceptionReason', event)}/>
                    </Form_Item>
                </div>

                <div className={styles.lineColumn18}>
                    <Form_Item name={mapLineItemPropertyToFieldName(props.index, 'toolLocation')}
                               label={''}
                               itemRules={propertyCache.fetchRule('Line_Item_Tools_Location')}>
                        <Input
                            placeholder=""
                            allowClear={false}
                            defaultValue={lineItemRecord.toolLocation}
                            onChange={event => genericHandleChange('toolLocation', event)}/>
                    </Form_Item>
                </div>

                <div className={styles.lineColumn19}>

                    <Form_Item name={mapLineItemPropertyToFieldName(props.index, 'supplyingCountry')}
                               label={''}
                               itemRules={propertyCache.fetchRule('Line_Item_Tools_Check')}>
                        <Select
                            size="small"
                            loading={!(props.countries && props.countries.length > 0)}
                            showSearch={true}
                            placeholder={intl.formatMessage({'id':'select-option','defaultMessage':'Select'})}
                            defaultValue={lineItemRecord.supplyingCountry}
                            onSelect={selectSupplierCountry}>
                            {props.countries &&
                                props.countries.map((country, index) => (
                                    <Option className={styles.currencySelect}
                                            key={`${props.index}_${index}_${country.id}`}
                                            value={`${country.id}_${country.country}`}>
                                      {getI18n(country.phraseId, country.country, intl)}
                                    </Option>
                                ))
                            }
                        </Select>
                    </Form_Item>
                </div>
                <div className={styles.lineColumn20}>
                    <Form_Item name={mapLineItemPropertyToFieldName(props.index, 'inventoryNumber')}
                               label={''}
                               itemRules={propertyCache.fetchRule('Line_Item_Tools_Check')}>
                        <Input
                            placeholder=""
                            allowClear={false}
                            defaultValue={lineItemRecord.inventoryNumber}
                            onChange={event => genericHandleChange('inventoryNumber', event)}/>
                    </Form_Item>
                </div>
                <div className={styles.lineColumn21}>
                    <Form_Item name={mapLineItemPropertyToFieldName(props.index, 'srmRefNumber')}
                               label={''}
                               itemRules={propertyCache.fetchRule('Line_Item_Tools_Check')}>
                        <Input
                            placeholder=""
                            allowClear={false}
                            defaultValue={lineItemRecord.srmRefNumber}
                            onChange={event => genericHandleChange('srmRefNumber', event)}/>
                    </Form_Item>
                </div>

            </div>)
    });
};

const LineItems: FunctionComponent<LineItemsProperties> = (props) => {

    const [state,_] = useState<LineItemsState>(props.state);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [measurementUnits, setMeasurementUnits] = useState<UnitOfMeasure[]>([]);
    const [taxLawReferences, setTaxLawReferences] = useState<TaxLawReference[]>([]);
    const [exemptionTypes, setExemptionTypes] = useState<ExemptionType[]>([]);
    const [allCurrencies, setCurrencies] = useState<getCurrencyList_getCurrencyList[]>(null);
    const [allCountries, setAllCountries] = useState<getCountryList_getCountryList[]>(null);
    const [flag,setFlag] = useState<boolean>(false);

    const validationErrors = useRef<FieldData[]>(null);
    const forceRefresh = ()=> setFlag(current=>!current);

    const dispatch = useAppDispatch();
    const { document } = useAppSelector((state) => state.document);
    const rules  = useAppSelector((state) => state.rules);

    const extractAndFormatDate = (dateField: string, dateFormat?: string) => {
        const nestedDate = readNestedFormValue(props.form, dateField);
        if (nestedDate) {
            if (dateFormat) {
                return nestedDate.format(dateFormat);
            }

            return `${nestedDate}`;
        }

        return null;
    };

    const overrideOrderNumberAndDeliveryNum = (cachedRule?:RuleResultCache)=> {

        const ruleCache = cachedRule ||
            new RuleResultCache(rules,props.form,document,['Delivery_Service_Number_Line_Item','Order_Number_Line_Item' ], props.intl);

        const orderNumber = readNestedFormValue(props.form,"document.orderNumber") || '';
        const deliveryNumber = readNestedFormValue(props.form,"document.deliveryNumber") || '';
        const deliveryDate = extractAndFormatDate("document.deliveryDate", "YYYY-MM-DD");

        const startIndex = (state.pageNumber-1) * PAGE_SIZE;
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const endIndex = startIndex + rangeSize();

        let fieldsToUpdate = {};

        state.lineItems?.forEach((lineItem,index)=> {
            const updatedItem = index>=startIndex && index<endIndex;
            if (ruleCache.isReadOnly('Order_Number_Line_Item')) {
                lineItem.orderNumber = orderNumber;
            }

            if (ruleCache.isReadOnly('Delivery_Service_Number_Line_Item')) {
                lineItem.deliveryOrServiceNumber = deliveryNumber;
            }
            if (ruleCache.isReadOnly('Delivery_Service_Number_Line_Item')) {
                lineItem.deliveryDate = deliveryDate;
            }
            if (updatedItem) {
                const ithFormValues = mapLineItemPropertyValuesToFormValues(index, lineItem);
                fieldsToUpdate = {...fieldsToUpdate,...ithFormValues};
            }
        });

        return fieldsToUpdate;
    };

    const updateFormItemOrderNumberAndDeliveryNum = (formValues:any)=> {


        const formKeys = Object.keys(formValues);

        if (formKeys.length) {
            props.form.setFieldsValue(formValues);
        }

        if (validationErrors.current) {
            console.log('Setting validation errors', new Date());
            props.form.setFields(validationErrors.current);
            validationErrors.current = null;
        }
        else {
            const ruleCache =
                new RuleResultCache(rules,props.form,document,['Exemption_Reason' ], props.intl);

            const startIndex = (state.pageNumber-1) * PAGE_SIZE;
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            const endIndex = startIndex + rangeSize();

            const exceptionReasonResult = ruleCache.fetchRule('Exemption_Reason');

            for (let i=startIndex;i<endIndex;i++) {
                const lineItemRecord = state.lineItems[i];
                if (lineItemRecord.taxRate === 0) {
                    const fieldData:FieldData[] = [];
                    if (!lineItemRecord.taxLawReference) {
                        lineItemRecord.taxLawReference = null;

                        fieldData.push({
                            name:`line_item_taxLawReference_${i % PAGE_SIZE}`,
                            value:null,
                            errors:!exceptionReasonResult.required?[]:[props.intl.formatMessage({id:'generic-requiredField-message'})]
                        });
                    }
                    if (!lineItemRecord.exceptionReason) {
                        fieldData.push({
                            name:`line_item_exceptionReason_${i % PAGE_SIZE}`,
                            value:null,
                            errors:!exceptionReasonResult.required?[]:[props.intl.formatMessage({id:'generic-requiredField-message'})]
                        });
                    }
                    if (fieldData.length>0) {
                        props.form.setFields(fieldData);
                    }
                }
            }
        }
    };

    const syncFormItemAndState = ()=> {
        const fieldsToUpdate = overrideOrderNumberAndDeliveryNum();
        updateFormItemOrderNumberAndDeliveryNum(fieldsToUpdate);
    };

    const updateRedux = () => {
        try {
            const iStoreLineItems = state.lineItems.map(item=> {
                const dto:IStoreLineItem = {...item};
                return dto;
            });
            dispatch(updateLineItems(iStoreLineItems));
        }
        catch(error) {
            console.log(error);
        }
    };

    function validateLineItems(theDocument: any) {
        const fieldValidationKeys = {
            orderNumber: ['Order_Number_Format'],
            itemDescription: ['Item_Description'],
            bmwPartNumber: ['Always_Mandatory', 'Part_Number_Format'],
            deliveryOrServiceNumber: ['Delivery_Service_Number_Line_Item', 'Delivery_Service_Number_Format'],
            unitOfMeasureFreeText: ['Always_Mandatory'],
            quantity: ['Always_Mandatory'],
            unitPrice: ['Always_Mandatory'],
            taxRate: ['Always_Mandatory'],
            taxLawReference: ['Exemption_Reason'],
            exceptionType: ['Exemption_Type'],
            exceptionReason: ['Exemption_Reason'],
            toolLocation: ['Line_Item_Tools_Location'],
            supplyingCountry: ['Line_Item_Tools_Check'],
            inventoryNumber: ['Line_Item_Tools_Check'],
            srmRefNumber: ['Line_Item_Tools_Check']
        }

        function getRuleDefinition (ruleResult:RuleResultCache, fields:string[]) {
            if (fields.length === 2) {
                return ruleResult.fetchRule(fields[0],fields[1]);
            }

            return ruleResult.fetchRule(fields[0]);
        }

        function getErrors(ruleSet:RuleSet, key:string, value:any) {
            const errors:string[] = [];

            const pictureList: RegExp[] = [];
            const formatMessage:string[] = [];

            if (ruleSet?.picture) {
                if (Array.isArray(ruleSet.picture)) {
                    let messageArray = [];
                    if (Array.isArray( ruleSet.formatMessage)) {
                        messageArray = ruleSet.formatMessage;
                    }

                    ruleSet.picture.forEach((item,idx) => {
                        pictureList.push(new RegExp(item));
                        if (idx<messageArray.length) {
                            formatMessage.push(messageArray[idx]);
                        }
                        else {
                            formatMessage.push(props.intl.formatMessage({
                                id: 'general-format-field-error',
                                defaultMessage: `The field do not match the required format`,
                            }))
                        }
                    });
                }
                else {
                    pictureList.push(new RegExp(ruleSet.picture));
                    if (ruleSet.formatMessage && !Array.isArray( ruleSet.formatMessage)) {
                        formatMessage.push(ruleSet.formatMessage);
                    }
                    else {
                        formatMessage.push(props.intl.formatMessage({
                            id: 'general-format-field-error',
                            defaultMessage: `The field do not match the required format`,
                        }));
                    }
                }
            }


            const valueCastToString = `${value}`;
            let passedRequired = true;
            if (ruleSet.required) {
                if (value===undefined|| value===null || !valueCastToString) {
                    errors.push(props.intl.formatMessage({id:'generic-requiredField-message'}));
                    passedRequired = false;
                }
            }
            if (passedRequired && valueCastToString && pictureList.length && formatMessage.length) {
                pictureList.forEach((reg,idx)=> {
                    if (!reg.test(valueCastToString)) {
                        errors.push(formatMessage[idx]);
                    }
                });
            }

            return errors;
        }

        const errors:{fieldName:string,error:string[], index:number}[] = [];
        let pageWithError = 0;
        for (let i = 0; i < state.lineItems.length; i++) {
            const itemPage = Math.floor(i/PAGE_SIZE)+1;
            if (pageWithError!==0 && pageWithError!==itemPage) {
                //already found a page, just break....
                break;
            }
            const ruleResult =
                new RuleResultCache(
                    rules,
                    props.form,
                    theDocument,
                    ['LineItems_Zero_Tax_Rate_Mandatory','Part_Number_Format','Line_Item_Tools_Check','Line_Item_Tools_Location','Delivery_Service_Number_Line_Item','Order_Number_Line_Item' ,'Always_Mandatory','Exemption_Reason','Exemption_Type','Order_Number_Format','Delivery_Service_Number_Format','Item_Description'],
                    props.intl,
                    i);

            const lineItem = state.lineItems[i];

            for (const key in fieldValidationKeys) {
                const fieldRules = fieldValidationKeys[key];
                const theValue = lineItem[key];
                const ruleDefinition = getRuleDefinition(ruleResult, fieldRules);
                const validationErrors = getErrors(ruleDefinition, key,theValue);
                if (validationErrors.length>0) {
                    pageWithError = itemPage;
                    errors.push({fieldName:key, error:validationErrors, index:i});
                }
            }
        }

        return {errors,pageWithError}
    }

    useEffect(() => {
        if (!loaded) {
            getCountryListData().then(result => setAllCountries(result.getCountryList))
            getExemptionTypes().then(result => setExemptionTypes(result));
            getUnitOfMeasureTypes().then(result => setMeasurementUnits(result));
            getTaxLawReferences().then(result => setTaxLawReferences(result));
            getCurrencyListData().then(result => setCurrencies(result.getCurrencyList));
            setLoaded(true);
            if (props.refreshCallback) {
                props.refreshCallback(()=> {
                    syncFormItemAndState();
                    updateRedux();
                    forceRefresh();
                });
            }
            if (props.validationCallback) {
                props.validationCallback((document:any)=> {
                    const validationResult = validateLineItems(document);

                    console.log('Errors not in view', validationResult.errors, 'On page', validationResult.pageWithError);

                    if (validationResult.pageWithError!==0 && validationResult.pageWithError !== state.pageNumber) {
                        state.pageNumber = validationResult.pageWithError;
                        const fieldData = [];
                        for (const error of validationResult.errors) {
                            const field = mapLineItemPropertyToFieldName(error.index, error.fieldName);
                            const lineItemValue = state.lineItems[error.index][error.fieldName];
                            fieldData.push({
                                name: field,
                                value: lineItemValue,
                                errors: error.error
                            });
                        }
                        validationErrors.current = fieldData;
                    }

                    return validationResult.pageWithError!==0;
                })
            }
        }
    },[]);

    useEffect(()=> {
        syncFormItemAndState();
    })

    const addRecord: RecordAppender = {
        appendRecord(index: number, currentRecord:LineItemRecord, newRecord: LineItemRecord) {
            const leftArray = state.lineItems.slice(0, index);
            const rightArray =state.lineItems.slice(index + 1);
            const lineItemRecords = [...leftArray, currentRecord, newRecord, ...rightArray];
            state.lineItems = lineItemRecords;

            if (index!==0 && (index+1)%PAGE_SIZE ===0) {
                state.pageNumber++;
            }

            syncFormItemAndState();
            updateRedux();
            forceRefresh();
            if (props.onItemChange) {
                props.onItemChange();
            }
        }
    };

    const deleteRecord: RecordDeleter = {
        deleteRecord(index: number) {
            if (state.lineItems.length > 1) {
                const leftArray = state.lineItems.slice(0, index);
                const rightArray = state.lineItems.slice(index + 1);
                const lineItemRecords = [...leftArray, ...rightArray];
                state.lineItems = lineItemRecords;
            } else {
                const lineItemRecords = [ new LineItemRecord()];
                state.lineItems = lineItemRecords;
            }

            if (state.pageNumber> Math.ceil((state.lineItems.length/PAGE_SIZE))) {
                state.pageNumber--;
            }

            syncFormItemAndState();
            updateRedux();
            forceRefresh();
            if (props.onItemChange) {
                props.onItemChange();
            }
        }
    };

    const reRenderForm = (rules: RuleDefinitions, document:IStoredDocumentRecord, form:FormInstance<any>)=> {
        const ruleIDs = ['Line_Item_Tools_Check','Delivery_Service_Number_Line_Item','Order_Number_Line_Item' ,'Always_Mandatory','Part_Number_Format','Order_Number_Format','Item_Description'];
        const ruleResultCache = new RuleResultCache(rules,form,document,ruleIDs,props.intl);
        return ruleResultCache;
    };

    const rangeSize = ()=> {
        const startIndex = (state.pageNumber-1) * PAGE_SIZE;
        const endIndex = Math.min(state.lineItems.length, (startIndex+PAGE_SIZE));
        const difference = endIndex - startIndex;
        return difference;
    }

    const selectPage = (selectedPage:any) => {
        state.pageNumber = selectedPage.target.value;
        forceRefresh();
    }

    const changePageNumber = (shouldAdd:boolean = true)=> {
        if (shouldAdd) {
            const MAX_PAGE_SIZE = Math.ceil((state.lineItems.length/PAGE_SIZE));
            if (state.pageNumber<MAX_PAGE_SIZE) {
                state.pageNumber++;
                forceRefresh();
            }
        }
        else {
            if (state.pageNumber>1) {
                state.pageNumber--;
                forceRefresh();
            }
        }
    }

    return reRenderForm(rules,document,props.form).render(propertyCache=> {
        return (<>
            <Card size={"small"} className={"ml-15"}>
                <div className={styles.lineItemOverflowContainer}>
                    <div className={styles.lineItemsContainer}>
                        <div  className={styles.lineColumn1}>{props.intl.formatMessage({'id':'line-item-option-title','defaultMessage':'Option'})}</div>
                        <div  className={styles.lineColumn2}>{props.intl.formatMessage({'id':'line-item-line-item-title','defaultMessage':'Line Item'})}</div>
                        <div  className={styles.lineColumn3}>
                            <span  hidden={!propertyCache.isMandatory('Order_Number_Line_Item')} className={styles.mandatoryField}>*</span>
                            {props.intl.formatMessage({'id':'line-item-ord-no-title','defaultMessage':'Order Number'})}
                            {
                                propertyCache.isMandatory('Order_Number_Line_Item') &&
                                (<Tooltip
                                    title={propertyCache.formatInfoMessage('Order_Number_Format')}>
                                    <InfoCircleOutlined style={{marginLeft: "5px",fontStyle:"1.1em"}}/>
                                </Tooltip>)
                            }
                        </div>
                        <div  className={styles.lineColumn4}>
                            <span  hidden={!propertyCache.isMandatory('Item_Description')} className={styles.mandatoryField}>*</span>
                            {props.intl.formatMessage({'id':'line-item-item-desc-title','defaultMessage':'Item Description'})}
                        </div>
                        <div  className={styles.lineColumn5}>
                            <span hidden={!propertyCache.isMandatory('Always_Mandatory')} className={styles.mandatoryField}>*</span>
                            {props.intl.formatMessage({'id':'line-item-part-no-title','defaultMessage':'BMW Part Number'})}

                            <Tooltip title={propertyCache.formatInfoMessage('Part_Number_Format')}>
                                <InfoCircleOutlined style={{marginLeft:"5px", fontStyle:"1.1em"}} />
                            </Tooltip>
                        </div>
                        <div  className={styles.lineColumn6}>
                            <span  hidden={!propertyCache.isMandatory('Delivery_Service_Number_Line_Item')} className={styles.mandatoryField}>*</span>
                            {props.intl.formatMessage({'id':'line-item-del-serv-no-title','defaultMessage':'Delivery or Service Number'})}
                        </div>
                        <div  className={styles.lineColumn7}>
                            <span hidden={!propertyCache.isMandatory('Always_Mandatory')} className={styles.mandatoryField}>*</span>
                            {props.intl.formatMessage({'id':'line-item-uom-title','defaultMessage':'UoM'})}

                            <Tooltip title={props.intl.formatMessage({'id':'line-item-unit-of-measurement-info','defaultMessage':'Please enter the unit of measurement, e.g. unit, liter, kg, etc.'})}>
                                <InfoCircleOutlined style={{marginLeft:"5px", fontStyle:"1.1em"}} />
                            </Tooltip>
                        </div>
                        <div  className={styles.lineColumn8}>
                            <span hidden={!propertyCache.isMandatory('Always_Mandatory')} className={styles.mandatoryField}>*</span>
                            {props.intl.formatMessage({'id':'line-item-quantity-title','defaultMessage':'Quantity'})}
                            <Tooltip title={props.intl.formatMessage({'id':'line-item-quantity-info','defaultMessage':'Quantity of items being billed'})}>
                                <InfoCircleOutlined style={{marginLeft:"5px", fontStyle:"1.1em"}} />
                            </Tooltip>
                        </div>
                        <div  className={styles.lineColumn9}>
                            <span hidden={!propertyCache.isMandatory('Always_Mandatory')} className={styles.mandatoryField}>*</span>
                            {props.intl.formatMessage({'id':'line-item-unit-price-title','defaultMessage':'Unit Price'})}
                            <Tooltip title={props.intl.formatMessage({'id':'line-item-unit-price-info','defaultMessage':'Price exclusive of VAT for a single unit'})}>
                                <InfoCircleOutlined style={{marginLeft:"5px", fontStyle:"1.1em"}} />
                            </Tooltip>
                        </div>
                        <div  className={styles.lineColumn11}>{props.intl.formatMessage({'id':'line-item-net-amount-title','defaultMessage':'Net Amount'})}</div>
                        <div  className={styles.lineColumn12}>
                            <span hidden={!propertyCache.isMandatory('Always_Mandatory')} className={styles.mandatoryField}>*</span>
                            {props.intl.formatMessage({'id':'line-item-tax-rate-title','defaultMessage':'Tax Rate'})}
                        </div>
                        <div  className={styles.lineColumn13}>{props.intl.formatMessage({'id':'line-item-tax-amount-title','defaultMessage':'Tax Amount'})}</div>
                        <div  className={styles.lineColumn14}>{props.intl.formatMessage({'id':'line-item-gross-amount-title','defaultMessage':'Gross Amount'})}</div>
                        <div  className={styles.lineColumn15}>{props.intl.formatMessage({'id':'line-item-tax-law-title','defaultMessage':'Tax Law Reference'})}</div>
                        <div  className={styles.lineColumn16}>{props.intl.formatMessage({'id':'line-item-exemption-type-title','defaultMessage':'Exemption Type'})}</div>
                        <div  className={styles.lineColumn17}>{props.intl.formatMessage({'id':'line-item-exemption-reason-title','defaultMessage':'Exemption Reason'})}</div>
                        <div hidden={propertyCache.isHidden('Line_Item_Tools_Check')} className={styles.lineColumn18}>
                            <span hidden={!propertyCache.isMandatory('Line_Item_Tools_Check')} className={styles.mandatoryField}>*</span>
                            {props.intl.formatMessage({'id':'line-item-tools-loc-title','defaultMessage':'Tool Location'})}
                            <Tooltip title={props.intl.formatMessage({id:'tool-location-info-address'})}>
                                <InfoCircleOutlined style={{marginLeft: "5px"}}/>
                            </Tooltip>
                        </div>
                        <div hidden={propertyCache.isHidden('Line_Item_Tools_Check')} className={styles.lineColumn19}>
                            <span hidden={!propertyCache.isMandatory('Line_Item_Tools_Check')} className={styles.mandatoryField}>*</span>
                            {props.intl.formatMessage({'id':'line-item-supp-country-title','defaultMessage':'Tool location country'})}
                            <Tooltip title={props.intl.formatMessage({id:'tool-location-info-country'})}>
                                <InfoCircleOutlined style={{marginLeft: "5px"}}/>
                            </Tooltip>
                        </div>
                        <div hidden={propertyCache.isHidden('Line_Item_Tools_Check')} className={styles.lineColumn20}>
                            <span hidden={!propertyCache.isMandatory('Line_Item_Tools_Check')} className={styles.mandatoryField}>*</span>
                            {props.intl.formatMessage({'id':'line-item-inv-no-title','defaultMessage':'Inventory number'})}
                            <Tooltip title={props.intl.formatMessage({id:'tool-location-info-inventory-num'})}>
                                <InfoCircleOutlined style={{marginLeft: "5px"}}/>
                            </Tooltip>
                        </div>
                        <div hidden={propertyCache.isHidden('Line_Item_Tools_Check')} className={styles.lineColumn21}>
                            <span hidden={!propertyCache.isMandatory('Line_Item_Tools_Check')} className={styles.mandatoryField}>*</span>
                            {props.intl.formatMessage({'id':'line-item-srm-ref-no-title','defaultMessage':'SBM reference number'})}
                            <Tooltip title={props.intl.formatMessage({id:'tool-location-info-sbm-ref-num'})}>
                                <InfoCircleOutlined style={{marginLeft: "5px"}}/>
                            </Tooltip>
                        </div>
                    </div>

                    <div className={styles.lineItemData}>
                        {
                            range(rangeSize(), (state.pageNumber-1) * PAGE_SIZE)
                                .map(index => {
                                    const lineItemRecord = state.lineItems[index];
                                    return (<LineItem
                                        key={lineItemRecord.key}
                                        index={index}
                                        size={state.lineItems.length}
                                        form={props.form}
                                        recordAppender={addRecord}
                                        recordDeleter={deleteRecord}
                                        lineItemRecords={state.lineItems}
                                        taxlawReferences={taxLawReferences}
                                        unitOfMeasures={measurementUnits}
                                        currencies={allCurrencies}
                                        exemptions={exemptionTypes}
                                        countries={allCountries}
                                        onItemChange={props.onItemChange}
                                        rules={rules}
                                        document={document}
                                        dispatch={dispatch}
                                        currentUser={props.currentUser}/>);
                                })
                        }
                    </div>
                </div>

                <div className={styles.lineItemBottomButtons}>
                    <div>
                        <span style={{marginLeft: "20px"}}>{state.lineItems.length <= PAGE_SIZE ?
                            props.intl.formatMessage({id:'line-item-x-to-y'}).replace('_num_1',`${state.lineItems.length}`).replace('_num_2',`${state.lineItems.length}`) :
                            props.intl.formatMessage({id:'line-item-x-to-y-of-z'}).replace('_num_1',`${(state.pageNumber - 1) * PAGE_SIZE + 1}`).replace('_num_2',`${Math.min((state.pageNumber - 1) * PAGE_SIZE + PAGE_SIZE, state.lineItems.length)}`).replace('_num_3',`${state.lineItems.length}`)}</span>

                        <a
                            className={styles.lineItemButtonLinks}
                            style={{marginLeft: "5px"}}
                            onClick={ _=>changePageNumber(false)}>
                            &lt;
                        </a>

                        <div style={{marginLeft: "5px", marginRight: "5px", display: "inline-block"}}>
                            { props.intl.formatMessage({id:'line-item-page-x-of-y'}).replace('_num_1',`${state.pageNumber}`).replace('_num_2',`${Math.ceil((state.lineItems.length/PAGE_SIZE))}`)}
                        </div>
                        <a className={styles.lineItemButtonLinks}
                           style={{marginLeft: "5px"}}
                           onClick={ _=>changePageNumber()}
                        >
                            &gt;
                        </a>

                        <select style={{marginLeft: "25px"}} value={state.pageNumber} onChange={e=>selectPage(e)}>
                            {range(Math.ceil((state.lineItems.length/PAGE_SIZE)), 1)
                                .map((pageNumber, index) => <option key={`page_number_${pageNumber}`} value={pageNumber}>{pageNumber}</option>)
                            }
                        </select>
                    </div>
                </div>
            </Card>
        </>);
    });
}

export default LineItems;