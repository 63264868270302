import React from "react";
import { renderLinkCellValue } from "../../components/decorators/decorator";
import { IntlShape } from "react-intl/src/types";
import { Popover } from "antd";

export function renderNotificationMassage(notificationMessage) {
  if (notificationMessage !== null && notificationMessage !== undefined && notificationMessage.trim().length > 0) {
    return (
      <>
        <Popover
          getPopupContainer={() => document.body.getElementsByTagName("main")[0] as HTMLElement}
          content={notificationMessage}
          title="Notification"
        >
          {notificationMessage}
        </Popover>
      </>

    );
  }
  return "";
}

export const NOTIFICATION_TABLE_HEADERS = (intl: IntlShape) => [
  {
    headerName: intl.formatMessage({
      id: "edoc-notification-header-massage-mark-as-read",
      defaultMessage: "Mark as Read"
    }),
    field: "readFlag",
    colId: "readFlag",
    width: 150,
    checkboxSelection: true,
    headerCheckboxSelection: true

  },
  {
    headerName: intl.formatMessage({ id: "edoc-notification-header-massage-date", defaultMessage: "Receive Date" }),
    field: "insertTime",
    colId: "insertTime",
    width: 110,
    cellRendererFramework: (params) => {
      return params.value ? params.value.substring(0, 10) : "";
    }
  },
  {
    headerName: intl.formatMessage({ id: "edoc-notification-header-type", defaultMessage: "Type" }),
    field: "notificationType",
    colId: "type",
    width: 80
  },
  {
    headerName: intl.formatMessage({ id: "edoc-notification-header-menu", defaultMessage: "Menu" }),
    field: "menu",
    colId: "menu",
    width: 80
  },
  {
    headerName: intl.formatMessage({ id: "edoc-notification-header-audience", defaultMessage: "Audience" }),
    field: "audience",
    colId: "audience",
    width: 80
  },
  {
    headerName: intl.formatMessage({ id: "edoc-notification-header-message", defaultMessage: "Message" }),
    field: "templateText",
    colId: "templateText",
    width: 800,
    cellRendererFramework: (props) => renderNotificationMassage(props.value)
  }
  ,
  {
    headerName: intl.formatMessage({ id: "edoc-notification-header-link", defaultMessage: "Link" }),
    field: "linkURL",
    colId: "linkURL",
    width: 100,
    cellRendererFramework: (params) => {
      const values = params.value.split(",");
      return renderLinkCellValue(values[0], values[1]) !== null ? renderLinkCellValue(values[0], values[1]) : null;
    }
  }

];