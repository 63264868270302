import React, { useEffect, useMemo, useRef, useState } from 'react';
import '../../Welcome.css';
import '../../survey/SurveyOverview.css';
import moduleStyles from '../../documents/styling/document.module.css';
import {
    Alert,
    Badge,
    Button,
    Card,
    Col,
    ConfigProvider,
    Dropdown,
    Form,
    Menu,
    message,
    Modal,
    notification,
    Row,
    Space,
    Spin,
    Tabs,
    Tooltip,
    Typography,
} from 'antd';
import { PageContainer } from '@ant-design/pro-layout';
import { IAppComponentProps } from '../../../components';
import styles from '../../../styles/scss/portal.scss';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { GridApi } from 'ag-grid-community';
import enUS from 'antd/lib/locale/en_US';
import deDe from 'antd/lib/locale/de_DE';
import { useIntl } from 'react-intl';
import { getI18n } from '../../../utils/Utils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import {
    DownloadOutlined,
    DownOutlined,
    EyeOutlined,
    ReloadOutlined,
    SaveOutlined,
    UserOutlined,
    ExceptionOutlined
} from '@ant-design/icons';
import * as globalTypes from '../../../../__generated__/globalTypes';
import TechDocumentsSearchForm from './TechMissingInvoicesSearchForm';
import { useGetMissingInvoicesDocsListQuery } from '../../documents/queries.generated';
import { PersonalizeGridView, getAgGridLocalization } from '../../info-portal/components/grid/GridUtil';
import { TECH_MISSING_INVOICES_TABLE_HEADERS } from './TechMissingInvoicesUtils';

dayjs.extend(utc);


const TechMissingInvoices: React.FunctionComponent<IAppComponentProps> = (props) => {
    const currentUser = props.currentUser;
    const [docGridApi, setDocGridApi] = useState<GridApi>(undefined);
    const [data, setData] = useState<any>();
    const [flag,setFlag] = useState<boolean>(false);
    const [showDiscrepancies,setShowDiscrepancies] = useState<boolean>(true);
    const [docListInput, setDocListInput] = useState<globalTypes.DocListInput>()
    const sysadmin_Entitlement = 'technicaldashboard@systemadmin';

    const { TabPane } = Tabs;

    const sizes: React.CSSProperties = {
        height: '100%',
        width: '98%',
    };

    const intl = useIntl();

    const gridView = useRef<PersonalizeGridView>(
        new PersonalizeGridView(
            'missing_invoices_ag_grid_personalized_view',
            TECH_MISSING_INVOICES_TABLE_HEADERS(props.intl, props.currentUser)));

    const { data: queryData, loading, error } = useGetMissingInvoicesDocsListQuery({
        variables: {
            docListInput: {receptionDateFrom: docListInput?.receptionDateFrom, receptionDateTo: docListInput?.receptionDateTo}
        },
    });


    const excelStyles = useMemo(() => {
        return [
            {
                id: 'currencyFormat',
                numberFormat: {
                    format: '#,##0.00',
                },
            },
        ];
    }, []);

    const reload = ()=>setFlag(prevState=>!prevState);

    const onDocGridReady = (params) => {
        setDocGridApi(params.api);
        if (gridView.current.hasPersonalizedView)
        {
            gridView.current.initPersonalizedView(params.api);
        }
        else
        {
            params.api.sizeColumnsToFit();
        }
        
        
    };

    const onSearch = (values) => {
        console.log(values);
        const docListInput = {
            receptionDateFrom: values.receptionDateFrom
                ? values.receptionDateFrom.format('YYYY-MM-DD')
                : dayjs().format('YYYY-MM-DD'),
            receptionDateTo: values.receptionDateTo
                ? values.receptionDateTo.format('YYYY-MM-DD')
                : dayjs().format('YYYY-MM-DD'),
        };
        setDocListInput(docListInput)
        
        console.log(docListInput);
    };

    const onExport = (_: any) => {
        const selectedRows = docGridApi.getSelectedRows();
        console.dir(selectedRows);

        if (selectedRows.length === 0) {
            //console.log("Error")
            Modal.warning({
                title: getI18n(
                    'Warning-message',
                    'Export Document(s)', intl,
                ),
                content: getI18n(
                    'Warning-export-message',
                    'You have not selected any items to be exported. Please select an item first.', intl,
                ),
            });
        } else {
            docGridApi.exportDataAsExcel({
                onlySelectedAllPages: false,
                onlySelected: true,
                fileName: 'Missing_Invoices_Export',
            });
        }
    };

    const onToggleDiscrepancies = ()=> 
    {
        setShowDiscrepancies(showDiscrepancies=>!showDiscrepancies);

        const field = 'discrepancies';
        const currentFilterModel = docGridApi.getFilterModel();
        if (!showDiscrepancies)
        {
            //remove discrepancies from filter model
            delete currentFilterModel.discrepancies;
            docGridApi?.setFilterModel(currentFilterModel);
        }
        else{
            const newFilter = { filterType: 'set', values: [`${showDiscrepancies}`] };
            // Apply the new filter model
            const newFilterModel = { ...currentFilterModel, [field]: newFilter };
            docGridApi?.setFilterModel(newFilterModel);
        }
    }

    const onSave = (_: any) => {
        gridView.current.savePersonalizedView();
        reload();
        notification.info({ message: intl.formatMessage({ id: 'report-result-preferences-saved' }) });
    };

    const onApplyPerson = (_: any) => {
        gridView.current.applyPersonalizedView();
    };

    const onReset = (_: any) => {
        docGridApi.setFilterModel(null);
        gridView.current.reset();
    };

    const downIcon = () => <DownOutlined />;

    const menu = (
        <Menu>
            <Menu.Item key="3" disabled={!gridView.current.hasPersonalizedView()}>
                <UserOutlined onClick={onApplyPerson} />
                <a data-testid={'info-portal-personal'} onClick={onApplyPerson}>
                    {intl.formatMessage({ id: 'info-portal-personal', defaultMessage: 'Personal' })}
                </a>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="4">
                <SaveOutlined onClick={onSave} />

                <a data-testid={'info-portal-save'} onClick={onSave}>
                    {intl.formatMessage({ id: 'info-portal-save', defaultMessage: 'Save' })}
                </a>
            </Menu.Item>
        </Menu>
    );

    const gridRef = useRef();

    function onDataRefreshed  () {
        // Ensure docGridApi is available and then call sizeColumnsToFit
        setData(queryData);
        docGridApi?.setRowData(queryData.getMissingInvoicesDocsList)
        //docGridApi?.sizeColumnsToFit();
    }

    useEffect(() => {
        console.log("use effect")
        console.dir(queryData)
        if (!docListInput)
            { const docListInputNew = {
                receptionDateFrom: dayjs().startOf('d').format('YYYY-MM-DD'),
                receptionDateTo: dayjs().endOf('d').format('YYYY-MM-DD'),
            };
            setDocListInput(docListInputNew)
        }
       
        if (queryData) {
            onDataRefreshed();
          }
    }, [queryData]);

    if (loading) console.log('Loading more data...');

    if (error)
    {
        message.error( `Error! ${error.message}`);
        console.log(`error: ${error.message}`);
        //setData(undefined);
    }
    

    return (
        <ConfigProvider locale={localStorage.getItem('locale') === 'en' ? enUS : deDe}>
            <PageContainer
                title={false}
                content={''}
                extraContent={''}
                className={styles.users}
                style={{ background: 'white' }}
            >
                <Spin spinning={loading} size={'large'}>
                    <div style={{ ...sizes, minHeight: '200px' }} className="pl15 ag-theme-balham">
                        <Col md={24} sm={12} xs={24}>
                            <Tabs defaultActiveKey={'1'}>
                                {props.distinctEntitlements?.includes(sysadmin_Entitlement) && (
                                    <TabPane
                                        tab={getI18n('Document-title-missing-invoices', 'ECAP Missing Invoices', intl)}
                                        key="1"
                                        id="document-List"
                                    >
                                        <Typography.Title level={5} className={'mb10'}>
                                            {getI18n('Document-description-missing-invoices', '', intl)}
                                        </Typography.Title>
                                        <Card size={'small'} className={'ml-100'}>
                                            <TechDocumentsSearchForm
                                                onSearch={onSearch}
                                                currentUser={currentUser}
                                                intl={props.intl}
                                                onDisplay={undefined}
                                                distinctEntitlements={props.distinctEntitlements}
                                                gridRef={gridRef}
                                            />
                                            <Row gutter={24}>
                                                <Col span={24}>
                                                    <Tabs defaultActiveKey={'1'} className="users-tabs">
                                                        <TabPane
                                                            tab={getI18n(
                                                                'Document-title-overview-documents',
                                                                'Documents',
                                                                intl,
                                                            )}
                                                            key="1"
                                                        >

                                                            <div style={{ paddingBottom: '20px', display: 'block' }}>
                                                                <div>
                                                                    <label>{getI18n('welcome-No_documents', '  Number Of Documents  ', intl)} </label>
                                                                    <Space>
                                                                        <Badge
                                                                            className="site-badge-count-109"
                                                                            count={data?.getMissingInvoicesDocsList.length}
                                                                            style={{ backgroundColor: '#52c41a' }}
                                                                            title={'Number of Documents'}
                                                                            overflowCount={999999}
                                                                        />
                                                                    </Space>
                                                                </div>

                                                                <Row gutter={24} style={{ paddingTop: '5px' }}>
                                                                    <Col style={{ paddingRight: '5px' }}>
                                                                        <Dropdown.Button
                                                                            data-testid={'view-result-customization'}
                                                                            overlay={menu} trigger={['click']}
                                                                            icon={downIcon()} size={'middle'}>
                                                                            <EyeOutlined />
                                                                            {intl.formatMessage({
                                                                                id: 'info-portal-view',
                                                                                defaultMessage: 'View',
                                                                            })}
                                                                        </Dropdown.Button>
                                                                    </Col>

                                                                    <Col style={{ paddingRight: '5px' }}>
                                                                        <Tooltip
                                                                            title={intl.formatMessage({ id: 'reset-filter' })}>
                                                                            <Button
                                                                                data-testid={'view-result-customization-reset'}
                                                                                onClick={onReset} size={'middle'}
                                                                                icon={<ReloadOutlined
                                                                                    className={moduleStyles.simpleFlipRefresh} />}>
                                                                                {intl.formatMessage({ id: 'info-portal-reset' })}
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </Col>
                                                                    <Col style={{ paddingRight: '5px' }}>
                                                                        <Tooltip
                                                                            title={intl.formatMessage({ id: 'export-to-excel' })}>
                                                                            <Button data-testid={'result-export'}
                                                                                    onClick={onExport}
                                                                                    icon={<DownloadOutlined />}
                                                                                    size={'middle'}>
                                                                                {intl.formatMessage({ id: 'export' })}
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </Col>
                                                                    <Col style={{ paddingRight: '5px' }}>
                                                                        <Tooltip
                                                                            title={intl.formatMessage({ id: 'missing-invoices-show-discrepancies-toggle', defaultMessage: 'Show Discrepancies or All data' })}>
                                                                            {showDiscrepancies && showDiscrepancies === true ?
                                                                                <Button data-testid={'show-discrepancies'}
                                                                                        onClick={onToggleDiscrepancies}
                                                                                        icon={<ExceptionOutlined />}
                                                                                        size={'middle'}>
                                                                                    {intl.formatMessage({ id: 'missing-invoices-show-discrepancies', defaultMessage: 'Show Discrepancies' })}
                                                                                </Button>
                                                                                :
                                                                                <Button data-testid={'show-discrepancies'}
                                                                                        onClick={onToggleDiscrepancies}
                                                                                        icon={<ExceptionOutlined />}
                                                                                        size={'middle'}>
                                                                                    {intl.formatMessage({ id: 'missing-invoices-show-discrepancies', defaultMessage: 'Show All'})}
                                                                                </Button>
                                                                            }
                                                                        </Tooltip>
                                                                    </Col>
                                                                </Row>
                                                            </div>

                                                            <div style={{ flex: 'auto', height: '500px' }}>
                                                                <AgGridReact
                                                                    ref={gridRef}
                                                                    defaultColDef={{
                                                                        enableValue: true,
                                                                        sortable: true,
                                                                        resizable: true,
                                                                    }}
                                                                    api={docGridApi}
                                                                    columnDefs={gridView.current.getView()}
                                                                    rowData={data?.getMissingInvoicesDocsList}
                                                                    sideBar={false}
                                                                    rowClass="agGridRows"
                                                                    localeText={getAgGridLocalization(intl)}
                                                                    suppressMenuHide={true}
                                                                    debug={false}
                                                                    onGridSizeChanged={() =>
                                                                        docGridApi.sizeColumnsToFit()
                                                                    }
                                                                    onGridReady={onDocGridReady}
                                                                    rowSelection={'multiple'}
                                                                    pagination={true}
                                                                    paginationPageSize={15}
                                                                    excelStyles={excelStyles}
                                                                    suppressRowClickSelection={true}
                                                                    enableBrowserTooltips={true}
                                                                    data-testid="documents-test-id"
                                                                />
                                                            </div>
                                                        </TabPane>
                                                    </Tabs>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </TabPane>
                                )}
                            </Tabs>
                        </Col>
                    </div>
                </Spin>
            </PageContainer>
        </ConfigProvider>
    );
};
export default TechMissingInvoices;


