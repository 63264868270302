import client from "../../../config/GraphQLApolloClient";
import { GET_RULES_FOR_RULE_TYPE } from "../queries";
import { getFilteredRulesWithRuleType } from "../__generated__/getFilteredRulesWithRuleType";

export const getRuleDataByRuleType = (ruleType: string) => {
    // get all the rules filtered by Rule type
    return new Promise<getFilteredRulesWithRuleType>((resolve, reject) => {
      client.query<getFilteredRulesWithRuleType>({
        query: GET_RULES_FOR_RULE_TYPE,
        variables: { ruleType },
        fetchPolicy: "network-only"
      }).then((response) => {
        const finalResponse: getFilteredRulesWithRuleType = { getFilteredRulesWithRuleType: response.data?.getFilteredRulesWithRuleType };
        resolve(finalResponse);
      }).catch((err) => {
        console.error(err);
        reject(err);
      });
  
    });
  };