import * as React from "react";
import { ICellEditorComp, ICellEditorParams } from "ag-grid-community/dist/lib/interfaces/iCellEditor";
import { ConfigProvider, Empty, Select, SelectProps } from "antd";
import { getFormats } from "./documentTypeUtils";
import { PARTNER_TYPE, SELECTED_COMPANY_CODE } from "../../../constants";
import enUS from "antd/lib/locale/en_US";
import deDe from "antd/lib/locale/de_DE";

type SelectCellEditorProps = ICellEditorParams;

export enum SELECT_TYPE {
  FORMAT,
  CHANNEL,
}

interface SelectCellEditorState {
  value?: string;
  loading?: boolean;
  options?: string[];
  formats?: any;
}

interface IOptions {
  selectType: SELECT_TYPE;
}

const empty = () => (
  <div style={{ textAlign: "center" }}>
    <Empty description={"Loading Data ....."} />
  </div>
);

let channelOptions: SelectProps["options"] = [];
let formatsOptions: SelectProps["options"] = [];
let selectedFromat: string = undefined;

export const createSelectCellEditor = (options: IOptions) =>
  class SelectCellEditor
    extends React.Component<SelectCellEditorProps, SelectCellEditorState>
    implements ICellEditorComp {


    constructor(props: SelectCellEditorProps) {
      super(props);
      this.state = { value: props.value, loading: false, options: [], formats: undefined };

      this.setState({ loading: true });
      Promise.resolve(getFormats(localStorage.getItem(PARTNER_TYPE),
        localStorage.getItem(SELECTED_COMPANY_CODE),
        props.data.processCode,
        props.data?.processCode === "INC" ? "IN" : "OUT"))

        .then(formatsData => {
            const selectOptions = [];
            this.setState({ loading: true, formats: formatsData });
            formatsOptions = [];
            channelOptions = [];
            if (options.selectType === SELECT_TYPE.FORMAT) {
              formatsData.format.map((item) => {
                formatsOptions.push({ value: item.formatName, label: item.formatName });
              });
            } else {
              this.props.api.stopEditing();
              formatsData.format.filter(flt => flt.formatName === this.props.data.updatedFormat).map((item) => {
                item.channel.map((channelItem) => {
                  channelOptions.push({ value: channelItem.channelName, label: channelItem.channelName });
                });
              });
            }
            this.setState({ options: selectOptions, loading: false });

          }
        ).catch(e => {
        this.setState({ loading: false });
        console.error(e);
      }).finally(() => {

        this.props.api?.startEditingCell({ colKey: "updatedChannel", rowIndex: this.props.rowIndex });
        this.props.api?.startEditingCell({ colKey: "updatedFormat", rowIndex: this.props.rowIndex });

      });

    }


    private onSelectChange(value: string) {
      selectedFromat = value;
    }

    getValue() {
      return this.state.value;
    }

    isCancelBeforeStart(): boolean {
      return this.props.charPress && this.state.value === undefined;
    }

    render() {

      return (
        <ConfigProvider locale={localStorage.getItem("locale") === "en" ? enUS : deDe}>
          <Select disabled={this.props.data?.processCode === "ECAP"}
                  style={{ width: "100%", height: "100%" }}
                  size="small"
                  loading={this.state.loading}
                  value={this.state.value}
                  onChange={(value) => {
                    this.setState({ value });
                    this.onSelectChange(value);
                  }}
                  options={options.selectType === SELECT_TYPE.FORMAT ? formatsOptions : channelOptions} />

        </ConfigProvider>
      );
    }


    isCancelAfterEnd(): boolean {
      return false;
    }

    getGui(): HTMLElement {
      return undefined;
    }
  };
