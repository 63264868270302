import './styles/scss/portal.scss'
import React from 'react'
import {createRoot} from "react-dom/client";
import {ApolloProvider} from "@apollo/client";

import App from "./main/App";
import * as serviceWorker from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";

import AsyncIntlProvider from "./main/AsyncIntlProvider";

import {store} from "./store/store";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import client from "./config/GraphQLApolloClient";
import {ConfigProvider} from "antd";
import enUS from "antd/lib/locale/en_US";
import deDe from "antd/lib/locale/de_DE";


const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <ConfigProvider
        locale={localStorage.getItem("locale") === "en" ? enUS : deDe}
        theme={{
            token: {
                colorPrimary: '#5b708f',
                borderRadius: 0,

            },
          }}
    >
        <BrowserRouter>
            <ApolloProvider client={client}>
                <Provider store={store}>
                    <AsyncIntlProvider>
                        <App/>
                    </AsyncIntlProvider>
                </Provider>
            </ApolloProvider>
        </BrowserRouter>
    </ConfigProvider>
);

serviceWorker.unregister();
reportWebVitals();