import { IAppComponentProps } from '../../../../components';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Checkbox, Col, DatePicker, Form, Input, Modal, Row, Select, Space, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import { useLazyQuery } from '@apollo/client';
import { getI18n, isExternalUser } from '../../../../utils/Utils';
import { updateSelectedPartner } from '../../IStoredPartnerRecordSlice';
import { useAppDispatch, useAppSelector } from '../../../../main/hooks';
import { filteredEntitlementLookupForSelf } from '../../../../main/entitlementService';
import { getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners } from '../../../../main/__generated__/getSelfEntitlementResultByFilter';
import { ECAP_USER_ENTITLEMENT_BY_USERID, USER_ENTITLEMENT_BY_USERID } from '../../../../main/queries';
import { getEcapUserEntitlementResultByUserId } from '../../../../main/__generated__/getEcapUserEntitlementResultByUserId';
import { DocumentType } from '../../../../components/DocumentTypes';
import { BmwCompanyCode } from '../../../../components/BmwCompanyCode';
import { isTemplateDocumentLoading } from '../../IStoredTemplateDocumentLoadingSlice';
import { setDocumentMode, setTemplateMode } from '../../IStoredDocumentRecordSlice';
import { MODE } from '../../IStoredDocumentRecord';
import { MY_DOCS_TEMPLATE_DROPDOWN } from '../queries';
import { getDocumentTemplateDropdown } from '../__generated__/getDocumentTemplateDropdown';
import RenderIfEntitled from '../../../../components/EntitlementComponents';
import {useNavigate} from "react-router-dom";
import _ from "lodash";

type TemplateDocumentSearchFormProps = {
    count: number;
    onExport: any;
    onSearch: any;
} & IAppComponentProps;

const template_Entitlement = 'display_tp@ecap';

const TemplateDocumentSearchForm: React.FunctionComponent<TemplateDocumentSearchFormProps> = (props) => {
    const [checked, setChecked] = React.useState(false);

    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const [selectOptions, setSelectOption] = useState<
        getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners[]
    >([]);

    const [partnerNo, setPartnerNo] = useState<string>(undefined);
    const intl = useIntl();
    const navigate = useNavigate();
    const { loading } = useAppSelector((state) => state.templateDocumentLoading);
    const [documentTemplateData, setDocumentTemplateData] = useState<getDocumentTemplateDropdown>();

    const [getTemplateDropdown, {}] = useLazyQuery<getDocumentTemplateDropdown>(MY_DOCS_TEMPLATE_DROPDOWN, {
        fetchPolicy: 'network-only',
    });

    const removeDuplicated = (data: getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners[]) =>
        Array.from(
            data
                .reduce((acc, item) => (item && item['partnerId'] && acc.set(item['partnerId'], item), acc), new Map())
                .values()
        );

    const searchPartner = (partnerName) => {
        Promise.resolve(filteredEntitlementLookupForSelf(template_Entitlement, partnerName, 1, 50))
            .then((response) => {
                const searchOption = removeDuplicated(response.getSelfEntitlementResultByFilter.partners);
                setSelectOption(searchOption);
                dispatch(isTemplateDocumentLoading(false));
            })
            .catch((err) => {
                console.error(err);
                dispatch(isTemplateDocumentLoading(false));
            });
    };

    const findSelectedPartner = (partnerNo) => {
        return selectOptions.find((item) => {
            if (item.partnerNo === partnerNo) {
                return item;
            }
        });
    };

    const handleSearch = _.debounce(function (value: string) {
        dispatch(isTemplateDocumentLoading(true));
        searchPartner(value);
    }, 1000);

    const [getUserEntitlement, {}] = useLazyQuery<getEcapUserEntitlementResultByUserId>(
        ECAP_USER_ENTITLEMENT_BY_USERID,
        {
            variables: { userId: props.currentUser.uid, authentication: localStorage.getItem('id_token') },
            fetchPolicy: 'cache-first',
        }
    );

    const checkPartnerEntitlement = () => {
        if (isExternalUser(props.distinctEntitlements)) {
            dispatch(isTemplateDocumentLoading(true));
            getUserEntitlement()
                .then((response) => {
                    const userEntitlement = response.data?.getEcapUserEntitlementResultByUserId;
                    if (userEntitlement?.partners?.length === 1) {
                        form.setFieldsValue({
                            partnerId: `${userEntitlement.partners[0]?.partnerNo} - ${userEntitlement.partners[0]?.partnerName}`,
                        });
                        setPartnerNo(userEntitlement.partners[0]?.partnerNo);
                    }
                    if (userEntitlement?.partners?.length > 1) {
                        setSelectOption(userEntitlement?.partners);
                    }
                    dispatch(isTemplateDocumentLoading(false));
                })
                .catch((_) => {
                    dispatch(isTemplateDocumentLoading(false));
                });
        }
    };

    const onSearch = () => {
        if (partnerNo) {
            const values = { ...form.getFieldsValue(), partnerNo };
            props.onSearch(values);
        } else {
            props.onSearch(form.getFieldsValue());
        }
    };

    const onClear = () => {
        Modal.confirm({
            title: intl.formatMessage({
                id: 'ecap-template-search-reset-data',
                defaultMessage: 'Reset Input Data',
            }),
            content: intl.formatMessage({
                id: 'ecap-template-search-input-data-warning',
                defaultMessage: 'Input Data will be lost. Do you want to continue?',
            }),
            okText: intl.formatMessage({
                id: 'e-cap-capture-document-cancel-confirm-modal-yes',
                defaultMessage: 'YES',
            }),
            cancelText: intl.formatMessage({
                id: 'e-cap-capture-document-cancel-confirm-modal-no',
                defaultMessage: 'NO',
            }),
            onOk: () => form.resetFields(),
        });
    };

    const onExport = () => {
        props.onExport();
    };

    const onCreate = () => {
        dispatch(setDocumentMode(undefined));
        console.log("Create new template");
        dispatch(setTemplateMode(true));
        navigate('/ecap/template-processing');
    };

    const handleChange = (value) => {
        dispatch(updateSelectedPartner(findSelectedPartner(value)));
        setChecked(!checked);
    };
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    // const getTemplateData = () => {
    //     getTemplateDropdown().then((response) => {
    //         setDocumentTemplateData(response.data);
    //     });
    // };

    useEffect(() => {
        checkPartnerEntitlement();
        onSearch();
        // getTemplateData();
    }, [partnerNo]);

    return (
        <>
            <Form
                size={'middle'}
                form={form}
                style={{ width: '2100px' }}
                layout={'vertical'}
                onFinish={onSearch}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                className={'mt100'}

            >
                <Card title={getI18n('management-searchmask-title', 'Search Mask', intl)} size={'default'}>
                    <Row gutter={24} style={{ alignContent: 'inherit', marginTop: '50' }}>
                        {/* <Col span={5}>
                            <Form.Item
                                label={getI18n(
                                    'ecap-template-filter-Templatename-fieldname',
                                    'Template Name',
                                    intl
                                )}
                                name={'templateName'}
                            >
                                <Select style={{ width: 400 }}
                                    className="mb30 mt6"
                                    size={'middle'}
                                    showSearch={true}
                                    placeholder={getI18n(
                                        'document-overview-search-message',
                                        'Enter Document Template name',
                                        intl
                                    )}
                                    defaultActiveFirstOption={true}
                                    filterOption={false}
                                    notFoundContent={null}
                                    loading={loading}
                                    >
                                        {documentTemplateData &&
                                        documentTemplateData.getDocumentTemplateDropdown &&
                                        documentTemplateData.getDocumentTemplateDropdown.map((item) => (
                                            <Select.Option
                                                key={`${item.documentTemplateID}_${item.templateName}`}
                                                value={item.documentTemplateID}
                                            >
                                                {item.templateName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col> */}
                        <Col span={5}>
                            <BmwCompanyCode
                                currentUser={props.currentUser}
                                intl={props.intl}
                                distinctEntitlements={props.distinctEntitlements}
                            />
                        </Col>
                        <Col span={5}>
                            <Form.Item label={getI18n('partner-overview', 'Partner', intl)} name={'partnerNo'}>
                                <Select
                                    style={{ width: 400 }}
                                   // className="mb30 mt6"
                                    size={'middle'}
                                    showSearch={true}
                                    placeholder={getI18n(
                                        'document-overview-search-message',
                                        'Enter partner number or partner name',
                                        intl
                                    )}
                                    onSearch={handleSearch}
                                    onChange={handleChange}
                                    defaultActiveFirstOption={true}
                                    filterOption={false}
                                    notFoundContent={null}
                                    loading={loading}
                                >
                                    {selectOptions &&
                                        selectOptions.map((item) => (
                                            <Select.Option
                                                key={`${item.partnerId}_${item.partnerNo}`}
                                                value={item.partnerNo}
                                                data-testid={`partnerNo_${item.partnerNo}`}
                                            >
                                                {item.partnerNo} - {item.partnerName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <DocumentType bmwTypesOnly={true} currentUser={props.currentUser} intl={props.intl} distinctEntitlements={[]} />
                        </Col>
                        <Col span={5} className="mb15 mt05">
                            <Form.Item name={'myTemplates'} valuePropName="checked">
                                <Checkbox className="mb25 mt35">
                                    {getI18n('ecap-template-filter-mytemplates-fieldname', 'My Templates  ', intl)}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <Card size="small">
                    <Row gutter={24}>
                        <RenderIfEntitled entitlementToCheck='create_tp@ecap' distinctEntitlements={props.distinctEntitlements} intl={props.intl}>
                            <Col xs={18} sm={4} md={2}>
                                <Button
                                    type={'primary'}
                                    size="large"
                                    className="w-100 mt10"
                                    data-testid={'btn-create'}
                                    onClick={onCreate}
                                >
                                    {getI18n('ecap-template-create-button', 'Create New Template', intl)}
                                </Button>
                            </Col>
                        </RenderIfEntitled>
                        <Col xs={18} sm={4} md={2}>
                            <Button
                                type={'primary'}
                                htmlType="submit"
                                size="large"
                                className="w-100 mt10"
                                data-testid={'btn-search'}
                            >
                                {getI18n('welcome-search-btton', 'SEARCH', intl)}
                            </Button>
                        </Col>
                        <Col xs={18} sm={4} md={2}>
                            <Button
                                type="primary"
                                onClick={onClear}
                                size="large"
                                className="w-100 mt10"
                                data-testid={'btn-clear'}
                            >
                                {getI18n('welcome-Clear-button', 'CLEAR', intl)}
                            </Button>
                        </Col>
                        <Col xs={18} sm={4} md={2}>
                            <Button
                                type="primary"
                                id="export-list-button"
                                onClick={onExport}
                                size="large"
                                className="w-100 mt10"
                                data-testid={'btn-export'}
                            >
                                {getI18n('welcome-export-list', 'EXPORT LIST', intl)}
                            </Button>
                        </Col>

                        <Col xs={24} sm={6} md={4} className="mb20 mt20">
                            <label>{getI18n('ecap-templates-template-count', 'Number Of Entries', intl)}</label>
                            <Space>
                                <Badge
                                    className="site-badge-count-109"
                                    count={props.count}
                                    style={{ backgroundColor: '#52c41a' }}
                                    title={getI18n('ecap-templates-template-count', 'Number Of Entries', intl)}
                                    overflowCount={999999}
                                />
                            </Space>
                        </Col>
                        <Col span={2} xs={22} sm={6} md={4} />
                        <Col span={2} xs={22} sm={6} md={4} />
                    </Row>
                </Card>
            </Form>
        </>
    );
};
export default TemplateDocumentSearchForm;
