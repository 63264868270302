import React, {useRef} from "react";
import {
  DropDownSelectionMode,
  ExternalStateUpdateCallback,
  InfoPortalAgGridColumnDef,
  InfoPortalColumnDef,
  InfoPortalGridService,
  InfoPortalReport,
  InfoPortalSearchProperties,
  IValidator,
  LabelLocation,
  OnChangeListener,
  ReportViewConfig,
  stringToDayjs_yyyy_MM_dd,
  Validator
} from "../../../InfoPortalInterfaces";
import {DocumentNumber, VINNumber} from "../../InputWithOperator";
import {Card, FormInstance} from "antd";
import {CompanySelection, descriptionFilter, PartnerSelection} from "../../InfoPortalDropdowns";
import styles from "../../styling/infoportal.module.css";
import {DocumentDateFromToSelection} from "../../FromToDateSelection";
import {RuleSet} from "../../../../e-cap/util/RuleResultCache";
import {IntlShape} from "react-intl/src/types";
import {retrieveTR1Report} from "../../../services/ReportSearch";
import {PortalUser} from "../../../../../components";
import {getDateFormatOrDefault} from "../../../../users/UserFormatPreferenceServices";
import dayjs, {Dayjs} from "dayjs";

function validatator(form: FormInstance<any>): Validator {
  const validator: IValidator = {
    fetchRule(fieldName: string): RuleSet {
      const result = {
        required: false,
        picture: "",
        readOnly: false,
        hidden: false,
        formatMessage: null,
        infoMessage: null
      };
      if (fieldName === "company") {
        result.required = true;
      }
      if (fieldName === "documentDate") {
        result.required = true;
      }
      return result;
    }
  };
  return new Validator(validator);
}

function SimpleSearch({
                                     currentUser,
                                     intl,
                                     distinctEntitlements,
                                     reportEntitlement,
                                     initialization,
                                     form,
                                     initialValuesCache
                                   }: InfoPortalSearchProperties) {
  const vertical = useRef<boolean>(false);
  const validator = validatator(form);
  const partnerSelectionUpdate = new ExternalStateUpdateCallback<{ companyIds: string[], newVal: string[] }>();

  const companySelectionChange: OnChangeListener<string[]> = {
    performAction(companyIds: string[]) {
      const newVal = form.getFieldValue("partner") || [];
      partnerSelectionUpdate.invokeCallBack({companyIds, newVal});
    }
  };

  const customDisabledDate = (current:Dayjs, arg:any) => {
    const today = dayjs();
    const minDate = today.subtract(5, "years");
    let maxDate = today;

    const selectedFrom: Dayjs = arg['from'];

    if (selectedFrom) {
      maxDate = selectedFrom.add(3, "months");
      if (maxDate.isAfter(today)) {
        maxDate = today;
      }
    }

    return current.isBefore(minDate) || current.isAfter(maxDate);
  };

  return <Card size={"small"} style={{ backgroundColor: "#f1f3f5" }}>
    <div className={styles.selfBilledExpertSearchContainer}>
      <div className={styles.singleColumnGrid}>
        <CompanySelection
            selectionMode={DropDownSelectionMode.MULTIPLE}
            labelLocation={vertical.current ? LabelLocation.TOP : LabelLocation.LEFT}
            displayColon={true}
            currentUser={currentUser}
            initialValue={initialValuesCache?.getValue("company")}
            intl={intl}
            validator={validator}
            distinctEntitlements={distinctEntitlements}
            initialization={initialization} form={form}
            customLabel={intl.formatMessage({id: 'bmwcompany-datails-panel-header'})}
            onSelectionChange={companySelectionChange}
            filterOptions={descriptionFilter}
            companyEntitlement={reportEntitlement}/>

        <PartnerSelection
            selectionMode={DropDownSelectionMode.MULTIPLE}
            partnerEntitlement={reportEntitlement}
            currentUser={currentUser} intl={intl}
            initialValue={initialValuesCache?.getValue("partner")}
            customLabel={intl.formatMessage({id: 'dealer-number'})}
            distinctEntitlements={distinctEntitlements}
            initialization={initialization} form={form}
            validator={validator}
            labelLocation={vertical.current ? LabelLocation.TOP : LabelLocation.LEFT}
            displayColon={true}
            companyIds={initialValuesCache?.getValue("company")}
            externalStateUpdater={partnerSelectionUpdate}
            filterOptions={descriptionFilter}/>
      </div>

      <div className={styles.singleColumnGrid}>
        <DocumentDateFromToSelection
            validator={validator}
            dateFormatter={getDateFormatOrDefault(currentUser)}
            labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
            displayColon={!vertical}
            customDisabledDate={customDisabledDate}
            initialValue={initialValuesCache?.getValue("documentDate", stringToDayjs_yyyy_MM_dd)}
            currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
            initialization={initialization} form={form}/>

        <DocumentNumber
            validator={validator}
            labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
            displayColon={!vertical}
            initialInputValue={initialValuesCache?.getValue("documentNumber")}
            initialSelectedOperator={initialValuesCache?.getValueOrDefault("documentNumberOperator", "CP")}
            currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
            initialization={initialization} form={form}/>

      </div>
      <div className={styles.singleColumnGrid}>
        <VINNumber
            validator={validator}
            labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
            displayColon={!vertical}
            initialInputValue={initialValuesCache?.getValue("vinNumber")}
            initialSelectedOperator={initialValuesCache?.getValueOrDefault("vinNumberOperator", "CP")}
            currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
            initialization={initialization} form={form}/>
      </div>
      </div>
  </Card>
;
}

export class TR1AgGridColumnDef implements InfoPortalAgGridColumnDef {
  getLineItemColumnDefinitions(intl: IntlShape,user:PortalUser): InfoPortalColumnDef[] {
    return [];
  }

  getDefaultColumnDefinitions(intl: IntlShape,_user:PortalUser): InfoPortalColumnDef[] {
    return [
      {
        "field": "bmwCompany",
        "headerName": intl.formatMessage({id: 'grid-header-bmw-company'})
      },
      {
        "field": "documentNumber",
        "headerName": intl.formatMessage({id: 'grid-header-document-number'})
      },
      {
        "field": "documentDate",
        "headerName": intl.formatMessage({id: 'grid-header-document-date'})
      },
      {
        "field": "dealerNumber",
        "headerName": intl.formatMessage({id: 'grid-header-dealer-number'})
      },
      {
        "field": "dealerName",
        "headerName": intl.formatMessage({id: 'grid-header-dealer-name'})
      },
      {
        "field": "partnerSalesPartner",
        "headerName": intl.formatMessage({id: 'grid-header-partner-sales-partner'})
      },
      {
        "field": "vinNumber",
        "headerName": intl.formatMessage({id: 'grid-header-vin-number'})
      },
      {
        "field": "handOverDateTransferOfRisk",
        "headerName": intl.formatMessage({id: 'grid-header-hand-over-date-transfer-of-risk'})
      },
      {
        "field": "brand",
        "headerName": intl.formatMessage({id: 'grid-header-brand'})
      },
      {
        "field": "modelCode",
        "headerName": intl.formatMessage({id: 'grid-header-model-code'})
      },
      {
        "field": "commissionType",
        "headerName": intl.formatMessage({id: 'grid-header-commission-type'})
      },
      {
        "field": "recipient",
        "headerName": intl.formatMessage({id: 'grid-header-recipient'})
      },
      {
        "field": "salesCategory",
        "headerName": intl.formatMessage({id: 'grid-header-sales-category'})
      },
      {
        "field": "type",
        "headerName": intl.formatMessage({id: 'grid-header-type'})
      },
      {
        "field": "currency",
        "headerName": intl.formatMessage({id: 'grid-header-currency'})
      },
      {
        "field": "paymentType",
        "headerName": intl.formatMessage({id: 'grid-header-payment-type'})
      },
      {
        "field": "baseValue",
        "headerName": intl.formatMessage({id: 'grid-header-base-value'})
      },
      {
        "field": "percentage",
        "headerName": intl.formatMessage({id: 'grid-header-percentage'})
      },
      {
        "field": "netValuePaidExclVAT",
        "headerName": intl.formatMessage({id: 'grid-header-net-value-paid-excl-vat'})
      },
      {
        "field": "grossValuePaidInclVAT",
        "headerName": intl.formatMessage({id: 'grid-header-gross-value-paid-incl-vat'})
      }
    ];
  }

  getShortViewColumns(): string[] {
    return [];
  }
}

export class TR1Report implements InfoPortalReport {
  private _config = new TR1AgGridColumnDef();

  getColumnsDefinition(): InfoPortalAgGridColumnDef {
    return this._config;
  }

  performSearch(form: FormInstance, currentUser: any, distinctEntitlements: string[]): Promise<InfoPortalGridService> {
    return retrieveTR1Report(form).then(result =>
        new InfoPortalGridService(
            new ReportViewConfig("TR1", this._config), result, distinctEntitlements));
  }

  hasExpertSearch(): boolean {
    return false;
  }

  renderExpertSearch({
                       currentUser,
                       intl,
                       distinctEntitlements,
                       initialization,
                       form,
                       initialValuesCache
                     }: InfoPortalSearchProperties): JSX.Element {
    return undefined;
  }

  renderSimpleSearch({
                       currentUser,
                       intl,
                       distinctEntitlements,
                       initialization,
                       form,
                       initialValuesCache,
                       rerenderDependentFields
                     }: InfoPortalSearchProperties): JSX.Element {
    return <SimpleSearch initialization={initialization}
                                      currentUser={currentUser}
                                      intl={intl}
                                      form={form}
                                      distinctEntitlements={distinctEntitlements}
                                      rerenderDependentFields={rerenderDependentFields}
                                      initialValuesCache={initialValuesCache}
                                      reportEntitlement={this.getEntitlement()}/>;
  }


  getEntitlement(): string {
    return "display_tnr@reports";
  }

  getReportName(): string {
    return "RL1";
  }
}